import React from 'react';
import {addSpacesToNumber} from "../help/addSpacesToNumber";
import {Col} from "antd";

const TableCardOne = ({
                          title,
                          value,
                          diff,
                          sub,
                          fsTitle = 13,
                          fsValue = 22,
                          fsDiff = 20,

                      }: any) => {

    return (
        <div className="fina-result-main-card-one">

            <div className="fina-result-main-title" style={{width: '60%', fontSize: fsTitle}}>
                {title}
                {sub &&
                <span style={{fontSize: 10}}> {sub}</span>
                }
            </div>
            <div className="fina-result-main-card-value" style={{fontSize: fsValue}}>
                <div>
                    {value}
                </div>
                <div className="fina-result-main-card-diff" style={{fontSize:fsDiff}}>
                    {diff}
                </div>
            </div>
        </div>
    );
};

export default TableCardOne;