import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import { ReactComponent as selectIcon } from "assets/images/select-icon.svg";
import { useUserUpdate } from "features/users/hooks/useUserUpdate";
import useRoleList from "features/roles/hooks/useRoleList";
import InputMask from "react-input-mask";
import {ReactComponent as telegramIcon} from "../../assets/images/telegram-icon.svg";

interface RoleOption {
    label: string;
    value: string;
}

interface EditUserModalProps {
    user: User;
    onClose: any;
}

export default function EditUserModalContent({
    user,
    onClose,
}: EditUserModalProps) {
    const { handleUpdate, isError, errorMessage, isLoading } = useUserUpdate(
        user.id
    );
    const [rolesOptions, setRolesOptions] = useState<RoleOption[]>([]);
    const { roles } = useRoleList();

    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    useEffect(() => {
        let newRolesOptions: RoleOption[] = [];
        roles?.forEach((role) => {
            newRolesOptions.push({
                value: role.id.toString(),
                label: role.name,
            });
        });
        setRolesOptions(newRolesOptions);
    }, [roles]);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            roleId: user.role.id.toString(),
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            tg: user.tg,
            login: user.login,
            password: "",
            email: user.email,
        });
    }, [user]);

    const onUpdate = (values: any, onClose: any) => {
        //sending only updated values

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (values.phone.length < 18) {
            setIsPhoneError(true);
            return
        }

        if (!emailRegex.test(values.email)) {
            setIsEmailError(true);
            return
        }

        let updatedValues = { ...values };
        if (updatedValues.roleId === user.role.id.toString()) {
            delete updatedValues.roleId;
        }
        if (updatedValues.firstName === user.firstName) {
            delete updatedValues.firstName;
        }
        if (updatedValues.lastName === user.lastName) {
            delete updatedValues.lastName;
        }
        if (updatedValues.phone === user.phone) {
            delete updatedValues.phone;
        }
        if (updatedValues.tg === user.tg) {
            delete updatedValues.tg;
        }
        if (updatedValues.login === user.login) {
            delete updatedValues.login;
        }
        if (updatedValues.email === user.email) {
            delete updatedValues.email;
        }
        if (!updatedValues.password) {
            delete updatedValues.email;
        }

        handleUpdate(updatedValues, onClose);
    };

    return (
        <div className={"modal-wrapper"} style={{ padding: "30px" }}>
            <ModalHeader
                title={"Редактирование пользователя"}
                onClose={onClose}
            />
            <Form
                form={form}
                layout={"vertical"}
                onFinish={(values) => onUpdate(values, onClose)}
            >
                <Form.Item
                    label={"Выберите роль пользователя"}
                    name="roleId"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, выберите роль пользователя",
                        },
                    ]}
                >
                    <Select
                        options={rolesOptions}
                        suffixIcon={<Icon component={selectIcon} />}
                    />
                </Form.Item>

                <Form.Item
                    label={"Введите имя"}
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите имя",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Введите фамилию"}
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите фамилию",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={"Номер телефона"}
                    name="phone"
                    rules={[{ required: true, message: 'Пожалуйста введите номер' }]}
                    validateStatus={isPhoneError ? 'error' : ''}
                    help={isPhoneError ? 'Пожалуйста введите корректный номер' : null}
                >
                    <InputMask
                        mask="+7 (999) 999-99-99"
                        maskChar=""
                        onFocus={() => setIsPhoneError(false)}
                    >
                        {(inputProps: any) => (
                            <Input
                                {...inputProps}
                                placeholder="Phone number"
                            />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item
                    label={"Ник telegram"}
                    name="tg"
                    rules={[
                        {
                            required: false,
                            message: "Пожалуйста, введите ник telegram",
                        },
                    ]}
                >
                    <InputMask
                        mask="@*********************************"
                        maskChar=""
                    >
                        {(inputProps: any) => (
                            <Input
                                {...inputProps}
                                placeholder={"Telegram"}
                            />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[{ required: true, message: 'Пожалуйста введите почту' }]}
                    validateStatus={isEmailError ? 'error' : ''}
                    help={isEmailError ? 'Пожалуйста введите корректную почту' : null}
                >
                    <Input
                        onFocus={() => setIsEmailError(false)}

                        style={{
                            padding: "5px !important",
                        }}
                        placeholder={"E-mail"}
                    />
                </Form.Item>

                <Form.Item
                    label={"Введите логин"}
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите логин",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Введите пароль"}
                    name="password"
                    rules={[
                        {
                            required: false,
                            message: "Пожалуйста, введите пароль",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{ width: "100%" }}
                            htmlType={"submit"}
                            loading={isLoading}
                        >
                            Сохранить
                        </Button>
                    </Col>
                    <Col span={12}>
                        {" "}
                        <Button
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{ width: "100%" }}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>

            {isError && (
                <div
                    className={"ant-form-item-explain-error"}
                    style={{ color: "#ff4d4f" }}
                >
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
