import React, {useState} from 'react';
import {Button, Col, DatePicker, Dropdown, Input, Row, Table} from "antd";
import useLaboratoryCurrentData from "../laboratoryTVT/hooks/useLaboratoryCurrentData";
import Icon from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";

const {RangePicker} = DatePicker;

const MicroSeasonsSection = () => {

  const {
    getProductsFilterItems,
    handleRangeChangeTable,
    selectedSeasonType,
    setSelectedSeasonType,
    seasonTypes,
    getStatusFilterItems,
    getGenderFilterItems,
    setSaleDate,
    isLoading,
    data
  } = useLaboratoryCurrentData()

  return (
    <div>
      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getProductsFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Предмет</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getGenderFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Пол</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{marginLeft: 10, marginTop: 3}}>
          <DatePicker
            placeholder={"Выберите период"}
            format={"DD.MM.YYYY"}
            style={{width: "100%"}}
          />
        </Col>

      </Row>
      <Row className={"container"} style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10}}>
        <Col style={{width: '100%'}}>
          <Input
            type={'number'}
            className="table-header-input"
            placeholder={"Среднее кол-во продаж в день "}
          />
        </Col>
        <Col style={{width: '100%'}}>
          <Input
            type={'number'}
            className="table-header-input"
            placeholder={"Кол-во дней с продажами "}
          />
        </Col>
      </Row>


      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col span={24}>

        </Col>
      </Row>
    </div>
  );
};

export default MicroSeasonsSection;
