import { Checkbox, Input, MenuProps } from "antd";
import { useState, useEffect } from "react";
import Icon from "@ant-design/icons";
import { ReactComponent as searchIcon } from "assets/images/search-icon.svg";

export interface AnalyticTableColumnFilter {
    allColumnsVisible?: boolean;
    productNameVisible: boolean;
    articleVisible: boolean;
    selfCostVisible: boolean;
    wbStorageRemainingVisible: boolean;
    storageRemainingWisible: boolean;
    productOnWayVisible: boolean;
    orderedInChinaVisible: boolean;
    avgOrdersVisible: boolean;
    avgSalesPerDayFinRepVisible: boolean;
    avgSalesVisible: boolean;
    salesPercentVisible: boolean;
    commissionVisible: boolean;
    logisticVisible: boolean;
    storageVisible: boolean;
    adExpensesVisible: boolean;
    adLeftMoneyVisible: boolean;
    profitPerMonthVisible: boolean;
    salePerMonthVisible: boolean;
    currentPriceVisible: boolean;
    recommendedPriceVisible: boolean;
    expensePerBuyoutVisible: boolean;
    expensePerCashbackVisible: boolean;
    notesVibislbe: boolean;
    companyVisible: boolean;
    needToShipVisible: boolean;
}

interface filtersSearchData {
    "columns-all": string;
    "columns-item": string;
    "columns-article": string;
    "columns-selfcost": string;
    "columns-wb-remaining": string;
    "columns-storage-remaining": string;
    "columns-item-way": string;
    "columns-ordered-in-china": string;
    "columns-average-orders": string;
    "columns-average-orders-fin-res": string;
    "columns-average-sells": string;
    "columns-average-sales-percent": string;
    "columns-commission": string;
    "columns-logistic": string;
    "columns-storage": string;
    "columns-ad-expenses": string;
    "columns-ad-left": string;
    "columns-month-profit": string;
    "columns-month-sale": string;
    "columns-current-price": string;
    "columns-recommended-price": string;
    "columns-expense-buyout": string;
    "columns-expense-cashback": string;
    "columns-notes": string;
    "columns-company": string;
    "columns-need-to-ship": string;
}

export const useAnalyticsTableColumnFilters = () => {
    const [columnSearchText, setColumnSearchText] = useState<string>("");
    const [allColumnsVisible, setAllColumnsVisible] = useState<boolean>(true);
    const [productNameVisible, setProductNameVisible] = useState<boolean>(true);
    const [articleVisible, setArticleVisible] = useState<boolean>(true);
    const [selfCostVisible, setSelfCostVisible] = useState<boolean>(true);
    const [wbStorageRemainingVisible, setWbStorageRemainingVisible] = useState<boolean>(true);
    const [storageRemainingWisible, setStorageRemainingVisible] = useState<boolean>(true);
    const [productOnWayVisible, setProductOnWayVisible] = useState<boolean>(true);
    const [orderedInChinaVisible, setOrderedInChinaVisible] = useState<boolean>(true);
    const [avgOrdersVisible, setAvgOrdersVisible] = useState<boolean>(true);
    const [avgSalesPerDayFinRepVisible, setAvgSalesPerDayFinRepVisible] = useState<boolean>(true);
    const [avgSalesVisible, setAvgSalesVisible] = useState<boolean>(true);
    const [salesPercentVisible, setSalesPercentVisible] = useState<boolean>(true);
    const [commissionVisible, setCommissionVisible] = useState<boolean>(true);
    const [logisticVisible, setLogisticVisible] = useState<boolean>(true);
    const [storageVisible, setStorageVisible] = useState<boolean>(true);
    const [adExpensesVisible, setAdExpensesVisible] = useState<boolean>(true);
    const [adLeftMoneyVisible, setAdLeftMoneyVisible] = useState<boolean>(true);
    const [profitPerMonthVisible, setProfitPerMonthVisible] = useState<boolean>(true);
    const [salePerMonthVisible, setSalePerMonthVisible] = useState<boolean>(true);
    const [currentPriceVisible, setCurrentPriceVisible] = useState<boolean>(true);
    const [recommendedPriceVisible, setRecommendedPriceVisible] = useState<boolean>(true);
    const [expensePerBuyoutVisible, setExpensePerBuyoutVisible] = useState<boolean>(true);
    const [expensePerCashbackVisible, setExpensePerCashbackVisible] = useState<boolean>(true);
    const [notesVibislbe, setNotesVisible] = useState<boolean>(true);
    const [companyVisible, setCompanyVisible] = useState<boolean>(true);
    const [needToShipVisible, setNeedToShipVisible] = useState<boolean>(true);

    const filters: AnalyticTableColumnFilter = {
        productNameVisible,
        articleVisible,
        selfCostVisible,
        wbStorageRemainingVisible,
        avgSalesPerDayFinRepVisible,
        storageRemainingWisible,
        productOnWayVisible,
        orderedInChinaVisible,
        avgOrdersVisible,
        avgSalesVisible,
        salesPercentVisible,
        commissionVisible,
        logisticVisible,
        storageVisible,
        adExpensesVisible,
        adLeftMoneyVisible,
        profitPerMonthVisible,
        salePerMonthVisible,
        currentPriceVisible,
        recommendedPriceVisible,
        expensePerBuyoutVisible,
        expensePerCashbackVisible,
        notesVibislbe,
        companyVisible,
        needToShipVisible,
    };

    const stateSetters = [
        setProductNameVisible,
        setArticleVisible,
        setSelfCostVisible,
        setWbStorageRemainingVisible,
        setAvgSalesPerDayFinRepVisible,
        setStorageRemainingVisible,
        setProductOnWayVisible,
        setOrderedInChinaVisible,
        setAvgOrdersVisible,
        setAvgSalesVisible,
        setSalesPercentVisible,
        setCommissionVisible,
        setLogisticVisible,
        setStorageVisible,
        setAdExpensesVisible,
        setAdLeftMoneyVisible,
        setProfitPerMonthVisible,
        setSalePerMonthVisible,
        setCurrentPriceVisible,
        setRecommendedPriceVisible,
        setExpensePerBuyoutVisible,
        setExpensePerCashbackVisible,
        setNotesVisible,
        setCompanyVisible,
        setNeedToShipVisible
    ];

    const columnSearchData = {
        "columns-all": "Все столбцы",
        "columns-item": "Предмет",
        "columns-article": "Артикул поставщика",
        "columns-selfcost": "Себестоимость",
        "columns-wb-remaining": "Остаток на складе WB",
        "columns-storage-remaining": "Остаток на складе",
        "columns-item-way": "Товар в пути",
        "columns-ordered-in-china": "Заказано в китае",
        "columns-average-orders": "Среднее кол-во заказов в день",
        "columns-average-sells": "Среднее кол-во продаж в день (отчет продаж) ",
        "columns-average-orders-fin-res": "Среднее кол-во продаж в день  (фин.отчет)",
        "columns-average-sales-percent": "% Выкупа",
        "columns-commission": "Комиссия",
        "columns-logistic": "Логистика",
        "columns-storage": "Хранение",
        "columns-ad-expenses": "Затраты на рекламу",
        "columns-ad-left": "Остаток рекл. бюджета",
        "columns-month-profit": "Выручка за 30 дней",
        "columns-month-sale": "Продажа за 30 дней",
        "columns-current-price": "Текущая цена",
        "columns-recommended-price": "Рекомендованная цена",
        "columns-expense-buyout": "Расход на выкуп",
        "columns-expense-cashback": "Расход на кешбек",
        "columns-notes": "Заметки",
        "columns-company": "Компания",
        "columns-need-to-ship": "Запас товара на дней",
    };

    const allSelectedCheck = (): boolean => {
        for (const filter in filters) {
            if (!filters[filter as keyof AnalyticTableColumnFilter]) {
                return false;
            }
        }
        return true;
    };

    const handleAllChange = (value: boolean) => {
        stateSetters.forEach((stateSetter) => {
            stateSetter(value);
        });
    };

    const columnsMenuItems: MenuProps["items"] = [
        {
            label: (
                <Input
                    className={"analytic-dropdown-input"}
                    onClick={(e) => e?.stopPropagation()}
                    prefix={<Icon component={searchIcon} />}
                    placeholder={"Поиск..."}
                    value={columnSearchText}
                    onChange={(e) => setColumnSearchText(e.target.value)}
                />
            ),
            key: "size-search",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={allColumnsVisible}
                        onClick={(e) => e.preventDefault()}
                        onChange={(e) => {
                            handleAllChange(e.target.checked);
                        }}
                    />
                    <div>Все столбцы</div>
                </div>
            ),
            key: "columns-all",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.productNameVisible}
                        onChange={(e) =>
                            setProductNameVisible(e.target.checked)
                        }
                    />
                    <div>Предмет</div>
                </div>
            ),
            key: "columns-item",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.articleVisible}
                        onChange={(e) => setArticleVisible(e.target.checked)}
                    />
                    <div>Артикул поставщика</div>
                </div>
            ),
            key: "columns-article",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.selfCostVisible}
                        onChange={(e) => setSelfCostVisible(e.target.checked)}
                    />
                    <div>Себестоимость</div>
                </div>
            ),
            key: "columns-selfcost",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.wbStorageRemainingVisible}
                        onChange={(e) =>
                            setWbStorageRemainingVisible(e.target.checked)
                        }
                    />
                    <div>Остаток на складе WB</div>
                </div>
            ),
            key: "columns-wb-remaining",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.storageRemainingWisible}
                        onChange={(e) =>
                            setStorageRemainingVisible(e.target.checked)
                        }
                    />
                    <div>Остаток на складе</div>
                </div>
            ),
            key: "columns-storage-remaining",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.productOnWayVisible}
                        onChange={(e) =>
                            setProductOnWayVisible(e.target.checked)
                        }
                    />
                    <div>Товар в пути</div>
                </div>
            ),
            key: "columns-item-way",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.orderedInChinaVisible}
                        onChange={(e) =>
                            setOrderedInChinaVisible(e.target.checked)
                        }
                    />
                    <div>Заказано в китае</div>
                </div>
            ),
            key: "columns-ordered-in-china",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.avgOrdersVisible}
                        onChange={(e) => setAvgOrdersVisible(e.target.checked)}
                    />
                    <div>Среднее кол-во заказов в день (отчет продаж)</div>
                </div>
            ),
            key: "columns-average-orders",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.avgSalesPerDayFinRepVisible}
                        onChange={(e) => setAvgSalesPerDayFinRepVisible(e.target.checked)}
                    />
                    <div>Среднее кол-во заказов в день (фин.отчет)</div>
                </div>
            ),
            key: "columns-average-orders-fin-res",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.avgSalesVisible}
                        onChange={(e) => setAvgSalesVisible(e.target.checked)}
                    />
                    <div>Среднее кол-во продаж в день</div>
                </div>
            ),
            key: "columns-average-sells",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.salesPercentVisible}
                        onChange={(e) =>
                            setSalesPercentVisible(e.target.checked)
                        }
                    />
                    <div>% Выкупа</div>
                </div>
            ),
            key: "columns-average-sales-percent",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.commissionVisible}
                        onChange={(e) => setCommissionVisible(e.target.checked)}
                    />
                    <div>Комиссия</div>
                </div>
            ),
            key: "columns-commission",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.logisticVisible}
                        onChange={(e) => setLogisticVisible(e.target.checked)}
                    />
                    <div>Логистика</div>
                </div>
            ),
            key: "columns-logistic",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.storageVisible}
                        onChange={(e) => setStorageVisible(e.target.checked)}
                    />
                    <div>Хранение</div>
                </div>
            ),
            key: "columns-storage",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.adExpensesVisible}
                        onChange={(e) => setAdExpensesVisible(e.target.checked)}
                    />
                    <div>Затраты на рекламу</div>
                </div>
            ),
            key: "columns-ad-expenses",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.adLeftMoneyVisible}
                        onChange={(e) =>
                            setAdLeftMoneyVisible(e.target.checked)
                        }
                    />
                    <div>Остаток рекл. бюджета</div>
                </div>
            ),
            key: "columns-ad-left",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.profitPerMonthVisible}
                        onChange={(e) =>
                            setProfitPerMonthVisible(e.target.checked)
                        }
                    />
                    <div>Выручка за 30 дней</div>
                </div>
            ),
            key: "columns-month-profit",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.salePerMonthVisible}
                        onChange={(e) =>
                            setSalePerMonthVisible(e.target.checked)
                        }
                    />
                    <div>Продажа за 30 дней</div>
                </div>
            ),
            key: "columns-month-sale",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.currentPriceVisible}
                        onChange={(e) =>
                            setCurrentPriceVisible(e.target.checked)
                        }
                    />
                    <div>Текущая цена</div>
                </div>
            ),
            key: "columns-current-price",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.recommendedPriceVisible}
                        onChange={(e) =>
                            setRecommendedPriceVisible(e.target.checked)
                        }
                    />
                    <div>Рекомендованная цена</div>
                </div>
            ),
            key: "columns-recommended-price",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.expensePerBuyoutVisible}
                        onChange={(e) =>
                            setExpensePerBuyoutVisible(e.target.checked)
                        }
                    />
                    <div>Расход на выкуп</div>
                </div>
            ),
            key: "columns-expense-buyout",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.expensePerCashbackVisible}
                        onChange={(e) =>
                            setExpensePerCashbackVisible(e.target.checked)
                        }
                    />
                    <div>Расход на кешбек</div>
                </div>
            ),
            key: "columns-expense-cashback",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.notesVibislbe}
                        onChange={(e) => setNotesVisible(e.target.checked)}
                    />
                    <div>Заметки</div>
                </div>
            ),
            key: "columns-notes",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.companyVisible}
                        onChange={(e) => setCompanyVisible(e.target.checked)}
                    />
                    <div>Компания</div>
                </div>
            ),
            key: "columns-company",
        },
        {
            label: (
                <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Checkbox
                        checked={filters.needToShipVisible}
                        onChange={(e) => setNeedToShipVisible(e.target.checked)}
                    />
                    <div>Запас товара на дней</div>
                </div>
            ),
            key: "columns-need-to-ship",
        },
    ];

    const getColumnFilterItems = () => {
        return columnsMenuItems.filter((menuItem, index) => {
            return (
                index === 0 ||
                columnSearchData[menuItem!.key as keyof filtersSearchData]
                    .toLowerCase()
                    .includes(columnSearchText.toLowerCase())
            );
        });
    };

    useEffect(() => {
        setAllColumnsVisible(allSelectedCheck());
    }, [
        productNameVisible,
        articleVisible,
        selfCostVisible,
        wbStorageRemainingVisible,
        storageRemainingWisible,
        productOnWayVisible,
        orderedInChinaVisible,
        avgOrdersVisible,
        avgSalesVisible,
        salesPercentVisible,
        commissionVisible,
        logisticVisible,
        storageVisible,
        adExpensesVisible,
        adLeftMoneyVisible,
        profitPerMonthVisible,
        salePerMonthVisible,
        currentPriceVisible,
        recommendedPriceVisible,
        expensePerBuyoutVisible,
        expensePerCashbackVisible,
        notesVibislbe,
        companyVisible,
        needToShipVisible,
    ]);

    return {
        getColumnFilterItems,
        filters,
    };
};
