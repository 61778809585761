import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import authSlice, { AuthState } from "store/authSlice";

export function useAuthStore() {
    const state = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const login = useCallback(
        (user: AuthState["user"]) => {
            const action = authSlice.actions.login(user);
            dispatch(action);
        },
        [dispatch]
    );

    const logout = useCallback(() => {
        const action = authSlice.actions.logout();
        dispatch(action);
    }, [dispatch]);

    return {
        state,
        actions: {
            login,
            logout,
        },
    };
}
