import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useSellerDelete} from "../../sellers/hooks/useSellerDelete";
import {useDeclarationUpdate} from "../../kiz/hooks/declaratiion/useDeclarationUpdate";
import {useGuideUpdate} from "../hooks/guide/useGuideUpdate";
import useDeclarationDataBuId from "../../kiz/hooks/declaratiion/useDeclarationById";
import useGuideById from "../hooks/guide/useGuideById";
import {LoadingOutlined} from "@ant-design/icons";

export default function EditGuide({onClose, id}: any) {

    const [form] = Form.useForm();

    const {
        handleUpdate,
        isError,
        errorMessage,
        isLoading,
        isSuccess,
    } = useGuideUpdate();

    const {
        guideData,
        setId,
        isLoading: isLoadingGet,
    } = useGuideById();

    const onSendData = (values: any) =>{
        var body: any = {}

        if (guideData?.name !== values.name){
            body['name'] = values.name
        }
        if (guideData?.description !== values.description){
            body['description'] = values.description
        }
        handleUpdate(body, id)
    }

    useEffect(() => {
        form.setFieldsValue({
            name: guideData?.name,
            description: guideData?.description
        })
    }, [id, guideData])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно изменили тип оплаты')
            onClose()
        }
    }, [isSuccess])

    useEffect(() =>{
        setId(id)
    },[id])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактирование типа оплаты"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    {isLoadingGet
                        ?
                        <div
                            style={{
                                padding: "120px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        <Form layout={"vertical"} onFinish={(values) => onSendData(values)} form={form}>
                            <Form.Item
                                label={"Введите название типа оплаты"}
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={"Введите описание для данного типа оплаты"}
                                name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Col style={{
                                display: 'flex',
                                gap: 10
                            }}>
                                <Button
                                    loading={isLoading}
                                    type={"primary"}
                                    className={"button"}
                                    htmlType={"submit"}
                                    style={{
                                        fontSize: "12px",
                                        width: "50%",
                                    }}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    onClick={onClose}
                                    className={"button"}
                                    type={"primary"}
                                    ghost
                                    style={{
                                        fontSize: "12px",
                                        width: "50%",
                                    }}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Form>
                    }
                </Col>
            </Row>
        </div>
    );
}
