import React from 'react';
import {Button, Checkbox} from "antd";
import dayjs from "dayjs";
import {downloadFinRecordFile} from "../../../api/documentMirror";

const DocumentItem = ({item, onCheck}: any) => {
    return (
        <div className="document-page-items-elem">
            <div className="document-page-items-elem-type" style={{width: 200, minWidth: 200}}>
                <Checkbox style={{marginRight: 10}} onChange={() => onCheck(item?.id)} checked={item?.isChecked}/>
                <div>
                    {item?.category?.title}
                </div>
            </div>
            <div className="document-page-items-elem-name" style={{width: 350, minWidth: 350}}>
                {item?.title}
            </div>
            <div className="document-page-items-elem-number" style={{width: 150, minWidth: 150}}>
                {item?.documentId}
            </div>
            <div className="document-page-items-elem-date" style={{width: 150, minWidth: 150}}>
                {dayjs(item?.docCreatedAt).format("DD.MM.YYYY")}
            </div>
            <div className="document-page-title-elem-button" style={{width: '100%'}}>
                <Button
                    onClick={() =>
                        downloadFinRecordFile(`${process.env.REACT_APP_API_URL}documents/file/${item?.id}`, item?.id)
                    }
                    className={"button"}
                    type={"primary"}
                    style={{width: "100px"}}
                >
                    Скачать
                </Button>
            </div>
        </div>
    );
};

export default DocumentItem;