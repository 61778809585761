import React from 'react'
import { Card, Col } from 'antd'

const SettingItem = ({ item }: any) => {
	return (
		<Card
			className='document-page-card'
			style={{ width: '100%', marginBottom: '16px' }}
		>
			<div className='document-page-card-row'>
				<strong>Полное наименование продавца: </strong>
				<span>{item?.fullName}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Краткое наименование продавца: </strong>
				<span>{item?.shortName}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Название магазина: </strong>
				<span>{item?.shopName}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>ИНН: </strong>
				<span>{item?.inn}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>КПП: </strong>
				<span>{item?.kpp}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>БИН: </strong>
				<span>{item?.bin || 'Не указано'}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Генеральный директор: </strong>
				<span>{item?.general}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Юридический адрес: </strong>
				<span>{item?.legalAddress}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Регион: </strong>
				<span>{item?.region}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Банк: </strong>
				<span>{item?.bankName}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>БИК: </strong>
				<span>{item?.bik}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Корреспондентский счет: </strong>
				<span>{item?.transferCorrespondentAccount || 'Не указано'}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Расчетный счет: </strong>
				<span>{item?.operatingAccount}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>SWIFT-код: </strong>
				<span>{item?.swiftCode || 'Не указано'}</span>
			</div>
			<div className='document-page-card-row'>
				<strong>Форма организации: </strong>
				<span>
					{item?.legalForm === 15 ? 'Организация без НДС' : 'Другая форма'}
				</span>
			</div>
		</Card>
	)
}

export default SettingItem
