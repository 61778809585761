import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useAnalyticsCashbackCreate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: create,
        isLoading: createLoading,
        isError,
        ...options
    } = useMutation(backend.analytics.createAnalyticsCashback, {
        async onSuccess() {
            await Promise.all([
                queryClient.invalidateQueries([`CASHBACK_RESULT_LIST`]),
            ]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, handleCloseModal?: any) => {
            try {
                let body = { ...values };
                await create(body);
                if (handleCloseModal) {
                    handleCloseModal();
                }
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message || "Произошла ошибка при создании кешбэка");
            }
        },
        [create]
    );

    return {
        handleCreate,
        isLoading: createLoading,
        isError,
        errorMessage,
        ...options
    };
};
