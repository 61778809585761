import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as editIcon} from "../../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";

const data = [
    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },

    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },

    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },

    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },

    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },

    {
        art: 'test',
        size: '12',
        playCount: '12',
        factCount: '12'
    },


];

export default function ListOfShipmentFilesFullInfo() {

    const navigate = useNavigate()

    const columns = [
        {
            title: "Артикул",
            dataIndex: "art",
            key: "art",
            width: "23%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "23%",
        },
        {
            title: "Планируемое кол-во",
            dataIndex: "playCount",
            key: "playCount",
            width: "23%",
        },
        {
            title: "Фактическое кол-во",
            dataIndex: "factCount",
            key: "factCount",
            width: "23%",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        minHeight: 60,
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                    // onClick={() => {
                    //     setEditProductOpened(true);
                    //     setProductToEdit(record);
                    // }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions()}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_PAYMENT",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE_PAYMENT",
        }
    ];

    const getProductsActions = () => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_PAYMENT":
                        // setEditPaymentModal(true)
                        break;
                    case "DELETE_PAYMENT":
                        // setEditProductKeyWordsModal({
                        //     ...editProductAnalyticsModal,
                        //     productId: productId,
                        //     isOpen: true
                        // })
                        break;
                }
            },
        };
    };

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Col span={24} style={{
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate('/storage/list-of-shipment-files')}
                >
                    <Icon component={headerPrevBtn}/>
                    Назад
                </div>
            </Col>

            <Row className={"container"}>

                <Col span={24}>
                    <Table
                        // loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={data}
                        scroll={{x: true}}
                        pagination={{
                            // onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            // pageSize: Number(currentPageSize),
                            // total: Number(storageData?.total),
                            // showSizeChanger: false,
                            // current: currentPage,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
