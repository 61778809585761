import {useAuthStore} from "features/auth/hooks/useAuthStore";
import {useCashbackOperationUpdate} from "../hooks/useCashbackOperationUpdate";
import ConditionModificationModalContent from "../modal/ConditionModificationModalContent";
import {Modal} from "antd";
import {useState} from "react";
import ConditionModificationModalContentOperationSix from "../modal/ConditionModificationModalContentOperationSix";

interface PatricipantsTableRowProps {
    cashbackOperation: CashbackOperation;
}

export default function CashbacksParticipantsTableRow({
                                                          cashbackOperation,
                                                      }: PatricipantsTableRowProps) {
    const {state} = useAuthStore();
    const {handleUpdate} = useCashbackOperationUpdate();


    const [conditionModificationOpened, setConditionModificationOpened] = useState<any>({
        isOpen: false,
        action: 'deny',
        text: 'Доработка условия',
        id: null
    });

    const [conditionModificationSixOpened, setConditionModificationSixOpened] = useState<any>({
        isOpen: false,
        id: null
    });

    const changeOperationStatus = (action: string) => {
        const body = {
            action,
            analyticId: state.user.id,
        };
        handleUpdate(cashbackOperation.id, body);
    };


    const renderType = () => {
        if (cashbackOperation.isAgreed === true) {
            return (
                <div
                    className={"participants-agreed-col"}
                    style={{
                        background: "#28C76F",
                        width: "100%",
                        borderBottomRightRadius: "8px",
                    }}
                >
                    Согласовано
                </div>
            );
        }
        if (cashbackOperation.isAgreed === false) {
            return (
                <div
                    className={"participants-agreed-col"}
                    style={{
                        background: "#EA5455",
                        width: "100%",
                        borderBottomRightRadius: "8px",
                    }}
                >
                    Не согласовано
                </div>
            );
        }
        return (
            <>
                {cashbackOperation.condition === 6
                    ? <>
                        <div
                            className={"participants-agreed-col"}
                            style={{background: "#4C78EE", width: "100%", cursor: 'pointer'}}
                            onClick={() => setConditionModificationSixOpened({isOpen: true, id: cashbackOperation.id})}
                        >
                            Да
                        </div>
                    </>
                    :
                    <>
                        {" "}
                        <div
                            className={"participants-agreed-col"}
                            style={{background: "#28C76F", width: "25%", cursor: 'pointer'}}
                            // onClick={() => changeOperationStatus("accept")}
                            onClick={() => setConditionModificationOpened({
                                isOpen: true,
                                action: 'accept',
                                text: 'Комментарии для пользователя',
                                id: cashbackOperation.id
                            })}
                        >
                            Да
                        </div>
                        <div
                            className={"participants-agreed-col"}
                            style={{
                                background: "#EA5455",
                                width: "25%",
                                cursor: 'pointer'
                            }}
                            onClick={() => setConditionModificationOpened({
                                isOpen: true,
                                action: 'deny',
                                text: 'Почему мы прекращаем работу с пользователем',
                                id: cashbackOperation.id
                            })}
                        >
                            Нет
                        </div>
                        {" "}
                        <div
                            className={"participants-agreed-col"}
                            style={{
                                background: "#4C78EE",
                                width: "50%",
                                borderBottomRightRadius: "8px",
                                cursor: 'pointer'
                            }}
                            onClick={() => setConditionModificationOpened({
                                isOpen: true,
                                action: 're_do',
                                text: 'Доработка условия',
                                id: cashbackOperation.id
                            })}
                        >
                            Доработка
                        </div>
                        {" "}
                    </>
                }

            </>
        );
    };

    const renderConditionLink = () => {
        let href: any = "";
        if (cashbackOperation.condition === 1) {
            href = cashbackOperation.contract.condition1Url;
        }
        if (cashbackOperation.condition === 2) {
            href = cashbackOperation.contract.condition2Url;
        }
        if (cashbackOperation.condition === 3) {
            href = cashbackOperation.contract.condition3Url;
        }
        if (cashbackOperation.condition === 4) {
            href = cashbackOperation.contract.condition4Url;
        }
        if (cashbackOperation.condition === 5) {
            href = cashbackOperation.contract.condition5Url;
        }

        // console.log('href')
        // console.log(href)

        return (
            <>
                {cashbackOperation.condition === 6
                    ? <div style={{color: '#000'}}>---</div>
                    : href?.length !== 0
                        ?
                        href?.map((item: any) =>
                            <a
                                style={{
                                    textDecorationLine: "underline",
                                    color: "#4C78EE",
                                    cursor: "pointer",
                                }}
                                href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                Ссылка
                            </a>
                        )

                        :
                        'Нет данные'
                }
            </>

        );
    };


    return (
        <div
            style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
            }}
        >
            <div
                className={"cashbacks-participants-header-container"}
                style={{borderRadius: "8px"}}
            >
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%", borderTopLeftRadius: "8px"}}
                >
                    № Заявки
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%"}}
                >
                    Название акции
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%"}}
                >
                    Артикул WB
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%"}}
                >
                    Размер
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%"}}
                >
                    Телеграм участника
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%", borderTopRightRadius: "8px"}}
                >
                    Номер телефона
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%", borderTopRightRadius: "8px"}}
                >
                    Реквизиты
                </div>
            </div>
            <div
                className={"cashbacks-participants-body-container"}
                style={{minHeight: "46px"}}
            >
                <div className={"cashbacks-participants-col"} style={{width: "11%"}}>
                    {cashbackOperation.id}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackAction.name}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackAction.nmId}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackItem.size}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackUser.tgNickname}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackUser.phoneNumber}
                </div>
                <div className={"cashbacks-participants-col"} style={{width: "18%"}}>
                    {cashbackOperation.contract.cashbackUser.creditCartNumber}
                </div>
            </div>
            <div className={"cashbacks-participants-header-container"}>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "9%"}}
                >
                    № Этапа
                </div>
                {
                    cashbackOperation.condition === 6
                        ?
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "73%"}}
                        />
                        :
                        <>
                            <div
                                className={"cashbacks-participants-heading"}
                                style={{width: "44%"}}
                            >
                                Выполнение условия
                            </div>
                            <div
                                className={"cashbacks-participants-heading"}
                                style={{width: "12%"}}
                            >
                                Уведомления
                            </div>
                            <div
                                className={"cashbacks-participants-heading"}
                                style={{width: "17%"}}
                            >
                                Подтверждения выполнения усл 1
                            </div>
                        </>
                }

                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "18%"}}
                >
                    Согласовать?
                </div>
            </div>
            <div
                className={"cashbacks-participants-body-container"}
                style={{minHeight: "25px", borderRadius: "8px", padding: '10px 0 '}}
            >
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "9%", borderBottomLeftRadius: "8px"}}
                >
                    {cashbackOperation.condition}
                </div>
                {
                    cashbackOperation.condition === 6
                        ?
                        <div className={"cashbacks-participants-col"} style={{width: "73%"}}/>
                        :
                        <>
                            <div className={"cashbacks-participants-col"} style={{width: "44%"}}>
                                {cashbackOperation.userMessageText || '---'}
                            </div>
                            <div
                                className={"cashbacks-participants-col"}
                                style={{
                                    width: "12%",
                                    color: "#4C78EE",
                                    cursor: "pointer",
                                }}
                            >
                                ---
                            </div>
                            <div
                                className={"cashbacks-participants-col"}
                                style={{
                                    width: "17%",
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {renderConditionLink()}
                            </div>
                        </>
                }
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "18%", borderBottomRightRadius: "8px"}}
                >
                    <div className={"participants-yes-no-container"}>{renderType()}</div>
                </div>
            </div>
            <Modal
                open={conditionModificationOpened.isOpen}
                closable={false}
                footer={null}
                width={"fit-content"}
            >
                <ConditionModificationModalContent
                    text={conditionModificationOpened.text}
                    operationId={conditionModificationOpened.id}
                    action={conditionModificationOpened.action}
                    onClose={() => setConditionModificationOpened({isOpen: false, action: null, id: null})}
                />
            </Modal>

            <Modal
                open={conditionModificationSixOpened.isOpen}
                closable={false}
                footer={null}
                width={"fit-content"}
            >
                <ConditionModificationModalContentOperationSix
                    operationId={conditionModificationSixOpened.id}
                    onClose={() => setConditionModificationSixOpened({isOpen: false, id: null})}
                />
            </Modal>
        </div>
    );
}
