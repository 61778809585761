import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useSellerCreate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: create,
        isLoading: createLoading,
        isError,
    } = useMutation(backend.seller.createSeller, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`SELLER_LIST`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, handleCloseModal: any) => {
            try {
                let body = { ...values };
                body.userId = parseInt(values.userId);
                await create(body);
                handleCloseModal();
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при создании пользователя");
            }
        },
        [create]
    );

    return {
        handleCreate,
        isLoading: createLoading,
        isError,
        errorMessage,
    };
};
