import React, {useEffect, useRef, useState} from "react";
import {
    AutoComplete,
    Button,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table, Upload, UploadProps,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import CreateTaskModal from "../../modal/tasks/CreateTaskModal";

const {RangePicker} = DatePicker;

const data = [
    {
        taskName: '124235234312',
        type: 'Приемка',
        owner: 'Кладовщик',
        owner2: 'И.О.Роман',
        dateCreate: '12.12.2000',
        dateCome: '12.12.2000',
        dateEnd: '12.12.2000',
        desc: 'Текст',
        comment: 'Текст',
    }
]

export default function TasksTable() {

    const navigate = useNavigate()

    const [tabNav, setTabNav] = useState('status1')

    const [createTaskModal, setCreateTaskModal] = useState<any>(false);

    const columns = [
        {
            title: "Название задачи",
            dataIndex: "taskName",
            key: "taskName",
            width: "11%",
        },
        {
            title: "Тип задачи",
            dataIndex: "type",
            key: "type",
            width: "11%",
        },
        {
            title: "Исполнитель",
            dataIndex: "owner",
            key: "owner",
            width: "11%",
        },
        {
            title: "Ответственный",
            dataIndex: "owner2",
            key: "owner2",
            width: "11%",
        },
        {
            title: "Дата созданиязадачи",
            dataIndex: "dateCreate",
            key: "dateCreate",
            width: "11%",
        },
        {
            title: "Дата принятия задачи",
            dataIndex: "dateCome",
            key: "dateCome",
            width: "11%",
        },
        {
            title: "Дата завер-ния задачи",
            dataIndex: "dateEnd",
            key: "dateEnd",
            width: "11%",
        },
        {
            title: "Описание",
            dataIndex: "desc",
            key: "desc",
            width: "11%",
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
            key: "comment",
            width: "11%",
        },
    ];

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={title}
                        // onChange={(text: any) => setTitle(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск"
                        size="large"
                    />
                </Col>
                <Col style={{width: "140px", paddingTop: 0, paddingBottom: 0, pointerEvents: 'none'}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        // menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по типу</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "200px", paddingTop: 0, paddingBottom: 0, pointerEvents: 'none'}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        // menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по исполнителю</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        // onChange={(e) => {
                        //     setCurrentPage(1);
                        //     setCurrentPageSize(e);
                        // }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>

            <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                    padding: "5px 20px 5px 30px",
                    marginTop: 20,
                    backgroundColor: '#fff'
                }}
            >
                <div className={"storage-tabs"} style={{}}>
                    <div
                        className={`${
                            tabNav === 'status1' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status1')}
                    >
                        Все
                    </div>
                    <div
                        className={`${
                            tabNav === 'status2' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status2')}
                    >
                        Активные
                    </div>
                    <div
                        className={`${
                            tabNav === 'status3' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status3')}
                    >
                        Архивные
                    </div>
                </div>

                <Col>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "190px",
                            fontSize: "12px",
                        }}
                        onClick={() => setCreateTaskModal(true)}
                    >
                        Добавить задачу
                    </Button>
                </Col>
            </Row>


            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        // loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        dataSource={data || []}
                        scroll={{x: true}}
                        pagination={{
                            // onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            // pageSize: Number(currentPageSize),
                            // total: Number(declaration?.total),
                            // showSizeChanger: false,
                            // current: currentPage,
                        }}
                    />
                </Col>
            </Row>

            <Modal
                open={createTaskModal}
                closable={false}
                footer={null}
                width={600}
            >
                <CreateTaskModal
                    onClose={() => setCreateTaskModal(false)}
                />
            </Modal>

        </div>
    );
}
