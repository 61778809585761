import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const usePostProductArrival = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: createDeclaration,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
    } = useMutation(backend.storage.createProductArrival, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`PRODUCTS_ARRIVAL_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, gtdId: any) => {
            try {
                let body = { values, gtdId };
                await createDeclaration(body);
                queryClient.invalidateQueries(['PRODUCTS_ARRIVAL_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message || e?.response?.data?.detail);
            }
        },
        [createDeclaration]
    );

    return {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    };
};
