import { Col, Row } from 'antd'
import React from 'react'
import SettingsMirrorApiAccess from 'features/settingsMirror/SettingsMirrorApiAccess'

export default function SettingsMirrorApiAccessPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<SettingsMirrorApiAccess />
			</Col>
		</Row>
	)
}
