import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { backend } from "../../../api";

export const useUserDelete = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, ...options } = useMutation(backend.user.deleteUser, {
        onSuccess: async () => {
            await Promise.all([queryClient.invalidateQueries(["USER_LIST"])]);
        },
    });

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateAsync(id);
        },
        [mutateAsync]
    );

    return {
        handleDelete,
        ...options,
    };
};
