import { Button, Col, Row, Table, DatePicker, Radio } from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import { getTypeText } from "../../utils/heplers";
import filterIcon from "assets/images/filter-icon.svg";
import { useEffect, useState } from "react";
import {
    cardStatisticsData,
    catalogueStatisticsData,
    companyItemsData,
    searchStatisticsData,
} from "../../utils/data";
import { ReactComponent as headerPrevBtn } from "assets/images/header-prev-btn.svg";
import { ReactComponent as rangePickerArrow } from "assets/images/rangepicker-arrow.svg";
import { ReactComponent as chartIcon } from "assets/images/presentation-chart-icon.svg";
import { ReactComponent as downloadIconButton } from "assets/images/download-icon-btn.svg";

const { RangePicker } = DatePicker;

interface CompanyStatisticsModalProps {
    company: any;
    onClose: () => void;
}

const searchColumns = [
    {
        title: "Ключевая фраза",
        dataIndex: "keyword",
        key: "keyword",
        width: "131px",
    },
    {
        title: "Просмотры",
        dataIndex: "views",
        key: "views",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Частота",
        dataIndex: "frequency",
        key: "frequency",
        width: "95px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Клики",
        dataIndex: "clicks",
        key: "clicks",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CTR, %",
        dataIndex: "ctr",
        key: "ctr",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CPC",
        dataIndex: "cpc",
        key: "cpc",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Затраты, руб",
        dataIndex: "costs",
        key: "costs",
        width: "109px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
];

const catalogueColumns = [
    {
        title: "Категория",
        dataIndex: "category",
        key: "category",
        width: "131px",
    },
    {
        title: "Просмотры",
        dataIndex: "views",
        key: "views",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Частота",
        dataIndex: "frequency",
        key: "frequency",
        width: "95px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Клики",
        dataIndex: "clicks",
        key: "clicks",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CTR, %",
        dataIndex: "ctr",
        key: "ctr",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CPC",
        dataIndex: "cpc",
        key: "cpc",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Затраты, руб",
        dataIndex: "costs",
        key: "costs",
        width: "109px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
];

const cardColumns = [
    {
        title: "Карточка",
        dataIndex: "card",
        key: "card",
        width: "131px",
    },
    {
        title: "Просмотры",
        dataIndex: "views",
        key: "views",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Частота",
        dataIndex: "frequency",
        key: "frequency",
        width: "95px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Клики",
        dataIndex: "clicks",
        key: "clicks",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CTR, %",
        dataIndex: "ctr",
        key: "ctr",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "CPC",
        dataIndex: "cpc",
        key: "cpc",
        width: "102px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
    {
        title: "Затраты, руб",
        dataIndex: "costs",
        key: "costs",
        width: "109px",
        filterIcon: <img src={filterIcon} />,
        filters: [],
    },
];

const companyItemsColumns = [
    {
        title: "Фото",
        dataIndex: "photo",
        key: "photo",
        width: "73px",
        render: (text: any, record: any) => {
            return (
                <div style={{ textAlign: "center" }}>
                    <img src={text} style={{ width: "28px" }}></img>{" "}
                </div>
            );
        },
    },
    {
        title: "Название",
        dataIndex: "title",
        key: "title",
        width: "140px",
    },
    {
        title: "Просмотры",
        dataIndex: "views",
        key: "views",
        width: "78px",
    },
    {
        title: "Частота",
        dataIndex: "frequency",
        key: "frequency",
        width: "63px",
    },
    {
        title: "Клики",
        dataIndex: "clicks",
        key: "clicks",
        width: "63px",
    },
    {
        title: "CTR,%",
        dataIndex: "ctr",
        key: "ctr",
        width: "53px",
    },
    {
        title: "CPC",
        dataIndex: "cpc",
        key: "cpc",
        width: "53px",
    },
    {
        title: "Добавл. в корзину",
        dataIndex: "bucketAdded",
        key: "bucketAdded",
        width: "86px",
    },
    {
        title: "Оформ. заказа",
        dataIndex: "ordered",
        key: "ordered",
        width: "71px",
    },
    {
        title: "Сумма",
        dataIndex: "sum",
        key: "sum",
        width: "63px",
    },
];

export default function CompanyStatisticsModalContent({
    company,
    onClose,
}: CompanyStatisticsModalProps) {
    const [detailedMode, setDetailedMode] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(searchStatisticsData);
    const [selectedColumns, setSelecteedColumns] = useState<any>(searchColumns);

    useEffect(() => {
        if (company.type === "card") {
            setSelecteedColumns(cardColumns);
            setSelectedData(cardStatisticsData);
        } else if (company.type === "catalogue") {
            setSelecteedColumns(catalogueColumns);
            setSelectedData(catalogueStatisticsData);
        } else {
            setSelecteedColumns(searchColumns);
            setSelectedData(searchStatisticsData);
        }
    }, [company]);

    const renderDefaultStatistics = () => {
        return (
            <>
                {" "}
                <div>
                    <Table
                        className={"statistics-table"}
                        columns={selectedColumns}
                        dataSource={selectedData}
                        rowClassName={(record: any, index: any) => {
                            if (index === 0) {
                                return "first-row";
                            }
                            return "";
                        }}
                        pagination={{ position: ["bottomCenter"] }}
                    />
                </div>
                <Row justify={"space-between"} align={"middle"}>
                    <Col>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{ width: "211px" }}
                        >
                            Понятно
                        </Button>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{ width: "211px", marginLeft: "15px" }}
                            ghost
                        >
                            Обновить данные
                        </Button>
                    </Col>
                    <Col>
                        <div
                            className={"detail-statistics-button"}
                            onClick={() => setDetailedMode(true)}
                        >
                            Детальная статистика
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

    const renderDetailedStatistics = () => {
        return (
            <div>
                <div
                    className={"back-button-header"}
                    onClick={() => setDetailedMode(false)}
                >
                    <Icon component={headerPrevBtn} />
                    Назад
                </div>
                <div className="total-income-container">
                    <div className={"total-income"}>
                        Общий доход - <span>1500 ₽</span>
                    </div>
                    <div className={"total-income"}>
                        Общий доход - <span>1500 ₽</span>
                    </div>
                </div>
                <div className={"detailed-statistics-data-container"}>
                    <Row
                        className={"detaied-statistics-data-header"}
                        justify={"space-between"}
                        align={"middle"}
                    >
                        <Col className={"view-clicks-container"}>
                            <div className={"view-click-active"}>
                                <div>Просмотры</div>
                                <div className={"view-click-value"}>2590</div>
                            </div>
                            <div>
                                <div>Клики</div>
                                <div className={"view-click-value"}>44</div>
                            </div>
                        </Col>
                        <Col>
                            <RangePicker
                                separator={
                                    <Icon component={rangePickerArrow} />
                                }
                                format={"DD.MM.YY"}
                                style={{ width: "177px" }}
                                placeholder={["Начало", "Конец"]}
                            />
                        </Col>
                    </Row>
                    <div className={"statistics-radio-container"}>
                        <Radio.Group>
                            <Radio value={"all"}>Все</Radio>
                            <Radio value={"web"}>Web</Radio>
                            <Radio value={"ios"}>IOS</Radio>
                            <Radio value={"android"}>Android</Radio>
                        </Radio.Group>
                    </div>
                    <div className={"keyword-chart-message-wrapper"}>
                        <div style={{ textAlign: "center" }}>
                            <Icon
                                component={chartIcon}
                                style={{ fontSize: "100px" }}
                            />
                        </div>
                    </div>
                </div>
                <div className={"company-items-wrapper"}>
                    <Row
                        className={"company-items-header"}
                        justify={"space-between"}
                        align={"middle"}
                    >
                        <Col className={"company-items-table-title"}>
                            Предметы компании
                        </Col>
                        <Col className={"company-items-table-excel"}>
                            <Icon
                                component={downloadIconButton}
                                style={{ fontSize: "18px" }}
                            />{" "}
                            <span className={"download-excel-button"}>
                                Скачать в Excel
                            </span>
                        </Col>
                    </Row>
                    <Table
                        className={"statistics-items-table"}
                        columns={companyItemsColumns}
                        dataSource={companyItemsData}
                        rowClassName={(record: any, index: any) => {
                            if (index === 0) {
                                return "first-row";
                            }
                            return "";
                        }}
                        pagination={{ position: ["bottomCenter"] }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={"modal-wrapper"} style={{ padding: "30px" }}>
            <div>
                <ModalHeader
                    title={`Статистика компании “${
                        company.company
                    }” ${getTypeText(company.type).toLocaleLowerCase()}`}
                    onClose={onClose}
                />
            </div>
            {!detailedMode
                ? renderDefaultStatistics()
                : renderDetailedStatistics()}
        </div>
    );
}
