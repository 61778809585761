import {useCallback, useState} from "react";
import { useMutation, useQueryClient } from "react-query";
import {backend} from "api";
import {apiKizFullInfo} from "../../../index";

export const useCashBackDelete = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<any>("");

    const { mutateAsync, ...options } = useMutation(
        backend.analytics.deleteCashBack,
        {
            onSuccess: async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["CASHBACK_RESULT_LIST"]),
                ]);
            },
            onError: async (e: any) => {
                setErrorMessage(e?.response?.data?.detail || e?.response?.data?.message)
            },
        }
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateAsync(id);
        },
        [mutateAsync]
    );

    return {
        handleDelete,
        errorMessage,
        ...options,
    };
};


