import React, {useEffect, useState} from "react";
import {Checkbox, Input, Menu, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import {AnalyticTableColumnFilter} from "../../../analytics/hooks/useAnalyticsTableColumnFilters";
import dayjs from "dayjs";

export const useFilteredDeclarationFullInfoColumns = () => {

    const columns = [
        {
            title: "ФИО Кладовшика",
            dataIndex: "declaration_file_uploader",
            key: "declaration_file_uploader",
            width: "8%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        {text || '---'}
                    </div>
                )
            }
        },
        {
            title: "Артикул кит",
            dataIndex: "article_china",
            key: "article_china",
            width: "8%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "4%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        {record?.size?.ru_size}
                    </div>
                )
            }
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            key: "count",
            width: "4%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        1
                    </div>
                )
            }
        },
        {
            title: "box_code",
            dataIndex: "box_code",
            key: "box_code",
            width: "12%",
        },
        {
            title: "Имя импортера",
            dataIndex: "importer_name",
            key: "importer_name",
            width: "12%",
        },
        {
            title: "Имя продукта",
            dataIndex: "name",
            key: "name",
            width: "12%",
        },
        {
            title: "Отсканирован",
            dataIndex: "has_added",
            key: "has_added",
            width: "6%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        {text ? 'Да' : 'Нет' }
                    </div>
                )
            }
        },
        {
            title: "Растаможен",
            dataIndex: "is_customed",
            key: "is_customed",
            width: "6%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        {text ? 'Да' : 'Нет' }
                    </div>
                )
            }
        },
        // {
        //     title: "Дата загрузки эмиссии",
        //     dataIndex: "picked_at",
        //     key: "picked_at",
        //     width: "8%",
        //     render: (text: any, record: any) => {
        //         return (
        //             <div
        //                 style={{
        //                     cursor: 'pointer'
        //                 }}
        //             >
        //                 {text
        //                     ? dayjs(text).format("DD.MM.YYYY HH:mm")
        //                     : '---'
        //                 }
        //             </div>
        //         )
        //     }
        // },
        {
            title: "Дата создания",
            dataIndex: "created_at",
            key: "created_at",
            width: "8%",
            render: (text: any, record: any) => {

                return (
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Дата обновления",
            dataIndex: "updated_at",
            key: "updated_at",
            width: "8%",
            render: (text: any, record: any) => {

                return (
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
    ];

    const [selectAll, setSelectAll] = useState(true);
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState(
        columns.map((column) => column.key) // Выбираем все столбцы по умолчанию
    );

    const handleColumnSelection = ({ key, checked }: any) => {
        if (checked) {
            // Если отмечен, добавляем столбец в выбранные
            setSelectedColumns((prevSelectedColumns) => [...prevSelectedColumns, key]);
        } else {
            // Если снят флажок, удаляем столбец из выбранных
            setSelectedColumns((prevSelectedColumns) =>
                prevSelectedColumns.filter((columnKey) => columnKey !== key)
            );
        }

        if ((selectedColumns.length === columns.map((column) => column.key).length) && selectedColumns.length === 0){
            setIndeterminate(false)
            setSelectAll(true)
        } else {
            setIndeterminate(true)
            setSelectAll(false)
        }
    };

    const handleSelectAll = (checked: any) => {
        if (selectedColumns.length === columns.map((column) => column.key).length) {
            setSelectedColumns([])
            setSelectAll(false)
            setIndeterminate(false)
        } else if(selectedColumns.length < columns.map((column) => column.key).length) {
            setSelectedColumns(columns.map((column) => column.key))
            setSelectAll(true)
            setIndeterminate(false)
        } else {
            setSelectAll(false)
            setIndeterminate(true)
        }
    };

    const getMenu = () => {
        return (
            <Menu>
                <Menu.Item key={'all'}>
                    <Checkbox
                        indeterminate={indeterminate}
                        checked={selectAll}
                        onChange={(event) =>
                            handleSelectAll(event.target.checked)
                        }
                    >
                        Все
                    </Checkbox>
                </Menu.Item>
                {columns.map((column) => (
                    <Menu.Item key={column.key}>
                        <Checkbox
                            checked={selectedColumns.includes(column.key)}
                            onChange={(event) =>
                                handleColumnSelection({ key: column.key, checked: event.target.checked })
                            }
                        >
                            {column.title}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const filteredColumns = columns.filter((column) => selectedColumns.includes(column.key));


    return {
        getMenu,
        filteredColumns,
    };
};