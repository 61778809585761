import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Select, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";
import Message from "./components/Message";
import EmptyMessages from "./components/EmptyMessages";
import useChatTypesData from "../chat/hooks/useChatTypesData";
import useChatByType from "../chat/hooks/useChatByType";
import {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useAuthStore} from "../auth/hooks/useAuthStore";
import useMessagesByChat from "../chat/hooks/useMessagesByChat";
import {usePostMessageToChat} from "../chat/hooks/usePostMessageToChat";

const {Option} = Select;

export const QuestionsAndAnswersBlock = () => {

    const {state} = useAuthStore();

    // console.log(state?.user?.role?.name)

    const [textToSend, setTextToSend] = useState('')

    const {
        chats
    } = useChatByType(1)

    const {
        messages,
        selectedChat,
        setSelectedChat,
        isLoading: isLoadingMessages
    } = useMessagesByChat()

    const {
        handleCreate,
        errorMessage,
        isLoading,
        isSuccess,
        isError,
    } = usePostMessageToChat()

    const onSendMessage = () => {
        console.log({
            chatId: selectedChat,
            userId: state?.user?.id,
            text: textToSend
        })
        handleCreate({
            chatId: selectedChat,
            userId: state?.user?.id,
            text: textToSend
        })
    }

    useEffect(() => {
        setSelectedChat(chats?.data?.[0]?.id)
    }, [chats])


    useEffect(() => {
        if (isSuccess) {
            setTextToSend('')
        }
        if (isError) {
            messages.error(errorMessage)
        }
    }, [isSuccess, isError])


    return (
        <>
            <Col
                span={24}
                style={{
                    marginBottom: 10,
                }}
            >
                <Select
                    className="custom-select" // Используем свой собственный CSS-класс
                    style={{
                        width: '100%',
                    }}
                    showSearch
                    value={selectedChat}
                    placeholder="Инвестор"
                    filterOption={false}
                    suffixIcon={<Icon
                        component={SelectIconComponent}
                        style={{marginTop: "2px", fontSize: "10px"}}
                    />}
                    onChange={(e: any, option: any) => {
                        setSelectedChat(e)
                    }}
                >
                    {chats?.data?.map((option: any) => {
                        return (
                            <Option key={option?.id} value={option?.id}>
                                {`${option.investor?.firstName} ${option.investor?.lastName}`}
                            </Option>
                        );
                    })}
                </Select>
            </Col>
            <div className="innovation-proposals-wrap">
                <div
                    className="innovation-proposals-top"
                    style={{
                        display: messages?.data?.length == 0 || !messages?.data || isLoadingMessages ? 'flex' : 'block',
                        justifyContent: 'center',
                        alignItems: messages?.data?.length == 0 || !messages?.data || isLoadingMessages ? 'center' : 'flex-start',
                        width: '100%'
                    }}
                >
                    {
                        isLoadingMessages
                            ?
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                            :
                            messages?.data?.length === 0 || !messages?.data
                                ? <EmptyMessages/>
                                :
                                messages?.data?.map((item: any, index: any) =>
                                    <Message
                                        data={item}
                                        isLastElement={index === messages?.data?.length - 1}
                                        isOwnMessage={state?.user?.id === item?.user?.id}
                                    />
                                )
                    }
                </div>

                {
                    selectedChat &&
                    <div className="innovation-proposals-bottom">
                        <h1 className="innovation-proposals-bottom-title">
                            Оставить сообщение
                        </h1>
                        <TextArea
                            value={textToSend}
                            onChange={(e) => setTextToSend(e?.target?.value)}
                            style={{
                                resize: 'none',
                                height: "auto !important",
                                marginBottom: 20,
                                borderColor: '#BABFC7',
                                backgroundColor: '#D9D9D9'
                            }}
                            rows={5}
                        />
                        <div className="innovation-proposals-bottom-button-wrap">
                            <Button
                                loading={isLoading}
                                disabled={!textToSend}
                                onClick={onSendMessage}
                                type={"primary"}
                                className={"innovation-proposals-bottom-button-button"}
                                style={{
                                    maxWidth: 281,
                                    width: '100%',
                                    backgroundColor: '#5C4E73',
                                    color: !textToSend ? '#c4c4c4' : '#fff'
                                }}
                                htmlType={"submit"}
                            >
                                Отправить
                            </Button>
                        </div>
                    </div>
                }

            </div>
        </>
    );
};

