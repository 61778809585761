import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function useBiderDataById(productId: any) {

    const { data: biderData, ...options } = useQuery(
        ["BIDER_DATA_BY_ID", productId],
        () =>
            backend.keyWords.getBiderDataById(
                productId.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        biderData,
        ...options,
    };
}
