import {useQuery} from "react-query";
import {backend} from "api";
import {useState} from "react";

export default function useMessagesByChat() {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(100)
    const [selectedChat, setSelectedChat] = useState(null)

    const {data: messages, ...options} = useQuery(
        [
            "CHAT_MESSAGES_DATA",
            selectedChat,
            page,
            limit
        ],
        () =>
            backend.chat.getMessagesByChat(
                selectedChat,
                page,
                limit
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        messages,
        page,
        limit,
        selectedChat,
        setPage,
        setLimit,
        setSelectedChat,
        ...options,
    };
}
