import {api, apiKiz} from "index";

export async function getExpensesData(
    page?: string,
    pageSize?: string,
    debouncedText?: string,
    sortType?: string,
    sortParam?: string
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (debouncedText) {
        params.append("search", debouncedText);
    }
    if (sortType && sortParam){
        params.append("filter_by", sortParam);
        params.append("order_by", sortType);
    }

    const response = await api.get<any>(`/operation-expences`, {
        params,
    });

    return response.data.data;
}

export async function getExpensesDataById(
    id: string,
) {
    const response = await api.get<any>(`/operation-expences/${id}`);
    return response.data.data;
}


export async function createExpenses(json: any) {
    const response = await api.post<any>(`/operation-expences`, json);
    return response.data;
}

export async function updateExpenses(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(`/operation-expences/${params.id}`, body);
    return response.data;
}

export async function deleteExpenses(id: any) {
    const response = await api.delete<any>(`/operation-expences/${id}`);
    return response.data;
}

export async function getGuide(
    page?: string,
    pageSize?: string,
    debouncedText?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (debouncedText) {
        params.append("search", debouncedText);
    }

    const response = await api.get<any>(`/operation-types`, {
        params,
    });

    return response.data.data;
}

export async function getGuideDataById(
    id: string,
) {
    const response = await api.get<any>(`/operation-types/${id}`);
    return response.data.data;
}

export async function createGuide(json: any) {
    const response = await api.post<any>(`/operation-types`, json);
    return response.data;
}

export async function updateGuide(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(`/operation-types/${params.id}`, body);
    return response.data;
}

export async function deleteGuide(id: any) {
    const response = await api.delete<any>(`/operation-types/${id}`);
    return response.data;
}

export async function getGuideList(
    search?: string
) {

    let params = new URLSearchParams();
    if (search) {
        params.append("search", search);
    }

    const response = await api.get<any>(`/operation-types/list`, {
        params,
    });

    return response.data.data;
}
