import React, {useEffect, useState} from 'react';
import {Button, Col, message, Modal, Pagination, Row, Spin, Table} from "antd";
import burgerIcon from '../../../../assets/images/burgerIcon.svg'
import {useNavigate} from "react-router-dom";
import AddDeclarationModal from "../../modal/declaration/AddDeclarationModal";
import SendToTSDModal from "../../modal/listOfAcceptanceFiles/SendToTSDModal";
import useListOfAcceptanceData from "../../hooks/listOfAcceptanceFiles/useListOfAcceptanceData";
import DownloadButton from "../../components/DownloadButton";
import FileUploader from "../../components/FileUploader";
import {LoadingOutlined} from "@ant-design/icons";
import {useDeleteItemAcceptance} from "../../hooks/listOfAcceptanceFiles/useDeleteItemAcceptance";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

const ListOfAcceptanceFilesTable = ({tab, setTab}: any) => {

    const [tabNav, setTabNav] = useState('status1')

    const navigate = useNavigate();

    const [sendToTSDModal, setSendToTSDModal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const {
        acceptances,
        isLoading,
    } = useListOfAcceptanceData()

    const {
        handleDelete,
        errorMessage,
        isError: isErrorDelete,
        isSuccess: isSuccessDelete,
        isLoading: isLoadingDelete,
    } = useDeleteItemAcceptance()

    useEffect(() =>{
        if (isSuccessDelete){
            message.success('Операция выполненная успешно')
        }
        if (isErrorDelete){
            message.error(errorMessage)
        }
    },[isErrorDelete, isSuccessDelete])

    const renderStatus1 = () => {

        if (isLoading){
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            )
        }
        if (acceptances?.result && acceptances?.result !== 0) {
            return (
                <div className="saved-invoices-wrap">
                    {
                        acceptances?.result?.map((item: any) =>
                            <div className="saved-invoices-item">
                                <div
                                    onClick={() => navigate('/storage/list-of-acceptance-files/full-info/123')}
                                    className="saved-invoices-item-left"
                                >
                                    <img src={burgerIcon} alt=""/>
                                    <div className="saved-invoices-item-title">
                                        {item?.filename}
                                    </div>
                                    <div className="saved-invoices-item-title">
                                        {item.status || '---'}
                                    </div>
                                </div>
                                <div className="saved-invoices-item-right">
                                    <Button
                                        className={"button"}
                                        type={"primary"}
                                        style={{width: "222px"}}
                                        onClick={() => setSendToTSDModal(true)}
                                    >
                                        Отправить на ТСД
                                    </Button>
                                    <Button
                                        disabled={isLoadingDelete}
                                        style={{
                                            background: "red",
                                            borderColor: "yellow",
                                            width: "222px",
                                            color: '#fff'
                                        }}
                                        className={"button"}
                                        type={"primary"}
                                        onClick={() => setIsModalVisible({
                                            isOpen: true,
                                            text: 'удалить файл?',
                                            onPress: () => handleDelete(item?.handle)
                                        })}
                                    >
                                        Удалить
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>

            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        )
    }

    return (
        <div>
            <Row
                className={"container"}
                style={{marginTop: "20px", }}
            >
                <div className={"storage-tabs"} style={{marginLeft: 20}}>
                    <div
                        className={`${
                            tabNav === 'status1' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status1')}
                    >
                        Статус 1
                    </div>
                    <div
                        className={`${
                            tabNav === 'status2' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status2')}
                    >
                        Статус 2
                    </div>
                    <div
                        className={`${
                            tabNav === 'status3' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status3')}
                    >
                        Статус 3
                    </div>
                    <div
                        className={`${
                            tabNav === 'status4' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status4')}
                    >
                        Статус 4
                    </div>
                </div>

                <Col span={24}>
                    {tabNav === 'status1' && renderStatus1()}
                    {tabNav === 'status2' && renderStatus1()}
                    {tabNav === 'status3' && renderStatus1()}
                    {tabNav === 'status4' && renderStatus1()}
                </Col>
            </Row>

            <Modal
                open={sendToTSDModal}
                closable={false}
                footer={null}
                width={500}
            >
                <SendToTSDModal
                    onClose={() => setSendToTSDModal(false)}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
};

export default ListOfAcceptanceFilesTable;