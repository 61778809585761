import React from 'react';
import {addSpacesToNumber} from "../help/addSpacesToNumber";
import {Col} from "antd";

const TableCardROI = ({
                          value,
                          fsTitle = 22,
                          fsValue = 32,
                      }: any) => {

    return (
        <div className="fina-result-main-card-solo">
            <div className="fina-result-main-title-solo" style={{fontSize: fsTitle, color: '#fff'}}>
                ROI на инвестиции
            </div>
            <div className="fina-result-main-card-value-solo">
                <div>
                    <span style={{color: '#fff', fontSize: fsValue}}>
                        {value ? value : '0'}%
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TableCardROI;