import {useQuery} from "react-query";
import {backend} from "api";
import dayjs, {Dayjs} from "dayjs";
import {useEffect, useState} from "react";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../assets/images/search-icon.svg";
import useSellerList from "../../sellers/hooks/useSellerList";
import Icon from "@ant-design/icons";
import useArticleWbData from "./useArticleWbData";
import {useDebounce} from "use-debounce";
import {useUnit} from "effector-react";
import {$selectedSeller, $type, onChangeSelectedSeller, onChangeSelectedType} from "../model/params";

export default function useFinancialResultList() {

  const [
    selectedSeller,
    type
  ] = useUnit([
    $selectedSeller,
    $type
  ])

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(1);
  const [selectedArticle, setSelectedArticle] = useState<Array<string>>([]);
  const [articleWbLink, setArticleWbLink] = useState('');

  const [isCalendarMode, setIsCalendarMode] = useState(true);

  // const [type, setType] = useState<any>('weeks');

  const getLastEndOfWeek = (today: any = dayjs()) => {

    if (today.day() === 1 || today.day() === 2) {
      const previousWeek = today.subtract(2, 'week');
      return previousWeek.endOf('week')
    } else {
      // Если сегодня не понедельник и не вторник, находим последнее воскресенье, двигаясь назад по дням
      return today.subtract(1, 'week').endOf('week')
    }
  };

  const getPreLastEndOfWeek = (today: any = dayjs()) => {

    if (today.day() === 1 || today.day() === 2) {
      const previousWeek = today.subtract(2, 'week');
      return previousWeek.startOf('week')
    } else {
      // Если сегодня не понедельник и не вторник, находим последнее воскресенье, двигаясь назад по дням
      return today.subtract(1, 'week').startOf('week')
    }
  };

  const prevSunday = getPreLastEndOfWeek().format('DD.MM.YYYY')
  const lastSunday = getLastEndOfWeek().format('DD.MM.YYYY')

  const [dateStart, setDateStart] = useState<any>(prevSunday);
  const [dateEnd, setDateEnd] = useState<any>(lastSunday);

  const handleRangeChangeTable = (dateStrings: any, isWeek?: any) => {
    if (isWeek) {
      const startOfWeek = dayjs(dateStrings).startOf('week');
      const endOfWeek = dayjs(dateStrings).endOf('week');

      setDateStart(startOfWeek.format('DD.MM.YYYY'));
      setDateEnd(endOfWeek.format('DD.MM.YYYY'));
    } else {
      setDateStart(dayjs(dateStrings[0]).format('DD.MM.YYYY'))
      setDateEnd(dayjs(dateStrings[1]).format('DD.MM.YYYY'))
    }

  }

  const onChangeType = (type: any) => {
    onChangeSelectedType(type)
    setCurrentPage(1)
    if (type !== 'weeks') {
      setIsCalendarMode(false)
    } else {
      setIsCalendarMode(true)
    }
  }

  const {
    sellers,
    setSearch: setSearchSeller
  } = useSellerList();

  const {
    articleWb,
    setSellerId,
    search: searchArticle,
    setSearch: setSearchArticle
  } = useArticleWbData();

  const [debouncedSelectedSeller] = useDebounce(selectedSeller, 1000);

  const getArticleWbFilterItems = () => {
    if (!articleWb) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    articleWb?.slice(0, 10)?.forEach((item: any) => {
      const isDuplicate = selectedArticle.some((articleItem: any) => articleItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%',}}
                value={item.id}
                checked={selectedArticle.some(
                  (articleItem: any) =>
                    articleItem?.id?.toString() === item?.id?.toString()
                )}
                onChange={(e) => {
                  e.stopPropagation();
                  let newSelectedArticle = [...selectedArticle];
                  if (e.target.checked) {
                    newSelectedArticle.push(item);
                  } else {
                    newSelectedArticle = newSelectedArticle.filter(
                      (el: any) => el?.id?.toString() !== item?.id?.toString()
                    );
                  }
                  setSelectedArticle(newSelectedArticle);
                }}
              >
                {item.nmId}
              </Checkbox>
            </div>
          ),
          key: `article-${item.id}`,
        });
      }
    });

    selectedArticle?.forEach((item: any) => {
      const isDuplicate = selectedArticle.some((articleItem: any) => articleItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%',}}
                value={item.id}
                checked={selectedArticle.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedArticle = [...selectedArticle];
                  if (e.target.checked) {
                    newSelectedArticle.push(item);
                  } else {
                    newSelectedArticle = newSelectedArticle.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedArticle(newSelectedArticle);
                }}
              >
                {item.nmId}
              </Checkbox>
            </div>
          ),
          key: `article-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      {
        label: (
          <Input
            value={searchArticle}
            className={"analytic-dropdown-input"}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => setSearchArticle(e?.target?.value)}
            prefix={<Icon component={searchIcon}/>}
            placeholder={"Поиск..."}
          />
        ),
        key: "product-search",
      },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  useEffect(() => {
    if (sellers?.[0]?.id) {
      if(!selectedSeller){
        onChangeSelectedSeller(sellers?.[0]?.id)
      }
      // setArticleWbLink(sellers?.[0]?.wb_article)
    }
  }, [sellers])

  useEffect(() => {
    setSellerId(selectedSeller)
  }, [debouncedSelectedSeller])

  const {data: financialResults, ...options} = useQuery(
    [
      "FINANCIAL_RESULT_LIST",
      dateStart,
      dateEnd,
      selectedSeller,
      selectedArticle
    ],
    () =>
      backend.financialResult.getFinancialResults(
        dateStart,
        dateEnd,
        type.toString(),
        selectedSeller,
        selectedArticle
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    financialResults,
    currentPage,
    setCurrentPage,
    currentPageSize,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    articleWb,
    type,
    setType: onChangeSelectedType,
    lastSunday,
    prevSunday,
    sellers,
    setSelectedSeller: onChangeSelectedSeller,
    selectedSeller,
    setCurrentPageSize,
    articleWbLink,
    setArticleWbLink,
    selectedArticle,
    isCalendarMode,
    setIsCalendarMode,
    setSearchSeller,
    getLastSunday: getLastEndOfWeek,
    onChangeType,
    handleRangeChangeTable,
    getArticleWbFilterItems,
    ...options,
  };
}
