import {Col, Row} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as arrowUp} from "assets/images/blue-arrow-up.svg";
import {ReactComponent as arrowDown} from "assets/images/blue-arrow-down.svg";
import {ReactComponent as conditionIcon} from "assets/images/money-hand-line-icon.svg";
import {useState} from "react";

interface CashbackInfoTableRowProps {
    data: CashbackContract;
}

export default function CashbackInfoTableRow({
                                                 data,
                                             }: CashbackInfoTableRowProps) {
    const [showConditions, setShowConditions] = useState<boolean>(false);

    return (
        <div
            style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
            }}
        >
            <div className={"cashbacks-participants-header-container"}>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%", borderTopLeftRadius: "8px"}}
                >
                    Имя участника
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Фамилия участника
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Отчество участника
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Telegram участника
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Номенклатура
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Размер
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Статус кешбека
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "11%"}}
                >
                    Сумма оплат. кешбека
                </div>
                <div
                    className={"cashbacks-participants-heading"}
                    style={{width: "12%", borderTopRightRadius: "8px"}}
                >
                    Подтверждение оплаты
                </div>
            </div>
            <div
                className={"cashbacks-participants-body-container"}
                style={{height: "46px"}}
            >
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackUser.firstName}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackUser.lastName}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackUser.middleName}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackUser.tgNickname}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackItem.nmId}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackItem.size}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackContractStatus.title}{" "}
                    {/*<Icon component={arrowDown}/>*/}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "11%"}}
                >
                    {data.cashbackAction.cashbackSum}
                </div>
                <div
                    className={"cashbacks-participants-col"}
                    style={{width: "12%"}}
                >
                    {data.paidDocumentUrl
                        ?
                        <a
                            href={`${process.env.REACT_APP_API_URL}storage/${data.paidDocumentUrl}`}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            {" "}
                            Ссылка
                        </a>
                        :
                        'Нет данные'
                    }
                </div>
            </div>
            <div>
                <Row
                    className={"cashbacks-participants-header-container"}
                    justify={"space-between"}
                    style={{
                        width: "100%",
                        height: "100%",
                        fontWeight: 600,
                        color: "#4B4B4B",
                        padding: "8px 34px 8px 34px",
                        borderBottomLeftRadius: showConditions ? 0 : "8px",
                        borderBottomRightRadius: showConditions ? 0 : "8px",
                    }}
                    align={"middle"}
                >
                    <Col>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "4px",
                                fontSize: "12px",
                            }}
                        >
                            <Icon
                                component={conditionIcon}
                                style={{fontSize: "22px"}}
                            />
                            Условия получения кешбека
                        </div>
                    </Col>
                    <Col>
                        <div
                            onClick={() => setShowConditions(!showConditions)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "4px",
                                cursor: "pointer",
                                fontSize: "12px",
                            }}
                        >
                            Свернуть
                            <Icon
                                component={showConditions ? arrowUp : arrowDown}
                                style={{fontSize: "12px"}}
                            />
                        </div>
                    </Col>
                </Row>
                <div
                    className={"collapse"}
                    style={{
                        height: showConditions ? 60 : 0,
                        minHeight: showConditions ? 100 : 0,
                        width: "100%",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                    }}
                >
                    <div
                        className={"cashbacks-participants-header-container"}
                        style={{height: "50px"}}
                    >
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%", borderTopLeftRadius: "6px"}}
                        >
                            Статус выполнения 1 условия
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Статус выполнения 2 условия
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Статус выполнения 3 условия
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Статус выполнения 4 условия
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Статус выполнения 5 условия
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Подтверждение выполнения усл 1
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Подтверждение выполнения усл 2
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Подтверждение выполнения усл 3
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Подтверждение выполнения усл 4
                        </div>
                        <div
                            className={"cashbacks-participants-heading"}
                            style={{width: "11%"}}
                        >
                            Подтверждение выполнения усл 5
                        </div>
                    </div>
                    <div
                        className={"cashbacks-participants-body-container"}
                        style={{minHeight: "25px"}}
                    >
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                borderBottomLeftRadius: "8px",
                            }}
                        >
                            {data.isCondition1 ? "Выполнил" : "Не выполнил"}
                            {/*<Icon component={arrowDown}/>*/}
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{width: "11%"}}
                        >
                            {data.isCondition2 ? "Выполнил" : "Не выполнил"}
                            {/*<Icon component={arrowDown}/>*/}
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{width: "11%"}}
                        >
                            {data.isCondition3 ? "Выполнил" : "Не выполнил"}
                            {/*<Icon component={arrowDown}/>*/}
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{width: "11%"}}
                        >
                            {data.isCondition4 ? "Выполнил" : "Не выполнил"}
                            {/*<Icon component={arrowDown}/>*/}
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{width: "11%"}}
                        >
                            {data.isCondition5 ? "Выполнил" : "Не выполнил"}
                            {/*<Icon component={arrowDown}/>*/}
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {data?.condition1Url?.length !== 0
                                ?
                                data?.condition1Url?.map((item: any) =>
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        style={{
                                            textDecorationLine: "underline",
                                            color: "#4C78EE",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {" "}
                                        Ссылка
                                    </a>
                                )
                                : 'Нет данных'
                            }
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {data?.condition2Url?.length !== 0
                                ?
                                data?.condition2Url?.map((item: any) =>
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        style={{
                                            textDecorationLine: "underline",
                                            color: "#4C78EE",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {" "}
                                        Ссылка
                                    </a>
                                )
                                : 'Нет данных'
                            }

                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {data?.condition3Url?.length !== 0
                                ?
                                data?.condition3Url?.map((item: any) =>
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        style={{
                                            textDecorationLine: "underline",
                                            color: "#4C78EE",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {" "}
                                        Ссылка
                                    </a>
                                )
                                : 'Нет данных'
                            }
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                borderBottomRightRadius: "8px",
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {data?.condition4Url?.length !== 0
                                ?
                                data?.condition4Url?.map((item: any) =>
                                    <>
                                        <a

                                            href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                            style={{
                                                textDecorationLine: "underline",
                                                color: "#4C78EE",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {" "}
                                            Ссылка
                                        </a>
                                    </>
                                )
                                : 'Нет данных'
                            }
                        </div>
                        <div
                            className={"cashbacks-participants-col"}
                            style={{
                                width: "11%",
                                borderBottomRightRadius: "8px",
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {data?.condition5Url?.length !== 0
                                ?
                                data?.condition5Url?.map((item: any) =>
                                    <>
                                        <a

                                            href={`${process.env.REACT_APP_API_URL}storage/${item}`}
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                            style={{
                                                textDecorationLine: "underline",
                                                color: "#4C78EE",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {" "}
                                            Ссылка
                                        </a>
                                    </>
                                )
                                : 'Нет данных'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
