import {Button} from "antd";

export const generateSearchButtons = (
    selectedSearchBlock: any,
    setKeywordsWb: any,
    setFixedPhrases: any,
    setMinusPhrase: any,
    keywordsWb: any,
    fixedPhrases: any,
    minusPhrase: any
) => {

    const handleSwap = async (toSwap: any) => {
        if (selectedSearchBlock === 'minusPhrase' && toSwap === 'keywordsWb') {

            let newVar = await minusPhrase.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setKeywordsWb([...keywordsWb, ...newVar])
            await setMinusPhrase(minusPhrase.filter((item: any) => item.selected === false))

        } else if (selectedSearchBlock === 'minusPhrase' && toSwap === 'fixedPhrases') {

            let newVar = await minusPhrase.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setFixedPhrases([...fixedPhrases, ...newVar])
            await setMinusPhrase(minusPhrase.filter((item: any) => item.selected === false))
        } else if (selectedSearchBlock === 'fixedPhrases' && toSwap === 'minusPhrase') {
            let newVar = await fixedPhrases.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setMinusPhrase([...minusPhrase, ...newVar])
            await setFixedPhrases(fixedPhrases.filter((item: any) => item.selected === false))
        } else if (selectedSearchBlock === 'fixedPhrases' && toSwap === 'keywordsWb') {
            let newVar = await fixedPhrases.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setKeywordsWb([...keywordsWb, ...newVar])
            await setFixedPhrases(fixedPhrases.filter((item: any) => item.selected === false))
        } else if (selectedSearchBlock === 'keywordsWb' && toSwap === 'fixedPhrases') {
            let newVar = await keywordsWb.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setFixedPhrases([...fixedPhrases, ...newVar])
            await setKeywordsWb(keywordsWb.filter((item: any) => item.selected === false))
        } else if (selectedSearchBlock === 'keywordsWb' && toSwap === 'minusPhrase') {
            let newVar = await keywordsWb.filter((item: any) => item.selected === true).map((item: any) => ({
                ...item,
                selected: false
            }))
            await setMinusPhrase([...minusPhrase, ...newVar])
            await setKeywordsWb(keywordsWb.filter((item: any) => item.selected === false))
        }
    }

    const renderButtons = () => {
        return (
            <>
                {selectedSearchBlock === 'minusPhrase'
                    ?
                    <>
                        <Button
                            className={"button"}
                            style={{fontSize: "12px"}}
                            type={"primary"}
                            ghost
                            onClick={() => handleSwap('keywordsWb')}
                        >
                            Перенести в ключевые фразы WB
                        </Button>
                        <Button
                            className={"button"}
                            style={{fontSize: "12px"}}
                            type={"primary"}
                            ghost
                            onClick={() => handleSwap('fixedPhrases')}
                        >
                            Перенести в фиксированные фразы
                        </Button>
                    </>
                    :
                    selectedSearchBlock === 'keywordsWb'
                        ?
                        <>
                            <Button
                                className={"button"}
                                style={{fontSize: "12px"}}
                                type={"primary"}
                                ghost
                                onClick={() => handleSwap('fixedPhrases')}
                            >
                                Перенести в фиксированные фразы
                            </Button>
                            <Button
                                className={"button"}
                                style={{fontSize: "12px"}}
                                type={"primary"}
                                ghost
                                onClick={() => handleSwap('minusPhrase')}
                            >
                                Перенести в минус фразы
                            </Button>
                        </>
                        :
                        selectedSearchBlock === 'fixedPhrases'
                            ?
                            <>
                                <Button
                                    className={"button"}
                                    style={{fontSize: "12px"}}
                                    type={"primary"}
                                    ghost
                                    onClick={() => handleSwap('minusPhrase')}
                                >
                                    Перенести в минус-фразы
                                </Button>
                                <Button
                                    className={"button"}
                                    style={{fontSize: "12px"}}
                                    type={"primary"}
                                    ghost
                                    onClick={() => handleSwap('keywordsWb')}
                                >
                                    Ключевые фразы WB
                                </Button>
                            </>
                            : null

                }
            </>
        )
    }

    return (
        <>
            {renderButtons()}
        </>
    )
}