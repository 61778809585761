import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "../../../../api";
import {useCallback, useState} from "react";

export default function useStartStatus() {

    const [id, setId] = useState<string>('');
    const queryClient = useQueryClient();

    const {data: response, ...options} = useQuery(
        ["START_MIRROR_STATUS", id],
        () =>
            backend.advertisingMirror.mirrorStartStatus(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["ADVERTISING_MIRROR_DATA"]),
                ]);
            }
        }
    );


    return {
        response,
        setId,
        id,
        ...options,
    };
}
