import {useQuery} from "react-query";
import {backend} from "api";
import React, {useState} from "react";
import {useDebounce} from "use-debounce";
import {Checkbox, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useProductList from "../../product/hooks/useProductList";
import useStatusesData from "./useStatusesData";
import useLaboratoryProductList from "./useLaboratoryProductList";
import LockIcon from "../../../assets/icon/LockIcon";

export default function useLaboratoryCurrentData() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<string>("10");

  const [genders, setGenders] = useState([
    {
      id: 1,
      title: 'мужское',
      key: 'man'
    },
    {
      id: 2,
      title: 'женское',
      key: 'woman'
    },
    {
      id: 3,
      title: 'детское',
      key: 'child'
    },
    {
      id: 4,
      title: 'девочки',
      key: 'girl'
    },
    {
      id: 5,
      title: 'мальчики',
      key: 'boy'
    },
    {
      id: 6,
      title: 'унисекс',
      key: 'unisex'
    },
  ])
  const [selectedGender, setSelectedGender] = useState<Array<string>>([]);
  const getGenderFilterItems = () => {
    if (!genders) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    genders?.forEach((item: any) => {
      const isDuplicate = selectedGender.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                // disabled={true}
                style={{width: '100%'}}
                value={item.title}
                checked={selectedGender.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedGender];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedGender(newSelectedProducts);
                }}
              >
                {/*<span*/}
                {/*  style={{*/}
                {/*    display: 'inline-block',*/}
                {/*    transform: `translateY(6px)`*/}
                {/*  }}*/}
                {/*><LockIcon/></span>*/}
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedGender?.forEach((item: any) => {
      const isDuplicate = selectedGender.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedGender.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedGender];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedGender(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      // {
      //   label: (
      //     <Input
      //       value={searchProduct}
      //       className={"analytic-dropdown-input"}
      //       onClick={(e) => e?.stopPropagation()}
      //       onChange={(e) => setSearchProduct(e?.target?.value)}
      //       prefix={<Icon component={searchIcon}/>}
      //       placeholder={"Поиск..."}
      //     />
      //   ),
      //   key: "product-search",
      // },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const [seasonTypes, setSeasonTypes] = useState([
    {
      id: 4,
      title: 'spring',
      key: 'spring',
      ru: 'Весна',
    },
    {
      id: 2,
      title: 'summer',
      key: 'summer',
      ru: 'Лето'
    },
    {
      id: 3,
      title: 'autumn',
      key: 'autumn',
      ru: 'Осень'
    },
    {
      id: 1,
      title: 'winter',
      key: 'winter',
      ru: 'Зима'
    },
  ])
  const [selectedSeasonType, setSelectedSeasonType] = useState<any>({
    id: 4,
    title: 'spring',
    key: 'spring',
    ru: 'Весна',
  });

  const {
    statusesData: statuses
  } = useStatusesData()

  const [selectedStatus, setSelectedStatus] = useState<Array<string>>([]);
  const getStatusFilterItems = () => {
    if (!statuses) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    statuses?.forEach((item: any) => {
      const isDuplicate = selectedStatus.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                // disabled={true}
                style={{width: '100%'}}
                value={item.title}
                checked={selectedStatus.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedStatus];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedStatus(newSelectedProducts);
                }}
              >
                {/*<span*/}
                {/*  style={{*/}
                {/*    display: 'inline-block',*/}
                {/*    transform: `translateY(6px)`*/}
                {/*  }}*/}
                {/*><LockIcon/></span>*/}
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedStatus?.forEach((item: any) => {
      const isDuplicate = selectedStatus.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedStatus.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedStatus];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedStatus(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      // {
      //   label: (
      //     <Input
      //       value={searchProduct}
      //       className={"analytic-dropdown-input"}
      //       onClick={(e) => e?.stopPropagation()}
      //       onChange={(e) => setSearchProduct(e?.target?.value)}
      //       prefix={<Icon component={searchIcon}/>}
      //       placeholder={"Поиск..."}
      //     />
      //   ),
      //   key: "product-search",
      // },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const [periodStartDate, setPeriodStartDate] = useState<any>('');
  const [periodEndDate, setPeriodEndDate] = useState<any>('');
  const handleRangeChangeTable = (dates: any, dateStrings: any) => {
    setPeriodStartDate(dateStrings[0])
    setPeriodEndDate(dateStrings[1])
  }

  const [saleDate, setSaleDate] = useState<any>('');

  const {
    products,
    search: searchProduct,
    setSearch: setSearchProduct,
    setNotEmptyMode
  } = useLaboratoryProductList();
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
  const getProductsFilterItems = () => {
    if (!products) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    products?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                disabled={!item?.is_enabled}
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {
                  !item?.is_enabled &&
                    <span
                        style={{
                          display: 'inline-block',
                          transform: `translateY(6px)`
                        }}
                    ><LockIcon/></span>
                }
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedProducts?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      // {
      //   label: (
      //     <Input
      //       value={searchProduct}
      //       className={"analytic-dropdown-input"}
      //       onClick={(e) => e?.stopPropagation()}
      //       onChange={(e) => setSearchProduct(e?.target?.value)}
      //       prefix={<Icon component={searchIcon}/>}
      //       placeholder={"Поиск..."}
      //     />
      //   ),
      //   key: "product-search",
      // },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const {data, ...options} = useQuery(
    [
      "LABORATORY_CURRENT_LIST",
      currentPage,
      currentPageSize,
      selectedGender,
      selectedSeasonType,
      selectedStatus,
      periodStartDate,
      periodEndDate,
      saleDate,
      selectedProducts
    ],
    () => backend.laboratory.getLaboratoryCurrentData(
      currentPage.toString(),
      currentPageSize,
      selectedGender,
      selectedSeasonType,
      selectedStatus,
      periodStartDate,
      periodEndDate,
      saleDate,
      selectedProducts
    ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    selectedProducts,
    getProductsFilterItems,
    handleRangeChangeTable,
    selectedSeasonType,
    setSelectedSeasonType,
    seasonTypes,
    genders,
    getGenderFilterItems,
    selectedGender,
    setSelectedGender,
    statuses,
    getStatusFilterItems,
    selectedStatus,
    saleDate,
    setSaleDate,
    data,
    ...options
  };
}
