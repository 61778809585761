import React, {useEffect, useState} from "react";
import {Button, Col, ConfigProvider, Dropdown, Menu, MenuProps, message, Modal, Row, Switch, Table} from "antd";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import useCashbacksUsersTableFilters from "../hooks/useCashbacksUsersTableFilters";
import useExpensesAdData from "../../expenses/hooks/useExpensesAdData";
import AddExpensesModal from "../modal/AddExpensesModal";
import EditExpensesModal from "../modal/EditExpensesModal";
import useExpensesUserData from "../../expenses/hooks/useExpensesUserData";
import useCashbackExpensesTableFilter from "../hooks/useCashbackExpensesTableFilter";
import {ReactComponent as trashBucket} from "../../../assets/images/trash-bucket.svg";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import {useDeleteExpenses} from "../hooks/useDeleteExpenses";

export default function CashbacksExpensesTable() {

    const [expensesState, setExpensesState] = useState('users')

    const [filterMenuOpenedUser, setFilterMenuOpenedUser] = useState<boolean>(false);
    const [filterMenuOpenedExpenses, setFilterMenuOpenedExpenses] = useState<boolean>(false);
    const {filterMenuItems: filterUsers} = useCashbacksUsersTableFilters();
    const {filterMenuItems: filterExpenses} = useCashbackExpensesTableFilter();

    const {
        expensesAdData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        isLoading,
        setOrderByExpenses
    } = useExpensesAdData()

    const {
        expensesUserData,
        currentPage: currentUserPage,
        setCurrentPage: setCurrentUserPage,
        isLoading: isUserLoading,
        setOrderByUser
    } = useExpensesUserData()

    const {
        handleDelete,
        errorMessage,
        isError,
        isSuccess
    } = useDeleteExpenses()

    const [addExpensesAdModal, setAddExpensesAdModal] = useState<any>(false);
    const [editExpensesAdModal, setEditExpensesAdModal] = useState<any>({
        id: null,
        isOpen: false
    });
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const cashbackItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_CASHBACK",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE_CASHBACK",
        }
    ];

    const getProductsActions = (record: any) => {
        return {
            items: cashbackItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_CASHBACK":
                        setEditExpensesAdModal({
                            id: record?.id,
                            isOpen: true
                        })
                        break;
                    case "DELETE_CASHBACK":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить затрату?',
                            onPress: () => handleDelete(record?.id)
                        })
                        break;
                }
            },
        };
    };

    const cashbackAdColumns = [
        {
            title: "No",
            dataIndex: "id",
            key: "id",
            width: "6%",
        },
        {
            title: "Кешбек",
            dataIndex: "cashbackAction",
            key: "cashbackAction",
            width: "13%",
            render: (text: any, record: any) => (
                <div>
                    {record?.cashbackAction?.name || '---'}
                </div>
            ),
        },
        {
            title: "Аналитик",
            dataIndex: "firstName",
            key: "firstName",
            width: "13%",
            render: (text: any, record: any) => (
                <div>
                    {record?.userCreated?.lastName + " " + record?.userCreated?.firstName || '---'}
                </div>
            ),
        },
        {
            title: "Ссылка на публикацию",
            dataIndex: "linkOnPublicUrl",
            key: "linkOnPublicUrl",
            width: "14%",
            render: (text: any, record: any) => (
                <div>
                    {text?.includes('http')
                        ? <a href={text} target={"_blank"}>Ссылка</a>
                        : '---'
                    }
                </div>
            ),
        },
        {
            title: "Документ об оплате",
            dataIndex: "linkOnPaymentUrl",
            key: "linkOnPaymentUrl",
            width: "12%",
            render: (text: any, record: any) => (
                <div>
                    {text?.includes('http')
                        ? <a href={text} target={"_blank"}>Ссылка</a>
                        : '---'
                    }
                </div>
            ),
        },
        {
            title: "Оплатившее лицо",
            dataIndex: "linkOnPayedWhom",
            key: "linkOnPayedWhom",
            width: "12%",
            render: (text: any, record: any) => (
                <div>
                    {text?.includes('http')
                        ? <a href={text} target={"_blank"}>Ссылка</a>
                        : '---'
                    }
                </div>
            ),
        },
        {
            title: "Сумма оплаты, руб",
            dataIndex: "sumPayed",
            key: "sumPayed",
            width: "12%",
        },
        {
            title: "",
            dataIndex: "edit",
            key: "edit",
            width: "15%",
            render: (text: any, record: any) => (
                <div
                    // onClick={() => setEditExpensesAdModal({
                    //     id: record?.id,
                    //     isOpen: true
                    // })}
                    // style={{
                    //     textAlign: "center",
                    //     display: "flex",
                    //     gap: "7px",
                    //     alignItems: "center",
                    //     justifyContent: "center",
                    //     cursor: "pointer",
                    // }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                    {/*<img src={bigEditIcon} alt="" style={{width: 15, cursor: 'pointer'}}/>*/}
                </div>
            ),
        },
    ];

    const cashbackUserColumns = [
        {
            title: "No",
            dataIndex: "id",
            key: "id",
            width: "6%",
        },
        {
            title: "Кешбек",
            dataIndex: "cashbackAction",
            key: "cashbackAction",
            width: "13%",
            render: (text: any, record: any) => (
                <div>
                    {record?.contract?.cashbackAction?.name || '---'}
                </div>
            ),
        },
        {
            title: "Пользователь кешбека",
            dataIndex: "cashbackAction",
            key: "cashbackAction",
            width: "13%",
            render: (text: any, record: any) => (
                <div>
                    {record?.contract?.cashbackUser?.lastName + " " + record?.contract?.cashbackUser?.firstName || '---'}
                </div>
            ),
        },
        {
            title: "Аналитик",
            dataIndex: "firstName",
            key: "firstName",
            width: "13%",
            render: (text: any, record: any) => (
                <div>
                    { record?.analytic?.lastName + " " + record?.analytic?.firstName || '---'}
                </div>
            ),
        },
        {
            title: "Ссылка на публикацию",
            dataIndex: "userMessageText",
            key: "userMessageText",
            width: "14%",
            render: (text: any, record: any) => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: "Одобрена(да/нет)",
            dataIndex: "isAgreed",
            key: "isAgreed",
            width: "12%",
            render: (text: any, record: any) => (
                <div>
                    {text === true ? 'Да' : 'Нет'}
                </div>
            ),
        },
        {
            title: "Управляется(да/нет)",
            dataIndex: "isOperated",
            key: "isOperated",
            width: "12%",
            render: (text: any, record: any) => (
                <div>
                    {text === true ? 'Да' : 'Нет'}
                </div>
            ),
        },
    ];


    useEffect(() =>{
        if (isError){
            message.error(errorMessage)
        }
        if (isSuccess){
            message.success('Вы успешно удалили затрату')
        }
    },[isError, isSuccess])

    return (
        <Row className={"container"} style={{marginTop: "10px"}}>
            <Col span={24}>
                <Row
                    justify={"space-between"}
                    align={"middle"}
                    style={{padding: "10px 20px 10px 10px"}}
                >
                    <Col span={24} style={{}}>
                        <div className="product-extra-nav">
                            <div className="product-extra-nav-items">
                                <div
                                    onClick={() => setExpensesState('users')}
                                    className={`product-extra-nav-item ${expensesState === 'users' && 'product-extra-nav-item-active'}`}>
                                    <p>Затраты на участников</p>
                                </div>
                                <div
                                    onClick={() => setExpensesState('advertisement')}
                                    className={`product-extra-nav-item ${expensesState === 'advertisement' && 'product-extra-nav-item-active'}`}>
                                    <p>Затраты на рекламу</p>
                                </div>
                            </div>
                            {expensesState === 'advertisement'
                                ?
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div>
                                        <div style={{position: "relative"}}>
                                            <div
                                                style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    setFilterMenuOpenedExpenses(!filterMenuOpenedExpenses);
                                                }}
                                            >
                                                <Icon component={filterIcon}/>
                                                <span
                                                    style={{
                                                        color: "#82868B",
                                                        marginLeft: "6px",
                                                    }}
                                                >
                                                Фильтры
                                            </span>
                                            </div>

                                            <Menu
                                                className={
                                                    filterMenuOpenedExpenses
                                                        ? "filter-menu filter-menu-opened"
                                                        : "filter-menu filter-menu-closed"
                                                }
                                                style={{
                                                    color: "red !important",
                                                    width: "250px",
                                                    left: "-40%",
                                                }}
                                                items={filterExpenses}
                                                mode={"inline"}
                                                onClick={(e) => {
                                                    setOrderByExpenses(e.key)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Button
                                        className={"button"}
                                        type={"primary"}
                                        style={{width: "220px", fontSize: "12px", height: 30, marginLeft: 20}}
                                        onClick={() => setAddExpensesAdModal(true)}
                                    >
                                        Добавить затрату на рекламу
                                    </Button>

                                </div>
                                :
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div>
                                        <div style={{position: "relative"}}>
                                            <div
                                                style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    setFilterMenuOpenedUser(!filterMenuOpenedUser);
                                                }}
                                            >
                                                <Icon component={filterIcon}/>
                                                <span
                                                    style={{
                                                        color: "#82868B",
                                                        marginLeft: "6px",
                                                    }}
                                                >
                                                Фильтры
                                            </span>
                                            </div>

                                            <Menu
                                                className={
                                                    filterMenuOpenedUser
                                                        ? "filter-menu filter-menu-opened"
                                                        : "filter-menu filter-menu-closed"
                                                }
                                                style={{
                                                    color: "red !important",
                                                    width: "250px",
                                                    left: "-200%",
                                                }}
                                                items={filterUsers}
                                                mode={"inline"}
                                                onClick={(e) => {
                                                    setOrderByUser(e.key)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </Col>

                </Row>
            </Col>
            <Col span={24}>
                {
                    expensesState === 'users'
                        ?
                        <Table
                            loading={isUserLoading}
                            columns={cashbackUserColumns}
                            className={"cashback-table"}
                            dataSource={expensesUserData?.data ?? []}
                            locale={{
                                emptyText: "Нет данных",
                                filterTitle: "Фильтр",
                                filterReset: "Очистить",
                                filterEmptyText: "Нет фильтров",
                            }}
                            scroll={{x: true}}
                            pagination={{
                                onChange: (page, pageSize): any => setCurrentUserPage(page),
                                position: ["bottomCenter"],
                                pageSize: Number(10),
                                total: Number(expensesUserData?.total),
                                showSizeChanger: false,
                                current: currentUserPage,
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => {
                                        // setUserToOpen(record);
                                        // setCashbackUserHistoryOpened(true);
                                    },
                                };
                            }}
                        />
                        :
                        <Table
                            loading={isLoading}
                            columns={cashbackAdColumns}
                            className={"cashback-table"}
                            dataSource={expensesAdData?.data ?? []}
                            locale={{
                                emptyText: "Нет данных",
                                filterTitle: "Фильтр",
                                filterReset: "Очистить",
                                filterEmptyText: "Нет фильтров",
                            }}
                            scroll={{x: true}}
                            pagination={{
                                onChange: (page, pageSize): any => setCurrentPage(page),
                                position: ["bottomCenter"],
                                pageSize: Number(10),
                                total: Number(expensesAdData?.total),
                                showSizeChanger: false,
                                current: currentPage,
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => {
                                        console.log(record)
                                    },
                                };
                            }}
                        />
                }
            </Col>

            <Modal
                open={addExpensesAdModal}
                closable={false}
                footer={null}
                width={500}
            >
                <AddExpensesModal
                    state={expensesState}
                    onClose={() => {
                        setAddExpensesAdModal(false)
                    }}
                />
            </Modal>

            <Modal
                open={editExpensesAdModal.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <EditExpensesModal
                    state={expensesState}
                    currentPage={currentPage}
                    id={editExpensesAdModal.id}
                    onClose={() => {
                        setEditExpensesAdModal({
                            id: null,
                            isOpen: false
                        })
                    }}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </Row>
    );
}
