import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table,
} from "antd";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import { ReactComponent as SelectIconComponent } from "assets/images/select-icon.svg";
import { ReactComponent as filterIcon } from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "../../assets/images/edit-icon.svg";
import useExpensesTableFilters from "./hooks/expenses/useExpensesTableFilters";
import {ReactComponent as trashBucket} from '../../assets/images/trash-bucket.svg'
import AddPayment from "./modal/AddPayment";
import EditPayment from "./modal/EditPayment";
import useGuideData from "./hooks/guide/useGuideData";
import {useGuideDelete} from "./hooks/guide/useGuideDelete";
import useExpensesData from "./hooks/expenses/useExpensesData";
import {useExpensesDelete} from "./hooks/expenses/useExpensesDelete";
import dayjs from "dayjs";
import ConfirmModal from "../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import useEmissionMainTableFilter from "../kiz/hooks/emission/useEmissionMainTableFilter";

export default function ExpensesTable() {

    const {
        expensesData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        sortType,
        sortParam,
        setSortType,
        setSortParam,
        isLoading,
    } = useExpensesData()

    const {
        handleDelete,
        errorMessage,
    } = useExpensesDelete()

    const [addPaymentModal, setAddPaymentModal] = useState<boolean>(false);
    const [editPaymentModal, setEditPaymentModal] = useState<any>({
        id: null,
        isOpen: false
    });
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);

    const {filterMenuItems} = useExpensesTableFilters();

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_PAYMENT",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE_PAYMENT",
        }
    ];

    const getProductsActions = (record: any) => {

        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_PAYMENT":
                        setEditPaymentModal({
                            id: record?.id,
                            isOpen: true
                        })
                        break;
                    case "DELETE_PAYMENT":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить расход?',
                            onPress: () => handleDelete(record?.id)
                        })
                        break;
                }
            },
        };
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "15%",
        },
        {
            title: "Тип оплаты",
            dataIndex: "operationType",
            key: "operationType",
            width: "10%",
            render: (text: any, record: any) => (
                <div>
                    {record?.operationType?.name}
                </div>
            ),
        },
        {
            title: "Продавец",
            dataIndex: "seller",
            key: "seller",
            width: "15%",
            render: (text: any, record: any) => (
                <div>
                    {record?.seller?.companyName}
                </div>
            ),
        },
        {
            title: "Сумма оплаты, руб",
            dataIndex: "cost",
            key: "cost",
            width: "10%",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
            width: "10%",
        },
        {
            title: "Начало периода",
            dataIndex: "description",
            key: "description",
            width: "15%",
            render: (text: any, record: any) => (
                <div>
                    {dayjs(record?.startDate).format('DD.MM.YYYY')}
                </div>
            ),
        },
        {
            title: "Конец периода",
            dataIndex: "description",
            key: "description",
            width: "15%",
            render: (text: any, record: any) => (
                <div>
                    {dayjs(record?.endDate).format('DD.MM.YYYY')}
                </div>
            ),
        },

        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        minHeight: 60,
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    useEffect(() =>{
        if (errorMessage){
            message.error('Произошла ошибка при попытке удалить оплату')
        }
    },[errorMessage])

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск по описанию"}
                        // type={"number"}
                    />
                </Col>

                <Col style={{width: "250px"}} >
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>


                {/*<Col>*/}
                {/*    <Button*/}
                {/*        className="table-header-button"*/}
                {/*        type={"primary"}*/}
                {/*        style={{*/}
                {/*            display: "flex",*/}
                {/*            justifyContent: "center",*/}
                {/*            alignItems: "center",*/}
                {/*            width: 200*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Добавить*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
            <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                    padding: "5px 20px 5px 30px",
                    marginTop: 20,
                    backgroundColor: '#fff'
                }}
            >
                <Col>
                    <div style={{position: "relative"}}>
                        <div
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setFilterMenuOpened(!filterMenuOpened);
                            }}
                        >
                            <Icon component={filterIcon}/>
                            <span
                                style={{
                                    color: "#82868B",
                                    marginLeft: "6px",
                                }}
                            >
                                Фильтры
                            </span>
                        </div>

                        <Menu
                            className={
                                filterMenuOpened
                                    ? "filter-menu filter-menu-opened"
                                    : "filter-menu filter-menu-closed"
                            }
                            style={{
                                color: "red !important",
                                width: "250px",
                                left: "-40%",
                            }}
                            items={filterMenuItems}
                            mode={"inline"}
                            onClick={(e) => {
                                const key = e.key;
                                const test = key.split('-')
                                setSortParam(test[0]);
                                setSortType(test[1]);
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "171px",
                            fontSize: "12px",
                        }}
                        onClick={() => setAddPaymentModal(true)}
                    >
                        Добавить оплату
                    </Button>
                </Col>
            </Row>

            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        dataSource={expensesData?.data || []}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(expensesData?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => {
                                    // setProductToInfo(record);
                                    // setProductInfoOpened(true);
                                },
                            };
                        }}
                    />
                </Col>
            </Row>

            <Modal
                open={addPaymentModal}
                closable={false}
                footer={null}
                width={600}
            >
                <AddPayment
                    onClose={() => setAddPaymentModal(false)}
                />
            </Modal>

            <Modal
                open={editPaymentModal.isOpen}
                closable={false}
                footer={null}
                width={600}
            >
                <EditPayment
                    id={editPaymentModal.id}
                    onClose={() => setEditPaymentModal({
                        id: null,
                        isOpen: false
                    })}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
