import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useAnalyticsCashbackUsersUpdate = (id: number) => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    mutateAsync: update,
    isLoading: updateLoading,
    isError,
  } = useMutation(backend.analytics.updateAnalyticsCashbacksUsers, {
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries([`CASHBACK_USERS_RESULT_LIST`]),
      ]);
    },
  });

  const handleUpdate = useCallback(
    async (values: any, handleCloseModal: any) => {
      try {
        let body = { ...values };

        if (values.userId) {
          body.userId = parseInt(values.userId);
        }

        await update({ id: id.toString(), ...body });
        handleCloseModal();
      } catch (e: any) {
        setErrorMessage("Произошла ошибка при обновлении кешбэка");
      }
    },
    [update]
  );

  const handleUpdateById = useCallback(
    async (values: any, handleCloseModal: any) => {
      try {
        let body = { ...values };

        if (values.userId) {
          body.userId = parseInt(values.userId);
        }

        await update({ id: values.userId.toString(), ...body });
        handleCloseModal();
      } catch (e: any) {
        setErrorMessage("Произошла ошибка при обновлении кешбэка");
      }
    },
    [update]
  );

  return {
    handleUpdate,
    handleUpdateById,
    isLoading: updateLoading,
    isError,
    errorMessage,
  };
};
