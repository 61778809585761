import {useQuery} from "react-query";
import {backend} from "api";
import React, {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import useExtRef from "../../../kiz/hooks/useExtRef";
import {useDebounce} from "use-debounce";

export default function useDocumentsData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch] = useDebounce(search, 1000);

    const [dateSortStart, setDateSortStart] = useState<any>();
    const [dateSortEnd, setDateSortEnd] = useState<any>();

    const [selectedSeller, setSelectedSeller] = useState<any>('');
    const [selectedCategories, setSelectedCategories] = useState<any>('all');

    const onChangeData = (dateStrings: any) => {
        if (dateStrings){
            setDateSortStart(dateStrings[0].format("DD.MM.YYYY"))
            setDateSortEnd(dateStrings[1].format("DD.MM.YYYY"))
        } else{
            setDateSortStart('')
            setDateSortEnd('')
        }
    }

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useExtRef();


    useEffect(() =>{
        setSelectedSeller(sellers?.data[0]?.extRef)
    },[])

    const {data: documentsData, ...options} = useQuery(
        [
            "DOCUMENT_MIRROR_DOCUMENTS_DATA",
            currentPage,
            currentPageSize,
            selectedSeller,
            dateSortStart,
            dateSortEnd,
            selectedCategories,
            debouncedSearch
        ],
        () =>
            backend.documentMirror.getDocumentsData(
                currentPage.toString(),
                currentPageSize,
                selectedSeller,
                dateSortStart,
                dateSortEnd,
                selectedCategories,
                debouncedSearch
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        documentsData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        dateSortStart,
        dateSortEnd,
        onChangeData,
        selectedSeller,
        setSelectedSeller,
        sellers,
        setSearchSellers,
        searchSellers,
        selectedCategories,
        setSelectedCategories,
        search,
        setSearch,
        ...options,
    };
}
