import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "../../api";
import React, {useCallback, useEffect, useState} from "react";
import useEmmissionsDataFilterSize from "../emission/useEmmissionsDataFilterSize";
import useEmmissionsDataFilterBoxCode from "../emission/useEmmissionsDataFilterBoxCode";
import useEmmissionsDataFilterChineArticle from "../emission/useEmmissionsDataFilterChineArticle";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import Icon from "@ant-design/icons";
import {useDebounce} from "use-debounce";
import useArticleBySearch from "../acceptance/useArticleBySearch";

export default function useDeclarationsFullInfoData() {

    const [id, setId] = useState<any>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [order, setOrderBy] = useState<string>("");
    const [isPicket, setIsPicket] = useState<any>("");

    const [dateCreateStart, setDateCreateStart] = useState<string>("");
    const [dateCreateEnd, setDateCreateEnd] = useState<string>("");

    const [dateUpdateStart, setDateUpdateStart] = useState<string>("");
    const [dateUpdateEnd, setDateUpdateEnd] = useState<string>("");

    const [dateCustomedStart, setDateCustomedStart] = useState<string>("");
    const [dateCustomedEnd, setDateCustomedEnd] = useState<string>("");

    const [searchName, setSearchName] = useState<string>("");
    const [debouncedName] = useDebounce(searchName, 1000);
    const [searchArticle, setSearchArticle] = useState<string>("");
    const [debouncedAcricle] = useDebounce(searchArticle, 1000);

    const onChangeDateCreate = (dateStrings: any) => {
        if (!dateStrings) {
            setDateCreateStart('')
            setDateCreateEnd('')
        } else {
            setDateCreateStart(dateStrings[0])
            setDateCreateEnd(dateStrings[1])
        }
    }
    const onChangeDateUpdate = (dateStrings: any) => {
        if (!dateStrings) {
            setDateUpdateStart('')
            setDateUpdateEnd('')
        } else {
            setDateUpdateStart(dateStrings[0])
            setDateUpdateEnd(dateStrings[1])
        }
    }
    const onChangeDateCustomed = (dateStrings: any) => {
        if (!dateStrings) {
            setDateCustomedStart('')
            setDateCustomedEnd('')
        } else {
            setDateCustomedStart(dateStrings[0])
            setDateCustomedEnd(dateStrings[1])
        }
    }

    const {sizes, setIdDeclaration: setIdForSizes} = useEmmissionsDataFilterSize()
    const {articles, setLimit, setIdDeclaration: setIdForArticle} = useArticleBySearch()

    const [searchSizesFilter, setSearchSizesFilter] = useState(sizes)
    const [searchArticleFilter, setSearchArticleFilter] = useState(articles)

    const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);
    const [selectedArticle, setSelectedArticle] = useState<Array<string>>([]);

    const getArticleFilterItems = () => {
        if (!searchArticleFilter) {
            return [];
        }
        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                        onChange={(e) => setSearchArticleFilter(articles.filter((item: any) => item.toLowerCase().includes(e.target.value.toLowerCase())))}
                    />
                ),
                key: "article-search",
            },
        ];
        searchArticleFilter?.forEach((article: any) => {
            items.push({
                label: (
                    <div
                        style={{display: "flex", gap: "10px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={article}
                            checked={selectedArticle.includes(article)}
                            onChange={(e) => {
                                let newSelectedArticle = [...selectedArticle];
                                if (e.target.checked) {
                                    newSelectedArticle.push(article);
                                } else {
                                    newSelectedArticle = newSelectedArticle.filter(
                                        (el) => el !== article
                                    );
                                }
                                setSelectedArticle(newSelectedArticle);
                            }}
                        >
                            {article}
                        </Checkbox>
                    </div>
                ),
                key: `article-${article}`,
            });
        });
        return items;
    };

    const getSizeFilterItems = () => {
        if (!searchSizesFilter) {
            return [];
        }
        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                        onChange={(e) => setSearchSizesFilter(sizes.filter((item: any) => item.toLowerCase().includes(e.target.value.toLowerCase())))}
                    />
                ),
                key: "size-search",
            },
        ];
        searchSizesFilter?.forEach((size: any) => {
            items.push({
                label: (
                    <div
                        style={{display: "flex", gap: "10px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={size}
                            checked={selectedSizes.includes(size)}
                            onChange={(e) => {
                                let newSelectedSizes = [...selectedSizes];
                                if (e.target.checked) {
                                    newSelectedSizes.push(size);
                                } else {
                                    newSelectedSizes = newSelectedSizes.filter(
                                        (el) => el !== size
                                    );
                                }
                                setSelectedSizes(newSelectedSizes);
                            }}
                        >
                            {size}
                        </Checkbox>
                    </div>
                ),
                key: `size-${size}`,
            });
        });
        return items;
    };

    useEffect(() =>{
        setIdForSizes(id)
        setIdForArticle(id)
        setLimit('')
    },[id])

    useEffect(() =>{
        if (sizes?.length !== 0){
            setSearchSizesFilter(sizes)
        }
        if (articles?.length !== 0){
            setSearchArticleFilter(articles)
        }
    },[sizes,articles])

    const {data: declaration, ...options} = useQuery(
        [
            "DECLARATION_DATA",
            id,
            currentPage,
            currentPageSize,
            order,
            dateCreateStart,
            dateCreateEnd,
            dateUpdateStart,
            dateUpdateEnd,
            dateCustomedStart,
            dateCustomedEnd,
            selectedSizes,
            debouncedName,
            selectedArticle,
            isPicket
        ],
        () =>
            backend.declaration.getDeclarationsFullInfoData(
                id.toString(),
                currentPage.toString(),
                currentPageSize,
                order,
                dateCreateStart,
                dateCreateEnd,
                dateUpdateStart,
                dateUpdateEnd,
                dateCustomedStart,
                dateCustomedEnd,
                selectedSizes,
                debouncedName,
                selectedArticle,
                isPicket
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        declaration,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        order,
        setOrderBy,
        setId,
        searchName,
        searchArticle,
        onChangeDateCreate,
        onChangeDateUpdate,
        onChangeDateCustomed,
        getSizeFilterItems,
        getArticleFilterItems,
        setSearchName,
        setSearchArticle,
        dateCreateStart,
        dateCreateEnd,
        dateUpdateStart,
        dateUpdateEnd,
        dateCustomedStart,
        dateCustomedEnd,
        selectedSizes,
        debouncedName,
        debouncedAcricle,
        setIsPicket,
        selectedArticle,
        isPicket,
        ...options,
    };
}
