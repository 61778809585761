import { useQuery } from "react-query";
import { backend } from "../../api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useArticleBySearch() {

    const [search, setSearch] = useState<any>('');
    const [limit, setLimit] = useState<any>('');
    const [declarationId, setDeclarationId] = useState<any>('');
    const [debouncedSearch] = useDebounce(search, 1000);
    const [idEmission, setIdEmission] = useState<any>('')
    const [idDeclaration, setIdDeclaration] = useState<any>('')

    const { data: articles, ...options } = useQuery(
        [
            "ARTICLE_BY_SEARCH",
            debouncedSearch,
            limit,
            declarationId,
            idEmission,
            idDeclaration
        ],
        () => backend.declaration.getArticleBySearch(
            debouncedSearch.toString(),
            limit,
            declarationId,
            idEmission,
            idDeclaration
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        articles,
        search,
        debouncedSearch,
        setSearch,
        setLimit,
        declarationId,
        setDeclarationId,
        setId: setIdEmission,
        setIdDeclaration,
        ...options,
    };
}
