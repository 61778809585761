import React, {useState, useRef, useEffect} from "react";
import {
    Col,
    Form,
    Input,
    Row,
    Select,
    DatePicker,
    TimePicker,
    Button,
    Checkbox, InputNumber,
} from "antd";
import type {InputRef} from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import {ReactComponent as rangePickerArrow} from "assets/images/rangepicker-arrow.svg";
import locale from "antd/es/date-picker/locale/en_US";
import AddKeywordDropdown from "../../components/advertisingMirror/AddKeywordDropdown";
import SearchCheckBoxBlock from "../../components/advertisingMirror/SearchCheckBoxBlock";
import {generateSearchButtons} from "../../help/generateSearchButtons";

const {RangePicker} = DatePicker;

const focusInput = (ref: React.RefObject<InputRef>) => {
    ref.current!.focus({
        cursor: "end",
    });
};

export default function AddCompanyModalContent({onClose}: any) {

    //auto, card, catalogue, search
    const [modalMode, setModalMode] = useState<string>("search");

    //budget input refs:
    const inputBudgetRef = useRef<InputRef>(null);
    const inputBetRef = useRef<InputRef>(null);
    const inputTargetRef = useRef<InputRef>(null);
    const inputMaxBetRef = useRef<InputRef>(null);

    const [budget, setBudget] = useState<any>(0)
    const [forecastOfImpressions, setForecastOfImpressions] = useState<any>(0)

    const [budgetCompany, setBudgetCompany] = useState<any>(0)
    const [bet, setBet] = useState<any>(0)
    const [targetLocation, setTargetLocation] = useState<any>(0)
    const [maximumBid, setMaximumBid] = useState<any>(0)

    const [addKeywordOpened, setAddKeywordOpened] = useState<boolean>(false);

    const [selectedSearchBlock, setSelectedSearchBlock] = useState<any>(null)

    const [budgetBlock, setBudgetBlock] = useState<any>([])

    const [keywordsWb, setKeywordsWb] = useState<any>([
        {id: 1, value: 'Куртка', selected: false},
        {id: 2, value: 'Ногти', selected: false},
        {id: 3, value: 'Ботинки', selected: false},
        {id: 4, value: 'Кепки', selected: false},
    ]);

    const [fixedPhrases, setFixedPhrases] = useState<any>([
        {id: 5, value: 'Куртка', selected: false},
        {id: 6, value: 'Кофе', selected: false},
        {id: 7, value: 'Танцы', selected: false},
        {id: 8, value: 'Борьба', selected: false},
    ]);

    const [minusPhrase, setMinusPhrase] = useState<any>([
        {id: 9, value: 'Наушники', selected: false},
        {id: 10, value: 'Телефон', selected: false},
        {id: 11, value: 'Чашка', selected: false},
        {id: 12, value: 'Ведро', selected: false},
    ]);

    const handleChangeSearchItems = (id: any, blockToSet: any) => {

        if (blockToSet === setKeywordsWb) {
            setSelectedSearchBlock('keywordsWb')
            setFixedPhrases(fixedPhrases.map((item: any) => ({...item, selected: false})))
            setMinusPhrase(minusPhrase.map((item: any) => ({...item, selected: false})))

            blockToSet((prevBlocks: any) =>
                prevBlocks.map((block: any) =>
                    block.id === id ? {...block, selected: !block.selected} : block
                )
            );
        } else if (blockToSet === setFixedPhrases) {
            setSelectedSearchBlock('fixedPhrases')

            setKeywordsWb(keywordsWb.map((item: any) => ({...item, selected: false})))
            setMinusPhrase(minusPhrase.map((item: any) => ({...item, selected: false})))

            blockToSet((prevBlocks: any) =>
                prevBlocks.map((block: any) =>
                    block.id === id ? {...block, selected: !block.selected} : block
                )
            );
        } else {
            setSelectedSearchBlock('minusPhrase')

            setKeywordsWb(keywordsWb.map((item: any) => ({...item, selected: false})))
            setFixedPhrases(fixedPhrases.map((item: any) => ({...item, selected: false})))

            blockToSet((prevBlocks: any) =>
                prevBlocks.map((block: any) =>
                    block.id === id ? {...block, selected: !block.selected} : block
                )
            );
        }
    }

    const onChangeBudgetBlock = (title: any, dateStrings: any, index: any) => {
        let find = budgetBlock.find((item: any) => item.title === title);
        // dateStrings[0]
        // dateStrings[1]
    }

    useEffect(() => {
        let findKeywordsWb = keywordsWb.find((item: any) => item.selected === true);
        let findFixedPhrases = fixedPhrases.find((item: any) => item.selected === true);
        let findMinusPhrase = minusPhrase.find((item: any) => item.selected === true);
        if (!findKeywordsWb && !findFixedPhrases && !findMinusPhrase) {
            setSelectedSearchBlock('')
        }
    }, [keywordsWb, fixedPhrases, minusPhrase])

    return (
        <div
            className={"modal-wrapper"}
            style={{
                padding: "30px",
                width: modalMode === "search" ? 1050 : 519,
            }}
        >
            <div>
                <ModalHeader title={"Добавление компании"} onClose={onClose}/>
            </div>
            <Row gutter={24}>
                <Col span={modalMode === "search" ? 12 : 24}>
                    <Form layout={"vertical"}>
                        <Form.Item label={"Введите название компании"}>
                            <Input style={{fontWeight: 600}}/>
                        </Form.Item>

                        <Form.Item
                            label={"Выберите тип рекламной компании"}
                            style={{marginBottom: "20px"}}
                            name={'type'}
                        >
                            <Select
                                style={{width: "100%"}}
                                onChange={(value: string) => setModalMode(value)}
                                defaultValue={"auto"}
                                options={[
                                    {value: "auto", label: "Автоматическая компания"},
                                    {value: "card", label: "Карточка товара"},
                                    {value: "catalogue", label: "Каталог"},
                                    {value: "search", label: "Поиск"},
                                ]}
                            />
                        </Form.Item>

                        {modalMode !== "auto" && (
                            <Form.Item label={"Выберите категории для размещения товаров"}>
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    style={{width: "100%"}}
                                    onChange={(values) => setBudgetBlock(values)}
                                    options={[
                                        {
                                            value: "jewelry",
                                            label: "Бижутерия",
                                        },
                                        {
                                            value: "outerwear",
                                            label: "Верхняя одежда",
                                        },
                                        {
                                            value: "womensClothing",
                                            label: "Женская одежда",
                                        },
                                        {
                                            value: "lingerie",
                                            label: "Нижнее бельё",
                                        },
                                        {
                                            value: "bedLinen",
                                            label: "Постельное бельё",
                                        },
                                        {
                                            value: "shoes",
                                            label: "Обувь",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        )}

                        {modalMode !== 'auto' &&
                        <Form.Item
                            label={"Выберите артикул"}
                            name={'acticle'}
                        >
                            <Select
                                mode={"multiple"}
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "123776680", label: "123776680"},
                                    {value: "123776680", label: "123776680"},
                                    {value: "123776680", label: "123776680"},
                                ]}
                            />
                        </Form.Item>
                        }

                        <Form.Item
                            label={"Выберите счет, с которого будет списываться бюджет РК"}
                            name={'wallet'}
                        >
                            <Select
                                mode={"multiple"}
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "123776680", label: "123776680"},
                                    {value: "123776680", label: "123776680"},
                                    {value: "123776680", label: "123776680"},
                                ]}
                            />
                        </Form.Item>

                        {modalMode === 'auto' &&
                        <div className="budget-wrapper">
                            <div className={"ant-form-item-label"}>
                                <label>
                                    Задайте бюджет, который Вы хотите потратить на рекламу и{" "}
                                    <br/> ставку
                                </label>
                            </div>
                            <Row>
                                <Col style={{width: "150px", marginRight: 100}}>
                                    <div className={"budget-input-title"}>Бюджет компании</div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            suffix={"₽"}
                                            ref={inputBudgetRef}
                                            value={budgetCompany}
                                            onChange={(e: any) => setBudgetCompany(e.target.value)}
                                        />
                                        <Icon
                                            className={"budget-input-edit-button"}
                                            component={editIcon}
                                            onClick={() => focusInput(inputBudgetRef)}
                                        />
                                    </div>
                                </Col>
                                <Col style={{width: "150px"}}>
                                    <div className={"budget-input-title"}>Прогноз показов</div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            suffix={"₽"}
                                            ref={inputBudgetRef}
                                            value={forecastOfImpressions}
                                            onChange={(e: any) => setForecastOfImpressions(e.target.value)}
                                        />
                                        <Icon
                                            className={"budget-input-edit-button"}
                                            component={editIcon}
                                            onClick={() => focusInput(inputBudgetRef)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        }

                        {(budgetBlock ?? []).map((item: any) => {
                            return (
                                <>
                                    <div style={{
                                        color: '#4c78ee',
                                        marginTop: 20,
                                        fontSize: 20
                                    }}>
                                        {item}
                                    </div>
                                    <div className="budget-wrapper">
                                        <div className={"ant-form-item-label"}>
                                            <label>
                                                Задайте бюджет, который Вы хотите потратить на рекламу и{" "}
                                                <br/> ставку
                                            </label>
                                        </div>
                                        <Row justify={"space-between"}>
                                            <Col style={{width: "100px"}}>
                                                <div className={"budget-input-title"}>Бюджет</div>
                                                <div className={"budget-input-container"}>
                                                    <Input
                                                        className={"budget-input"}
                                                        suffix={"₽"}
                                                        ref={inputBudgetRef}
                                                        value={budget}
                                                        onChange={(e: any) => setBudget(e.target.value)}
                                                    />
                                                    <Icon
                                                        className={"budget-input-edit-button"}
                                                        component={editIcon}
                                                        onClick={() => focusInput(inputBudgetRef)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col style={{width: "100px"}}>
                                                <div className={"budget-input-title"}>Ставка</div>
                                                <div className={"budget-input-container"}>
                                                    <Input
                                                        className={"budget-input"}
                                                        suffix={"₽"}
                                                        value={bet}
                                                        onChange={(e: any) => setBet(e.target.value)}
                                                        ref={inputBetRef}
                                                    />
                                                    <Icon
                                                        className={"budget-input-edit-button"}
                                                        component={editIcon}
                                                        onClick={() => focusInput(inputBetRef)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col style={{width: "95px"}}>
                                                <div className={"budget-input-title"}>Целевое место</div>
                                                <div className={"budget-input-container"}>
                                                    <Input
                                                        className={"budget-input"}
                                                        ref={inputTargetRef}
                                                        value={targetLocation}
                                                        onChange={(e: any) => setTargetLocation(e.target.value)}
                                                    />
                                                    <Icon
                                                        className={"budget-input-edit-button"}
                                                        component={editIcon}
                                                        onClick={() => focusInput(inputTargetRef)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col style={{width: "138px"}}>
                                                <div className={"budget-input-title"}>
                                                    Максимальная ставка
                                                </div>
                                                <div className={"budget-input-container"}>
                                                    <Input
                                                        className={"budget-input"}
                                                        suffix={"₽"}
                                                        ref={inputMaxBetRef}
                                                        value={maximumBid}
                                                        onChange={(e: any) => setMaximumBid(e.target.value)}
                                                    />
                                                    <Icon
                                                        className={"budget-input-edit-button"}
                                                        component={editIcon}
                                                        onClick={() => focusInput(inputMaxBetRef)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={"entrance-rate-wrapper"}>
                                        <div className={"entrance-rate-label"}>
                                            Входные ставки из кабинета WB
                                        </div>
                                        <Row justify={"space-between"} gutter={8}>
                                            <Col span={6}>
                                                <div className={"entrance-rate-selected"}>
                                                    1ое - <span>1500 ₽</span>
                                                </div>
                                                <div className={"entrance-rate-selected"}>
                                                    2ое - <span>1200 ₽</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className={"entrance-rate"}>
                                                    3ое - <span>1000 ₽</span>
                                                </div>
                                                <div className={"entrance-rate"}>
                                                    4ое - <span>1000 ₽</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className={"entrance-rate"}>
                                                    5ое - <span>1000 ₽</span>
                                                </div>
                                                <div className={"entrance-rate"}>
                                                    6ое - <span>1000 ₽</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className={"entrance-rate"}>
                                                    7ое - <span>1000 ₽</span>
                                                </div>
                                                <div className={"entrance-rate"}>
                                                    8ое - <span>1000 ₽</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={"datepick-wrapper"}>
                                        <div className={"ant-form-item-label"}>
                                            <label>
                                                Задайте даты проведения компании и временной <br/>
                                                промежуток показа
                                            </label>
                                        </div>

                                        <div className={"datepick-container"}>
                                            <RangePicker
                                                // onChange
                                                style={{width: "100%"}}
                                                showTime={{format: 'HH:mm'}}
                                                format="DD.MM.YY HH:mm"
                                                placeholder={["Начало", "Конец"]}
                                                separator={<Icon component={rangePickerArrow}/>}
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        }) ?? <></>}


                        <Row gutter={16} style={{paddingTop: "12px"}}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    style={{width: "100%"}}
                                    type={"primary"}
                                >
                                    Запустить
                                </Button>
                            </Col>
                            <Col span={12}>
                                {" "}
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    ghost
                                    style={{width: "100%"}}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                {modalMode === "search" && (
                    <Col span={12}>
                        <Row gutter={16} style={{marginTop: "25px"}}>
                            <Col span={12}>
                                <div className={"ant-form-item-label"}>
                                    <label>Ключевые фразы WB</label>
                                </div>
                                <div className="keyword-input-container">
                                    <SearchCheckBoxBlock
                                        blocks={keywordsWb}
                                        handleChangeSearchItems={handleChangeSearchItems}
                                        blockToSet={setKeywordsWb}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={"ant-form-item-label plus-container"}>
                                    <label>Фиксированные фразы</label>
                                    <div
                                        className={"plus-button"}
                                        onClick={() => setAddKeywordOpened(!addKeywordOpened)}
                                    >
                                        +
                                    </div>
                                    <AddKeywordDropdown
                                        open={addKeywordOpened}
                                        keywordsWb={keywordsWb}
                                        setKeywordsWb={setKeywordsWb}
                                        onClose={() => setAddKeywordOpened(false)}
                                    />
                                </div>
                                <div className="keyword-input-container">
                                    <SearchCheckBoxBlock
                                        blocks={fixedPhrases}
                                        handleChangeSearchItems={handleChangeSearchItems}
                                        blockToSet={setFixedPhrases}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "16px"}}>
                            <Col span={24}>
                                <div className={"ant-form-item-label"}>
                                    <label>Минус фразы</label>
                                </div>
                                <div
                                    className={"keyword-input-container"}
                                    style={{minHeight: "300px"}}
                                >
                                    <SearchCheckBoxBlock
                                        blocks={minusPhrase}
                                        handleChangeSearchItems={handleChangeSearchItems}
                                        blockToSet={setMinusPhrase}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className={"keyword-select-buttons-container"}>
                            {
                                generateSearchButtons(
                                    selectedSearchBlock,
                                    setKeywordsWb,
                                    setFixedPhrases,
                                    setMinusPhrase,
                                    keywordsWb,
                                    fixedPhrases,
                                    minusPhrase
                                )
                            }
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}
