import React from 'react';
import {Button, Col, Row, Table} from "antd";
import burgerIcon from '../../../../assets/images/burgerIcon.svg'
import {useNavigate} from "react-router-dom";

const ListOfShipmentFilesTable = ({tab, setTab}: any) => {

    const navigate = useNavigate();

    const data = [
        {
            id: '1',
            name: 'test',
        },
        {
            id: '2',
            name: 'test',
        },
        {
            id: '3',
            name: 'test',
        },
        {
            id: '4',
            name: 'test',
        },
    ]

    return (
        <div>
            <Row
                className={"container"}
                style={{marginTop: "20px", minHeight: "800px"}}
            >
                <Col span={24} >
                    <div className="saved-invoices-wrap">
                        {
                            data.map((item: any) =>
                                <div className="saved-invoices-item">
                                    <div
                                        onClick={() => navigate('/storage/list-of-shipment-files/full-info/123')}
                                        className="saved-invoices-item-left"
                                    >
                                        <img src={burgerIcon} alt=""/>
                                        <div className="saved-invoices-item-title">
                                            {item.name}
                                        </div>
                                    </div>
                                    <div className="saved-invoices-item-right">
                                        <Button
                                            className={"button"}
                                            type={"primary"}
                                            style={{width: "222px"}}
                                        >
                                            Отправить на ТСД
                                        </Button>
                                        <Button
                                            style={{ background: "red", borderColor: "yellow", width: "222px", color: '#fff' }}
                                            className={"button"}
                                            type={"primary"}
                                        >
                                            Удалить
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ListOfShipmentFilesTable;