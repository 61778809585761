import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useKeyWordsGroupList() {

    const [searchValue, setSearchValue] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");

    const [debouncedSearchText] = useDebounce(searchValue, 500);

    const { data: keyWordsGroup, ...options } = useQuery(
        [
            "KEY_WORDS_GROUP_LIST",
            debouncedSearchText,
            currentPage,
            currentPageSize,
        ],
        () => backend.keyWords.getKeyWordsGroupData(
            debouncedSearchText,
            currentPage.toString(),
            currentPageSize,
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        keyWordsGroup,
        currentPage,
        currentPageSize,
        setCurrentPage,
        setCurrentPageSize,
        searchValue,
        setSearchValue,
        ...options,
    };
}
