import ModalHeader from "features/shared/ModalHeader";
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useRef,
    useState
} from "react";
import {AutoComplete, Button, Checkbox, Col, Dropdown, Input, MenuProps, message, Select, Spin} from "antd";
import bucket from '../../../assets/images/bucket.svg'
import {LoadingOutlined} from "@ant-design/icons";
import useCoordinatorUserDataById from "../../coordinatorUsers/hooks/useCoordinatorUserDataById";
import {useCoordinatorDeleteAnalytics} from "../../coordinatorUsers/hooks/useCoordinatorDeleteAnalytics";
import {useCoordinatorPostAnalytics} from "../../coordinatorUsers/hooks/useCoordinatorPostAnalytics";
import useUserListByRole from "../../users/hooks/useUserListByRole";

const { Option } = Select;

export default function EditCoordinatorAnalytics({onClose, coordinatorId}: any) {


    const [selectedAnalyticUser, setSelectedAnalyticUser] = useState<Array<string>>([]);

    const {
        isLoading,
        coordinatorUser,
        setId,
    } = useCoordinatorUserDataById()

    const {
        users,
        setRoleId,
        searchValue,
        setSearchValue,
    } = useUserListByRole();


    useEffect(() =>{
        setRoleId(3)
    },[coordinatorId])

    const handleCheckForCommonUsers = (arr1: any, arr2: any) =>{
        let uniqueArray: any[] = [];

        arr1.forEach((obj1: any) => {
            const isDuplicate = arr2?.some((obj2: any) => obj2.user?.id === obj1.id);
            if (!isDuplicate) {
                uniqueArray.push(obj1);
            }
        });

        arr2.forEach((obj2: any) => {
            const isDuplicate = arr1?.some((obj1: any) => obj1.id === obj2.user?.id);
            if (!isDuplicate) {
                uniqueArray.push(obj2);
            }
        });

        return uniqueArray;
    }

    const {
        isLoading: deleteLoading,
        handleDelete: handleDelete,
        isError: isErrorDelete,
        errorMessage: deleteMessage,
        isSuccess: successDelete,
        setId: setIdForDelete
    } = useCoordinatorDeleteAnalytics()

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
        setId: setIdForPost
    } = useCoordinatorPostAnalytics();

    useEffect(() =>{
        setId(coordinatorId)
        setIdForDelete(coordinatorId)
        setIdForPost(coordinatorId)
        setSelectedAnalyticUser([])
    },[coordinatorId])

    useEffect(() => {
        if (isErrorDelete){
            message.error(deleteMessage)
        }
    }, [isErrorDelete])

    useEffect(() => {
        if (isErrorCreate){
            message.error('Произошла ошибка при попытке добавить аналитиков')
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isSuccessCreate){
            setSelectedAnalyticUser([])
            setSearchValue('')
        }
    }, [isSuccessCreate])

    const getAnalyticsUsersFilterItems = () => {
        if (!users || !coordinatorUser?.analytics) {
            return [];
        }
        const items: MenuProps["items"] = [

        ];
        handleCheckForCommonUsers(users, coordinatorUser?.analytics)?.forEach((item: any) => {
            items.push({
                label: (
                    <div
                        style={{ display: "flex", gap: "10px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={item.firstName}
                            checked={selectedAnalyticUser.includes(item?.id.toString())}
                            onChange={(e) => {
                                let newSelectedAnalyticsUser = [...selectedAnalyticUser];
                                if (e.target.checked) {
                                    newSelectedAnalyticsUser.push(item?.id.toString());
                                } else {
                                    newSelectedAnalyticsUser = newSelectedAnalyticsUser.filter(
                                        (el) => el !== item?.id.toString()
                                    );
                                }
                                setSelectedAnalyticUser(newSelectedAnalyticsUser);
                            }}
                        >
                            {item.firstName} {item.lastName}
                        </Checkbox>
                    </div>
                ),
                key: `analyticUser-${item?.id}`,
            });
        });
        return items;
    };


    const handleSearch = (value: any) => {
        setSearchValue(value);
        console.log("Selected value:", value);
    };
    const handleSelect = (value: any) => {
        setSearchValue(value);
        console.log("Selected value:", value);
        // Добавьте здесь свой код для обработки выбранного значения
    };


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Редактировать закрепленного аналитика"}
                onClose={onClose}
            />

            <div className={"send-article-wrapper"}>
                <div className="edit-product-wrap">

                    {isLoading || deleteLoading
                        ?
                        <div
                            style={{
                                padding: "30px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        coordinatorUser?.analytics?.length <= 0
                            ? <div className="edit-product-item-analytic-empty">
                                На данный момент продукт не имеет аналитиков
                            </div>
                            :
                            coordinatorUser?.analytics?.map((item: any) =>
                                <div className="edit-product-item-analytic">
                                    {item?.user?.firstName}
                                    <img
                                        onClick={() => handleDelete(
                                            {
                                                analytictId: item?.user?.id.toString(),
                                                coordinatorId : coordinatorId
                                            }

                                        )}
                                        src={bucket}
                                        alt=""
                                        className='edit-product-item-analytic-bucket'
                                    />
                                </div>
                            )
                    }
                </div>
                <div className="products-edit">
                    Добавить нового аналитика
                </div>
                <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Введите ключевое слово"
                    value={searchValue}
                    onSearch={(e: any) => handleSearch(e)}
                    onSelect={handleSelect}
                    filterOption={false}
                >
                    {/* Здесь добавьте нужные варианты выбора */}
                    {users?.map((item: any) => (
                        <Option key={item.id} value={item.id.toString()}>
                            {`${item.lastName} ${item.firstName}`}
                        </Option>
                    ))}
                </Select>
                {/*<Dropdown*/}
                {/*    trigger={["click"]}*/}
                {/*    placement={"bottomRight"}*/}
                {/*    menu={{items: getAnalyticsUsersFilterItems()}}*/}
                {/*    overlayClassName="dropdown-border"*/}
                {/*>*/}
                {/*    <div*/}
                {/*        className="products-header-dropdown"*/}
                {/*        style={{*/}
                {/*            borderWidth: 1,*/}
                {/*            borderColor: '#000'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <div>*/}
                {/*            Выберите имя из списка*/}
                {/*        </div>*/}
                {/*        <Icon*/}
                {/*            component={SelectIconComponent}*/}
                {/*            style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Dropdown>*/}
                <div className="products-edit-buttons-wrap">
                    <Button
                        loading={createLoading}
                        disabled={!searchValue}
                        className="products-edit-button-save"
                        type={"primary"}
                        style={{width: "222px", fontSize: "12px"}}
                        // onClick={() => handleCreate({
                        //     coordinatorId: coordinatorId,
                        //     userIds: selectedAnalyticUser.map(item => Number(item))
                        // })}
                        onClick={() => handleCreate({
                            coordinatorId: coordinatorId,
                            userIds: [searchValue]
                        })}
                    >
                        Сохранить
                    </Button>
                    <Button
                        className="products-edit-button-exit"
                        type={"primary"}
                        style={{width: "222px", fontSize: "12px"}}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
}