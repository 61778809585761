import { Col, Row } from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import DeclarationsTable from "../../features/kiz/pages/declaration/DeclarationsTable";
import ListOfAcceptanceFilesTable from "../../features/kiz/pages/listOfAcceptanceFiles/ListOfAcceptanceFilesTable";

export default function ListOfAcceptanceFiles() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <ListOfAcceptanceFilesTable />
            </Col>
        </Row>
    );
}
