import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, message} from "antd";
import useAutoPriceDataById from "../hooks/useAutoPriceDataById";
import {useChangeAutoPrice} from "../hooks/useChangeAutoPrice";

const RecommendedPriceForm = ({
                                  id,
                                  onClose
                              }: any) => {

    const [form] = Form.useForm<{}>();

    const [isAuto, setIsAuto] = useState(false)

    const {
        dataById
    } = useAutoPriceDataById(id)

    const {
        handleCreate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useChangeAutoPrice()

    const onFinishExtra = (value: any) => {
        console.log({
            id,
            recommendedPrice: Number(value?.recommendedPrice),
            autoChangeLt: Number(value?.autoChangeLt),
            autoChangeGt: Number(value?.autoChangeGt),
            isAuto: isAuto,
        })
        message.warning('Извините на данный момент функция не доступна')
        // handleCreate({
        //     id,
        //     recommendedPrice: Number(value?.recommendedPrice),
        //     autoChangeLt: Number(value?.autoChangeLt),
        //     autoChangeGt: Number(value?.autoChangeGt),
        //     isAuto: isAuto,
        // })
    }

    useEffect(() => {
        form.setFieldsValue({
            recommendedPrice: dataById?.recommendedPrice,
            autoChangeLt: dataById?.autoChangeLt,
            autoChangeGt: dataById?.autoChangeGt
        })
        setIsAuto(dataById?.isAuto)
    }, [dataById])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
        if (isSuccess) {
            message.success('Вы успешно изменили данные')
            onClose()
        }
    }, [isError, isSuccess])

    return (
        <Form
            onFinish={(values) => onFinishExtra(values)}
            form={form}
            layout={"vertical"}
        >
            <Form.Item
                rules={[{required: true}]}
                name={"recommendedPrice"}
                label={"Рекомендуемая цена"}
            >
                <Input
                    type={"number"}
                />
            </Form.Item>

            <Form.Item
                rules={[{required: true}]}
                name={"autoChangeLt"}
                label={"Позволительное изменение до (руб)"}
            >
                <Input
                    type={"number"}
                />
            </Form.Item>

            <Form.Item
                rules={[{required: true}]}
                name={"autoChangeGt"}
                label={"Предел изменения автоматической цены после (руб)"}
            >
                <Input
                    type={"number"}
                />
            </Form.Item>

            <Checkbox
                style={{
                    marginBottom: 20,
                }}
                checked={isAuto}
                onChange={(e: any) => setIsAuto(e?.target?.checked)}
            >
                Автоматическое изменение цены
            </Checkbox>

            <Col style={{display: "flex", gap: "15px"}}>
                <Button
                    type={"primary"}
                    className={"button"}
                    style={{fontSize: "12px", width: "50%"}}
                    htmlType={"submit"}
                    loading={isLoading}
                >
                    Сохранить
                </Button>
                <Button
                    type={"primary"}
                    ghost
                    className={"button"}
                    style={{fontSize: "12px", width: "50%"}}
                    onClick={onClose}
                >
                    Отмена
                </Button>
            </Col>
        </Form>
    );
};

export default RecommendedPriceForm;