import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";

export default function useCashbackList() {

    const [filteredList, setFilteredList] = useState([])
    const [search, setSearch] = useState<any>('')

    const onSearch = (e: any) =>{
        setFilteredList(cashBackList.filter((item: any) => item?.name?.toLowerCase()?.includes(e?.toLowerCase())))
    }

    const {data: cashBackList, ...options} = useQuery(
        [
            "CASHBACK_LIST",
        ],
        () =>
            backend.analytics.getCashBackList(
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() =>{
        setFilteredList(cashBackList)
    },[cashBackList])


    return {
        cashBackList: filteredList,
        onSearch,
        ...options,
    };
}
