import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {useAddfile} from "../hooks/useAddfile";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

export default function AddFileModal({onClose, modalData, selectedSeller}: any) {

    const [form] = Form.useForm();

    // const [selectedSeller, setSelectedSeller] = useState<any>();
    const [selectedType, setSelectedType] = useState<any>();
    const [selectedFile, setSelectedFile] = useState<any>();

    // const {sellers} = useSellerList();

    const {
        handleAddFile,
        errorMessage,
        isLoading,
        isSuccess,
        isError
    } = useAddfile()

    const onFinish = (values: any) => {

        if (!selectedFile){
            return message.error('Пожалуйста выберите файл')
        }

        console.log({
            seller_id: selectedSeller,
            step: selectedType,
            file: selectedFile,
            filename: values?.filename
        })

        handleAddFile({
            seller_id: selectedSeller,
            step: selectedType,
            file: selectedFile,
            filename: values?.filename
        })
    };

    const fileInputRef = useRef<any>(null);

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];

        setSelectedFile(selectedFile)
    };

    useEffect(() =>{
        if (isSuccess){
            message.success('Вы успешно добавили файл')
            onClose()
            form.resetFields()
            setSelectedFile('')
            fileInputRef.current.value = ''
        }
        if (isError){
            message.error(errorMessage || 'Произошла ошибка при попытке добавить файл, обратитесь в поддержку')
        }
    },[isSuccess, isError])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px", width: '90%'}} >
            <ModalHeader title={'Добавить файл в диаграмму'} onClose={() =>{
                onClose()
                form.resetFields()
                setSelectedFile('')
                fileInputRef.current.value = ''
            }}/>
            <Row
                className={"container"}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                    style={{
                        width: '100%'
                    }}
                >
                    {/*<Form.Item*/}
                    {/*    style={{*/}
                    {/*        width: '100%'*/}
                    {/*    }}*/}
                    {/*    label={'Выберите инвестора'}*/}
                    {/*    name="seller_id"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: "Пожалуйста, введите название",*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Select*/}
                    {/*        style={{*/}
                    {/*            width: '100%'*/}
                    {/*        }}*/}
                    {/*        showSearch*/}
                    {/*        value={selectedSeller}*/}
                    {/*        filterOption={false}*/}
                    {/*        suffixIcon={<Icon component={SelectIconComponent}*/}
                    {/*                          style={{marginTop: "2px", fontSize: "10px"}}/>}*/}
                    {/*        onChange={(e: any, option: any) => {*/}
                    {/*            setSelectedSeller(e);*/}
                    {/*        }}*/}
                    {/*        optionLabelProp="children"*/}
                    {/*    >*/}
                    {/*        {sellers?.data?.map((option: any) => (*/}
                    {/*            <Option key={option?.wbArticle} value={option?.id}>*/}
                    {/*                {option.companyName}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        label={'Выберите раздел'}
                        name="step"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                        style={{
                            width: '100%'
                        }}
                    >
                        <Select
                            style={{
                                width: '100%'
                            }}
                            suffixIcon={<Icon component={SelectIconComponent}/>}
                            onChange={(e) => {
                                setSelectedType(e);
                            }}
                            options={[
                                {value: "1", label: "Подписание документов"},
                                {value: "2", label: "Закуп товара"},
                                {value: "3", label: "Растаможка товара"},
                                {value: "4", label: "Услуги фулфилмента"},
                                {value: "5", label: "Отгрузка на ВБ"},
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'Введите название файла'}
                        name="filename"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    {selectedFile?.name &&
                    <div>
                        {selectedFile?.name}
                    </div>
                    }
                    <input
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.fiff"
                        type="file"
                        ref={fileInputRef}
                        style={{display: "none"}}
                        onChange={handleInputChange}
                    />
                    <Button
                        type="dashed"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 10,
                        }}
                        onClick={handleFileSelect}
                    >
                        Добавить файл
                    </Button>
                    <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                        <Col span={12}>
                            <Button
                                loading={isLoading}
                                className={"button"}
                                type={"primary"}
                                style={{width: "100%", backgroundColor: '#5c4e73'}}
                                htmlType={"submit"}
                            >
                                Добавить
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </div>
    );
}
