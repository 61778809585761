import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useTopologyTypeData() {

    const [type, setType] = useState<any>('location');

    const {data: topologyTypesData, ...options} = useQuery(
        [
            "MARKING_DATA",
            type
        ],
        () =>
            backend.storage.getTopologyTypeData(
                type.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        topologyTypesData,
        type,
        setType,
        ...options,
    };
}
