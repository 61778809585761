import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";

export default function SuccessUploadCustomedFileModal({onClose, backendData}: any) {


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Результат загрузки"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"}>
                        <Form.Item label={"Общее количество"}>
                            <Input disabled={true} value={backendData?.result?.total}/>
                        </Form.Item>
                        <Form.Item label={"Обработанные"}>
                            <Input disabled={true} value={backendData?.result?.processed}/>
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{
                                    fontSize: "12px",
                                    width: "100%",
                                }}
                                onClick={async () => {
                                    await onClose()
                                }}
                            >
                                Ок
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
