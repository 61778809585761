import {api} from "../index";

export async function getExpensesAdData(
    page?: string,
    pageSize?: string,
    orderByExpenses?: string,
) {
    let params = new URLSearchParams();
    
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (orderByExpenses) {
        const orderData = orderByExpenses.split(':');

        params.append("order_by", orderData[1]);
        params.append("filter_by", orderData[0]);
    }

    const response = await api.get<ApiResponseList<any>>(`/cashbacks/ad-expenses`, {
        params,
    });
    return response.data.data;
}

export async function getExpensesUserData(
    page?: string,
    pageSize?: string,
    orderByUser?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    if (orderByUser) {
        const orderData = orderByUser.split(':');

        params.append("order_by", orderData[1]);
        params.append("filter_by", orderData[0]);
    }

    const response = await api.get<ApiResponseList<any>>(`/contract-operations/expenses`, {
        params,
    });
    return response.data.data;
}

export async function createExpensesAd(json: any) {
    const response = await api.post<any>(`/cashbacks/ad-expenses`, json);
    return response.data;
}

export async function getExpensesAdById(
    id: string,
) {
    const response = await api.get<any>(`/cashbacks/ad-expenses/${id}`);
    return response.data.data;
}

export async function updateExpensesAdById(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(`/cashbacks/ad-expenses/${params.id}`, body);
    return response.data;
}