import React from 'react';
import { ReactComponent as uploadButtonIcon } from "../../../assets/images/upload-button-icon.svg";
import { Button, message } from "antd";
import Icon from "@ant-design/icons";
import axios from "axios";
import { getToken } from "../../../utils/tokenStorage";
import dayjs from "dayjs";


const generateFilename = () => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  return `otgruzki_${currentDate}.xlsx`;
};


export const DownloadShipmentsButton = ({
                                          page,
                                          pageSize,
                                          searchText,
                                          sizes,
                                          products,
                                          selectedSeller,
                                          selectedWarehouse,
                                          selectedPeriod,
                                        }: any) => {

  const token = getToken();

  const handleDownload = () => {
    message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

    let params = new URLSearchParams();

    if (pageSize) {
      params.append("limit", pageSize);
    }
    if (page) {
      params.append("page", page);
    }
    if (selectedPeriod) {
      params.append("period", selectedPeriod);
    }
    if (sizes && sizes.length > 0) {
      params.append("sizes", sizes?.map((item: any) => item?.title).join(","));
    }
    if (products && products.length > 0) {
      params.append("categories", products?.map((item: any) => item?.id)?.join(","));
    }
    if (selectedSeller && selectedSeller.length > 0) {
      params.append("sellers", selectedSeller?.map((item: any) => item?.id)?.join(","));
    }
    if (selectedWarehouse && selectedWarehouse.length > 0) {
      params.append("warehouses", selectedWarehouse?.map((item: any) => item?.id)?.join(","));
    }

    if (searchText) {
      params.append("search", searchText);
    }

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}otgruzki/download`,
      responseType: 'blob',
      params: params,
      headers: {
        Authorization: `Bearer ${token}`
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', generateFilename());
      link.setAttribute('target', '_blank');
      link.type = 'application/xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  return (
    <Button
      onClick={handleDownload}
      className="table-header-button"
      type={"primary"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Выгрузить
      <Icon
        component={uploadButtonIcon}
        style={{ fontSize: "18px", display: "block" }}
      />
    </Button>
  );
};

