import {useQuery} from "react-query";
import {backend} from "api";
import {useState} from "react";

const PAGE_LIMIT = 10;

export default function useAnalyticsCashbackUsersList() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortType, setSortType] = useState<string>("desc");
    const [sortParam, setSortParam] = useState<string>("id");

    const {data: analyticsUsersCashbacks, ...options} = useQuery(
        [
            "CASHBACK_USERS_RESULT_LIST",
            currentPage,
            sortType,
            sortParam
        ],
        () =>
            backend.analytics.getAnalyticsCashbacksUsersData(
                currentPage.toString(),
                PAGE_LIMIT.toString(),
                sortType,
                sortParam
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        analyticsUsersCashbacks,
        currentPage,
        setCurrentPage,
        sortType,
        sortParam,
        setSortType,
        setSortParam,
        ...options,
    };
}
