import { useAuthCheck } from "features/auth/hooks/useAuthCheck";
import { useAuthStore } from "features/auth/hooks/useAuthStore";
import { useMount } from "hooks/useMount";
import { useState } from "react";
import PublicRoutes from "./PublicRoutes";
import SecureRoutes from "./SecureRoutes";

export default function AppRoutes() {
    const authStore = useAuthStore();
    const { checkAuth } = useAuthCheck();
    const [render, setRender] = useState<boolean>(false);

    useMount(() => checkAuth().finally(() => setRender(true)));

    if (!render) return null;

    return authStore.state.isLoggedIn ? <SecureRoutes /> : <PublicRoutes />;
}
