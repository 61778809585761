import { useState } from "react";
import { Col, Row } from "antd";
import OrdersFromChinaTab from "./OrdersFromChinaTab";
import DeliveriesFromChinaTab from "./DeliveriesFromChinaTab";

export default function OrdersFromChinaPage() {

    //addSk, listOfSavedInvoices
    const [tab, setTab] = useState<any>({
        route: "order",
        id: null
    });

    return (
        <Row>
            <Col style={{ padding: "10px 20px" }} span={24}>
                <div className={"storage-tabs"}>
                    <div
                        className={`${
                            tab.route === "order" ? "tab-active" : ""
                        }`}
                        onClick={() => setTab({...tab, route: "order"})}
                    >
                        Заказы
                    </div>
                    <div
                        className={`${
                            tab.route  === "deliveries" ? "tab-active" : ""
                        }`}
                        onClick={() => setTab({...tab, route: "deliveries"})}
                    >
                        Поставки
                    </div>
                </div>
                <Row>
                    <Col span={24}>
                        {tab.route === "order" && (
                            <OrdersFromChinaTab />
                        )}
                        {tab.route === "deliveries" && (
                            <DeliveriesFromChinaTab />
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
