import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useState} from "react";

export default function useListOfAcceptanceData() {

    const { data: acceptances, ...options } = useQuery(
        [
            "LIST_OF_ACCEPTANCE_DATA",
        ],
        () =>
            backend.declaration.getListOfAcceptanceData(),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        acceptances,
        ...options,
    };
}
