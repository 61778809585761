import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Table,
} from "antd";
import {useNavigate} from "react-router-dom";
import AddSelectionModal from "../modal/AddSelectionModal";


export default function AdsSelectionTablet() {

  const navigate = useNavigate()

  const [addSelectionModal, setAddSelectionModal] = useState(false)

  const data = [
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
  ]
  const columns = [
    {
      title: "name1",
      dataIndex: "name1",
      key: "name1",
      width: "35%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/adsSelection/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: "name2",
      dataIndex: "name2",
      key: "name2",
      width: "35%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/adsSelection/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: "name3",
      dataIndex: "name3",
      key: "name3",
      width: "35%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/adsSelection/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text}
          </div>
        )
      }
    },
  ];

  return (
    <div style={{backgroundColor: '#f8f8f8'}}>
      <Row gutter={6} align={"middle"} justify={'end'}>
        <Col>
          <Button
            type={"primary"}
            style={{
              fontWeight: 600,
              fontSize: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            // onClick={handleFileSelect}
            onClick={() => setAddSelectionModal(true)}
          >
            Добавить выборку
          </Button>
        </Col>
      </Row>
      <Row className={"container"}>
        <Col span={24}>
          <Table
            // loading={isLoading}
            className="product-arrival-table"
            columns={columns}
            // dataSource={storageData?.data?.map((item: any, index: any) => ({
            //     ...item,
            //     key: index,
            // }))}
            dataSource={data}
            scroll={{x: true}}
            pagination={{
                // onChange: (page, pageSize) => setCurrentPage(page),
                position: ["bottomCenter"],
                // pageSize: Number(currentPageSize),
                // total: Number(emissions?.total),
                // showSizeChanger: false,
                // current: currentPage,
            }}
          />
        </Col>
      </Row>
      <Modal
        open={addSelectionModal}
        closable={false}
        footer={null}
        width={500}
      >
        <AddSelectionModal
          onClose={() => setAddSelectionModal(false)}
        />
      </Modal>
    </div>
  );
}
