import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Input,
    Select,
    Button,
    Table,
    Dropdown,
    Checkbox,
    DatePicker,
    Modal, Pagination, message, Spin,
} from "antd";
import { ReactComponent as SelectIconComponent } from "../../../../assets/images/select-icon.svg";
import useIssuingPositionsData from "../../hooks/issuingPositions/useIssuingPositionsData";
import Icon, { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { ReactComponent as rangePickerArrow } from "../../../../assets/images/rangepicker-arrow.svg";
import BiderTableItem from "../../components/bider/BiderTableItem";
import useBiderData from "../../hooks/bider/useBiderData";

const { Option } = Select;

export default function BiderTable() {

    const {
        data,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        selectedSeller,
        getProductsFilterItems,
        sellers,
        setSearchSellers,
        setSelectedSeller,
        searchArticle,
        setSearchArticle,
        isLoading
    } = useBiderData()

    console.log(data)

    const renderData = () => {
        if (isLoading) {
            return (
                <div style={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    padding: 200
                }}>
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                    />
                </div>
            )
        }
        if (data?.data?.length === 0) {
            return (
                <div style={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    padding: 200
                }}>
                    Нет данных
                </div>
            )
        }
        if (data?.data?.length !== 0 ) {
            return (
                <div>
                    <div className="issuing-positions-wrap">
                        {
                            data?.data?.map((item: any) =>
                                <BiderTableItem
                                    item={item}
                                />
                            )
                        }
                    </div>
                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={Number(currentPageSize)}
                        total={data?.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </div>

            )
        }
    }

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{ flexGrow: "1" }}>
                    <Input
                        value={searchArticle}
                        onChange={(text: any) => setSearchArticle(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск по артикулу"
                        size="large"
                    />
                </Col>

                <Col style={{ width: "250px" }}>
                    <Select
                        className="table-header-select"
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSeller}
                        placeholder="Продавец"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{ marginTop: "2px", fontSize: "10px" }}
                        />}
                        onSearch={(value: any) => setSearchSellers(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.map((option: any) => {
                            return (
                                <Option key={option?.wb_article} value={option?.id}>
                                    {option.company_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>


                <Col style={{ width: "200px" }}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{ items: getProductsFilterItems() }}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Предмет</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{ marginTop: "2px", fontSize: "10px" }}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{ width: "200px" }}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent} />}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(Number(e));
                        }}
                        options={[
                            { value: "10", label: "Показать по 10 шт." },
                            { value: "50", label: "Показать по 50 шт." },
                            { value: "100", label: "Показать по 100 шт." },
                        ]}
                    />
                </Col>
            </Row>

            {renderData()}

        </div>
    );
}
