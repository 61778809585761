import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useUserList() {

    const [sortRoleId, setSortRoleId] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortParam, setSortParam] = useState<string>('id');
    const [orderBy, setOrderBy] = useState<string>('asc');
    const [search, setSearch] = useState<string>('');
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: users, ...options } = useQuery(
        [
            "USER_LIST",
            sortRoleId,
            currentPage,
            sortParam,
            orderBy,
            debouncedSearch
        ],
        () => backend.user.getUserList(
            sortRoleId,
            currentPage.toString(),
            sortParam,
            orderBy,
            debouncedSearch
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        users,
        sortRoleId,
        setSortRoleId,
        currentPage,
        setCurrentPage,
        sortParam,
        orderBy,
        setSortParam,
        setOrderBy,
        search,
        setSearch,
        ...options,
    };
}
