import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "../../../../api";
import {useCallback, useState} from "react";

export default function usePauseStatus() {
    const [id, setId] = useState<string>('');

    const { data: response, ...options } = useQuery(
        ["PAUSE_MIRROR_STATUS", id],
        () =>
            backend.advertisingMirror.mirrorPauseStatus(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        response,
        setId,
        id,
        ...options,
    };
}
