import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useCoordinatorsUserList() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchName, setSearchName] = useState<string>("");
    const [debouncedSearchName] = useDebounce(searchName, 1000);

    const { data: coordinatorUser, ...options } = useQuery(
        [
            "COORDINATOR_USER_LIST",
            currentPage,
            currentPageSize,
            debouncedSearchName,
        ],
        () => backend.coordinatorUser.getCoordinatorUserData(
            currentPage.toString(),
            currentPageSize,
            debouncedSearchName,
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        coordinatorUser,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchName,
        setSearchName,
        ...options,
    };
}
