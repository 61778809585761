import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "../../api";
import {useCallback, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useDeclarationData() {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<string>("10");
  const [order, setOrderBy] = useState<string>("");

  const [title, setTitle] = useState<string>("");
  const [gtin, setGtin] = useState<string>("");
  const [debounceTitle] = useDebounce(title, 1000)
  const [debounceGtin] = useDebounce(gtin, 1000)

  const [dateCreateStart, setDateCreateStart] = useState<string>("");
  const [dateCreateEnd, setDateCreateEnd] = useState<string>("");

  const [dateUpdateStart, setDateUpdateStart] = useState<string>("");
  const [dateUpdateEnd, setDateUpdateEnd] = useState<string>("");

  const [dateCustomedStart, setDateCustomedStart] = useState<string>("");
  const [dateCustomedEnd, setDateCustomedEnd] = useState<string>("");

  const [searchByProduct, setSearchByProduct] = useState<string | null>(null);

  const onChangeDateCreate = (dateStrings: any) => {
    if (!dateStrings) {
      setDateCreateStart('')
      setDateCreateEnd('')
    } else {
      setDateCreateStart(dateStrings[0])
      setDateCreateEnd(dateStrings[1])
    }
  }
  const onChangeDateUpdate = (dateStrings: any) => {
    if (!dateStrings) {
      setDateUpdateStart('')
      setDateUpdateEnd('')
    } else {
      setDateUpdateStart(dateStrings[0])
      setDateUpdateEnd(dateStrings[1])
    }
  }
  const onChangeDateCustomed = (dateStrings: any) => {
    if (!dateStrings) {
      setDateCustomedStart('')
      setDateCustomedEnd('')
    } else {
      setDateCustomedStart(dateStrings[0])
      setDateCustomedEnd(dateStrings[1])
    }
  }

  const {data: declaration, ...options} = useQuery(
    [
      "DECLARATION_DATA",
      currentPage,
      currentPageSize,
      order,
      dateCreateStart,
      dateCreateEnd,
      dateUpdateStart,
      dateUpdateEnd,
      dateCustomedStart,
      dateCustomedEnd,
      debounceTitle,
      debounceGtin,
      searchByProduct
    ],
    () =>
      backend.declaration.getDeclarationsData(
        currentPage.toString(),
        currentPageSize,
        order,
        dateCreateStart,
        dateCreateEnd,
        dateUpdateStart,
        dateUpdateEnd,
        dateCustomedStart,
        dateCustomedEnd,
        debounceTitle,
        debounceGtin,
        searchByProduct
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );


  return {
    declaration,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    order,
    setOrderBy,
    title,
    setTitle,
    gtin,
    setGtin,
    onChangeDateCreate,
    onChangeDateUpdate,
    onChangeDateCustomed,
    searchByProduct,
    setSearchByProduct,
    ...options,
  };
}
