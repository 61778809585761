import {api} from "index";

// Requests
export async function getUserList(
    sortRoleId?: number,
    currentPage?: string,
    sortParam?: string,
    orderBy?: string,
    search?: string
) {
    let params = new URLSearchParams();

    if (sortRoleId) {
        params.append("role_id", String(sortRoleId));
    }
    if (search) {
        params.append("search", String(search));
    }
    if (currentPage) {
        params.append("page", String(currentPage));
    }
    if (sortParam && orderBy) {
        params.append("parameter", String(sortParam));
        params.append("order_by", String(orderBy));
    }

    params.append("limit", String(1000));

    const response = await api.get<ApiResponseList<User>>(`/users`, {
        params
    });
    return response.data.data;
}

export async function getUserListByRole(
    roleId?: string,
    debouncedSearch?: string
) {
    let params = new URLSearchParams();

    if (roleId) {
        params.append("role_id", roleId);
    }
    if (debouncedSearch) {
        params.append("search", debouncedSearch);
    }

    const response = await api.get<any>(`/users/list`, {
        params,
    });
    return response.data.data;
}

export async function createUser(json: CreateUserBody) {
    const response = await api.post<ApiResponse<User>>(`/users`, json);
    return response.data.data;
}

export async function updateUser(params: UpdateUserParams) {
    const {id, ...body} = params;
    const response = await api.patch<ApiResponse<User>>(
        `/users/${params.id}`,
        body
    );
    return response.data.data;
}

export async function deleteUser(id: string) {
    const response = await api.delete(`/users/${id}`);
    return response.data.data;
}

export async function uploadMyPhoto(formData: any) {
    const response = await api.patch(`/users/me/photo`, formData);
    console.log(response);
    return response.data.data;
}


//current user

export async function updateCurrentUserAvatar(json: any) {
    const formData = new FormData()
    formData.append('file', json)

    const response = await api.patch<any>(`/users/me/photo`, formData);
    return response.data.data;
}

export async function getUserCurrentData() {
    const response = await api.get<any>(`/users/me`);
    return {...response.data.data, photo: `${response.data.data.photo}?${new Date()}`};
}
