import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Dropdown, message, Modal, Row} from "antd";
import EditProductBider from "../../modal/bider/EditProductBider";
import dayjs from "dayjs";
import greenArrowToTop from "../../../../assets/images/ArrowToTop.svg";
import arrowToBottom from "../../../../assets/images/ArrowToBottom.svg";

const BiderTableItem = ({item}: any) => {

    const [editBiderModal, setEditBiderModal] = useState<any>({
        isOpen: false,
        id: null
    });

    const renderArrow = (min: any, max: any) => {
        if (min === 0) return (
            <img
                src={greenArrowToTop}
                alt=""
                style={{width: 16, height: 16, marginBottom: 2}}
            />
        )
        if (max === 0) return (
            <img
                src={arrowToBottom}
                alt=""
                style={{width: 16, height: 16, marginBottom: 2}}
            />
        )
        if ((Number(min) - Number(max)) > 0) {
            return (
                <img
                    src={greenArrowToTop}
                    alt=""
                    style={{width: 16, height: 16, marginBottom: 2}}
                />
            )
        } else {
            return (
                <img
                    src={arrowToBottom}
                    alt=""
                    style={{width: 16, height: 16, marginBottom: 2}}
                />
            )
        }
    }

    return (
        <div className="issuing-positions-item">
            <div className="issuing-positions-item-info" style={{marginBottom: 10}}>
                <img src={item?.photo} className="issuing-positions-item-info-img" alt={'img'}/>
                <div className="issuing-positions-item-info-data">
                    <div className="issuing-positions-item-info-data-title">
                        {item?.productName}
                    </div>
                    <a
                        className="issuing-positions-item-info-data-elem"
                        href={`https://wildberries.ru/catalog/${item?.nmId}/detail.aspx?targetUrl=SP`}
                        target='_blank'
                        style={{
                            color: '#fff',
                            backgroundColor: '#4C78EE'
                        }}
                    >
                        {item?.nmId}
                    </a>
                </div>
            </div>

            <table className="issuing-positions-table">
                <thead>
                <th style={{width: '40%', fontWeight: 700}}>Главные ключевые слова</th>
                <th style={{width: '20%'}}>
                    {/*{dayjs(item?.minDate).format('DD.MM.YYYY')}*/}
                    До РК
                </th>
                <th style={{width: '20%'}}>
                    {/*{dayjs(item?.maxDate).format('DD.MM.YYYY')}*/}
                    В биддере
                </th>
                <th style={{width: '20%'}}></th>
                </thead>
                <tbody style={{width: '100%'}}>
                {
                    item?.keywords?.map((item: any) =>
                        <tr style={{width: '100%', textAlign: 'center'}}>
                            <td style={{width: '50%', textAlign: 'center', fontWeight: 700}}>
                                {item?.keywordTitle}
                            </td>
                            <td style={{width: '25%'}}>
                                {item?.minDatePosition == 0 ? '-' : item?.minDatePosition}
                            </td>
                            <td style={{width: '25%'}}>
                                {item?.maxDatePosition == 0 ? '-' : item?.maxDatePosition}
                            </td>
                            <td style={{width: '25%'}}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5
                                }}>
                                    {Math.abs(Number(item?.minDatePosition) - Number(item?.maxDatePosition))}
                                    {
                                        renderArrow(item?.minDatePosition, item?.maxDatePosition)
                                    }
                                </div>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>

            {/*<div style={{width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>*/}
            {/*    <div style={{fontWeight: 700, width: "50%"}}>*/}
            {/*        Главные ключевые слова*/}
            {/*    </div>*/}
            {/*    <div style={{fontWeight: 400, width: "50%"}}>*/}
            {/*        слово <br/>*/}
            {/*        слово <br/>*/}
            {/*        слово <br/>*/}
            {/*        слово <br/>*/}
            {/*        слово <br/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div style={{width: '100%', height: 1, margin: '10px 0', backgroundColor: '#BABFC7'}}/>

            <div style={{width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>
                <div style={{fontWeight: 700, width: "50%"}}>
                    Максимальная допустимая ставка
                </div>
                <div style={{fontWeight: 400, width: "50%"}}>
                    {item?.maxBet}
                </div>
            </div>
            <div style={{width: '100%', height: 1, margin: '10px 0', backgroundColor: '#BABFC7'}}/>

            <div style={{width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>
                <div style={{fontWeight: 700, width: "50%"}}>
                    Максимальный бюджет на день
                </div>
                <div style={{fontWeight: 400, width: "50%"}}>
                    {item?.maxBudget}
                </div>
            </div>
            <div style={{width: '100%', height: 1, margin: '10px 0', backgroundColor: '#BABFC7'}}/>

            <div style={{width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>
                <div style={{fontWeight: 700, width: "50%"}}>
                    Количество попыток для отключения при отрицательном росте
                </div>
                <div style={{fontWeight: 400, width: "50%"}}>
                    {item?.maxFallsCount}
                </div>
            </div>
            <div style={{width: '100%', height: 1, margin: '10px 0', backgroundColor: '#BABFC7'}}/>

            <div style={{width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>
                <div style={{fontWeight: 700, width: "50%"}}>
                    Шаг ставки
                </div>
                <div style={{fontWeight: 400, width: "50%"}}>
                    {item?.budgetStep}
                </div>
            </div>

            <div className="issuing-positions-item-table-buttons" style={{marginTop: 20}}>
                <Button
                    onClick={() => setEditBiderModal({
                        isOpen: true,
                        id: item?.productId
                    })}
                    className={"button"}
                    type={"primary"}
                    style={{width: "100%"}}
                    htmlType={"submit"}
                >
                    Редактировать
                </Button>
            </div>

            <Modal
                open={editBiderModal.isOpen}
                closable={false}
                footer={null}
                width={480}
            >
                <EditProductBider
                    onClose={() => setEditBiderModal({id: null, isOpen: false})}
                    productId={editBiderModal.id}
                />
            </Modal>
        </div>
    );
};

export default BiderTableItem;