import { Col, Row } from "antd";
import AnalyticsTable from "features/analytics/pages/AnalyticsTable";
import React from "react";
import {QuestionsAndAnswersBlock} from "../../features/questionsAndAnswers/QuestionsAndAnswersBlock";

export default function QuestionsAndAnswersPage() {
    return (
        <Row style={{ padding: "10px 20px" }} className='finance-page'>
            <Col className={"container finance-page"} span={24}>
                <QuestionsAndAnswersBlock/>
            </Col>
        </Row>
    );
}
