import {api, apiKiz, apiKizFullInfo} from "../index";
import dayjs from "dayjs";

export async function getProductArrivalData(
    page?: string,
    pageSize?: string,
    selectedGtd?: string,
    dateStart?: string,
    dateEnd?: string,
    orderBy?: string,
    title?: string,
    selectedArticleCcs?: string[],
    selectedArticleMkp?: string[],
    selectedArticlePvd?: string[],
    selectedArticleCns?: string[],
    selectedSizes?: string[],
    selectedSeller?: string[],
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (selectedGtd) {
        params.append("ccdid", selectedGtd);
    }
    if (orderBy) {
        params.append("order_by", orderBy);
    }
    if (title) {
        params.append("title", title);
    }

    if (selectedArticleCcs?.length !== 0 && selectedArticleCcs) {
        params.append("article_ccd", selectedArticleCcs?.join(','));
    }

    if (selectedArticleMkp?.length !== 0 && selectedArticleMkp) {
        params.append("article_mkp", selectedArticleMkp?.join(','));
    }

    if (selectedArticlePvd?.length !== 0 && selectedArticlePvd) {
        params.append("article_pvd", selectedArticlePvd?.join(','));
    }

    if (selectedArticleCns?.length !== 0 && selectedArticleCns) {
        params.append("article_cns", selectedArticleCns?.join(','));
    }

    if (selectedSizes?.length !== 0 && selectedSizes) {
        params.append("size", selectedSizes?.join(','));
    }

    if (selectedSeller && selectedSeller?.length > 0) {
        params.append("seller_ref", selectedSeller?.map((item: any) => item?.extRef)?.join(','));
    }

    if (dateStart && dateEnd) {
        params.append("created_after", dateStart);
        params.append("created_before", dateEnd);
    }

    const response = await api.get<any>(`/sthouse/products/delivered`, {
        params,
    });

    return response.data.result;
}

export async function getArticleByGTDId(
    gtdID: any
) {
    if (gtdID) {
        const response = await api.get<any>(`/sthouse/product-articles/by-ccd/${gtdID}`);

        return response.data.result;
    }
}

export async function getSizesByGTDIdAndArticle(
    gtdID: any,
    article: any
) {

    if (gtdID && article) {
        const response = await api.get<any>(`/sthouse/product-sizes/by-ccd-article/${gtdID}/${article}`);

        return response.data.result;
    }

}

export async function createProduct(json: any) {

    const response = await api.post<any>(`/sthouse/products/new`, json);
    return response.data;
}

export async function createProductArrival(json: any) {

    const {values, gtdId} = json

    let params = new URLSearchParams();

    if (values?.article) {
        params.append("article", values?.article);
    }
    if (values?.size) {
        params.append("size", values?.size);
    }
    if (values?.amount) {
        params.append("amount", values?.amount);
    }

    const response = await api.put<any>(`/sthouse/ccds/${gtdId}/deliveries`, {}, {params});
    return response.data;
}

export async function postChangeStatusShiments(json: any) {

    const {values, id} = json

    let params = new URLSearchParams();

    params.append("status", values);

    const response = await api.post<any>(`/product-sizes/${id}/delivery`, {}, {params});
    return response.data;
}

export async function postCompleteFormation(gtdId: any) {

    let params = new URLSearchParams();

    params.append("state", 'ccd_ready_for_delivery');

    if (gtdId) {
        const response = await api.patch<any>(`/sthouse/documents/${gtdId}`, {}, {params});
        return response.data;
    }
}

export async function getGTDData(
    page?: string,
    pageSize?: string,
    search?: string,
    dateStart?: string,
    dateEnd?: string,
    selectedStatus?: string
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (search) {
        params.append("title", search);
    }
    if (dateStart && dateEnd) {
        params.append("period_start_date", dateStart);
        params.append("period_end_date", dateEnd);
    }

    if (selectedStatus) {
        params.append("state", selectedStatus);
    }

    const response = await api.get<any>(`/sthouse/ccds/paginated`, {
        params,
    });

    return response.data.result;
}


export async function getShimpentsFormationData(
    page?: string,
    pageSize?: string,
    search?: string,
    sellectedSeller?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (search) {
        params.append("search", search);
    }
    if (sellectedSeller) {
        params.append("sellers", sellectedSeller);
    }

    const response = await api.get<any>(`/storages/stocks`, {
        params,
    });

    return response.data.data;
}

export async function getTopologyData(
    page?: string,
    pageSize?: string,
    search?: string,
    container?: string,
    containerId?: string,
    selectedSeller?: string[],
    selectedArticle?: string[],
    selectedSizes?: string[],
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (search) {
        params.append("name", search);
    }
    if (container) {
        params.append("container", container);
    }
    if (containerId) {
        params.append("container_id", containerId);
    }
    if (selectedSeller && selectedSeller?.length > 0) {
        params.append("seller_ref", selectedSeller?.map((item: any) => item?.extRef)?.join(','));
    }
    if (selectedArticle && selectedArticle?.length > 0) {
        params.append("article", selectedArticle?.join(','));
    }
    if (selectedSizes && selectedSizes?.length > 0) {
        params.append("size", selectedSizes?.join(','));
    }

    if (container && containerId) {
        const response = await api.get<any>(`/sthouse/map/products/by-address`, {
            params,
        });
        return response.data.result;
    }

}

export async function getTopologyTypeData(
    type?: string,
) {
    let params = new URLSearchParams();
    if (type) {
        params.append("map_depth", type);
    }
    const response = await api.get<any>(`/sthouse/map`, {
        params,
    });

    return response.data.result;
}

export async function getArticleList(
    page?: string,
    pageSize?: string,
    debouncedSearch?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (debouncedSearch) {
        params.append("begins", debouncedSearch);
    }
    const response = await api.get<any>(`/sthouse/filter-hint-list/article`, {
        params,
    });

    return response.data.result;
}

export async function getSellerList(
    page?: string,
    pageSize?: string,
    debouncedSearch?: string,
) {
    let params = new URLSearchParams();

    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    if (debouncedSearch) {
        params.append("search", debouncedSearch);
    }

    const response = await api.get<any>(`/sellers/extRef`, {params});
    return response.data.data;
}

export async function getSizesList(
    page?: string,
    pageSize?: string,
    debouncedSearch?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (debouncedSearch) {
        params.append("begins", debouncedSearch);
    }
    const response = await api.get<any>(`/sthouse/filter-hint-list/size`, {
        params,
    });

    return response.data.result;
}

export async function getGTDById(
    id: string,
) {
    if (id) {
        const response = await api.get(`/sthouse/documents/${id}`);
        return response.data.result;
    }
}

export async function getKizDataById(
    id: string,
) {
    if (id) {
        const response = await api.get(`/sthouse/documents/${id}`);
        return response.data.result;
    }
}

export async function getProductByGTD(
    id: string,
    page?: string,
    pageSize?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    if (id) {
        const response = await api.get(`/sthouse/ccds/${id}/products/paginated`, {params});
        return response.data.result;
    }
}

export async function createGTD(data: any) {
    const {values, formatData} = data;

    const params = new URLSearchParams();
    params.append("filename", values?.filename);
    params.append("title", values?.title);
    params.append("owner_ref", values?.owner_ref);
    params.append("description", values?.description);

    const response = await api.post<any>(`/sthouse/ccds/new`, formatData, {params});
    return response.data;
}

export async function createCreateKizDocumentToGTD(data: any) {
    const {values, formatData} = data;

    const params = new URLSearchParams();
    params.append("filename", values?.filename);
    params.append("title", values?.title);
    params.append("gtin", values?.gtin);

    if (values?.description) {
        params.append("description", values?.description);
    }

    const response = await api.put<any>(`/sthouse/ccds/${values?.ccd_id}/cis-lists/new`, formatData, {params});
    return response.data;
}

export async function deleteKizDocumentById(id: string) {

    const response = await api.delete(`/sthouse/documents/${id}`);
    return response.data;
}

export async function updateGTD(data: any) {

    const {id, formatData, values} = data;

    const params = new URLSearchParams();
    // params.append("filename", values?.filename);
    params.append("title", values?.title);
    params.append("owner_ref", values?.owner_ref);
    params.append("description", values?.description);

    const response = await api.patch<any>(`/sthouse/documents/${id}`, formatData, {params});
    return response.data;
}

export async function getSellersInfoForShipmentsFormation() {

    const response = await api.get<any>(`/info/sellers`);
    return response.data.result;
}

export async function getArticleCcdForProductsArrival(
    search: any
) {
    const params = new URLSearchParams();

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/sthouse/filter-hint-list/deliveries/article_ccd`, {params});
    return response.data.result;
}

export async function getArticleMkpForProductsArrival(
    search: any
) {
    const params = new URLSearchParams();

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/sthouse/filter-hint-list/deliveries/article_mkp`, {params});
    return response.data.result;
}

export async function getArticlePvdForProductsArrival(
    search: any
) {
    const params = new URLSearchParams();

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/sthouse/filter-hint-list/deliveries/article_pvd`, {params});
    return response.data.result;
}

export async function getArticleCnsForProductsArrival(
    search: any
) {
    const params = new URLSearchParams();

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/sthouse/filter-hint-list/deliveries/article_cns`, {params});
    return response.data.result;
}

export async function getSizesForProductsArrival(
    search: any
) {
    const params = new URLSearchParams();

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/sthouse/filter-hint-list/deliveries/size`, {params});
    return response.data.result;
}

export async function getArticleListForGTDProduct(
    limit?: string,
    search?: string,
) {
    let params = new URLSearchParams();
    if (limit) {
        params.append("limit", limit);
    }

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/info/articles/list`, {
        params,
    });

    return response.data.result;
}

export async function getSizesListForGTDProductByArticle(
    limit?: string,
    search?: string,
    article?: string,
) {
    let params = new URLSearchParams();
    if (limit) {
        params.append("limit", limit);
    }

    if (search) {
        params.append("begins", search);
    }

    if (article) {
        const response = await api.get<any>(`/info/articles/${article}/sizes`, {
            params,
        });
        return response.data.result;
    }
}

export async function getCountryDataForProductGTD(
    limit?: string,
    search?: string,
) {
    let params = new URLSearchParams();
    if (limit) {
        params.append("limit", limit);
    }

    if (search) {
        params.append("begins", search);
    }

    const response = await api.get<any>(`/info/countries`, {
        params,
    });
    return response.data.result;
}

export async function getInfoProductByArticleAndSize(
    size?: string,
    article?: string,
) {

    if (article && size) {
        const response = await api.get<any>(`/info/product/${article}/${size}`);
        return response.data.result;
    }
}

export async function postUploadShipments({values}: any) {

    const response = await api.put<any>(`/sthouse/leftovers/from-excel-file`, values);

    return response.data.result;
}

