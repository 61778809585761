import React from 'react';
import {Col, Row} from "antd";
import AdsSelectionFullInfo from "../../features/adsSelection/pages/AdsSelectionFullInfo";

const AdsSelectionPage = () => {
  return (
    <Row style={{ padding: "10px 20px" }}>
      <Col className={"container"} span={24}>
        <AdsSelectionFullInfo/>
      </Col>
    </Row>
  );
};

export default AdsSelectionPage;