import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu, MenuProps, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as columnsIcon} from "assets/images/columns-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";
import useDeclarationTableFilter from "../../hooks/declaratiion/useDeclarationTableFilter";
import {useFilteredDeclarationFullInfoColumns} from "../../hooks/declaratiion/useFilteredDeclarationFullInfoColumns";
import CustomsClearanceFilesModal from "../../modal/declaration/CustomsClearanceFilesModal";
import useDeclarationData from "../../hooks/declaratiion/useDeclarationData";
import useDeclarationsFullInfoData from "../../hooks/declaratiion/useDeclarationsFullInfoData";
import {ReactComponent as rangePickerArrow} from "../../../../assets/images/rangepicker-arrow.svg";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import {downloadDeclarationFile, downloadEmissionFile, downloadFile} from "../../help/downloadFile";

const {RangePicker} = DatePicker;

const data = [
    {
        id: 1,
        declaration_file_uploader: 'test',
        article_china: 'test',
        size: {
            ru_size: 'test'
        },
        count: 'test',
        box_code: 'test',
        importer_name: 'test',
        name: 'name',
        has_added: 'name',
        is_customed: 'name',
        created_at: new Date(),
        updated_at: new Date(),
    }
]

export default function ListOfAcceptanceFilesFullInfoDeclaration() {

    const navigate = useNavigate()
    const route = useParams()


    const [customsClearanceFilesModal, setCustomsClearanceFilesModal] = useState<boolean>(false);

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const {filterMenuItems} = useDeclarationTableFilter();

    const {
        getMenu,
        filteredColumns,
    } = useFilteredDeclarationFullInfoColumns()

    const [visible, setVisible] = useState(false);

    const handleVisibleChange = (visible: any) => {
        setVisible(visible);
    };

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Col span={24}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    <Icon component={headerPrevBtn}/>
                    Назад
                </div>
            </Col>
            <Col span={24} style={{
                marginTop: 20,
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    {'test'}
                </div>
            </Col>
            <Row gutter={6} align={"middle"} style={{marginBottom: 10,}}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={searchName}
                        // onChange={(text: any) => setSearchName(text.target.value)}
                        className="table-header-input"
                        placeholder="Имя продукта"
                        size="large"
                    />
                </Col>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={searchArticle}
                        // onChange={(text: any) => setSearchArticle(text.target.value)}
                        className="table-header-input-without-border"
                        placeholder="Артикул кит"
                        size="large"
                    />

                </Col>

                <Col style={{width: "250px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        // onChange={(e) => {
                        //     setCurrentPage(1);
                        //     setCurrentPageSize(e);
                        // }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
                <Col style={{width: "250px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={""}
                        // onChange={(e) => setIsPicket(e)}
                        options={[
                            {value: "", label: "Показать все"},
                            {value: "true", label: "Показать отсканированные"},
                            {value: "false", label: "Показать не отсканированные"},
                        ]}
                    />
                </Col>
                {/*<Col style={{width: "88px", paddingTop: 0, paddingBottom: 0}}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{items: getSizeFilterItems()}}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Размер</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}
                {/*<Col style={{width: "165px", paddingTop: 0, paddingBottom: 0}}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{items: getArticleFilterItems()}}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Артикул китайский</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}
            </Row>
            <Col
                span={24}
                className="kiz-actions-wrapper"
            >
                <div className="kiz-actions-wrapper-left">
                    <div style={{position: "relative"}}>
                        <div
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setFilterMenuOpened(!filterMenuOpened);
                            }}
                        >
                            <Icon component={filterIcon}/>
                            <span
                                style={{
                                    color: "#82868B",
                                    marginLeft: "6px",
                                }}
                            >
                                Фильтры
                            </span>
                        </div>

                        <Menu
                            className={
                                filterMenuOpened
                                    ? "filter-menu filter-menu-opened"
                                    : "filter-menu filter-menu-closed"
                            }
                            style={{
                                color: "red !important",
                                width: "250px",
                                left: "-40%",
                            }}
                            items={filterMenuItems}
                            mode={"inline"}
                            // onClick={(e) => {
                            //     const key = e.key;
                            //     setOrderBy(key);
                            // }}
                        />
                    </div>
                    <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        overlay={getMenu()}
                        overlayStyle={{width: "218px"}}
                        overlayClassName="dropdown-border"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                    >
                        <div style={{cursor: "pointer"}}>
                            <Icon component={columnsIcon}/>
                            <span style={{marginLeft: 5}}>Столбцы</span>
                        </div>
                    </Dropdown>
                </div>
                <div style={{
                    display: 'flex',
                    gap: 10,
                }}>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "199px"}}
                    >
                        Отпикать выделенное
                    </Button>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "179px"}}
                        onClick={() => setCustomsClearanceFilesModal(true)}
                    >
                        Файлы растаможки
                    </Button>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "210px"}}
                        // onClick={() =>
                        //     downloadDeclarationFile(
                        //         `${process.env.REACT_APP_API_URL_KIZ_FULL_INFO}declarations/${route.id}/cises/xlsx`,
                        //         true,
                        //         order,
                        //         dateCreateStart,
                        //         dateCreateEnd,
                        //         dateUpdateStart,
                        //         dateUpdateEnd,
                        //         dateCustomedStart,
                        //         dateCustomedEnd,
                        //         selectedSizes,
                        //         searchName,
                        //         searchArticle,
                        //         isPicket,
                        //     )
                        // }
                    >
                        Выгрузить выбранные
                    </Button>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "171px"}}
                        // onClick={() =>
                        //     downloadDeclarationFile(
                        //         `${process.env.REACT_APP_API_URL_KIZ_FULL_INFO}declarations/${route.id}/cises/xlsx`,
                        //         true,
                        //     )
                        // }
                    >
                        Выгрузить все
                    </Button>
                </div>
            </Col>

            <Col
                span={24}
                className="kiz-actions-wrapper"
                style={{display: 'flex', justifyContent: 'flex-end', gap: 10}}
            >
                <Button
                    className={"button"}
                    type={"primary"}
                    style={{width: "260px"}}
                    // onClick={() => downloadFile(
                    //     `${process.env.REACT_APP_API_URL_KIZ}declarations/${route.id}/freeKizes`,
                    //     true,
                    //     selectedSizes,
                    //     selectedArticle
                    // )}
                >
                    Выгрузить свободные товары
                </Button>
                <Button
                    className={"button"}
                    type={"primary"}
                    style={{width: "290px"}}
                    // onClick={() => downloadFile(
                    //     `${process.env.REACT_APP_API_URL_KIZ}declarations/${route.id}/freeKizes?type=all`,
                    //     true,
                    //     selectedSizes,
                    //     selectedArticle
                    // )}
                >
                    Выгрузить растаможенные товары
                </Button>
            </Col>

            <Row className={"container"}>

                <Col span={24}>
                    <Table
                        // loading={isLoading}
                        className="product-arrival-table"
                        columns={filteredColumns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={data}
                        scroll={{x: true}}
                        // pagination={{
                        //     onChange: (page, pageSize) => setCurrentPage(page),
                        //     position: ["bottomCenter"],
                        //     pageSize: Number(currentPageSize),
                        //     total: Number(declaration?.total),
                        //     showSizeChanger: false,
                        //     current: currentPage,
                        // }}
                    />
                </Col>
            </Row>


            <Modal
                open={customsClearanceFilesModal}
                closable={false}
                footer={null}
                width={500}
            >
                <CustomsClearanceFilesModal
                    id={route.id}
                    onClose={() => setCustomsClearanceFilesModal(false)}
                />
            </Modal>

        </div>
    );
}
