import React from 'react';
import {Button, message} from 'antd';
import { getToken, removeToken } from "utils/tokenStorage";
import axios from "axios";
import dayjs from "dayjs";

interface DownloadButtonProps {
    fileUrl: string;
}

export const generateFilename = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    return `products_reports_${currentDate}.xlsx`;
};

const DownloadButton = ({fileUrl}: DownloadButtonProps) => {
    const token = getToken();

    const handleDownload = () => {
        message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')
        axios({
            method: 'get',
            url: fileUrl,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', generateFilename());
            link.setAttribute('target', '_blank');
            link.type = 'application/xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    };

    return (
        <Button
            className="button"
            type="primary"
            style={{width: "200px", fontSize: "12px"}}
            htmlType="submit"
            onClick={handleDownload}
        >
            Выгрузить допоставки
        </Button>
    );
};

export default DownloadButton;