import {getToken} from "../../../utils/tokenStorage";
import {message} from "antd";
import axios from "axios";

export const downloadEmissionFile = (
    url?: any,
) => {
    const token = getToken();

    message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

    const params = new URLSearchParams();

    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        params: params,
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file.xlsx`);
        link.setAttribute('target', '_blank');
        link.type = 'application/xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error downloading file:', error);
    });
};