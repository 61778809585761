import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "api";
import {useCallback, useEffect, useState} from "react";

export default function useGTDById() {

    const [id, setId] = useState<any>('');

    useEffect(() =>{
        setId(id)
    }, [id])

    const { data: gtdData, ...options } = useQuery(
        [
            "GTD_BY_ID",
            id,
        ],
        () =>
            backend.storage.getGTDById(
                id?.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        gtdData,
        id,
        setId,
        ...options,
    };
}