import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useRef, useState} from "react";
import trashBucket from "../../../assets/images/trash-bucket.svg";


export default function AddSelectionModal({onClose}: any) {

    const [selectedSeller, setSelectedSeller] = useState<any>();

    const [form] = Form.useForm();
    const fileInputRef = useRef<any>(null);
    const [file, setFile] = useState<any>('')

    const onFinish = (values: any) => {

        const formData = new FormData();
        formData.append('file', file);

        if (!file){
            message.error('Выберите, пожалуйста, файл')
        } else{
            message.warning('Файл начал загрузку. Не закрывайте страницу')
            // handleCreate({
            //     values: formData,
            //     selectedSeller
            // })
        }

    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    // useEffect(() =>{
    //     if (errorMessage){
    //         message.error(errorMessage)
    //     }
    //     if (isSuccess){
    //         message.success('Вы успешно загрузили эмиссию')
    //         form.resetFields()
    //         setFile('')
    //         setSelectedSeller('')
    //     }
    // },[isError, isSuccess])


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавление выборки"} onClose={() =>{
                form.resetFields()
                setFile('')
                setSelectedSeller('')
                onClose()
            }}/>
            <>
                <div  style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20, width: '100%'}}>
                    {file?.name
                        ?
                        <>
                            <div style={{
                                fontSize: 16,
                                color: '#4B4B4B'
                            }}>
                                {file?.name}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    fontSize: 14,
                                    color: '#EA5455',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setFile('')}
                            >
                                <img src={trashBucket} alt="" style={{marginTop: -2}}/>
                                Удалить
                            </div>
                        </>
                        :
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            Выберите файл
                        </div>
                    }
                </div>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Form.Item
                      label={"Введите название"}
                      name="lastName"
                      rules={[
                          {
                              required: true,
                              message: "Пожалуйста, введите название",
                          },
                      ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                      label={"Выберите дату от"}
                      name="date"
                      rules={[
                          {
                              required: true,
                              message: "Пожалуйста, выберите дату от",
                          },
                      ]}
                    >
                        <DatePicker
                          showTime
                          placeholder={""}
                          format={"DD.MM.YYYY HH:mm"}
                          style={{width: "100%"}}
                        />
                    </Form.Item>

                    <Col style={{display: 'flex', gap: 10}}>
                        <Button
                            className={"button"}
                            type="dashed"
                            style={{width: "100%"}}
                            onClick={handleFileSelect}
                        >
                            Загрузить sku
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                        <Button
                            // loading={isLoading}
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                        >
                            Создать
                        </Button>
                    </Col>

                </Form>
            </>
        </div>
    );
}
