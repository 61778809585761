import {api, apiKiz} from "../../../index";
import {apiKizFullInfo} from "../../../index";
import dayjs from "dayjs";

// Requests
export async function getDeclarationsData(
    page: string,
    pageSize: string,
    order?: string,
    dateCreateStart?: string,
    dateCreateEnd?: string,
    dateUpdateStart?: string,
    dateUpdateEnd?: string,
    dateCustomedStart?: string,
    dateCustomedEnd?: string,
    title?: string,
    gtin?: string,
    searchByProduct?: string | null,
) {
    const params = new URLSearchParams();

    params.append("page", page);
    params.append("limit", pageSize);

    if (order) {
        params.append("order_by", order);
    }

    if (title) {
        params.append("title", title);
    }

    if (gtin) {
        params.append("gtin", gtin);
    }

    if (searchByProduct) {
        params.append("searchByProduct", String(searchByProduct === 'yes'));
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    const response = await apiKizFullInfo.get(`/declarations`, {
        params,
    });
    return response.data;
}

export async function getDeclarationsFullInfoData(
    id: string,
    page: string,
    pageSize: string,
    order?: string,
    dateCreateStart?: string,
    dateCreateEnd?: string,
    dateUpdateStart?: string,
    dateUpdateEnd?: string,
    dateCustomedStart?: string,
    dateCustomedEnd?: string,
    selectedSizes?: string[],
    debouncedName?: string,
    selectedArticle?: string[],
    isPicket?: string,
) {
    const params = new URLSearchParams();

    params.append("page", page);
    params.append("limit", pageSize);

    if (order) {
        params.append("order_by", order);
    }

    if (debouncedName) {
        params.append("name", debouncedName);
    }

    if (selectedArticle?.length !== 0 && selectedArticle) {
        params.append("article_china", selectedArticle?.join(','));
    }

    if (selectedSizes?.length !== 0 && selectedSizes) {
        params.append("ru_size", selectedSizes?.join(','));
    }

    if (isPicket) {
        params.append("picked", isPicket);
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }


    const response = await apiKizFullInfo.get(`/declarations/${id}/cises`, {
        params,
    });
    return response.data;
}


export async function getFreeKizezInfoForPDF(
    id: string,
    size?: string,
    article?: string,
    type?: string
) {
    const params = new URLSearchParams();
    if (size?.length !== 0 && size) {
        params.append("size", size);
    }
    if (article?.length !== 0 && article) {
        params.append("article", article);
    }
    if (type) {
        params.append("type", type);
    }

    const response = await apiKiz.get(`/declarations/${id}/kizes`, {
        params,
    });
    return response.data.data;
}

export async function getDeclarationsDataById(
    id: string,
) {
    const params = new URLSearchParams();


    const response = await apiKizFullInfo.get(`/declarations/${id}`, {params});
    return response.data.result;
}

export async function getDeclarationsCustomedFilesDataById(
    id: string,
) {
    const params = new URLSearchParams();

    if (id) {
        const response = await apiKizFullInfo.get(`/declarations/${id}/files`, {params});
        return response.data;
    }
}

export async function clearKizes(json: any) {
    const response = await apiKiz.post<any>(`/kizesremove_many`, json);
    return response.data;
}

export async function createDeclaration(json: Declaration) {

    const params = new URLSearchParams();


    const response = await apiKizFullInfo.post<Declaration>(`/declarations/new`, json, {params});
    return response.data;
}

export async function getExtraRefData(
    currentPage?: string,
    search?: string,
) {
    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", String(currentPage));
    }
    if (search) {
        params.append("search", search);
    }

    params.append("limit", String(100));

    const response = await api.get<any>(`/sellers/extRef`, {params});
    return response.data.data;
}

export async function loadCustomsClearedKizes(params: any) {
    const {id, values} = params;

    const response = await apiKiz.post<any>(`/import/customed/${id}`, values);
    return response.data;
}

export async function loadEmission({values, selectedSeller}: any) {
    const params = new URLSearchParams();

    if (selectedSeller){
        params.append('ext_ref', selectedSeller)
    }

    const response = await apiKiz.post<any>(`/import/kizes`, values, {params});
    return response.data;
}

export async function loadDeclarationCustomedfile(data: any) {
    const {values, id, name} = data;

    const params = new URLSearchParams();
    params.append("filename", name);

    const response = await apiKizFullInfo.post<any>(`/declarations/${id}/add-file`, values, {params});
    return response.data;
}

export async function updateDeclaration(param: any) {

    const params = new URLSearchParams();


    const {id, ...body} = param;

    const response = await apiKizFullInfo.patch<Seller>(`/declarations/${param.id}`, body, {params});
    return response.data;
}

export async function deleteDeclaration(id: string) {

    const params = new URLSearchParams();

    params.append("did", id);

    const response = await apiKizFullInfo.delete(`/declarations/delete`, {params});
    return response.data;
}


export async function deleteDeclarationCustomedFile(data: any) {

    const {id, handle} = data;

    const params = new URLSearchParams();

    params.append("handle", handle);

    const response = await apiKizFullInfo.delete(`/declarations/${id}/file`, {params});
    return response.data;
}


//old
export async function getDeclarationsDataOlc(
    page: string,
    pageSize: string
) {
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("limit", pageSize);

    const response = await apiKiz.get(`/declarations`, {
        params,
    });
    return response.data.data;
}

export async function createDeclarationOld(json: Declaration) {
    const response = await apiKiz.post<Declaration>(`/declarations`, json);
    return response.data;
}

export async function getDeclarationsDataByIdOld(
    id: string,
) {
    const response = await apiKiz.get(`/declarations/${id}`);
    return response.data.data;
}

export async function updateDeclarationOld(params: any) {
    const {id, ...body} = params;
    const response = await apiKiz.patch<Seller>(`/declarations/${params.id}`, body);
    return response.data;
}

export async function deleteDeclarationOld(id: string) {
    const response = await apiKiz.delete(`/declarations/${id}`);
    return response.data;
}

export async function getArticleBySearch(
    search?: string,
    limit?: string,
    declarationId?: string,
    id?: string,
    idDeclaration?: string,
) {
    const params = new URLSearchParams();

    params.append("field", 'article_china');

    if (limit) {
        params.append("limit", limit);
    }

    if (search) {
        params.append("begins", search);
    }

    if (declarationId) {
        params.append("did", declarationId);
    }

    if (id) {
        params.append("eid", id);
    }

    if (idDeclaration) {
        params.append("did", idDeclaration);
    }

    const response = await apiKizFullInfo.get(`/cises_filter_vals`, {params});
    return response.data;
}

export async function getSizeByArticle(
    articleChina?: string,
) {
    const params = new URLSearchParams();

    params.append("field", 'size.ru_size');

    if (articleChina) {
        params.append("article_china", articleChina);
    }

    if (articleChina) {
        const response = await apiKizFullInfo.get(`/cises_filter_vals`, {params});
        return response.data;
    }
}

export async function getAcceptanceData() {
    const params = new URLSearchParams();


    const response = await apiKizFullInfo.get(`/shipments`, {
        params,
    });

    return response.data;
}

export async function addAcceptanceToTable(json: any) {

    const params = new URLSearchParams();

    const {article, size, plan, did} = json;

    params.append("article", article);
    params.append("size", size);
    params.append("plan", plan);
    params.append("did", did);

    const response = await apiKizFullInfo.post<Declaration>(`/shipments/new`, {}, {params});
    return response.data;
}


export async function deleteAcceptanceFromTable(id: string) {

    const params = new URLSearchParams();

    params.append("sid", id);

    const response = await apiKizFullInfo.delete(`/shipments/delete`, {params});
    return response.data;
}


export async function deleteAllAcceptanceFromTable() {

    const params = new URLSearchParams();


    const response = await apiKizFullInfo.delete(`/shipments/delete_all`, {params});
    return response.data;
}

export async function saveAcceptanceFile(json: any) {

    const params = new URLSearchParams();

    const {filename} = json;

    params.append("filename", filename);

    const response = await apiKizFullInfo.post<Declaration>(`/shipments/save_file`, {}, {params});
    return response.data;
}

export async function getListOfAcceptanceData() {
    const params = new URLSearchParams();


    const response = await apiKizFullInfo.get(`/shipments/files`, {
        params,
    });
    return response.data;
}

export async function getDeclarationsList(
    page: string,
    pageSize: string,
) {
    const params = new URLSearchParams();

    params.append("page", page);
    params.append("limit", pageSize);

    const response = await apiKizFullInfo.get(`/declarations`, {
        params,
    });
    return response.data;
}