import React, {useState} from 'react';
import dots from "../../../assets/financeResultIcons/dots.svg";
import {Modal} from "antd";
import DownloadFileModal from "../modals/DownloadFileModal";
import subscribeCircleDesc from "../../../assets/financeResultIcons/subscribeCircleDesc.svg";
import subscribeCircleOpacityDesc from "../../../assets/financeResultIcons/subscribeCircleOpacityDesc.svg";
import productComeCircleDesc from "../../../assets/financeResultIcons/productComeCircleDesc.svg";
import productComeCircleOpacityDesc from "../../../assets/financeResultIcons/productComeCircleOpacityDesc.svg";
import tamojCircleDesc from "../../../assets/financeResultIcons/tamojCircleDesc.svg";
import tamojCircleOpacityDesc from "../../../assets/financeResultIcons/tamojCircleOpacityDesc.svg";
import documentComeCircleDesc from "../../../assets/financeResultIcons/documentComeCircleDesc.svg";
import documentComeCircleOpacityDesv from "../../../assets/financeResultIcons/documentComeCircleOpacityDesv.svg";
import wbCircleDesc from "../../../assets/financeResultIcons/wbCircleDesc.svg";
import wbCircleOpacityDesc from "../../../assets/financeResultIcons/wbCircleOpacityDesc.svg";
import weSaleCircleDesc from "../../../assets/financeResultIcons/weSaleCircleDesc.svg";
import weSaleCircleOpacityDesc from "../../../assets/financeResultIcons/weSaleCircleOpacityDesc.svg";
import xLine from "../../../assets/financeResultIcons/xLine.svg";

const InfoChart = ({data, isReadyToStart, onClickStart, selectedSeller}: any) => {

    const [downloadFileModal, setDownloadFileModal] = useState<any>({
        isOpen: false,
        title: ''
    });

    const mapChartData = (chartData: any) => {

        if (chartData?.length === 0 || !chartData) {
            return []
        }

        const statuses: any = {
            1: 'Подписание документов',
            2: 'Закуп товара',
            3: 'Растаможка товара',
            4: 'Услуги фулфилмента',
            5: 'Отгрузка на ВБ',
            6: 'Старт продаж',
        }
        const icons: any = {
            1: subscribeCircleDesc,
            2: productComeCircleDesc,
            3: tamojCircleDesc,
            4: documentComeCircleDesc,
            5: wbCircleDesc,
            6: weSaleCircleDesc,
        }
        const iconsOpacity: any = {
            1: subscribeCircleOpacityDesc,
            2: productComeCircleOpacityDesc,
            3: tamojCircleOpacityDesc,
            4: documentComeCircleOpacityDesv,
            5: wbCircleOpacityDesc,
            6: weSaleCircleOpacityDesc,
        }

        const newItem = {
            step: 6,
            files: [],
            isActive: isReadyToStart
        };

        return [...chartData, newItem].map((item: any) =>
            item.step === 6
                ?
                ({
                    ...item,
                    status: statuses[item?.step],
                    icon: icons[item?.step],
                    iconOpacity: iconsOpacity[item?.step],
                })
                :
                ({
                    ...item,
                    status: statuses[item?.step],
                    icon: icons[item?.step],
                    iconOpacity: iconsOpacity[item?.step],
                    isActive: item?.files?.length !== 0 ? true : false
                })

        )
    }



    return (
        <div className="info-chart-wrap">
            {
                mapChartData(data)?.map((item: any, index: any) =>
                    <div className="info-chart-item">

                        {index % 2 == 0
                            ?
                            <div className="info-chart-item-top">

                                <div
                                    className="info-chart-item-icon"
                                    style={{
                                        cursor: item?.isActive ? 'pointer' : 'auto',
                                        pointerEvents: item?.isActive ? 'auto' : 'none',
                                    }}
                                    onClick={() => item.step !== 6
                                        ?
                                        setDownloadFileModal({
                                            isOpen: true,
                                            title: `Файлы ${item?.status}`,
                                            step: item?.step,
                                            selectedSeller: selectedSeller
                                        })
                                        :
                                        onClickStart()
                                    }
                                >
                                    <img
                                        src={item?.isActive ? item?.icon : item?.iconOpacity}
                                        alt=""
                                        className='icon-inside-chart'
                                        style={{
                                            opacity: item?.isActive ? 1 : 0.5,
                                        }}
                                    />
                                </div>
                                <img
                                    src={xLine}
                                    style={{
                                        opacity: item?.isActive ? 1 : 0.5,
                                    }}
                                />
                                <div
                                    className="info-chart-item-block"
                                    style={{
                                        opacity: item?.isActive ? 1 : 0.5
                                    }}
                                >
                                    {item?.status}
                                </div>
                            </div>
                            :
                            <div className="info-chart-item-top">

                                <div
                                    className="info-chart-item-block"
                                    style={{
                                        opacity: item?.isActive ? 1 : 0.5
                                    }}
                                >
                                    {item?.status}
                                </div>
                                <img
                                    src={xLine}
                                    style={{
                                        opacity: item?.isActive ? 1 : 0.5,
                                    }}
                                />
                                <div
                                    className="info-chart-item-icon"
                                    style={{
                                        cursor: item?.isActive ? 'pointer' : 'auto',
                                        pointerEvents: item?.isActive ? 'auto' : 'none',
                                    }}
                                    onClick={() => item.step !== 6
                                        ?
                                        setDownloadFileModal({
                                            isOpen: true,
                                            title: `Файлы ${item?.status}`,
                                            step: item?.step,
                                            selectedSeller: selectedSeller
                                        })
                                        :
                                        onClickStart()
                                    }
                                >
                                    <img
                                        src={item?.isActive ? item?.icon : item?.iconOpacity}
                                        alt=""
                                        className='icon-inside-chart'
                                        style={{
                                            opacity: item?.isActive ? 1 : 0.5,
                                        }}
                                    />
                                </div>
                            </div>

                        }

                        {index !== 5 &&
                        <div className="info-chart-item-bottom">
                            <img src={dots} alt="" style={{opacity: item?.isActive ? 1 : 0.4}}/>
                        </div>
                        }

                    </div>
                )
            }
            <Modal
                open={downloadFileModal.isOpen}
                closable={false}
                footer={null}
                width={360}
            >
                <DownloadFileModal
                    modalData={downloadFileModal}
                    onClose={() => setDownloadFileModal({
                        isOpen: false,
                        title: ''
                    })}
                />
            </Modal>
        </div>
    );
};

export default InfoChart;