import React, {useEffect, useRef, useState} from "react";
import {
  AutoComplete,
  Button,
  Col, DatePicker,
  Dropdown,
  Input,
  Menu, MenuProps, message, Modal,
  Pagination,
  Radio,
  Row,
  Select, Table, Upload, UploadProps,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "../../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import useDeclarationData from "../../hooks/declaratiion/useDeclarationData";
import AddDeclarationModal from "../../modal/declaration/AddDeclarationModal";
import {useDeclarationDelete} from "../../hooks/declaratiion/useDeclarationDelete";
import EditDeclarationModal from "../../modal/declaration/EditDeclarationModal";
import {downloadFile} from "../../help/downloadFile";
import {useLoadCustomsClearedKizes} from "../../hooks/declaratiion/useLoadCustomsClearedKizes";
import SuccessUploadModal from "../../modal/SuccessUploadModal";
import {ReactComponent as rangePickerArrow} from "../../../../assets/images/rangepicker-arrow.svg";
import useDeclarationMainTableFilter from "../../hooks/declaratiion/useDeclarationMainTableFilter";
import dayjs from "dayjs";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import DownloadButton from "../../components/DownloadButton";

const {RangePicker} = DatePicker;

export default function DeclarationsTable() {

  const navigate = useNavigate()

  const {
    declaration,
    isError,
    isLoading,
    currentPage,
    currentPageSize,
    setCurrentPage,
    setCurrentPageSize,
    setOrderBy,
    onChangeDateCreate,
    onChangeDateUpdate,
    onChangeDateCustomed,
    title,
    setTitle,
    gtin,
    setGtin,
    searchByProduct,
    setSearchByProduct,
  } = useDeclarationData();

  const {
    handleDelete: handleDeclarationDelete,
    isError: isErrorDeleteDeclaration,
    errorMessage: deleteDeclarationMessage,
    isSuccess: successDeleteDeclaration
  } = useDeclarationDelete();

  const {
    handleCreate,
    uploadData,
    isSuccess: isSuccessUploadFile,
    isError: isErrorUploadFile,
    errorMessage: errorMessageUploadFile
  } = useLoadCustomsClearedKizes()

  const fileInputRef = useRef<any>(null);

  const [recordId, setRecordId] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState<any>({
    isOpen: false,
    text: '',
    onPress: () => {
    }
  });

  const [responseUploadFile, setResponseUploadFile] = useState<any>({
    totalCount: 0,
    failedCount: 0,
    createdCount: 0,
    firstAddedId: 0,
    lastAddedId: 0
  });

  const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
  const {filterMenuItems} = useDeclarationMainTableFilter();

  const [addDeclarationModal, setAddDeclarationModal] = useState(false)
  const [editDeclarationModal, setEditDeclarationModal] = useState({
    isOpen: false,
    id: null
  })
  const [answerModal, setAnswerModal] = useState<any>(false);

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      width: "20%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/storage/declaration/full-info/${record.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: "gtin",
      dataIndex: "gtin",
      key: "gtin",
      width: "45%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/storage/declaration/full-info/${record.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: "Дата загрузки эмиссии",
      dataIndex: "custom_passed_at",
      key: "custom_passed_at",
      width: "10%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/storage/declaration/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text
              ? dayjs(text).format("DD.MM.YYYY HH:mm")
              : '---'
            }
          </div>
        )
      }
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/storage/declaration/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text
              ? dayjs(text).format("DD.MM.YYYY HH:mm")
              : '---'
            }
          </div>
        )
      }
    },
    {
      title: "Дата обновления",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "10%",
      render: (text: any, record: any) => {
        const onGoToFullInfo = () => {
          navigate(`/storage/declaration/full-info/${record?.id}`)
        }

        return (
          <div
            onClick={onGoToFullInfo}
            style={{
              cursor: 'pointer'
            }}
          >
            {text
              ? dayjs(text).format("DD.MM.YYYY HH:mm")
              : '---'
            }
          </div>
        )
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <div
          style={{
            minHeight: 60,
            textAlign: "center",
            display: "flex",
            gap: "7px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          // onClick={() => {
          //     setEditProductOpened(true);
          //     setProductToEdit(record);
          // }}
        >
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={getDeclarationActions(record)}
          >
            <MoreOutlined
              style={{cursor: "pointer", fontSize: "20px"}}/>
          </Dropdown>

        </div>
      ),
    },
  ];

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    message.success('Файл начал загрузку. Не закрывайте страницу')

    handleCreate(formData, recordId)

  };

  const productsItemsForEdit: MenuProps["items"] = [
    {
      label: (
        <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
      ),
      key: "EDIT_DECLARATION",
    },
    // {
    //     label: (
    //         <span style={{display: "flex", gap: "10px", width: 180}}>
    //             <Icon component={editIcon} style={{cursor: "pointer"}}/>
    //             Выгрузить свободные товары
    //         </span>
    //     ),
    //     key: "DOWNLOAD_FREE_KIZES",
    // },
    // {
    //     label: (
    //         <span style={{display: "flex", gap: "10px", width: 180}}>
    //             <Icon component={editIcon} style={{cursor: "pointer"}}/>
    //             Выгрузить растаможенные товары
    //         </span>
    //     ),
    //     key: "DOWNLOAD_CUSTOMS_CLEARED_KIZES",
    // },
    {
      label: (
        <>
                    <span style={{display: "flex", gap: "10px", width: 180}}>
                        <Icon component={editIcon} style={{cursor: "pointer"}}/>
                        Добавить растаможенные товары
                    </span>
          <input
            type="file"
            ref={fileInputRef}
            style={{display: 'none'}}
            onChange={handleFileChange}
          />
        </>

      ),
      key: "LOAD_CUSTOMS_CLEARED_KIZES",
    },
    {
      label: (
        <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
      ),
      key: "DELETE_DECLARATION",
    }
  ];

  const getDeclarationActions = (record: any) => {
    return {
      items: productsItemsForEdit,
      onClick: ({item, key, keyPath, domEvent}: any) => {
        switch (key) {
          case "EDIT_DECLARATION":
            setEditDeclarationModal({
              isOpen: true,
              id: record.id
            })
            break;
          case "DOWNLOAD_FREE_KIZES":
            // downloadFile(
            //     `${process.env.REACT_APP_API_URL_KIZ}declarations/${record.id}/freeKizes`,
            //     true,
            //     // size,
            //     // article
            // )
            break;
          case "DOWNLOAD_CUSTOMS_CLEARED_KIZES":
            // downloadFile(
            //     `${process.env.REACT_APP_API_URL_KIZ}declarations/${record.id}/freeKizes?type=all`,
            //     true,
            //     // size,
            //     // article
            // )
            break;
          case "LOAD_CUSTOMS_CLEARED_KIZES":
            // uploadFile('123', record.id)
            handleFileSelect()
            setRecordId(record.id)
            break;
          case "DELETE_DECLARATION":

            setIsModalVisible({
              isOpen: true,
              text: 'удалить декларацию',
              onPress: () => handleDeclarationDelete(record.id)
            })
            break;
        }
      },
    };
  };

  useEffect(() => {
    if (isErrorDeleteDeclaration) {
      message.error(deleteDeclarationMessage)
    }
  }, [isErrorDeleteDeclaration])

  useEffect(() => {
    if (successDeleteDeclaration) {
      message.success('Вы успешно удалили декларацию')
    }
  }, [successDeleteDeclaration])

  useEffect(() => {
    if (isSuccessUploadFile) {
      message.success('Файл успешно загружен')
      setAnswerModal(true)
      setResponseUploadFile(uploadData.data)
    }
  }, [isSuccessUploadFile])

  useEffect(() => {
    if (isErrorUploadFile) {
      message.error(errorMessageUploadFile)
    }
  }, [isErrorUploadFile])


  return (
    <div style={{backgroundColor: '#f8f8f8'}}>
      <Row gutter={6} align={"middle"}>
        <Col style={{flexGrow: "1"}}>
          <Input
            value={title}
            onChange={(text: any) => setTitle(text.target.value)}
            className="table-header-input"
            placeholder="Название"
            size="large"
          />
        </Col>
        <Col style={{flexGrow: "1"}}>
          <Input
            value={gtin}
            onChange={(text: any) => setGtin(text.target.value)}
            className="table-header-input-without-border"
            placeholder="Gtin"
            size="large"
          />
        </Col>

        <Col style={{width: "300px"}}>
          <Select
            placeholder={'Поиск свободных по товару'}
            className="table-header-select table-header-select-no-offset"
            suffixIcon={<Icon component={SelectIconComponent}/>}
            value={searchByProduct}
            onChange={(e) => {
              setSearchByProduct(e)
            }}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Button style={{width: '100%', marginTop: 10}} onClick={() => setSearchByProduct(null)}>
                  Сбросить
                </Button>
              </>
            )}
            options={[
              {value: 'yes', label: "Да"},
              {value: "no", label: "Нет"},
            ]}
          />
        </Col>

        <Col style={{width: "250px"}}>
          <Select
            className="table-header-select table-header-select-no-offset"
            suffixIcon={<Icon component={SelectIconComponent}/>}
            defaultValue={"10"}
            onChange={(e) => {
              setCurrentPage(1);
              setCurrentPageSize(e);
            }}
            options={[
              {value: "10", label: "Показать по 10 шт."},
              {value: "50", label: "Показать по 50 шт."},
              {value: "100", label: "Показать по 100 шт."},
            ]}
          />
        </Col>

        <Col>
          <DownloadButton
            title={'Выгрузить свободные кизы'}
            isWithQuery={false}
            fileUrl={`${process.env.REACT_APP_API_URL_KIZ}kizes/free-kizes`}
          />
        </Col>
        <Col>
          <Button
            onClick={() => setAddDeclarationModal(true)}
            className="table-header-button"
            type={"primary"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Добавить декларацию
          </Button>
        </Col>
      </Row>

      <Row style={{marginTop: 10,}}>
        <Col
          span={24}
          className="kiz-actions-wrapper"
        >
          <div className="kiz-actions-wrapper-left">
            <Col span={3}>
              <div style={{position: "relative"}}>
                <div
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setFilterMenuOpened(!filterMenuOpened);
                  }}
                >
                  <Icon component={filterIcon}/>
                  <span
                    style={{
                      color: "#82868B",
                      marginLeft: "6px",
                    }}
                  >
                                Фильтры
                            </span>
                </div>

                <Menu
                  className={
                    filterMenuOpened
                      ? "filter-menu filter-menu-opened"
                      : "filter-menu filter-menu-closed"
                  }
                  style={{
                    color: "red !important",
                    width: "250px",
                    left: "-40%",
                  }}
                  items={filterMenuItems}
                  mode={"inline"}
                  onClick={(e) => {
                    setOrderBy(e.key)
                  }}
                />
              </div>
            </Col>
            <Col span={7}>
              <RangePicker
                style={{width: '350px'}}
                onChange={onChangeDateCreate}
                showTime={{format: 'HH-mm-ss'}}
                placeholder={["Дата создания(от)", "Дата создания(до)"]}
                separator={
                  <Icon component={rangePickerArrow}/>
                }
                format={"DD.MM.YYYY HH:mm:ss"}
              />
            </Col>
            <Col span={7}>
              <RangePicker
                style={{width: '350px'}}
                onChange={onChangeDateUpdate}
                showTime={{format: 'HH-mm-ss'}}
                placeholder={["Дата обновления(от)", "Дата обновления(до)"]}
                separator={
                  <Icon component={rangePickerArrow}/>
                }
                format={"DD.MM.YYYY HH:mm:ss"}
              />
            </Col>
            <Col span={7}>
              <RangePicker
                style={{width: '350px'}}
                onChange={onChangeDateCustomed}
                showTime={{format: 'HH-mm-ss'}}
                placeholder={["Дата загрузки(от)", "Дата загрузки(до)"]}
                separator={
                  <Icon component={rangePickerArrow}/>
                }
                format={"DD.MM.YYYY HH:mm:ss"}
              />
            </Col>
          </div>
        </Col>
      </Row>

      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col span={24}>
          <Table
            loading={isLoading}
            className="product-arrival-table"
            columns={columns}
            dataSource={declaration?.data || []}
            scroll={{x: true}}
            pagination={{
              onChange: (page, pageSize) => setCurrentPage(page),
              position: ["bottomCenter"],
              pageSize: Number(currentPageSize),
              total: Number(declaration?.total),
              showSizeChanger: false,
              current: currentPage,
            }}
          />
        </Col>

      </Row>

      <Modal
        open={addDeclarationModal}
        closable={false}
        footer={null}
        width={500}
      >
        <AddDeclarationModal
          onClose={() => setAddDeclarationModal(false)}
        />
      </Modal>
      <Modal
        open={editDeclarationModal.isOpen}
        closable={false}
        footer={null}
        width={500}
      >
        <EditDeclarationModal
          id={editDeclarationModal.id}
          onClose={() => setEditDeclarationModal({
            isOpen: false,
            id: null
          })}
        />
      </Modal>

      <Modal
        open={answerModal}
        closable={false}
        footer={null}
        width={600}
      >
        <SuccessUploadModal
          backendData={responseUploadFile}
          setBackendData={setResponseUploadFile}
          onClose={() => setAnswerModal(false)}
        />
      </Modal>
      <Modal
        open={isModalVisible.isOpen}
        closable={false}
        footer={null}
        width={500}
      >
        <ConfirmModal
          onClose={() => setIsModalVisible({
            isOpen: false,
            onPress: () => {
            }
          })}
          onConfirm={() => isModalVisible.onPress()}
          text={isModalVisible.text}
        />
      </Modal>
    </div>
  );
}
