import { MenuProps } from "antd";
import editIcon from "assets/images/edit-mirror-icon.svg";
import activateIcon from "assets/images/activate-mirror-icon.svg";
import stopMirrorIcon from "assets/images/stop-mirror-icon.svg";
import archivateIcon from "assets/images/archivate-mirror-icon.svg";
import statisticsIcon from "assets/images/statistics-mirror-icon.svg";
import finishMirrorIcon from "assets/images/finish-mirror-icon.svg";

export const items: MenuProps["items"] = [
    {
        label: (
            <span style={{ display: "flex", gap: "10px" }}>
                <img src={editIcon} />
                Редактировать
            </span>
        ),
        key: "EDIT",
    },
    {
        label: (
            <span style={{ display: "flex", gap: "10px" }}>
                <img src={stopMirrorIcon} />
                Пауза
            </span>
        ),
        key: "STOP",
    },
    {
        label: (
            <span style={{ display: "flex", gap: "10px" }}>
                <img src={activateIcon} />
                Возобновить
            </span>
        ),
        key: "RESTART",
    },
    {
        label: (
            <span style={{ display: "flex", gap: "10px" }}>
                <img src={finishMirrorIcon} />
                Завершить
            </span>
        ),
        key: "FINISH",
    },
    // {
    //     label: (
    //         <span style={{ display: "flex", gap: "10px", color: "#28C76F" }}>
    //             <img src={activateIcon} />
    //             Активировать
    //         </span>
    //     ),
    //     key: "ACTIVATE",
    // },
    // {
    //     label: (
    //         <span style={{ display: "flex", gap: "10px" }}>
    //             <img src={archivateIcon} />
    //             Архивировать
    //         </span>
    //     ),
    //     key: "ARCHIVATE",
    // },
    {
        label: (
            <span style={{ display: "flex", gap: "10px" }}>
                <img src={statisticsIcon} />
                Статистика
            </span>
        ),
        key: "STATISTICS",
    },
];
