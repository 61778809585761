import { Col, Row } from "antd";
import FinancialResultsTable from "features/financial-results/FinancialResultsTable";
import React from "react";
import CostPriceTable from "../../features/costPrice/CostPriceTable";

export default function CostPricePage() {
    return (
        <Row style={{ padding: "10px 20px" }}>
            <Col className={"container"} span={24}>
                <CostPriceTable />
            </Col>
        </Row>
    );
}
