import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function useExpensesUserData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [orderByUser, setOrderByUser] = useState<string>("");

    const { data: expensesUserData, ...options } = useQuery(
        ["EXPENSES_USER_DATA", currentPage, currentPageSize, orderByUser],
        () =>
            backend.expenses.getExpensesUserData(
                currentPage.toString(),
                currentPageSize,
                orderByUser
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        expensesUserData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        orderByUser,
        setOrderByUser,
        ...options,
    };
}
