import {
    Button, Checkbox,
    Col,
    Form,
    Input,
    message, Table,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useAdLeftMoney} from "../hooks/useAdLeftMoney";
import {useChangeCurrentCurrency} from "../hooks/useChangeCurrentCurrency";
import {Menubar} from "primereact/menubar";
import RecommendedPriceForm from "../components/RecommendedPriceForm";

export default function ChangeCurrentCurrencyModal({
                                                       id,
                                                       recommendedPrice,
                                                       autoChangeLt,
                                                       articule,
                                                       fullPrice,
                                                       spp,
                                                       onClose,
                                                   }: any) {


    const [form] = Form.useForm<{}>();

    const [isAgree, setIsAgree] = useState(false)
    const [desiredPrice, setDesiredPrice] = useState<number>(0)
    // const [spp, setSpp] = useState<any>(0)

    const {
        handleCreate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useChangeCurrentCurrency()

    const onFinish = (value: any) => {
        handleCreate({price: value?.price, discount: value?.discount, nmId: articule})
    }

    const desiredPriceWithoutSpp = Number(desiredPrice) / (1 - (Number(spp)/ 100))
    const discount: number = 1 - (Number(desiredPriceWithoutSpp) / Number(fullPrice))
    const generalDiscount = Number(String(discount).includes("-") ? String(discount).slice(0,5) : String(discount).slice(0,4))

    // подсчет первого варианта
    const firstDiscount = generalDiscount - 0.01
    const finalPriceFirst = (Number(fullPrice) - Number(firstDiscount) * Number(fullPrice) - (Number(fullPrice) - Number(firstDiscount) * Number(fullPrice)) * Number(spp) / 100).toFixed(2)

    // подсчет первого варианта
    const secondDiscount = generalDiscount - 0.02
    const finalPriceSecond = (Number(fullPrice) - Number(secondDiscount) * Number(fullPrice) - (Number(fullPrice) - Number(secondDiscount) * Number(fullPrice)) * Number(spp) / 100).toFixed(2)

    // подсчет первого варианта где скидка +0.01
    const firstDiscountExtra = generalDiscount + 0.01
    const finalPriceFirstExtra = (Number(fullPrice) - Number(firstDiscountExtra) * Number(fullPrice) - (Number(fullPrice) - Number(firstDiscountExtra) * Number(fullPrice)) * Number(spp) / 100).toFixed(2)

    // подсчет первого варианта где скидка +0.02
    const secondDiscountExtra = generalDiscount + 0.02
    const finalPriceSecondExtra = (Number(fullPrice) - Number(secondDiscountExtra) * Number(fullPrice) - (Number(fullPrice) - Number(secondDiscountExtra) * Number(fullPrice)) * Number(spp) / 100).toFixed(2)

    // подсчет третьего варианта
    const finalPriceThird = (Number(fullPrice) - Number(generalDiscount) * Number(fullPrice) - (Number(fullPrice) - Number(generalDiscount) * Number(fullPrice)) * Number(spp) / 100).toFixed(2)

    // подсчет финального поля
    const priceFour = (Number(desiredPrice) / ((1 - Number(generalDiscount)) - (1 - Number(generalDiscount)) * Number(spp) / 100)).toFixed(2)
    const finalResultFour = ((Number(priceFour) - Number(priceFour) * generalDiscount) * (1 - Number(spp) / 100)).toFixed(0)

    const tableData = [
        {
            fullPrice: fullPrice,
            spp: spp,
            price: desiredPrice ? fullPrice : 0,
            discount: desiredPrice ? Number(firstDiscount * 100).toFixed(0) : 0,
            finalPrice: desiredPrice ? Number(finalPriceFirst) : 0
        },
        {
            fullPrice: fullPrice,
            spp: spp,
            price: desiredPrice ? fullPrice : 0,
            discount: desiredPrice ? Number(secondDiscount * 100).toFixed(0) : 0,
            finalPrice: desiredPrice ? Number(finalPriceSecond) : 0
        },
        {
            fullPrice: fullPrice,
            spp: spp,
            price: desiredPrice ? fullPrice : 0,
            discount: desiredPrice ? Number(firstDiscountExtra * 100).toFixed(0) : 0,
            finalPrice: desiredPrice ? Number(finalPriceFirstExtra) : 0
        },
        {
            fullPrice: fullPrice,
            spp: spp,
            price: desiredPrice ? fullPrice : 0,
            discount: desiredPrice ? Number(secondDiscountExtra * 100).toFixed(0) : 0,
            finalPrice: desiredPrice ? Number(finalPriceSecondExtra) : 0
        },
        {
            fullPrice: fullPrice,
            spp: spp,
            price: desiredPrice ? fullPrice : 0,
            discount: desiredPrice ? Number(generalDiscount * 100).toFixed(0) : 0,
            finalPrice: desiredPrice ? Number(finalPriceThird) : 0
        },
        // {
        //     fullPrice: priceFourthFix,
        //     spp: spp,
        //     price: desiredPrice ? priceFourthFix : 0,
        //     discount: desiredPrice ? Number(generalDiscount * 100).toFixed(0) : 0,
        //     finalPrice: desiredPrice ? finalResultFix : 0
        // }
    ]

    function findClosestToZero(arr: any) {
        // Создадим массив объектов, содержащих разницу и модуль разницы между fullPrice и finalPrice.
        const diffs = arr.map((obj: any) => ({
            obj,
            diff: Number(obj.finalPrice) - Number(desiredPrice),
            absDiff: Math.abs(Number(obj.finalPrice) - Number(desiredPrice))
        }));

        // Отсортируем массив по модулю разницы (по возрастанию).
        diffs.sort((a: any, b: any) => a.absDiff - b.absDiff);

        // Вернем два объекта с наименьшими модулями разницы.
        return [
            diffs[0].obj,
            diffs[1].obj,
            {
                fullPrice: desiredPrice ? priceFour : 0,
                spp: spp,
                price: desiredPrice ? priceFour : 0,
                discount: desiredPrice ? Number(generalDiscount * 100).toFixed(0) : 0,
                finalPrice: desiredPrice ? Number(finalResultFour) : 0
            }
        ];
    }

    useEffect(() => {
        form.setFieldsValue({
            spp: Number(spp),
            currentPrice: Number(fullPrice),
            desiredPrice: 0,
            price: 0,
            discount: 0
        })
    }, [articule, fullPrice, spp])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
        if (isSuccess) {
            message.success('Вы успешно изменили цену')
            form.setFieldsValue({
                desiredPrice: 0,
                price: 0,
                discount: 0
            })
            onClose()
        }
    }, [isError, isSuccess])

    const columns = [
        {
            title: "Цена текущая",
            dataIndex: "fullPrice",
            key: "fullPrice",
            width: '16%'
        },
        {
            title: "Скидка",
            dataIndex: "discount",
            key: "discount",
            width: '16%',
        },
        {
            title: "Цена без спп",
            dataIndex: "price",
            key: "price",
            width: '16%'
        },
        {
            title: "Спп",
            dataIndex: "spp",
            key: "spp",
            width: '16%',
        },
        {
            title: "Цена",
            dataIndex: "finalPrice",
            key: "finalPrice",
            width: '16%',
        },
        {
            title: "",
            dataIndex: "-",
            key: "-",
            width: '16%',
            render: (text: any, record: any) => {
                return (
                    <div style={{padding: 5}}>
                        <Button
                            disabled={!desiredPrice}
                            onClick={() => {
                                form.setFieldsValue({
                                    price: record?.price,
                                    discount: record?.discount
                                })
                            }}
                            className={"button"}
                            type={"primary"}
                            style={{fontSize: 12}}
                        >
                            Применить
                        </Button>
                    </div>
                )
            }
        },
    ];

    const styleForWrongCurrentPrice = {
        color: '#d50303',
        borderColor:  '#d50303'
    }


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Изменения текущей цены"} onClose={onClose}/>
            <Form
                onFinish={(values) => onFinish(values)}
                form={form}
                layout={"vertical"}
            >

                <Form.Item
                    rules={[{required: true}]}
                    name={"desiredPrice"}
                    label={"Введите желаемую цену"}
                >
                    <Input
                        type={"number"}
                        value={desiredPrice}
                        onChange={((e: any) => setDesiredPrice(e?.target?.value))}
                    />
                </Form.Item>

                <Form.Item
                    rules={[{required: true}]}
                    name={"currentPrice"}
                    label={"Цена текущая"}
                >
                    <Input type={"number"} disabled={true}/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {required: true, message: 'Введите спп'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value >= 1 && value <= 99) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Скидка должна быть в диапазоне от 1 до 99');
                            },
                        }),
                    ]}
                    name={"spp"}
                    label={"спп"}
                >
                    {/*<Input type={"number"} value={spp} onChange={((e: any) => setSpp(Number(e?.target?.value)))}/>*/}
                    <Input type={"number"} disabled={true}/>
                </Form.Item>

                <Table
                    className="product-arrival-table"
                    columns={columns}
                    // dataSource={tableData}
                    dataSource={findClosestToZero(tableData)}
                    pagination={false}
                />

                <Form.Item
                    rules={[{required: true}]}
                    name={"price"}
                    label={"Введите цену"}
                >
                    <Input
                        type={"number"}
                    />
                </Form.Item>
                <Form.Item
                    rules={[
                        {required: true, message: 'Введите скидку'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value >= 1 && value <= 100) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Скидка должна быть в диапазоне от 1 до 100');
                            },
                        }),
                    ]}
                    name={"discount"}
                    label={"Введите скидку"}
                >
                    <Input
                        type={"number"}
                    />
                </Form.Item>

                <Checkbox
                    checked={isAgree}
                    onChange={(e: any) => setIsAgree(!isAgree)}
                    style={{
                        color: '#ff0000',
                        marginBottom: 20,
                    }}
                >
                    Вы точно уверены, что хотите изменить цену. Изменения сразу вступят в силу
                </Checkbox>

                <Col style={{display: "flex", gap: "15px"}}>
                    <Button
                        disabled={!isAgree}
                        type={"primary"}
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        htmlType={"submit"}
                        loading={isLoading}
                    >
                        Применить цену
                    </Button>
                    <Button
                        type={"primary"}
                        ghost
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </Col>
            </Form>

            <div style={{marginTop: 30}}/>

            <ModalHeader title={"Автоматическое изменение цены"} isWithCircle={false}/>

            <RecommendedPriceForm
                id={id}
                onClose={onClose}
            />

        </div>
    );
}
