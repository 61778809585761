import { Col, Row } from "antd";
import CashbackTable from "features/cashbacks/pages/CashbackTable";

export default function CashbacksPage() {
    return (
        <Row>
            <Col style={{ padding: "10px 20px" }} span={24}>
                <CashbackTable />
            </Col>
        </Row>
    );
}
