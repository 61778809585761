import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import plusIcon from 'assets/images/bluePlus.svg'
import circleIcon from 'assets/images/gtd-circle-icon.svg'
import AddTsdFileButton from "../../../document-mirror/components/AddTSDFileButton";
import useGTDById from "../../hooks/gtd/useGTDById";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import useExtRef from "../../../kiz/hooks/useExtRef";
import {useUpdateGTD} from "../../hooks/gtd/useUpdateGTD";

const {Option} = Select;

export default function EditGTD({onClose, id}: any) {

    const [form] = Form.useForm();
    const fileInputRef = useRef<any>(null);
    const [file, setFile] = useState<any>('')

    const [selectedSeller, setSelectedSeller] = useState<any>('');
    const {
        sellers,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
    } = useExtRef();

    const {
        gtdData,
        setId,
        isLoading,
        isError,
        isSuccess
    } = useGTDById()

    const {
        handleUpdate,
        isLoading: updateLoading,
        isError: isErrorUpdate,
        isSuccess: isSuccessUpdate,
        errorMessage,
    } = useUpdateGTD()

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFinish = (values: any) => {

        if (!file?.isOldFile){
            const formData = new FormData();
            formData.append('file', file);
            handleUpdate({...values, file: false}, id, formData)
        } else {
            handleUpdate({...values, file: false}, id)
        }
    };

    useEffect(() => {
        setId(id)
    }, [id])

    useEffect(() =>{
        if (isError){
            message.error('Произошла ошибка при получении данных. Обратитесь в поддержку')
            onClose()
        }
        form.setFieldsValue({
            title: gtdData?.title,
            owner_ref: Number(gtdData?.owner_ref),
            description: gtdData?.description,
            // filename: gtdData?.file_name,
        })
        setFile({
            name: gtdData?.file_name,
            isOldFile: true
        })
    },[gtdData])

    useEffect(() =>{
        if (isErrorUpdate){
            message.error(errorMessage)
        }
        if (isSuccessUpdate){
            onClose()
            message.success('Вы успешно обновили ГТД')
        }
    },[isErrorUpdate, isSuccessUpdate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактировать ГТД"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                {
                    isLoading
                        ?
                        <div
                            style={{
                                padding: "150px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        <Col span={24}>
                            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                                <Form.Item
                                    label={"Введите название"}
                                    name='title'
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={"Выберите владельца"}
                                    name="owner_ref"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch
                                        value={selectedSeller}
                                        placeholder="Юр. лицо"
                                        filterOption={false}
                                        suffixIcon={<Icon
                                            component={SelectIconComponent}
                                            style={{marginTop: "2px", fontSize: "10px"}}
                                        />}
                                        onSearch={(value: any) => setSearch(value)}
                                        onChange={(e: any, option: any) => {
                                            setSelectedSeller(e)
                                        }}
                                    >
                                        {sellers?.data?.map((option: any) => {
                                            return (
                                                <Option key={option?.wbArticle} value={option?.id}>
                                                    {option.companyName}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"Введите описание (необязательно)"}
                                    name='description'
                                >
                                    <TextArea style={{height: 140}} rows={15}/>
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*    label={"Введите название файла"}*/}
                                {/*    name='filename'*/}
                                {/*    rules={[*/}
                                {/*        {*/}
                                {/*            required: true,*/}
                                {/*        },*/}
                                {/*    ]}*/}
                                {/*>*/}
                                {/*    <Input/>*/}
                                {/*</Form.Item>*/}

                                <AddTsdFileButton
                                    handleFileSelect={handleFileSelect}
                                    fileInputRef={fileInputRef}
                                    file={file}
                                    handleFileChange={handleFileChange}
                                />

                                <Col style={{
                                    display: 'flex',
                                    gap: 10
                                }}>
                                    <Button
                                        loading={updateLoading}
                                        type={"primary"}
                                        className={"button"}
                                        htmlType={"submit"}
                                        style={{
                                            fontSize: "12px",
                                            width: "50%",
                                        }}
                                    >
                                        Сохранить
                                    </Button>
                                    <Button
                                        onClick={onClose}
                                        className={"button"}
                                        type={"primary"}
                                        ghost
                                        style={{
                                            fontSize: "12px",
                                            width: "50%",
                                        }}
                                    >
                                        Отмена
                                    </Button>
                                </Col>
                            </Form>
                        </Col>
                }

            </Row>
        </div>
    );
}
