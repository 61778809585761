import React, {useState} from 'react';
import LaboratoryResultsTVTTable from "../../features/laboratoryTVT/LaboratoryResultsTVTTable";


const ResultsLaboratory = () => {
  return (
    <div>
      <LaboratoryResultsTVTTable/>
    </div>
  );
};

export default ResultsLaboratory;