import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "../api";

export const useKizesClear = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: createDeclaration,
        isSuccess: isSuccessClear,
        isLoading: clearLoading,
        isError: isErrorClear,
    } = useMutation(backend.declaration.clearKizes);

    const handleClear = useCallback(
        async (values: any) => {
            try {
                let body = { ...values };
                await createDeclaration(body);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [createDeclaration]
    );

    return {
        isSuccessClear,
        handleClear,
        clearLoading,
        isErrorClear,
        errorMessage,
    };
};
