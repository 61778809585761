import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Input,
    MenuProps,
    Modal,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as editIcon} from "../../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import useTopologyMainTableFilter from "../../../kiz/hooks/topology/useTopologyMainTableFilter";
import AddTopology from "../../modal/topology/AddTopology";
import EditTopology from "../../modal/topology/EditTopology";
import useTopologyData from "../../hooks/topology/useTopologyData";
import useTopologyTypeData from "../../hooks/topology/useTopologyTypeData";

const {Option} = Select;

export default function TopologyTable() {

    const {
        topologyData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        container,
        setContainer,
        containerId,
        setContainerId,
        getArticleFilterItems,
        getSizesFilterItems,
        getSellerFilterItems,
        isLoading
    } = useTopologyData()

    const {
        topologyTypesData,
        type,
        setType,
        isLoading: isLoadingTypes
    } = useTopologyTypeData()


    const [showTable, setShowTable] = useState<string>("location");

    const [addTopologyModal, setAddTopologyModal] = useState<any>(false);
    const [editTopologyModal, setEditTopologyModal] = useState<any>(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {
        }
    });

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const {filterMenuItems} = useTopologyMainTableFilter();

    const columnsProduct = [
        {
            title: "Расположение",
            dataIndex: "-",
            key: "-",
            width: "9%",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record?.box?.cell?.tier?.rack?.location?.title}
                    </div>
                )
            }
        },
        {
            title: "Стеллаж",
            dataIndex: "-",
            key: "-",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record?.box?.cell?.tier?.rack?.label}
                    </div>
                )
            }
        },
        {
            title: "Ярус стеллажа",
            dataIndex: "-",
            key: "-",
            width: "5%",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record?.box?.cell?.tier?.level}
                    </div>
                )
            }
        },
        {
            title: "Ячейка в ярусе",
            dataIndex: "-",
            key: "-",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record?.box?.cell?.label}
                    </div>
                )
            }
        },
        {
            title: "Короб",
            dataIndex: "-",
            key: "-",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record?.title}
                    </div>
                )
            }
        },
        {
            title: "Фото",
            dataIndex: "image_src",
            key: "image_src",
            width: "10%",
            render: (text: any) => {
                return (
                    <div style={{padding: 5}}>
                        {
                            text
                                ? <img src={text} alt="" style={{maxWidth: 100, maxHeight: 100}}/>
                                : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Товар в ячейке",
            dataIndex: "name",
            key: "name",
            width: "15%",
        },
        {
            title: "Баркод",
            dataIndex: "barcode",
            key: "barcode",
            width: "5%",
        },
        {
            title: "Ар-кул кит",
            dataIndex: "article_cns",
            key: "article_cns",
            width: "5%",
        },
        {
            title: "Ар-кул пост",
            dataIndex: "article_pvd",
            key: "article_pvd",
            width: "5%",
        },
        {
            title: "Ар-кул WB",
            dataIndex: "article_mkp",
            key: "article_mkp",
            width: "5%",
        },
        {
            title: "Размер",
            dataIndex: "dec_size",
            key: "dec_size",
            width: "5%",
        },
        {
            title: "Юр. лицо",
            dataIndex: "importer_name",
            key: "importer_name",
            width: "5%",
        },
        {
            title: "Кизовый",
            dataIndex: "isKiz",
            key: "isKiz",
            width: "5%",
            render: (text: any) => {
                return (
                    <div>
                        {text ? 'Да' : 'Нет'}
                    </div>
                )
            }
        },
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     render: (text: any, record: any) => (
        //         <div
        //             style={{
        //                 minHeight: 60,
        //                 textAlign: "center",
        //                 display: "flex",
        //                 gap: "7px",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 cursor: "pointer",
        //             }}
        //             // onClick={() => {
        //             //     setEditProductOpened(true);
        //             //     setProductToEdit(record);
        //             // }}
        //         >
        //             <Dropdown
        //                 trigger={["click"]}
        //                 placement={"bottomRight"}
        //                 menu={getTopologyActions(record)}
        //             >
        //                 <MoreOutlined
        //                     style={{cursor: "pointer", fontSize: "20px"}}/>
        //             </Dropdown>
        //
        //         </div>
        //     ),
        // },
    ];

    const topologyItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE",
        }
    ];

    const getTopologyActions = (record: any) => {
        return {
            items: topologyItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT":
                        setEditTopologyModal(true)
                        break;
                    case "DELETE":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить топологию?',
                            onPress: () => {
                            }
                        })
                        break;

                }
            },
        };
    };

    const rowSelection = {
        selectedRowKeys,
        columnWidth: '3%',
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    useEffect(() =>{
        setContainer(showTable)
        setType(showTable)
    },[showTable])

    useEffect(() =>{
        setContainerId(topologyTypesData?.[0]?.id)
    },[topologyTypesData])

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(text: any) => setSearchText(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск"
                        size="large"
                    />
                </Col>
                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getArticleFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по артикулу</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSellerFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по юр.лицу</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSizesFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по размеру</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>

            <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                    padding: "5px 20px 5px 30px",
                    marginTop: 20,
                    backgroundColor: '#fff'
                }}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Col>
                        {/*<div style={{position: "relative"}}>
                            <div
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    setFilterMenuOpened(!filterMenuOpened);
                                }}
                            >
                                <Icon component={filterIcon}/>
                                <span
                                    style={{
                                        color: "#82868B",
                                        marginLeft: "6px",
                                    }}
                                >
                                Фильтры
                            </span>
                            </div>

                            <Menu
                                className={
                                    filterMenuOpened
                                        ? "filter-menu filter-menu-opened"
                                        : "filter-menu filter-menu-closed"
                                }
                                style={{
                                    color: "red !important",
                                    width: "250px",
                                    left: "-40%",
                                }}
                                items={filterMenuItems}
                                mode={"inline"}
                                onClick={(e) => {
                                    // const key = e.key;
                                    // const test = key.split('-')
                                    // setSortParam(test[0]);
                                    // setSortType(test[1]);
                                }}
                            />
                        </div>*/}
                    </Col>
                    <Col style={{ marginLeft: 20,}}>
                        <Radio.Group
                            defaultValue={showTable}
                            style={{width: "100%"}}
                            onChange={(e) => setShowTable(e.target.value)}
                        >
                            <Radio.Button
                                value={"location"}
                                style={{
                                    width: "120px",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingRight: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Pасположение
                            </Radio.Button>
                            <Radio.Button
                                value={"rack"}
                                style={{
                                    width: "120px",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingRight: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Стеллаж
                            </Radio.Button>
                            <Radio.Button
                                value={"tier"}
                                style={{
                                    width: "120px",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingRight: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Ярус стеллажа
                            </Radio.Button>
                            <Radio.Button
                                value={"cell"}
                                style={{
                                    width: "120px",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingRight: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Ячейка в ярусе
                            </Radio.Button>
                            <Radio.Button
                                value={"box"}
                                style={{
                                    width: "120px",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingLeft: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Короб
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col style={{width: 200, marginLeft: 20}}>
                        <Select
                            className="table-header-select table-header-select-no-offset"
                            style={{width: 200}}
                            value={containerId}
                            defaultValue={topologyTypesData?.[0]?.id}
                            filterOption={false}
                            suffixIcon={<Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />}
                            onChange={(e) => setContainerId(e)}
                        >
                            {topologyTypesData?.map((option: any) => (
                                <Option key={option.id} value={option.id}>
                                    {option.title}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </div>

                <Col>
                    {/*<Button*/}
                    {/*    type={"primary"}*/}
                    {/*    className={"button"}*/}
                    {/*    style={{*/}
                    {/*        marginLeft: "10px",*/}
                    {/*        width: "171px",*/}
                    {/*        fontSize: "12px",*/}
                    {/*    }}*/}
                    {/*    onClick={() => setAddTopologyModal(true)}*/}
                    {/*>*/}
                    {/*    Добавить*/}
                    {/*</Button>*/}
                    <Button
                        disabled={selectedRowKeys.length === 0}
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "171px",
                            fontSize: "12px",
                        }}
                        // onClick={() => setAddTopologyModal(true)}
                    >
                        Отправить на отгрузку
                    </Button>
                </Col>
            </Row>


            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={columnsProduct}
                        rowSelection={rowSelection}
                        dataSource={topologyData?.data?.map((item: any, index: any) => ({
                            ...item,
                            key: item?.id,
                        }))}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(topologyData?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                </Col>
            </Row>

            <Modal
                open={addTopologyModal}
                closable={false}
                footer={null}
                width={600}
            >
                <AddTopology
                    onClose={() => setAddTopologyModal(false)}
                />
            </Modal>

            <Modal
                open={editTopologyModal}
                closable={false}
                footer={null}
                width={600}
            >
                <EditTopology
                    onClose={() => setEditTopologyModal(false)}
                />
            </Modal>

            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {
                        }
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
