import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import useCashbackStatuses from "./useCashbackStatuses";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../assets/images/search-icon.svg";
import Icon from "@ant-design/icons";

const PAGE_LIMIT = 10;

export default function useAnalyticsCashbackList() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [sortType, setSortType] = useState<string>("desc");
    const [sortParam, setSortParam] = useState<string>("id");
    const [debouncedSearchText] = useDebounce(searchText, 1000);

    const [selectedCashback, setSelectedCashback] = useState<Array<string>>(['1', '5']);

    const {cashBackStatuses} = useCashbackStatuses()

    // useEffect(() =>{
    //     let filter = cashBackStatuses
    //         ?.filter((item: any) => item?.title === 'Готов к запуску' || item?.title === 'В процессе')
    //         ?.map((item: any) => String(item?.id));
    //     setSelectedCashback(filter)
    // },[])

    const getCashbackStatuses = () => {
        if (!cashBackStatuses) {
            return [];
        }
        const items: MenuProps["items"] = [];
        cashBackStatuses?.forEach((item: any) => {
            items.push({
                label: (
                    <div
                        style={{ display: "flex", gap: "10px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={item.id}
                            checked={selectedCashback?.includes(item?.id?.toString())}
                            onChange={(e) => {
                                let newSelectedStatus = [...selectedCashback];
                                if (e.target.checked) {
                                    newSelectedStatus?.push(item?.id?.toString());
                                } else {
                                    newSelectedStatus = newSelectedStatus.filter(
                                        (el) => el !== item?.id?.toString()
                                    );
                                }
                                setSelectedCashback(newSelectedStatus);
                            }}
                        >
                            {item?.title}
                        </Checkbox>
                    </div>
                ),
                key: `cashback-status-${item.id}`,
            });
        });
        return items;
    };

    const {data: analyticsCashbacks, ...options} = useQuery(
        [
            "CASHBACK_RESULT_LIST",
            currentPage,
            currentPageSize,
            debouncedSearchText,
            sortParam,
            sortType,
            selectedCashback
        ],
        () =>
            backend.analytics.getAnalyticsCashbacksData(
                currentPage.toString(),
                currentPageSize.toString(),
                debouncedSearchText,
                sortParam,
                sortType,
                selectedCashback
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        analyticsCashbacks,
        currentPage,
        setCurrentPage,
        setSearchText,
        sortType,
        sortParam,
        setSortParam,
        setSortType,
        currentPageSize,
        setCurrentPageSize,
        getCashbackStatuses,
        ...options,
    };
}
