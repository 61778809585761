import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";

export default function CreateTaskModal({onClose}: any) {

    const [form] = Form.useForm();


    const onFinish = (values: any) => {
        // handleCreate(values)
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Создать задачу"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Введите название задачи"}
                            name='1'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"Выберите тип задачи"}
                            name='2'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "1"},
                                    {value: "2", label: "2"},
                                    {value: "3", label: "3"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Выберите исполнителя"}
                            name='3'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "1"},
                                    {value: "2", label: "2"},
                                    {value: "3", label: "3"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Выберите артикул товара"}
                            name='4'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "1"},
                                    {value: "2", label: "2"},
                                    {value: "3", label: "3"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Выберите размер товара"}
                            name='5'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "1"},
                                    {value: "2", label: "2"},
                                    {value: "3", label: "3"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Выберите поставку товара"}
                            name='6'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "1"},
                                    {value: "2", label: "2"},
                                    {value: "3", label: "3"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Введите описание задачи"}
                            name='7'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            label={"Введите комментарий к задаче"}
                            name='8'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                // loading={createLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Создать
                            </Button>
                            <Button
                                onClick={onClose}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
