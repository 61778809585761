import React, {useState} from "react";
import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Table,
    DatePicker,
    Modal, Menu, Dropdown,
} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import AddProductArrivalModalContent from "../../modal/productArrival/AddProductArrivalModalContent";
import EditProductArrivalModalContent from "../../modal/productArrival/EditProductArrivalModalContent";
import useProductArrivalData from "../../hooks/productArrival/useProductArrivalData";
import {DownloadStorageButton} from "../../components/DownloadStorageButton";
import dayjs from "dayjs";
import selectIcon from "../../../../assets/images/select-icon.svg";
import useExpensesTableFilters from "../../../operatingExpenses/hooks/expenses/useExpensesTableFilters";
import useProductArrivalTableFilters from "../../hooks/productArrival/useProductArrivalTableFilters";

const {RangePicker} = DatePicker;
const {Option} = Select;

export default function ProductArrivalTable() {

    const [addProductOpened, setAddProductOpened] = useState<boolean>(false);

    const {
        productArrivalData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        gtdData,
        setSearchGtd,
        selectedGtd,
        setSelectedGtd,
        setOrderBy,
        handleRangeChangeTable,
        getArticleCcdFilterItems,
        getSellerFilterItems,
        getArticleMkpFilterItems,
        getArticlePvdFilterItems,
        getArticleCnsFilterItems,
        getSizesFilterItems,
        isLoading
    } = useProductArrivalData();

    const {filterMenuItems} = useProductArrivalTableFilters();
    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);

    const columns = [
        {
            title: "Фото",
            dataIndex: "image_src",
            key: "image_src",
            width: "5%",
            render: (text: any, record: any) => {
                return (
                    <div style={{textAlign: "center"}}>
                        <img src={text} style={{width: "90px"}} alt=""></img>{" "}
                    </div>
                );
            },
        },
        {
            title: "Наименов.",
            dataIndex: "name",
            key: "name",
            width: "6%",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
            width: "6%",
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            key: "count",
            width: "6%",
        },
        {
            title: "Страна",
            dataIndex: "country",
            key: "country",
            width: "6%",
        },
        {
            title: "Бокскод",
            dataIndex: "box_code",
            key: "box_code",
            width: "6%",
        },
        {
            title: "Юр. лицо",
            dataIndex: "importer_name",
            key: "importer_name",
            width: "6%",
        },
        {
            title: "Штрихкод",
            dataIndex: "barcode",
            key: "barcode",
            width: "6%",
        },
        {
            title: "Производительность",
            dataIndex: "production_name",
            key: "production_name",
            width: "6%",
        },
        {
            title: "Размер по ГТД",
            dataIndex: "dec_size",
            key: "dec_size",
            width: "6%",
        },
        {
            title: "Артикул ГТД",
            dataIndex: "article_ccd",
            key: "article_ccd",
            width: "6",
        },
        {
            title: "Артикул ВБ",
            dataIndex: "article_mkp",
            key: "article_mkp",
            width: "6%",
        },
        {
            title: "Артикул Пост.",
            dataIndex: "article_pvd",
            key: "article_pvd",
            width: "6%",
        },
        {
            title: "Артикул Кит.",
            dataIndex: "article_cns",
            key: "article_cns",
            width: "6%",
        },
        {
            title: "Дата созд.",
            dataIndex: "created_at",
            key: "created_at",
            width: "6%",
            render: (text: any, record: any) =>{
                return(
                    <div>
                        {dayjs(text).format('DD.MM.YYYY')}
                    </div>
                )
            }
        },
        {
            title: "Дата обн.",
            dataIndex: "updated_at",
            key: "updated_at",
            width: "6%",
            render: (text: any, record: any) =>{
                return(
                    <div>
                        {dayjs(text).format('DD.MM.YYYY')}
                    </div>
                )
            }
        },
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     render: (text: any, record: any) => (
        //         <div
        //             style={{
        //                 textAlign: "center",
        //                 display: "flex",
        //                 gap: "7px",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 cursor: "pointer",
        //             }}
        //             onClick={() => {
        //                 setEditProductOpened(true);
        //                 setProductToEdit(record);
        //             }}
        //         >
        //             <Icon component={editIcon} style={{fontSize: "12px"}}/>
        //         </div>
        //     ),
        // },
    ];

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск"}
                    />
                </Col>

                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        showSearch
                        value={selectedGtd}
                        placeholder="ГТД"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearchGtd(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedGtd(e)
                        }}
                    >
                        {gtdData?.data?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.id}>
                                    {option.title}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>


                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>

                <Col style={{width: "180px"}}>
                    <div
                        className={"table-header-add-product"}
                        onClick={() => setAddProductOpened(true)}
                    >
                        Добавить товар
                    </div>
                </Col>

                {/*<Col>*/}
                {/*    <DownloadStorageButton*/}
                {/*        dateStart={searchText}*/}
                {/*        dateEnd={periodStartDate}*/}
                {/*        search={periodEndDate}*/}
                {/*    />*/}
                {/*</Col>*/}
            </Row>
            <Row gutter={6} align={"middle"} style={{marginTop: 10}}>
                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getArticleCcdFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Aртикул ГТД</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getArticleMkpFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Aртикул ВБ</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getArticlePvdFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Aртикул Пост.</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getArticleCnsFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Aртикул Кит.</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSizesFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Размер</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSellerFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Юр. лицо</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
            </Row>
            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Row
                        justify={"space-between"}
                        align={"middle"}
                        style={{padding: "5px 30px"}}
                    >
                        <Col >
                            <RangePicker
                                showTime
                                onChange={handleRangeChangeTable}
                                placeholder={["Начало", "Конец"]}
                                style={{width: "300px"}}
                                format={"YYYY-MM-DD HH:mm:ss"}
                            />
                        </Col>
                        <Col >
                            <div style={{position: "relative"}}>
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setFilterMenuOpened(!filterMenuOpened);
                                    }}
                                >
                                    <Icon component={filterIcon}/>
                                    <span
                                        style={{
                                            color: "#82868B",
                                            marginLeft: "6px",
                                        }}
                                    >
                                Фильтры
                            </span>
                                </div>

                                <Menu
                                    className={
                                        filterMenuOpened
                                            ? "filter-menu filter-menu-opened"
                                            : "filter-menu filter-menu-closed"
                                    }
                                    style={{
                                        color: "red !important",
                                        width: "250px",
                                        left: "-150%",
                                    }}
                                    items={filterMenuItems}
                                    mode={"inline"}
                                    onClick={(e: any) => {
                                        console.log(e)
                                        setOrderBy(e?.key)
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className={"product-arrival-table"}
                        columns={columns}
                        dataSource={productArrivalData?.data ?? []}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize): any => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(productArrivalData?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: (e) => {
                        //             setProductToInfo(record);
                        //             setProductInfoOpened(true);
                        //         },
                        //     };
                        // }}
                    />
                </Col>
            </Row>


            <Modal
                open={addProductOpened}
                footer={null}
                closable={false}
                width={640}
            >
                <AddProductArrivalModalContent
                    onClose={() => setAddProductOpened(false)}
                />
            </Modal>
        </div>
    );
}
