import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as headerPrevBtn } from "assets/images/header-prev-btn.svg";
import CashbackInfoTable from "features/cashbacks/pages/CashbackInfoTable";

export default function CashbackInfoPage() {
    const navigate = useNavigate();

    return (
        <Row style={{ padding: "20px" }}>
            <Col span={24}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate("/cashbacks")}
                >
                    <Icon component={headerPrevBtn} />
                    Назад
                </div>
            </Col>
            <Col span={24}>
                <CashbackInfoTable />
            </Col>
        </Row>
    );
}
