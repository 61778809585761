import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useGuidListData() {

    const [search, setSearch] = useState('');
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: guidListData, ...options } = useQuery(
        ["GUID_LIST_DATA", search],
        () =>
            backend.operatingExpenses.getGuideList(
                debouncedSearch.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        guidListData,
        search,
        setSearch,
        ...options,
    };
}
