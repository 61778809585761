import React from 'react';
import { useDrag } from 'react-dnd';
const ItemTypes = {
    PHRASE: 'phrase',
};
interface PhraseProps {
    item: any;
}

const Phrase: React.FC<PhraseProps> = ({ item }) => {

    const [, drag] = useDrag({
        type: ItemTypes.PHRASE,
        item: { item },
    });

    return (
        <div className="plus-minus-wrap-phrases-item" ref={drag}>
            {item?.title}
        </div>
    );
};

export default Phrase;
