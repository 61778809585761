import {
    Button, Checkbox,
    Col,
    Form,
    Input,
    message, Table,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useAdLeftMoney} from "../hooks/useAdLeftMoney";
import {useChangeCurrentCurrency} from "../hooks/useChangeCurrentCurrency";
import {Menubar} from "primereact/menubar";
import RecommendedPriceForm from "../components/RecommendedPriceForm";
import useAutoPriceDataById from "../hooks/useAutoPriceDataById";

export default function RecommendedPriceModal({
                                                       id,
                                                       onClose,
                                                   }: any) {



    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Автоматическое изменение цены"} onClose={onClose} />

           <RecommendedPriceForm
               id={id}
               onClose={onClose}
           />

        </div>
    );
}
