import {Col, Dropdown, Input, Row, Select, Table} from "antd";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import React, {useState} from "react";
import Icon, { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import useSellerList from "../../../sellers/hooks/useSellerList";
import useBiderLogsData from "../../hooks/boder-logs/useBiderLogsData";
import filterIcon from "../../../../assets/images/filter-icon.svg";

const { Option } = Select;

export default function BiderLogsTable() {


    const {
        data,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        selectedSeller,
        sellers,
        setSearchSellers,
        setSelectedSeller,
        searchArticle,
        setSearchArticle,
        isLoading
    } = useBiderLogsData()

    console.log(data)

    const columns = [
        {
            title: "Имя прдукта",
            dataIndex: "productName",
            key: "productName",
            width: "131px",
            render: (text: any, record: any) =>{

                return(
                    <div>
                        {
                            record?.product?.name
                        }
                    </div>
                )
            }
        },
        {
            title: "Артикул продукта",
            dataIndex: "productArticle",
            key: "productArticle",
            width: "102px",
            render: (text: any, record: any) =>{
                return(
                    <div>
                        {
                            record?.product?.wbArticle
                        }
                    </div>
                )
            }
        },
        {
            title: "Фото",
            dataIndex: "productPhoto",
            key: "productPhoto",
            width: "95px",
            render: (text: any, record: any) =>{
                return(
                    <img src={record?.product?.photo} alt="" style={{height: 100, width: 100, margin: 20}}/>
                )
            }
        },
        {
            title: "Описание Лога",
            dataIndex: "desc",
            key: "desc",
            width: "102px",
        },
        {
            title: "Тип лога",
            dataIndex: "type",
            key: "type",
            width: "102px",
        },
        {
            title: "ads Название",
            dataIndex: "cpc",
            key: "cpc",
            width: "102px",
            render: (text: any, record: any) =>{
                return(
                    <div>
                        {
                            record?.ads?.title
                        }
                    </div>
                )
            }
        },
    ];


    return (
        <div>
            <Row gutter={6} align={"middle"} style={{marginBottom: 20}}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={searchArticle}
                        // onChange={(text: any) => setSearchArticle(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск по артикулу"
                        size="large"
                    />
                </Col>

                <Col style={{width: "250px"}}>
                    <Select
                        className="table-header-select"
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSeller}
                        placeholder="Продавец"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearchSellers(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.map((option: any) => {
                            return (
                                <Option key={option?.wb_article} value={option?.id}>
                                    {option.company_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>

                <Col style={{width: "200px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(Number(e));
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>

            <Table
                // loading={isLoading}
                className="product-arrival-table"
                columns={columns}
                dataSource={data?.data || []}
                scroll={{x: true}}
                pagination={{
                    onChange: (page, pageSize) => setCurrentPage(page),
                    position: ["bottomCenter"],
                    pageSize: Number(currentPageSize),
                    total: Number(data?.total),
                    showSizeChanger: false,
                    current: currentPage,
                }}
            />

        </div>
    )
}