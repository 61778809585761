import {Button, Col, Input, Row, Select, DatePicker, Pagination, message, Spin} from "antd";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as uploadButtonIcon} from "assets/images/upload-button-icon.svg";
import ShipmentsFormationTableRow from "../../components/ShipmentsFormationTableRow";
import useSellersInfoForShipmentsFormation from "../../hooks/shipmentsFormation/useSellersInfoForShipmentsFormation";
import React, {useEffect, useRef, useState} from "react";
import {downloadEmissionFile} from "../../help/DownloadsFiles";
import {useUploadShipments} from "../../hooks/shipmentsFormation/useUploadShipments";
import useShimpentsFormationData from "../../hooks/shipmentsFormation/useShimpentsFormationData";
import {useChangeStatusShiments} from "../../hooks/shipmentsFormation/useChangeStatusShiments";

const {RangePicker} = DatePicker;
const {Option} = Select;

export default function ShimpentsFormationTable() {

    const fileInputRef = useRef<any>(null);

    const {
        sellers
    } = useSellersInfoForShipmentsFormation()

    const {
        handleCreate,
        uploadData,
        errorMessage,
        isError,
        isSuccess
    } = useUploadShipments()

    const {
        shimpentsFormationData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        sellectedSeller,
        setSellectedSeller,
        searchText,
        setSearchText,
        isLoading
    } = useShimpentsFormationData()

    const {
        handleCreate: handleChangeStatus,
        errorMessage: changeStatusErrorMessage,
        isLoading: changeStatusLoading,
        isError: isChangeStatusError,
        isSuccess: isChangeStatusSuccess
    } = useChangeStatusShiments()

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {

        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        if (!event.target.files[0]) {
            message.error('Выберите, пожалуйста, файл')
        } else {
            message.warning('Файл начал загрузку. Не закрывайте страницу')
            handleCreate({values: formData})
        }
    };

    const onChangeStatus = (status: any, id: any) =>{
        console.log(status, id)
        handleChangeStatus(status, id)
    }

    const renderTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }
        if (shimpentsFormationData?.data?.length !== 0 && shimpentsFormationData?.data) {
            return (
                <>
                    {
                        shimpentsFormationData?.data?.map((item: any) =>
                            <ShipmentsFormationTableRow
                                isLoadingCheckBox={changeStatusLoading}
                                item={item}
                                onChangeStatus={onChangeStatus}
                            />
                        )
                    }

                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={parseInt(currentPageSize)}
                        total={shimpentsFormationData.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            )
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        )
    }


    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
            fileInputRef.current.value = '';
        }
        if (isSuccess) {
            message.success('Вы успешно загрузили данные')
            fileInputRef.current.value = '';
        }
    }, [isError, isSuccess])


    return (
        <>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e?.target?.value)}
                        className="table-header-input"
                        placeholder={"Поиск"}
                    />
                </Col>

                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>

                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        style={{
                            width: '100%'
                        }}
                        allowClear
                        showSearch
                        value={sellectedSeller}
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onChange={(e) => setSellectedSeller(e)}
                        placeholder={"Юр. лицо"}
                    >
                        {sellers?.slice(1, 20)?.map((option: any) => (
                            <Option key={option?.value?.id} value={option?.value?.id}>
                                {option?.value?.companyName}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col>
                    <Button
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 0,
                            backgroundColor: '#92ef71'
                        }}
                        onClick={handleFileSelect}
                    >
                        Загрузить остатки
                        <Icon
                            component={uploadButtonIcon}
                            style={{fontSize: "18px", display: "block", transform: 'rotate(180deg)'}}
                        />
                    </Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                    />
                </Col>
                <Col>
                    <Button
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => downloadEmissionFile(`${process.env.REACT_APP_API_URL}sthouse/sellers/xlsx`)}
                    >
                        Выгрузить список юр. лиц
                        <Icon
                            component={uploadButtonIcon}
                            style={{fontSize: "18px", display: "block",}}
                        />
                    </Button>
                </Col>
            </Row>
            <Row className={"container"} style={{marginTop: "10px"}}>
                {/*<Col span={24}>*/}
                {/*    <Row*/}
                {/*        justify={"space-between"}*/}
                {/*        align={"middle"}*/}
                {/*        style={{ padding: "5px 30px" }}*/}
                {/*    >*/}
                {/*        <Col>*/}
                {/*            <RangePicker*/}
                {/*                placeholder={["Начало", "Конец"]}*/}
                {/*                separator={*/}
                {/*                    <Icon component={rangePickerArrow} />*/}
                {/*                }*/}
                {/*                style={{ width: "180px" }}*/}
                {/*            />*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*            <div style={{ cursor: "pointer" }}>*/}
                {/*                <Icon component={filterIcon} />*/}
                {/*                <span*/}
                {/*                    style={{*/}
                {/*                        color: "#82868B",*/}
                {/*                        marginLeft: "6px",*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    Фильтры*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Col>*/}
                <Col span={24}>
                    {
                        renderTable()
                    }


                </Col>
            </Row>
        </>
    );
}
