import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import plusIcon from 'assets/images/bluePlus.svg'
import circleIcon from 'assets/images/gtd-circle-icon.svg'
import AddTsdFileButton from "../../../document-mirror/components/AddTSDFileButton";
import {useCreateGTD} from "../../hooks/gtd/useCreateGTD";
import useExtRef from "../../../kiz/hooks/useExtRef";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

export default function AddGTD({onClose}: any) {

    const [form] = Form.useForm();
    const fileInputRef = useRef<any>(null);
    const [file, setFile] = useState<any>('')

    const {
        handleCreate,
        errorMessage,
        isError,
        isSuccess,
        isLoading
    } = useCreateGTD()

    const [selectedSeller, setSelectedSeller] = useState<any>('');
    const {
        sellers,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
    } = useExtRef();

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('file', file);

        if (!file){
            return message.warning('Пожалуйста, выберите файл')
        }

        console.log(values)
        handleCreate(values, formData)
    };


    useEffect(() =>{
        if (isError){
            message.error(errorMessage)
        }
        if (isSuccess){
            message.success("Вы успешно добавили ГТД")
            form.resetFields()
            setFile('')
            onClose()
        }
    },[isError, isSuccess])

    console.log(sellers)

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавить ГТД"} onClose={() =>{
                form.resetFields()
                setFile('')
                onClose()
            }}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Введите название"}
                            name='title'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Выберите владельца"}
                            name="owner_ref"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                value={selectedSeller}
                                placeholder="Юр. лицо"
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => setSearch(value)}
                                onChange={(e: any, option: any) => {
                                    setSelectedSeller(e)
                                }}
                            >
                                {sellers?.data?.map((option: any) => {
                                    return (
                                        <Option key={option?.wbArticle} value={option?.extRef}>
                                            {option.companyName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={"Введите описание (необязательно)"}
                            name='description'
                        >
                            <TextArea style={{height: 140}} rows={15}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите название файла"}
                            name='filename'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <AddTsdFileButton
                            handleFileSelect={handleFileSelect}
                            fileInputRef={fileInputRef}
                            file={file}
                            handleFileChange={handleFileChange}
                        />

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                loading={isLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Добавить
                            </Button>
                            <Button
                                onClick={() =>{
                                    form.resetFields()
                                    setFile('')
                                    onClose()
                                }}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
