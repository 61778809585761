import {useCallback, useState} from "react";
import { useMutation, useQueryClient } from "react-query";
import {backend} from "api";

export const useProductDeleteKeyWord = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [productId, setProductId] = useState<string>('');

    const { mutateAsync, ...options } = useMutation(
        backend.product.deleteProductsKeyWord,
        {
            onSuccess: async () => {
                await Promise.all([queryClient.invalidateQueries(["PRODUCT_DATA_BY_ID", productId]),]);
                await Promise.all([queryClient.invalidateQueries(["PRODUCTS_ALL_DATA"])]);
            },
            onError: async (e: any) => {
                setErrorMessage(e?.response?.data?.detail || e?.response?.data?.message)
            },
        }
    );

    const handleDelete = useCallback(
        async (id: string, productId: any) => {

            const data = {id, productId}
            await mutateAsync(data);
            await queryClient.invalidateQueries(["PRODUCT_DATA_BY_ID", productId]);
            await queryClient.invalidateQueries(["PRODUCTS_ALL_DATA"]);
        },
        [mutateAsync]
    );

    return {
        handleDelete,
        errorMessage,
        setId: setProductId,
        ...options,
    };
};
