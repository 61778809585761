import bannerImage from "assets/images/banner-image-table.png";
import companyItemsPhoto from "assets/images/company-items-photo.png";

export const advertisingMirrorData = [
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Приостановлена",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "card",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Приостановлена",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "catalogue",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Активная",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Активная",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Активная",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Активная",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Приостановлена",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Приостановлена",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Приостановлена",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Архивирована",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Архивирована",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Архивирована",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
    {
        type: "search",
        company: "Название рекламной компании",
        banner: [bannerImage, bannerImage, bannerImage],
        dateCreated: "01.03.2023",
        status: "Архивирована",
        ctr: 8,
        cpc: 8,
        budgetRemaining: 100,
    },
];

export const searchStatisticsData = [
    {
        keyword: "Всего по компании",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        keyword: "Ключевая фраза",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
];

export const catalogueStatisticsData = [
    {
        category: "Всего по компании",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
    {
        category: "Категория",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
];

export const cardStatisticsData = [
    {
        card: "Карточка",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        costs: "168 234, 06",
    },
];

export const companyItemsData = [
    {
        photo: "",
        title: "Всего по компании",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        bucketAdded: "2",
        ordered: "10",
        sum: "1 043",
    },
    {
        photo: companyItemsPhoto,
        title: "149731164, Куртка женская для весны",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        bucketAdded: "2",
        ordered: "10",
        sum: "1 043",
    },
    {
        photo: companyItemsPhoto,
        title: "149731164, Куртка женская для весны",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        bucketAdded: "2",
        ordered: "10",
        sum: "1 043",
    },
    {
        photo: companyItemsPhoto,
        title: "149731164, Куртка женская для весны",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        bucketAdded: "2",
        ordered: "10",
        sum: "1 043",
    },
    {
        photo: companyItemsPhoto,
        title: "149731164, Куртка женская для весны",
        views: "81426",
        frequency: "1.05",
        clicks: "3228",
        ctr: "4.58",
        cpc: "43.58",
        bucketAdded: "2",
        ordered: "10",
        sum: "1 043",
    },
];
