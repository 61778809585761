import { useState } from "react";
import { Col, ConfigProvider, Menu, Modal, Row, Switch, Table } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as filterIcon } from "assets/images/filter-icon.svg";
import { ReactComponent as editIcon } from "assets/images/edit-icon.svg";
import EditCashbackUserModalContent from "../modal/EditCashbackUserModalContent";
import CashbackUserHistoryModalContent from "../modal/CashbackUserHistoryModalContent";
import useAnalyticsCashbackUsersList from "../hooks/useAnalyticsCashbackUsersList";
import { useAnalyticsCashbackUsersUpdate } from "../hooks/useAnalyticsCashbackUsersUpdate";
import useCashbacksUsersTableTabFilters from "../hooks/useCashbacksUsersTableTabFilters";

export default function CashbacksUsersTable() {

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const [editCashbackUserOpened, setEditCashbackUserOpened] = useState<boolean>(false);
    const [cashbackUserHistoryOpened, setCashbackUserHistoryOpened] = useState<boolean>(false);

    const { filterMenuItems } = useCashbacksUsersTableTabFilters();

    const [selectedCashbackUser, setSelectedCashbackUser] = useState(null);

    const {
        analyticsUsersCashbacks,
        currentPage,
        setCurrentPage,
        sortType,
        sortParam,
        setSortType,
        setSortParam,
        isLoading,
    } = useAnalyticsCashbackUsersList();

    const { handleUpdateById } = useAnalyticsCashbackUsersUpdate(-1);

    const cashbackUsersColumns = [
        {
            title: "No",
            dataIndex: "id",
            key: "id",
            width: "6%",
            render: (text : any, record: any) => {

                const onClick = () =>{
                    setUserToOpen(record);
                    setCashbackUserHistoryOpened(true);
                }

                return (
                    <div onClick={onClick} style={{cursor: 'pointer'}}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Имя пользователя",
            dataIndex: "firstName",
            key: "firstName",
            width: "13%",
        },
        {
            title: "Фамилия пользователя",
            dataIndex: "lastName",
            key: "lastName",
            width: "14%",
        },
        {
            title: "Отчество пользователя",
            dataIndex: "middleName",
            key: "middleName",
            width: "14%",
        },
        {
            title: "Телефон пользователя",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            width: "14%",
        },
        {
            title: "Телеграм пользователя",
            dataIndex: "tgNickname",
            key: "tgNickname",
            width: "12%",
        },
        {
            title: "Заметки",
            dataIndex: "notes",
            key: "notes",
            width: "15%",
            render: (text: any, record: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    {text}
                    <Icon component={editIcon} style={{ fontSize: "16px" }} />
                </div>
            ),
        },
        {
            title: "Добавить в бан",
            dataIndex: "actionBan",
            key: "actionBan",
            width: "11%",
            render: (text: any, record: any) => (
                <ConfigProvider theme={{ token: { colorPrimary: "#28C76F" } }}>
                    <Switch
                        onChange={(checked) => {
                            handleUpdateById(
                                { userId: record.id, isBanned: checked },
                                () => {}
                            );
                        }}
                        checked={record.isBanned}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: "",
            dataIndex: "actionEdit",
            key: "actionEdit",
            render: (text: any, record: any) => (
                <div
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        fontSize: "16px",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();

                        setSelectedCashbackUser(record);
                        setEditCashbackUserOpened(true);
                    }}
                >
                    <Icon component={editIcon} />
                </div>
            ),
        },
    ];

    const [userToOpen, setUserToOpen] = useState<any>();

    return (
        <Row className={"container"} style={{ marginTop: "10px" }}>
            <Col span={24}>
                <Row
                    justify={"space-between"}
                    align={"middle"}
                    style={{ padding: "10px 20px 10px 60px" }}
                >
                    <Col>
                        <div style={{ position: "relative" }}>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setFilterMenuOpened(!filterMenuOpened);
                                }}
                            >
                                <Icon component={filterIcon} />
                                <span
                                    style={{
                                        color: "#82868B",
                                        marginLeft: "6px",
                                    }}
                                >
                                    Фильтры
                                </span>
                            </div>

                            <Menu
                                className={
                                    filterMenuOpened
                                        ? "filter-menu filter-menu-opened"
                                        : "filter-menu filter-menu-closed"
                                }
                                style={{
                                    color: "red !important",
                                    width: "250px",
                                    left: "-40%",
                                }}
                                items={filterMenuItems}
                                onClick={(e) => {
                                    const key = e.key;
                                    const test = key.split(':')
                                    setSortParam(test[0]);
                                    setSortType(test[1]);
                                }}
                                mode={"inline"}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Table
                    loading={isLoading}
                    className={"cashback-table"}
                    columns={cashbackUsersColumns}
                    dataSource={analyticsUsersCashbacks?.data ?? []}
                    pagination={{
                        onChange: (page, pageSize): any => setCurrentPage(page),
                        position: ["bottomCenter"],
                        pageSize: Number(10),
                        total: Number(analyticsUsersCashbacks?.total),
                        showSizeChanger: false,
                        current: currentPage,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            // onClick: (e) => {
                            //     setUserToOpen(record);
                            //     setCashbackUserHistoryOpened(true);
                            // },
                        };
                    }}
                />
            </Col>
            <Modal
                open={editCashbackUserOpened}
                footer={null}
                closable={false}
                width={982}
            >
                <EditCashbackUserModalContent
                    cashbackUser={selectedCashbackUser}
                    onClose={() => setEditCashbackUserOpened(false)}
                />
            </Modal>
            {userToOpen && (
                <Modal
                    open={cashbackUserHistoryOpened}
                    footer={null}
                    closable={false}
                    width={982}
                >
                    <CashbackUserHistoryModalContent
                        id={userToOpen.id}
                        onClose={() => setCashbackUserHistoryOpened(false)}
                    />
                </Modal>
            )}
        </Row>
    );
}
