import { Col, Row } from 'antd'
import React from 'react'
import SettingsMirrorUsersDataTable from '../../features/settingsMirror/SettingsMirrorUsersData'

export default function SettingsMirrorUsersDataPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<SettingsMirrorUsersDataTable />
			</Col>
		</Row>
	)
}
