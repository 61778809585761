import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useWarehousesData() {

  const [search, setSearch] = useState<any>('')
  const [debouncedSearch] = useDebounce(search, 1000);

  const { data: warehouse, ...options } = useQuery(
    ["WAREHOUSES_LIST", debouncedSearch],
    () => backend.shipments.getWarehouses(debouncedSearch),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    warehouse,
    search,
    setSearch,
    ...options,
  };
}
