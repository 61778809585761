import React, {useEffect} from "react";
import {Layout, theme} from "antd";
import Header from "./Header";
import MenuContent from "./MenuContent";
import {useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import './styles.css'
import {useAuthStore} from "../auth/hooks/useAuthStore";

const {Content, Sider} = Layout;

const AppLayout: React.FC = () => {

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    let location = useLocation();
    const {state} = useAuthStore();

    const isFinance = location?.pathname?.includes('financial-results') ||
        location?.pathname?.includes('innovation-proposals') ||
        location?.pathname?.includes('questions-and-answers')

    const [collapsed, setCollapsed] = useState(false);
    const [isShowBurger, setIsShowBurger] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 1350 && !isShowBurger) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
            console.log(screenWidth < 1350 && !isShowBurger)

        };

        handleResize(); // Выполняем проверку при загрузке страницы

        window.addEventListener('resize', handleResize); // Подписываемся на событие изменения размера окна

        return () => {
            window.removeEventListener('resize', handleResize); // Отписываемся от события при размонтировании компонента
        };
    }, [isShowBurger]);

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header
                isFinance={isFinance}
                isShowBurger={isShowBurger}
                setIsShowBurger={setIsShowBurger}
            />
            <Layout>
                <Sider
                    width={isShowBurger ? 0 : '100%'}
                    style={{background: colorBgContainer}}
                    collapsed={collapsed}
                    collapsedWidth={68}
                    className="header-mob-bar"
                >
                    <MenuContent
                        isFinance={isFinance}
                        mode="inline"
                        defaultSelectedKeys={["1"]}
                        defaultOpenKeys={["sub1"]}
                        style={{eight: "100%", borderRight: 0}}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        isShowBurger={isShowBurger}
                        setIsShowBurger={setIsShowBurger}
                        isMob={true}
                    />
                </Sider>
                <Sider
                    width={230}
                    style={{background: isFinance ? '#5C4E73' : colorBgContainer}}
                    collapsed={collapsed}
                    collapsedWidth={68}
                    className="header-desc-bar"
                >
                    <MenuContent
                        isFinance={isFinance}
                        mode="inline"
                        defaultSelectedKeys={["1"]}
                        defaultOpenKeys={["sub1"]}
                        style={{height: "100%", borderRight: 0}}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        isShowBurger={isShowBurger}
                        setIsShowBurger={setIsShowBurger}
                        isMob={false}
                    />
                </Sider>

                {isShowBurger &&
                <Layout>
                    <Content
                        style={{
                            margin: 0,
                            minHeight: 280,
                            background: "#F8F8F8",
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
                }
            </Layout>
        </Layout>
    );
};

export default AppLayout;
