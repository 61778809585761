import { Spin, Table } from "antd";
import ModalHeader from "features/shared/ModalHeader";
import useCashbackUserHistory from "../hooks/useCashbackUserHistory";
import { LoadingOutlined } from "@ant-design/icons";

const cashbacksUserHistoryColumns = [
    {
        title: "Название акции",
        dataIndex: "name",
        key: "name",
        width: "14%",
        render: (text: any, record: any, index: any) => {
            return record.cashbackAction.name;
        },
    },
    {
        title: "Товар",
        dataIndex: "product",
        key: "product",
        width: "14%",
        render: (text: any, record: any, index: any) => {
            return record.cashbackAction.nmId;
        },
    },
    {
        title: "Размер",
        dataIndex: "size",
        key: "size",
        width: "8%",
        render: (text: any, record: any, index: any) => {
            return record.cashbackItem.size;
        },
    },
    {
        title: "Дата участ",
        dataIndex: "publishDate",
        key: "publishDate",
        width: "8%",
        render: (text: any, record: any, index: any) => {
            const date = new Date(record.cashbackAction.publishDate);
            return date.toLocaleDateString();
        },
    },
    {
        title: "Статус выполнен условий",
        dataIndex: "",
        key: "conditionStatus",
        width: "14%",
        render: (record: any, index: any) => {
            if (
                record.isCondition1 &&
                record.isCondition2 &&
                record.isCondition3 &&
                record.isCondition4 &&
                record.isCondition5
            ) {
                return <>Выполнены</>;
            }
            return <>Не выполнены</>;
        },
    },
    {
        title: "Статус получения кешбека",
        dataIndex: "statusTitle",
        key: "statusTitle",
        width: "14%",
        render: (text: any, record: any, index: any) => {
            return record.cashbackContractStatus.title;
        },
    },
    {
        title: "Полученный кешбек, руб",
        dataIndex: "cashbackAction.cashbackSum",
        key: "cashbackSum",
        width: "14%",
        render: (text: any, record: any, index: any) => {
            return record.cashbackAction.cashbackSum;
        },
    },
    {
        title: "Заметки",
        dataIndex: "notes",
        key: "notes",
        width: "13%",
        render: (text: any, record: any) => (
            <div
                style={{
                    textAlign: "center",
                    display: "flex",
                    gap: "7px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                Текст
            </div>
        ),
    },
];

interface CashbackUserHIstoryModalProps {
    id: number;
    onClose: any;
}

export default function CashbackUserHistoryModalContent({
    id,
    onClose,
}: CashbackUserHIstoryModalProps) {
    const { userHistory, isLoading } = useCashbackUserHistory(id.toString());
    console.log(userHistory);

    const renderCashbackUserHistoryTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                    />
                </div>
            );
        }

        if (userHistory?.data && userHistory.data.length > 0) {
            return (
                <>
                    <Table
                        className={"cashback-table"}
                        columns={cashbacksUserHistoryColumns}
                        dataSource={userHistory.data}
                        pagination={false}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    return (
        <div className={"modal-wrapper"} style={{ padding: "30px" }}>
            <ModalHeader
                title={"История пользователя за все время"}
                onClose={onClose}
            />
            {renderCashbackUserHistoryTable()}
        </div>
    );
}
