import {useCallback, useState} from "react";
import { useMutation, useQueryClient } from "react-query";
import {backend} from "api";

export const useCoordinatorDeleteAnalytics = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [id, setId] = useState<string>('');

    const { mutateAsync, ...options } = useMutation(
        backend.coordinatorUser.deleteCoordinatorUserAnalytics,
        {
            onSuccess: async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["COORDINATOR_USER_DATA_BY_ID", id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["COORDINATOR_USER_LIST"]),
                ]);
            },
            onError: (e: any) => {
                setErrorMessage(e?.response?.data?.message);
            },
        }
    );

    const handleDelete = useCallback(
        async (values: any) => {
            const data = {
                coordinatorId: values?.coordinatorId,
                analytictId: values?.analytictId,
            };
            try{
                await mutateAsync(data);
            }catch (e: any) {
                setErrorMessage(e?.response?.data?.message)
            }
            await queryClient.invalidateQueries([
                "COORDINATOR_USER_DATA_BY_ID",
                values?.coordinatorId,
            ]);
            await queryClient.invalidateQueries(["COORDINATOR_USER_LIST"]);
        },
        [mutateAsync, queryClient]
    );

    return {
        handleDelete,
        errorMessage,
        setId,
        ...options,
    };
};
