import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useState} from "react";

export default function usePublishToTestGroup() {

    const [id, setId] = useState<any>(null);

    const {data: data, ...options} = useQuery(
        [
            "PUBLISH_TO_TEST_GROUP",
            id,
        ],
        () =>
            backend.analytics.publishToTestGroup(
                id.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        id,
        setId,
        ...options,
    };
}
