import axios from "axios";
import { getToken, removeToken } from "utils/tokenStorage";

import * as declaration from "./declaration";
import * as kizes from "./kizes";

export const createApiKiz = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL_KIZ,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
            Accept: "application/json, application/x-www-form-urlencoded",
        },
    });

    instance.interceptors.request.use((config) => {
        const token = getToken();
        if (token) {
            (config.headers as any)["Authorization"] = `Bearer ${token}`;
        }

        return config;
    });

    instance.interceptors.response.use(
        (res) => res,
        (error) => {
            if (error.response.status === 401) {
                removeToken();
                if (error.config?.url !== "/auth/token") {
                    //if sent request is not login
                    window.location.reload(); //then reload so that user can login again
                }
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export const backend = {
    declaration,
    kizes
};
