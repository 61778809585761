// Requests
import {apiKiz, apiKizFullInfo} from "../../../index";
import dayjs from "dayjs";

export async function getEmissionsData(
    page: string,
    pageSize: string,
    search?: string,
    gtinSearch?: string,
    dateCreateStart?: string,
    dateCreateEnd?: string,
    dateUpdateStart?: string,
    dateUpdateEnd?: string,
    dateCustomedStart?: string,
    dateCustomedEnd?: string,
    order ?: string
) {
    const params = new URLSearchParams();
    params.append("limit", pageSize);
    params.append("page", page);

    params.append("uid", 'tester');

    if (search) {
        params.append("title", search);
    }
    if (gtinSearch) {
        params.append("gtin", String(gtinSearch));
    }

    if (order) {
        params.append("order_by", order);
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    const response = await apiKizFullInfo.get(`/emissions`, {
        params,
    });
    return response.data;
}

export async function getDeclarationsDataById(
    id: string,
    page: string,
    pageSize: string,
    search?: string,
    searchArticle?: string,
    dateCreateStart?: string,
    dateCreateEnd?: string,
    dateUpdateStart?: string,
    dateUpdateEnd?: string,
    dateCustomedStart?: string,
    dateCustomedEnd?: string,
    order?: string,
    selectedSizes?: string[],
    selectedBoxCode?: string[],
) {
    const params = new URLSearchParams();
    params.append("limit", pageSize);
    params.append("page", page);

    params.append("uid", 'tester');

    if (search) {
        params.append("name", search);
    }

    if (searchArticle) {
        params.append("article_china", searchArticle);
    }

    if (order) {
        params.append("order_by", order);
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (selectedSizes?.length !== 0 && selectedSizes) {
        params.append("ru_size", selectedSizes?.join(','));
    }

    if (selectedBoxCode?.length !== 0  && selectedBoxCode) {
        params.append("box_code", selectedBoxCode?.join(','));
    }

    const response = await apiKizFullInfo.get(`/emissions/${id}/cises`, {
        params,
    });

    return response.data;
}


export async function getEmissionsDataFilterSize(
    id?: string,
    fieldParam?: string,
    idDeclaration?: string,
) {
    const params = new URLSearchParams();

    params.append("uid", 'tester');

    if (id) {
        params.append("eid", id);
    }
    if (fieldParam) {
        params.append("field", fieldParam);
    }

    if (idDeclaration) {
        params.append("did", idDeclaration);
    }

    const response = await apiKizFullInfo.get(`/cises_filter_vals`, {
        params,
    });
    return response.data;
}

export async function deleteEmission(
    emissionId?: any,
    userId?: any,
) {
    const params = new URLSearchParams();

    params.append("uid", 'tester');

    if (emissionId) {
        params.append("eid", emissionId);
    }
    if (userId) {
        params.append("uid", userId);
    }

    const response = await apiKizFullInfo.delete(`/emissions/delete`, {params});
    return response.data;
}

export async function deleteEmissionFullInfo(
    emissionId?: any,
    userId?: any,
) {
    const params = new URLSearchParams();

    if (emissionId) {
        params.append("eid", emissionId);
    }
    if (userId) {
        params.append("uid", userId);
    }

    const response = await apiKizFullInfo.delete(`/cises/delete`, {params});
    return response.data;
}

export async function deleteItemOfListAcceptance(
    handle?: any,
    userId?: any,
) {
    const params = new URLSearchParams();

    params.append("uid", 'tester');

    if (handle) {
        params.append("handle", handle);
    }
    if (userId) {
        params.append("uid", userId);
    }

    const response = await apiKizFullInfo.delete(`/shipments/file`, {params});
    return response.data;
}