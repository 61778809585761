import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Form,
	Input,
	MenuProps,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Spin,
	Switch,
	Table,
	Tooltip,
} from 'antd'
import ModalHeader from 'features/shared/ModalHeader'
import React, { useEffect, useRef, useState } from 'react'
import { useAddFile } from '../hooks/useAddFile'
import { ReactComponent as SelectIconComponent } from '../../../assets/images/select-icon.svg'
import Icon, { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

export default function AddFileModal({
	onClose,
	modalData,
	selectedSeller,
}: any) {
	const [form] = Form.useForm()

	const [selectedFile, setSelectedFile] = useState<any>()

	const { handleAddFile, errorMessage, isLoading, isSuccess, isError } =
		useAddFile()

	const onFinish = (values: any) => {
		if (!selectedFile) {
			return message.error('Пожалуйста выберите файл')
		}

		console.log({
			file: selectedFile,
		})

		handleAddFile({
			file: selectedFile,
		})
	}

	const fileInputRef = useRef<any>(null)

	const handleFileSelect = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files && event.target.files[0]

		setSelectedFile(selectedFile)
	}

	useEffect(() => {
		if (isSuccess) {
			// message.success('Вы успешно добавили файл')
			onClose()
			form.resetFields()
			setSelectedFile('')
			fileInputRef.current.value = ''
		}
		if (isError) {
			message.error(
				errorMessage ||
					'Произошла ошибка при попытке добавить файл, обратитесь в поддержку'
			)
		}
	}, [isSuccess, isError])

	return (
		<div className={'modal-wrapper'} style={{ padding: '30px', width: '90%' }}>
			<ModalHeader
				title={'Добавить файл продуктов'}
				onClose={() => {
					onClose()
					form.resetFields()
					setSelectedFile('')
					fileInputRef.current.value = ''
				}}
			/>
			<Row
				className={'container'}
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<Form
					form={form}
					layout={'vertical'}
					onFinish={onFinish}
					style={{
						width: '100%',
					}}
				>
					{selectedFile?.name && <div>{selectedFile?.name}</div>}
					<input
						accept='.xls,.xlsx'
						type='file'
						ref={fileInputRef}
						style={{ display: 'none' }}
						onChange={handleInputChange}
					/>
					<Button
						type='dashed'
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: 10,
						}}
						onClick={handleFileSelect}
					>
						Добавить файл
					</Button>
					<Row gutter={16} style={{ justifyContent: 'flex-end' }}>
						<Col span={12}>
							<Button
								loading={isLoading}
								className={'button'}
								type={'primary'}
								style={{ width: '100%' }}
								htmlType={'submit'}
							>
								Отправить
							</Button>
						</Col>
					</Row>
				</Form>
			</Row>
		</div>
	)
}
