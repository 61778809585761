import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../apiExtraInfo";

import {useCallback, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import useEmmissionsDataFilterSize from "./useEmmissionsDataFilterSize";
import useEmmissionsDataFilterBoxCode from "./useEmmissionsDataFilterBoxCode";
import Icon from "@ant-design/icons";
import useEmmissionsDataFilterChineArticle from "./useEmmissionsDataFilterChineArticle";

export default function useEmissionsDataById() {

    const [id, setId] = useState<any>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch] = useDebounce(search, 1000);
    const [searchAcricle, setSearchAcricle] = useState<string>("");
    const [debouncedAcricle] = useDebounce(searchAcricle, 1000);

    const [order, setOrderBy] = useState<string>("");

    const [dateCreateStart, setDateCreateStart] = useState<string>("");
    const [dateCreateEnd, setDateCreateEnd] = useState<string>("");

    const [dateUpdateStart, setDateUpdateStart] = useState<string>("");
    const [dateUpdateEnd, setDateUpdateEnd] = useState<string>("");

    const [dateCustomedStart, setDateCustomedStart] = useState<string>("");
    const [dateCustomedEnd, setDateCustomedEnd] = useState<string>("");

    const onChangeDateCreate = (dateStrings: any) => {
        if (!dateStrings) {
            setDateCreateStart('')
            setDateCreateEnd('')
        } else {
            setDateCreateStart(dateStrings[0])
            setDateCreateEnd(dateStrings[1])
        }
    }
    const onChangeDateUpdate = (dateStrings: any) => {
        if (!dateStrings) {
            setDateUpdateStart('')
            setDateUpdateEnd('')
        } else {
            setDateUpdateStart(dateStrings[0])
            setDateUpdateEnd(dateStrings[1])
        }
    }
    const onChangeDateCustomed = (dateStrings: any) => {
        if (!dateStrings) {
            setDateCustomedStart('')
            setDateCustomedEnd('')
        } else {
            setDateCustomedStart(dateStrings[0])
            setDateCustomedEnd(dateStrings[1])
        }
    }

    const {sizes, setId: setIdForSize} = useEmmissionsDataFilterSize()
    const {boxCode} = useEmmissionsDataFilterBoxCode()
    const {chineArticle, setId: setIdForArticle} = useEmmissionsDataFilterChineArticle()

    const [searchSizesFilter, setSearchSizesFilter] = useState(sizes)
    const [searchBoxCodeFilter, setSearchBoxCodeFilter] = useState(boxCode)

    const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);
    const [selectedBoxCode, setSelectedBoxCode] = useState<Array<string>>([]);

    const getSizeFilterItems = () => {
        if (!searchSizesFilter) {
            return [];
        }
        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                        onChange={(e) => setSearchSizesFilter(sizes.filter((item: any) => item.toLowerCase().includes(e.target.value.toLowerCase())))}
                    />
                ),
                key: "size-search",
            },
        ];
        searchSizesFilter?.forEach((size: any) => {
            items.push({
                label: (
                    <div
                        style={{display: "flex", gap: "10px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={size}
                            checked={selectedSizes.includes(size)}
                            onChange={(e) => {
                                let newSelectedSizes = [...selectedSizes];
                                if (e.target.checked) {
                                    newSelectedSizes.push(size);
                                } else {
                                    newSelectedSizes = newSelectedSizes.filter(
                                        (el) => el !== size
                                    );
                                }
                                setSelectedSizes(newSelectedSizes);
                            }}
                        >
                            {size}
                        </Checkbox>
                    </div>
                ),
                key: `size-${size}`,
            });
        });
        return items;
    };

    const getBoxCodeFilterItems = () => {
        if (!searchBoxCodeFilter) {
            return [];
        }
        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                        onChange={(e) => setSearchBoxCodeFilter(boxCode.filter((item: any) => item.toLowerCase().includes(e.target.value.toLowerCase())))}
                    />
                ),
                key: "boxCode-search",
            },
        ];
        searchBoxCodeFilter?.forEach((item: any) => {
            items.push({
                label: (
                    <div
                        style={{display: "flex", gap: "10px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={item}
                            checked={selectedBoxCode.includes(item)}
                            onChange={(e) => {
                                let newSelectedSeller = [...selectedBoxCode];
                                if (e.target.checked) {
                                    newSelectedSeller.push(item);
                                } else {
                                    newSelectedSeller = newSelectedSeller.filter(
                                        (el) => el !== item
                                    );
                                }
                                setSelectedBoxCode(newSelectedSeller);
                            }}
                        >
                            {item}
                        </Checkbox>
                    </div>
                ),
                key: `seller-${item}`,
            });
        });
        return items;
    };

    useEffect(() =>{
        if (sizes?.length !== 0){
            setSearchSizesFilter(sizes)
        }
        if (boxCode?.length !== 0){
            setSearchBoxCodeFilter(boxCode)
        }
    },[sizes, boxCode])

    useEffect(() =>{
        setIdForArticle(id)
        setIdForSize(id)
    }, [id])

    const { data: emissionsById, ...options } = useQuery(
        [
            "EMISSIONS_DATA_BY_ID",
            currentPage,
            currentPageSize,
            id,
            debouncedSearch,
            debouncedAcricle,
            dateCreateStart,
            dateCreateEnd,
            dateUpdateStart,
            dateUpdateEnd,
            dateCustomedStart,
            dateCustomedEnd,
            order,
            selectedSizes,
            selectedBoxCode
        ],
        () =>
            backend.emissions.getDeclarationsDataById(
                id?.toString(),
                currentPage.toString(),
                currentPageSize,
                debouncedSearch.toString(),
                debouncedAcricle.toString(),
                dateCreateStart,
                dateCreateEnd,
                dateUpdateStart,
                dateUpdateEnd,
                dateCustomedStart,
                dateCustomedEnd,
                order,
                selectedSizes,
                selectedBoxCode
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        id,
        setId,
        emissionsById,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        search,
        setSearch,
        setOrderBy,
        searchAcricle,
        setSearchAcricle,
        selectedSizes,
        selectedBoxCode,
        dateCreateStart,
        dateCreateEnd,
        dateUpdateStart,
        dateUpdateEnd,
        dateCustomedStart,
        dateCustomedEnd,
        chineArticle,
        onChangeDateCreate,
        onChangeDateUpdate,
        onChangeDateCustomed,
        getSizeFilterItems,
        getBoxCodeFilterItems,
        ...options,
    };
}