import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSizeList() {

    const [search, setSearch] = useState<any>('')
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: sizes, ...options } = useQuery(
        ["SIZE_LIST", debouncedSearch],
        () => backend.sizes.getSizes(debouncedSearch),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        sizes,
        search,
        setSearch,
        ...options,
    };
}
