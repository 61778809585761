import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import useKizDataByGTD from "../../hooks/gtd/useKizDataByGTD";
import {useDeleteKizDocumentById} from "../../hooks/gtd/useDeleteKizDocumentById";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

export default function EditKizFilesModal({onClose, gtdId}: any) {

    const {
        kizData,
        id,
        setId,
        isLoading
    } = useKizDataByGTD()

    const {
        handleDelete,
        errorMessage,
        isError,
        isSuccess,
        isLoading: isLoadingDelete
    } = useDeleteKizDocumentById()

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    useEffect(() => {
        setId(gtdId)
    }, [gtdId])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
        if (isSuccess) {
            message.success('Вы успешно удалили документ')
        }
    }, [isError, isSuccess])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактировать КИЗ файлы"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <div className="storage-edit-kiz-files">
                        {
                            isLoading
                                ?
                                <div
                                    style={{
                                        padding: "150px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Spin
                                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                    />
                                </div>
                                :
                                kizData?.documents?.length === 0 || !kizData?.documents
                                    ?
                                    <div style={{margin: '0 auto', padding: 10}}>
                                        Нет данных
                                    </div>
                                    :
                                    kizData?.documents?.map((item: any, index: number) =>
                                        <div className="storage-edit-kiz-files-item">
                                            <div className="storage-edit-kiz-files-item-title">
                                                {item?.title}
                                            </div>
                                            <div className="storage-edit-kiz-files-item-right">
                                                <div
                                                    className="storage-edit-kiz-files-item-right-item"
                                                    style={{color: '#EA5455'}}
                                                    onClick={() => setIsModalVisible({
                                                        isOpen: true,
                                                        text: 'удалить документ?',
                                                        onPress: () => handleDelete(item?.id)
                                                    })}
                                                >
                                                    <Icon
                                                        component={trashBucket}
                                                        style={{cursor: "pointer", marginLeft: 10,}}
                                                    />
                                                    Удалить
                                                </div>
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    isLoading={isLoadingDelete}
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
