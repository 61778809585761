import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "../../api";

export const useLoadCustomsClearedKizes = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: onUpload,
        data: uploadData,
        ...options
    } = useMutation(backend.declaration.loadCustomsClearedKizes);

    const handleCreate = useCallback(
        async (values: any, id: string) => {
            try {
                await onUpload({ id: id, values });
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [onUpload]
    );

    return {
        handleCreate,
        uploadData,
        errorMessage,
        ...options,
    };
};
