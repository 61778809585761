import { Avatar, Col, Row } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as blueIcon } from "assets/images/article-blue.svg";
import { ReactComponent as orangeIcon } from "assets/images/article-orange.svg";
import avatarImage from "assets/images/analytic-avatar.png";
import line from "assets/images/article-line.png";
import dayjs from "dayjs";
import {ReactComponent as editIcon} from "../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../assets/images/trash-bucket.svg";
import React from "react";

interface ArticleProps {
    title: string;
    date: string;
    text: string;
    avatarImage: string;
    name: string;
    occupation: string;
    color: "blue" | "orange";
}

export default function Article({ color , data, onDeleteNote, setUpdateStateMode, updateStateMode }: any) {

    return (
        <Row>
            <Col span={24} >
                <Row justify={"space-between"} style={{ padding: "0 12.5px" }}>
                    <Col>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img className={"article-line"} src={line}></img>
                            <Icon
                                component={
                                    color === "blue" ? blueIcon : orangeIcon
                                }
                                style={{ fontSize: "20px" }}
                            />
                            <span className={"article-title"}>
                                {data?.noteTitle}
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <span className={"article-date"}>
                            {dayjs(data?.updatedAt).format("HH:mm YYYY-MM-DD")}
                        </span>
                        <Icon
                            onClick={() => setUpdateStateMode({
                                isUpdate: false,
                                id: data?.id,
                                text: data?.noteText,
                                title: data?.noteTitle
                            })}
                            component={editIcon}
                            style={{cursor: "pointer", marginLeft: 20,}}
                        />
                        <Icon
                            onClick={() => onDeleteNote(data?.id)}
                            component={trashBucket}
                            style={{cursor: "pointer", marginLeft: 10,}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ paddingLeft: "30px" }}>
                        <p className={"article-text"}>
                            {data?.noteText}{" "}
                        </p>
                    </Col>
                    <Col span={24} style={{ paddingLeft: "30px" }}>
                        <Row gutter={14} align={"middle"}>
                            <Col>
                                <Avatar src={avatarImage} size={32} />
                            </Col>
                            <Col>
                                <div className={"article-profile-name"}>
                                    {`${data?.noteCreator?.firstName} ${data?.noteCreator?.lastName}`}
                                </div>
                                <div className={"article-profile-occupation"}>
                                    {data?.noteCreator?.email}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
