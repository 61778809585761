import { api } from "index";

// Requests
export async function getSellerList(
    currentPage?: string,
    search?: string,
) {
    let params = new URLSearchParams();

    if (search) {
        params.append("search", search);
    }

    const response = await api.get<any>(`/sellers/list`, {params});
    return response.data.data;
}


// Requests
export async function getSeller(
    currentPage?: string,
    currentSize?: string,
    search?: string,
) {
    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", String(currentPage));
    }
    if (currentSize) {
        params.append("limit", String(currentSize));
    }
    if (search) {
        params.append("search", search);
    }

    const response = await api.get<ApiResponseList<Seller>>(`/sellers`, {params});
    return response.data.data;
}


export async function createSeller(json: CreateSellerBody) {
    const response = await api.post<ApiResponse<Seller>>(`/sellers`, json);
    return response.data.data;
}

export async function updateSeller(params: UpdateSellerParams) {
    const { id, ...body } = params;
    const response = await api.patch<ApiResponse<Seller>>(
        `/sellers/${params.id}`,
        body
    );
    return response.data.data;
}

export async function deleteSeller(id: string) {
    const response = await api.delete(`/sellers/${id}`);
    return response.data;
}
