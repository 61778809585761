import React, {useEffect, useRef, useState} from "react";
import {
    Button, Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Input,
    Menu,
    MenuProps,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined, DownloadOutlined} from "@ant-design/icons";
import useFinanceReportData from "./hooks/finanseReports/useFinanceReportData";
import {ReactComponent as rangePickerArrow} from "../../assets/images/rangepicker-arrow.svg";
import dayjs from "dayjs";
import {downloadFinRecordFile} from "../../api/documentMirror";

const {RangePicker} = DatePicker;
const {Option} = Select;

export default function DocumentMirrorStatementTable() {

    const [showMode, setShowMode] = useState<string>("byDay");

    const {
        financeReports,
        currentPage,
        setCurrentPage,
        currentPageSize,
        onChangeData,
        selectedSeller,
        setSelectedSeller,
        sellers,
        search,
        setSearch,
        sellerName,
        setSellerName,
        isLoading
    } = useFinanceReportData()

    const all = [
        {
            title: "№ отчета",
            dataIndex: "reportId",
            key: "reportId",
            width: 150,
        },
        {
            title: "Дата начала",
            dataIndex: "dateFrom",
            key: "dateFrom",
            width: 150,
            render: (text: any, record: any) => {
                return (
                    <div>
                        {dayjs(text).format("DD.MM.YYYY")}
                    </div>
                )
            }
        },
        {
            title: "Дата конца",
            dataIndex: "dateTo",
            key: "dateTo",
            width: 150,
            render: (text: any, record: any) => {
                return (
                    <div>
                        {dayjs(text).format("DD.MM.YYYY")}
                    </div>
                )
            }
        },
        {
            title: "День форм.",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (text: any, record: any) => {
                return (
                    <div>
                        {dayjs(text).format("DD.MM.YYYY")}
                    </div>
                )
            }
        },
        // {
        //     title: "Фото",
        //     dataIndex: "5",
        //     key: "5",
        //     width: 150,
        // },
        {
            title: "Продажа руб",
            dataIndex: "totalSale",
            key: "totalSale",
            width: 150,
        },
        {
            title: "Скидка %",
            dataIndex: "avgSalePercent",
            key: "avgSalePercent",
            width: 150,
        },
        {
            title: "К перес., руб",
            dataIndex: "forPay",
            key: "forPay",
            width: 150,
        },
        // {
        //     title: "Ар-кул кит",
        //     dataIndex: "deliveryRub",
        //     key: "deliveryRub",
        //     width: 150,
        // },
        {
            title: "Логистика, руб",
            dataIndex: "deliveryRub",
            key: "deliveryRub",
            width: 150,
        },
        {
            title: "Повыш. логист,руб",
            dataIndex: "penaltyLogistics",
            key: "penaltyLogistics",
            width: 150,
        },
        {
            title: "Другие вид. штрафов,руб",
            dataIndex: "penaltyWithoutLogistics",
            key: "penaltyWithoutLogistics",
            width: 150,
        },
        {
            title: "Общая сум. штрафов,руб",
            dataIndex: "penalty",
            key: "penalty",
            width: 150,
        },
        {
            title: "Доплаты, руб",
            dataIndex: "additionalPayment",
            key: "additionalPayment",
            width: 150,
        },
        {
            title: "Стоимость хранения, руб",
            dataIndex: "paidStorageSum",
            key: "paidStorageSum",
            width: 150,
        },
        {
            title: "Стоимость платн приемки, руб",
            dataIndex: "paidAcceptanceSum",
            key: "paidAcceptanceSum",
            width: '150px',
        },
        {
            title: "Прочие удержания, руб",
            dataIndex: "paidWithholdingSum",
            key: "paidWithholdingSum",
            width: '150px',
        },
        {
            title: "Итого к оплате, руб",
            dataIndex: "bankPaymentSum",
            key: "bankPaymentSum",
            width: '150px',
        },
        {
            title: "Валюта",
            dataIndex: "currency",
            key: "currency",
            width: '150px',
        },
        {
            title: "Юр. лицо",
            dataIndex: "-",
            key: "-",
            width: '150px',
            render: () =>{
                return (
                    <div>
                        {sellerName || '---'}
                    </div>
                )
            }
        },
        {
            title: "Статус отчета",
            dataIndex: "bankPaymentStatusName",
            key: "bankPaymentStatusName",
            width: '150px',
        },
        // {
        //     title: "Статус оплаты",
        //     dataIndex: "23",
        //     key: "23",
        //     width: '150px',
        // },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        minHeight: 60,
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                    // onClick={() => {
                    //     setEditProductOpened(true);
                    //     setProductToEdit(record);
                    // }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getTopologyActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>

                </div>
            ),
        },
    ];

    const topologyItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180, cursor: 'pointer'}}>
                         {React.createElement(DownloadOutlined)}
                    Скачать
                </span>
            ),
            key: "DOWNLOAD",
        },
    ];

    const getTopologyActions = (record: any) => {
        return {
            items: topologyItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "DOWNLOAD":
                        downloadFinRecordFile(`${process.env.REACT_APP_API_URL}frs/file/${record?.id}`, record?.id)
                        break;
                    case "DELETE":
                        break;

                }
            },
        };
    };

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={search}
                        onChange={(text: any) => setSearch(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск по номеру отчета"
                        size="large"
                    />
                </Col>
                <Col style={{width: "250px"}}>
                    <Radio.Group
                        defaultValue={showMode}
                        style={{width: "100%"}}
                        onChange={(e) => setShowMode(e.target.value)}
                    >
                        <Radio.Button
                            value={"byDay"}
                            style={{
                                width: "50%",
                                paddingTop: "4px",
                                textAlign: "center",
                                paddingRight: "1.5px",
                            }}
                        >
                            Еженедельные
                        </Radio.Button>
                        <Radio.Button
                            value={"all"}
                            style={{
                                width: "50%",
                                paddingTop: "4px",
                                textAlign: "center",
                                paddingLeft: "1.5px",
                            }}
                        >
                            Итоговые
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col style={{width: "300px", paddingTop: 0, paddingBottom: 0}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        style={{
                            width: '100%',
                        }}
                        defaultValue={sellers?.data?.[0]?.extRef}
                        value={selectedSeller}
                        placeholder="Продавец"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                            setSellerName(option?.key);
                        }}
                    >
                        {sellers?.data?.map((option: any) => {
                            return (
                                <Option key={option?.companyName} value={option?.extRef}>
                                    {option.companyName}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
                <Col>
                    <RangePicker
                        onChange={onChangeData}
                        placeholder={["Начало", "Конец"]}
                        separator={
                            <Icon component={rangePickerArrow}/>
                        }
                        format={"DD.MM.YYYY"}
                        style={{
                            backgroundColor: '#fff',
                            height: 38,
                            borderRadius: 0,
                            borderColor: '#fff'
                        }}
                    />
                </Col>
                {/*<Col >*/}
                {/*    <Button*/}
                {/*        onClick={() => downloadEmissionFile(*/}
                {/*            `${process.env.REACT_APP_API_URL}frs/file/${''}`*/}
                {/*        )}*/}
                {/*        className="table-header-button"*/}
                {/*        type={"primary"}*/}
                {/*        style={{*/}
                {/*            display: "flex",*/}
                {/*            justifyContent: "center",*/}
                {/*            alignItems: "center",*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Скачать Excel*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>

            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        scroll={{x: 2500}}
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={all}
                        dataSource={financeReports?.data || []}
                        // scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(financeReports?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
