import { Col, Row } from 'antd'
import React from 'react'
import ActionsCalendar from 'features/actions/ActionsCalendar'

export default function ActionsCalendarPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<ActionsCalendar />
			</Col>
		</Row>
	)
}
