import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useState } from 'react'
import { backend } from 'api'

export const useAddFile = () => {
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<string>('')

	const { mutateAsync: handleAdd, ...options } = useMutation(
		backend.product.addFileToProductsResult,
		{
			async onSuccess(data: any) {
				console.log(data)
				if (data.isSuccess) {
					await Promise.all([queryClient.invalidateQueries([`PRODUCTS_LIST`])])
					message.success('Файл успешно отправлен')
				} else {
					message.warning(
						`Файл отправлен, но произошла ошибка со стороны сервера ${data.message}`
					)
				}
			},
		}
	)

	const handleAddFile = useCallback(
		async (values: any) => {
			try {
				await handleAdd(values)
			} catch (e: any) {
				const errorData = (await e.response.data) || e?.response?.data?.detail
				message.error(`Файл не отправлен по причине ${e.message}`)
				if (errorData) {
					setErrorMessage(errorData)
				}
			}
		},
		[handleAdd]
	)

	return {
		handleAddFile,
		errorMessage,
		...options,
	}
}
