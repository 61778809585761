import { Button, Col, Form, Input, message, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import useActionsData from '../hooks/actions/useActionsData'

export const EditModal = ({
	isVisible,
	handleCancel,
	setIsVisible,
	selectedSupplier,
}: any) => {
	const { editSupplier } = useActionsData()
	const [form] = Form.useForm()

	useEffect(() => {
		if (selectedSupplier) {
			form.setFieldsValue({
				fullName: selectedSupplier.fullName,
				shortName: selectedSupplier.shortName,
				inn: selectedSupplier.inn,
				kpp: selectedSupplier.kpp,
				bik: selectedSupplier.bik,
				operatingAccount: selectedSupplier.operatingAccount,
				legalAddress: selectedSupplier.legalAddress,
				general: selectedSupplier.general,
				bankName: selectedSupplier.bankName,
				region: selectedSupplier.region,
				city: selectedSupplier.city,
				street: selectedSupplier.street,
				legalForm: selectedSupplier.legalForm,
				ogrnip: selectedSupplier.ogrnip,
				shopName: selectedSupplier.shopName,
				unp: selectedSupplier.unp,
				unn: selectedSupplier.unn,
				bin: selectedSupplier.bin,
				tin: selectedSupplier.tin,
				transferBankINN: selectedSupplier.transferBankINN,
				transferBIC: selectedSupplier.transferBIC,
				transferCorrespondentAccount:
					selectedSupplier.transferCorrespondentAccount,
				taxpayerCode: selectedSupplier.taxpayerCode,
				swiftCode: selectedSupplier.swiftCode,
				postalCode: selectedSupplier.postalCode,
			})
		}
	}, [selectedSupplier, form])

	const handleSave = async () => {
		try {
			const values = await form.validateFields()

			await editSupplier(values)

			message.success('Данные компании успешно изменены')
			setIsVisible(false)
		} catch (error) {
			message.error('Не удалось изменить данные компании')
		}
	}

	return (
		<Modal
			visible={isVisible}
			onCancel={handleCancel}
			bodyStyle={{ padding: 20 }}
			width={800}
			footer={[
				<div style={{ padding: 20 }}>
					<Button key='back' onClick={handleCancel}>
						Отмена
					</Button>
					<Button key='submit' type='primary' onClick={handleSave}>
						Сохранить
					</Button>
				</div>,
			]}
		>
			<Form form={form} layout='vertical'>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label='Полное наименование' name='fullName'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Краткое наименование' name='shortName'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='ИНН' name='inn'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='КПП' name='kpp'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='БИК' name='bik'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Расчетный счет' name='operatingAccount'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Юридический адрес' name='legalAddress'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Регион' name='region'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Город' name='city'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Улица' name='street'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Генеральный директор' name='general'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Название банка' name='bankName'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Форма собственности' name='legalForm'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='ОГРНИП' name='ogrnip'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Название магазина' name='shopName'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='УНП' name='unp'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='УНН' name='unn'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='БИН' name='bin'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='ТИН' name='tin'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='ИНН банка' name='transferBankINN'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='БИК банка' name='transferBIC'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label='Корреспондентский счет'
							name='transferCorrespondentAccount'
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Код налогоплательщика' name='taxpayerCode'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='SWIFT-код' name='swiftCode'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='Почтовый индекс' name='postalCode'>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}
