import { Button, Checkbox, Form, message, Modal, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import useSettingsData from '../hooks/settings/useSettingsData'

export const EditModal = ({
	isVisible,
	handleCancel,
	setIsVisible,
	selectedUser,
}: any) => {
	const { settingsData, setDataType, editUser } = useSettingsData()
	const [localAccess, setLocalAccess] = useState<any>({})
	const [role, setRole] = useState<string>(selectedUser?.position)
	const [roles, setRoles] = useState([])

	useEffect(() => {
		setDataType('wb-user-roles')
		if (settingsData) {
			setRoles(settingsData)
		}
		setRole(selectedUser?.position)
	}, [settingsData])

	const accessNames: { [key in keyof typeof localAccess]?: string } = {
		finance: 'Финансовая аналитика',
		discountPrice: 'Цены и скидки',
		feedbacksQuestions: 'Отзывы и вопросы',
		supply: 'Поставки',
		balance: 'Баланс',
		wbPoint: 'Точки WB',
	}

	useEffect(() => {
		if (selectedUser?.access) {
			const updatedAccess = {
				...selectedUser.access,
				wbPoint: { disabled: true },
			}
			setLocalAccess(updatedAccess)
		}
	}, [selectedUser])

	const handleCheckboxChange = (accessKey: string) => {
		setLocalAccess((prevAccess: any) => ({
			...prevAccess,
			[accessKey]: {
				...prevAccess[accessKey],
				disabled: !prevAccess[accessKey].disabled,
			},
		}))
	}

	const handleSave = async () => {
		try {
			await editUser({
				user_id: selectedUser.id,
				balance_disabled: localAccess.balance?.disabled ?? true,
				discount_price_disabled: localAccess.discountPrice?.disabled ?? true,
				feedbacks_questions_disabled:
					localAccess.feedbacksQuestions?.disabled ?? true,
				finance_disabled: localAccess.finance?.disabled ?? true,
				supply_disabled: localAccess.supply?.disabled ?? true,
				wb_point_disabled: localAccess.wbPoint?.disabled ?? true,
				worker_new_position: role ? role : selectedUser.position,
			})
			setIsVisible(false)
			setRole(selectedUser.position)
			message.success('Пользователь успешно изменен')
		} catch (error) {
			message.error('Не удалось изменить пользователя')
		}
	}

	return (
		<Modal
			visible={isVisible}
			onCancel={handleCancel}
			bodyStyle={{ padding: 20 }}
			footer={[
				<div style={{ padding: 20 }}>
					<Button key='back' onClick={handleCancel}>
						Отмена
					</Button>
					<Button key='submit' type='primary' onClick={handleSave}>
						Сохранить
					</Button>
				</div>,
			]}
		>
			<div>
				<p>Форма редактирования прав доступа для {selectedUser?.firstName}</p>
				<Form.Item label='Роль'>
					<Select
						placeholder='Выберите роль'
						value={role}
						onChange={value => setRole(value)}
						allowClear
					>
						{roles ? (
							roles.map((r: any) => (
								<Select.Option key={r.id} value={r.role}>
									{r.role}
								</Select.Option>
							))
						) : (
							<>Loading...</>
						)}
					</Select>
				</Form.Item>
				{Object.keys(localAccess).length === 0 ? (
					<div style={{ textAlign: 'center', padding: '20px 0' }}>
						<Spin size='large' />
					</div>
				) : (
					Object.keys(localAccess)
						.filter(accessKey => accessKey !== 'wbPoint')
						.sort((a, b) =>
							(accessNames[a] ?? a).localeCompare(accessNames[b] ?? b)
						)
						.map(accessKey =>
							localAccess[accessKey] ? (
								<div key={accessKey}>
									<Checkbox
										checked={!localAccess[accessKey].disabled}
										onChange={() => handleCheckboxChange(accessKey)}
									>
										{accessNames[accessKey] ?? accessKey}
									</Checkbox>
								</div>
							) : null
						)
				)}
			</div>
		</Modal>
	)
}
