import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import useActionsData from '../hooks/actions/useActionsData'
const { Option } = Select

interface ISellerPicker {
	selectedSeller: any
	setSelectedSeller: any
}

export const PickSeller: FC<ISellerPicker> = ({
	selectedSeller,
	setSelectedSeller,
}) => {
	const { sellers } = useActionsData()
	const [filteredSellers, setFilteredSellers] = useState(sellers)

	useEffect(() => {
		const sortedSellers = [...(sellers || [])].sort((a, b) =>
			a.company_name.localeCompare(b.company_name)
		)
		setFilteredSellers(sortedSellers)
	}, [sellers])

	const handleChange = (value: string, option: any) => {
		setSelectedSeller(option.key, value || '')
		const sortedSellers = [...(sellers || [])].sort((a, b) =>
			a.company_name.localeCompare(b.company_name)
		)
		setFilteredSellers(sortedSellers)
	}

	const handleSearch = (value: string) => {
		const filtered = sellers
			?.filter((seller: any) =>
				seller.company_name.toLowerCase().includes(value.toLowerCase())
			)
			.sort((a: any, b: any) => a.company_name.localeCompare(b.company_name))
		setFilteredSellers(filtered)
	}

	return (
		<Select
			className='table-header-select'
			style={{ width: '100%' }}
			value={selectedSeller}
			placeholder='Продавец'
			showSearch
			onSearch={handleSearch}
			onChange={(value, option: any) => handleChange(value, option)}
			filterOption={false}
		>
			{filteredSellers?.map((option: any) => (
				<Option key={option.id} value={option.company_name}>
					{option.company_name}
				</Option>
			))}
		</Select>
	)
}
