import {useQuery} from "react-query";
import {backend} from "../../apiExtraInfo";
import {useState} from "react";

export default function useEmmissionsDataFilterSize() {

    const [idEmission, setIdEmission] = useState<any>('')
    const [idDeclaration, setIdDeclaration] = useState<any>('')
    const [fieldParam, setFieldParam] = useState<any>('size.ru_size')

    const {data: sizes, ...options} = useQuery(
        ["EMISSIONS_DATA_FILTER_SIZE_BOX_CODE", idEmission, fieldParam, idDeclaration],
        () => backend.emissions.getEmissionsDataFilterSize(
            idEmission,
            fieldParam.toString(),
            idDeclaration
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        sizes,
        setId: setIdEmission,
        setIdDeclaration,
        ...options,
    };
}
