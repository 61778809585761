import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const useChangeStatusShiments = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync,
        ...options
    } = useMutation(backend.storage.postChangeStatusShiments, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`SHIMPENTS_FORMATION_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, id: any) => {
            try {
                let body = { values, id };
                await mutateAsync(body);
                queryClient.invalidateQueries(['SHIMPENTS_FORMATION_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message || e?.response?.data?.detail);
            }
        },
        [mutateAsync]
    );

    return {
        handleCreate,
        errorMessage,
        ...options
    };
};
