import { useCallback, useState } from "react";
import { Row, Col } from "antd/es/grid";
import { Form, Input, Checkbox, Button } from "antd";
import { useLogin } from "./hooks/useLogin";

export default function LoginForm() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { login, isCredentialsError, isOtherError } = useLogin();

    const onLogin = useCallback(
        (values: LoginParams) => {
            setIsLoading(true);
            login(values).finally(() => setIsLoading(false));
        },
        [login]
    );

    return (
        <Row>
            <Col span={24}>
                <div className="login-title">Войти</div>
                <div
                    style={{
                        color: "#82868B",
                        marginTop: "10px",
                        lineHeight: "21px",
                    }}
                >
                    Пожалуйста, введите свои данные для подключения.
                </div>
                <Form
                    layout={"vertical"}
                    style={{ marginTop: "32px" }}
                    onFinish={onLogin}
                >
                    <Form.Item
                        label={
                            <label className="form-label">
                                Имя пользователя
                            </label>
                        }
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите имя пользователя",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<label className="form-label">Пароль</label>}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите пароль",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox>Запомнить меня</Checkbox>
                    </Form.Item>
                    <Button
                        type={"primary"}
                        htmlType={"submit"}
                        style={{ width: "100%" }}
                        loading={isLoading}
                    >
                        Войти
                    </Button>
                    {isCredentialsError && (
                        <div
                            className={"ant-form-item-explain-error"}
                            style={{ color: "#ff4d4f" }}
                        >
                            Неверный логин или пароль
                        </div>
                    )}
                    {isOtherError && (
                        <div
                            className={"ant-form-item-explain-error"}
                            style={{ color: "#ff4d4f" }}
                        >
                            При входе произошла ошибка
                        </div>
                    )}
                </Form>
            </Col>
        </Row>
    );
}
