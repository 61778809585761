import React, { useState } from "react";
import { Col, Row } from "antd";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export default function StoragePage() {

    const location = useLocation();
    const navigate = useNavigate()

    return (
        <Row>
            <Col style={{ padding: "10px 20px" }} span={24}>
                <Row>
                    {
                        !location.pathname.includes('full-info') &&
                        <div className={"storage-tabs"}>
                            <div
                                className={`${
                                    location.pathname === '/storage' ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage')}
                            >
                                Приход товара
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/shipments-formation') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/shipments-formation')}
                            >
                                Формирование отгрузок на маркетплейс
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/additional-deliveries') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/additional-deliveries')}
                            >
                                Допоставки на склад
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/addSk') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/addSk')}
                            >
                                Добавить ШК
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/list-of-saved-invoices') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/list-of-saved-invoices')}
                            >
                                Список сохраненных накладных
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/gtd') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/gtd')}
                            >
                                ГТД
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/topology') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/topology')}
                            >
                                Топология
                            </div>
                        </div>
                    }
                </Row>
                <Row style={{marginTop: 20, marginBottom: 10}}>
                    {
                        !location.pathname.includes('full-info') &&
                        <div className={"storage-tabs"}>
                            <div
                                className={`${
                                    location.pathname === '/storage/test' ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/test')}
                            >
                                Test
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/emissions') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/emissions')}
                            >
                                Эмиссии
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/declaration') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/declaration')}
                            >
                                Декларации
                            </div>
                            <div
                                className={`${
                                    location.pathname === '/storage/shipment' ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/shipment')}
                            >
                                Отгрузка на WB
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/list-of-shipment-files') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/list-of-shipment-files')}
                            >
                                Список файлов отгрузки на WB
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/acceptance') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/acceptance')}
                            >
                                Приемка
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/list-of-acceptance-files') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/list-of-acceptance-files')}
                            >
                                Список файлов приемки
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/tsd') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/tsd')}
                            >
                                ТСД
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/storekeepers') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/storekeepers')}
                            >
                                Кладовщики
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/marking') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/marking')}
                            >
                                Маркировка
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/tasks') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/tasks')}
                            >
                                Задачи
                            </div>
                            <div
                                className={`${
                                    location.pathname.includes('/storage/map') ? "tab-active" : ""
                                }`}
                                onClick={() => navigate('/storage/map')}
                            >
                                Карта склада
                            </div>
                        </div>
                    }
                </Row>
                <Row>
                    <Col span={24}>
                        <Outlet/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
