import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "api";
import {useCallback, useEffect, useState} from "react";

export default function useKizDataByGTD() {

    const [id, setId] = useState<any>('');

    useEffect(() =>{
        setId(id)
    }, [id])

    const { data: kizData, ...options } = useQuery(
        [
            "DOCUMENTS_KIZ_DATA_BY_ID",
            id,
        ],
        () =>
            backend.storage.getKizDataById(
                id?.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        kizData,
        id,
        setId,
        ...options,
    };
}