import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const useAddfile = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: handleAdd,
        ...options
    } = useMutation(backend.financialResult.addFileToFinResult, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`CHART_FIN_RES_DATA`])]);
        },
    });

    const handleAddFile = useCallback(
        async (values: any) => {
            try {
                await handleAdd(values);

            } catch (e: any) {
                const errorData = await e.response.data || e?.response?.data?.detail;

                if (errorData) {
                    setErrorMessage(errorData);
                }

            }
        },
        [handleAdd]
    );

    return {
        handleAddFile,
        errorMessage,
        ...options
    };
};
