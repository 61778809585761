import { useQuery } from "react-query";
import { backend } from "../../api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSizeByArticle() {

    const [articleChine, setArticleChine] = useState<any>('');
    const [debouncedArticle] = useDebounce(articleChine, 1000);

    const { data: sizes, ...options } = useQuery(
        [
            "SIZE_BY_ARTICLE",
            debouncedArticle
        ],
        () => backend.declaration.getSizeByArticle(
            debouncedArticle.toString()
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        sizes,
        articleChine,
        setArticleChine,
        ...options,
    };
}
