import {useQuery} from "react-query";
import {backend} from "api";
import {useState} from "react";

export default function useChatByType(type: any) {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(100)

    const {data: chats, ...options} = useQuery(
        [
            "CHAT_BY_TYPE_DATA",
            type,
            page,
            limit
        ],
        () =>
            backend.chat.getChatByChatType(
                type,
                page,
                limit
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        chats,
        page,
        limit,
        setPage,
        setLimit,
        ...options,
    };
}
