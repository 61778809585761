import { Button, Modal, Input, Form, message, Checkbox, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import Icon, { LoadingOutlined } from '@ant-design/icons'
import useSettingsData from '../hooks/settings/useSettingsData'
import { GeneratedTokenModal } from './GeneratedToken'

export const AddTokenModal = ({
	isVisible,
	handleCancel,
	setIsVisible,
}: any) => {
	const {
		selectedSellerId,
		setDataType: setDataScopesType,
		settingsData: scopesData,
	} = useSettingsData()
	const { generateToken, token } = useSettingsData()
	const { setDataType: setDataNamesType, settingsData: scopesNamesData } =
		useSettingsData()

	const [tokenName, setTokenName] = useState('')
	const [selectedScopes, setSelectedScopes] = useState<string[]>([])
	const [generatedToken, setGeneratedToken] = useState<string | null>(null)
	const [isTokenModalVisible, setIsTokenModalVisible] = useState(false)
	const [isGeneratingToken, setIsGeneratingToken] = useState(false)
	const isTokenNameValid = tokenName.trim() !== ''

	useEffect(() => {
		setDataScopesType('token-scopes')
		setDataNamesType('token-scopes-name')
	}, [])

	useEffect(() => {
		if (token) {
			setGeneratedToken(token)
			setIsGeneratingToken(false)
		}
	}, [token])

	const handleCheckboxChange = (id: string) => {
		setSelectedScopes((prev: any) =>
			prev.includes(id)
				? prev.filter((scope: any) => scope !== id)
				: [...prev, id]
		)
	}

	const scopesList =
		scopesData &&
		scopesNamesData &&
		scopesData?.scopes.map((scope: any) => ({
			id: scope.id,
			name: scopesNamesData[scope.id]?.name || scope.id,
			description: scopesNamesData[scope.id]?.description || '',
		}))

	const handleSave = async () => {
		setGeneratedToken('')
		setIsGeneratingToken(true)

		if (isTokenNameValid && selectedScopes.length > 0) {
			try {
				await generateToken({
					tokenName,
					param: selectedScopes,
				})

				setIsTokenModalVisible(true)
				setIsVisible(false)
				setTokenName('')
				setSelectedScopes([])
			} catch (error) {
				message.error('Не удалось добавить токен')
				setIsGeneratingToken(false)
			}
		} else {
			message.warning('Выберите хотя бы одно разрешение.')
			setIsGeneratingToken(false)
		}
	}

	const handleTokenModalClose = () => {
		setIsTokenModalVisible(false)
		setGeneratedToken(null)
	}

	return (
		<>
			<Modal
				visible={isVisible}
				onCancel={handleCancel}
				bodyStyle={{ padding: 20 }}
				footer={[
					<div style={{ padding: 20 }} key='footer'>
						<Button key='back' onClick={handleCancel}>
							Отмена
						</Button>
						<Button
							key='submit'
							type='primary'
							onClick={handleSave}
							disabled={!isTokenNameValid || selectedScopes.length === 0}
						>
							Сохранить
						</Button>
					</div>,
				]}
			>
				<Form layout='vertical'>
					<Form.Item
						label='Название токена'
						required
						help={!isTokenNameValid && 'Введите название токена'}
						validateStatus={!isTokenNameValid ? 'error' : undefined}
					>
						<Input
							placeholder='Введите название токена'
							value={tokenName}
							onChange={e => setTokenName(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Разрешения' style={{ paddingLeft: 0 }}>
						{scopesList ? (
							<Checkbox.Group
								value={selectedScopes}
								style={{ display: 'flex', flexDirection: 'column' }}
							>
								{scopesList.map((scope: any) => (
									<Checkbox
										style={{ margin: 0 }}
										key={scope.id}
										value={scope.id}
										onChange={() => handleCheckboxChange(scope.id)}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '0.2rem',
												textAlign: 'left',
											}}
										>
											<span>{scope.name}</span>
										</div>
									</Checkbox>
								))}
							</Checkbox.Group>
						) : (
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						)}
					</Form.Item>
				</Form>
			</Modal>

			<GeneratedTokenModal
				isVisible={isTokenModalVisible}
				handleClose={() => {
					handleCancel()
					handleTokenModalClose()
				}}
				generatedToken={generatedToken}
			/>
		</>
	)
}
