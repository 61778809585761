import React, {useState} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer
} from 'recharts';
import dayjs from "dayjs";

interface ChartProps {
    data: any;
    lines: any;
    xCoords: any;
}

const KeyWordsChartV2: React.FC<ChartProps> = ({data, xCoords, lines}) => {

    const colors = ['#28C76F', '#FDB813', '#FF4961', '#166FFF', '#FF9F1C', '#9C36B5', '#3B4CCA', '#FF5722', '#F44336', '#9C27B0', '#4CAF50', '#2196F3', '#FFC107', '#E91E63', '#00BCD4'];


    const mapData = data.map((item: any) => ({
        ...item,
        stats: item?.stats.map((item1: any) => ({
            ...item1,
            x: dayjs(item1?.x).format('DD.MM.YYYY HH:mm'),
            y: item1?.y === 6000 ? null : item1?.y
        }))
    }))

    // const mapData = data.map((item: any) => ({
    //     ...item,
    //     stats: item?.stats.map((item1: any) => ({
    //         ...item1,
    //         x: dayjs(item1?.x).format('DD.MM.YYYY HH:mm')
    //     }))
    // }))

    const mapXCoords = xCoords?.map((item: any) => dayjs(item).format('DD.MM.YYYY HH:mm'))
    const mapLines = lines?.map((item: any) => ({
        startDate: dayjs(item?.startDate).format('DD.MM.YYYY HH:mm'),
        endDate: dayjs(item?.endDate).format('DD.MM.YYYY HH:mm')
    }))

    const resultArray = mapXCoords.reduce((accumulator: any, date: any) => {
        const dateObject: any = {x: date};

        mapData.forEach((dataObject:any) => {
            dataObject.stats.forEach((stat:any) => {
                if (stat.x === date) {
                    dateObject[dataObject.title] = stat.y;
                }
            });
        });

        accumulator.push(dateObject);
        return accumulator;
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <LineChart width={800} height={450} data={resultArray}>
                    <CartesianGrid strokeDasharray="2 2"/>
                    <XAxis dataKey="x" type="category" allowDuplicatedCategory={false}/>
                    <YAxis />
                    <Tooltip/>
                    <Legend/>

                    {data.map((s: any, index: any) => (
                        <Line
                            key={s.id}
                            dataKey={s.title}
                            stroke={colors[index % colors.length]}
                            connectNulls={true}
                        />
                    ))}
                    {
                        mapLines?.length !== 0 && mapLines &&
                        mapLines?.map((item: any, index: any) =>
                            <>
                                <ReferenceLine x={item?.startDate} stroke="#57f804"/>
                                <ReferenceLine x={item?.endDate} stroke="#FF4961"/>
                            </>
                        )
                    }
                </LineChart>
            </div>
            {/*<LineChart width={900} height={400}>*/}
            {/*    <CartesianGrid strokeDasharray="3 3"/>*/}
            {/*    <XAxis*/}
            {/*        type="category"*/}
            {/*        dataKey="x"*/}
            {/*        allowDuplicatedCategory={false}*/}
            {/*    />*/}
            {/*    <YAxis dataKey="y"/>*/}
            {/*    <Tooltip/>*/}
            {/*    <Legend/>*/}
            {/*    <Line data={mapXCoords}/>*/}
            {/*    {mapData.map((series: any, index: any) => (*/}
            {/*        <Line*/}
            {/*            connectNulls={true}*/}
            {/*            key={series.id}*/}
            {/*            type="monotone"*/}
            {/*            dataKey="y"*/}
            {/*            name={series.title}*/}
            {/*            data={series.stats}*/}
            {/*            stroke={colors[index % colors.length]}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*    {*/}
            {/*        mapLines?.length !== 0 && mapLines &&*/}
            {/*        mapLines?.map((item: any, index: any) =>*/}
            {/*            <>*/}
            {/*                <ReferenceLine x={item?.startDate} stroke="#57f804"/>*/}
            {/*                <ReferenceLine x={item?.endDate} stroke="#FF4961"/>*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</LineChart>*/}
        </div>

    );
};


export default KeyWordsChartV2;