import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useState } from 'react'
import { backend } from 'api'

export const useAddFile = () => {
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<string>('')

	const { mutateAsync: handleAdd, ...options } = useMutation(
		backend.analytics.addFileToSelfCostResult,
		{
			async onSuccess(data: any) {
				if (data.isSuccess) {
					await Promise.all([
						queryClient.invalidateQueries([`COST_PRICE_DATA`]),
					])
					message.success('Файл успешно отправлен')
				} else {
					message.warning(
						`Файл отправлен, но произошла ошибка со стороны сервера ${data.message}`
					)
				}
			},
		}
	)

	const handleAddFile = useCallback(
		async (values: any) => {
			try {
				await handleAdd(values)
			} catch (e: any) {
				const errorData = (await e.response.data) || e?.response?.data?.detail

				if (errorData) {
					setErrorMessage(errorData)
				}
			}
		},
		[handleAdd]
	)

	return {
		handleAddFile,
		errorMessage,
		...options,
	}
}
