import React, {useEffect, useState} from "react";
import {Checkbox, Input, Menu, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import {AnalyticTableColumnFilter} from "../../../analytics/hooks/useAnalyticsTableColumnFilters";
import dayjs from "dayjs";

export const useFilteredEmissionFullInfoColumns = () => {

    const columns = [
        {
            title: "Имя",
            dataIndex: "name",
            key: "name",
            width: "17%",
        },
        {
            title: "Артикул кит",
            dataIndex: "article_china",
            key: "article_china",
            width: "5%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "3%",
            render: (text: any, record: any) =>{
                return (
                    <div>
                        {record?.size?.ru_size}
                    </div>
                )
            }
        },
        {
            title: "Box_code",
            dataIndex: "box_code",
            key: "box_code",
            width: "2%",
        },
        {
            title: "Имя импортера",
            dataIndex: "importer_name",
            key: "importer_name",
            width: "10%",
        },
        {
            title: "Имя продукта",
            dataIndex: "production_name",
            key: "production_name",
            width: "10%",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
            width: "15%",
        },
        {
            title: "Киз_код_короткий",
            dataIndex: "kiz_code_short",
            key: "kiz_code_short",
            width: "15%",
        },
        {
            title: "Дата загрузки эмиссии",
            dataIndex: "custom_passed_at",
            key: "custom_passed_at",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Дата создания",
            dataIndex: "created_at",
            key: "created_at",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Дата обновления",
            dataIndex: "updated_at",
            key: "updated_at",
            width: "8%",
            render: (text: any, record: any) => {
                return (
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },

    ];

    // getColumnFilterItems()

    const [selectAll, setSelectAll] = useState(true);
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState(
        columns.map((column) => column.key) // Выбираем все столбцы по умолчанию
    );

    const handleColumnSelection = ({ key, checked }: any) => {
        if (checked) {
            // Если отмечен, добавляем столбец в выбранные
            setSelectedColumns((prevSelectedColumns) => [...prevSelectedColumns, key]);
        } else {
            // Если снят флажок, удаляем столбец из выбранных
            setSelectedColumns((prevSelectedColumns) =>
                prevSelectedColumns.filter((columnKey) => columnKey !== key)
            );
        }

        if ((selectedColumns.length === columns.map((column) => column.key).length) && selectedColumns.length === 0){
            setIndeterminate(false)
            setSelectAll(true)
        } else {
            setIndeterminate(true)
            setSelectAll(false)
        }
    };

    const handleSelectAll = (checked: any) => {
        if (selectedColumns.length === columns.map((column) => column.key).length) {
            setSelectedColumns([])
            setSelectAll(false)
            setIndeterminate(false)
        } else if(selectedColumns.length < columns.map((column) => column.key).length) {
            setSelectedColumns(columns.map((column) => column.key))
            setSelectAll(true)
            setIndeterminate(false)
        } else {
            setSelectAll(false)
            setIndeterminate(true)
        }
    };

    const getMenu = () => {
        return (
            <Menu>
                <Menu.Item key={'all'}>
                    <Checkbox
                        indeterminate={indeterminate}
                        checked={selectAll}
                        onChange={(event) =>
                            handleSelectAll(event.target.checked)
                        }
                    >
                        Все
                    </Checkbox>
                </Menu.Item>
                {columns.map((column) => (
                    <Menu.Item key={column.key}>
                        <Checkbox
                            checked={selectedColumns.includes(column.key)}
                            onChange={(event) =>
                                handleColumnSelection({ key: column.key, checked: event.target.checked })
                            }
                        >
                            {column.title}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const filteredColumns = columns.filter((column) => selectedColumns.includes(column.key));

    return {
        getMenu,
        filteredColumns,
    };
};