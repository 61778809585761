import dayjs from "dayjs";
import {message} from "antd";
import axios from "axios";
import {getToken} from "../../../utils/tokenStorage";

const generateFilename = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    return `analytics_report_${currentDate}.xlsx`;
};

export const downloadFile = (
    url: any,
    isWithQuery: any,
    size?: any,
    article?: any
) => {
    const link = document.createElement('a');

    const params = new URLSearchParams();

    let path = url

    if (article?.length !== 0 && article) {
        params.append("article", article?.join(','));
    }

    if (size?.length !== 0 && size) {
        params.append("size", size?.join(','));
    }
    console.log(params)

    if (size?.length !== 0 && size && article?.length !== 0 && article){
        link.href = path + '?' + params;
        console.log(path + '?' + params)
    } else {
        link.href = path;
        console.log(path)
    }
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadEmissionFile = (
    url?: any,
    isWithQuery?: any,
    sizes?: any,
    boxCodes?: any,
    name?: any,
    article?: any,
    dateCreateStart?: any,
    dateCreateEnd?: any,
    dateUpdateStart?: any,
    dateUpdateEnd?: any,
    dateCustomedStart?: any,
    dateCustomedEnd?: any,
    id?: any
) => {
    const token = getToken();

    message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

    const params = new URLSearchParams();

    if (sizes.length !== 0 && sizes) {
        params.append("ru_size", sizes);
    }

    if (boxCodes.length !== 0 && boxCodes) {
        params.append("box_code", boxCodes);
    }
    if (name) {
        params.append("name", name);
    }
    if (article) {
        params.append("article_china", article);
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        params: params,
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `declaration-${id}.xlsx`);
        link.setAttribute('target', '_blank');
        link.type = 'application/xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error downloading file:', error);
    });
};

export const downloadDeclarationFile = (
    url?: any,
    isWithQuery?: any,
    did?: string,
    order?: string,
    dateCreateStart?: string,
    dateCreateEnd?: string,
    dateUpdateStart?: string,
    dateUpdateEnd?: string,
    dateCustomedStart?: string,
    dateCustomedEnd?: string,
    selectedSizes?: string[],
    debouncedName?: string,
    selectedArticle?: string[],
    isPicket?: string,
) => {
    const token = getToken();

    console.log(selectedArticle)
    message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

    const params = new URLSearchParams();

    if (order) {
        params.append("order_by", order);
    }

    if (debouncedName) {
        params.append("name", debouncedName);
    }

    if (selectedArticle && selectedArticle?.length !== 0) {
        params.append("article_china", selectedArticle?.join(','));
    }

    if (isPicket) {
        params.append("picked", isPicket);
    }

    if (dateCreateStart && dateCreateEnd) {
        params.append("created_after", dayjs(dateCreateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("created_before", dayjs(dateCreateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateUpdateStart && dateUpdateEnd) {
        params.append("updated_after", dayjs(dateUpdateStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("updated_before", dayjs(dateUpdateEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (dateCustomedStart && dateCustomedEnd) {
        params.append("custom_passed_after", dayjs(dateCustomedStart).format('YYYY-MM-DD HH:mm:ss'));
        params.append("custom_passed_before", dayjs(dateCustomedEnd).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (selectedSizes?.length !== 0 && selectedSizes) {
        params.append("ru_size", selectedSizes?.join(','));
    }

    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        params: params,
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `declaration-${did}-free-products.xlsx`);
        link.setAttribute('target', '_blank');
        link.type = 'application/xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error downloading file:', error);
    });
};

export const downloadDeclarationFileByHandle = (
    url?: any,
    handle?: any
) => {
    const link = document.createElement('a');

    const params = new URLSearchParams();


    if (handle) {
        params.append("handle", handle);
    }

    let path = url

    console.log(path + '?' + params)

    link.href = path + '?' + params;
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
