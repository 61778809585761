import { api } from "index";

export async function getCoordinatorUserData(
    page?: string,
    pageSize?: string,
    debouncedSearchName?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (debouncedSearchName) {
        params.append("search", debouncedSearchName);
    }

    const response = await api.get<any>(`/coordinator-users`, {
        params,
    });

    return response.data.data;
}

export async function getCoordinatorUserDataById(
    id: string,
) {
    const response = await api.get(`/coordinator-users/${id}`);
    return response?.data?.data;
}


export async function deleteCoordinatorUserAnalytics(data : any) {
    const response = await api.delete(`/analytics-users/coordinator/${data?.coordinatorId}/analytic/${data?.analytictId}`)
    return response.data;
}

export async function createCoordinatorUserAnalytics(json: any) {
    const response = await api.post<any>(`/analytics-users`, json);
    return response.data;
}
