import {
  Button,
  Col,
  DatePicker, Dropdown,
  Form,
  Input, MenuProps, message, Modal, Pagination,
  Row,
  Select, Spin,
  Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect} from "react";
import {useUpdateNeeds} from "../hooks/useUpdateNeeds";

export default function ShipmentsModalContent({productId, count, onClose}: any) {

  const [form] = Form.useForm();

  const {
    handleUpdate,
    isLoading,
    isError,
    errorMessage,
    isSuccess
  } =useUpdateNeeds()

  const onFinish = (values: any) => {
    console.log(values, productId)
    handleUpdate(values, productId)
  };

  useEffect(() => {
    form.setFieldsValue({
      count: count
    })
  }, [count]);

  useEffect(() => {
    if(isSuccess){
      form.resetFields()
      onClose()
    }
  }, [isSuccess]);

  useEffect(() => {
    if(isError){
      message.error(errorMessage)
    }
  }, [isError]);


  return (
    <div className={"modal-wrapper"} style={{padding: "30px"}}>
      <ModalHeader title={"Запас дней"} onClose={() =>{
        form.resetFields()
        onClose()
      }}/>
      <>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <div>
                <span>Количество</span>
                <Tooltip
                  // title={getTooltipText()}
                  placement={"right"}
                >

                </Tooltip>
              </div>
            }
            name="count"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название",
              },
            ]}
          >
            <Input type={'number'}/>
          </Form.Item>
          <Button
            className={"button"}
            type={"primary"}
            style={{width: "100%"}}
            htmlType={"submit"}
            loading={isLoading}
          >
            Изменить
          </Button>
        </Form>
      </>

    </div>
  );
}
