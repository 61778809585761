import {useQuery} from "react-query";
import {backend} from "api";
import React, {useState} from "react";
import {useDebounce} from "use-debounce";
import {Checkbox, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useProductList from "../../product/hooks/useProductList";
import LockIcon from "../../../assets/icon/LockIcon";
import useLaboratoryProductList from "./useLaboratoryProductList";
import useLaboratoryAppointmentsList from "./useLaboratoryAppointmentsList";

export default function useLaboratoryResultData() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<string>("10");

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(searchText, 1000);


  const [seasonTypes, setSeasonTypes] = useState([
    {
      id: 4,
      title: 'spring',
      key: 'spring',
      ru: 'Весна',
    },
    {
      id: 2,
      title: 'summer',
      key: 'summer',
      ru: 'Лето'
    },
    {
      id: 3,
      title: 'autumn',
      key: 'autumn',
      ru: 'Осень'
    },
    {
      id: 1,
      title: 'winter',
      key: 'winter',
      ru: 'Зима'
    },
  ])
  const [selectedSeasonType, setSelectedSeasonType] = useState<any>({
    id: 4,
    title: 'spring',
    ru: 'Весна'
  });

  const {
    products,
    search: searchProduct,
    setSearch: setSearchProduct,
    setNotEmptyMode
  } = useLaboratoryProductList();
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
  const getProductsFilterItems = () => {
    if (!products) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    products?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                disabled={!item?.is_enabled}
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {
                  !item?.is_enabled &&
                    <span
                        style={{
                          display: 'inline-block',
                          transform: `translateY(6px)`
                        }}
                    ><LockIcon/></span>
                }
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedProducts?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      // {
      //   label: (
      //     <Input
      //       value={searchProduct}
      //       className={"analytic-dropdown-input"}
      //       onClick={(e) => e?.stopPropagation()}
      //       onChange={(e) => setSearchProduct(e?.target?.value)}
      //       prefix={<Icon component={searchIcon}/>}
      //       placeholder={"Поиск..."}
      //     />
      //   ),
      //   key: "product-search",
      // },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const {
    appointments,
  } = useLaboratoryAppointmentsList();

  const [selectedAppointment, setSelectedAppointment] = useState<Array<string>>([]);
  const getAppointmentFilterItems = () => {
    if (!appointments) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    appointments?.forEach((item: any) => {
      const isDuplicate = selectedAppointment.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                disabled={!item.is_enabled}
                style={{width: '100%'}}
                value={item.title}
                checked={selectedAppointment.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedAppointment];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedAppointment(newSelectedProducts);
                }}
              >
                {!item.is_enabled &&
                    <span
                        style={{
                          display: 'inline-block',
                          transform: `translateY(6px)`
                        }}
                    ><LockIcon/></span>
                }
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedAppointment?.forEach((item: any) => {
      const isDuplicate = selectedAppointment.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedAppointment.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedAppointment];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedAppointment(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      // {
      //   label: (
      //     <Input
      //       value={searchProduct}
      //       className={"analytic-dropdown-input"}
      //       onClick={(e) => e?.stopPropagation()}
      //       onChange={(e) => setSearchProduct(e?.target?.value)}
      //       prefix={<Icon component={searchIcon}/>}
      //       placeholder={"Поиск..."}
      //     />
      //   ),
      //   key: "product-search",
      // },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const { data, ...options } = useQuery(
    [
      "LABORATORY_RESULT_LIST",
      debouncedSearchText,
      currentPage,
      currentPageSize,
      selectedSeasonType
    ],
    () => backend.laboratory.getLaboratoryResultData(
      debouncedSearchText,
      currentPage.toString(),
      currentPageSize,
      selectedSeasonType
    ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    searchText,
    setSearchText,
    selectedProducts,
    getProductsFilterItems,
    getAppointmentFilterItems,
    seasonTypes,
    selectedSeasonType,
    setSelectedSeasonType,
    setSeasonTypes,
    data,
    ...options
  };
}
