import { Col, Row } from 'antd'
import React from 'react'
import SettingsMirrorSuppliesDataTable from '../../features/settingsMirror/SettingsMirrorSuppliesData'

export default function SettingsMirrorSuppliesDataPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<SettingsMirrorSuppliesDataTable />
			</Col>
		</Row>
	)
}
