import { Button, Modal } from 'antd'
import React from 'react'

export const DeleteModal = ({
	isVisible,
	handleCancel,
	handleDelete,
	selected,
	text = {},
}: any) => {
	return (
		<Modal
			visible={isVisible}
			onCancel={handleCancel}
			bodyStyle={{ padding: '20px 20px 0px 20px' }}
			footer={[
				<div style={{ padding: '0 20px 20px 20px' }}>
					<Button key='back' onClick={handleCancel}>
						Отмена
					</Button>

					<Button key='submit' type='primary' onClick={handleDelete}>
						Удалить
					</Button>
				</div>,
			]}
		>
			<div>
				<p>{text}</p>
			</div>
		</Modal>
	)
}
