import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useCashbackTableFilters() {
    const filterMenuItems: MenuProps["items"] = [
        // {
        //     label: (
        //         <Input
        //             className={"analytic-dropdown-input"}
        //             onClick={(e) => e?.stopPropagation()}
        //             prefix={<Icon component={searchIcon}/>}
        //             placeholder={"Поиск..."}
        //         />
        //     ),
        //     key: "filter-search",
        // },
        {
            label: "По id",
            key: "id",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "id-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "id-desc",
                },
            ],
        },
        {
            label: "По названию",
            key: "name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "name-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "name-desc",
                },
            ],
        },
        {
            label: "По дате",
            key: "date",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "date-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "date-desc",
                },
            ],
        },
        {
            label: "По сумме",
            key: "sum",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "sum-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "sum-desc",
                },
            ],
        },
        // {
        //     label: (
        //         <div style={{marginTop: 8, display: 'flex', justifyContent: 'center'}}>
        //                 Сбросить
        //         </div>
        //     ),
        //     key: "reset",
        // },
    ];

    return {filterMenuItems};
}
