import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const useCreateKizDocumentToGTD = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: onCreate,
        data: createData,
        ...options
    } = useMutation(backend.storage.createCreateKizDocumentToGTD, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`GTD_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, formatData: any) => {
            try {
                const data = {
                    values: values,
                    formatData: formatData,
                }
                await onCreate(data);
                await queryClient.invalidateQueries(['GTD_DATA']);
            } catch (e: any) {
                console.log(e?.response?.data?.detail)
                setErrorMessage(e?.response?.data?.detail);
            }
        },
        [onCreate]
    );

    return {
        handleCreate,
        createData,
        errorMessage,
        ...options,
    };
};
