import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "api";

import {useCallback, useEffect, useState} from "react";

export default function useAutoPriceDataById(id: any) {


    const {data: dataById, ...options} = useQuery(
        [
            "AUTO_PRICE_DATA_BY_ID",
            id,
        ],
        () =>
            backend.analytics.getAutoPriceDataById(
                id?.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        dataById,
        ...options,
    };
}