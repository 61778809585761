import React, {useEffect, useState} from 'react';
import line from "../../../assets/images/FinancialresultLine.png";
import calendar2 from "../../../assets/images/financeResultCalendar.png";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const DatePickerCustomed = ({
                                setPickerOpen,
                                dateStart,
                                dateEnd,
                                pickerOpen,
                                handleRangeChangeTable,
                                height = 30,
                                width = '100%',
                                rangeType
                            }: any) => {

    const today = dayjs();
    const currentWeekStart = today.startOf('isoWeek');

    const isWeekBlocked = (date: any) => {
        const selectedWeekStart = dayjs(date).startOf('isoWeek');
        return selectedWeekStart.isAfter(currentWeekStart) || selectedWeekStart.isSame(currentWeekStart);
    };

    return (
        <div
            onClick={() => setPickerOpen(true)}
            style={{
                pointerEvents: rangeType === 'weeks' ? 'auto' : 'none',
                cursor: rangeType === 'weeks' ? 'pointer' : 'auto',
                width: width,
                height: height,
                backgroundColor: '#D9D9D9',
                color: '#000',
                borderRadius: 6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}
        >
            <div
                style={{
                    width: '100%',
                    fontSize: 12,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 10,
                    padding: 15,
                }}
            >
                {dateStart}
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src={line}/>
                    {dateEnd}
                </div>
                <img src={calendar2} className='fin-res-callendar'/>
            </div>
            <DatePicker
                style={{
                    visibility: 'hidden',
                    width: 0,
                    position: 'absolute',
                    left: '50%',
                }}
                onOpenChange={(e) =>{
                    if (!e){
                        setPickerOpen(false)
                    }
                }}
                open={pickerOpen}
                onChange={async (date: any) => {
                    await handleRangeChangeTable(date, true)
                    await setPickerOpen(false)
                }}
                format={"dd.MM.yyyy HH:mm"}
                disabledDate={(date) => isWeekBlocked(date)} // Передайте функцию для блокировки недель
            />
        </div>
    );
};

export default DatePickerCustomed;