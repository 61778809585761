import {api, apiKizFullInfo} from "index";
import dayjs from "dayjs";

// Requests
export async function getFinancialResults(
    startDate: any,
    endDate: string,
    type?: string,
    selectedSeller?: string,
    selectedArticle?: string[]
) {
    const params = new URLSearchParams();

    if (selectedSeller){
        params.append("sellerId", selectedSeller.toString());
    }

    if (startDate && endDate && type) {
        params.append("type", type.toString() === 'quarters' ? 'quarter' : type.toString());
        params.append("startDate", startDate);
        params.append("endDate", endDate);
    }

    if (selectedArticle?.length !== 0 && selectedArticle) {
        params.append("productIds", selectedArticle?.join(','));
    }

    if (selectedSeller){
        const response = await api.get<any>(
            `/financial-results`,
            {params}
        );

        return response.data.data;
    }

}

export async function createFinancialResult(json: CreateFinancialResultBody) {
    const response = await api.post<ApiResponse<FinancialResult>>(
        `/financial-results`,
        json
    );
    return response.data.data;
}

export async function deleteFileFromFinResultChart(id: string) {

    const response = await api.delete(`fin_res_files/${id}`);
    return response.data;
}

export async function getArticleWbData(
    sellerId?: string,
    search?: string
) {
    const params = new URLSearchParams();

    if (sellerId){
        params.append("seller_id", sellerId.toString());
    }

    if (search){
        params.append("search", search.toString());
    }

    const response = await api.get<any>(`/productsV2/articles`,{params});
    return response.data.data;
}

export async function getStockData(
    sellerId?: string,
) {
    const params = new URLSearchParams();

    if (sellerId){
        params.append("sellerId", sellerId.toString());

        const response = await api.get<any>(`/financial-results/stocks`,{params});
        return response.data.data;
    }
}
// Requests
export async function getDataForRange(
    sellerId: string
) {
    const params = new URLSearchParams();

    if (sellerId){
        params.append("sellerId", sellerId.toString());
    }
    if (sellerId){
        const response = await api.get<any>(`/financial-results/intervals`, {params});
        return response.data.data;
    }
}

export async function addFileToFinResult(json: any) {

    const {seller_id, step, file, filename} = json

    const formData = new FormData()
    formData.append('file', file)

    const params = new URLSearchParams();

    if (filename){
        params.append("filename", filename);
    }
    console.log('params')
    console.log(params)

    const response = await api.post<any>(`/fin_res_files/${seller_id}/${step}`, formData, {params});
    return response.data.data;
}

export async function updateFileName(json: any) {
    console.log(json)

    const {id, filename} = json

    const response = await api.patch<any>(`/fin_res_files/${id}`, {filename});
    return response.data.data;
}


export async function getChartFinResData(
    sellerId?: string,
) {
    const params = new URLSearchParams();

    if (sellerId){
        params.append("seller_id", sellerId);
    }

    if (sellerId){
        const response = await api.get<any>(`/fin_res_files/list`,{params});
        return response.data.data;
    }
}

export async function getFileListById(
    id?: string,
) {

    if (id){
        const response = await api.get<any>(`/fin_res_files/file/${id}`);
        return response.data.data;
    }
}
