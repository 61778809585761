import React from 'react';
import {Col, Row} from "antd";
import MicroSeasonsSection from "../../features/microSeasons/MicroSeasonsSection";

const MicroSeasons = () => {
  return (
    <Row style={{padding: "10px 20px"}}>
      <Col className={"container"} span={24}>
        <MicroSeasonsSection/>
      </Col>
    </Row>
  );
};

export default MicroSeasons;