import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as editIcon} from "../../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";

const data = [
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },

];

export default function StorekeepersFullInfo() {

    const [tabNav, setTabNav] = useState('status1')

    const navigate = useNavigate()
    const route = useParams()

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_PAYMENT",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
        <Icon component={trashBucket} style={{cursor: "pointer"}}/>
        В архив
    </span>
            ),
            key: "DELETE_PAYMENT",
        }
    ];

    const getProductsActions = () => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_PAYMENT":
                        // setEditPaymentModal(true)
                        break;
                    case "DELETE_PAYMENT":
                        // setEditProductKeyWordsModal({
                        //     ...editProductAnalyticsModal,
                        //     productId: productId,
                        //     isOpen: true
                        // })
                        break;
                }
            },
        };
    };

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Col span={24} style={{
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate('/storage/storekeepers')}
                >
                    <Icon component={headerPrevBtn}/>
                    Назад
                </div>
            </Col>

            <Col span={24} style={{
                marginTop: 20,
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    {route.id}
                </div>
            </Col>

            <Row className={"container"}>

                <div className={"storage-tabs"} style={{marginLeft: 20, marginTop: 20,}}>
                    <div
                        className={`${
                            tabNav === 'status1' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status1')}
                    >
                        Активные
                    </div>
                    <div
                        className={`${
                            tabNav === 'status2' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status2')}
                    >
                        Архивные
                    </div>
                    {/*<div*/}
                    {/*    className={`${*/}
                    {/*        tabNav === 'status3' ? "tab-active" : ""*/}
                    {/*    }`}*/}
                    {/*    onClick={() => setTabNav('status3')}*/}
                    {/*>*/}
                    {/*    Отсканировано этим устройством*/}
                    {/*</div>*/}
                </div>

                <Col span={24} style={{width: '100%', padding: 20,}}>

                    <div className="tsd-wrap">
                        {
                            data.map(item =>
                                <div className="tsd-item">
                                    <div className="tsd-item-info">
                                        <div>
                                            {item.name}
                                        </div>
                                        <div>
                                            {item.status}
                                        </div>
                                        <div>
                                            {item.fio}
                                        </div>
                                    </div>
                                    <div className="tsd-item-dots">
                                        <Dropdown
                                            trigger={["click"]}
                                            placement={"bottomRight"}
                                            menu={getProductsActions()}
                                        >
                                            <MoreOutlined
                                                style={{cursor: "pointer", fontSize: "20px"}}/>
                                        </Dropdown>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <Pagination
                        defaultCurrent={1}
                        current={1}
                        // onChange={(page, pageSize): any => setPage(page)}
                        total={3}
                        pageSize={6}
                        style={{textAlign: "center", padding: "16px 0"}}
                    />
                </Col>
            </Row>
        </div>
    );
}
