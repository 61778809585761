import { backend } from "api";
import { useAuthStore } from "features/auth/hooks/useAuthStore";
import { useAuthCheck } from "features/auth/hooks/useAuthCheck";
import { setToken, setRefreshToken } from "utils/tokenStorage";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

export function useLogin() {
    const authStore = useAuthStore();
    const { checkAuth } = useAuthCheck();
    const [isCredentialsError, setIsCredentialsError] =
        useState<boolean>(false);
    const [isOtherError, setIsOtherError] = useState<boolean>(false);

    const loginRequest = useMutation((data: LoginParams) =>
        backend.auth.login(data)
    );
    const refreshRequest = useMutation((token: string) =>
        backend.auth.refresh(token)
    );

    const login = useCallback(
        async (values: LoginParams) => {
            setIsCredentialsError(false);
            setIsOtherError(false);
            if (loginRequest.isLoading || authStore.state.isLoggedIn) return;

            try {
                const token = await loginRequest.mutateAsync(values);
                if (!token) throw String("Login Failed");

                setToken(token.access_token);
                setRefreshToken(token.refresh_token)
                await checkAuth();
            } catch (err) {
                if (err === "Invalid credentials") {
                    setIsCredentialsError(true);
                } else {
                    setIsOtherError(true);
                }
            }
        },
        [authStore.state.isLoggedIn, checkAuth, loginRequest]
    );

    const refresh = useCallback(
        async (refreshToken: string) => {
            setIsCredentialsError(false)
            setIsOtherError(false)
            if (refreshRequest.isLoading || authStore.state.isLoggedIn) return

            try {
                const token = await refreshRequest.mutateAsync(refreshToken)
                if (!token) throw String('Login Failed')

                setToken(token.access_token)
                setRefreshToken(token.refresh_token)
                await checkAuth()
            } catch (err) {
                if (err === 'Invalid credentials') {
                    setIsCredentialsError(true)
                } else {
                    setIsOtherError(true)
                }
            }
        },
        [authStore.state.isLoggedIn, checkAuth, refreshRequest]
    )

    return {
        login,
        refresh,
        isCredentialsError,
        isOtherError,
    };
}
