import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Checkbox, Col, Dropdown, Input, MenuProps, message, Row, Spin} from "antd";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Block from "../components/Block";
import usePlusMinusPhraseData from "../hooks/usePlusMinusPhraseData";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {usePostMinusPhrase} from "../hooks/usePostMinusPhrase";

export default function EditProductPlusMinusPhrase({onClose, productId}: any) {

    const {
        data,
        isLoading,
    } = usePlusMinusPhraseData(productId)

    const {
        isSuccessCreate,
        handleCreate,
        setId,
        createLoading,
        isErrorCreate,
        errorMessage,
    } = usePostMinusPhrase()

    const [plusPhrase, setPusPhrase] = useState<any>([])
    const [minusPhrase, setMinusPhrase] = useState<any>([])

    const handleDrop = (id: string, targetBlock: string) => {
        if (targetBlock === 'plusPhrase') {
            let find = minusPhrase?.find((item: any) => item?.id === id);
            setMinusPhrase(minusPhrase.filter((item: any) => item?.id !== id))
            setPusPhrase((prev: any) => ([...prev, find]))
        }
        if (targetBlock === 'minusPhrase') {
            let find = plusPhrase?.find((item: any) => item?.id === id);
            setPusPhrase(plusPhrase.filter((item: any) => item?.id !== id))
            setMinusPhrase((prev: any) => ([...prev, find]))
        }
    };

    const setData = () => {
        setPusPhrase(data?.plus)
        setMinusPhrase(data?.minus)
    }

    useEffect(() => {
        setData()
    }, [data])

    useEffect(() => {
        setId(productId)
    }, [productId])

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно обновили плюс/минус фразы')
        }
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isErrorCreate, isSuccessCreate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Редактировать плюс/минус фразы"}
                onClose={() =>{
                    setData()
                    onClose()
                }}
            />
            {
                isLoading
                    ?
                    <div style={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: 'center',
                        padding: '200px 0',
                    }}>
                        <Spin
                            indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                        />
                    </div>
                    :
                    <>
                        <DndProvider backend={HTML5Backend}>
                            <Row style={{width: '100%'}} justify={'space-between'}>
                                <Col span={11}>
                                    <Block name="Плюс фразы" blockName={'plusPhrase'} onDrop={handleDrop}
                                           blocks={plusPhrase}/>
                                </Col>
                                <Col span={11}>
                                    <Block name="Минус Фразы" blockName={'minusPhrase'} onDrop={handleDrop}
                                           blocks={minusPhrase}/>
                                </Col>
                            </Row>
                        </DndProvider>

                        <div className={"send-article-wrapper"}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10}}>
                                <Button
                                    loading={createLoading}
                                    className="products-edit-button-save"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    onClick={() => handleCreate({
                                        keywordIds: minusPhrase.map((item: any) => item?.id),
                                        productId
                                    })}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    className="products-edit-button-exit"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    onClick={() => {
                                        setData()
                                        onClose()
                                    }}
                                >
                                    Отмена
                                </Button>
                            </div>
                        </div>
                    </>
            }


        </div>
    );
}
