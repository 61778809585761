import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

// Slices
import authSlice from "./authSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
    },
    devTools: {
        actionCreators: {
            ...authSlice.actions,
        },
    },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
