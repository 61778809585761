import {api, apiKiz, apiKizFullInfo} from "index";
import dayjs from "dayjs";

// Requests
export async function getAnalyticsData(
    page?: string,
    pageSize?: string,
    showMode?: string,
    searchText?: string,
    sizes?: string[],
    products?: string[],
    selectedSeller?: string[],
    dateSortStart?: string,
    dateSortEnd?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (sizes && sizes.length > 0) {
        params.append("sizes", sizes?.map((item: any) => item?.title).join(","));
    }
    if (products && products.length > 0) {
        params.append("categories", products?.map((item: any) => item?.id)?.join(","));
    }
    if (selectedSeller && selectedSeller.length > 0) {
        params.append("sellers", selectedSeller?.map((item: any) => item?.id)?.join(","));
    }
    if (showMode) {
        params.append("show_by", showMode);
    }
    if (searchText) {
        params.append("search", searchText);
    }

    if (dateSortStart && dateSortEnd) {
        params.append("start_date", dayjs(dateSortStart).format('DD.MM.YYYY'));
        params.append("end_date", dayjs(dateSortEnd).format('DD.MM.YYYY'));
    }

    const response = await api.get<ApiResponseList<Analytics>>(`/analitics`, {
        params,
    });
    return response.data.data;
}

export async function deleteCashBack(id: string) {

    const response = await api.delete(`/cashbacks/${id}`);
    return response.data;
}

export async function getAnalyticsProductByArticle(article?: string) {

    const response = await api.get<ApiResponse<Product>>(
        `/products/byArticule/${article}`
    );

    return response.data.data;
}

export async function getAnalyticsProductSizesByArticle(article?: string) {
    if (article) {
        const response = await api.get<ApiResponseList<ProductSize>>(
            `/products/byArticule/${article}/sizes`
        );
        return response.data.data;
    }
}

export async function getAnalyticsProductSizesByProductId(id?: string) {

    if (id) {
        const response = await api.get<ApiResponseList<ProductSize>>(
            `/productsV2/${id}/sizes`
        );

        return response.data.data;
    }
}

export async function getAnalyticsProductSizesByProductIdFullInfo(id?: string) {

    if (id) {
        const response = await api.get<ApiResponseList<ProductSize>>(
            `/productsV2/${id}/sizes/stocks
`
        );

        return response.data.data;
    }
}

export async function getAnalyticsCashbacksData(
    page?: string,
    pageSize?: string,
    searchText?: string,
    sortParam?: string,
    sortType?: string,
    selectedCashback?: string[],
) {
    const params = new URLSearchParams();

    if (pageSize) {
        params.append("limit", pageSize);
    }

    if (page) {
        params.append("page", page);
    }

    if (searchText) {
        params.append("search", searchText);
    }

    if (sortParam) {
        params.append("parameter", sortParam);
    }
    if (sortType) {
        params.append("order_by", sortType);
    }

    if (selectedCashback && selectedCashback.length > 0) {
        params.append("statuses", selectedCashback.join(","));
    }

    const response = await api.get<ApiResponseList<Cashback>>(`/cashbacks`, {
        params,
    });

    return response.data.data;
}

//get cashback statuses
export async function getCashbackStatuses() {
    const response = await api.get<any>(`/cashbacks/statuses`);
    return response.data.data.data;
}

export async function createAnalyticsCashback(json: CreateFinancialResultBody) {
    const response = await api.post<ApiResponse<FinancialResult>>(
        `/cashbacks`,
        json
    );
    return response.data.data;
}

export async function updateAnalyticsCashback(params: UpdateCashbackParams) {
    const {id, ...body} = params;
    const response = await api.patch<ApiResponse<Cashback>>(
        `/cashbacks/actions/${id}`,
        body
    );
    return response.data.data;
}

export async function getAnalyticsCashbacksUsersData(
    page?: string,
    pageSize?: string,
    sortType?: string,
    sortParam?: string,
) {

    let url = "/cashbacks/users";

    const params = new URLSearchParams();

    if (pageSize) {
        params.append("limit", pageSize);
    }

    if (page) {
        params.append("page", page);
    }

    if (sortParam) {
        params.append("parameter", sortParam);
    }

    if (sortType) {
        params.append("order_by", sortType);
    }

    if (params.toString()) {
        url += `?${params.toString()}`;
    }

    const response = await api.get<ApiResponseList<CashbackUser>>(url);

    return response.data.data;
}

export async function updateAnalyticsCashbacksUsers(
    params: UpdateCashbackUserParams
) {
    const {id, ...body} = params;
    const response = await api.patch<ApiResponse<CashbackUser>>(
        `/cashbacks/users/${params.id}`,
        body
    );
    return response.data.data;
}

export async function updateAnalyticsCashbacks(
    params: UpdateCashbackUserParams
) {
    const {id, ...body} = params;
    const response = await api.patch<ApiResponse<CashbackUser>>(
        `/cashbacks/users/${params.id}`,
        body
    );
    return response.data.data;
}

export async function createAnalyticsNote(json: any) {
    const response = await api.post<any>(`/notes`, json);
    return response.data;
}

export async function getAnalyticsNotes(id: string) {
    const response = await api.get<any>(`/notes/product/${id}`);
    return response.data.data;
}

export async function deleteAnalyticsNote(id: string) {
    const response = await api.delete<any>(`notes/${id}`);
    return response.data.data;
}

export async function updateAnalyticsNote(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(
        `notes/${id}`,
        body
    );
    return response.data;
}

export async function getCashbackContractByActionId(
    id?: string,
    page?: string,
    pageSize?: string
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    const response = await api.get<ApiResponseList<CashbackContract>>(
        `/cashbacks/${id}/contracts`, {params}
    );
    return response.data.data;
}

export async function getCashbackContractsByUserId(id?: string) {
    const response = await api.get<ApiResponseList<CashbackContract>>(
        `/cashbacks/users/${id}/history`
    );
    return response.data.data;
}

//price data
export async function getAnalyticsPriceData(
    id: string,
    spp?: string,
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();
    if (id) {
        params.append("product_id", id);
    }
    if (spp) {
        params.append("spp", spp);
    }
    if (period_start_date) {
        params.append("period_start_date", period_start_date);
    }
    if (period_end_date) {
        params.append("period_end_date", period_end_date);
    }

    const response = await api.get<any>(`/analitics/price-change-chart`, {
        params,
    });
    return response.data.data;
}

export async function getAnalyticsOrderChartData(
    id: string,
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();
    if (id) {
        params.append("product_id", id);
    }

    if (period_start_date) {
        params.append("period_start_date", period_start_date);
    }
    if (period_end_date) {
        params.append("period_end_date", period_end_date);
    }

    const response = await api.get<any>(`/analitics/orders-chart`, {
        params,
    });
    return response.data.data;
}

//keyWords data
export async function getAnalyticsKeyWordsData(
    id: string,
    period_start_date?: string,
    period_end_date?: string,
    page?: string,
    pageSize?: string,
    isShoWithDataMod?: string,
) {

    let params = new URLSearchParams();

    if (id) {
        params.append("product_id", id);
    }
    if (period_start_date) {
        params.append("period_start_date", period_start_date);
    }
    if (period_end_date) {
        params.append("period_end_date", period_end_date);
    }
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    params.append("has_data", String(isShoWithDataMod));

    const response = await api.get<any>(`/analitics/keywords`, {
        params,
    });
    return response.data.data;
}

export async function getAnalyticsKeyWordsDataV2(
    id: string,
    isShoWithDataMod: string,
    period_start_date?: string,
    period_end_date?: string,
    currentPage?: string,
    limit?: string,
    debouncedSearchKeyWords?: string,
) {

    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", currentPage);
    }
    if (limit) {
        params.append("limit", limit);
    }
    if (id) {
        params.append("product_id", id);
    }
    if (debouncedSearchKeyWords) {
        params.append("search", debouncedSearchKeyWords);
    }
    if (period_start_date) {
        params.append("start_date", dayjs(period_start_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (period_end_date) {
        params.append("end_date", dayjs(period_end_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }

    params.append("found", isShoWithDataMod === 'all' ? 'false' : 'true');

    const response = await api.get<any>(`/keywordsV2/product/${id}/stats`, {
        params,
    });
    return response.data.data;
}

//keyWords chart data
export async function getAnalyticsKeyWordsChartData(
    id: string,
    keyword_ids: string[],
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();

    if (id) {
        params.append("product_id", id);
    }

    if (keyword_ids.length !== 0) {
        params.append("keyword_ids", keyword_ids.join(','));
    }
    if (period_start_date) {
        params.append("period_start_date", period_start_date);
    }
    if (period_end_date) {
        params.append("period_end_date", period_end_date);
    }

    if (keyword_ids.length !== 0) {
        const response = await api.get<any>(`/analitics/keywords-chart`, {
            params,
        });
        return response.data.data;
    }
}

//keyWords chart data
export async function getAnalyticsKeyWordsChartDataV2(
    id: string,
    keyword_ids: string[],
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();

    if (id) {
        params.append("product_id", id);
    }

    if (keyword_ids.length !== 0) {
        params.append("keyword_ids", keyword_ids.join(','));
    }
    if (period_start_date) {
        params.append("start_date", dayjs(period_start_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (period_end_date) {
        params.append("end_date", dayjs(period_end_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }

    if (keyword_ids.length !== 0) {
        const response = await api.get<any>(`/keywordsV2/product/${id}/positions/chart`, {
            params,
        });
        return response.data.data;
    }
}

export async function getAnalyticsKeyWordsChartDataWithLines(
    productId: string,
    adsId: string,
    keyword_ids: string[],
) {
    let params = new URLSearchParams();

    if (productId) {
        params.append("product_id", productId);
    }
    if (adsId) {
        params.append("ads_id ", adsId);
    }

    if (keyword_ids.length !== 0) {
        params.append("keyword_ids", keyword_ids.join(','));
    }

    if (productId && adsId){
        const response = await api.get<any>(`/keywordsV2/product/${productId}/positions/ads/${adsId}/chart`, {
            params,
        });
        return response.data.data;
    }
}

export async function getAdsListForChart(
    id: string,
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();

    if (id) {
        params.append("product_id", id);
    }

    if (period_start_date) {
        params.append("start_date", dayjs(period_start_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (period_end_date) {
        params.append("end_date", dayjs(period_end_date, 'DD.MM.YYYY HH:mm').utcOffset(3).format('YYYY-MM-DDTHH:mm:ssZ'));
    }

    if (id) {
        const response = await api.get<any>(`/keywordsV2/product/${id}/ads/intervals/list`, {
            params,
        });
        return response.data.data;
    }
}

//cashBack chart data
export async function getAnalyticsCashBackChartData(
    id: string,
    period_start_date?: string,
    period_end_date?: string,
) {
    let params = new URLSearchParams();

    if (id) {
        params.append("product_id", id);
    }

    if (period_start_date) {
        params.append("period_start_date", period_start_date);
    }
    if (period_end_date) {
        params.append("period_end_date", period_end_date);
    }

    const response = await api.get<any>(`/analitics/cashback-chart`, {
        params,
    });

    return response.data.data;
}

export async function getAllOperations() {
    const response = await api.get<ApiResponseList<CashbackOperation>>(
        `/contract-operations`
    );

    return response.data.data;
}

export async function updateOperation(params: UpdateCashbackOperationParams) {
    const {id, ...body} = params;
    const response = await api.patch<ApiResponse<CashbackOperation>>(
        `/contract-operations/${id}`,
        body
    );
    return response.data;
}

export async function updateOperationSix(params: UpdateCashbackOperationParams) {
    const {id, ...body} = params;

    const formData = new FormData()

    formData.append('file', body?.file)

    const response = await api.patch<ApiResponse<CashbackOperation>>(
        `/contract-operations/${id}/payments/upload?user_id=${body.analyticId}`, formData);

    return response.data;
}

//set new costPrice to product
export async function postAdLeftMoney(json: any) {
    const response = await api.post<any>(`/testSend`, json);
    return response.data;
}

//set new price and discount
export async function postChangeCurrentCurrency(json: any) {

    const {
        price,
        discount,
        nmId
    } = json

    const response = await api.post<any>(`/productsV2/updatePrice`, {
        price: Number(price),
        discount: Number(discount),
        nmId: Number(nmId)
    });
    return response.data;
}

//set new auto price
export async function postChangeAutoPrice(json: any) {

    const {
        id,
        recommendedPrice,
        autoChangeLt,
        autoChangeGt,
        isAuto
    } = json

    const response = await api.post<any>(`/productsV2/setAutoPrice`, {
        id: Number(id),
        recommendedPrice: Number(recommendedPrice),
        autoChangeLt: Number(autoChangeLt),
        autoChangeGt: Number(autoChangeGt),
        isAuto: Boolean(isAuto)
    });
    return response.data;
}

//set new costPrice to product
export async function setNewCostPriceToProduct(json: any) {
    const response = await api.post<any>(`/self-costs`, json);
    return response.data;
}

export async function updateCostPrice(data: any) {

    const {json, id} = data
    const response = await api.patch<any>(`/self-costs/${id}`, json);
    return response.data;
}

export async function publishToTestGroup(id: any) {
    const response = await api.get<any>(
        `/cashbacks/send-to-test-group/${id}`
    );

    return response.data.data;
}

export async function getCashBackList() {
    let params = new URLSearchParams();

    const response = await api.get<any>(`/cashbacks/list`, {
        params,
    });
    return response.data.data;
}

export async function deleteExpenses(id?: string) {
    const response = await api.delete<any>(
        `/cashbacks/ad-expenses/${id}`
    );
    return response.data.data;
}

export async function getAutoPriceDataById(id?: string) {

    const response = await api.get<any>(`/productsV2/${id}/autoPrice`);
    return response.data.data;
}

export async function addFileToSelfCostResult(json: any) {
	const { file } = json

	const formData = new FormData()
	formData.append('file', file)


	const response = await api.post<any>(
		`/self-costs/import`,
		formData,
	)
	return response.data
}