import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";

export default function useStockData() {

    const [sellerId, setSellerId] = useState<any>(null)

    const { data: stockData, ...options } = useQuery(
        [
            "STOCK_DATA",
            sellerId
        ],
        () => backend.financialResult.getStockData(
            sellerId
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        stockData,
        setSellerId,
        ...options,
    };
}
