import React from 'react'
import { Button, Checkbox } from 'antd'
import dayjs from 'dayjs'
import { downloadFinRecordFile } from '../../../api/documentMirror'
import { formatDate } from 'utils/formatDate'
import { useNavigate } from 'react-router-dom'

const actionTypes: any = {
	regular: { title: 'Акция', color: 'rgba(70, 130, 180, 0.6)' },
	auto: { title: 'Автоакция', color: 'rgba(255, 203, 10, 0.6)' },
	special: {
		title: 'Специальное предложение',
		color: 'rgba(34, 139, 34, 0.6)',
	},
	sale: { title: 'Дни распродаж', color: 'rgba(255, 10, 255, 0.6)' },
}

const ActionItem = ({ item, onCheck }: any) => {
	const navigate = useNavigate()
	const actionDates = `${formatDate(
		new Date(item.startDateTime)
	)} - ${formatDate(new Date(item.endDateTime))}`

	const handleClick = () => {
		navigate(`/actions/actions-calendar/${item.id || 0}`)
	}

	return (
		<div
			onClick={handleClick}
			style={{
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '10px',
				padding: '15px',
				marginBottom: '10px',
				boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '10px',
				}}
			>
				<div
					style={{
						backgroundColor: actionTypes[item.type].color,
						borderRadius: '5px',
						padding: '8px 12px',
					}}
				>
					<p style={{ color: '#303030', fontWeight: 'bold', margin: 0 }}>
						{actionTypes[item.type].title}
					</p>
				</div>
				<p style={{ margin: 0, fontSize: '0.9em', color: '#555' }}>
					Даты акции: {actionDates}
				</p>
			</div>
			<div
				className='document-page-items-users'
				style={{ paddingLeft: '10px' }}
			>
				<p style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#333' }}>
					{`${item.name}`}
				</p>
			</div>
		</div>
	)
}

export default ActionItem
