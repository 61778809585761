import Icon from "@ant-design/icons";
import {Button, Col, DatePicker, Input, Row, Spin, Table} from "antd";
import {ReactComponent as rangePickerArrow} from "assets/images/rangepicker-arrow.svg";
import {ReactComponent as closeIcon} from "assets/images/modal-close-icon-blue.svg";
import {ReactComponent as chartIcon} from "assets/images/presentation-chart-icon.svg";
import React, {useEffect, useState} from "react";
import LineChart from "../components/PriceChart";
import PriceChart from "../components/PriceChart";
import useAnalyticsPriceData from "../hooks/analiticsPrice/useAnalyticsPriceData";
import {LoadingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const {RangePicker} = DatePicker;


function PriceChangeModalContent({onClose, id}: any) {

    const [activeRange, setActiveRange] = useState('3')

    const {
        data,
        isLoading,
        setPeriod_start_date,
        setPeriod_end_date,
        spp,
        setSpp,
    } = useAnalyticsPriceData(id)

    const handleRangeChange = (dates: any, dateStrings: any) => {
        setPeriod_start_date(dateStrings[0])
        setPeriod_end_date(dateStrings[1])
    }

    const handleOneWeek = () => {
        const today = dayjs().format('DD.MM.YYYY');
        const oneWeekAgo = dayjs().subtract(1, 'week').format('DD.MM.YYYY');
        setActiveRange('1')
        setPeriod_start_date(oneWeekAgo)
        setPeriod_end_date(today)
    }

    const handleOneMonth = () => {
        const today = dayjs().format('DD.MM.YYYY');
        const oneMonthAgo = dayjs().subtract(1, 'month').format('DD.MM.YYYY');
        setActiveRange('2')
        setPeriod_start_date(oneMonthAgo)
        setPeriod_end_date(today)
    }

    const handleThreeMonths = () => {
        const today = dayjs().format('DD.MM.YYYY');
        const threeMonthsAgo = dayjs().subtract(3, 'month').format('DD.MM.YYYY');
        setActiveRange('3')
        setPeriod_start_date(threeMonthsAgo)
        setPeriod_end_date(today)
    }

    if (isLoading) {
        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                <Spin
                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                />
            </div>
        )
    }

    return (
        <div style={{padding: "28px 30px 60px 30px"}}>
            <div style={{position: "relative", height: "50px"}}>
                <Icon
                    onClick={() => onClose()}
                    component={closeIcon}
                    style={{fontSize: "23px", position: "absolute", right: 0}}
                />
            </div>
            <Row className={"keyword-chart-wrapper"}>
                <Col span={24}>
                    <Row justify={"space-between"}>
                        <Col className={"analytics-discount-wrapper"}>
                            <span className={"analytics-discount-title"}>
                                СПП, %{" "}
                            </span>
                            <Input
                                className={"analytics-spp-input"}
                                type={"number"}
                                value={spp}
                                onChange={(e) => setSpp(e.target.value)}
                                suffix={"%"}
                            />
                        </Col>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                }}
                            >
                                {" "}
                                <span onClick={handleOneWeek}
                                      className={activeRange === '1' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Неделя
                                </span>
                                <span
                                    onClick={handleOneMonth}
                                    className={activeRange === '2' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Месяц
                                </span>
                                <span onClick={handleThreeMonths}
                                      className={activeRange === '3' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    3 Месяца
                                </span>
                                <RangePicker
                                    onChange={handleRangeChange}
                                    placeholder={["Начало", "Конец"]}
                                    separator={
                                        <Icon component={rangePickerArrow}/>
                                    }
                                    format={"DD.MM.YYYY"}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={"keyword-chart-message-wrapper"}>
                        <div style={{textAlign: "center"}}>
                            {data?.coords?.length <= 0
                                ?
                                <>
                                    <Icon
                                        component={chartIcon}
                                        style={{fontSize: "100px"}}
                                    />
                                    <div className={"keyword-chart-message"}>
                                        Выберите ключевые слова, чтобы увидеть график
                                        сравнения их позиций
                                    </div>
                                </>
                                : <PriceChart data={data.coords} title={'Цена'}/>

                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PriceChangeModalContent;