import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "store";
import AppRoutes from "./features/routes";
import locale from "antd/locale/ru_RU";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ReactPDF from "@react-pdf/renderer";

import("dayjs/locale/ru");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');
dayjs.locale('ru');

const queryClient = new QueryClient();

function App() {

    useEffect(() => {
        dayjs.locale("ru");
    }, []);

    return (
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: `"Montserrat", sans-serif`,
                        colorPrimary: "#4C78EE",
                    },
                    components: {
                        Input: {
                            borderRadius: 4,
                            controlHeight: 48,
                        },
                        Button: {
                            controlHeight: 37,
                        },
                    },
                }}
                locale={locale}
            >
                <QueryClientProvider client={queryClient}>
                    <ReduxProvider store={store}>
                        {" "}
                        <AppRoutes />
                    </ReduxProvider>
                </QueryClientProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
}

export default App;
