import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";

export default function DownloadEmissions({onClose}: any) {

    const [form] = Form.useForm();


    const onFinish = (values: any) => {
        // handleCreate(values)
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Эмиссия коробок"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Какое количество коробов необходимо для эмиссии?"}
                            name='numberOfShelf'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                // loading={createLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Выгрузить
                            </Button>
                            <Button
                                onClick={onClose}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
