import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useState} from "react";

export default function useAcceptanceData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");


    const { data: acceptance, ...options } = useQuery(
        [
            "ACCEPTANCE_DATA",
        ],
        () =>
            backend.declaration.getAcceptanceData(),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        acceptance,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
