import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function useKeyWordGroupById() {
    const [id, setId] = useState<string>('');

    const { data: keyWordBGroupId, ...options } = useQuery(
        ["KEY_WORDS_GROUP_LIST_BY_ID", id],
        () =>
            backend.keyWords.getKeyWordGroupDataById(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        keyWordBGroupId,
        setId,
        ...options,
    };
}
