import {useQuery} from "react-query";
import {backend} from "api";
import React, {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";

export default function useDocumentsCategoriesData() {

    const {data: documentsCategoriesData, ...options} = useQuery(
        [
            "DOCUMENT_MIRROR_DOCUMENTS_CATEGORIES_DATA",

        ],
        () =>
            backend.documentMirror.getDocumentsCategoriesData(),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        documentsCategoriesData,
        ...options,
    };
}
