import { useQuery } from "react-query";
import {backend} from "../../apiExtraInfo";
import {useState} from "react";

export default function useEmmissionsDataFilterBoxCode() {

    const [id, setId] = useState<any>('')
    const [fieldParam, setFieldParam] = useState<any>('box_code')


    const { data: boxCode, ...options } = useQuery(
        ["EMISSIONS_DATA_FILTER_SIZE_BOX_CODE", id, fieldParam],
        () => backend.emissions.getEmissionsDataFilterSize(
            id,
            fieldParam.toString()
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        boxCode,
        ...options,
    };
}
