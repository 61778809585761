import {
    Button,
    Col,
    Form, Input,
    message,
    Modal,
    Row, Spin,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getToken} from "../../../utils/tokenStorage";
import {useDeleteFileFormChart} from "../hooks/useDeleteFileFormChart";
import {useAuthStore} from "../../auth/hooks/useAuthStore";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import Icon, {LoadingOutlined, MoreOutlined, DownloadOutlined, EyeOutlined} from "@ant-design/icons";
import {ReactComponent as editIcon} from "../../../assets/images/big-edit-icon.svg";
import {ReactComponent as trashBucket} from 'assets/images/trash-bucket.svg'
import {useUpdateFileName} from "../hooks/useUpdateFileName";
import useChartData from "../hooks/useChartData";

export default function DownloadFileModal({onClose, modalData}: any) {

    const token = getToken();
    const {state} = useAuthStore();
    const [form] = Form.useForm();

    const isAdmin = state?.user?.role?.name === 'Администратор'
    const isMNMCoord = state?.user?.role?.name === 'МнМ-координатор'

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {
        }
    });

    const [editMode, setEditMode] = useState({
        isEditMode: false,
        text: '',
        id: null
    })

    const {
        handleDelete,
        errorMessage,
        isError,
        isSuccess,
        isLoading
    } = useDeleteFileFormChart()

    const {
        onUpdate,
        errorMessage: isErrorUpdateMessage,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess: isSuccessUpdate,
    } = useUpdateFileName()

    const {
        chartData,
        setSellerId,
        isLoading: isLoadingChart
    } = useChartData()


    const filesData = chartData?.data.find((item: any) => item?.step === modalData?.step)?.files


    const handleDownloadClick = (path: any, fileName: any) => {

        message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

        axios({
            method: 'get',
            url: path,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.type = 'application/xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            message.error('Ошибка при скачивании, обратитесь в поддержку')
        });

    }

    const handleOpenClick = (path: any) => {
        axios({
            method: 'get',
            url: path,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            })
            .catch(error => {
                message.error('Ошибка при открытии файла. Пожалуйста, убедитесь, что файл имеет расширение .pdf')
            });
    }

    const handleDeleteFile = (item: any) => {
        setIsModalVisible({
            isOpen: true,
            text: 'удалить файл?',
            onPress: () => handleDelete(item)
        })
    }

    const sliceName = (name: any) => {
        if (name.length > 25) {
            return name.slice(0, 25) + '...'
        } else {
            return name
        }
    }

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
        if (isSuccess) {
            message.success('Вы успешно удалили файл')
            onClose()
        }
        if (isErrorUpdate) {
            message.error(isErrorUpdateMessage)
        }
        if (isSuccessUpdate) {
            message.success('Вы успешно обновили наименование файла')
            setEditMode({
                isEditMode: false,
                text: '',
                id: null
            })
        }
    }, [isSuccess, isError, isErrorUpdate, isSuccessUpdate])

    useEffect(() => {
        form.setFieldsValue({
            filename: editMode.text
        })
    }, [editMode?.id])

    useEffect(() => {
        setSellerId(modalData?.selectedSeller)
    }, [modalData?.selectedSeller])

    if (isLoadingChart) {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    paddingTop: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: "center",
                }}
            >
                <Spin
                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                />
            </div>
        )
    }

    if (editMode.isEditMode) {
        return (
            <div className={"modal-wrapper"} style={{padding: "30px"}}>
                <ModalHeader title={'Редактирование наименования файла'} onClose={() => {
                    setEditMode({
                        isEditMode: false,
                        text: '',
                        id: null
                    })
                    onClose()
                }}/>
                <Form
                    name={"addResultForm"}
                    layout={"vertical"}
                    form={form}
                    onFinish={(values) => onUpdate({id: editMode.id, filename: values?.filename})}
                >
                    <Form.Item
                        label={"Введите новое наименование файла"}
                        name="filename"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите наименование файла",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "100%"}}
                                htmlType={"submit"}
                                loading={isLoadingUpdate}
                            >
                                Сохранить
                            </Button>
                        </Col>
                        <Col span={12}>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{width: "100%"}}
                                onClick={() => {
                                    setEditMode({
                                        isEditMode: false,
                                        text: '',
                                        id: null
                                    })
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={modalData?.title} onClose={() => {
                setEditMode({
                    isEditMode: false,
                    text: '',
                    id: null
                })
                onClose()
            }}/>
            <Row
                className={"container"}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div className="fin-download-modal-item-wrap" style={{marginTop: 20,}}>
                    {filesData?.length === 0
                        ? <div>Нет файлов</div>
                        :
                        filesData?.map((item: any) =>
                            <div className="customs-clearance-ciles-modal-item">
                                <div
                                    className="fin-download-modal-item-name"
                                    onClick={() => handleOpenClick(
                                        `${process.env.REACT_APP_API_URL + item?.path}`
                                    )}
                                >
                                    {sliceName(item?.filename)}
                                </div>
                                <div className='fin-download-modal'>
                                    <div
                                        onClick={() =>
                                            handleDownloadClick(
                                                `${process.env.REACT_APP_API_URL + item?.path}`,
                                                item?.filename
                                            )
                                        }
                                    >
                                        {React.createElement(DownloadOutlined)}
                                    </div>
                                    {isAdmin || isMNMCoord &&
                                    <div
                                        onClick={() => setEditMode({
                                            isEditMode: true,
                                            text: item?.filename,
                                            id: item?.id
                                        })}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <Icon component={editIcon} style={{cursor: "pointer"}}/>
                                    </div>
                                    }
                                    {isAdmin &&
                                    <div
                                        onClick={() => handleDeleteFile(item?.id)}
                                        style={{
                                            cursor: 'pointer',
                                            opacity: isLoading ? 0.5 : 1,
                                            pointerEvents: isLoading ? 'none' : 'auto',
                                        }}
                                    >
                                        <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                                    </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </Row>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={360}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {
                        }
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
