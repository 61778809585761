import { Input, MenuProps } from "antd";
import { useEffect, useState } from "react";
import Icon from "@ant-design/icons";
import { ReactComponent as filterSortIcon } from "assets/images/filter-sort-icon.svg";
import { ReactComponent as searchIcon } from "assets/images/search-icon.svg";

interface AnalyticTableFilter {
    selfCostSort: boolean;
    wbStorageRemainingSort: boolean;
    storageRemainingSort: boolean;
    productInWaySort: boolean;
    avgOrderSort: boolean;
    avgSalesSort: boolean;
    salesPercentSort: boolean;
    commissionSort: boolean;
    logisticSort: boolean;
    storageSort: boolean;
    adExpensesSort: boolean;
    adLeftMoneySort: boolean;
    profitPerMonthSort: boolean;
    salePerMonthSort: boolean;
    currentPriceSort: boolean;
    expensePerBuyoutSort: boolean;
    expensePerCashbackSort: boolean;
    needToShipSort: boolean;
}

interface FilterSearchData {
    selfCostSort: string;
    wbStorageRemainingSort: string;
    storageRemainingSort: string;
    productInWaySort: string;
    avgOrderSort: string;
    avgSalesSort: string;
    salesPercentSort: string;
    commissionSort: string;
    logisticSort: string;
    storageSort: string;
    adExpensesSort: string;
    adLeftMoneySort: string;
    profitPerMonthSort: string;
    salePerMonthSort: string;
    currentPriceSort: string;
    expensePerBuyoutSort: string;
    expensePerCashbackSort: string;
    needToShipSort: string;
}

export const useAnalyticsTableFilters = () => {
    const [filterSearchText, setFilterSearchText] = useState<string>("");
    const [selfCostSort, setSelfCostSort] = useState<boolean>(false);
    const [wbStorageRemainingSort, setWbStorageRemainingSort] = useState<boolean>(false);
    const [storageRemainingSort, setStorageRemainingSort] = useState<boolean>(false);
    const [productInWaySort, setProductInWaySort] = useState<boolean>(false);
    const [avgOrderSort, setAvgOrderSort] = useState<boolean>(false);
    const [avgSalesSort, setAvgSalesSort] = useState<boolean>(false);
    const [salesPercentSort, setSalesPercentSort] = useState<boolean>(false);
    const [commissionSort, setCommissionSort] = useState<boolean>(false);
    const [logisticSort, setLogisticSort] = useState<boolean>(false);
    const [storageSort, setStorageSort] = useState<boolean>(false);
    const [adExpensesSort, setAdExpensesSort] = useState<boolean>(false);
    const [adLeftMoneySort, setAdLeftMoneySort] = useState<boolean>(false);
    const [profitPerMonthSort, setProfitPerMonthSort] = useState<boolean>(false);
    const [salePerMonthSort, setSalePerMonthSort] = useState<boolean>(false);
    const [currentPriceSort, setCurrentPriceSort] = useState<boolean>(false);
    const [expensePerBuyoutSort, setExpensePerBuyoutSort] = useState<boolean>(false);
    const [expensePerCashbackSort, setExpensePerCashbackSort] = useState<boolean>(false);
    const [needToShipSort, setNeedToShipSort] = useState<boolean>(false);

    const [sortField, setSortField] = useState<string>("");

    const stateSetters = [
        setSelfCostSort,
        setWbStorageRemainingSort,
        setStorageRemainingSort,
        setProductInWaySort,
        setAvgOrderSort,
        setAvgSalesSort,
        setSalesPercentSort,
        setCommissionSort,
        setLogisticSort,
        setStorageSort,
        setAdExpensesSort,
        setAdLeftMoneySort,
        setProfitPerMonthSort,
        setSalePerMonthSort,
        setCurrentPriceSort,
        setExpensePerBuyoutSort,
        setExpensePerCashbackSort,
        setNeedToShipSort
    ];

    const filterSearchData = {
        selfCostSort: "Себестоимость",
        wbStorageRemainingSort: "Остаток на складе WB",
        storageRemainingSort: "Остаток на складе",
        productInWaySort: "Товар в пути",
        avgOrderSort: "Среднее кол-во заказов в день",
        avgSalesSort: "Среднее кол-во покупок в день",
        salesPercentSort: "% Выкупа",
        commissionSort: "Комиссия",
        logisticSort: "Логистика",
        storageSort: "Хранение",
        adExpensesSort: "Затраты на рекламу",
        adLeftMoneySort: "Остаток рекл. бюджета",
        profitPerMonthSort: "Выручка за 30 дней",
        salePerMonthSort: "Продажа за 30 дней",
        currentPriceSort: "Текущая цена",
        expensePerBuyoutSort: "Расход на выкуп",
        expensePerCashbackSort: "Расход на кешбек",
        needToShipSort: "Запас товара на дней",
    };

    const filters: AnalyticTableFilter = {
        selfCostSort,
        wbStorageRemainingSort,
        storageRemainingSort,
        productInWaySort,
        avgOrderSort,
        avgSalesSort,
        salesPercentSort,
        commissionSort,
        logisticSort,
        storageSort,
        adExpensesSort,
        adLeftMoneySort,
        profitPerMonthSort,
        salePerMonthSort,
        currentPriceSort,
        expensePerBuyoutSort,
        expensePerCashbackSort,
        needToShipSort,
    };

    const handleSelectSort = (
        prevValue: boolean,
        setter: (value: boolean) => void
    ) => {
        if (prevValue) {
            setter(false);
            return;
        }
        for (const stateSetter of stateSetters) {
            stateSetter(false);
        }
        setter(true);
    };

    const filterMenuItems: MenuProps["items"] = [
        {
            label: (
                <Input
                    className={"analytic-dropdown-input"}
                    onClick={(e) => e?.stopPropagation()}
                    prefix={<Icon component={searchIcon} />}
                    placeholder={"Поиск..."}
                    value={filterSearchText}
                    onChange={(e) => setFilterSearchText(e.target.value)}
                />
            ),
            key: "filter-search",
        },
        {
            label: "Себестоимость",
            key: "filter1",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(selfCostSort, setSelfCostSort)
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "selfCostSort",
                },
            ],
        },
        {
            label: "Остаток на складе WB",
            key: "filter2",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    wbStorageRemainingSort,
                                    setWbStorageRemainingSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "wbStorageRemainingSort",
                },
            ],
        },
        {
            label: "Остаток на складе",
            key: "filter3",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    storageRemainingSort,
                                    setStorageRemainingSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "storageRemainingSort",
                },
            ],
        },
        {
            label: "Товар в пути",
            key: "filter4",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    productInWaySort,
                                    setProductInWaySort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "productInWaySort",
                },
            ],
        },
        {
            label: "Среднее кол-во заказов",
            key: "filter5",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(avgOrderSort, setAvgOrderSort)
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "avgOrderSort",
                },
            ],
        },
        {
            label: "Среднее кол-во продаж",
            key: "filter6",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(avgSalesSort, setAvgSalesSort)
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "avgSalesSort",
                },
            ],
        },
        {
            label: "% Выкупа",
            key: "filter7",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    salesPercentSort,
                                    setSalesPercentSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "salesPercentSort",
                },
            ],
        },
        {
            label: "Комиссия",
            key: "filter8",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    commissionSort,
                                    setCommissionSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "commissionSort",
                },
            ],
        },
        {
            label: "Логистика",
            key: "filter9",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(logisticSort, setLogisticSort)
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "logisticSort",
                },
            ],
        },
        {
            label: "Хранение",
            key: "filter10",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(storageSort, setStorageSort)
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "storageSort",
                },
            ],
        },
        {
            label: "Затраты на рекламу",
            key: "filter11",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    adExpensesSort,
                                    setAdExpensesSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "adExpensesSort",
                },
            ],
        },
        {
            label: "Ост. рекл. бюджета",
            key: "filter12",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    adLeftMoneySort,
                                    setAdLeftMoneySort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "adLeftMoneySort",
                },
            ],
        },
        {
            label: "Выручка за 30 дней",
            key: "filter13",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    profitPerMonthSort,
                                    setProfitPerMonthSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "profitPerMonthSort",
                },
            ],
        },
        {
            label: "Продажа за 30 дней",
            key: "filter14",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    salePerMonthSort,
                                    setSalePerMonthSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "salePerMonthSort",
                },
            ],
        },
        {
            label: "Текущая цена",
            key: "filter15",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    currentPriceSort,
                                    setCurrentPriceSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "currentPriceSort",
                },
            ],
        },
        {
            label: "Расход на выкуп",
            key: "filter16",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    expensePerBuyoutSort,
                                    setExpensePerBuyoutSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "expensePerBuyoutSort",
                },
            ],
        },
        {
            label: "Расход на кешбек",
            key: "filter17",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    expensePerCashbackSort,
                                    setExpensePerCashbackSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "expensePerCashbackSort",
                },
            ],
        },
        {
            label: "Запас товара на дней",
            key: "filter18",
            children: [
                {
                    icon: <Icon component={filterSortIcon} />,
                    label: (
                        <span
                            onClick={() =>
                                handleSelectSort(
                                    needToShipSort,
                                    setNeedToShipSort
                                )
                            }
                        >
                            Сортировка от А до Я
                        </span>
                    ),
                    key: "needToShipSort",
                },
            ],
        },
    ];

    const getSelectedFilter = () => {
        for (const key in filters) {
            if (filters[key as keyof AnalyticTableFilter]) {
                return key;
            }
        }
        return "";
    };

    const getFilterMenuItems = () => {
        return filterMenuItems.filter((menuItem, index) => {
            return (
                index === 0 ||
                filterSearchData[
                    (menuItem! as any).children[0].key as keyof FilterSearchData
                ].toLowerCase()
            );
        });
    };

    useEffect(() => {
        let sortKey = "";

        if (adExpensesSort) sortKey = "adExpenses";
        if (adLeftMoneySort) sortKey = "adLeftMoney";
        if (avgOrderSort) sortKey = "avgOrdersPerDay";
        if (avgSalesSort) sortKey = "avgSalesPerDay";
        if (commissionSort) sortKey = "commissionPercent";
        if (currentPriceSort) sortKey = "currentPrice";
        if (expensePerBuyoutSort) sortKey = "expensePerBuyout";
        if (expensePerCashbackSort) sortKey = "expensePerCashback";
        if (logisticSort) sortKey = "deliveryRub";
        if (productInWaySort) sortKey = "productsOnWay";
        if (profitPerMonthSort) sortKey = "profitPerMonth";
        if (salePerMonthSort) sortKey = "salesPerMonth";
        if (salesPercentSort) sortKey = "salesPercent";
        if (selfCostSort) sortKey = "selfCost";
        if (storageRemainingSort) sortKey = "ownTotalStorageCount";
        if (storageSort) sortKey = "storage";
        if (wbStorageRemainingSort) sortKey = "wbTotalStorageCount";
        if (needToShipSort) sortKey = "needToShip";

        setSortField(sortKey);
    }, [
        selfCostSort,
        wbStorageRemainingSort,
        storageRemainingSort,
        productInWaySort,
        avgOrderSort,
        avgSalesSort,
        salesPercentSort,
        commissionSort,
        logisticSort,
        storageSort,
        adExpensesSort,
        adLeftMoneySort,
        profitPerMonthSort,
        salePerMonthSort,
        currentPriceSort,
        expensePerBuyoutSort,
        expensePerCashbackSort,
        needToShipSort,
    ]);

    return {
        getSelectedFilter,
        getFilterMenuItems,
        sortField,
    };
};
