import { api } from "index";

// Requests
export async function getSizes(
    search?: string,
) {
    let params = new URLSearchParams();
    if (search) {
        params.append("search", search);
    }

    const response = await api.get<any>(`/product-sizes/sizes/list`, {params});
    return response.data.data;
}
