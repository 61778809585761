import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom'

const ActionsPage = () => {
	// const location = useLocation()
	// const navigate = useNavigate()
	return (
		<Row>
			<Col style={{ padding: '10px 20px' }} span={24}>
				{/* <div className={'storage-tabs'}>
					<div
						className={`${
							location.pathname.includes('/sellers-data') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/settings-mirror/sellers-data')}
					>
						Данные Продавца
					</div>
					<div
						className={`${
							location.pathname.includes('/users') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/settings-mirror/users')}
					>
						Пользователи
					</div>
					<div
						className={`${
							location.pathname.includes('/api-access') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/settings-mirror/api-access')}
					>
						Доступ к апи
					</div>
					<div
						className={`${
							location.pathname.includes('/supplies') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/settings-mirror/supplies')}
					>
						Возвраты товаров
					</div>
				</div> */}
				<Row>
					<Col span={24}>
						<Outlet />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default ActionsPage
