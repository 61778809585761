import {useNavigate} from "react-router-dom";
import {Col, Row} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as headerPrevBtn} from "assets/images/header-prev-btn.svg";
import CashbackParticipantsTable from "features/cashbacks/pages/CashbacksParticipantsTable";
import CashbacksUsersTable from "../../features/cashbacks/pages/CashbacksUsersTable";
import CashbacksExpensesTable from "../../features/cashbacks/pages/CashbacksExpensesTable";

export default function CashbackExpensesPage() {
    const navigate = useNavigate();

    return (
        <Row style={{ padding: "20px" }}>
            <Col span={24}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate("/cashbacks")}
                >
                    <Icon component={headerPrevBtn} />
                    Назад
                </div>
            </Col>
            <Col span={24}>
                <CashbacksExpensesTable />
            </Col>
        </Row>
    );
}
