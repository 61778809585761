import {useQuery} from "react-query";
import {backend} from "api";

export default function useSellersInfoForShipmentsFormation() {

    const {data: sellers, ...options} = useQuery(
        [
            "SELLERS_FOR_SHIPMENTS_FORMATION",
        ],
        () =>
            backend.storage.getSellersInfoForShipmentsFormation(
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        sellers,
        ...options,
    };
}
