import {Button, Col, Form, Input, Row, Select, Table} from "antd";
import trashCan from '../../../../assets/images/trashCan.svg'

const data = [
    {
        barcode: "1234567890",
        boxCount: "10",
        shkBox: "10",
        name: 'test'
    },
    {
        barcode: "1234567890",
        boxCount: "10",
        shkBox: "10",
        name: 'test'
    },
    {
        barcode: "1234567890",
        boxCount: "10",
        shkBox: "10",
        name: 'test'
    },
    {
        barcode: "1234567890",
        boxCount: "10",
        shkBox: "10",
        name: 'test'
    },
    {
        barcode: "1234567890",
        boxCount: "10",
        shkBox: "10",
        name: 'test'
    },
];


const columns = [
    {
        title: "Баркод(штрихкод)",
        dataIndex: "barcode",
        key: "barcode",
        width: "25%",
    },
    {
        title: "Кол-во",
        dataIndex: "boxCount",
        key: "boxCount",
        width: "25%",
    },
    {
        title: "ШК короба",
        dataIndex: "shkBox",
        key: "shkBox",
        width: "25%",
    },
    {
        title: "Наименование",
        dataIndex: "name",
        key: "name",
        width: "25%",
    },
];


export default function AddSkTabTable() {
    return (
        <div>
            <Row
                className={"container"}
                style={{marginTop: "20px", minHeight: "800px"}}
            >

                <Col span={8}>
                    <div
                        style={{
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            width: 200,
                            height: 250,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div style={{
                            width: 360,
                            textAlign: 'center',
                            marginBottom: 10,
                            fontSize: 12,
                            fontWeight: 600,
                            color: '#4B4B4B'
                        }}>
                            Для того, чтобы сгенерировать полный отчет,
                            загрузите отчет “Примерное наименование отчета”
                            из раздела “Допоставки на склад”
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                        }}>
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "222px"}}
                            >
                                Загрузить накладную
                            </Button>
                            <Button
                                disabled={true}
                                className={"button"}
                                type={"primary"}
                                style={{width: "222px"}}
                            >
                                Скачать накладную
                            </Button>
                            <Button
                                disabled={true}
                                className={"button"}
                                type={"primary"}
                                style={{width: "222px"}}
                            >
                                Сохранить накладную
                            </Button>
                        </div>
                    </div>

                </Col>
                <Col span={16}>
                    <Table
                        className={"additional-deliveries-table"}
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
            </Row>

        </div>
    );
}
