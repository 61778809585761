import { Col, Row } from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import EmissionsTablet from "../../features/kiz/pages/emissions/EmissionsTablet";

export default function EmissionsTab() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <EmissionsTablet />
            </Col>
        </Row>
    );
}
