import React, {useState, useEffect, useRef} from "react";
import {
    Row,
    Col,
    Input,
    Select,
    Button,
    Table,
    Dropdown,
    Checkbox,
    DatePicker,
    Modal, Pagination, message,
} from "antd";
import Icon from "@ant-design/icons";
import {MoreOutlined} from "@ant-design/icons";
import selectIcon from "assets/images/select-icon.svg";
import AddCompanyModalContent from "../../modal/advertisingMirror/AddCompanyModalContent";
import EditCompanyModalContent from "../../modal/advertisingMirror/EditCompanyModalContent";
import {getBackground, getStatusText, getTypeText} from "../../utils/heplers";
import {items} from "../../utils/items";
import CompanyStatisticsModalContent from "../../modal/advertisingMirror/CompanyStatisticsModalContent";
import useAdvertisingMirrorData from "../../hooks/advertisingMirror/useAdvertisingMirrorData";
import dayjs from "dayjs";
import {CopyToClipboard} from "react-copy-to-clipboard";
import useSellerList from "../../../sellers/hooks/useSellerList";
import ConfirmModal from "../../modal/advertisingMirror/ConfirmModal";
import useStopStatus from "../../hooks/advertisingMirror/useStopStatus";
import usePauseStatus from "../../hooks/advertisingMirror/usePauseStatus";
import useStartStatus from "../../hooks/advertisingMirror/useStartStatus";

const {RangePicker} = DatePicker;

export default function AdvertisingMirrorTable() {

    const {
        sellers
    } = useSellerList();

    const {
        advertising,
        allCountPage,
        allCount,
        setOffSet,
        currentPage,
        setCurrentPage,
        currentPageSize,
        onChangePage,
        setCurrentPageSize,
        selectedStatus,
        setSelectedStatus,
        selectedType,
        setSelectedType,
        sellerId,
        setSellerId,
        setSelectedSeller,
        isLoading,
    } = useAdvertisingMirrorData();

    useEffect(() => {
        setCurrentPage(1)
        setOffSet(0)
    }, [allCountPage])

    useEffect(() => {
        // setSellerId(2)
    }, [sellers])

    //modal view
    const [addCompanyOpened, setAddCompanyOpened] = useState<boolean>(false);
    const [editCompanyOpened, setEditCompanyOpened] = useState<boolean>(false);
    const [companyToEdit, setCompanyToEdit] = useState<any>();
    const [companyStatisticsOpened, setCompanyStatisticsOpened] = useState<boolean>(false);
    const [companyStatistics, setCompanyStatistics] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const {
        response: responseStop,
        setId: setIdStop,
        id: stopId,
        isError: isErrorStop,
        error: errorStop,
        isSuccess: isSuccessStop
    } = useStopStatus()

    const {
        response: responseStart,
        setId: setIdStart,
        id: startId,
        isError: isErrorStart,
        error: errorStart,
        isSuccess: isSuccessStart
    } = useStartStatus()

    const {
        response: responsePause,
        setId: setIdPause,
        id: pauseId,
        isError: isErrorPause,
        error: errorPause,
        isSuccess: isSuccessPause
    } = usePauseStatus()

    const handleStop = async (id: any) => {
        await setIdStop(id)

        await setIsModalVisible({
            isOpen: false,
            text: '',
            onPress: () => {}
        });
    };

    const handleRestart = async (id: any) => {
        await setIdStart(id)
        await setIsModalVisible({
            isOpen: false,
            text: '',
            onPress: () => {}
        });
    };

    const handleFinish = async (id: any) => {
        await setIdPause(id)
        await setIsModalVisible({
            isOpen: false,
            text: '',
            onPress: () => {}
        });
    };

    const columns = [
        {
            title: "Тип рекламы",
            dataIndex: "type",
            key: "type",
            width: "11%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {getTypeText(record.type)}
                </div>
            ),
        },
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
            width: "18%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "Баннер",
            dataIndex: "params",
            key: "params",
            width: "18%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {/*{record.banner.map((src: any) => (*/}
                    {/*    <img src={src}></img>*/}
                    {/*))}*/}
                    {
                        text[0]?.nms?.map((src: any) => (
                            <img
                                className={"table-mirror-img"}
                                src={src?.photo}
                            />
                        ))
                    }
                </div>
            ),
        },
        {
            title: "Дата создания",
            dataIndex: "createTime",
            key: "createTime",
            width: "18%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {/*{record.banner.map((src: any) => (*/}
                    {/*    <img src={src}></img>*/}
                    {/*))}*/}
                    {dayjs(
                        text
                    ).format("MM:HH, DD.MM.YYYY")}
                </div>
            ),
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: "11%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {getStatusText(text) || '---'}
                </div>
            ),
        },
        {
            title: "Название предмета",
            dataIndex: "params",
            key: "params",
            width: "18%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {
                        text[0]?.subjectName || '---'
                    }
                </div>
            ),
        },
        {
            title: "Артикул",
            dataIndex: "advertId",
            key: "advertId",
            width: "14%",
            render: (text: any, record: any) => (
                <CopyToClipboard text={`${text}`}
                                 onCopy={() => message.success('Текст скопирован')}>
                    <div
                        className="render-table-cell"
                        style={{background: getBackground(record.status), cursor: 'pointer'}}
                    >
                        {text}
                    </div>
                </CopyToClipboard>

            ),
        },
        {
            title: "Ежедневный бюджет",
            dataIndex: "params",
            key: "params",
            width: "13%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status)}}
                >
                    {text[0]?.dailyBudget || '---'}
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: "4%",
            render: (text: any, record: any) => (
                <div
                    className="render-table-cell"
                    style={{background: getBackground(record.status), width: 30}}
                >
                    <Dropdown
                        menu={getActions(record)}
                        trigger={["click"]}
                        placement={"bottomRight"}
                        overlayClassName={"dropdown-border"}
                    >
                        <MoreOutlined style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const getActions = (company: any) => {
        const status = company?.status;

        const actions = {
            items: items?.map((item: any) => {
                let disabled = false;
                if (status === 7 && (item.key === 'FINISH' || item.key === 'STOP' || item.key === 'RESTART')) {
                    disabled = true; // Disable all buttons
                } else if (status === 9 && item.key === 'RESTART') {
                    disabled = true; // Disable RESTART button for status 9
                } else if (status === 11 && item.key === 'STOP') {
                    disabled = true; // Disable STOP button for status 11
                }
                return {
                    ...item,
                    disabled: disabled,
                };
            }),
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT":
                        setEditCompanyOpened(true);
                        setCompanyToEdit(company);
                        break;
                    case "STOP":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'остановить',
                            onPress: () => handleStop(company)
                        })
                        break;
                    case "RESTART":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'возобновить',
                            onPress: () => handleRestart(company?.advertId)
                        })
                        break;
                    case "FINISH":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'завершить',
                            onPress: () => handleFinish(company?.advertId)
                        })
                        break;
                    case "STATISTICS":
                        setCompanyStatisticsOpened(true);
                        setCompanyStatistics(company);
                        break;
                }
            },
            className: "dropdown-border",

        };
        return actions;
    };

    useEffect(() =>{
        if (isErrorPause && pauseId){
            // @ts-ignore
            message.error(errorPause?.response?.data?.message)
        }
        if (isErrorStart && startId){
            // @ts-ignore
            message.error(errorStart?.response?.data?.message)
        }
        if (isErrorStop && stopId){
            // @ts-ignore
            message.error(errorStop?.response?.data?.message)
        }

    },[isErrorPause, isErrorStart, isErrorStop])

    useEffect(() =>{
        if (isSuccessPause && pauseId){
            message.success('Вы успешно остановили рекламу')
        }
        if (isSuccessStart && startId){
            message.success('Вы успешно возобновили рекламу')
        }
        if (isSuccessStop && stopId){
            message.success('Вы успешно завершили рекламу')
        }

    },[isSuccessPause, isSuccessStart, isSuccessStop])

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                {/*<Col span={11}>*/}
                {/*    <Input className="table-header-input" placeholder={"Поиск"}/>*/}
                {/*</Col>*/}
                <Col span={8}>
                    <Select
                        className="table-header-select table-header-select-mirror"
                        suffixIcon={<img src={selectIcon}/>}
                        defaultValue={1}
                        options={sellers?.map((item: any) => ({
                            value: item?.id,
                            label: item?.company_name
                        }))}
                        onChange={(value) => {
                            setSellerId(value)
                            setSelectedSeller(value)
                        }}
                        placeholder="Выбрать продавца"
                    />
                </Col>
                <Col span={8}>
                    <Select
                        className="table-header-select table-header-select-mirror"
                        suffixIcon={<img src={selectIcon}/>}
                        defaultValue={"10"}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "20", label: "Показать по 20 шт."},
                            {value: "25", label: "Показать по 25 шт."},
                        ]}
                        onChange={(value) => setCurrentPageSize(value)}
                    />
                </Col>
                <Col span={8}>
                    <Button
                        className="table-header-button"
                        type={"primary"}
                        onClick={() => setAddCompanyOpened(true)}
                    >
                        Добавить
                    </Button>
                </Col>
            </Row>

            <Row style={{marginTop: 10}}>
                <Col span={24} style={{background: "#ffffff", borderRadius: 8}}>
                    <div style={{
                        margin: 10,
                        fontSize: 16,
                        textAlign: 'center',
                    }}>
                        Статус РК:
                    </div>
                    <Row>
                        <Col span={6}>
                            <Button
                                className={
                                    selectedStatus === 0
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedStatus(0)}
                            >
                                Все
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button
                                className={
                                    selectedStatus === 9
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedStatus(9)}
                            >
                                Активные
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button
                                className={
                                    selectedStatus === 11
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedStatus(11)}
                            >
                                Приостановленные
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button
                                className={
                                    selectedStatus === 7
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedStatus(7)}
                            >
                                Завершенные
                            </Button>
                        </Col>
                    </Row>
                    <div style={{
                        margin: 10,
                        fontSize: 16,
                        textAlign: 'center',
                    }}>
                        Тип РК:
                    </div>
                    <Row style={{marginBottom: 10}}>
                        <Col style={{width: '20%'}}>
                            <Button
                                className={
                                    selectedType === 0
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedType(0)}
                            >
                                Все
                            </Button>
                        </Col>
                        <Col style={{width: '20%'}}>
                            <Button
                                className={
                                    selectedType === 4
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedType(4)}
                            >
                                Реклама в каталоге
                            </Button>
                        </Col>
                        <Col style={{width: '20%'}}>
                            <Button
                                className={
                                    selectedType === 5
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedType(5)}
                            >
                                Реклама в карточке товара
                            </Button>
                        </Col>
                        <Col style={{width: '20%'}}>
                            <Button
                                className={
                                    selectedType === 6
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedType(6)}
                            >
                                Реклама в поиске
                            </Button>
                        </Col>
                        <Col style={{width: '20%'}}>
                            <Button
                                className={
                                    selectedType === 7
                                        ? "table-tab-button-active"
                                        : "table-tab-button"
                                }
                                onClick={() => setSelectedType(7)}
                            >
                                <div>
                                    Реклама в рекомендациях
                                </div>
                                <div>
                                    на главной странице
                                </div>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Table
                                loading={isLoading}
                                columns={columns}
                                dataSource={advertising}
                                locale={{
                                    emptyText: "Нет данных",
                                    filterTitle: "Фильтр",
                                    filterReset: "Очистить",
                                    filterEmptyText: "Нет фильтров",
                                }}
                                scroll={{x: true}}
                                pagination={{
                                    onChange: (page, pageSize): any => onChangePage(page),
                                    position: ["bottomCenter"],
                                    pageSize: Number(currentPageSize),
                                    total: Number(allCountPage),
                                    showSizeChanger: false,
                                    current: currentPage,
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>

            <Modal
                open={addCompanyOpened}
                closable={false}
                footer={null}
                width={"fit-content"}
            >
                <AddCompanyModalContent onClose={() => setAddCompanyOpened(false)}/>
            </Modal>
            {editCompanyOpened && (
                <Modal
                    open={editCompanyOpened}
                    closable={false}
                    footer={null}
                    width={"fit-content"}
                >
                    <EditCompanyModalContent
                        company={companyToEdit}
                        onClose={() => setEditCompanyOpened(false)}
                    />
                </Modal>
            )}
            {companyStatisticsOpened && (
                <Modal
                    open={companyStatisticsOpened}
                    closable={false}
                    footer={null}
                    width={810}
                >
                    <CompanyStatisticsModalContent
                        company={companyStatistics}
                        onClose={() => setCompanyStatisticsOpened(false)}
                    />
                </Modal>
            )}
        </div>
    );
}
