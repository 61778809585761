import { Col, Row } from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import TopologyTable from "../../features/storage/pages/topology/TopologyTable";

export default function Topology() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <TopologyTable />
            </Col>
        </Row>
    );
}
