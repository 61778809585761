import React from 'react';
import {Button} from 'antd';

interface DownloadButtonProps {
    fileUrl: string;
    title?: string;
    size?: string;
    article?: string;
    isWithQuery: boolean
}

const DownloadButton = ({fileUrl, article, size, isWithQuery = false, title = 'Выгрузить'}: DownloadButtonProps) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        let path = fileUrl

        if (isWithQuery){
            const trimSize = size?.replace(/\s/g, '')
            const trimArticle = article?.replace(/\s/g, '')

            if (isWithQuery) {
                if (trimSize && trimArticle) {
                    path += `&size=${trimSize}&article=${trimArticle}`
                } else if (trimSize) {
                    path += `&size=${trimSize}`
                } else if (trimArticle) {
                    path += `&article=${article?.trim()}`
                }
            } else {
                if (trimSize && trimArticle) {
                    path += `?size=${trimSize}&article=${trimArticle}`
                } else if (trimSize) {
                    path += `?size=${trimSize}`
                } else if (trimArticle) {
                    path += `?article=${trimArticle}`
                }
            }
        }


        console.log(path)

        link.href = path;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            className="button"
            type="primary"
            style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}
            htmlType="submit"
            onClick={handleDownload}
        >
            {title}
        </Button>
    );
};

export default DownloadButton;