import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";

export default function useArticleWbData() {

    const [sellerId, setSellerId] = useState('')
    const [search, setSearch] = useState('')

    const { data: articleWb, ...options } = useQuery(
        [
            "ARTICLE_WB_DATA",
            sellerId,
            search
        ],
        () => backend.financialResult.getArticleWbData(
            sellerId,
            search
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        articleWb,
        setSellerId,
        search,
        setSearch,
        ...options,
    };
}
