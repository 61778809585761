import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useUpdateNeeds = () => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    mutateAsync: update,
    isLoading: updateLoading,
    isError,
    isSuccess,
  } = useMutation(backend.shipments.updateNeeds, {
    async onSuccess() {
      await Promise.all([queryClient.invalidateQueries([`SHIPMENTS_DATA`])]);
    },
  });

  const handleUpdate = useCallback(
    async (values: any, id: string) => {
      try {
        await update({ id: id, json: values });
      } catch (e: any) {
        setErrorMessage("Произошла ошибка при редактировании");
      }
    },
    [update]
  );

  return {
    handleUpdate,
    isLoading: updateLoading,
    isError,
    errorMessage,
    isSuccess
  };
};
