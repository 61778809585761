import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const useUploadShipments = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: onUpload,
        data: uploadData,
        ...options
    } = useMutation(backend.storage.postUploadShipments);

    const handleCreate = useCallback(
        async ({values}: any) => {
            try {
                await onUpload({values});
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.detail);
            }
        },
        [onUpload]
    );

    return {
        handleCreate,
        uploadData,
        errorMessage,
        ...options,
    };
};
