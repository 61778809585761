import { useQuery } from "react-query";
import { backend } from "api";

export default function useCashbackOperationsList() {
    const { data: cashbackOperations, ...options } = useQuery(
        ["CASHBACK_OPERATIONS"],
        () => backend.analytics.getAllOperations(),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            refetchInterval: 5000
        }
    );

    return {
        cashbackOperations,
        ...options,
    };
}
