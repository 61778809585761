import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createApi } from "api";
import { createApiKiz } from "./features/kiz/api";
import {createApiKizExtraInfo} from "./features/kiz/apiExtraInfo";

export const api = createApi();
export const apiKiz = createApiKiz();
export const apiKizFullInfo = createApiKizExtraInfo();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
