import React from 'react';
import {addSpacesToNumber} from "../help/addSpacesToNumber";
import {Col} from "antd";

const TableCardSimple = ({
                             title,
                             value,
                             diff,
                             percent,
                             sub,
                             fsTitle = 13,
                             fsValue = 22,
                             fsDiff = 20,
                             fsSub = 10
                         }: any) => {

    return (
        <div className="fina-result-main-card">
            <div className="fina-result-main-title" style={{fontSize: fsTitle}}>
                {title}
                {sub &&
                <span style={{fontSize: fsSub}}> {sub}</span>
                }
            </div>
            <div className="fina-result-main-card-value">
                <div style={{fontSize: fsValue}}>
                    {value}{percent && '%'}
                </div>
                <div className="fina-result-main-card-diff" style={{fontSize: fsDiff}}>
                    {diff}
                </div>
            </div>
        </div>
    );
};

export default TableCardSimple;