import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useState} from "react";

export default function useDeclarationDataOld() {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");

    const { data: declaration, ...options } = useQuery(
        ["DECLARATION_DATA", currentPage, currentPageSize],
        () =>
            backend.declaration.getDeclarationsDataOlc(
                currentPage.toString(),
                currentPageSize
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        declaration,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
