import React from "react";
import BiderLogsTable from "../../features/advertising-mirror/pages/bider-logs/BiderLogsTable";

export default function BiderLogsTab() {
    return (
        <div style={{marginTop: "20px"}}>
            <BiderLogsTable/>
        </div>
    );
}
