import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Input,
    InputNumber, InputRef, message,
    Row,
    Select, Space,
    Switch,
} from "antd";
import {
    getAnalyticsProductSizesByArticle, getAnalyticsProductSizesByProductId, getAnalyticsProductSizesByProductIdFullInfo,
} from "api/analytics";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";
import {parseDateFromMiddle} from "../helpers/parseDateString";
import {useAddCostPrice} from "../../costPrice/hooks/useAddCostPrice";

const {Option} = Select;

export default function CostPriceModal({
                                           productId,
                                           articule,
                                           sellerId,
                                           productSizeId,
                                           onClose,
                                       }: any) {


    const [form] = Form.useForm<{}>();
    const [selectedArticle, setSelectedArticle] = useState<any>(null)
    const [sizesData, setSizesData] = useState<any>([])

    console.log(sizesData)

    const {
        handleCreate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useAddCostPrice()

    const onFinish = (value: any) => {

        const parsedDate = parseDateFromMiddle(value?.numberDT);

        if (parsedDate) {
            console.log({
                productSizeId: productSizeId ? productSizeId : value?.productSizeId,
                investorId: sellerId,
                cost: value?.cost,
                count: value?.count,
                numberDT: value?.numberDT,
                appearedFrom: parsedDate,
                productId: productId
            })
            handleCreate({
                productSizeId: productSizeId ? productSizeId : value?.productSizeId,
                investorId: sellerId,
                cost: Number(value?.cost),
                count: Number(value?.count),
                numberDT: value?.numberDT,
                appearedFrom: parsedDate,
                productId: productId
            })
        } else {
            message.error('Вы ввели неверный формат номера ДТ');
        }
    }

    useEffect(() => {
        getAnalyticsProductSizesByProductIdFullInfo(productId).then((data) => {
            setSizesData(data)

        }).catch((e) => {
            console.log(e?.response?.data?.message)
            if (e?.response?.data?.message === 'Product not found') {

            }
        });
    }, [selectedArticle])

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно добавили новую себестоимость')
            form.resetFields()
            onClose()
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
    }, [isError])


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Редактировать себестоимость"}
                onClose={() => {
                    form.resetFields()
                    onClose()
                }}
            />
            <Form
                onFinish={(values) => onFinish(values)}
                form={form}
                layout={"vertical"}
            >
                {!productSizeId &&
                <Form.Item
                    rules={[{required: true}]}
                    name={"productSizeId"}
                    label={"Размер"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedArticle}
                        placeholder="Размер"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        // onSearch={(value: any) => setSearch(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedArticle(e)
                        }}
                    >
                        {sizesData?.slice(0, 10)?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.id}>
                                    {option?.size}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                }

                <Form.Item
                    rules={[{required: true}]}
                    name={"cost"}
                    label={"себестоимость (руб.)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"count"}
                    label={"Кол-во прихода (шт)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"numberDT"}
                    label={"Номер ДТ"}
                >
                    <Input/>
                </Form.Item>

                <Col style={{display: "flex", gap: "15px"}}>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        htmlType={"submit"}
                        loading={isLoading}
                    >
                        Сохранить
                    </Button>
                    <Button
                        type={"primary"}
                        ghost
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        onClick={() => {
                            form.resetFields()
                            onClose()
                        }}
                    >
                        Отмена
                    </Button>
                </Col>
            </Form>
        </div>
    );
}
