import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";

export default function useChartData() {

    const [sellerId, setSellerId] = useState<any>('')

    const { data: chartData, ...options } = useQuery(
        [
            "CHART_FIN_RES_DATA",
            sellerId
        ],
        () => backend.financialResult.getChartFinResData(
            sellerId
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        chartData,
        setSellerId,
        ...options,
    };
}
