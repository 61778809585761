import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useSellerUpdate = (id: number) => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading: updateLoading,
        isError,
    } = useMutation(backend.seller.updateSeller, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`SELLER_LIST`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, handleCloseModal: any) => {
            try {
                let body = { ...values };
                if (values.userId) {
                    body.userId = parseInt(values.userId);
                }

                await update({ id: id.toString(), ...body });
                handleCloseModal();
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при редактировании продавца");
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: updateLoading,
        isError,
        errorMessage,
    };
};
