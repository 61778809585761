import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { backend } from "api";

export const useAnalyticsNoteUpdate = () => {
    const queryClient = useQueryClient();

    const {
        mutateAsync: update,
        isLoading,
        isError,
        ...options
    } = useMutation(backend.analytics.updateAnalyticsNote, {
        async onSuccess() {
            await Promise.all([
                queryClient.invalidateQueries([`ANALYTICS_NOTE_DATA`]),
            ]);
        },
    });

    const handleUpdate = useCallback(
        async (id: any, values: any) => {
            try {
                let body = { ...values, id };
                await update(body);
            } catch (e: any) {
                console.error(e);
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: isLoading,
        isError,
        ...options
    };
};
