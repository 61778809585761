import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useSellerDelete} from "../../sellers/hooks/useSellerDelete";
import {useGuideAdd} from "../hooks/guide/useGuideAdd";

export default function AddGuide({onClose}: any) {

    const [form] = Form.useForm();

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useGuideAdd()

    const onFinish = (values: any) => {
        handleCreate(values)
    };

    useEffect(() => {
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно добавили тип оплаты')
            onClose()
            form.resetFields()
        }
    }, [isSuccessCreate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавить тип оплаты"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Row
                className={"container"}
            >
                <Col span={24} >
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Введите название типа оплаты"}
                            name='name'
                            rules={[
                                {
                                    required: true,
                                    message: "Поле обязательно для заполнения",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите описание для данного типа оплаты"}
                            name='description'
                            rules={[
                                {
                                    required: true,
                                    message: "Поле обязательно для заполнения",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                loading={createLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Добавить
                            </Button>
                            <Button
                                onClick={() =>{
                                    form.resetFields()
                                    onClose()
                                }}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
