import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const usePostMinusPhrase = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [id, setId] = useState<string>('');

    const {
        mutateAsync: postMinusPhrase,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
    } = useMutation(backend.product.postMinusPhrase, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries(["PLUS_MINUS_PHRASE_DATA", id])]);
            await Promise.all([queryClient.invalidateQueries(["PRODUCTS_ALL_DATA"])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any) => {
            try {
                await postMinusPhrase(values);
                await queryClient.invalidateQueries(["PLUS_MINUS_PHRASE_DATA", id]);
                await queryClient.invalidateQueries(["PRODUCTS_ALL_DATA"]);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [postMinusPhrase]
    );

    return {
        isSuccessCreate,
        handleCreate,
        setId,
        createLoading,
        isErrorCreate,
        errorMessage,
    };
};
