import { Col, Row } from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import DeclarationsTable from "../../features/kiz/pages/declaration/DeclarationsTable";
import AcceptanceTable from "../../features/kiz/pages/acceptance/AcceptanceTable";

export default function AcceptanceTab() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <AcceptanceTable />
            </Col>
        </Row>
    );
}
