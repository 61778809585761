import { Col, Row } from 'antd'
import React from 'react'
import ActionItemsTable from '../../features/actions/ActionItemsTable'

export default function ActionItemsData() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<ActionItemsTable />
			</Col>
		</Row>
	)
}
