import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import plusIcon from 'assets/images/bluePlus.svg'
import circleIcon from 'assets/images/gtd-circle-icon.svg'
import AddTsdFileButton from "../../../document-mirror/components/AddTSDFileButton";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import {useCreateKizDocumentToGTD} from "../../hooks/gtd/useCreateKizDocumentToGTD";


export default function AddKizFilesModal({onClose, gtdId}: any) {

    const fileInputRef = useRef<any>(null);

    // const [files, setFiles] = useState<any>([])

    // const handleFileSelect = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //     }
    // };
    //
    // const handleFileChange = (event: any, item: any) => {
    //
    //     let find = files?.map((elem: any) => elem?.id === item?.id ? ({
    //         ...elem,
    //         fileName: event.target.files[0]?.name
    //     }) : elem)
    //
    //     setFiles(find)
    // };

    // const handleSelectFile = (event: any) => {
    //     console.log(event.target.files[0])
    //     setFiles([...files, {id: Math.random(), fileName: event.target.files[0]?.name}])
    // };

    const {
        handleCreate,
        createData,
        errorMessage,
        isSuccess,
        isError,
        isLoading
    } = useCreateKizDocumentToGTD()

    const [form] = Form.useForm();
    const [file, setFile] = useState<any>('')

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('file', file);

        if (!file){
            return message.warning('Пожалуйста, выберите файл')
        }
        var body: any = {};

        if (values?.title) {
            body["title"] = values?.title;
        }
        if (values?.gtin) {
            body["gtin"] = values?.gtin;
        }
        if (values?.description) {
            body["description"] = values?.description;
        }
        if (values?.filename) {
            body["filename"] = values?.filename;
        }
        console.log(body)

        handleCreate({...body, ccd_id: gtdId}, formData)
    };

    useEffect(() =>{
        if (isError){
            message.error(errorMessage)
        }
        if (isSuccess){
            message.success("Вы успешно добавили ГТД")
            form.resetFields()
            setFile('')
            onClose()
        }
    },[isError, isSuccess])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавить КИЗ файлы"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Введите название"}
                            name='title'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите gtin"}
                            name='gtin'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите описание (необязательно)"}
                            name='description'
                        >
                            <TextArea style={{height: 140}} rows={15}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите название файла"}
                            name='filename'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <AddTsdFileButton
                            title={'Добавить КИЗ файл'}
                            handleFileSelect={handleFileSelect}
                            fileInputRef={fileInputRef}
                            file={file}
                            handleFileChange={handleFileChange}
                        />

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                loading={isLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Добавить
                            </Button>
                            <Button
                                onClick={() =>{
                                    form.resetFields()
                                    onClose()
                                }}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
