import {Button, Col, DatePicker, Form, Input, message, Row, Select} from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import {ReactComponent as addPhotoIcon} from "assets/images/add-photo-icon.svg";
import React, {useEffect, useState} from "react";
import {usePostProductData} from "../../hooks/gtd/usePostProductData";
import useGTDData from "../../hooks/gtd/useGTDData";
import selectIcon from "../../../../assets/images/select-icon.svg";
import useArticleByGTD from "../../hooks/productArrival/useArticleByGTD";
import useSizesByGTDAndArticle from "../../hooks/productArrival/useSizesByGTDAndArticle";
import {usePostProductArrival} from "../../hooks/productArrival/usePostProductArrival";

const {Option} = Select;

export default function AddProductArrivalModalContent({onClose}: any) {

    const [form] = Form.useForm();
    const articleValue = Form.useWatch("article", form);
    const ccdidValue = Form.useWatch("ccdid", form);

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    } = usePostProductArrival()

    const {
        gtdData,
        searchText,
        setSearchText,
        setSelectedStatus,
    } = useGTDData()

    const {
        articleDataByGTDId,
        setIdGTD,
    } = useArticleByGTD()

    const {
        sizesDataBuGTDAndArticle,
        setArticle,
        setIdGTD: setIdGTDForSizes,
    } = useSizesByGTDAndArticle()

    const onFinish = (values: any) => {

        var body: any = {};

        const ccdid = values?.ccdid


        if (values?.article) {
            body["article"] = values?.article;
        }

        if (values?.size) {
            body["size"] = values?.size;
        }

        if (values?.amount) {
            body["amount"] = Number(values?.amount);
        }

        handleCreate(body, ccdid)
    }

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно создани новый товар')
            form.resetFields()
            onClose()
        }
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isSuccessCreate, isErrorCreate])

    useEffect(() => {
        setSelectedStatus('ccd_ready_for_delivery')
    }, [])

    useEffect(() =>{
        setIdGTD(ccdidValue)
        setIdGTDForSizes(ccdidValue)
        form.setFieldValue('article', '')
    },[ccdidValue])

    useEffect(() =>{
        setArticle(articleValue)
        form.setFieldValue('size', '')
    },[articleValue])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Карточка товара"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                            label={"Выберите ГТД"}
                            name={'ccdid'}
                            rules={[{required: true}]}
                        >
                            <Select
                                showSearch
                                onSearch={(e: any) => setSearchText(e)}
                                filterOption={false}
                                suffixIcon={<img src={selectIcon} alt={""}/>}
                            >
                                {gtdData?.data?.map((option: any) => {
                                    return (
                                        <Option key={option?.id} value={option?.id}>
                                            {option.title}
                                        </Option>
                                    );
                                })}
                            </Select>

                        </Form.Item>

                        <Form.Item
                            label={"Введите ариткул"}
                            name={'article'}
                            rules={[{required: true}]}
                        >
                            <Select
                                showSearch
                                onSearch={(e: any) => setSearchText(e)}
                                filterOption={false}
                                suffixIcon={<img src={selectIcon} alt={""}/>}
                            >
                                {articleDataByGTDId?.map((option: any) => {
                                    return (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={"Введите размер"}
                            name={'size'}
                            rules={[{required: true}]}
                        >
                            <Select
                                showSearch
                                onSearch={(e: any) => setSearchText(e)}
                                filterOption={false}
                                suffixIcon={<img src={selectIcon} alt={""}/>}
                            >
                                {sizesDataBuGTDAndArticle?.map((option: any) => {
                                    return (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={"Введите количество"}
                            name={'amount'}
                            rules={[{required: true}]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col style={{display: "flex", gap: "15px", width: '100%'}}>
                        <Button
                            loading={createLoading}
                            type={"primary"}
                            className={"button"}
                            htmlType={"submit"}
                            style={{fontSize: "12px", width: "100%"}}
                        >
                            Создать
                        </Button>
                        <Button
                            type={"primary"}
                            ghost
                            className={"button"}
                            style={{fontSize: "12px", width: "100%"}}
                            onClick={() =>{
                                form.resetFields()
                                onClose()
                            }}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
