import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {Button, Col, message, Modal, Spin} from "antd";
import trashBucket from '../../../../assets/images/trash-bucket.svg'
import useDeclarationCustomedFilesById from "../../hooks/declaratiion/useDeclarationCustomedFilesById";
import {LoadingOutlined} from "@ant-design/icons";
import {useUploadEmission} from "../../hooks/emission/useUploadEmission";
import {useUploadDeclarationCustomedFile} from "../../hooks/declaratiion/useUploadDeclarationCustomedFile";
import {downloadDeclarationFileByHandle} from "../../help/downloadFile";
import SuccessUploadModal from "../SuccessUploadModal";
import SuccessUploadCustomedFileModal from "./SuccessUploadCustomedFileModal";
import {useDeleteDeclarationCustomedFile} from "../../hooks/declaratiion/useDeleteDeclarationCustomedFile";

export default function CustomsClearanceFilesModal({onClose, id}: any) {

    const {
        files,
        setId,
        isLoading,
    } = useDeclarationCustomedFilesById()

    const {
        handleDelete,
        errorMessage,
        setId: setIdForDelete,
        isError
    } = useDeleteDeclarationCustomedFile()

    const {
        handleCreate,
        uploadData,
        isSuccess: isSuccessUploadFile,
        isError: isErrorUploadFile,
        errorMessage: errorMessageUploadFile
    } = useUploadDeclarationCustomedFile()

    useEffect(() => {
        setId(id)
        setIdForDelete(id)
    }, [id])

    const fileInputRef = useRef<any>(null);
    const [answerModal, setAnswerModal] = useState<any>(false);

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        message.success('Файл начал загрузку. Не закрывайте страницу')

        handleCreate(formData, id, file?.name)

    };

    const onLoad = (handle: any) =>{
        downloadDeclarationFileByHandle(
            `${process.env.REACT_APP_API_URL_FILE_STORAGE}archive/file`,
            handle
        )
    }

    const onDelete = (handle: any) =>{
        handleDelete(
            id,
            handle
        )
    }

    useEffect(() =>{
        if (isError){
            message.error(errorMessage)
        }
    },[isError])

    useEffect(() =>{
        if (isErrorUploadFile){
            message.error(errorMessageUploadFile)
        }
    },[isErrorUploadFile])

    useEffect(() =>{
        if (isSuccessUploadFile){
            setAnswerModal(true)
            message.success('Файл успешно загружен')
        }
    },[isSuccessUploadFile])


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Файлы растаможки"} onClose={onClose}/>

            {isLoading
                ?
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
                :
                files?.result?.length === 0
                    ? <div>Нет файлов растоможки</div>
                    :
                    <div className="customs-clearance-ciles-modal-wrap">
                        {
                            files?.result?.map((item: any) =>
                                <div className="customs-clearance-ciles-modal-item">
                                    <div className="customs-clearance-ciles-modal-item-name" onClick={() => onLoad(item.handle)}>
                                        {item.filename}
                                    </div>
                                    <div className="customs-clearance-ciles-modal-item-delete" onClick={() => onDelete(item.handle)}>
                                        <img src={trashBucket} alt=""/>
                                        Удалить
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
            <Button
                className="table-header-button"
                type={"primary"}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={handleFileSelect}
            >
                Добавить
            </Button>
            <input
                type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
            />

            <Modal
                open={answerModal}
                closable={false}
                footer={null}
                width={600}
            >
                <SuccessUploadCustomedFileModal
                    backendData={uploadData}
                    onClose={() => setAnswerModal(false)}
                />
            </Modal>
        </div>
    );
}
