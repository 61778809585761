import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSellerList() {

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [search, setSearch] = useState<any>('')
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: sellers, ...options } = useQuery(
        [
            "SELLER_LIST",
            currentPage,
            debouncedSearch
        ],
        () => backend.seller.getSellerList(
            currentPage.toString(),
            debouncedSearch
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        sellers,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
        ...options,
    };
}
