import { useQuery } from "react-query";
import { backend } from "api";

export default function useCashbackUserHistory(id?: string) {
    const { data: userHistory, ...options } = useQuery(
        ["CASHBACKS_USER_HISTORY", id],
        () => backend.analytics.getCashbackContractsByUserId(id),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        userHistory,
        ...options,
    };
}
