import { Input, MenuProps } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as filterSortIcon } from "assets/images/filter-sort-icon.svg";
import { ReactComponent as searchIcon } from "assets/images/search-icon.svg";
import React from "react";

export default function useCashbacksUsersTableFilters() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По номеру",
            key: "id",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "id:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "id:desc",
                },
            ],
        },
        {
            label: "По аналитику",
            key: "analytic",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "analytic:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "analytic:desc",
                },
            ],
        },
        {
            label: "По кешбэку",
            key: "cashback",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "cashback:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "cashback:desc",
                },
            ],
        },
        {
            label: "По одобренности",
            key: "agreed",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "agreed:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "agreed:desc",
                },
            ],
        },
        {
            label: "По управлению",
            key: "managed",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "managed:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "managed:desc",
                },
            ],
        },
    ];

    return { filterMenuItems };
}
