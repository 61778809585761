import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    InputRef, message,
    Row,
    Select,
    Space,
    Switch,
} from "antd";
import {
    getAnalyticsProductSizesByProductId,
} from "api/analytics";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {useDebounce} from "use-debounce";
import {useAnalyticsCashbackCreate} from "../hooks/useAnalyticsCashbackCreate";
import {getKeywordsByProductArticleV2, getProductsDataById} from "api/product";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useArticleWbData from "../../financial-results/hooks/useArticleWbData";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";

import("dayjs/locale/ru");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');
dayjs.locale('ru');

const {TextArea} = Input;
const {Option} = Select;

export default function AddCashbackModalContent({onClose}: any) {

    const {
        articleWb,
        setSellerId,
        search,
        setSearch,
    } = useArticleWbData()

    const [selectedArticle, setSelectedArticle] = useState<any>(null)
    const [selectedProduct, setSelectedProduct] = useState<any>(null)
    const [debouncedArticleSelectedProduct] = useDebounce(selectedProduct, 1000);

    const [publishDate, setPublishDate] = useState<any>('');

    const [cashbackSwitchChecked, setCashbackSwitchChecked] = useState<boolean>(false);
    const [chooseSizeSwich, setChooseSizeSwich] = useState<boolean>(false);

    const [form] = Form.useForm<{}>();

    const articleValue = Form.useWatch("article", form);

    const [debouncedArticleValue] = useDebounce(articleValue, 1000);

    const [product, setProduct] = useState<Product>();
    const [productSizes, setProductSizes] = useState<any[]>();
    const [isExistProductSizes, setIsExistProductSizes] = useState<Boolean>(true);
    const [productSizesFilter, setProductSizesFilter] = useState<any[]>();

    const [productSizesSelected, setSelectedProductSizes] = useState<any[]>();
    const [selectError, setSelectError] = useState(false);

    const [keywordsToSelect, setKeywordsToSelect] = useState<any[]>([]);

    const {
        handleCreate,
        isLoading,
        isSuccess,
        isError,
        errorMessage
    } = useAnalyticsCashbackCreate();

    const inputRef = useRef<InputRef>(null);

    const [newKeyword, setNewKeyword] = useState("");

    const addItem = (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        e.preventDefault();
        setKeywordsToSelect([
            ...(keywordsToSelect as any[]),
            {label: newKeyword, value: newKeyword},
        ]);

        setNewKeyword("");

        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const onNewKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewKeyword(event.target.value);
    };

    useEffect(() => {
        if (!debouncedArticleValue || !debouncedArticleSelectedProduct) {
            return;
        }

        getProductsDataById(debouncedArticleSelectedProduct).then((data) => {
            console.log(
                "🚀 ~ file: AddCashbackModalContent.tsx:33 ~ getAnalyticsProductByArticle ~ data:",
                data
            );

            setProduct(data);
        }).catch(() => console.log('error'));

        getKeywordsByProductArticleV2(debouncedArticleValue).then((data) => {
            let keywords = data.map((elem: any) => ({
                label: elem.title,
                value: elem.title,
            }));
            setKeywordsToSelect(keywords);
        }).catch(() => console.log('error'));

        //
        // getAnalyticsProductSizesByArticle(debouncedArticleValue).then((data) => {
        //     var filter = (data as any).map((t: any) => ({
        //         label: t.size,
        //         value: t.size,
        //         amount: t.amount
        //     }));
        //
        //     console.log(data)
        //
        //     const all = {
        //         label: 'Все товары',
        //         value: 'all',
        //         amount: filter.reduce((min: any, obj: any) => {
        //             const amount = obj.amount;
        //             return amount < min ? amount : min;
        //         }, Infinity)
        //     }
        //
        //     setProductSizes([all, ...filter]);
        //     setProductSizesFilter([all, ...filter]);
        //     setIsExistProductSizes(true)
        // }).catch((e) => {
        //     console.log(e?.response?.data?.message)
        //     if (e?.response?.data?.message === 'Product not found'){
        //         setIsExistProductSizes(false)
        //     }
        // });

        getAnalyticsProductSizesByProductId(debouncedArticleSelectedProduct).then((data: any) => {
            var filter = data.map((t: any) => ({
                label: t,
                value: t
                // amount: t.amount
            }));

            setProductSizes(filter);
            setProductSizesFilter(filter);
            setIsExistProductSizes(true)
        }).catch((e) => {
            console.log(e?.response?.data?.message)
            if (e?.response?.data?.message === 'Product not found') {
                setIsExistProductSizes(false)
            }
        });

    }, [debouncedArticleValue, debouncedArticleSelectedProduct]);


    const onCreate = (values: any, onClose: any) => {
        let updatedValues = {...values};
        console.log(
            "🚀 ~ file: AddCashbackModalContent.tsx:69 ~ onCreate ~ updatedValues:",
            updatedValues
        );

        var body: any = {};

        body["name"] = updatedValues.name;
        body["daysAction"] = updatedValues.daysAction;

        body["cashbackType"] = cashbackSwitchChecked
            ? "fixed"
            : "percentage";
        body["cashbackValue"] = updatedValues.cashbackValue;

        body["publishText"] = updatedValues.publishText;
        body["actionText"] = updatedValues.actionText;

        // body["publishDate"] = dayjs(updatedValues.publishDate)
        //     .tz('Europe/Moscow')
        //     .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        body["publishDate"] = dayjs(publishDate).format('YYYY-MM-DD HH:mm');

        body["productId"] = product?.id ?? -1;

        body["wasPositionAt"] = 0;
        body["wasPageAt"] = 0;

        body["keyword"] = values.keyword;

        console.log(
            "🚀 ~ file: AddCashbackModalContent.tsx:104 ~ body[]=updatedValues.keys.map ~ updatedValues:",
            Object.keys(updatedValues)
        );

        if (chooseSizeSwich) {
            body["items"] = (productSizesSelected ?? []).map((item) => {
                return {
                    size: item,
                    count: updatedValues[item],
                };
            });
        } else {
            body["maxItemsGiven"] = values.maxItemsGiven
            body["items"] = [];
        }


        body["condition1"] = updatedValues.condition1
        body["condition2"] = updatedValues.condition2
        body["condition3"] = updatedValues.condition3
        body["condition4"] = updatedValues.condition4
        body["condition5"] = updatedValues.condition5

        if (!body["name"]) {
            return;
        }

        console.log(updatedValues.publishText)

        // if (body["items"].length === 0) {
        //     return;
        // }

        handleCreate(body, onClose);
    };

    useEffect(() => {
        if (isSuccess) {
            form.resetFields()
        }
    }, [isSuccess])


    const handleSelectChange = (values: any) => {
        if (productSizesSelected?.includes('all')) {
            setSelectedProductSizes(['all']);
        } else {
            setSelectedProductSizes(values);
        }
        setSelectError(values.length === 0);
    };


    function removeHtmlCss(inputString: any) {
        var cleanString = inputString.replace(/<.*?>/g, '');
        return cleanString;
    }

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
    }, [isError])

    return (
        <div
            className={"modal-wrapper add-cashback-content"}
            style={{padding: "30px"}}
        >
            <ModalHeader title={"Добавить акцию"} onClose={() => {
                form.resetFields()
                onClose()
            }}/>

            <Form
                onFinish={(values) => onCreate(values, onClose)}
                form={form}
                layout={"vertical"}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: 'Поле обазательно для воода'}]}
                            name={"name"}
                            label={"Введите имя акции"}
                        >

                            <Input style={{fontWeight: 600}}/>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: 'Поле обазательно для воода'}]}
                            name={"article"}
                            label={"Введите номенклатуру товара, передаваемого в акцию"}
                        >
                            {/*<Input/>*/}
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                value={selectedArticle}
                                placeholder="Артикул"
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => setSearch(value)}
                                onChange={(e: any, option: any) => {
                                    setSelectedArticle(e)
                                    setSelectedProduct(option?.key)
                                }}
                            >
                                {articleWb?.slice(0, 10)?.map((option: any) => {
                                    return (
                                        <Option key={option?.id} value={option?.nmId}>
                                            {option.nmId}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <div
                            className={"modal-switch-wrapper"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                            }}
                        >
                            <div className={"modal-form-label"}>Выбор размеров:</div>
                            <div
                                className={`modal-switch-label ${
                                    !chooseSizeSwich ? "active" : ""
                                }`}
                            >
                                Все
                            </div>
                            <Form.Item
                                name={"cashbackTypeBoolean"}
                                className={"no-mb"}
                            >
                                <Switch
                                    checked={chooseSizeSwich}
                                    onChange={(e) => setChooseSizeSwich(e)}
                                />
                            </Form.Item>
                            <div
                                className={`modal-switch-label ${
                                    chooseSizeSwich ? "active" : ""
                                }`}
                            >
                                По размерно
                            </div>
                        </div>

                        {chooseSizeSwich
                            ?
                            <>
                                <Form.Item
                                    name="sizes"
                                    rules={[{required: true}]}
                                    label={"Выберите размеры, которые будут участвовать в акции"}
                                >
                                    <Select
                                        onFocus={() => setSelectError(false)}
                                        onBlur={() => setSelectError(false)}
                                        onChange={(values) => handleSelectChange(values)}
                                        mode="multiple"
                                        allowClear
                                        options={productSizesFilter}
                                        style={{
                                            borderColor: selectError ? 'red !important' : '',
                                        }}
                                    />
                                </Form.Item>

                                {(productSizesSelected ?? []).map((item: any) => {
                                    return (
                                        <Form.Item
                                            rules={[
                                                // ({getFieldValue}) => ({
                                                //     validator(_, value) {
                                                //         const maxValue = productSizesFilter?.find(item1 => item1.value == item)?.amount;
                                                //         if (value <= maxValue) {
                                                //             return Promise.resolve();
                                                //         }
                                                //         return Promise.reject('Введено некорректное количество');
                                                //     },
                                                // }),
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (!isExistProductSizes) {
                                                            return Promise.reject('Продукт не существует');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                                {required: true, message: 'Поле обазательно для воода'}
                                            ]}
                                            name={item}
                                            label={`Введите кол-во товара ${item} размера`}
                                        >
                                            <InputNumber style={{width: "100%"}}/>
                                        </Form.Item>
                                    );
                                }) ?? <></>}
                            </>
                            :
                            <Form.Item
                                rules={[
                                    // ({getFieldValue}) => ({
                                    //     validator(_, value) {
                                    //         const maxValue = productSizesFilter?.find(item1 => item1.value == 'all')?.amount;
                                    //         if (value <= maxValue) {
                                    //             return Promise.resolve();
                                    //         }
                                    //         return Promise.reject('Введено некорректное количество');
                                    //     },
                                    // }),
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!isExistProductSizes) {
                                                return Promise.reject('Продукт не существует');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                    {required: true, message: 'Поле обазательно для воода'}
                                ]}
                                name={'maxItemsGiven'}
                                label={`Введите кол-во всех товаров размеров`}
                            >
                                <InputNumber style={{width: "100%"}} type={'number'}/>
                            </Form.Item>

                        }


                        <Form.Item
                            rules={[{required: true, message: 'Поле обазательно для воода'}]}
                            name={"keyword"}
                            label={"Введите ключ, по которому будут искать товар"}
                        >
                            <Select
                                allowClear
                                options={keywordsToSelect}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: "8px 0"}}/>
                                        <Space style={{padding: "0 8px 4px"}}>
                                            <Input
                                                placeholder="Введите новое ключевое слово"
                                                ref={inputRef}
                                                value={newKeyword}
                                                onChange={onNewKeywordChange}
                                            />
                                            <Button type="text" block onClick={addItem}>
                                                Добавить
                                            </Button>
                                        </Space>
                                    </>
                                )}
                            />
                        </Form.Item>
                        <div
                            className={"modal-switch-wrapper"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                            }}
                        >
                            <div className={"modal-form-label"}>Возвращаем:</div>
                            <div
                                className={`modal-switch-label ${
                                    !cashbackSwitchChecked ? "active" : ""
                                }`}
                            >
                                Процент
                            </div>
                            <Form.Item
                                name={"cashbackTypeBoolean"}
                                className={"no-mb"}
                            >
                                <Switch
                                    checked={cashbackSwitchChecked}
                                    onChange={(e) => setCashbackSwitchChecked(e)}
                                />
                            </Form.Item>
                            <div
                                className={`modal-switch-label ${
                                    cashbackSwitchChecked ? "active" : ""
                                }`}
                            >
                                Фиксированную сумму
                            </div>
                        </div>
                        <Form.Item
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const maxValue = cashbackSwitchChecked ? 9999999 : 100;
                                        if (value <= maxValue) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Введено некорректное количество');
                                    },
                                }),
                                {required: true, message: 'Поле обазательно для воода'}
                            ]}
                            name={"cashbackValue"}
                            label={"Введите значение возврата"}
                        >
                            <InputNumber style={{width: "100%"}}/>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: 'Поле обазательно для воода'}]}
                            name={"publishDate"}
                            label={"Введите/выберите дату, когда запустить акцию"}
                        >
                            <DatePicker
                                showTime
                                placeholder={""}
                                format={"DD.MM.YYYY HH:mm"}
                                style={{width: "100%"}}
                                onChange={(e) => setPublishDate(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            rules={
                                [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 999,
                                        message: 'Минимальное количество дней: 1, максимальное количество дней: 999'
                                    },
                                    {required: true, message: 'Поле обазательно для воода'}
                                ]
                            }
                            name={"daysAction"}
                            label={"Введите кол-во дней, за которые выполнится акция"}
                        >
                            <InputNumber style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    validator: (rule, value, callback) => {
                                        const cleanValue = removeHtmlCss(value); // Удаление HTML и CSS из текста
                                        if (cleanValue.length > 574) {
                                            callback('Максимальная длина 574 символов');
                                        } else if (!cleanValue.trim()) {
                                            callback('Поле обязательно для ввода');
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ]}
                            name={"publishText"}
                            label={"Введите текст рекламы акции кешбека для публикации"}
                        >
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item
                            name={"actionText"}
                            label={"Введите текст условия акции кешбека"}
                            rules={[
                                {
                                    validator: (rule, value, callback) => {
                                        const cleanValue = removeHtmlCss(value); // Удаление HTML и CSS из текста
                                        if (cleanValue.length > 574) {
                                            callback('Максимальная длина 574 символов');
                                        } else if (!cleanValue.trim()) {
                                            callback('Поле обязательно для ввода');
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ]}
                            // rules={[
                            //     {
                            //         max: 574,
                            //         message: 'Максимальная длина 574 символов',
                            //     },
                            //     {required: true, message: 'Поле обазательно для воода'}
                            // ]}
                        >
                            {/*<TextArea style={{height: "auto !important"}} rows={2}/>*/}
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item
                            name={"condition1"}
                            label={"Введите условие №1"}
                        >
                            <ReactQuill/>
                        </Form.Item>
                        <Form.Item
                            name={"condition2"}
                            label={"Введите условие №2"}
                        >
                            <ReactQuill/>
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={"condition3"} label={"Введите условие №3"}>
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item name={"condition4"} label={"Введите условие №4"}>
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item name={"condition5"} label={"Введите условие №5"}>
                            <ReactQuill/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={"center"} style={{marginTop: "10px"}}>
                    <Col style={{display: "flex", gap: "15px"}}>
                        <Button
                            loading={isLoading}
                            type={"primary"}
                            className={"button"}
                            htmlType={"submit"}
                            style={{fontSize: "12px", width: "222px"}}
                        >
                            Добавить
                        </Button>
                        <Button
                            type={"primary"}
                            ghost
                            className={"button"}
                            style={{fontSize: "12px", width: "222px"}}
                            onClick={() => {
                                form.resetFields()
                                onClose()
                            }}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
