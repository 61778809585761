import {useQuery} from "react-query";
import {backend} from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";
import {Checkbox, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useProductList from "../../product/hooks/useProductList";
import {getLaboratoryStatusesData} from "../../../api/laboratory";

export default function useStatusesData() {

  const {data: statusesData, ...options} = useQuery(
    [
      "LABORATORY_STATUSES_LIST",
    ],
    () => backend.laboratory.getLaboratoryStatusesData(

    ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    statusesData,
    ...options
  };
}
