import {Col, Row} from "antd";
import React from "react";
import OrdersFromChinaTable from "../../features/ordersFromChina/OrdersFromChinaTable";
import ExpensesTable from "../../features/operatingExpenses/ExpensesTable";

export default function ExpensesTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col span={24}>
                <ExpensesTable/>
            </Col>
        </Row>
    );
}
