import {
    Alert,
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import useDeclarationData from "../hooks/declaratiion/useDeclarationData";
import {LoadingOutlined} from "@ant-design/icons";
import {useDeclarationDelete} from "../hooks/declaratiion/useDeclarationDelete";
import {useKizesClear} from "../hooks/useKizesClear";
import useDeclarationDataOld from "../hooks/oldDeclaration/useDeclarationDataOld";

export default function DeclarationsClear({onClose, declarationState, setDeclarationState}: any) {

    const {
        declaration,
        isError,
        isLoading,
        currentPage,
        currentPageSize,
        setCurrentPage,
        setCurrentPageSize,
    } = useDeclarationDataOld();

    const {
        isSuccessClear,
        handleClear,
        clearLoading,
        isErrorClear,
        errorMessage,
    } = useKizesClear();

    const [size, setSize] = useState<string>("");
    const [article, setArticle] = useState<string>("");
    const [sizeError, setSizeError] = useState<string>("");
    const [articleError, setArticleError] = useState<string>("");

    const onClear = (id: any) => {
        if (!article) {
            setArticleError('Поле обязательно к запполнению')
            return;
        }
        if (!size) {
            setSizeError('Поле обязательно к запполнению')
            return;
        }

        const trimSize = size.replace(/\s/g, '')
        const trimArticle = article.replace(/\s/g, '')

        handleClear({
            declaration_id: id,
            sizes: trimSize,
            acticle: trimArticle
        })
    }

    useEffect(() => {
        if (isErrorClear) {
            message.error('Введены некорректные данные')
        }
    }, [isErrorClear])

    useEffect(() => {
        if (isSuccessClear) {
            message.success('Вы успешно очистили КИЗЫы')
        }
    }, [isSuccessClear])


    const renderDeclarationTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (declaration?.data) {
            return (
                <>
                    <Row
                        justify={"space-between"}
                        style={{padding: "10px 40px 5px 30px"}}
                    >
                    </Row>
                    <div className="table-container">
                        <table className={"kiz-table"}>
                            <thead>
                            <th style={{}}>Название</th>
                            <th style={{}}>gtin</th>
                            <th style={{}}>Очистка</th>
                            </thead>
                            <tbody>
                            {declaration?.data?.map((item: any) => (
                                <tr>
                                    <td style={{
                                        maxWidth: 50,
                                    }}>
                                        {item.title}
                                    </td>
                                    <td style={{}}>
                                        {item.gtin}
                                    </td>

                                    <td style={{}}>
                                        <Button
                                            className="button"
                                            type="primary"
                                            style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}
                                            htmlType="submit"
                                            onClick={() => onClear(item.id)}
                                        >
                                            Очистить
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={parseInt(currentPageSize)}
                        total={declaration.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            );
        }

        return (
            `<div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>`
        );
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Очистка кизов"} onClose={onClose}/>
            {declarationState.state === "declarationList" &&
            <>
                <div style={{textAlign: 'center', marginBottom: 10,}}>
                    Параметры для очистки кизов
                </div>
                <Col style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                }}>
                    <Input
                        style={{
                            borderColor: sizeError ? '#f83737' : '#babfc7',
                            color: sizeError ? '#f83737' : '#000',
                        }}
                        onFocus={() => setSizeError('')}
                        value={size}
                        onChange={(text: any) => setSize(text.target.value)}
                        placeholder="Размер*"
                        size="large"
                    />
                    <Input
                        style={{
                            borderColor: articleError ? '#f83737' : '#babfc7',
                            color: articleError ? '#f83737' : '#000',
                        }}
                        onFocus={() => setArticleError('')}
                        value={article}
                        onChange={(text: any) => setArticle(text.target.value)}
                        placeholder="Артикул"
                        size="large"
                    />
                </Col>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderDeclarationTable()}
                    </Col>
                </Row>
            </>
            }
        </div>
    );
}
