import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Input,
    InputNumber, InputRef, message,
    Row,
    Select, Space,
    Switch,
} from "antd";
import {
    getAnalyticsProductSizesByArticle, getAnalyticsProductSizesByProductIdFullInfo,
} from "api/analytics";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import useArticleWbData from "../../financial-results/hooks/useArticleWbData";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";
import useSellerList from "../../sellers/hooks/useSellerList";
import {parseDateFromMiddle} from "../../analytics/helpers/parseDateString";
import {useAddCostPrice} from "../hooks/useAddCostPrice";

const {Option} = Select;

export default function AddCostPriceModal({
                                           onClose,
                                       }: any) {


    const [form] = Form.useForm<{}>();
    const [selectedArticle, setSelectedArticle] = useState<any>(null)
    const [selectedProduct, setSelectedProduct] = useState<any>(null)
    const [selectedSize, setSelectedSize] = useState<any>(null)
    const [sizesData, setSizesData] = useState<any>([])
    const [selectedSeller, setSelectedSeller] = useState<any>();

    console.log(selectedProduct)
    const {
        handleCreate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useAddCostPrice()

    const {
        articleWb,
        setSellerId,
        search,
        setSearch,
    } = useArticleWbData()

    const {
        sellers,
        search: searchSeller,
        setSearch: setSearchSeller,
    } = useSellerList();

    const onFinish = (value: any) =>{

        const parsedDate = parseDateFromMiddle(value?.numberDT);

        if (parsedDate) {
            console.log({
                productSizeId: selectedSize,
                investorId: value?.investorId,
                cost: Number(value?.cost),
                count: Number(value?.count),
                numberDT: value?.numberDT,
                appearedFrom: parsedDate,
                productId: Number(selectedProduct)
            })
            handleCreate({
                productSizeId: selectedSize,
                investorId: value?.investorId,
                cost: Number(value?.cost),
                count: Number(value?.count),
                numberDT: value?.numberDT,
                appearedFrom: parsedDate,
                productId: Number(selectedProduct)
            })
        } else {
            message.error('Вы ввели неверный формат номера ДТ');
        }
    }

    useEffect(() =>{
        getAnalyticsProductSizesByProductIdFullInfo(selectedProduct).then((data) => {
            setSizesData(data)

        }).catch((e) => {
            console.log(e?.response?.data?.message)
            if (e?.response?.data?.message === 'Product not found'){

            }
        });
    },[selectedArticle])

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно добавили новую себестоимость')
            form.resetFields()
            onClose()
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError){
            message.error(errorMessage)
        }
    }, [isError])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавление себестоимости"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Form
                onFinish={(values) => onFinish(values)}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    rules={[{ required: true }]}
                    name={"investorId"}
                    label={"Инвестор"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSeller}
                        placeholder="Инвестор"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearchSeller(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.map((option: any) => {
                            return (
                                <Option key={option?.user_id} value={option?.user_id}>
                                    {option?.company_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    name={"articule"}
                    label={"Артикул"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedArticle}
                        placeholder="Артикул"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearch(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedArticle(e)
                            setSelectedProduct(option?.key)
                            console.log(option?.key)
                        }}
                    >
                        {articleWb?.slice(0,10)?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.nmId}>
                                    {option.nmId}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    name={"productSizeId"}
                    label={"Размер"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSize}
                        placeholder="Размер"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        // onSearch={(value: any) => setSearch(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSize(e)
                        }}
                    >
                        {sizesData?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.id}>
                                    {option.size}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    rules={[{ required: true }]}
                    name={"cost"}
                    label={"Себестоимость (руб.)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    name={"count"}
                    label={"Кол-во прихода (шт)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    name={"numberDT"}
                    label={"Номер ДТ"}
                >
                    <Input/>
                </Form.Item>

                <Col style={{display: "flex", gap: "15px"}}>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        htmlType={"submit"}
                        loading={isLoading}
                    >
                        Сохранить
                    </Button>
                    <Button
                        type={"primary"}
                        ghost
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        onClick={() =>{
                            form.resetFields()
                            onClose()
                        }}
                    >
                        Отмена
                    </Button>
                </Col>
            </Form>
        </div>
    );
}
