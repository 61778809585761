import {
	Button,
	Modal,
	Input,
	Form,
	message,
	Checkbox,
	Spin,
	Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import Icon, { CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import useSettingsData from '../hooks/settings/useSettingsData'

export const GeneratedTokenModal = ({
	isVisible,
	handleClose,
	generatedToken,
}: any) => {
	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(generatedToken)
			message.success('Токен скопирован в буфер обмена!')
		} catch (error) {
			message.error('Не удалось скопировать токен.')
		}
	}
	return (
		<Modal
			visible={isVisible}
			onCancel={handleClose}
			bodyStyle={{ padding: '20px 10px 0px 10px' }}
			footer={[
				<div style={{ padding: '0 20px 20px 0px' }} key='footer'>
					<Button key='close' type='primary' onClick={handleClose}>
						Закрыть
					</Button>
				</div>,
			]}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '1rem',
					marginTop: '1rem',
				}}
			>
				<Typography.Text
					copyable={false}
					style={{
						backgroundColor: '#f5f5f5',
						padding: '8px',
						borderRadius: '4px',
						overflowWrap: 'break-word',
						wordWrap: 'break-word',
						width: '100%',
						minHeight: '60px',
						maxHeight: '150px',
						overflowY: 'auto',
					}}
				>
					{generatedToken || 'Токен не сгенерирован'}
				</Typography.Text>
				<Button icon={<CopyOutlined />} onClick={handleCopy}>
					Скопировать
				</Button>
			</div>
		</Modal>
	)
}
