import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {Checkbox} from 'antd';


export default function SendToTSDModal({onClose}: any) {

    const [selectedIds, setSelectedIds] = useState<any>([]);


    const handleCheckboxChange = (checkedValues: any) => {
        setSelectedIds(checkedValues);
    };

    const options = [
        {id: 1, label: '12345 Устройство 1'},
        {id: 2, label: '12345 Устройство 1'},
        {id: 3, label: '12345 Устройство 1'},
    ];

    const handleMasterCheckboxChange = (e: any) => {
        if (e.target.checked ){
            setSelectedIds(options?.map((option: any) => option.id));
        }
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Отправить на тсд"} onClose={onClose}/>

            <div className="send-to-tsd-modal-wrap">
                <Checkbox
                    className="send-to-tsd-modal-wrap-item"
                    checked={selectedIds.length === options.length}
                    indeterminate={selectedIds.length > 0 && selectedIds.length < options.length}
                    onChange={handleMasterCheckboxChange}
                    style={{
                        marginBottom: 20
                    }}
                >
                    Выбрать все устройства
                </Checkbox>
            </div>


            <Checkbox.Group
                onChange={handleCheckboxChange}
                value={selectedIds}
                className="send-to-tsd-modal-wrap"
            >
                {options.map((option) => (
                    <div key={option.id} className="send-to-tsd-modal-wrap-item">
                        <Checkbox value={option.id}> {option.label}</Checkbox>
                    </div>
                ))}
            </Checkbox.Group>


            <Button
                className={"button"}
                type={"primary"}
                style={{ width: "100%", marginTop: 20, }}
            >
                Отправить в работу
            </Button>
        </div>
    );
}
