import dayjs, {Dayjs} from "dayjs";
import React, {useState, useEffect, useRef} from "react";
import {Col, Row, DatePicker, Button, Modal, Pagination, Input, Dropdown, message, Menu, Upload} from "antd";
import {DownOutlined} from '@ant-design/icons';
import Icon from "@ant-design/icons";
import {ReactComponent as rangePickerArrow} from "assets/images/rangepicker-arrow.svg";
import type {MenuProps} from 'antd';
import Declarations from "../modal/Declarations";
import FileUploader from "../components/FileUploader";
import useDeclarationData from "../hooks/declaratiion/useDeclarationData";
import DeclarationsClear from "../modal/DeclarationsClear";
import useFreeKizesInfoForPDF from "../hooks/declaratiion/useFreeKizesInfoForPDF";

const {RangePicker} = DatePicker;

interface MenuItemProps {
    label: string;
    key: string;
    danger?: boolean;
    disabled?: boolean;
}

export default function KizTablet() {

    const [openDeclarationModal, setOpenDeclarationModal] = useState<boolean>(false);
    const [openDeclarationModalClear, setOpenDeclarationModalClear] = useState<boolean>(false);

    const [declarationState, setDeclarationState] = useState<any>({
        state: 'declarationList',
        idForEdit: null,
    });
    const [declarationStateClear, setDeclarationStateClear] = useState<any>({
        state: 'declarationList',
        idForEdit: null,
    });

    const testData = [
        {
            id: 1,
            name: '1463 Оксфорды черные женские',
            article_china: '1463',
            size_id: '44,12,13,14',
            kiz_code_short: '(01)04650254655691(21)5sTRXkSfoSCs=',
            kiz_code_long: '(01)04650254655691(21)5sTRXkSfoSCs=',
            is_customed: 'True',
            customed_date: new Date(),
            has_added: 'True',
        },
        {
            id: 1,
            name: '1463 Оксфорды черные женские',
            article_china: '1463',
            size_id: '44,12,13,14',
            kiz_code_short: '(01)04650254655691(21)5sTRXkSfoSCs=',
            kiz_code_long: '(01)04650254655691(21)5sTRXkSfoSCs=',
            is_customed: 'True',
            customed_date: new Date(),
            has_added: 'True',
        },
        {
            id: 1,
            name: '1463 Оксфорды черные женские',
            article_china: '1463',
            size_id: '44,12,13,14',
            kiz_code_short: '(01)04650254655691(21)5sTRXkSfoSCs=',
            kiz_code_long: '(01)04650254655691(21)5sTRXkSfoSCs=',
            is_customed: 'True',
            customed_date: new Date(),
            has_added: 'True',
        },
        {
            id: 1,
            name: '1463 Оксфорды черные женские',
            article_china: '1463',
            size_id: '44,12,13,14',
            kiz_code_short: '(01)04650254655691(21)5sTRXkSfoSCs=',
            kiz_code_long: '(01)04650254655691(21)5sTRXkSfoSCs=',
            is_customed: 'True',
            customed_date: new Date(),
            has_added: 'True',
        },
        {
            id: 1,
            name: '1463 Оксфорды черные женские',
            article_china: '1463',
            size_id: '44,12,13,14',
            kiz_code_short: '(01)04650254655691(21)5sTRXkSfoSCs=',
            kiz_code_long: '(01)04650254655691(21)5sTRXkSfoSCs=',
            is_customed: 'True',
            customed_date: new Date(),
            has_added: 'True',
        },
    ]

    return (
        <Row style={{
            minHeight: '100%',
        }}>
            <Col span={24} style={{padding: "11px 30px"}}>
                <Row justify={"center"} style={{display: 'flex', alignItems: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setOpenDeclarationModal(true)}
                            >
                                Декларации
                            </Button>
                        </Col>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setOpenDeclarationModalClear(true)}
                            >
                                Очистка кизов
                            </Button>
                        </Col>
                        <Col style={{maxWidth: '190px'}}>
                            <FileUploader
                                successText={'Не забудьте растоможить КИЗы'}
                                actionUrl={`${process.env.REACT_APP_API_URL_KIZ}import/kizes`}
                                title={'Загрузить эмиссию'}
                            />
                        </Col>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                {testData.length === 0 ? (
                    <span>Нет информации по выбранному промежутку</span>
                ) : (
                    <>
                        <div className="table-container">
                            <table className={"kiz-table"}>
                                <thead>
                                <th style={{}}>Артикул</th>
                                <th style={{}}>Размеры</th>
                                <th style={{}}>Киз короткий</th>
                                <th style={{}}>Киз длинный</th>
                                <th style={{}}>Растаможен ли</th>
                                <th style={{}}>Дата добавления</th>
                                <th style={{}}>Был ли добавен</th>
                                </thead>
                                <tbody>
                                {testData.map((item) => (
                                    <tr>

                                        <td style={{
                                            maxWidth: 50,
                                        }}>
                                            {item.article_china}
                                        </td>

                                        <td style={{}}>
                                            {item.size_id}
                                        </td>
                                        <td style={{}}>
                                            {item.kiz_code_short}
                                        </td>
                                        <td style={{}}>
                                            {item.kiz_code_long}
                                        </td>
                                        <td style={{
                                            maxWidth: 50,
                                        }}>
                                            {item.is_customed}
                                        </td>
                                        <td style={{
                                            maxWidth: 100,
                                        }}>
                                            {dayjs(
                                                item.customed_date
                                            ).format("DD.MM.YYYY")}
                                        </td>
                                        <td style={{
                                            maxWidth: 50,
                                        }}>
                                            {item.has_added}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <Pagination
                            defaultCurrent={1}
                            current={1}
                            // onChange={(page, pageSize): any => setPage(page)}
                            total={3}
                            pageSize={6}
                            style={{textAlign: "center", padding: "16px 0"}}
                        />
                    </>
                )}
            </Col>
            <Modal
                open={openDeclarationModal}
                closable={false}
                footer={null}
                width={1000}
            >
                <Declarations
                    declarationState={declarationState}
                    setDeclarationState={setDeclarationState}
                    onClose={() => {
                        setOpenDeclarationModal(false)
                        setDeclarationState({
                            state: 'declarationList',
                            idForEdit: null
                        })
                    }}
                />
            </Modal>
            <Modal
                open={openDeclarationModalClear}
                closable={false}
                footer={null}
                width={1000}
            >
                <DeclarationsClear
                    declarationState={declarationStateClear}
                    setDeclarationState={setDeclarationStateClear}
                    onClose={() => {
                        setOpenDeclarationModalClear(false)
                        setDeclarationState({
                            state: 'declarationList',
                            idForEdit: null
                        })
                    }}
                />
            </Modal>

        </Row>
    );
}


