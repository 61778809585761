import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function useExpensesAdData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [orderByExpenses, setOrderByExpenses] = useState<string>("");

    const { data: expensesAdData, ...options } = useQuery(
        ["EXPENSES_AD_DATA", currentPage, currentPageSize, orderByExpenses],
        () =>
            backend.expenses.getExpensesAdData(
                currentPage.toString(),
                currentPageSize,
                orderByExpenses
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        expensesAdData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        orderByExpenses,
        setOrderByExpenses,
        ...options,
    };
}
