import React, { useState } from "react";
import { Col, Row } from "antd";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import AnalyticsTable from "../../features/analytics/pages/AnalyticsTable";
import ShipmentsTable from "../../features/shipments/ShipmentsTable";

export default function ShipmentsPage() {
    return (
      <Row>
          <Col style={{ padding: "10px 20px" }} span={24}>
              <ShipmentsTable />
          </Col>
      </Row>
    );
}
