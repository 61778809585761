import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Modal, Pagination, Row, Spin, Tooltip} from "antd";
import ModalHeader from "../../shared/ModalHeader";
import {LoadingOutlined} from "@ant-design/icons";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

const phrase = {
    data: [{
        id: 1,
        title: 'Ключевое слово 1'
    }]
}
export default function EditListOfPhrase({onClose}: any) {

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const [phraseState, setPhraseState] = useState<any>({
        state: 'phraseList',
        idForEdit: null,
    });

    const renderPhraseTable = () => {

        if (phrase?.data) {
            return (
                <>
                    <Row
                        justify={"space-between"}
                        style={{padding: "10px 40px 5px 30px"}}
                    >
                    </Row>
                    <Row>
                        <Input
                            // value={searchValue}
                            // onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={'Введите фразу для поиска'}
                        />
                    </Row>
                    <div className="table-container">
                        <table className={"kiz-table"}>
                            <thead>
                            <th style={{}}>Название</th>
                            <th style={{}}>Редактировать</th>
                            <th style={{}}>Удаление</th>
                            </thead>
                            <tbody>
                            {phrase?.data?.map((item: any) => (
                                <tr>
                                    <td style={{
                                        maxWidth: 50,
                                    }}>
                                        {item.title}
                                    </td>

                                    <td style={{}}>
                                        <Button
                                            className={"button"}
                                            type={"primary"}
                                            style={{width: "100%", fontSize: "12px"}}
                                            htmlType={"submit"}
                                            onClick={() => {
                                                setPhraseState({state: 'phraseUpdate', idForEdit: item.id})
                                                // setId(item.id)
                                            }}
                                        >
                                            Редактировать
                                        </Button>
                                    </td>
                                    <td style={{}}>
                                        <Button
                                            className="button"
                                            type="primary"
                                            style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}
                                            htmlType="submit"
                                            onClick={() => setIsModalVisible({
                                                isOpen: true,
                                                text: 'удалить ключевое слово',
                                                onPress: () => {}
                                            })}
                                        >
                                            Удалить
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <Pagination
                        // onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        // current={currentPage}
                        // pageSize={parseInt(currentPageSize)}
                        // total={keyWords.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderAddPhrase = () => {
        return (
            <>
                <Form
                    form={form}
                    layout={"vertical"}
                    // onFinish={onFinish}
                >
                    <Form.Item
                        label={
                            <div>
                                <span>Название</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                        <Col span={12}>
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "100%"}}
                                htmlType={"submit"}
                                // loading={createLoading}
                            >
                                Добавить
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );

    };

    const renderUpdatePhrase = () => {
        if (false) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (true) {
            return (
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        // onFinish={onFinishUpdate}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span>Название</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите название",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    style={{width: "100%"}}
                                    htmlType={"submit"}
                                    // loading={isLoadingUpdateKeyWord}
                                >
                                    Сохранить
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            );
        }
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Фразы"} onClose={onClose}/>
            {phraseState.state === "phraseList" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "200px", fontSize: "12px"}}
                                onClick={() => {
                                    setPhraseState({state: 'phraseAdd'})
                                    // setId('')
                                }}
                            >
                                Добавить фразу
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderPhraseTable()}
                    </Col>
                </Row>
            </>
            }
            {phraseState.state === "phraseAdd" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => {
                                    form.resetFields()
                                    setPhraseState({state: 'phraseList'})
                                }}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderAddPhrase()}
                    </Col>
                </Row>
            </>
            }
            {phraseState.state === "phraseUpdate" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setPhraseState({state: 'phraseList'})}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderUpdatePhrase()}
                    </Col>
                </Row>
            </>
            }
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
};

