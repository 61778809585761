import {useMutation, useQueryClient} from "react-query";
import React, {useCallback, useState} from "react";
import {backend} from "../../api";
import {getFreeKizezInfoForPDF} from "../../api/declaration";
import {saveAs} from "file-saver";
import {wrap} from "comlink";
import {MyPdfComponent} from "../../components/MyPdfComponent";
import {getTest} from "../../help/worker";
import ReactPDF from "@react-pdf/renderer";
import pdf = ReactPDF.pdf;
import {message} from "antd";

export const useDownloadKizesByWorker = () => {

    const onDownloadPdf = async ({id, article, size, type}: any) => {
        const worker = new Worker(new URL('../../help/worker', import.meta.url));
        const getTestWorker = wrap<typeof getTest>(worker);

        let response;
        try {
            response = await getFreeKizezInfoForPDF(id, size, article, type)

            console.log(response)

            await message.success('Файл начал загрузку, не закрывайте браузер')

        } catch (e) {
            message.error('Произошла ошибка при попытке получить данные')
        }

        getTestWorker(response)
            .then(({pdfBlob, pdfName}: any) => {
                console.log('ok')
                saveAs(pdfBlob, pdfName);
            })
            .catch((e: any) => {
                message.error('Произошла ошибка при генерации pdf')
            })
            .finally(() => {
                // This block executes after the promise is settled (resolved or rejected)
            });
    };

    return {
        onDownloadPdf
    };
};
