import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useArticleListForGTDProduct() {

    const [limit, setLimit] = useState<string>("10");
    const [searchArticle, setSearchArticle] = useState<string>("");
    const [debouncedSpp] = useDebounce(searchArticle, 1000);

    const {data: articleList, ...options} = useQuery(
        [
            "ARTICLE_DATA_FOR_GTD_PRODUCT",
            limit,
            debouncedSpp,
        ],
        () =>
            backend.storage.getArticleListForGTDProduct(
                limit,
                debouncedSpp,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        articleList,
        searchArticle,
        setSearchArticle,
        ...options,
    };
}
