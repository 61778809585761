import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useCostPriceTableFilters() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По ID",
            key: "id",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "id-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "id-desc",
                },
            ],
        },
        {
            label: "По стоимости",
            key: "cost",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "cost-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "cost-desc",
                },
            ],
        },
        {
            label: "По размеру",
            key: "size",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "size-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "size-desc",
                },
            ],
        },
        {
            label: "По кол-ву",
            key: "amount",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "amount-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "amount-desc",
                },
            ],
        },
        {
            label: "По дате",
            key: "date",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "date-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "date-desc",
                },
            ],
        },
        {
            label: "По акртикулу",
            key: "article",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "article-asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "article-desc",
                },
            ],
        },

    ];

    return {filterMenuItems};
}
