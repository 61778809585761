import {
    AutoComplete,
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import plusIcon from 'assets/images/bluePlus.svg'
import circleIcon from 'assets/images/gtd-circle-icon.svg'
import AddTsdFileButton from "../../../document-mirror/components/AddTSDFileButton";
import selectIcon from "../../../../assets/images/select-icon.svg";
import {ReactComponent as addPhotoIcon} from "../../../../assets/images/add-photo-icon.svg";
import {usePostProductData} from "../../hooks/gtd/usePostProductData";
import Icon from "@ant-design/icons";
import useArticleListForGTDProduct from "../../hooks/gtd/useArticleListForGTDProduct";
import useSizesListForGTDProductByArticle from "../../hooks/gtd/useSizesListForGTDProductByArticle";
import {useDebounce} from "use-debounce";
import useInfoProductByArticleAndSize from "../../hooks/gtd/useInfoProductByArticleAndSize";
import useCountryDataForProductGTD from "../../hooks/gtd/useCountryDataForProductGTD";

export default function AddProduct({onClose, id, seller}: any) {

    const [form] = Form.useForm();
    const articleValue = Form.useWatch("dec_article", form);
    const sizeValue = Form.useWatch("dec_size", form);
    const [debouncedArticle] = useDebounce(articleValue, 1000);
    const [debouncedSize] = useDebounce(sizeValue, 1000);

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    } = usePostProductData()
    
    const {
        articleList,
        searchArticle,
        setSearchArticle,
    } = useArticleListForGTDProduct()

    const {
        countryData,
        searchCountry,
        setSearchCountry,
    } = useCountryDataForProductGTD()

    const {
        sizesList,
        searchSize,
        setSearchSize,
    } = useSizesListForGTDProductByArticle(debouncedArticle)

    const {
        productDataByArticleAndSize
    } = useInfoProductByArticleAndSize(debouncedArticle, debouncedSize)

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [selectedGTD, setSelectedGTD] = useState<any>(null);

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file)
        if (file) {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                setSelectedImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const onFinish = (values: any) => {

        var body: any = {};

        if (values?.title) {
            body["title"] = values?.title;
        }
        if (values?.barcode) {
            body["barcode"] = values?.barcode;
        }
        if (id) {
            body["doc_id"] = id;
        }
        if (values?.dec_article) {
            body["dec_article"] = values?.dec_article;
        }
        if (values?.dec_size) {
            body["dec_size"] = values?.dec_size;
        }
        if (values?.provider_article) {
            body["provider_article"] = values?.provider_article;
        }
        if (values?.marketplace_article) {
            body["marketplace_article"] = values?.marketplace_article;
        }
        if (values?.chinese_article) {
            body["chinese_article"] = values?.chinese_article;
        }
        if (values?.cis_ref) {
            body["cis_ref"] = values?.cis_ref;
        }
        if (values?.name) {
            body["name"] = values?.name;
        }
        if (values?.country) {
            body["country"] = values?.country;
        }
        if (values?.box_code) {
            body["box_code"] = values?.box_code;
        }
        if (values?.importer_name) {
            body["importer_name"] = values?.importer_name;
        }
        if (values?.production_name) {
            body["production_name"] = values?.production_name;
        }
        if (values?.description) {
            body["description"] = values?.description;
        }

        body["with_cis"] = values?.with_cis;

        console.log(body)

        handleCreate(body)
    }

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно создани новый товар')
            form.resetFields()
            onClose()
        }
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isSuccessCreate, isErrorCreate])

    useEffect(() =>{
        form.setFieldsValue({
            title: productDataByArticleAndSize?.name,
            name: productDataByArticleAndSize?.name,
            barcode: productDataByArticleAndSize?.size?.wb_barcode,
            chinese_article: productDataByArticleAndSize?.article_china,
            production_name: productDataByArticleAndSize?.brand,
            provider_article: productDataByArticleAndSize?.article_china,
            importer_name: seller,
        })
        setSelectedImage(productDataByArticleAndSize?.photo_url)
    },[productDataByArticleAndSize])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавить товар в ГТД"} onClose={() => {
                form.resetFields()
                onClose()
            }}/>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Row gutter={20}>
                    <Col span={12}>
                        <div style={{marginBottom: 16}}>
                            <div className={"modal-form-label"}>
                                Фото товара:
                            </div>
                            <div className={"add-photo-container"}>
                                <label htmlFor="fileInput">
                                    {selectedImage
                                        ?
                                        <img src={selectedImage} alt="Выбранное изображение"
                                             style={{width: '100%', height: '100%'}}/>
                                        :
                                        <Icon component={addPhotoIcon} style={{fontSize: "42px"}}/>
                                    }
                                </label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{display: "none"}}
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>

                        <Form.Item
                            label={"Введите артикул"}
                            name={'dec_article'}
                            rules={[{required: true}]}
                        >
                            {/*<Input/>*/}
                            <AutoComplete
                                style={{ width: '100%' }}
                                options={articleList?.map((item: any) => ({value: item}))}
                                filterOption={(inputValue: any, option: any) =>
                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSearch={(e) => setSearchArticle(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Введите размер"}
                            name={'dec_size'}
                            rules={[{required: true}]}
                        >
                            {/*<Input/>*/}
                            <AutoComplete
                                style={{ width: '100%' }}
                                options={sizesList?.map((item: any) => ({value: item}))}
                                filterOption={(inputValue: any, option: any) =>
                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSearch={(e) => setSearchSize(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Введите наименование"}
                            name={'title'}
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Введите баркод"}
                            name={'barcode'}
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item
                            label={"Выберите является ли товар кизовым"}
                            name={'with_cis'}
                            rules={[{required: true}]}
                        >
                            <Select
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: true, label: "Является"},
                                    {value: false, label: "Не является"},
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Введите артикул поставщика"}
                            name={'provider_article'}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item
                            label={"Введите китайский артикул"}
                            name={'chinese_article'}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите название"}
                            name={'name'}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Введите страну производителя"}
                            name={'country'}
                        >
                            <AutoComplete
                                style={{ width: '100%'}}
                                options={countryData?.map((item: any) => ({value: item}))}
                                filterOption={(inputValue: any, option: any) =>
                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSearch={(e) => setSearchCountry(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Введите бокс код"}
                            name={'box_code'}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Введите имя импортера"}
                            name={'importer_name'}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Введите имя производителя"}
                            name={'production_name'}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Введите описание"}
                            name={'description'}
                        >
                            <Input/>
                        </Form.Item>

                    </Col>
                    <Col style={{
                        marginTop: "20px",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <Col style={{display: "flex", gap: "15px"}}>
                            <Button
                                loading={createLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{fontSize: "12px", width: "222px"}}
                            >
                                Добавить
                            </Button>
                            <Button
                                type={"primary"}
                                ghost
                                className={"button"}
                                style={{fontSize: "12px", width: "222px"}}
                                onClick={() => {
                                    form.resetFields()
                                    onClose()
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </Form>

        </div>
    );
}
