import React, {useState} from "react";
import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Input,
    MenuProps,
    Modal,
    Row,
    Table,
} from "antd";
import {
    UserOutlined,
    ContactsOutlined,
    MobileOutlined,
    LockOutlined,
    MoreOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import filterIcon from "assets/images/filter-icon.svg";
import AddUserModalContent from "features/account/AddUserModalContent";
import AddSellerModalContent from "features/account/AddSellerModalContent";
import editIcon from "assets/images/edit-mirror-icon.svg";
import lockIcon from "assets/images/lock-icon.svg";
import deleteIcon from "assets/images/delete-icon.svg";
import EditUserModalContent from "features/account/EditUserModalContent";
import EditSellerModalContent from "features/account/EditSellerModalContent";
import useUserList from "./hooks/useUserList";
import {useUserDelete} from "./hooks/useUserDelete";
import {useSellerDelete} from "features/sellers/hooks/useSellerDelete";
import useRoleList from "../roles/hooks/useRoleList";
import {Radio} from 'antd';
import {ReactComponent as filterSortIcon} from "../../assets/images/filter-sort-icon.svg";
import useSellerData from "../sellers/hooks/useSellerData";

const {Group: RadioGroup} = Radio;

export default function UsersTable() {

    const {roles} = useRoleList()

    const rolesMap = roles?.map((item: any) => ({text: item.name, value: item?.id}))

    const yourFunction = (filterValue: any, record: any) => {
        // Выполните необходимые действия с фильтром и записью
        console.log("Значение фильтра:", filterValue);
        console.log("Запись:", record);
    };

    const usersColumns = [
        {
            title: "Роль",
            dataIndex: "role",
            key: "role",
            width: "13%",
            filterIcon: <img src={filterIcon}/>,
            filters: rolesMap,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <RadioGroup
                        style={{
                            flexDirection: "column"
                        }}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            setSortRoleId(e.target.value);
                        }}
                        value={sortRoleId}
                    >
                        {rolesMap?.map((filter: any) => (
                            <Radio key={filter.value} value={filter.value}>
                                {filter.text}
                            </Radio>
                        ))}
                    </RadioGroup>
                    <div style={{height: 1, width: '100%', backgroundColor: '#f2f2f2', marginTop: 8}}/>
                    <div style={{marginTop: 8, display: 'flex', justifyContent: 'center'}}>
                        <Button onClick={() => setSortRoleId(0)} size="small" style={{width: 90}}>
                            Сбросить
                        </Button>
                    </div>
                </div>
            ),
            render: (text: any, record: any) => (
                <div className="render-table-cell">{text.name}</div>
            ),
        },
        {
            title: "Имя",
            dataIndex: "firstName",
            key: "name",
            width: "13%",
            filterIcon: <img src={filterIcon}/>,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <div
                        onClick={() => setSortParam('first_name')}
                        style={{
                            padding: '0 10px',
                            borderRadius: 3,
                            backgroundColor: sortParam === 'first_name' ? 'rgba(76,120,238, 0.2)' : '#fff',
                            marginBottom: 10,
                            cursor: 'pointer'
                        }}
                    >
                        <Icon component={filterSortIcon}/>
                        Сортировка от А до Я
                    </div>
                    <Button onClick={() => setSortParam('id')} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
        },
        {
            title: "Фамилия",
            dataIndex: "lastName",
            key: "surname",
            width: "13%",
            filterIcon: <img src={filterIcon}/>,
            filters: [],
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <div
                        onClick={() => setSortParam('l_name')}
                        style={{
                            padding: '0 10px',
                            borderRadius: 3,
                            backgroundColor: sortParam === 'l_name' ? 'rgba(76,120,238, 0.2)' : '#fff',
                            marginBottom: 10,
                            cursor: 'pointer'
                        }}
                    >
                        <Icon component={filterSortIcon}/>
                        Сортировка от А до Я
                    </div>
                    <Button onClick={() => setSortParam('id')} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
        },
        {
            title: "Номер телефона",
            dataIndex: "phone",
            key: "phone",
            width: "13%",
            filterIcon: <img src={filterIcon}/>,
            filters: [],
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <div
                        onClick={() => setSortParam('phone')}
                        style={{
                            padding: '0 10px',
                            borderRadius: 3,
                            backgroundColor: sortParam === 'phone' ? 'rgba(76,120,238, 0.2)' : '#fff',
                            marginBottom: 10,
                            cursor: 'pointer'
                        }}
                    >
                        <Icon component={filterSortIcon}/>
                        Сортировка от А до Я
                    </div>
                    <Button onClick={() => setSortParam('id')} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
        },
        {
            title: "Ник tg",
            dataIndex: "tg",
            key: "tg",
            width: "13%",
            filterIcon: <img src={filterIcon}/>,
            filters: [],
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <div
                        onClick={() => setSortParam('tg')}
                        style={{
                            padding: '0 10px',
                            borderRadius: 3,
                            backgroundColor: sortParam === 'tg' ? 'rgba(76,120,238, 0.2)' : '#fff',
                            marginBottom: 10,
                            cursor: 'pointer'
                        }}
                    >
                        <Icon component={filterSortIcon}/>
                        Сортировка от А до Я
                    </div>
                    <Button onClick={() => setSortParam('id')} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "16%",
            filterIcon: <img src={filterIcon}/>,
            filters: [],
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
                <div style={{padding: 8}}>
                    <div
                        onClick={() => setSortParam('email')}
                        style={{
                            padding: '0 10px',
                            borderRadius: 3,
                            backgroundColor: sortParam === 'email' ? 'rgba(76,120,238, 0.2)' : '#fff',
                            marginBottom: 10,
                            cursor: 'pointer'
                        }}
                    >
                        <Icon component={filterSortIcon}/>
                        Сортировка от А до Я
                    </div>
                    <Button onClick={() => setSortParam('id')} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: "4%",
            render: (text: any, record: any) => (
                <div className="render-table-cell">
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getUserActions(record)}
                    >
                        <MoreOutlined style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const sellersColumns = [
        {
            title: "Юридическое лицо",
            dataIndex: "companyName",
            key: "companyName",
            width: "16%",
            filterIcon: <img src={filterIcon}/>,
            filters: [],
        },
        {
            title: "Стандартный WB Ключ",
            dataIndex: "wbToken",
            key: "wbToken",
            width: "16%",
            render: (text: any, record: any) => (
                <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
                    {text}
                </div>
            ),
        },
        {
            title: "Запасной WB Ключ",
            dataIndex: "standartWbToken",
            key: "standartWbToken",
            width: "16%",
            render: (text: any, record: any) => (
                <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
                    {text}
                </div>
            ),
        },
        {
            title: "WB Ключ Аналитики",
            dataIndex: "statisticsWbToken",
            key: "statisticsWbToken",
            width: "16%",
            render: (text: any, record: any) => (
                <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
                    {text}
                </div>
            ),
        },
        {
            title: "Рекламный WB Ключ",
            dataIndex: "adsWbToken",
            key: "adsWbToken",
            width: "16%",
            render: (text: any, record: any) => (
                <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
                    {text}
                </div>
            ),
        },
        {
            title: "Инвестор",
            dataIndex: "aa",
            key: "user",
            width: "16%",
            render: (text: any, record: any) => (
                <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
                    {record.user?.login ?? "-"}
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div className="render-table-cell">
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getSellerActions(record)}
                    >
                        <MoreOutlined style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const userItems: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <img src={editIcon}/>
                    Редактировать
                </span>
            ),
            key: "EDIT-USER",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <img src={lockIcon}/>
                    Сбросить пароль
                </span>
            ),
            key: "2",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: "#EA5455"}}>
                  <img src={deleteIcon}/>
                  Удалить пользователя
                </span>
            ),
            key: "DELETE-USER",
        },
    ];

    const getUserActions = (record: any) => {
        const userActions = {
            items: userItems,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT-USER":
                        setEditUserOpened(true);
                        setUserToEdit(record);
                        break;
                    case "DELETE-USER":
                        handleUserDelete(record?.id);
                        break;
                }
            },
        };
        return userActions;
    };

    const sellerItems: MenuProps["items"] = [
        {
            label: (
                <span
                    style={{display: "flex", gap: "10px"}}
                    onClick={() => setEditSellerOpened(true)}
                >
                  <img src={editIcon}/>
                  Редактировать
                </span>
            ),
            key: "EDIT-SELLER",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: "#EA5455"}}>
                  <img src={deleteIcon}/>
                  Удалить Юридическое лицо
                </span>
            ),
            key: "DELETE-SELLER",
        },
    ];

    const getSellerActions = (record: any) => {
        const sellerActions = {
            items: sellerItems,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT-SELLER":
                        setEditSellerOpened(true);
                        setSellerToEdit(record);
                        break;
                    case "DELETE-SELLER":
                        handleSellerDelete(record?.id);
                        break;
                }
            },
            className: "dropdown-border",
        };
        return sellerActions;
    };

    const [activeTab, setActiveTab] = useState<number>(0);
    const [addUserOpened, setAddUserOpened] = useState<boolean>(false);
    const [addSellerOpened, setAddSellerOpened] = useState<boolean>(false);

    const [editUserOpened, setEditUserOpened] = useState<boolean>(false);
    const [userToEdit, setUserToEdit] = useState<User | null>();

    const [editSellerOpened, setEditSellerOpened] = useState<boolean>(false);
    const [sellerToEdit, setSellerToEdit] = useState<Seller | null>();

    const {
        users,
        sortRoleId,
        setSortRoleId,
        currentPage,
        setCurrentPage,
        sortParam,
        orderBy,
        setSortParam,
        setOrderBy,
        isLoading
    } = useUserList();

    const {handleDelete: handleUserDelete} = useUserDelete();

    const {
        sellers,
        currentPage: currentPageSeller,
        setCurrentPage: setCurrentPageSeller,
        isLoading: isLoadingSellers,
        currentSize: currentSizeSellers,
        setCurrentSize: setCurrentSizeSellers,
    } = useSellerData();

    const {handleDelete: handleSellerDelete} = useSellerDelete();

    return (
        <Row style={{marginTop: "13px"}}>
            <Col span={24} className={"container"}>
                <Row>
                    <Col span={24} style={{padding: "6px 0"}}>
                        <Row justify={"space-between"}>
                            <Col span={8}>
                                {" "}
                                <Button
                                    type={"ghost"}
                                    className={
                                        activeTab === 0
                                            ? "account-tab-button-active"
                                            : "account-tab-button"
                                    }
                                    onClick={() => setActiveTab(0)}
                                >
                                    Продавцы
                                </Button>
                                <Button
                                    type={"ghost"}
                                    className={
                                        activeTab === 1
                                            ? "account-tab-button-active"
                                            : "account-tab-button"
                                    }
                                    onClick={() => setActiveTab(1)}
                                >
                                    Управление пользователями
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className={"account-add-button"}
                                    ghost
                                    type={"primary"}
                                    style={{marginRight: "10px"}}
                                    onClick={() => setAddSellerOpened(true)}
                                >
                                    Добавить продавца
                                </Button>

                                <Button
                                    className={"account-add-button"}
                                    type={"primary"}
                                    style={{marginRight: "20px"}}
                                    onClick={() => setAddUserOpened(true)}
                                >
                                    Добавить пользователя
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className={"account-table-wrapper"}>
                        {activeTab === 0 && (
                            <Table
                                loading={isLoadingSellers}
                                columns={sellersColumns}
                                dataSource={sellers?.data || []}
                                locale={{
                                    emptyText: "Нет данных",
                                    filterTitle: "Фильтр",
                                    filterReset: "Очистить",
                                    filterEmptyText: "Нет фильтров",
                                }}
                                scroll={{x: true}}
                                pagination={{
                                    onChange: (page, pageSize): any => setCurrentPageSeller(page),
                                    position: ["bottomCenter"],
                                    pageSize: Number(currentSizeSellers),
                                    total: Number(sellers?.total),
                                    showSizeChanger: false,
                                    current: currentPageSeller,
                                }}
                            />
                        )}
                        {activeTab === 1 && (
                            <Table
                                loading={isLoading}
                                columns={usersColumns}
                                dataSource={users?.data}
                                locale={{
                                    emptyText: "Нет данных",
                                    filterTitle: "Фильтр",
                                    filterReset: "Очистить",
                                    filterEmptyText: "Нет фильтров",
                                }}
                                scroll={{x: true}}
                                pagination={{
                                    onChange: (page, pageSize): any => setCurrentPage(page),
                                    position: ["bottomCenter"],
                                    pageSize: Number(10),
                                    total: Number(users?.total),
                                    showSizeChanger: false,
                                    current: currentPage,
                                }}
                            />
                        )}
                        <Modal open={addUserOpened} closable={false} footer={null}>
                            <AddUserModalContent onClose={() => setAddUserOpened(false)}/>
                        </Modal>
                        <Modal open={addSellerOpened} closable={false} footer={null}>
                            <AddSellerModalContent
                                onClose={() => setAddSellerOpened(false)}
                            />
                        </Modal>
                        {userToEdit && (
                            <Modal open={editUserOpened} closable={false} footer={null}>
                                <EditUserModalContent
                                    user={userToEdit}
                                    onClose={() => {
                                        setEditUserOpened(false);
                                        setUserToEdit(null);
                                    }}
                                />
                            </Modal>
                        )}

                        {sellerToEdit && (
                            <Modal open={editSellerOpened} closable={false} footer={null}>
                                <EditSellerModalContent
                                    seller={sellerToEdit}
                                    onClose={() => setEditSellerOpened(false)}
                                />
                            </Modal>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
