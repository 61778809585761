import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import useSizeList from "features/sizes/hooks/useSizeList";
import {Checkbox, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useProductList from "../../product/hooks/useProductList";
import useSellerList from "../../sellers/hooks/useSellerList";
import useWarehousesData from "./useWarehousesData";

export default function useShipmentsData() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<string>("10");
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(searchText, 1000);
  const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
  const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);

  const [selectedWarehouse, setSelectedWarehouse] = useState<Array<string>>([]);
  const [searchWarehouse, setSearchWarehouse] = useState<string>('');

  const [selectedPeriod, setSelectedPeriod] = useState<string>('3');

  const [withDetails, setWithDetails] = useState<string>('all');

  const {
    sizes,
    search: searchSize,
    setSearch: setSearchSize,
  } = useSizeList();

  const {
    warehouse,
  } = useWarehousesData();

  const {
    products,
    search: searchProduct,
    setSearch: setSearchProduct,
    setNotEmptyMode
  } = useProductList();

  const {
    sellers,
    search: searchSellers,
    setSearch: setSearchSellers,
  } = useSellerList();

  const getWarehouseFilterItems = () => {
    if (!warehouse) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    warehouse?.filter((item: any) => selectedWarehouse ? item?.title?.toLowerCase()?.includes(searchWarehouse?.toLowerCase()) : item)
      ?.forEach((item: any) => {
        const isDuplicate = selectedWarehouse.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

        if (!isDuplicate) {
          allItems.push({
            label: (
              <div
                style={{display: "flex", gap: "10px"}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  style={{width: '100%'}}
                  value={item.title}
                  checked={selectedWarehouse.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                  onChange={(e) => {
                    let newSelectedProducts = [...selectedWarehouse];
                    if (e.target.checked) {
                      newSelectedProducts.push(item);
                    } else {
                      newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                    }
                    setSelectedWarehouse(newSelectedProducts);
                  }}
                >
                  {item.title}
                </Checkbox>
              </div>
            ),
            key: `product-${item.id}`,
          });
        }
      });

    selectedWarehouse?.forEach((item: any) => {
      const isDuplicate = selectedWarehouse.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedWarehouse.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedWarehouse];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedWarehouse(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      {
        label: (
          <Input
            value={searchWarehouse}
            className={"analytic-dropdown-input"}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => setSearchWarehouse(e?.target?.value)}
            prefix={<Icon component={searchIcon}/>}
            placeholder={"Поиск..."}
          />
        ),
        key: "product-search",
      },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const getProductsFilterItems = () => {
    if (!products) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    products?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    selectedProducts?.forEach((item: any) => {
      const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                onChange={(e) => {
                  let newSelectedProducts = [...selectedProducts];
                  if (e.target.checked) {
                    newSelectedProducts.push(item);
                  } else {
                    newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `product-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      {
        label: (
          <Input
            value={searchProduct}
            className={"analytic-dropdown-input"}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => setSearchProduct(e?.target?.value)}
            prefix={<Icon component={searchIcon}/>}
            placeholder={"Поиск..."}
          />
        ),
        key: "product-search",
      },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const getSizeFilterItems = () => {
    if (!sizes) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    sizes?.slice(0, 10)?.forEach((item: any) => {
      const isDuplicate = selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title);

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title)}
                onChange={(e) => {
                  let newSelectedSizes = [...selectedSizes];
                  if (e.target.checked) {
                    newSelectedSizes.push(item);
                  } else {
                    newSelectedSizes = newSelectedSizes.filter((el: any) => el?.title !== item?.title);
                  }
                  setSelectedSizes(newSelectedSizes);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `sizes-${item.title}`,
        });
      }
    });

    selectedSizes?.forEach((item: any) => {
      const isDuplicate = selectedSizes.some((sizeItem: any) => sizeItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item.title}
                checked={selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title)}
                onChange={(e) => {
                  let newSelectedSizes = [...selectedSizes];
                  if (e.target.checked) {
                    newSelectedSizes.push(item);
                  } else {
                    newSelectedSizes = newSelectedSizes.filter((el: any) => el?.title !== item?.title);
                  }
                  setSelectedSizes(newSelectedSizes);
                }}
              >
                {item.title}
              </Checkbox>
            </div>
          ),
          key: `sizes-${item.title}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      {
        label: (
          <Input
            value={searchSize}
            className={"analytic-dropdown-input"}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => setSearchSize(e?.target?.value)}
            prefix={<Icon component={searchIcon}/>}
            placeholder={"Поиск..."}
          />
        ),
        key: "sizes-search",
      },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const getSellersFilterItems = () => {
    if (!sellers) {
      return [];
    }

    let allItems: any[] = [];
    let selectedItems: any[] = [];

    sellers?.forEach((item: any) => {
      const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id.toString() === item?.id.toString());

      if (!isDuplicate) {
        allItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item?.company_name}
                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id.toString() === item?.id.toString())}
                onChange={(e) => {
                  let newSelectedSellers = [...selectedSeller];
                  if (e.target.checked) {
                    newSelectedSellers.push(item);
                  } else {
                    newSelectedSellers = newSelectedSellers.filter((el: any) => el?.id.toString() !== item?.id.toString());
                  }
                  setSelectedSeller(newSelectedSellers);
                }}
              >
                {item?.company_name}
              </Checkbox>
            </div>
          ),
          key: `seller-${item.id.toString()}`,
        });
      }
    });

    selectedSeller?.forEach((item: any) => {
      const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

      if (isDuplicate) {
        selectedItems.push({
          label: (
            <div
              style={{display: "flex", gap: "10px"}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                style={{width: '100%'}}
                value={item?.company_name}
                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id.toString() === item?.id.toString())}
                onChange={(e) => {
                  let newSelectedSellers = [...selectedSeller];
                  if (e.target.checked) {
                    newSelectedSellers.push(item);
                  } else {
                    newSelectedSellers = newSelectedSellers.filter((el: any) => el?.id.toString() !== item?.id.toString());
                  }
                  setSelectedSeller(newSelectedSellers);
                }}
              >
                {item?.company_name}
              </Checkbox>
            </div>
          ),
          key: `seller-${item.id}`,
        });
      }
    });

    const items: MenuProps["items"] = [
      {
        label: (
          <Input
            value={searchSellers}
            className={"analytic-dropdown-input"}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => setSearchSellers(e?.target?.value)}
            prefix={<Icon component={searchIcon}/>}
            placeholder={"Поиск..."}
          />
        ),
        key: "sizes-search",
      },
      ...selectedItems,
      ...allItems
    ];

    return items;
  };

  const {data: shipmentsData, ...options} = useQuery(
    [
      "SHIPMENTS_DATA",
      currentPage,
      currentPageSize,
      debouncedSearchText,
      selectedSizes,
      selectedProducts,
      selectedSeller,
      selectedWarehouse,
      selectedPeriod,
      withDetails
    ],
    () =>
      backend.shipments.getShipmentsData(
        currentPage.toString(),
        currentPageSize,
        debouncedSearchText,
        selectedSizes,
        selectedProducts,
        selectedSeller,
        selectedWarehouse,
        selectedPeriod,
        withDetails
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    shipmentsData,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    searchText,
    setSearchText,
    selectedSizes,
    selectedProducts,
    selectedSeller,
    selectedPeriod,
    setSelectedPeriod,
    selectedWarehouse,
    getSizeFilterItems,
    getProductsFilterItems,
    getSellersFilterItems,
    getWarehouseFilterItems,
    withDetails,
    setWithDetails,
    ...options,
  };
}
