import {
    Alert,
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useExpensesAdAdd} from "../../expenses/hooks/useExpensesAdAdd";
import {useAuthStore} from "../../auth/hooks/useAuthStore";
import useDeclarationDataBuId from "../../expenses/hooks/useDeclarationById";
import {useDeclarationUpdate} from "../../expenses/hooks/useDeclarationUpdate";
import useCashbackList from "../hooks/useCashbackList";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

export default function EditExpensesModal({onClose, id, currentPage}: any) {

    const [form] = Form.useForm();
    const [selectedDeclaration, setSelectedDeclaration] = useState<any>('')

    const {
        handleUpdate,
        isLoading,
        isError,
        errorMessage,
    } = useDeclarationUpdate()

    const {
        declaration,
        setId,
    } = useDeclarationDataBuId()

    const {
        cashBackList,
        onSearch
    } = useCashbackList()

    useEffect(() =>{
        setId(id)
    },[id])

    const onFinish = (values: any) => {

        handleUpdate(values, id, onClose, currentPage)
    };

    useEffect(() => {
        form.setFieldsValue({
            linkOnPaymentUrl: declaration?.linkOnPaymentUrl,
            linkOnPublicUrl: declaration?.linkOnPublicUrl,
            linkOnPayedWhom: declaration?.linkOnPayedWhom,
            whomPayedName: declaration?.whomPayedName,
            sumPayed: declaration?.sumPayed,
            cashbackActionId: declaration?.cashbackAction?.id
        });
    }, [declaration]);


    const renderEditExpensesAd = () => {
        return (
            <>
                <ModalHeader title={"Редактировать затрату на рекламу"} onClose={onClose}/>
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span>Выберите cashback action</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="cashbackActionId"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={selectedDeclaration}
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => onSearch(value)}
                                onChange={(e) => setSelectedDeclaration(e)}
                            >
                                {cashBackList?.slice(1,20)?.map((option: any) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите ссылку, где находится чек об оплате рекламы</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="linkOnPaymentUrl"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите ссылку, где будет опубликован товар</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="linkOnPublicUrl"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    label={*/}
                        {/*        <div>*/}
                        {/*            <span>Введите ссылку на того, кто оплатил</span>*/}
                        {/*            <Tooltip*/}
                        {/*                // title={getTooltipText()}*/}
                        {/*                placement={"right"}*/}
                        {/*            >*/}
                        {/*            </Tooltip>*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*    name="linkOnPayedWhom"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Поле не должно быть пустым",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите на чье имя было оплачено</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="whomPayedName"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите сумму оплаты</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="sumPayed"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input
                                type={"number"}
                            />
                        </Form.Item>
                        <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    style={{width: "100%"}}
                                    htmlType={"submit"}
                                    loading={isLoading}
                                >
                                    Сохранить
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    className="products-edit-button-exit"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    onClick={onClose}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {isError && (
                        <div
                            className={"ant-form-item-explain-error"}
                            style={{color: "#ff4d4f"}}
                        >
                            {errorMessage}
                        </div>
                    )}
                </>
            </>
        )
    }

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            {renderEditExpensesAd()}
        </div>
    );
}
