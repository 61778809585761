import React, {useEffect, useState} from "react";
import Icon from "@ant-design/icons";
import {Button, Col, DatePicker, Form, Input, Row, Select, Spin, Switch, Table} from "antd";
import {ReactComponent as rangePickerArrow} from "assets/images/rangepicker-arrow.svg";
import {ReactComponent as closeIcon} from "assets/images/modal-close-icon-blue.svg";
import {ReactComponent as chartIcon} from "assets/images/presentation-chart-icon.svg";
import {ReactComponent as searchLineIcon} from "assets/images/search-line-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import dayjs from "dayjs";
import {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import KeyWordsChartV2 from "../../components/issuingPositions/KeyWordsChartV2";
import useKeyWordsChartData from "../../hooks/issuingPositions/useKeyWordsChartData";
import useAdsListForChart from "../../hooks/issuingPositions/useAdsListForChart";
import useAnalyticsKeyWordsDataV2 from "../../hooks/issuingPositions/useAnalyticsKeyWordsDataV2";
import useKeyWordsChartDataWithLines from "../../hooks/issuingPositions/useKeyWordsChartDataWithLines";

const {RangePicker} = DatePicker;
const {Option} = Select;

const columns = [
    {
        title: "Ключевое слово",
        dataIndex: "title",
        key: "title",
        width: "16%",
        render: (text: any, record: any, index: any) =>

            <div className={"keyword-table-col"}>
                {/*<Icon component={searchLineIcon}/>*/}
                <span style={{color: "#4C78EE", margin: "0 2px 0 10px"}}>
                    {text}
                </span>
                {/*<Icon component={editIcon}/>*/}
            </div>
    },
    {
        title: "Частота",
        dataIndex: "friquency",
        key: "friquency",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            // index === 0 ? (
            //     <div className={"keyword-table-first-row"}>{text}</div>
            // ) : index === 1 ? (
            //     <div className={"keyword-table-second-row"}>{text}</div>
            // ) : (
            //     <div className={"keyword-table-col"}>{text}</div>
            // ),
            <div className={"keyword-table-col"}>
                {text}
            </div>
    },
    {
        title: "Частота WB",
        dataIndex: "friquencyWb",
        key: "friquencyWb",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            <div className={"keyword-table-col"}>{text}</div>

    },
    {
        title: "Результат",
        dataIndex: "result",
        key: "result",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            <div className={"keyword-table-col"}>{text}</div>

    },
    {
        title: "Ср. позиция",
        dataIndex: "avgPosition",
        key: "avgPosition",
        width: "8%",
        render: (text: any, record: any, index: any) =>
            // index === 1 ? (
            //     <div className={"keyword-table-second-row"}>{text}</div>
            // ) : (
            //     <div className={"keyword-table-col"}>{text}</div>
            // ),
            <div className={"keyword-table-col"}>{text}</div>

    },
];

export default function KeywordModalContent({onClose, id}: any) {

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [dateMode, setDateMode] = useState<boolean>(false);

    const [addKeywordOpened, setAddKeywordOpened] = useState<boolean>(false);
    const [activeRangeChart, setActiveRangeChart] = useState('1')


    const generateColumns = (data: any) => {
        if (data?.data && data?.data?.length !== 0 && data?.x?.length !== 0) {

            return data?.x.map((position: any) => {
                const formattedDate = dayjs(position).format('DD.MM.YYYY HH:mm')
                const title = formattedDate;
                const key = formattedDate;
                return {
                    title,
                    dataIndex: `positions.${formattedDate}.position`,
                    key,
                    width: "8%",
                    render: (text: any, record: any, rowIndex: number) =>
                        <div className={"keyword-table-col"}>
                            {
                                record.positions.find((item: any) =>
                                    dayjs(item.date).format('DD.MM.YYYY HH:mm') === formattedDate
                                )?.value
                            }
                        </div>
                };
            });
        } else {
            return [];
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const {
        data,
        setPeriod_start_date,
        setPeriod_end_date,
        isShoWithDataMod,
        setIsShoWithDataMod,
        currentPage,
        setCurrentPage,
        searchKeyWords,
        setSearchKeyWords,
        isLoading,
    } = useAnalyticsKeyWordsDataV2(id, true)

    const {
        data: chartData,
        setKeywordIds,
        setPeriod_start_date: setPeriod_start_date_chart,
        setPeriod_end_date: setPeriod_end_date_chart,
        isLoading: isLoadingChart,
    } = useKeyWordsChartData(id)

    const {
        data: chartDataWithLines,
        setKeywordIds: setKeywordIdsWithLines,
        selectedAds,
        setSelectedAds,
        isLoading: isLoadingChartWithLines,
    } = useKeyWordsChartDataWithLines(id)

    const {
        data: adsList,
        setPeriod_start_date: setPeriod_start_date_ads,
        setPeriod_end_date: setPeriod_end_date_ads,
        isLoading: isLoadingAds,
    } = useAdsListForChart(id)

    const handleRangeChangeChart = (dates: any, dateStrings: any) => {
        setPeriod_start_date_chart(dateStrings[0])
        // setPeriod_start_date_ads(dateStrings[0])
        setPeriod_start_date(dateStrings[0])
        setPeriod_end_date_chart(dateStrings[1])
        setPeriod_end_date(dateStrings[1])
        // setPeriod_end_date_ads(dateStrings[1])
    }

    const handle12HoursChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const secondDate = dayjs().subtract(12, 'hours').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('1')
        setPeriod_start_date_chart(secondDate)
        // setPeriod_start_date_ads(secondDate)
        setPeriod_end_date_chart(today)
        setPeriod_start_date(secondDate)
        setPeriod_end_date(today)
        // setPeriod_end_date_ads(today)
    }

    const handle1DayChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const secondDate = dayjs().subtract(1, 'day').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('2')
        setPeriod_start_date_chart(secondDate)
        // setPeriod_start_date_ads(secondDate)
        setPeriod_end_date_chart(today)
        setPeriod_start_date(secondDate)
        setPeriod_end_date(today)
        // setPeriod_end_date_ads(today)
    }

    const handle1WeekChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const secondDate = dayjs().subtract(1, 'week').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('3')
        setPeriod_start_date_chart(secondDate)
        // setPeriod_start_date_ads(secondDate)
        setPeriod_end_date_chart(today)
        setPeriod_start_date(secondDate)
        setPeriod_end_date(today)
        // setPeriod_end_date_ads(today)
    }

    useEffect(() => {
        setKeywordIds(selectedRowKeys)
        setKeywordIdsWithLines(selectedRowKeys)
    }, [selectedRowKeys])

    useEffect(() => {
        if (data?.length !== 0 && !isLoading && isFirstLoad) {
            setKeywordIds(data?.data?.map((item: any) => item?.id))
            setSelectedRowKeys(data?.data?.map((item: any) => item?.id))
            setKeywordIdsWithLines(data?.data?.map((item: any) => item?.id))

            setIsFirstLoad(false)
        }
    }, [isLoading, isFirstLoad, data])

    useEffect(() => {
        setSelectedAds(adsList?.[0]?.id)
    }, [adsList])

    return (
        <div style={{padding: "28px 30px"}}>
            <div className="container-key-word-switch">
                <p
                    className="container-key-word-switch-side-one"
                    style={{color: dateMode ? '#000' : '#4c78ee'}}
                >
                    Отображать по датам
                </p>
                <Switch checked={dateMode} onChange={setDateMode}/>
                <p
                    className="container-key-word-switch-side-two"
                    style={{color: dateMode ? '#4c78ee' : '#000'}}
                >
                    Отображать по рекламным компаниям
                </p>
            </div>
            <div style={{position: "relative", height: "50px"}}>
                <Icon
                    onClick={() => onClose()}
                    component={closeIcon}
                    style={{fontSize: "23px", position: "absolute", right: 0}}
                />
            </div>
            <Row className="keyword-chart-wrapper">
                <Col span={24}>
                    <Row justify={"space-between"}>
                        <Col style={{width: "230px"}}>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                suffixIcon={<Icon component={SelectIconComponent}/>}
                                defaultValue={'all'}
                                onChange={(e) => {
                                    setIsShoWithDataMod(e);
                                    setIsFirstLoad(true);
                                }}
                                options={[
                                    {value: 'withData', label: "Показать с данными в таблице"},
                                    {value: 'all', label: "Показать все"},
                                ]}
                            />
                        </Col>
                        {
                            dateMode &&
                            <Col style={{width: "230px"}}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={selectedAds}
                                    placeholder="Рекламная компания"
                                    filterOption={false}
                                    suffixIcon={<Icon
                                        component={SelectIconComponent}
                                        style={{marginTop: "2px", fontSize: "10px"}}
                                    />}
                                    onChange={(e: any, option: any) => {
                                        setSelectedAds(e)
                                    }}
                                >
                                    {adsList?.map((option: any) => {
                                        return (
                                            <Option key={option?.id} value={option?.id}>
                                                {option.title}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Col>
                        }

                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                }}
                            >
                                {" "}
                                <span onClick={handle12HoursChart}
                                      className={activeRangeChart === '1' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    12 часов
                                </span>
                                <span
                                    onClick={handle1DayChart}
                                    className={activeRangeChart === '2' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    День
                                </span>
                                <span onClick={handle1WeekChart}
                                      className={activeRangeChart === '3' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Неделя
                                </span>
                                <RangePicker
                                    showTime
                                    onChange={handleRangeChangeChart}
                                    placeholder={["Начало", "Конец"]}
                                    separator={
                                        <Icon component={rangePickerArrow}/>
                                    }
                                    format={"DD.MM.YYYY HH:mm"}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="keyword-chart-message-wrapper">

                        {dateMode && selectedAds
                            ?
                            isLoadingChartWithLines
                                ? <div
                                    style={{
                                        padding: "150px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Spin
                                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                    />
                                </div>
                                :
                                !chartDataWithLines?.keywords || chartDataWithLines?.keywords?.length === 0
                                    ?
                                    <div style={{textAlign: "center"}}>
                                        <Icon
                                            component={chartIcon}
                                            style={{fontSize: "100px"}}
                                        />
                                        <div className={"keyword-chart-message"}>
                                            Выберите ключевые слова, чтобы увидеть график
                                            сравнения их позиций
                                        </div>
                                    </div>
                                    : <KeyWordsChartV2
                                        data={chartDataWithLines?.keywords}
                                        xCoords={chartDataWithLines?.x}
                                        lines={chartDataWithLines?.adsIntervals}
                                    />
                            :
                            isLoadingChart
                                ? <div
                                    style={{
                                        padding: "150px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Spin
                                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                    />
                                </div>
                                :
                                !chartData?.keywords || chartData?.keywords?.length === 0
                                    ?
                                    <div style={{textAlign: "center"}}>
                                        <Icon
                                            component={chartIcon}
                                            style={{fontSize: "100px"}}
                                        />
                                        <div className={"keyword-chart-message"}>
                                            Выберите ключевые слова, чтобы увидеть график
                                            сравнения их позиций
                                        </div>
                                    </div>
                                    : <KeyWordsChartV2
                                        data={chartData?.keywords}
                                        xCoords={chartData?.x}
                                        lines={chartData?.adsIntervals}
                                    />
                        }
                    </div>
                </Col>
            </Row>
            <Row className={"keyword-table-wrapper"}>
                <Col span={24}>
                    <Row>
                        <Col style={{
                            padding: 10,
                            width: '100%'
                        }}>
                            <Input
                                value={searchKeyWords}
                                onChange={(text: any) => setSearchKeyWords(text.target.value)}
                                className="table-header-input"
                                placeholder="Поиск по ключевому слову"
                                size="large"
                                style={{
                                    borderRadius: '10px 10px 0 0 '
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <Table
                                loading={isLoading}
                                scroll={{x: 1000}}
                                className="keyword-table"
                                dataSource={data?.data?.map((item: any, index: any) => ({
                                    ...item,
                                    key: item?.id,
                                }))}
                                columns={[...columns, ...generateColumns(data)]}
                                rowSelection={rowSelection}
                                pagination={{
                                    onChange: (page, pageSize): any => setCurrentPage(page),
                                    position: ["bottomCenter"],
                                    pageSize: Number(5),
                                    total: Number(data?.total),
                                    showSizeChanger: false,
                                    current: currentPage,
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
