import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { backend } from "../../../api";

export const useSellerDelete = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, ...options } = useMutation(
        backend.seller.deleteSeller,
        {
            onSuccess: async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["SELLER_LIST_FULL_INFO"]),
                ]);
            },
        }
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateAsync(id);
        },
        [mutateAsync]
    );

    return {
        handleDelete,
        ...options,
    };
};
