import React from 'react';
import LaboratoryCurrentTVTTable from "../../features/laboratoryTVT/LaboratoryCurrentTVTTable";

const CurrentLaboratory = () => {
  return (
    <div>
      <LaboratoryCurrentTVTTable/>
    </div>
  );
};

export default CurrentLaboratory;