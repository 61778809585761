import { Col, Row } from "antd";
import FinancialResultsTable from "features/financial-results/FinancialResultsTable";

export default function FinancialResultsPage() {
    return (
        <Row style={{ padding: "10px 20px" }} className='finance-page'>
            <Col className={"container finance-page"} span={24}>
                <FinancialResultsTable />
            </Col>
        </Row>
    );
}
