import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";

export default function AddOrderModal({onClose}: any) {

    const [form] = Form.useForm();

    const [checked, setChecked] = useState(false);
    const handleChange = (e: any) => {
        setChecked(e.target.checked);
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Добавить заказ"}
                onClose={() => {
                    form.resetFields()
                    onClose()
                }}
            />
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"} form={form}>
                        <Form.Item
                            label={"Введите/выберите артикул"}
                            name={"1"}
                            style={{marginBottom: "-10px"}}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                style={{marginTop: 10}}
                            >
                                Новый товар
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            label={"Выберите размер"}
                            name={"2"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label={"Выберите цвет"} name={"3"}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Укажите кол-во товара"} name={"4"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Выберите инвестора"} name={"5"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Оформить заказ
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields()
                                    onClose()
                                }}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
