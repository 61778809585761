import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Spin, Table } from 'antd'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { EditModal } from './modals/EditSupplierModal'
// import SettingItem from './components/SettingsSuppliesItem'
import { PickSeller } from './components/PickSeller'

const { Option } = Select

const ActionItemsTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [selectedUser, setSelectedUser] = useState<any>(null)

	const [isOwner, setIsOwner] = useState<boolean>(false)
	const {
		actionsData,
		setDataType,
		selectedSeller,
		setSelectedSeller,
		sellers,
		isLoading,
	} = useActionsData()

	const { actionsData: isOwnerData, setDataType: setDataTypeOwner } =
		useActionsData()

	const [data, setData] = useState<[] | null>(actionsData?.data)

	// useEffect(() => {
	// 	setDataType('supplier-info')
	// 	setDataTypeOwner('am-i-owner')
	// 	console.log(actionsData)
	// 	if (actionsData) {
	// 		console.log(actionsData)
	// 		setData(actionsData.data.supplierInfo)
	// 	}
	// 	if (isOwnerData) {
	// 		setIsOwner(isOwnerData.is_owner)
	// 	}
	// }, [actionsData, isOwnerData])

	const handleCancel = () => {
		setIsEditModalVisible(false)
		setSelectedUser(null)
	}

	const columns = [
		{
			title: 'Фото',
			dataIndex: 'photo',
			key: 'photo',
			width: 100,
			align: 'center' as const,
			render: (photo: any) => (
				<img src={photo} alt='Фото' style={{ width: 50, height: 50 }} />
			),
		},
		{
			title: 'Товар уже участвует в акции',
			dataIndex: 'inPromotion',
			key: 'inPromotion',
			width: 200,
			align: 'center' as const,
			render: (inPromotion: any) => <Checkbox checked={inPromotion} />,
		},
		{
			title: 'Предмет',
			dataIndex: 'item',
			key: 'item',
			width: 200,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Артикул WB',
			dataIndex: 'article',
			key: 'article',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Остаток на складах WB (шт.)',
			dataIndex: 'stockWB',
			key: 'stockWB',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Остаток на складе продавца (шт.)',
			dataIndex: 'sellerStock',
			key: 'sellerStock',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Текущая розничная цена',
			dataIndex: 'retailPrice',
			key: 'retailPrice',
			width: 150,
			align: 'center' as const,
			render: (price: any) => (
				<div style={{ paddingLeft: '16px' }}>{price}</div>
			),
		},
		{
			title: 'Текущая скидка на сайте, %',
			dataIndex: 'currentDiscount',
			key: 'currentDiscount',
			width: 150,
			align: 'center' as const,
			render: (discount: any) => (
				<div style={{ paddingLeft: '16px' }}>{discount}</div>
			),
		},
		{
			title: 'Загружаемая скидка для участия',
			dataIndex: 'uploadDiscount',
			key: 'uploadDiscount',
			width: 150,
			align: 'center' as const,
			render: (discount: any) => (
				<div style={{ paddingLeft: '16px' }}>{discount}</div>
			),
		},
		{
			title: 'Текущая до СПП',
			dataIndex: 'currentBeforeSPP',
			key: 'currentBeforeSPP',
			width: 150,
			align: 'center' as const,
			render: (price: any) => (
				<div style={{ paddingLeft: '16px' }}>{price}</div>
			),
		},
		{
			title: 'Будет цена',
			dataIndex: 'futurePrice',
			key: 'futurePrice',
			width: 150,
			align: 'center' as const,
			render: (price: any) => (
				<div style={{ paddingLeft: '16px' }}>{price}</div>
			),
		},
		{
			title: 'Разница',
			dataIndex: 'difference',
			key: 'difference',
			width: 150,
			align: 'center' as const,
			render: (difference: any) => (
				<div style={{ paddingLeft: '16px' }}>{difference}</div>
			),
		},
		{
			title: 'Участие в акции',
			dataIndex: 'promotionParticipation',
			key: 'promotionParticipation',
			width: 150,
			align: 'center' as const,
			render: (participation: any) => <Checkbox checked={participation} />,
		},
	]

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'}>
				{/* <Col style={{ width: '250px' }}>
					<PickSeller
						selectedSeller={selectedSeller}
						setSelectedSeller={setSelectedSeller}
					/>
				</Col>
				<Col>
					<Button
						onClick={() => setIsEditModalVisible(true)}
						className='table-header-button'
						type='primary'
						// disabled={!isOwner}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: '10px',
						}}
					>
						Изменить продавца
						<EditOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
					</Button>
				</Col> */}
			</Row>

			<Row className='container' style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
						/>
					</Col>
				)}
			</Row>

			<EditModal
				isVisible={isEditModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsEditModalVisible}
				selectedSupplier={data}
			/>
		</div>
	)
}

export default ActionItemsTable
