import React, {useEffect, useState} from 'react';
import {Button, Col, Input, message, Modal, Pagination, Row, Select, Spin, Table} from "antd";
import burgerIcon from '../../../../assets/images/burgerIcon.svg'
import {useNavigate} from "react-router-dom";
import AddGTD from "../../modal/gtd/AddGTD";
import EditGTD from "../../modal/gtd/EditGTD";
import AddProduct from "../../modal/gtd/AddProduct";
import useGTDData from "../../hooks/gtd/useGTDData";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import {useCompleteFormation} from "../../hooks/gtd/useCompleteFormation";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

const state:any = {
    "ccd_being_formed": 'Формируется',
    "ccd_ready_for_delivery": 'Готова к поставке',
    "ccd_delivered": 'Поставленная',
}

const GTDTablet = () => {

    const navigate = useNavigate();

    const [addGTDModal, setAddGTDModal] = useState(false)
    const [editGTDModal, setEditGTDModal] = useState({
        isOpen: false,
        id: null
    })
    const [addProductModal, setAddProductModal] = useState<any>({
        isOpen: false,
        id: null
    })
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const {
        gtdData,
        periodStartDate,
        periodEndDate,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        setPeriodStartDate,
        setPeriodEndDate,
        selectedStatus,
        setSelectedStatus,
        isLoading,
    } = useGTDData()

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    } = useCompleteFormation()

    useEffect(() =>{
        if (isSuccessCreate){
            message.success('Вы успешно завершили формирование')
        }
        if (isErrorCreate){
            message.error(errorMessage)
        }
    },[isErrorCreate,isSuccessCreate])

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"} style={{marginBottom: 10}}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(text: any) => setSearchText(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск"
                        size="large"
                    />
                </Col>
                <Col style={{width: 180}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e);
                        }}
                        options={[
                            {value: "ccd_being_formed", label: "Формируется"},
                            {value: "ccd_ready_for_delivery", label: "Готова к поставке"},
                            {value: "ccd_delivered", label: "Поставленная"},
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        onClick={() => setAddGTDModal(true)}
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Сформировать заказ с ГТД
                    </Button>
                </Col>
            </Row>

            <Row
                className={"container"}
                style={{marginTop: "20px", minHeight: "800px"}}
            >
                <Col span={24}>
                    <div className="saved-invoices-wrap">
                        {
                            isLoading
                                ?
                                <div
                                    style={{
                                        padding: "150px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Spin
                                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                    />
                                </div>
                                :
                                gtdData?.data.length === 0 || !gtdData
                                    ?
                                    <div
                                        style={{
                                            padding: "150px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Нет данных
                                    </div>
                                    :
                                    <>
                                        {
                                            gtdData?.data?.map((item: any) =>
                                                <div className="saved-invoices-item">
                                                    <div
                                                        onClick={() => navigate(`/storage/gtd/full-info/${item?.id}`)}
                                                        className="saved-invoices-item-left"
                                                    >
                                                        <img src={burgerIcon} alt=""/>
                                                        <div className="saved-invoices-item-title">
                                                            {item.title}
                                                        </div>
                                                        <div className="saved-invoices-item-title">
                                                            |
                                                        </div>
                                                        <div className="saved-invoices-item-title">
                                                            {state[item.state]}
                                                        </div>
                                                    </div>
                                                    <div className="saved-invoices-item-right">
                                                        <Button
                                                            onClick={() => setIsModalVisible({
                                                                isOpen: true,
                                                                text: 'завершить формирование?',
                                                                onPress: () => handleCreate(item.id)
                                                            })}
                                                            className={"button"}
                                                            type={"primary"}
                                                            style={{width: "240px"}}
                                                            disabled={item?.state !== 'ccd_being_formed'}
                                                        >
                                                            Завершить формирование
                                                        </Button>
                                                        <Button
                                                            onClick={() => setEditGTDModal({
                                                                isOpen: true,
                                                                id: item?.id
                                                            })}
                                                            className={"button"}
                                                            type={"primary"}
                                                            style={{width: "222px"}}
                                                            disabled={item?.state !== 'ccd_being_formed'}
                                                        >
                                                            Редактировать
                                                        </Button>
                                                        <Button
                                                            onClick={() => setAddProductModal({
                                                                isOpen: true,
                                                                id: item?.id,
                                                                seller: item?.owner?.sellers?.[0]?.company_name
                                                            })}
                                                            className={"button"}
                                                            type={"primary"}
                                                            style={{width: "222px"}}
                                                            disabled={item?.state !== 'ccd_being_formed'}
                                                        >
                                                            Добавить товары
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <Pagination
                                            onChange={(page, pageSize): any => setCurrentPage(page)}
                                            defaultCurrent={1}
                                            current={currentPage}
                                            pageSize={parseInt(currentPageSize)}
                                            total={gtdData.total}
                                            style={{
                                                textAlign: "center",
                                                padding: "16px 0",
                                            }}
                                            showSizeChanger={false}
                                        />
                                    </>
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                open={addGTDModal}
                closable={false}
                footer={null}
                width={500}
            >
                <AddGTD
                    onClose={() => setAddGTDModal(false)}
                />
            </Modal>
            <Modal
                open={editGTDModal.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <EditGTD
                    id={editGTDModal?.id}
                    onClose={() => setEditGTDModal({
                        isOpen: false,
                        id: null
                    })}
                />
            </Modal>
            <Modal
                open={addProductModal?.isOpen}
                closable={false}
                footer={null}
                width={900}
            >
                <AddProduct
                    id={addProductModal?.id}
                    seller={addProductModal?.seller}
                    onClose={() => setAddProductModal({
                        isOpen: false,
                        id: null
                    })}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                    isLoading={createLoading}
                />
            </Modal>
        </div>
    );
};

export default GTDTablet;