import {Col, Row} from "antd/es/grid";
import LoginForm from "../../../features/login/LoginForm";
import loginBackground from "assets/images/login-bg.png";

export default function LoginPage() {
    return (
        <Row style={{height: '100vh'}} className="auth-wrap">
            <Col className="auth-block">
                    <div style={{maxWidth: "400px", margin: "0 auto"}}>
                        {" "}
                        <LoginForm/>
                    </div>
            </Col>
            <Col className="auth-block-img">
                <img
                    src={loginBackground}
                    style={{width: "100%", height: "100vh"}}
                    alt=""
                ></img>
            </Col>
        </Row>
    );
}
