import {Col, Row} from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import DeliveriesFromChinaTable from "features/ordersFromChina/DeliveriesFromChinaTable";

export default function DeliveriesFromChinaTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col className={"container"} span={24}>
                <DeliveriesFromChinaTable/>
            </Col>
        </Row>
    );
}
