import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../assets/images/search-icon.svg";
import useSellerList from "../../sellers/hooks/useSellerList";
import Icon from "@ant-design/icons";

export default function useCostPriceData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [sortParam, setSortParam] = useState<string>("id");
    const [sortType, setSortType] = useState<string>("asc");
    const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);
    const [periodStartDate, setPeriodStartDate] = useState<any>('');
    const [periodEndDate, setPeriodEndDate] = useState<any>('');

    const handleRangeChangeTable = (dates: any, dateStrings: any) => {
        setPeriodStartDate(dateStrings[0])
        setPeriodEndDate(dateStrings[1])
    }

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useSellerList();


    const getSellersFilterItems = () => {
        if (sellers?.length === 0 || !sellers) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sellers?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id}`,
                });
            }
        });

        selectedSeller?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSellers}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSellers(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "product-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };


    const {data: costPriceData, ...options} = useQuery(
        [
            "COST_PRICE_DATA",
            currentPage,
            currentPageSize,
            sortParam,
            sortType,
            selectedSeller,
            periodStartDate,
            periodEndDate
        ],
        () =>
            backend.costPrice.getCostPriceData(
                currentPage.toString(),
                currentPageSize,
                sortParam,
                sortType,
                selectedSeller,
                periodStartDate,
                periodEndDate
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        costPriceData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        setSortParam,
        setSortType,
        handleRangeChangeTable,
        getSellersFilterItems,
        ...options,
    };
}
