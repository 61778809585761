import React from 'react';
import AddSkTabTable from "../../features/storage/pages/addSkTab/AddSkTabTable";

const AddSkTab = () => {
    return (
        <div style={{ marginTop: "20px" }}>
            <AddSkTabTable />
        </div>
    );
};

export default AddSkTab;