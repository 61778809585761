import React, {useEffect, useRef, useState} from "react";
import {
    AutoComplete,
    Button,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table, Upload, UploadProps,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import DownloadEmissions from "../../modal/marking/DownloadEmissions";

const {RangePicker} = DatePicker;

const productData = [
    {
        fio: 'Иванов Иван Иванович',
        count: '2',
        date: '12.12.2000',
    }
]

const storyData = [
    {
        photo: '-',
        acticleCit: '123123',
        acticlePost: '123123',
        acticleWB: '123',
        size: '38,12',
        barCode: '3243214',
        link: 'test',
    }
]


export default function MarkingTable() {

    const navigate = useNavigate()

    const [showTable, setShowTable] = useState<string>("floor");

    const [downloadEmissionModal, setDownloadEmissionModal] = useState<any>(false);

    const columnsProduct = [
        {
            title: "ФИО ответственного за эмиссию",
            dataIndex: "fio",
            key: "fio",
            width: "35%",
        },
        {
            title: "Кол-во коробов",
            dataIndex: "count",
            key: "count",
            width: "15%",
        },
        {
            title: "Дата и время создания",
            dataIndex: "date",
            key: "date",
            width: "35%",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div style={{padding: 10,}}>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "190px",
                            fontSize: "12px",
                        }}
                    >
                        Выгрузить повторно
                    </Button>
                </div>
            ),
        },
    ];

    const columnsStory = [
        {
            title: "Фото",
            dataIndex: "photo",
            key: "photo",
            width: "15%",
        },
        {
            title: "Ар-кул кит",
            dataIndex: "acticleCit",
            key: "acticleCit",
            width: "5%",
        },
        {
            title: "Ар-кул пост",
            dataIndex: "acticlePost",
            key: "acticlePost",
            width: "5%",
        },
        {
            title: "Ар-кул WB",
            dataIndex: "acticleWB",
            key: "acticleWB",
            width: "5%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "10%",
        },
        {
            title: "Баркод",
            dataIndex: "barCode",
            key: "barCode",
            width: "20%",
        },
        {
            title: "Ссылка на документ маркировки",
            dataIndex: "link",
            key: "link",
            width: "40%",
        },
    ];

    const renderByProductFloor = () => {
        return (
            <Table
                // loading={isLoading}
                className="product-arrival-table"
                columns={columnsStory}
                dataSource={storyData || []}
                scroll={{x: true}}
                pagination={{
                    // onChange: (page, pageSize) => setCurrentPage(page),
                    position: ["bottomCenter"],
                    // pageSize: Number(currentPageSize),
                    // total: Number(declaration?.total),
                    // showSizeChanger: false,
                    // current: currentPage,
                }}
            />
        )
    }

    const renderByProductShelf = () => {
        return (
            <Table
                // loading={isLoading}
                className="product-arrival-table"
                columns={columnsProduct}
                dataSource={productData || []}
                scroll={{x: true}}
                pagination={{
                    // onChange: (page, pageSize) => setCurrentPage(page),
                    position: ["bottomCenter"],
                    // pageSize: Number(currentPageSize),
                    // total: Number(declaration?.total),
                    // showSizeChanger: false,
                    // current: currentPage,
                }}
            />
        )
    }

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={title}
                        // onChange={(text: any) => setTitle(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск"
                        size="large"
                    />
                </Col>
                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0, pointerEvents: 'none'}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        // menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по артикулу</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px", paddingTop: 0, paddingBottom: 0, pointerEvents: 'none'}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        // menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Поиск по размеру</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "170px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        // onChange={(e) => {
                        //     setCurrentPage(1);
                        //     setCurrentPageSize(e);
                        // }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>

            <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                    padding: "5px 20px 5px 30px",
                    marginTop: 20,
                    backgroundColor: '#fff'
                }}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Col style={{width: "190px"}}>
                        <Radio.Group
                            defaultValue={showTable}
                            style={{width: "100%"}}
                            onChange={(e) => setShowTable(e.target.value)}
                        >
                            <Radio.Button
                                value={"shelf"}
                                style={{
                                    width: "30%",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingRight: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                Товары
                            </Radio.Button>
                            <Radio.Button
                                value={"floor"}
                                style={{
                                    width: "70%",
                                    paddingTop: "4px",
                                    textAlign: "center",
                                    paddingLeft: "1.5px",
                                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
                                }}
                            >
                                История эмиссий
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                </div>

                <Col>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "190px",
                            height: 50,
                            fontSize: "12px",
                        }}
                        onClick={() => setDownloadEmissionModal(true)}
                    >
                        <div>
                            Выпустить эмиссию
                        </div>
                        <div>
                            маркировки коробов
                        </div>
                    </Button>
                </Col>
            </Row>


            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>

                    {showTable === 'shelf' &&
                    renderByProductShelf()
                    }

                    {showTable === 'floor' &&
                    renderByProductFloor()
                    }
                </Col>
            </Row>

            <Modal
                open={downloadEmissionModal}
                closable={false}
                footer={null}
                width={600}
            >
                <DownloadEmissions
                    onClose={() => setDownloadEmissionModal(false)}
                />
            </Modal>

        </div>
    );
}
