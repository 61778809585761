import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import CashbacksParticipantsTableRow from "./CashbacksParticipantsTableRow";
import useCashbackOperationsList from "../hooks/useCashbackOperationsList";

export default function CashbackParticipantsTable() {
    const {cashbackOperations, isLoading} = useCashbackOperationsList();

    const renderPatricipantsTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}}/>}/>
                </div>
            );
        }

        if (cashbackOperations?.data) {
            return (
                <>
                    {cashbackOperations.data.map(
                        (cashbackOperation: CashbackOperation) => (
                            cashbackOperation?.contract?.cashbackContractStatus?.id === 8
                                ? null
                                :
                                <div style={{marginTop: "20px"}}>
                                    <CashbacksParticipantsTableRow
                                        cashbackOperation={cashbackOperation}
                                    />
                                </div>
                        )
                    )}
                </>
            );
        } else {
            return (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 300,
                    fontSize: 30,
                    color: '#d5d5d5'
                }}>
                    Новых заявок нет
                </div>
            );
        }
    };

    return <>{renderPatricipantsTable()}</>;
}
