import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "../../api";

export const useUploadDeclarationCustomedFile = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: onUpload,
        data: uploadData,
        ...options
    } = useMutation(backend.declaration.loadDeclarationCustomedfile, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`DECLARATION_CUSTOMED_FILES_DATA_BY_ID`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, id: any, name: any) => {
            try {
                const data = {
                    values: values,
                    id: id,
                    name: name,
                }
                await onUpload(data);
                await queryClient.invalidateQueries(['DECLARATION_CUSTOMED_FILES_DATA_BY_ID', id]);
            } catch (e: any) {
                console.log(e?.response?.data?.detail)
                setErrorMessage(e?.response?.data?.detail);
            }
        },
        [onUpload]
    );

    return {
        handleCreate,
        uploadData,
        errorMessage,
        ...options,
    };
};
