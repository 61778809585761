import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useDebounce} from "use-debounce";

export default function useAnalyticsKeyWordsDataV2(productId: any, endDay: any) {

    const end = dayjs().format('DD.MM.YYYY HH:mm');
    const start = endDay
        ? dayjs().subtract(12, 'hours').format('DD.MM.YYYY HH:mm')
        : dayjs().subtract(1, 'week').format('DD.MM.YYYY HH:mm');

    const [period_start_date, setPeriod_start_date] = useState<any>(start);
    const [period_end_date, setPeriod_end_date] = useState<any>(end);

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(5);

    const [searchKeyWords, setSearchKeyWords] = useState<string>('');
    const [debouncedSearchKeyWords] = useDebounce(searchKeyWords, 1000);

    const [isShoWithDataMod, setIsShoWithDataMod] = useState<any>('all');

    const {data: data, ...options} = useQuery(
        [
            "ANALYTICS_KEY_WORDS_DATA_V2",
            productId,
            isShoWithDataMod,
            period_start_date,
            period_end_date,
            currentPage,
            limit,
            debouncedSearchKeyWords
        ],
        () =>
            backend.analytics.getAnalyticsKeyWordsDataV2(
                productId?.toString(),
                isShoWithDataMod.toString(),
                period_start_date,
                period_end_date,
                currentPage,
                limit,
                debouncedSearchKeyWords
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        setPeriod_start_date,
        setPeriod_end_date,
        isShoWithDataMod,
        setIsShoWithDataMod,
        currentPage,
        setCurrentPage,
        searchKeyWords,
        setSearchKeyWords,
        ...options,
    };
}
