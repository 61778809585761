import {apiKiz} from "../../../index";

// Requests
export async function getKizesData(
    page: string,
    pageSize: string
) {
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("limit", pageSize);

    const response = await apiKiz.get(`/declarations`, {
        params,
    });
    return response.data.data;
}

export async function loadKizes(file: any) {
    const response = await apiKiz.post(`/import/kizes`, file);
    return response.data;
}
