import React from 'react';
import {ReactComponent as uploadButtonIcon} from "../../../assets/images/upload-button-icon.svg";
import {Button, message} from "antd";
import Icon from "@ant-design/icons";
import axios from "axios";
import {getToken} from "../../../utils/tokenStorage";
import dayjs from "dayjs";


const generateFilename = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    return `analytics_report_${currentDate}.xlsx`;
};


export const DownloadAnaliticsButton = ({
                                            showMode,
                                            searchText,
                                            sizes,
                                            products,
                                            sellers,
                                            dateSortStart,
                                            dateSortEnd,
                                            isWithQuery = false
                                        }: any) => {

    const token = getToken();

    const handleDownload = () => {
        message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

        let params = new URLSearchParams();

        if (showMode) {
            params.append("show_by", showMode);
        }

        if (sizes && sizes.length > 0) {
            params.append("sizes", sizes?.map((item: any) => item?.title).join(","));
        }
        if (products && products.length > 0) {
            params.append("categories", products?.map((item: any) => item?.id)?.join(","));
        }
        if (sellers && sellers.length > 0) {
            params.append("sellers", sellers?.map((item: any) => item?.id)?.join(","));
        }

        if (searchText) {
            params.append("search", searchText);
        }

        if (dateSortStart && dateSortEnd) {
            params.append("start_date", dateSortStart);
            params.append("end_date", dateSortEnd);
        }

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}analitics/download`,
            responseType: 'blob',
            params: params,
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', generateFilename());
            link.setAttribute('target', '_blank');
            link.type = 'application/xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    };

    return (
        <Button
            onClick={handleDownload}
            className="table-header-button"
            type={"primary"}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            Выгрузить
            <Icon
                component={uploadButtonIcon}
                style={{fontSize: "18px", display: "block"}}
            />
        </Button>
    );
};

