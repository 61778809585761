import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useShimpentsFormationData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchText, 1000);
    const [sellectedSeller, setSellectedSeller] = useState<any>(null)

    const {data: shimpentsFormationData, ...options} = useQuery(
        [
            "SHIMPENTS_FORMATION_DATA",
            currentPage,
            currentPageSize,
            debouncedSearch,
            sellectedSeller
        ],
        () =>
            backend.storage.getShimpentsFormationData(
                currentPage.toString(),
                currentPageSize,
                debouncedSearch,
                sellectedSeller
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        shimpentsFormationData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        sellectedSeller,
        setSellectedSeller,
        ...options,
    };
}
