import {
    Alert,
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useExpensesAdAdd} from "../../expenses/hooks/useExpensesAdAdd";
import {useAuthStore} from "../../auth/hooks/useAuthStore";
import useCashbackList from "../hooks/useCashbackList";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

export default function AddExpensesModal({onClose, id, isReadOnly = false, cashBackTitle}: any) {

    const [form] = Form.useForm();
    const {state} = useAuthStore();

    const [selectedDeclaration, setSelectedDeclaration] = useState<any>('')
    const [search, setSearch] = useState<any>('')

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useExpensesAdAdd()

    const {
        cashBackList,
        onSearch
    } = useCashbackList()

    const onFinish = (values: any) => {
        let data;

        if (isReadOnly) {
            data = {
                ...values,
                userCreatedId: state.user.id,
                cashbackActionId: id,
            }
        } else {
            data = {
                ...values,
                userCreatedId: state.user.id,
            }
        }

        console.log(data)
        handleCreate(data, onClose)
    };

    useEffect(() => {
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isSuccessCreate) {
            form.resetFields()
        }
    }, [isSuccessCreate])

    const renderCreateExpensesAd = () => {
        return (
            <>
                <ModalHeader title={"Добавить затрату на рекламу"} onClose={() =>{
                    form.resetFields()
                    onClose()
                }}/>
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onFinish}
                    >
                        {isReadOnly
                            ?
                            <Form.Item
                                label={
                                    <div>
                                        <span>Выберите акцию</span>
                                        <Tooltip
                                            // title={getTooltipText()}
                                            placement={"right"}
                                        >
                                        </Tooltip>
                                    </div>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле не должно быть пустым",
                                    },
                                ]}
                            >
                                <Input value={cashBackTitle} disabled/>
                            </Form.Item>
                            :
                            <Form.Item
                                label={
                                    <div>
                                        <span>Выберите акцию</span>
                                        <Tooltip
                                            // title={getTooltipText()}
                                            placement={"right"}
                                        >
                                        </Tooltip>
                                    </div>
                                }
                                name="cashbackActionId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле не должно быть пустым",
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    value={selectedDeclaration}
                                    filterOption={false}
                                    suffixIcon={<Icon
                                        component={SelectIconComponent}
                                        style={{marginTop: "2px", fontSize: "10px"}}
                                    />}
                                    onSearch={(value: any) => onSearch(value)}
                                    onChange={(e) => setSelectedDeclaration(e)}
                                >
                                    {cashBackList?.slice(1, 20)?.map((option: any) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>


                            </Form.Item>
                        }

                        <Form.Item
                            label={
                                <div>
                                    <span>Введите ссылку, где находится чек об оплате рекламы</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="linkOnPaymentUrl"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите ссылку, где будет опубликован товар</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="linkOnPublicUrl"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    label={*/}
                        {/*        <div>*/}
                        {/*            <span>Введите ссылку на того, кто оплатил</span>*/}
                        {/*            <Tooltip*/}
                        {/*                // title={getTooltipText()}*/}
                        {/*                placement={"right"}*/}
                        {/*            >*/}
                        {/*            </Tooltip>*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*    name="linkOnPayedWhom"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Поле не должно быть пустым",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите на чье имя было оплачено</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="whomPayedName"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Введите сумму оплаты</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="sumPayed"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не должно быть пустым",
                                },
                            ]}
                        >
                            <Input
                                type={"number"}
                            />
                        </Form.Item>
                        <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    style={{width: "100%"}}
                                    htmlType={"submit"}
                                    loading={createLoading}
                                >
                                    Добавить
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    className="products-edit-button-exit"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    onClick={() =>{
                                        form.resetFields()
                                        onClose()
                                    }}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            </>
        )
    }

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            {renderCreateExpensesAd()}
        </div>
    );
}
