import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Input,
    InputNumber, InputRef, message,
    Row,
    Select, Space, Spin,
    Switch,
} from "antd";
import {
    getAnalyticsProductSizesByArticle,
} from "api/analytics";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {useDebounce} from "use-debounce";
import useArticleWbData from "../../financial-results/hooks/useArticleWbData";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";
import useSellerList from "../../sellers/hooks/useSellerList";
import {parseDateFromMiddle} from "../../analytics/helpers/parseDateString";
import useCostPriceById from "../hooks/useCostPriceById";
import {useAddCostPrice} from "../hooks/useAddCostPrice";
import {LoadingOutlined} from "@ant-design/icons";
import {useUpdateCostPrice} from "../hooks/useUpdateCostPrice";

const {Option} = Select;

export default function EditCostPriceModal({
                                               id,
                                               productId,
                                               onClose,
                                           }: any) {

    const [form] = Form.useForm<{}>();
    const [selectedArticle, setSelectedArticle] = useState<any>(null)
    const [sizesData, setSizesData] = useState<any>([])
    const [selectedSeller, setSelectedSeller] = useState<any>();
    const articleValue = Form.useWatch("articule", form);

    const {
        handleUpdate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useUpdateCostPrice()

    const {
        articleWb,
        setSellerId,
        search,
        setSearch,
    } = useArticleWbData()

    const {
        sellers,
        search: searchSeller,
        setSearch: setSearchSeller,
    } = useSellerList();

    const {
        costPriceById,
        setId,
        isLoading: isLoadingData
    } = useCostPriceById()

    const onFinish = (value: any) => {

        const parsedDate = parseDateFromMiddle(value?.numberDT);

        if (parsedDate) {
            console.log({
                productSizeId: value?.productSizeId,
                investorId: value?.investorId,
                cost: value?.cost,
                count: value?.count,
                numberDT: value?.numberDT,
                appearedFrom: parsedDate.format('DD.MM.YYYY'),
                productId: productId
            })
            handleUpdate({
                productSizeId: value?.productSizeId,
                investorId: value?.investorId,
                cost: Number(value?.cost),
                count: Number(value?.count),
                numberDT: value?.numberDT,
                appearedFrom: parsedDate,
                productId: productId
            }, id)
        } else {
            message.error('Вы ввели неверный формат номера ДТ');
        }
    }

    useEffect(() => {
        getAnalyticsProductSizesByArticle(articleValue).then((data) => {
            setSizesData(data)
        }).catch((e) => {
            console.log(e?.response?.data?.message)
            if (e?.response?.data?.message === 'Product not found') {
                message.error('Размеры не были найдены по данному артикулу')
            }
        });
    }, [selectedArticle, articleValue])

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно добавили новую себестоимость')
            onClose()
        }
        if (isError) {
            message.error(errorMessage)
        }
    }, [isSuccess, isError])

    useEffect(() => {
        setId(id)
    }, [id])

    useEffect(() =>{
        form.setFieldsValue({
            investorId: costPriceById?.investorId,
            articule: costPriceById?.wbArticle,
            productSizeId: costPriceById?.productSizeId,
            cost: costPriceById?.cost,
            count: costPriceById?.count,
            numberDT: costPriceById?.numberDT,

        })
    },[costPriceById])


    if (isLoadingData){
        return <div
            style={{
                padding: "150px",
                textAlign: "center",
            }}
        >
            <Spin
                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
            />
        </div>
    }

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактирование себестоимости"} onClose={onClose}/>
            <Form
                onFinish={(values) => onFinish(values)}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    rules={[{required: true}]}
                    name={"investorId"}
                    label={"Инвестор"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSeller}
                        placeholder="Инвестор"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearchSeller(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.map((option: any) => {
                            return (
                                <Option key={option?.user_id} value={option?.user_id}>
                                    {option.company_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"articule"}
                    label={"Артикул"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedArticle}
                        placeholder="Артикул"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearch(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedArticle(e)
                        }}
                    >
                        {articleWb?.slice(0, 10)?.map((option: any) => {
                            return (
                                <Option key={option?.nmId} value={option?.nmId}>
                                    {option.articule}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"productSizeId"}
                    label={"Размер"}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedArticle}
                        placeholder="Размер"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onSearch={(value: any) => setSearch(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedArticle(e)
                        }}
                    >
                        {sizesData?.slice(0, 10)?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.id}>
                                    {option.size}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"cost"}
                    label={"себестоимость (руб.)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"count"}
                    label={"Кол-во прихода (шт)"}
                >
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name={"numberDT"}
                    label={"Номер ДТ"}
                >
                    <Input/>
                </Form.Item>

                <Col style={{display: "flex", gap: "15px"}}>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        htmlType={"submit"}
                        loading={isLoading}
                    >
                        Сохранить
                    </Button>
                    <Button
                        type={"primary"}
                        ghost
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </Col>
            </Form>
        </div>
    );
}
