import { Col, Row } from "antd";
import React, {useState} from "react";
import ProductsCoordinatorsTable from "../../features/product/ProductsCoordinatorsTable";

export default function ProductsAnalyticsPage() {

    return (
        <Row style={{ padding: "10px 20px" }}>
            <Col className={"container"} span={24}>
                <ProductsCoordinatorsTable />
            </Col>
        </Row>
    );
}