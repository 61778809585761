import {useQuery} from "react-query";
import {backend} from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useUserListByRole() {

    const [roleId, setRoleId] = useState(3)

    const [searchValue, setSearchValue] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchValue, 1000);

    const {data: users, ...options} = useQuery(
        [
            "USER_LIST_By_ROLE",
            roleId,
            debouncedSearch

        ],
        () => backend.user.getUserListByRole(
            roleId.toString(),
            debouncedSearch
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        users,
        setRoleId,
        searchValue,
        setSearchValue,
        ...options,
    };
}
