import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";

export default function useDataForRange() {

    const [sellerId, setSellerId] = useState<any>('')

    const { data: rangeData, ...options } = useQuery(
        [
            "FIN_RES_RANGE_DATA",
            sellerId,
        ],
        () => backend.financialResult.getDataForRange(
            sellerId
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        rangeData,
        sellerId,
        setSellerId,
        ...options,
    };
}
