import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useSellerDelete} from "../../sellers/hooks/useSellerDelete";
import set = Reflect.set;

export default function SuccessUploadModal({onClose, backendData, setBackendData}: any) {

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Результат загрузки"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    <Form layout={"vertical"}>
                        <Form.Item label={"Общее количество"}>
                            <Input disabled={true} value={backendData?.totalCount}/>
                        </Form.Item>
                        <Form.Item label={"Кол-во не загруженных"}>
                            <Input disabled={true} value={backendData?.failedCount}/>
                        </Form.Item>
                        <Form.Item label={"Кол-во загруженных"}>
                            <Input disabled={true} value={backendData?.createdCount}/>
                        </Form.Item>
                        <Form.Item label={"ID первого добавленного"}>
                            <Input disabled={true} value={backendData?.firstAddedId}/>
                        </Form.Item>
                        <Form.Item label={"ID последне добавленного"}>
                            <Input disabled={true} value={backendData?.lastAddedId}/>
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{
                                    fontSize: "12px",
                                    width: "100%",
                                }}
                                onClick={async () => {
                                    await setBackendData({
                                        totalCount: 0,
                                        failedCount: 0,
                                        createdCount: 0,
                                        firstAddedId: 0,
                                        lastAddedId: 0
                                    })
                                    await onClose()
                                }}
                            >
                                Ок
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
