import {Button, Col, Form, Input, Row, Upload} from "antd";
import {useAuthStore} from "features/auth/hooks/useAuthStore";
import ModalHeader from "features/shared/ModalHeader";
import {useCashbackOperationUpdate} from "../hooks/useCashbackOperationUpdate";
import {useCashbackOperationSixUpdate} from "../hooks/useCashbackOperationSixUpdate";
import {useEffect, useState} from "react";
import paperСlip from '../../../assets/images/paperСlip.svg'
import {UploadOutlined} from '@ant-design/icons';

const {TextArea} = Input;

interface ConditionModificationModalProps {
    operationId: number;
    onClose: any;
}

export default function ConditionModificationModalContentOperationSix({
                                                                          operationId,
                                                                          onClose,
                                                                      }: ConditionModificationModalProps) {
    const [form] = Form.useForm<{}>();

    const {state} = useAuthStore();
    const {
        handleUpdate: handleUpdateSix,
        isSuccess,
        isLoading
    } = useCashbackOperationSixUpdate();

    const [fileName, setFileName] = useState('');
    const [fileUpload, setFileUpload] = useState<any>('');

    const handleFileChange = (info: any) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0];

            console.log(info.fileList[0]?.originFileObj)
            setFileUpload(file?.originFileObj);

            setFileName(file.name);
        } else {
            setFileName('');
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setFileName('')
            setFileUpload('')
            form.setFieldsValue({
                paymentDetails: ''
            })
        }
    }, [])

    const changeOperationStatusSix = (values: any) => {
        const body = {
            analyticId: state.user.id,
            file: fileUpload,
            paymentDetails: values?.paymentDetails
        };
        // console.log(body)
        // console.log(operationId)
        handleUpdateSix(operationId, body, onClose);
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Перевод оплаты"} onClose={onClose}/>
            <Form
                onFinish={(values) => changeOperationStatusSix(values)}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name={"paymentDetails"}
                    label={"Реквизиты для оплаты"}
                    style={{width: "451px"}}
                >
                    <Input/>
                </Form.Item>
                <div style={{marginBottom: 10}}>
                    <Upload onChange={handleFileChange}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer'}}>
                            <img src={paperСlip} alt=""/>
                            <label
                                style={{
                                    color: '#4C78EE'
                                }}
                            >
                                Прикрепить подтверждающий документ
                            </label>
                        </div>
                    </Upload>
                </div>
                <Row gutter={16}>
                    <Col span={24}>
                        <Button
                            disabled={true}
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%", marginBottom: 10}}
                            htmlType={"submit"}
                        >
                            Оплатить с системы
                        </Button>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            disabled={!fileName}
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                        >
                            Добавить
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            loading={isLoading}
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{width: "100%"}}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
