import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const useUserUpdateAvatar = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: handleUpdateCurrentAvatar,
        isLoading: updateLoadingAvatar,
        isError,
        isSuccess,
    } = useMutation(backend.user.updateCurrentUserAvatar, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`USER_CURRENT_DATA`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, handleCloseModal?: any) => {
            try {
                await handleUpdateCurrentAvatar(values);
                if (handleCloseModal) {
                    handleCloseModal();
                }
            } catch (e: any) {
                const errorData = await e.response.data;

                if (errorData) {
                    setErrorMessage(
                        "Произошла ошибка при редактировании пользователя"
                    );
                }

            }
        },
        [handleUpdateCurrentAvatar]
    );

    return {
        handleUpdateCurrentAvatar: handleUpdate,
        updateLoadingAvatar,
        isError,
        errorMessage,
        isSuccess,
    };
};
