import { Input, MenuProps } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as filterSortIcon } from "assets/images/filter-sort-icon.svg";
import { ReactComponent as searchIcon } from "assets/images/search-icon.svg";
import React from "react";

export default function useCashbacksUsersTableTabFilters() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По id",
            key: "id",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "id:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "id:desc",
                },
            ],
        },
        {
            label: "По имени",
            key: "name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "first_name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "first_name:desc",
                },
            ],
        },
        {
            label: "По фамилии",
            key: "l_name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "l_name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "l_name:desc",
                },
            ],
        },
        {
            label: "По telegram",
            key: "tg",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "tg:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "tg:desc",
                },
            ],
        },
        {
            label: "По номеру телефона",
            key: "phone",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "phone:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "phone:desc",
                },
            ],
        },
        {
            label: "По email",
            key: "email",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "email:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "email:desc",
                },
            ],
        },
    ];

    return { filterMenuItems };
}
