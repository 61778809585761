import {useCallback, useState} from "react";
import { useMutation, useQueryClient } from "react-query";
import {backend} from "../../apiExtraInfo";
import {useAuthStore} from "../../../auth/hooks/useAuthStore";

export const useDeleteEmission = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<any>("");

    const {state} = useAuthStore();


    const { mutateAsync, ...options } = useMutation(
        backend.emissions.deleteEmission,
        {
            onSuccess: async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["EMISSIONS_DATA"]),
                ]);
            },
            onError: async (e: any) => {
                setErrorMessage(e?.response?.data?.detail || e?.response?.data?.message)
            },
        }
    );

    const handleDelete = useCallback(
        async (id: any) => {
            await mutateAsync(id, state.user.id);
        },
        [mutateAsync]
    );

    return {
        handleDelete,
        errorMessage,
        ...options,
    };
};
