import React from 'react';

const LockIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 10.5H6C5.46957 10.5 4.96086 10.7107 4.58579 11.0858C4.21071 11.4609 4 11.9696 4 12.5V19.5C4 20.0304 4.21071 20.5391 4.58579 20.9142C4.96086 21.2893 5.46957 21.5 6 21.5H18C18.5304 21.5 19.0391 21.2893 19.4142 20.9142C19.7893 20.5391 20 20.0304 20 19.5V12.5C20 11.9696 19.7893 11.4609 19.4142 11.0858C19.0391 10.7107 18.5304 10.5 18 10.5H16M8 10.5V7.5C8 6.43913 8.42143 5.42172 9.17157 4.67157C9.92172 3.92143 10.9391 3.5 12 3.5C13.0609 3.5 14.0783 3.92143 14.8284 4.67157C15.5786 5.42172 16 6.43913 16 7.5V10.5M8 10.5H16M12 14.5V17.5"
                stroke="#E4DEDE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default LockIcon;