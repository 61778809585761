import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function useKeyWordsChartData(id: any) {

    const end = dayjs().format('DD.MM.YYYY HH:mm');
    const start = dayjs().subtract(12, 'hours').format('DD.MM.YYYY HH:mm');

    const [period_start_date, setPeriod_start_date] = useState<any>(start);
    const [period_end_date, setPeriod_end_date] = useState<any>(end);
    const [keywordIds , setKeywordIds] = useState<any>([]);

    const {data: data, ...options} = useQuery(
        [
            "KEY_WORDS_DATA_V2",
            id,
            keywordIds,
            period_start_date,
            period_end_date,
        ],
        () =>
            backend.analytics.getAnalyticsKeyWordsChartDataV2(
                id.toString(),
                keywordIds,
                period_start_date,
                period_end_date,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        data,
        keywordIds,
        setKeywordIds,
        setPeriod_start_date,
        setPeriod_end_date,
        ...options,
    };
}
