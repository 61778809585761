import dayjs from 'dayjs';

export function parseDateFromMiddle(input: string): dayjs.Dayjs | null {
    // Разбиваем строку по "/"
    const parts = input.split('/');

    // Проверяем, что строка содержит три части
    if (parts.length !== 3) {
        console.error('Ошибка: Неверный формат данных');
        return null;
    }

    // Извлекаем среднюю часть, которая представляет дату
    const datePart = parts[1];

    // Проверяем, что дата четырехзначная
    if (datePart.length !== 6) {
        console.error('Ошибка: Неверный формат даты');
        return null;
    }

    // Извлекаем день, месяц и год из даты
    const day = parseInt(datePart.slice(0, 2));
    const month = parseInt(datePart.slice(2, 4));
    const year = 2000 + parseInt(datePart.slice(4, 6)); // Добавляем 2000, так как год представлен в двузначном формате

    // Проверяем, что день и месяц валидны
    if (isNaN(day) || isNaN(month) || day < 1 || day > 31 || month < 1 || month > 12) {
        console.error('Ошибка: Неверные день или месяц');
        return null;
    }

    // Пытаемся создать объект даты с помощью dayjs
    const date = dayjs(`${year}-${month}-${day}`);

    // Проверяем, что объект даты успешно создан
    if (!date.isValid()) {
        console.error('Ошибка: Неверные данные для создания даты');
        return null;
    }

    return date;
}