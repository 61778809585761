import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function useAnalyticsKeyWordsData(productId: any, endDay: any) {


    const end = dayjs().format('DD.MM.YYYY HH:mm');
    const start = endDay
        ? dayjs().subtract(12, 'hours').format('DD.MM.YYYY HH:mm')
        : dayjs().subtract(1, 'week').format('DD.MM.YYYY HH:mm');

    const [period_start_date, setPeriod_start_date] = useState<any>(start);
    const [period_end_date, setPeriod_end_date] = useState<any>(end);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("5");

    const [isShoWithDataMod, setIsShoWithDataMod] = useState<any>(false);

    const {data: data, ...options} = useQuery(
        [
            "ANALYTICS_KEY_WORDS_DATA",
            productId,
            period_start_date,
            period_end_date,
            currentPage,
            currentPageSize,
            isShoWithDataMod
        ],
        () =>
            backend.analytics.getAnalyticsKeyWordsData(
                productId?.toString(),
                period_start_date,
                period_end_date,
                currentPage?.toString(),
                currentPageSize,
                isShoWithDataMod?.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        setPeriod_start_date,
        setPeriod_end_date,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        isShoWithDataMod,
        setIsShoWithDataMod,
        ...options,
    };
}
