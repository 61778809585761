import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useEmissionMainTableFilter() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "Дата загруки",
            key: "custom_passed_at",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "custom_passed_at:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "custom_passed_at:desc",
                },
            ],
        },
        {
            label: "По дате создания",
            key: "created_at",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "created_at:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "created_at:desc",
                },
            ],
        },
        {
            label: "По дате обновления",
            key: "updated_at",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "updated_at:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "updated_at:desc",
                },
            ],
        },
        {
            label: "gtin",
            key: "gtin",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "gtin:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "gtin:desc",
                },
            ],
        },
        {
            label: "Название эмиссии",
            key: "title",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "title:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "title:desc",
                },
            ],
        },
    ];

    return {filterMenuItems};
}
