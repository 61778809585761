import { Button, Col, Form, Input, Row, Select, Table } from "antd";
import trashCan from '../../../../assets/images/trashCan.svg'

const data = [
    {
        barcode: "1234567890",
        boxCount: "10",
    },
    {
        barcode: "1234567890",
        boxCount: "10",
    },
    {
        barcode: "1234567890",
        boxCount: "10",
    },
    {
        barcode: "1234567890",
        boxCount: "10",
    },
    {
        barcode: "1234567890",
        boxCount: "10",
    },
];

const data2 = [
    {
        barcode: "1234567890",
        actualCount: "10",
        plannedCount: "10",
    },
    {
        barcode: "1234567890",
        actualCount: "10",
        plannedCount: "10",
    },
    {
        barcode: "1234567890",
        actualCount: "10",
        plannedCount: "10",
    },
    {
        barcode: "1234567890",
        actualCount: "10",
        plannedCount: "10",
    },
    {
        barcode: "1234567890",
        actualCount: "10",
        plannedCount: "10",
    },
];

const columns = [
    {
        title: "Баркод(штрихкод)",
        dataIndex: "barcode",
        key: "barcode",
        width: "50%",
    },
    {
        title: "Кол-во",
        dataIndex: "boxCount",
        key: "boxCount",
        width: "50%",
    },
];

const columns2 = [
    {
        title: "Баркод(штрихкод)",
        dataIndex: "barcode",
        key: "barcode",
        width: "31%",
    },
    {
        title: "Кол-во",
        dataIndex: "actualCount",
        key: "actualCount",
        width: "31%",
    },
    {
        title: "Кол-во",
        dataIndex: "plannedCount",
        key: "plannedCount",
        width: "31%",
    },
    {
        title: "",
        dataIndex: "",
        key: "",
        width: "7%",
        render: () => <img src={trashCan} alt="" style={{cursor: 'pointer'}}/>,
    },
];

export default function AdditionalDeliveriesTable() {

    return (
        <div>
            <Row gutter={10}>
                <Col>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{ width: "222px" }}
                    >
                        Создать накладную
                    </Button>
                </Col>
                <Col>
                    <Button
                        className={"button"}
                        type={"primary"}
                        ghost
                        style={{ width: "222px" }}
                    >
                        Скачать накладную
                    </Button>
                </Col>
            </Row>
            <Row
                className={"container"}
                style={{ marginTop: "20px", minHeight: "500" }}
            >
                <Col span={12} style={{ padding: "14px 40px" }}>
                    <Form layout={"vertical"} className={"form"}>
                        <Form.Item
                            label={"Выберите или напишите артикул(вб,китайский), штрихкод товара"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Укажите размер товара"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label={"Укажите кол-во товара"}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"Укажите тип коробки"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label={"Укажите планируемое кол-во товара в коробке"}>
                            <Input />
                        </Form.Item>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{
                                fontSize: "12px",
                                width: "171px",
                            }}
                        >
                            Добавить в таблицу
                        </Button>
                    </Form>
                </Col>
                <Col span={12}>
                    <Table
                        className={"additional-deliveries-table"}
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
                <Col span={12} style={{ padding: "14px 40px" }}>
                    <Table
                        className={"additional-deliveries-table"}
                        dataSource={data2}
                        columns={columns2}
                        pagination={false}
                    />
                </Col>
            </Row>

        </div>
    );
}
