import {Button, Col, Dropdown, Form, Input, message, Modal, Row, Select, Table} from "antd";
import trashCan from '../../../../assets/images/trashCan.svg'
import useArticleBySearch from "../../hooks/acceptance/useArticleBySearch";
import React, {useEffect, useState} from "react";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import useSizeByArticle from "../../hooks/acceptance/useSizeByArticle";
import useAcceptanceData from "../../hooks/acceptance/useAcceptanceData";
import bucket from '../../../../assets/images/bucket.svg'
import {useAddAcceptanceToTable} from "../../hooks/acceptance/useAddAcceptanceToTable";
import {useDeleteAcceptance} from "../../hooks/acceptance/useDeleteAcceptance";
import {useDeleteAllAcceptance} from "../../hooks/acceptance/useDeleteAllAcceptance";
import SendToCheck from "../../modal/acceptance/SendToCheck";
import useDeclarationList from "../../hooks/acceptance/useDeclarationList";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

const {Option} = Select;

export default function AcceptanceTable() {

    const [form] = Form.useForm();

    const [selectedArticle, setSelectedArticle] = useState<any>('')
    const [selectedDeclaration, setSelectedDeclaration] = useState<any>('')

    const [modalToSave, setModalToSave] = useState<any>({
        isOpen: false,
        typeToSend: null
    })

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const {
        articles,
        search,
        debouncedSearch,
        setSearch,
        setLimit,
        declarationId,
        setDeclarationId,
    } = useArticleBySearch()

    const {
        declarationList
    } = useDeclarationList()

    const {
        sizes,
        articleChine,
        setArticleChine,
    } = useSizeByArticle()

    const {
        acceptance,
        isLoading
    } = useAcceptanceData()

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage
    } = useAddAcceptanceToTable()

    const {
        handleDelete,
        errorMessage: errorDeleteMessage,
        isError: isErrorDelete,
        isSuccess: isSuccessDelete,
        isLoading: isLoadingDelete
    } = useDeleteAcceptance()

    const {
        handleDelete: handleDeleteAllAcceptance,
        errorMessage: errorAllDeleteMessage,
        isError: isErrorDeleteAll,
        isSuccess: isSuccessDeleteAll,
        isLoading: isLoadingDeleteAll
    } = useDeleteAllAcceptance()


    const handleAddToTable = (value: any) => {
        handleCreate({
            did: selectedDeclaration,
            article: selectedArticle,
            size: value.size,
            plan: value.plan
        })
    }
    const handleDeleteOne = (id: any) => {
        handleDelete(id)
    }

    const columns = [
        {
            title: "Артикул",
            dataIndex: "article",
            key: "article",
            width: "18%",
        },
        {
            title: "Декларация",
            dataIndex: "declaration_title",
            key: "declaration_title",
            width: "18%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "19%",
        },
        {
            title: "Планируемое кол-во",
            dataIndex: "planned_quantity",
            key: "planned_quantity",
            width: "19%",
        },
        {
            title: "Фактическое кол-во",
            dataIndex: "actual_quantity",
            key: "actual_quantity",
            width: "19%",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: "7%",
            render: (text: any, record: any) => (
                <div
                    style={{
                        cursor: 'pointer',
                        pointerEvents: isLoadingDelete || isLoadingDeleteAll ? "none" : 'auto',
                        opacity: isLoadingDelete || isLoadingDeleteAll ? 0.5 : 1,
                    }}
                    onClick={() => {
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить строку?',
                            onPress: () => handleDeleteOne(record?.id)
                        })
                    }}

                >
                    <img src={bucket} alt=""/>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Операция выполнена успешно')
            form.resetFields()
        }
    }, [isSuccessCreate])
    useEffect(() => {
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isSuccessDelete) {
            message.success('Операция выполнена успешно')
        }
    }, [isSuccessDelete])
    useEffect(() => {
        if (isErrorDelete) {
            message.error(errorDeleteMessage)
        }
    }, [isErrorDelete])

    useEffect(() => {
        if (isSuccessDeleteAll) {
            message.success('Операция выполнена успешно')
        }
    }, [isSuccessDeleteAll])
    useEffect(() => {
        if (isErrorDeleteAll) {
            message.error(errorAllDeleteMessage)
        }
    }, [isErrorDeleteAll])

    useEffect(() => {
        setDeclarationId(selectedDeclaration)
    }, [selectedDeclaration])

    useEffect(() => {
        setArticleChine(selectedArticle)
    }, [selectedArticle])

    useEffect(() => {
        setLimit(10)
    }, [])

    return (
        <div>
            <Row gutter={10} style={{
                backgroundColor: '#f8f8f8',
                paddingBottom: 20
            }}>
                <Col>
                    <Button
                        disabled={acceptance?.result?.length === 0}
                        className={"button"}
                        type={"primary"}
                        ghost
                        style={{width: "222px"}}
                        onClick={() => setModalToSave({
                            isOpen: true,
                            typeToSend: 'save'
                        })}
                    >
                        Сохранить файл отгрузки
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={acceptance?.result?.length === 0}
                        className={"button"}
                        type={"primary"}
                        ghost
                        style={{width: "222px"}}
                    >
                        Отправить на ТСД
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={acceptance?.result?.length === 0}
                        loading={isLoadingDeleteAll}
                        style={{
                            background: "red",
                            borderColor: "yellow",
                            width: "222px",
                            color: '#fff'
                        }}
                        className={"button"}
                        type={"primary"}
                        onClick={() => setIsModalVisible({
                            isOpen: true,
                            text: 'удалить очистить таблицу?',
                            onPress: () => handleDeleteAllAcceptance()
                        })}
                    >
                        Очистить таблицу
                    </Button>
                </Col>
            </Row>
            <Row
                className={"container"}
                style={{marginTop: "20px", minHeight: "500"}}
            >
                <Col span={12} style={{padding: "14px 40px"}}>
                    <Form layout={"vertical"} className={"form"} onFinish={handleAddToTable} form={form}>
                        <Form.Item
                            name={'declaration'}
                            label={"Выберите декларацию"}
                        >
                            <Select
                                showSearch
                                value={selectedDeclaration}
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => setSearch(value)}
                                onChange={(e) => setSelectedDeclaration(e)}
                            >
                                {declarationList?.map((option: any) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={'article'}
                            label={"Выберите артикул(вб,китайский), штрихкод товара"}
                        >
                            <Select
                                showSearch
                                value={selectedArticle}
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => setSearch(value)}
                                onChange={(e) => setSelectedArticle(e)}
                            >
                                {articles?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={'size'}
                            label={"Укажите размер товара"}
                        >
                            <Select
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                            >
                                {sizes?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                        <Form.Item label={"Укажите кол-во товара"} name={'plan'}>
                            <Input type={'number'}/>
                        </Form.Item>
                        <Button
                            loading={createLoading}
                            type={"primary"}
                            className={"button"}
                            htmlType={"submit"}
                            style={{
                                fontSize: "12px",
                                width: "171px",
                            }}
                        >
                            Добавить в таблицу
                        </Button>
                    </Form>
                </Col>
                <Col span={12}>
                    <Table
                        loading={isLoading}
                        className={"additional-deliveries-table"}
                        columns={columns}
                        dataSource={acceptance?.result ?? []}
                        pagination={false}
                    />
                </Col>
            </Row>

            <Modal
                open={modalToSave.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <SendToCheck
                    onClose={() => setModalToSave({typeToSend: null, isOpen: false})}
                    typeToSend={modalToSave.typeToSend}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
