import { useQuery } from "react-query";
import { backend } from "api";
import { useState } from "react";

export default function useAnalyticsNoteData() {
    const [id , setId] = useState<number>(1);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");

    const { data: notes, ...options } = useQuery(
        ["ANALYTICS_NOTE_DATA", id],
        () =>
            backend.analytics.getAnalyticsNotes(
                id.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        id,
        setId,
        notes,

        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
