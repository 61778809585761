import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Spin, Table } from 'antd'
import { EditOutlined, LoadingOutlined, InboxOutlined } from '@ant-design/icons'
import useSettingsData from './hooks/settings/useSettingsData'
import { EditModal } from './modals/EditSupplierModal'
import SettingItem from './components/SettingsSuppliesItem'
import dayjs from 'dayjs'
import { PickSeller } from './components/PickSeller'
import { ConfirmationModal } from './modals/ConfirmationModal'

const { Option } = Select

const SettingsMirrorSuppliesDataTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [selectedUser, setSelectedUser] = useState<any>(null)

	const [isOwner, setIsOwner] = useState<boolean>(false)
	const {
		settingsData,
		setDataType,
		selectedSeller,
		setSelectedSeller,
		sellers,
		isLoading,
		setPvz,
	} = useSettingsData()

	const { settingsData: isOwnerData, setDataType: setDataTypeOwner } =
		useSettingsData()

	const [data, setData] = useState<any[] | undefined>(settingsData?.offices)
	const [isOpen, setIsOpen] = useState<boolean>(false)

	useEffect(() => {
		setDataType('get-pvz')
		setDataTypeOwner('am-i-owner')
		if (settingsData) {
			setData(settingsData.offices) //offers
		}
		if (isOwnerData) {
			setIsOwner(isOwnerData.is_owner)
		}
	}, [settingsData, isOwnerData])

	const handleCancel = () => {
		setIsEditModalVisible(false)
		setSelectedUser(null)
	}

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			align: 'center' as const,

			width: 200,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			key: 'address',
			width: 200,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'ID владельца',
			dataIndex: 'ownerID',
			key: 'ownerID',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Главный',
			dataIndex: 'isMain',
			key: 'isMain',
			width: 50,
			align: 'center' as const,
			render: (_: any, record: any) => <Checkbox checked={record.isMain} />,
		},
		{
			title: 'Активный',
			dataIndex: 'isSiteActive',
			key: 'isSiteActive',
			width: 50,
			align: 'center' as const,
			render: (_: any, record: any) => (
				<Checkbox checked={record.isSiteActive} />
			),
		},
		{
			title: 'Время работы',
			dataIndex: 'workTimeName',
			key: 'workTimeName',
			width: 150,
			align: 'center' as const,

			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		// {
		// 	title: 'Дата создания',
		// 	dataIndex: 'createdAt',
		// 	key: 'createdAt',
		// 	width: 150,
		// 	align: 'center' as const,

		// 	render: (createdAt: any) => dayjs(createdAt).format('DD.MM.YYYY'),
		// },
		// {
		// 	title: 'Дата обновления',
		// 	dataIndex: 'updatedAt',
		// 	key: 'updatedAt',
		// 	width: 150,
		// 	align: 'center' as const,

		// 	render: (updatedAt: any) => dayjs(updatedAt).format('DD.MM.YYYY'),
		// },
	]

	return (
		<>
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row gutter={6} align={'middle'} justify={'end'}>
					<Col style={{ width: '250px' }}>
						<PickSeller
							selectedSeller={selectedSeller}
							setSelectedSeller={setSelectedSeller}
						/>
					</Col>
					<Col>
						<Button
							onClick={() => setIsOpen(true)}
							className='table-header-button'
							type='primary'
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginLeft: '10px',
							}}
						>
							Установить наши склады
							<InboxOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
						</Button>
					</Col>
				</Row>
				<Row className={'container'} style={{ marginTop: '10px' }}>
					{isLoading ? (
						<div
							style={{
								margin: '0 auto',
								padding: '150px',
								textAlign: 'center',
							}}
						>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						</div>
					) : data?.length === 0 ? (
						<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
					) : (
						<>
							<Col span={24}>
								<Table
									columns={columns}
									dataSource={data}
									pagination={false}
									rowKey={(record: any) => record.id}
								/>
							</Col>
						</>
					)}
				</Row>
			</div>
			<ConfirmationModal
				title={'Вы уверены, что хотите обновить склады выбранного продавца?'}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				confirm={setPvz}
			/>
		</>
	)
}

export default SettingsMirrorSuppliesDataTable
