import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useProductList() {

    const [search, setSearch] = useState<any>('')
    const [notEmptyMode, setNotEmptyMode] = useState<any>(true)
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: products, ...options } = useQuery(
        [
            "PRODUCTS_LIST",
            debouncedSearch,
            notEmptyMode
        ],

        () => backend.product.getProducts(
            debouncedSearch,
            notEmptyMode
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        products,
        search,
        setSearch,
        setNotEmptyMode,
        ...options,
    };
}
