import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function useKeyWordsChartDataWithLines(productId: any) {


    const [keywordIds , setKeywordIds] = useState<any>([]);
    const [selectedAds, setSelectedAds] = useState<any>(null)

    const {data: data, ...options} = useQuery(
        [
            "KEY_WORDS_DATA_WITH_LINES",
            productId,
            selectedAds,
            keywordIds
        ],
        () =>
            backend.analytics.getAnalyticsKeyWordsChartDataWithLines(
                productId.toString(),
                selectedAds.toString(),
                keywordIds
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        data,
        keywordIds,
        setKeywordIds,
        selectedAds,
        setSelectedAds,
        ...options,
    };
}
