import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import { ReactComponent as selectIcon } from "assets/images/select-icon.svg";
import { ReactComponent as questionTooltipIcon } from "assets/images/question-tooltip-icon.svg";
import { useSellerCreate } from "features/sellers/hooks/useSellerCreate";
import React, { useEffect, useState } from "react";
import useUserList from "features/users/hooks/useUserList";

interface UserOption {
    label: string;
    value: string;
}

const {Option} = Select;

export default function AddSellerModalContent({ onClose }: any) {

    const [form] = Form.useForm<{}>();

    const getTooltipText = () => {
        return (
            <>
                <p style={{ marginTop: 0 }}>
                    Для аналитики ваших продаж на Wildberries необходимо
                    подключить ваш кабинет поставщика по API-ключу.
                </p>
                <p>
                    Если ключа нет, то выберите в левом меню “Настройки” -{">"}{" "}
                    “Доступ к API”
                </p>
                <p>
                    После введите Ваш ключ в соседней форме на этой странице и
                    нажмите "Подключить магазин".
                </p>
                <p style={{ marginBottom: 0 }}>
                    Если Вы ранее работали с сервисами статистики Wildberries,
                    то по API-ключу статистика будет доступна за последние 90
                    дней. Если Вы ранее не работали с сервисами статистики
                    Wildberries, то статистика по API-ключу будет доступна c
                    момента создания API-ключа.
                </p>
            </>
        );
    };

    const { handleCreate, isError, errorMessage, isLoading } =
        useSellerCreate();

    const [selectedUser, setSelectedUser] = useState<any>(null);
    const {
        users,
        search,
        setSearch,
        setSortRoleId,
    } = useUserList();

    return (
        <div className={"modal-wrapper"} style={{ padding: "30px" }}>
            <ModalHeader title={"Добавление продавца"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Form
                layout={"vertical"}
                onFinish={(values) => handleCreate(values, onClose)}
                form={form}
            >
                <Form.Item
                    label={"Введите название юридического лица"}
                    name="companyName"
                    rules={[
                        {
                            required: true,
                            message:
                                "Пожалуйста, введите название юридического лица",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        <div>
                            <span>Введите стандартный WB ключ</span>
                            <Tooltip
                                title={getTooltipText()}
                                placement={"bottomLeft"}
                            >
                                {" "}
                                <Icon
                                    style={{ marginLeft: "7px" }}
                                    component={questionTooltipIcon}
                                />
                            </Tooltip>
                        </div>
                    }
                    name="wbToken"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите стандартный WB ключ",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        <div>
                            <span>Введите запасной WB ключ</span>
                            <Tooltip
                                title={getTooltipText()}
                                placement={"right"}
                            >
                                {" "}
                                <Icon
                                    style={{ marginLeft: "7px" }}
                                    component={questionTooltipIcon}
                                />
                            </Tooltip>
                        </div>
                    }
                    name="standartWbToken"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите запасной WB ключ",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        <div>
                            <span>Введите WB ключ аналитики</span>
                            <Tooltip
                                title={getTooltipText()}
                                placement={"right"}
                            >
                                {" "}
                                <Icon
                                    style={{ marginLeft: "7px" }}
                                    component={questionTooltipIcon}
                                />
                            </Tooltip>
                        </div>
                    }
                    name="statisticsWbToken"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите WB ключ аналитики",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        <div>
                            <span>Введите рекламный WB ключ</span>
                            <Tooltip
                                title={getTooltipText()}
                                placement={"right"}
                            >
                                {" "}
                                <Icon
                                    style={{ marginLeft: "7px" }}
                                    component={questionTooltipIcon}
                                />
                            </Tooltip>
                        </div>
                    }
                    name="adsWbToken"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите рекламный WB ключ",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        "Выберите инвестора, за которым будет закреплен продавец"
                    }
                    name="userId"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, выберите инвестора",
                        },
                    ]}
                >
                    <Select
                        // className="table-header-select table-header-select-no-offset"
                        // id="analytics-select"
                        style={{
                            width: '100%'
                        }}
                        filterOption={false}
                        suffixIcon={<Icon component={selectIcon}/>}
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e?.toString())}
                        showSearch
                        onSearch={(e) => setSearch(e)}
                    >
                        {users?.data?.map((option: any) => {
                            return (
                                <Option key={option?.id?.toString()} value={option?.id?.toString()}>
                                    {option?.login}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{ width: "100%" }}
                            htmlType={"submit"}
                            loading={isLoading}
                        >
                            Добавить
                        </Button>
                    </Col>
                    <Col span={12}>
                        {" "}
                        <Button
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{ width: "100%" }}
                            onClick={() =>{
                                form.resetFields()
                                onClose()
                            }}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>

            {isError && (
                <div
                    className={"ant-form-item-explain-error"}
                    style={{ color: "#ff4d4f" }}
                >
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
