import { Col, Row } from "antd";
import AnalyticsTable from "features/analytics/pages/AnalyticsTable";

export default function AnalyticsPage() {
    return (
        <Row>
            <Col style={{ padding: "10px 20px" }} span={24}>
                <AnalyticsTable />
            </Col>
        </Row>
    );
}
