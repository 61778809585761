import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useCountryDataForProductGTD() {

    const [limit, setLimit] = useState<string>("10");
    const [searchCountry, setSearchCountry] = useState<string>("");
    const [debouncedSpp] = useDebounce(searchCountry, 1000);

    const {data: countryData, ...options} = useQuery(
        [
            "COUNTRY_DATA_FOR_GTD_PRODUCT",
            limit,
            debouncedSpp,
        ],
        () =>
            backend.storage.getCountryDataForProductGTD(
                limit,
                debouncedSpp,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        countryData,
        searchCountry,
        setSearchCountry,
        ...options,
    };
}
