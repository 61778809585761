import React, {useEffect, useState} from "react";
import Icon from "@ant-design/icons";
import {Button, Col, DatePicker, Form, Input, Row, Spin, Table} from "antd";
import {ReactComponent as rangePickerArrow} from "assets/images/rangepicker-arrow.svg";
import {ReactComponent as closeIcon} from "assets/images/modal-close-icon-blue.svg";
import {ReactComponent as chartIcon} from "assets/images/presentation-chart-icon.svg";
import {ReactComponent as searchLineIcon} from "assets/images/search-line-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import {ReactComponent as keywordDown} from "assets/images/keyword-down.svg";
import {ReactComponent as keywordUp} from "assets/images/keyword-up.svg";
import useAnalyticsKeyWordsData from "../hooks/keyWords/useAnalyticsKeyWordsData";
import dayjs from "dayjs";
import {LoadingOutlined} from "@ant-design/icons";
import useAnalyticsKeyWordsChartDataTsx from "../hooks/keyWords/useAnalyticsKeyWordsChartData.tsx";
import PriceChart from "../components/PriceChart";
import KeyWordsChart from "../components/KeyWordsChart";

const {RangePicker} = DatePicker;

const columns = [
    {
        title: "Ключевое слово",
        dataIndex: "title",
        key: "title",
        width: "16%",
        render: (text: any, record: any, index: any) =>

            <div className={"keyword-table-col"}>
                <Icon component={searchLineIcon}/>
                <span style={{color: "#4C78EE", margin: "0 2px 0 10px"}}>
                    {text}
                </span>
                <Icon component={editIcon}/>
            </div>
    },
    {
        title: "Частота",
        dataIndex: "friquency",
        key: "friquency",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            // index === 0 ? (
            //     <div className={"keyword-table-first-row"}>{text}</div>
            // ) : index === 1 ? (
            //     <div className={"keyword-table-second-row"}>{text}</div>
            // ) : (
            //     <div className={"keyword-table-col"}>{text}</div>
            // ),
            <div className={"keyword-table-col"}>
                {text}
            </div>
    },
    {
        title: "Частота WB",
        dataIndex: "friquencyWb",
        key: "friquencyWb",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            <div className={"keyword-table-col"}>{text}</div>

    },
    {
        title: "Результат",
        dataIndex: "result",
        key: "result",
        width: "9%",
        render: (text: any, record: any, index: any) =>
            <div className={"keyword-table-col"}>{text}</div>

    },
    {
        title: "Ср. позиция",
        dataIndex: "avgPosition",
        key: "avgPosition",
        width: "8%",
        render: (text: any, record: any, index: any) =>
            // index === 1 ? (
            //     <div className={"keyword-table-second-row"}>{text}</div>
            // ) : (
            //     <div className={"keyword-table-col"}>{text}</div>
            // ),
            <div className={"keyword-table-col"}>{text}</div>

    },
];

export default function KeywordModalContent({onClose, id}: any) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const [addKeywordOpened, setAddKeywordOpened] = useState<boolean>(false);
    const [activeRangeTable, setActiveRangeTable] = useState('1')
    const [activeRangeChart, setActiveRangeChart] = useState('1')

    function compareDates(a: any, b: any) {
        var dateA: any = new Date(a);
        var dateB: any = new Date(b);
        return dateA - dateB;
    }

    const generateColumns = (data: any) => {
        if (data && data.length !== 0) {
            // console.log(data)

            let map = data.map((item: any) => item?.positions.map((item1: any) => item1.date));
            const combinedArray = [].concat(...map);
            // @ts-ignore
            const uniqueDates = [...new Set(combinedArray)];

            // Сортировка данных по полю "name" от меньшей к большей дате
            uniqueDates.sort(compareDates);

            return uniqueDates.map((position: any) => {
                const title = position;
                const key = position;
                return {
                    title,
                    dataIndex: `positions.${position}.position`,
                    key,
                    width: "8%",
                    render: (text: any, record: any, rowIndex: number) =>
                        <div className={"keyword-table-col"}>
                            {
                                record.positions.find((item: any) => item.date === position)?.position
                            }
                        </div>
                };
            });
        } else {
            return [];
        }
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const {
        data,
        setPeriod_start_date,
        setPeriod_end_date,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        isLoading,
    } = useAnalyticsKeyWordsData(id, false)

    const {
        data: chartData,
        setKeywordIds,
        setPeriod_start_date: setPeriod_start_date_chart,
        setPeriod_end_date: setPeriod_end_date_chart,
        isLoading: isLoadingChart,
    } = useAnalyticsKeyWordsChartDataTsx(id)

    const handleRangeChangeTable = (dates: any, dateStrings: any) => {
        setPeriod_start_date(dateStrings[0])
        setPeriod_end_date(dateStrings[1])
    }

    const handleOneWeekTable = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const oneWeekAgo = dayjs().subtract(1, 'week').format('DD.MM.YYYY HH:mm');
        setActiveRangeTable('1')
        setPeriod_start_date(oneWeekAgo)
        setPeriod_end_date(today)
    }

    const handleOneMonthTable = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const oneMonthAgo = dayjs().subtract(1, 'month').format('DD.MM.YYYY HH:mm');
        setActiveRangeTable('2')
        setPeriod_start_date(oneMonthAgo)
        setPeriod_end_date(today)
    }

    const handleThreeMonthsTable = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const threeMonthsAgo = dayjs().subtract(3, 'month').format('DD.MM.YYYY HH:mm');
        setActiveRangeTable('3')
        setPeriod_start_date(threeMonthsAgo)
        setPeriod_end_date(today)
    }

    const handleRangeChangeChart = (dates: any, dateStrings: any) => {
        setPeriod_start_date_chart(dateStrings[0])
        setPeriod_end_date_chart(dateStrings[1])
    }

    const handleOneWeekChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const oneWeekAgo = dayjs().subtract(1, 'week').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('1')
        setPeriod_start_date_chart(oneWeekAgo)
        setPeriod_end_date_chart(today)
    }

    const handleOneMonthChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const oneMonthAgo = dayjs().subtract(1, 'month').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('2')
        setPeriod_start_date_chart(oneMonthAgo)
        setPeriod_end_date_chart(today)
    }

    const handleThreeMonthsChart = () => {
        const today = dayjs().format('DD.MM.YYYY HH:mm');
        const threeMonthsAgo = dayjs().subtract(3, 'month').format('DD.MM.YYYY HH:mm');
        setActiveRangeChart('3')
        setPeriod_start_date_chart(threeMonthsAgo)
        setPeriod_end_date_chart(today)
    }


    useEffect(() => {
        setKeywordIds(selectedRowKeys)
    }, [selectedRowKeys])

    useEffect(() => {
        if (data?.data?.length !== 0 && !isLoading && isFirstLoad) {
            setKeywordIds(data?.data?.map((item: any) => item?.id))
            setSelectedRowKeys(data?.data?.map((item: any) => item?.id))
            setIsFirstLoad(false)
        }
    }, [isLoading])

    return (
        <div style={{padding: "28px 30px"}}>
            <div style={{position: "relative", height: "50px"}}>
                <Icon
                    onClick={() => onClose()}
                    component={closeIcon}
                    style={{fontSize: "23px", position: "absolute", right: 0}}
                />
            </div>
            <Row className={"keyword-chart-wrapper"}>
                <Col span={24}>
                    <Row justify={"end"}>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                }}
                            >
                                {" "}
                                <span onClick={handleOneWeekChart}
                                      className={activeRangeChart === '1' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Неделя
                                </span>
                                <span
                                    onClick={handleOneMonthChart}
                                    className={activeRangeChart === '2' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Месяц
                                </span>
                                <span onClick={handleThreeMonthsChart}
                                      className={activeRangeChart === '3' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    3 Месяца
                                </span>
                                <RangePicker
                                    onChange={handleRangeChangeChart}
                                    placeholder={["Начало", "Конец"]}
                                    separator={
                                        <Icon component={rangePickerArrow}/>
                                    }
                                    format={"DD.MM.YYYY"}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={"keyword-chart-message-wrapper"}>
                        {isLoadingChart
                            ? <div
                                style={{
                                    padding: "150px",
                                    textAlign: "center",
                                }}
                            >
                                <Spin
                                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                />
                            </div>
                            :
                            !chartData?.coords
                                ?
                                <div style={{textAlign: "center"}}>
                                    <Icon
                                        component={chartIcon}
                                        style={{fontSize: "100px"}}
                                    />
                                    <div className={"keyword-chart-message"}>
                                        Выберите ключевые слова, чтобы увидеть график
                                        сравнения их позиций
                                    </div>
                                </div>
                                : <KeyWordsChart data={chartData?.coords}/>
                        }
                    </div>
                </Col>
            </Row>
            <Row className={"keyword-table-wrapper"}>
                <Col span={24}>
                    <Row
                        justify={"space-between"}
                        style={{padding: "13px 20px"}}
                    >
                        <Col>
                            {/*<Button*/}
                            {/*    type={"primary"}*/}
                            {/*    className={"button"}*/}
                            {/*    onClick={() =>*/}
                            {/*        setAddKeywordOpened(!addKeywordOpened)*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    Добавить ключевое слово*/}
                            {/*</Button>*/}
                            <div
                                className={`keyword-add-dropdown ${
                                    !addKeywordOpened &&
                                    "keyword-add-dropdown-closed"
                                }`}
                            >
                                <Form layout={"vertical"}>
                                    <Form.Item label={"Введите ключевое слово"}>
                                        {" "}
                                        <Input/>
                                        <Row
                                            style={{marginTop: "10px"}}
                                            gutter={16}
                                        >
                                            <Col span={12}>
                                                <Button
                                                    className={"button"}
                                                    style={{
                                                        color: "#4C78EE",
                                                        width: "100%",
                                                        border: "1px solid #4C78EE",
                                                    }}
                                                    onClick={() =>
                                                        setAddKeywordOpened(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Отмена
                                                </Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button
                                                    className={"button"}
                                                    type={"primary"}
                                                    style={{width: "100%"}}
                                                >
                                                    Добавить
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                }}
                            >
                                {" "}
                                <span onClick={handleOneWeekTable}
                                      className={activeRangeTable === '1' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Неделя
                                </span>
                                <span
                                    onClick={handleOneMonthTable}
                                    className={activeRangeTable === '2' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    Месяц
                                </span>
                                <span onClick={handleThreeMonthsTable}
                                      className={activeRangeTable === '3' ? "keyword-date-select-button-active" : "keyword-date-select-button"}
                                >
                                    3 Месяца
                                </span>
                                <RangePicker
                                    onChange={handleRangeChangeTable}
                                    placeholder={["Начало", "Конец"]}
                                    separator={
                                        <Icon component={rangePickerArrow}/>
                                    }
                                    format={"DD.MM.YYYY"}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                loading={isLoading}
                                scroll={{x: 1000}}
                                className="keyword-table"
                                dataSource={data?.data?.map((item: any, index: any) => ({
                                    ...item,
                                    key: item?.id,
                                }))}
                                columns={[...columns, ...generateColumns(data?.data)]}
                                rowSelection={rowSelection}
                                pagination={{
                                    onChange: (page, pageSize): any => setCurrentPage(page),
                                    position: ["bottomCenter"],
                                    pageSize: Number(currentPageSize || 10),
                                    total: Number(data?.total || 0),
                                    showSizeChanger: false,
                                    current: currentPage || 0,
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
