import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const useCompleteFormation = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: create,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
    } = useMutation(backend.storage.postCompleteFormation, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`GTD_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (gtdId: any) => {
            try {
                await create(gtdId);
                queryClient.invalidateQueries(['GTD_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message || e?.response?.data?.detail);
            }
        },
        [create]
    );

    return {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    };
};
