import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table,
} from "antd";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import { ReactComponent as SelectIconComponent } from "assets/images/select-icon.svg";
import {ReactComponent as editIcon} from "../../assets/images/edit-icon.svg";
import useExpensesTableFilters from "./hooks/expenses/useExpensesTableFilters";
import {ReactComponent as trashBucket} from '../../assets/images/trash-bucket.svg'
import AddGuide from "./modal/AddGuide";
import EditGuide from "./modal/EditGuide";
import useGuideData from "./hooks/guide/useGuideData";
import {useGuideDelete} from "./hooks/guide/useGuideDelete";
import ConfirmModal from "../advertising-mirror/modal/advertisingMirror/ConfirmModal";

export default function GuideTable() {

    const {
        expensesData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        isLoading,
    } = useGuideData()

    const {
        handleDelete,
        errorMessage,
    } = useGuideDelete()

    const [addGuideModal, setAddGuideModal] = useState<any>(false);
    const [editGuideModal, setEditGuideModal] = useState<any>({
        id: null,
        isOpen: false
    });
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);

    const {filterMenuItems} = useExpensesTableFilters();

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_GUIDE",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE_GUIDE",
        }
    ];

    const getProductsActions = (record: any) => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_GUIDE":
                        setEditGuideModal({
                            id: record.id,
                            isOpen: true
                        })
                        break;
                    case "DELETE_GUIDE":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить справочник?',
                            onPress: () => handleDelete(record.id)
                        })
                        break;
                }
            },
        };
    };

    const columns = [
        // {
        //     title: "ID",
        //     dataIndex: "id",
        //     key: "id",
        //     width: "15%",
        // },
        {
            title: "Тип оплаты",
            dataIndex: "name",
            key: "name",
            width: "45%",
        },
        {
            title: "Описание оплаты",
            dataIndex: "description",
            key: "description",
            width: "45%",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        minHeight: 60,
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    useEffect(() =>{
        if (errorMessage){
            message.error('Произошла ошибка при попытке удалить тип оплаты')
        }
    },[errorMessage])


    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск по описанию"}
                        // type={"number"}
                    />
                </Col>

                <Col style={{width: "250px"}} >
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        // onChange={(e) => {
                        //     setCurrentPage(1);
                        //     setCurrentPageSize(e);
                        // }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>


                {/*<Col>*/}
                {/*    <Button*/}
                {/*        className="table-header-button"*/}
                {/*        type={"primary"}*/}
                {/*        style={{*/}
                {/*            display: "flex",*/}
                {/*            justifyContent: "center",*/}
                {/*            alignItems: "center",*/}
                {/*            width: 200*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Добавить*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
            <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                    padding: "5px 20px 5px 30px",
                    marginTop: 20,
                    backgroundColor: '#fff'

                }}
            >
                <Col>
                    <div style={{position: "relative"}}>

                        <Menu
                            className={
                                filterMenuOpened
                                    ? "filter-menu filter-menu-opened"
                                    : "filter-menu filter-menu-closed"
                            }
                            style={{
                                color: "red !important",
                                width: "250px",
                                left: "-40%",
                            }}
                            items={filterMenuItems}
                            mode={"inline"}
                            onClick={(e) => {
                                // const key = e.key;
                                // const test = key.split('-')
                                // setSortParam(test[0]);
                                // setSortType(test[1]);
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{
                            marginLeft: "10px",
                            width: "171px",
                            fontSize: "12px",
                        }}
                        onClick={() => setAddGuideModal(true)}
                    >
                        Добавить тип оплаты
                    </Button>
                </Col>
            </Row>

            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={expensesData?.data || []}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(expensesData?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => {
                                    // setProductToInfo(record);
                                    // setProductInfoOpened(true);
                                },
                            };
                        }}
                    />
                </Col>
            </Row>

            <Modal
                open={addGuideModal}
                closable={false}
                footer={null}
                width={600}
            >
                <AddGuide
                    onClose={() => setAddGuideModal(false)}
                />
            </Modal>

            <Modal
                open={editGuideModal.isOpen}
                closable={false}
                footer={null}
                width={600}
            >
                <EditGuide
                    id={editGuideModal.id}
                    onClose={() => setEditGuideModal({
                        id: null,
                        isOpen: false
                    })}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
