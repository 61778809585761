import {useQuery} from "react-query";
import {backend} from "api";
import dayjs, {Dayjs} from "dayjs";
import {useEffect, useState} from "react";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import useSellerList from "../../../sellers/hooks/useSellerList";
import useProductList from "../../../product/hooks/useProductList";
import {useDebounce} from "use-debounce";

export default function useBiderLogsData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [selectedSeller, setSelectedSeller] = useState<any>(null);
    const [searchArticle, setSearchArticle] = useState<string>('');
    const [debouncedSearchArticle] = useDebounce(searchArticle, 1000);

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useSellerList();


    useEffect(() => {
        if (sellers?.[0]?.id) {
            setSelectedSeller(sellers?.[0]?.id)
        }
    }, [sellers])

    const {data: data, ...options} = useQuery(
        [
            "BIDER_LOGS_LIST",
            currentPage,
            currentPageSize,
            selectedSeller,
            debouncedSearchArticle,
        ],
        () =>
            backend.advertisingMirror.getBiderLogsData(
                currentPage.toString(),
                currentPageSize,
                selectedSeller,
                debouncedSearchArticle,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        selectedSeller,
        sellers,
        setSelectedSeller,
        searchSellers,
        setSearchSellers,
        searchArticle,
        setSearchArticle,
        ...options,
    };
}
