import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Modal, Pagination, Row, Spin, Tooltip} from "antd";
import useDeclarationData from "../../kiz/hooks/declaratiion/useDeclarationData";
import useDeclarationDataBuId from "../../kiz/hooks/declaratiion/useDeclarationById";
import {useDeclarationAdd} from "../../kiz/hooks/declaratiion/useDeclarationAdd";
import {useDeclarationUpdate} from "../../kiz/hooks/declaratiion/useDeclarationUpdate";
import {useDeclarationDelete} from "../../kiz/hooks/declaratiion/useDeclarationDelete";
import DownloadButton from "../../kiz/components/DownloadButton";
import FileUploader from "../../kiz/components/FileUploader";
import ModalHeader from "../../shared/ModalHeader";
import {LoadingOutlined} from "@ant-design/icons";
import useKeyWordsList from "../../keyWords/hooks/useKeyWordsList";
import useKeyWordById from "../../keyWords/hooks/useKeyWordById";
import {useKeyWordAdd} from "../../keyWords/hooks/useKeyWordAdd";
import {useKeyWordUpdate} from "../../keyWords/hooks/useKeyWordUpdate";
import {useKeyWordDelete} from "../../keyWords/hooks/useKeyWordDelete";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import {getKeyWordsGroupData} from "../../../api/keyWords";
import useKeyWordsGroupList from "../hooks/useKeyWordsGroupList";
import {ReactComponent as deleteIcon}   from '../../../assets/images/delete-icon.svg'
import Icon from "@ant-design/icons";
import {ReactComponent as headerPrevBtn} from "../../../assets/images/header-prev-btn.svg";
import {useAddKeyWordsGroup} from "../hooks/useAddKeyWordsGroup";
import {useKeyWordGroupDelete} from "../hooks/useKeyWordGroupDelete";
import useKeyWordGroupById from "../hooks/useKeyWordGroupById";
import {useKeyWordGroupUpdate} from "../hooks/useKeyWordGroupUpdate";

export default function EditKeyWordsGroup({onClose}: any) {

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const [keyWordState, setKeyWordState] = useState<any>({
        state: 'keyWordList',
        idForEdit: null,
    });

    const [keywords, setKeywords] = useState<any>([]);

    const {
        keyWordsGroup,
        currentPage,
        currentPageSize,
        setCurrentPage,
        setCurrentPageSize,
        searchValue,
        setSearchValue,
        isError,
        isLoading,
    } = useKeyWordsGroupList()

    const {
        keyWordBGroupId,
        setId,
        isLoading: isLoadingKeyWordById,
    } = useKeyWordGroupById();

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useAddKeyWordsGroup()

    const {
        isError: isErrorKeyWordUpdate,
        handleUpdate,
        errorMessage: keyWordUpdateErrorMessage,
        isLoading: isLoadingUpdateKeyWord,
        isSuccess: keyWordSuccessUpdate,
    } = useKeyWordGroupUpdate();

    const {
        handleDelete: handleKeyWordDelete,
        isError: isErrorDeleteKeyWord,
        errorMessage: deleteKeyWordMessage,
        isSuccess: successDeleteKeyWord,
        isLoading: isLoadingKeyWords,
    } = useKeyWordGroupDelete();

    const addKeyword = () => {
        setKeywords([...keywords, ""]);
    };

    const removeKeyword = (index: any) => {
        const updatedKeywords = [...keywords];
        updatedKeywords.splice(index, 1);
        setKeywords(updatedKeywords);
    };

    const handleKeywordChange = (value: any, index: any) => {
        const updatedKeywords = [...keywords];
        updatedKeywords[index] = value;
        setKeywords(updatedKeywords);
    };

    const onFinish = (values: any) => {

        const dataToSend = {
            title: values.title,
            keywords: keywords.map((item: any) => ({title: item}))
        }

        handleCreate(dataToSend)
    };

    const onFinishUpdate = (values: any) => {

        const dataToSend = {
            title: values.title,
            keywords: keywords.map((item: any) => ({title: item}))
        }

        handleUpdate(dataToSend, keyWordState.idForEdit)
    };

    const onDeleteKeyWord = (id: any) => {
        handleKeyWordDelete(id)
    }

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно добавили новое ключевое слово')
            form.resetFields()
            setKeywords([])
            setKeyWordState({state: 'keyWordList'})
        }
        if (isErrorCreate){
            message.error(errorMessage)
        }
    }, [isSuccessCreate, isErrorCreate])

    useEffect(() => {
        if (keyWordSuccessUpdate) {
            message.success('Вы успешно обновили ключевое слово')
            setKeyWordState({state: 'keyWordList'})
        }
        if (isErrorKeyWordUpdate) {
            message.error(keyWordUpdateErrorMessage)
        }
    }, [keyWordSuccessUpdate, isErrorKeyWordUpdate])

    useEffect(() => {
        if (isErrorDeleteKeyWord){
            message.error(deleteKeyWordMessage)
        }
    }, [isErrorDeleteKeyWord])

    useEffect(() => {
        if (successDeleteKeyWord){
            message.success('Вы успешно удалили ключевое слово')
        }
    }, [successDeleteKeyWord])


    useEffect(() => {
        if (keyWordBGroupId){
            form.setFieldsValue({
                title: keyWordBGroupId?.title,
            })
            setKeywords(keyWordBGroupId?.keywords.map((item: any) => item?.title))
        }
    }, [keyWordBGroupId])

    const renderKeyWordTable = () => {
        if (isLoading || isLoadingKeyWords) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (keyWordsGroup?.data) {
            return (
                <>
                    <Row
                        justify={"space-between"}
                        style={{padding: "10px 40px 5px 30px"}}
                    >
                    </Row>
                    <Row>
                        <Input
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={'Введите название группы'}
                        />
                    </Row>
                    <div className="table-container">
                        <table className={"kiz-table"}>
                            <thead>
                            <th style={{}}>Название группы</th>
                            <th style={{}}>Редактировать</th>
                            <th style={{}}>Удаление</th>
                            </thead>
                            <tbody>
                            {keyWordsGroup?.data?.map((item: any) => (
                                <tr>
                                    <td style={{
                                        maxWidth: 50,
                                    }}>
                                        {item.title}
                                    </td>

                                    <td style={{}}>
                                        <Button
                                            className={"button"}
                                            type={"primary"}
                                            style={{width: "100%", fontSize: "12px"}}
                                            htmlType={"submit"}
                                            onClick={() => {
                                                setKeyWordState({state: 'keyWordUpdate', idForEdit: item.id})
                                                setId(item.id)
                                            }}
                                        >
                                            Редактировать
                                        </Button>
                                    </td>
                                    <td style={{}}>
                                        <Button
                                            className="button"
                                            type="primary"
                                            style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}
                                            htmlType="submit"
                                            onClick={() => setIsModalVisible({
                                                isOpen: true,
                                                text: 'удалить ключевое слово',
                                                onPress: () => onDeleteKeyWord(item.id)
                                            })}
                                        >
                                            Удалить
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={parseInt(currentPageSize)}
                        total={keyWordsGroup.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderAddKeyWord = () => {
        return (
            <>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        label={
                            <div>
                                <span>Название группы</span>
                                <Tooltip placement="right"></Tooltip>
                            </div>
                        }
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    {keywords.map((keyword: any, index: any) => (
                        <Form.Item
                            key={`keyword${index}`}
                            label={`Ключевое слово ${index + 1}`}
                            name={`keyword${index}`}
                        >
                            <Input.Group compact style={{display: 'flex', alignItems: 'center'}}>
                                <Input
                                    style={{ width: "100%", marginRight: 20 }}
                                    value={keyword}
                                    onChange={(e) => handleKeywordChange(e.target.value, index)}
                                />
                                {index >= 0 && (
                                    <Icon component={deleteIcon} onClick={() => removeKeyword(index)}/>
                                )}
                            </Input.Group>
                        </Form.Item>
                    ))}

                    <Row gutter={16} style={{ justifyContent: "flex-end" }}>
                        <Col span={12}>
                            <Button
                                loading={createLoading}
                                className="button"
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType="submit"
                            >
                                Добавить
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type="dashed" onClick={addKeyword} style={{ width: "100%" }}>
                                Добавить ключевое слово
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );

    };

    const renderKeyWordById = () => {
        if (isLoadingKeyWordById) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (keyWordBGroupId) {
            return (
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onFinishUpdate}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span>Название</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите название",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        {keywords.map((keyword: any, index: any) => (
                            <Form.Item
                                key={`keyword${index}`}
                                label={`Ключевое слово ${index + 1}`}
                                name={`keyword${index}`}
                            >
                                <Input.Group compact style={{display: 'flex', alignItems: 'center'}}>
                                    <Input
                                        style={{ width: "100%", marginRight: 20 }}
                                        value={keyword}
                                        onChange={(e) => handleKeywordChange(e.target.value, index)}
                                    />
                                    {index >= 0 && (
                                        <Icon component={deleteIcon} onClick={() => removeKeyword(index)}/>
                                    )}
                                </Input.Group>
                            </Form.Item>
                        ))}
                        <Row gutter={16} style={{ justifyContent: "flex-end" }}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    style={{width: "100%"}}
                                    htmlType={"submit"}
                                    loading={isLoadingUpdateKeyWord}
                                >
                                    Сохранить
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type="dashed" onClick={addKeyword} style={{ width: "100%" }}>
                                    Добавить ключевое слово
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            );
        }
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Группы ключевых слов"} onClose={onClose}/>
            {keyWordState.state === "keyWordList" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "240px", fontSize: "12px"}}
                                onClick={() => {
                                    setKeyWordState({state: 'keyWordAdd'})
                                    setId('')
                                    form.resetFields()
                                    setKeywords([])
                                }}
                            >
                                Добавить группу ключевых слов
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderKeyWordTable()}
                    </Col>
                </Row>
            </>
            }
            {keyWordState.state === "keyWordAdd" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setKeyWordState({state: 'keyWordList'})}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderAddKeyWord()}
                    </Col>
                </Row>
            </>
            }
            {keyWordState.state === "keyWordUpdate" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setKeyWordState({state: 'keyWordList'})}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderKeyWordById()}
                    </Col>
                </Row>
            </>
            }
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
};
