import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useGuideData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedText] = useDebounce(searchText, 1000);

    const { data: expensesData, ...options } = useQuery(
        ["GUIDE_DATA", currentPage, currentPageSize, debouncedText],
        () =>
            backend.operatingExpenses.getGuide(
                currentPage.toString(),
                currentPageSize,
                debouncedText
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        expensesData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        ...options,
    };
}
