import React, {useState} from 'react';
import {Button, Col, Dropdown, Input, Row, Table} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import useLaboratoryResultData from "./hooks/useLaboratoryResultData";
import dayjs from "dayjs";

const LaboratoryResultsTVTTable = () => {

  const {
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    selectedProducts,
    getProductsFilterItems,
    getAppointmentFilterItems,
    seasonTypes,
    selectedSeasonType,
    setSelectedSeasonType,
    setSeasonTypes,
    data,
    isLoading
  } = useLaboratoryResultData()

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: "16%",
    },
    {
      title: "Предмет",
      dataIndex: "item",
      key: "item",
      width: "16%",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      width: "16%",
    },
    {
      title: "Время начала поиска",
      dataIndex: "start_time",
      key: "start_time",
      width: "16%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{textAlign: "center"}}
          >
            {text ? dayjs(text).format('DD.MM.YYYY, HH.mm') : '--'}
          </div>
        );
      },
    },
    {
      title: "Время завершения поиска",
      dataIndex: "end_time",
      key: "end_time",
      width: "16%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{textAlign: "center"}}
          >
            {text ? dayjs(text).format('DD.MM.YYYY, HH.mm') : '--'}
          </div>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: "16%",
    },
  ];

  return (
    <div>

      <Row style={{paddingTop: 20, backgroundColor: '#f8f8f8'}}>
        {seasonTypes.map((item, index) =>
          <Col style={{width: '25%'}}>
            <Button
              className={
                item.id === selectedSeasonType.id
                  ? "table-tab-button-active-without-circle"
                  : "table-tab-button-without-circle"
              }
              style={{
                borderTopLeftRadius: index === 0 ? '10px' : '0',
                borderTopRightRadius: index === 3 ? '10px' : '0'
              }}
              onClick={() => setSelectedSeasonType(item)}
            >
              {item.ru}
            </Button>
          </Col>
        )
        }
      </Row>

      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getProductsFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Предмет</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getAppointmentFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Назначение</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
      </Row>

      {/*<Row className={"container"} style={{marginTop: "10px"}}>*/}
      {/*  <Col style={{flexGrow: "1"}}>*/}
      {/*    <Input*/}
      {/*      className="table-header-input"*/}
      {/*      placeholder={"Поиск Трендовых Высокоморжинальных товаров AI"}*/}
      {/*      value={searchText}*/}
      {/*      onChange={(e) => setSearchText(e.target.value)}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col span={24}>
          <Table
            loading={isLoading}
            className="product-arrival-table"
            columns={columns}
            dataSource={data}
            scroll={{x: true}}
            pagination={false}
            // pagination={{
            //   onChange: (page, pageSize) => setCurrentPage(page),
            //   position: ["bottomCenter"],
            //   pageSize: Number(currentPageSize),
            //   total: Number(data?.total),
            //   showSizeChanger: false,
            //   current: currentPage,
            // }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LaboratoryResultsTVTTable;
