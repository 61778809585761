import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useArticleByGTD() {

    const [idGTD, setIdGTD] = useState(null)

    const {data: articleDataByGTDId, ...options} = useQuery(
        [
            "ARTICLE_DATA_BY_GTD_ID",
            idGTD
        ],
        () =>
            backend.storage.getArticleByGTDId(idGTD),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        articleDataByGTDId,
        idGTD,
        setIdGTD,
        ...options,
    };
}
