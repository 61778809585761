import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useUserUpdate = (id: number) => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading: updateLoading,
        isError,
    } = useMutation(backend.user.updateUser, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`USER_LIST`])]);
            await Promise.all([queryClient.invalidateQueries([`USER_CURRENT_DATA`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, handleCloseModal?: any) => {
            try {
                let body = { ...values };
                if (values.roleId) {
                    body.roleId = parseInt(values.roleId);
                }

                await update({ id: id.toString(), ...body });
                if (handleCloseModal) {
                    handleCloseModal();
                }
            } catch (e: any) {
                const errorData = await e.response.data;
                if (errorData) {
                    if (errorData.message === 'Failed to save changes:'){
                        setErrorMessage("Ошибка ");
                    } else if (
                        errorData?.detail[0]?.loc?.length > 1 &&
                        errorData?.detail[0]?.loc[1] === "email"
                    ) {
                        setErrorMessage("Пожалуйста, введите корректный Email");
                        return;
                    }
                    if (
                        errorData.detail === "This email address already exists"
                    ) {
                        setErrorMessage(
                            "Пользователь с данным email уже существует"
                        );
                        return;
                    }
                    if (errorData.detail === "This login already exists") {
                        setErrorMessage(
                            "Пользователь с данным логином уже существует"
                        );
                        return;
                    }
                }

                setErrorMessage(
                    "Произошла ошибка при редактировании пользователя"
                );
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: updateLoading,
        isError,
        setErrorMessage,
        errorMessage,
    };
};
