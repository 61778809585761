import { Col, Row } from "antd";
import React from "react";
import DocumentMirrorStatementTable from "../../features/document-mirror/DocumentMirrorStatementTable";

export default function DocumentMirrorStatement() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <DocumentMirrorStatementTable />
            </Col>
        </Row>
    );
}
