import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useUploadEmission} from "../../hooks/emission/useUploadEmission";
import SuccessUploadModal from "../../modal/SuccessUploadModal";
import useEmissionsData from "../../hooks/emission/useEmissionsData";
import dayjs from "dayjs";
import {ReactComponent as rangePickerArrow} from "../../../../assets/images/rangepicker-arrow.svg";
import useEmissionMainTableFilter from "../../hooks/emission/useEmissionMainTableFilter";
import selectIcon from "../../../../assets/images/select-icon.svg";
import {useDeleteEmission} from "../../hooks/emission/useDeleteEmission";
import ConfirmModal from "../../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import AddDeclarationModal from "../../modal/declaration/AddDeclarationModal";
import AddEmissionModal from "../../modal/emission/AddEmissionModal";

const {RangePicker} = DatePicker;

export default function EmissionsTablet() {

    const navigate = useNavigate()

    const {
        emissions,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        search,
        setSearch,
        searchGtin,
        setSearchGtin,
        onChangeDateCreate,
        onChangeDateUpdate,
        onChangeDateCustomed,
        setOrderBy,
        getSizeFilterItems,
        getBoxCodeFilterItems,
        isLoading
    } = useEmissionsData()

    const {
        handleDelete,
        errorMessage,
        isSuccess: isSuccessDeleteEmisson,
        isError: iserrorDeleteEmission
    } = useDeleteEmission()

    const {
        handleCreate,
        uploadData,
        isSuccess: isSuccessUploadFile,
        isError: isErrorUploadFile,
        errorMessage: errorMessageUploadFile
    } = useUploadEmission()

    const [responseUploadFile, setResponseUploadFile] = useState<any>({
        totalCount: 0,
        failedCount: 0,
        createdCount: 0,
        firstAddedId: 0,
        lastAddedId: 0
    });

    const fileInputRef = useRef<any>(null);

    const [addEmissionModal, setAddEmissionModal] = useState(false)
    const [answerModal, setAnswerModal] = useState<any>(false);
    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const {filterMenuItems} = useEmissionMainTableFilter();

    const columns = [
        {
            title: "Название эмисиии",
            dataIndex: "title",
            key: "title",
            width: "35%",
            render: (text: any, record: any) => {
                const onGoToFullInfo = () => {
                    navigate(`/storage/emissions/full-info/${record?.id}`)
                }

                return (
                    <div
                        onClick={onGoToFullInfo}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text}
                    </div>
                )
            }
        },
        {
            title: "gtin",
            dataIndex: "gtin",
            key: "gtin",
            width: "30%",
            render: (text: any, record: any) => {
                const onGoToFullInfo = () => {
                    navigate(`/storage/emissions/full-info/${record?.id}`)
                }

                return (
                    <div
                        onClick={onGoToFullInfo}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Дата загрузки эмиссии",
            dataIndex: "custom_passed_at",
            key: "custom_passed_at",
            width: "10%",
            render: (text: any, record: any) => {
                const onGoToFullInfo = () => {
                    navigate(`/storage/emissions/full-info/${record?.id}`)
                }

                return (
                    <div
                        onClick={onGoToFullInfo}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Дата создания",
            dataIndex: "created_at",
            key: "created_at",
            width: "10%",
            render: (text: any, record: any) => {
                const onGoToFullInfo = () => {
                    navigate(`/storage/emissions/full-info/${record?.id}`)
                }

                return (
                    <div
                        onClick={onGoToFullInfo}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "Дата обновления",
            dataIndex: "updated_at",
            key: "updated_at",
            width: "10%",
            render: (text: any, record: any) => {
                const onGoToFullInfo = () => {
                    navigate(`/storage/emissions/full-info/${record?.id}`)
                }

                return (
                    <div
                        onClick={onGoToFullInfo}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {text
                            ? dayjs(text).format("DD.MM.YYYY HH:mm")
                            : '---'
                        }
                    </div>
                )
            }
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        minHeight: 60,
                        textAlign: "center",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                    // onClick={() => {
                    //     setEditProductOpened(true);
                    //     setProductToEdit(record);
                    // }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const productsItemsForEdit: MenuProps["items"] = [
        // {
        //     label: (
        //         <span style={{display: "flex", gap: "10px", width: 180}}>
        //             <Icon component={editIcon} style={{cursor: "pointer"}}/>
        //             Выкачать файл эмиссии
        //         </span>
        //     ),
        //     key: "DOWNLOAD_FILE",
        // },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE",
        }
    ];

    const getProductsActions = (record: any) => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "DOWNLOAD_FILE":
                        // setEditPaymentModal(true)
                        break;
                    case "LOAD_CUSTOMED":
                        // setEditPaymentModal(true)
                        break;
                    case "LOAD_NOT_CUSTOMED":
                        // setEditPaymentModal(true)
                        break;
                    case "LOAD_FREED":
                        // setEditPaymentModal(true)
                        break;
                    case "DELETE":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить ключевое эмиссию?',
                            onPress: () => handleDelete(record.id)
                        })
                        break;
                }
            },
        };
    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        message.warning('Файл начал загрузку. Не закрывайте страницу')

        // handleCreate(formData)

    };


    useEffect(() =>{
        if (isSuccessDeleteEmisson){
            message.success('Вы успешно удалилил эмисиию')
        }
    },[])

    useEffect(() =>{
        if (iserrorDeleteEmission){
            message.success(errorMessage)
        }
    },[])

    useEffect(() => {
        if (isSuccessUploadFile) {
            message.success('Файл успешно загружен')
            setAnswerModal(true)
            setResponseUploadFile(uploadData.data)
        }
    }, [isSuccessUploadFile])

    useEffect(() => {
        if (isErrorUploadFile) {
            message.error(errorMessageUploadFile)
        }
    }, [isErrorUploadFile])

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск по названию"}
                        // type={"number"}
                    />

                </Col>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={searchGtin}
                        onChange={(e) => setSearchGtin(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск по gtin"}
                        type={"number"}
                    />

                </Col>
                {/*<Col style={{ width: "88px", paddingTop: 0, paddingBottom: 0 }}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{ items: getSizeFilterItems() }}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Размер</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{ marginTop: "2px", fontSize: "10px" }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}
                {/*<Col style={{ width: "98px" }}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{ items: getBoxCodeFilterItems() }}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Box_code</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{ marginTop: "2px", fontSize: "10px" }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}
                <Col style={{ width: "190px" }}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon} alt={""} />}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            { value: "10", label: "Показать по 10 шт." },
                            { value: "50", label: "Показать по 50 шт." },
                            { value: "100", label: "Показать по 100 шт." },
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        // onClick={handleFileSelect}
                        onClick={() => setAddEmissionModal(true)}
                    >
                        Добавить эмиссию
                    </Button>
                    {/*<input*/}
                    {/*    type="file"*/}
                    {/*    ref={fileInputRef}*/}
                    {/*    style={{display: 'none'}}*/}
                    {/*    onChange={handleFileChange}*/}
                    {/*/>*/}
                </Col>
            </Row>
            <Row style={{marginTop: 10,}}>
                <Col
                    span={24}
                    className="kiz-actions-wrapper"
                >
                    <div className="kiz-actions-wrapper-left">
                        <Col span={3}>
                            <div style={{position: "relative"}}>
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setFilterMenuOpened(!filterMenuOpened);
                                    }}
                                >
                                    <Icon component={filterIcon}/>
                                    <span
                                        style={{
                                            color: "#82868B",
                                            marginLeft: "6px",
                                        }}
                                    >
                                Фильтры
                            </span>
                                </div>

                                <Menu
                                    className={
                                        filterMenuOpened
                                            ? "filter-menu filter-menu-opened"
                                            : "filter-menu filter-menu-closed"
                                    }
                                    style={{
                                        color: "red !important",
                                        width: "250px",
                                        left: "-40%",
                                    }}
                                    items={filterMenuItems}
                                    mode={"inline"}
                                    onClick={(e) => {
                                        setOrderBy(e.key)
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                style={{width: '350px'}}
                                onChange={onChangeDateCreate}
                                showTime={{format: 'HH-mm-ss'}}
                                placeholder={["Дата создания(от)", "Дата создания(до)"]}
                                separator={
                                    <Icon component={rangePickerArrow}/>
                                }
                                format={"DD.MM.YYYY HH:mm:ss"}
                            />
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                style={{width: '350px'}}
                                onChange={onChangeDateUpdate}
                                showTime={{format: 'HH-mm-ss'}}
                                placeholder={["Дата обновления(от)", "Дата обновления(до)"]}
                                separator={
                                    <Icon component={rangePickerArrow}/>
                                }
                                format={"DD.MM.YYYY HH:mm:ss"}
                            />
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                style={{width: '350px'}}
                                onChange={onChangeDateCustomed}
                                showTime={{format: 'HH-mm-ss'}}
                                placeholder={["Дата загрузки(от)", "Дата загрузки(до)"]}
                                separator={
                                    <Icon component={rangePickerArrow}/>
                                }
                                format={"DD.MM.YYYY HH:mm:ss"}
                            />
                        </Col>
                    </div>
                </Col>
            </Row>

            <Row className={"container"}>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={emissions?.data}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(emissions?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: (e) => {
                        //             navigate(`/kiz/emissions/full-info/${record.date}${record.name}`)
                        //         },
                        //     };
                        // }}
                    />
                </Col>
            </Row>

            <Modal
                open={answerModal}
                closable={false}
                footer={null}
                width={600}
            >
                <SuccessUploadModal
                    backendData={responseUploadFile}
                    setBackendData={setResponseUploadFile}
                    onClose={() => setAnswerModal(false)}
                />
            </Modal>
            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        onPress: () => {}
                    })}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
            <Modal
                open={addEmissionModal}
                closable={false}
                footer={null}
                width={500}
            >
                <AddEmissionModal
                    onClose={() => setAddEmissionModal(false)}
                />
            </Modal>
        </div>
    );
}
