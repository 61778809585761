import { useQuery } from "react-query";
import { backend } from "api";
import { useState } from "react";


export default function useCashbackActions(id?: string) {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");

    const { data: cashbackActions, ...options } = useQuery(
        [
            "CASHBACK_ACTIONS",
            id,
            currentPage
        ],
        () =>
            backend.analytics.getCashbackContractByActionId(
                id,
                currentPage.toString(),
                currentPageSize.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        cashbackActions,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
