import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useKeyWordUpdate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading: updateLoading,
        isError,
        isSuccess,
    } = useMutation(backend.keyWords.updateKeyWord, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`KEY_WORDS_LIST`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, id: string) => {
            try {
                let body = { ...values };

                await update({ id: id, ...body });
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при редактировании ключевого слова");
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: updateLoading,
        isError,
        errorMessage,
        isSuccess
    };
};
