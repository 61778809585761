import { useState, useRef, useEffect } from "react";
import {
    Col,
    Form,
    Input,
    Row,
    Select,
    DatePicker,
    TimePicker,
    Button,
    Checkbox,
} from "antd";
import type { InputRef } from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import { ReactComponent as editIcon } from "assets/images/edit-icon.svg";
import { ReactComponent as rangePickerArrow } from "assets/images/rangepicker-arrow.svg";
import locale from "antd/es/date-picker/locale/en_US";
import AddKeywordDropdown from "../../components/advertisingMirror/AddKeywordDropdown";

const { RangePicker } = DatePicker;

const focusInput = (ref: React.RefObject<InputRef>) => {
    ref.current!.focus({
        cursor: "end",
    });
};

interface EditCompanyModalProps {
    company: any;
    onClose: () => void;
}

export default function EditCompanyModalContent({
    company,
    onClose,
}: EditCompanyModalProps) {
    //budget input refs:
    const inputBudgetRef = useRef<InputRef>(null);
    const inputBetRef = useRef<InputRef>(null);
    const inputTargetRef = useRef<InputRef>(null);
    const inputMaxBetRef = useRef<InputRef>(null);

    const [modalMode, setModalMode] = useState<string>("");

    const [addKeywordOpened, setAddKeywordOpened] = useState<boolean>(false);

    useEffect(() => {
        setModalMode(company.type);
    }, [company]);

    return (
        <div
            className={"modal-wrapper"}
            style={{
                padding: "30px",
                width: modalMode === "search" ? 992 : 519,
            }}
        >
            <div>
                <ModalHeader
                    title={"Редактировать компанию"}
                    onClose={onClose}
                />
            </div>
            <Row gutter={24}>
                <Col span={modalMode === "search" ? 12 : 24}>
                    <Form layout={"vertical"}>
                        <Form.Item label={"Введите название компании"}>
                            <Input style={{ fontWeight: 600 }} />
                        </Form.Item>

                        <Form.Item label={"Выберите артикул"}>
                            <Select
                                mode={"multiple"}
                                allowClear
                                style={{ width: "100%" }}
                                options={[
                                    { value: "123776680", label: "123776680" },
                                    { value: "123776680", label: "123776680" },
                                    { value: "123776680", label: "123776680" },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Выберите тип рекламной компании"}
                            style={{ marginBottom: "20px" }}
                        >
                            <Select
                                style={{ width: "100%" }}
                                onChange={(value: string) =>
                                    setModalMode(value)
                                }
                                value={modalMode}
                                options={[
                                    { value: "card", label: "Карточка товара" },
                                    { value: "catalogue", label: "Каталог" },
                                    { value: "search", label: "Поиск" },
                                ]}
                            />
                        </Form.Item>

                        {modalMode === "catalogue" && (
                            <Form.Item
                                label={
                                    "Выберите категории для размещения товаров"
                                }
                            >
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    style={{ width: "100%", maxWidth: "431px" }}
                                    options={[
                                        {
                                            value: "jewelry",
                                            label: "Бижутерия",
                                        },
                                        {
                                            value: "outerwear",
                                            label: "Верхняя одежда",
                                        },
                                        {
                                            value: "womensClothing",
                                            label: "Женская одежда",
                                        },
                                        {
                                            value: "lingerie",
                                            label: "Нижнее бельё",
                                        },
                                        {
                                            value: "bedLinen",
                                            label: "Постельное бельё",
                                        },
                                        {
                                            value: "shoes",
                                            label: "Обувь",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        )}

                        <div className={"budget-wrapper"}>
                            <div className={"ant-form-item-label"}>
                                <label>
                                    Задайте бюджет, который Вы хотите потратить
                                    на рекламу и <br /> ставку
                                </label>
                            </div>
                            <Row justify={"space-between"}>
                                <Col style={{ width: "55px" }}>
                                    <div className={"budget-input-title"}>
                                        Бюджет
                                    </div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            suffix={"₽"}
                                            ref={inputBudgetRef}
                                        />
                                        <Icon
                                            className={
                                                "budget-input-edit-button"
                                            }
                                            component={editIcon}
                                            onClick={() =>
                                                focusInput(inputBudgetRef)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col style={{ width: "55px" }}>
                                    <div className={"budget-input-title"}>
                                        Ставка
                                    </div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            suffix={"₽"}
                                            ref={inputBetRef}
                                        />
                                        <Icon
                                            className={
                                                "budget-input-edit-button"
                                            }
                                            component={editIcon}
                                            onClick={() =>
                                                focusInput(inputBetRef)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col style={{ width: "95px" }}>
                                    <div className={"budget-input-title"}>
                                        Целевое место
                                    </div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            ref={inputTargetRef}
                                        />
                                        <Icon
                                            className={
                                                "budget-input-edit-button"
                                            }
                                            component={editIcon}
                                            onClick={() =>
                                                focusInput(inputTargetRef)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col style={{ width: "138px" }}>
                                    <div className={"budget-input-title"}>
                                        Максимальная ставка
                                    </div>
                                    <div className={"budget-input-container"}>
                                        <Input
                                            className={"budget-input"}
                                            suffix={"₽"}
                                            ref={inputMaxBetRef}
                                        />
                                        <Icon
                                            className={
                                                "budget-input-edit-button"
                                            }
                                            component={editIcon}
                                            onClick={() =>
                                                focusInput(inputMaxBetRef)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className={"entrance-rate-wrapper"}>
                            <div className={"entrance-rate-label"}>
                                Входные ставки из кабинета WB
                            </div>
                            <Row justify={"space-between"} gutter={8}>
                                <Col span={6}>
                                    <div className={"entrance-rate-selected"}>
                                        1ое - <span>1500 ₽</span>
                                    </div>
                                    <div className={"entrance-rate-selected"}>
                                        2ое - <span>1200 ₽</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={"entrance-rate"}>
                                        3ое - <span>1000 ₽</span>
                                    </div>
                                    <div className={"entrance-rate"}>
                                        4ое - <span>1000 ₽</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={"entrance-rate"}>
                                        5ое - <span>1000 ₽</span>
                                    </div>
                                    <div className={"entrance-rate"}>
                                        6ое - <span>1000 ₽</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={"entrance-rate"}>
                                        7ое - <span>1000 ₽</span>
                                    </div>
                                    <div className={"entrance-rate"}>
                                        8ое - <span>1000 ₽</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className={"datepick-wrapper"}>
                            <div className={"ant-form-item-label"}>
                                <label>
                                    Задайте даты проведения компании и временной{" "}
                                    <br />
                                    промежуток показа
                                </label>
                            </div>

                            <div className={"datepick-container"}>
                                <RangePicker
                                    style={{ width: "182px" }}
                                    placeholder={["Начало", "Конец"]}
                                    format={"DD.MM.YY"}
                                    separator={
                                        <Icon component={rangePickerArrow} />
                                    }
                                />
                                <TimePicker
                                    placeholder={"Выберите время"}
                                    style={{ width: "150px" }}
                                    locale={{
                                        ...locale,
                                        lang: {
                                            ...locale.lang,
                                            ok: "ОК",
                                            now: "Сейчас",
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <Row gutter={16} style={{ paddingTop: "12px" }}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    style={{ width: "100%" }}
                                    type={"primary"}
                                >
                                    Запустить
                                </Button>
                            </Col>
                            <Col span={12}>
                                {" "}
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    ghost
                                    style={{ width: "100%" }}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                {modalMode === "search" && (
                    <Col span={12}>
                        <Row gutter={16} style={{ marginTop: "25px" }}>
                            <Col span={12}>
                                <div className={"ant-form-item-label"}>
                                    <label>Ключевые фразы WB</label>
                                </div>
                                <div className={"keyword-input-container"}>
                                    <Checkbox>Куртка</Checkbox>
                                    <Checkbox>Куртка женская</Checkbox>
                                    <Checkbox>Куртка косуха</Checkbox>
                                    <Checkbox>Весенняя куртка</Checkbox>
                                    <Checkbox>Куртка oversize женская</Checkbox>
                                    <Checkbox>Кожанка женская</Checkbox>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div
                                    className={
                                        "ant-form-item-label plus-container"
                                    }
                                >
                                    <label>Фиксированные фразы</label>
                                    <div
                                        className={"plus-button"}
                                        onClick={() =>
                                            setAddKeywordOpened(
                                                !addKeywordOpened
                                            )
                                        }
                                    >
                                        +
                                    </div>
                                    <AddKeywordDropdown
                                        open={addKeywordOpened}
                                        setKeywordsWb={() =>{}}
                                        keywordsWb={[]}
                                        onClose={() =>
                                            setAddKeywordOpened(false)
                                        }
                                    />
                                </div>
                                <div className={"keyword-input-container"}>
                                    <Checkbox>Куртка</Checkbox>
                                    <Checkbox>Куртка женская</Checkbox>
                                    <Checkbox>Куртка косуха</Checkbox>
                                    <Checkbox>Весенняя куртка</Checkbox>
                                    <Checkbox>Куртка oversize женская</Checkbox>
                                    <Checkbox>Кожанка женская</Checkbox>
                                </div>
                            </Col>
                        </Row>
                        <div className={"keyword-select-buttons-container"}>
                            <Button
                                className={"button"}
                                style={{ fontSize: "12px" }}
                                type={"primary"}
                                ghost
                            >
                                Перенести в минус-фразы
                            </Button>
                            <Button
                                className={"button"}
                                style={{ fontSize: "12px" }}
                                type={"primary"}
                                ghost
                            >
                                Перенести в фиксированные-фразы
                            </Button>
                        </div>
                        <Row style={{ marginTop: "16px" }}>
                            <Col span={24}>
                                <div className={"ant-form-item-label"}>
                                    <label>Минус фразы</label>
                                </div>
                                <div
                                    className={"keyword-input-container"}
                                    style={{ minHeight: "300px" }}
                                >
                                    <div style={{ color: "#82868B" }}>
                                        Нет минус фраз, Вы можете добавить
                                        минус-фразы из ключевых фраз. Просто
                                        выберите их из списка
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
}
