import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Button,
    Menu,
    Table,
    Dropdown,
    MenuProps,
    Modal,
    Input,
    Radio,
    Select,
    Pagination, message,
} from "antd";
import Icon, {MoreOutlined} from "@ant-design/icons";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import useCashbackTableFilters from "../hooks/useCashbackTableFilters";
import {ReactComponent as editIcon} from "assets/images/edit-mirror-icon.svg";
import {ReactComponent as stopCircleIcon} from "assets/images/stop-circle-bold.svg";
import {ReactComponent as plusIcon} from "assets/images/round-plus.svg";
import {ReactComponent as trashCan}  from 'assets/images/trashCan.svg'
import selectIcon from "assets/images/select-icon.svg";
import AddCashbackModalContent from "../modal/AddCashbackModalContent";
import EditCashbackModalContent from "../modal/EditCashbackModalContent";
import {useNavigate} from "react-router-dom";
import useAnalyticsCashbackList from "../hooks/useAnalyticsCashbackList";
import dayjs from "dayjs";
import {useAnalyticsCashbackCreate} from "../hooks/useAnalyticsCashbackCreate";
import {useAnalyticsCashbackUpdate} from "../hooks/useAnalyticsCashbackUpdate";
import {CopyToClipboard} from "react-copy-to-clipboard";
import AddExpensesModal from "../modal/AddExpensesModal";
import usePublishToTestGroup from "../hooks/usePublishToTestGroup";
import {publishToTestGroup} from "../../../api/analytics";
import {useCashBackDelete} from "../hooks/useDeleteCashBack";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";

enum CashbackStatus {
    IN_PROGRESS = 1,
    STOPPED = 2,
    ENDED = 3,
    CANCELED = 4,
    READY_TO_START = 5,
}

const menuItems: MenuProps["items"] = [
    {
        label: (
            <span style={{display: "flex", gap: "10px"}}>
                <Icon component={editIcon}/>
                Редактировать
            </span>
        ),
        key: "EDIT",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px"}}>
                <Icon component={editIcon}/>
                Опубликовать в тестовую группу
            </span>
        ),
        key: "PUBLISH",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px"}}>
                <Icon component={plusIcon}/>
                Добавить затрату
            </span>
        ),
        key: "ADD_EXPENSES",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#bfbfbf"}}>
                <Icon component={stopCircleIcon}/>
                Готов к запуску
            </span>
        ),
        key: "READY_TO_START",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#4C78EE"}}>
                <Icon component={stopCircleIcon}/>
                Запустить (по времени) с публикацией поста
            </span>
        ),
        key: "START",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#0ade59"}}>
                <Icon component={stopCircleIcon}/>
                Запустить (по времени) без публикацией поста
            </span>
        ),
        key: "READY_WITHOUT_PUBLISH",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#FF9F43"}}>
                <Icon component={stopCircleIcon}/>
                Приостановить
            </span>
        ),
        key: "STOP",
    },
    // {
    //   label: (
    //     <span style={{ display: "flex", gap: "10px", color: "#00CFE8" }}>
    //       <Icon component={stopCircleIcon} />
    //       Отменить
    //     </span>
    //   ),
    //   key: "CANCEL",
    // },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#EA5455"}}>
                <Icon component={stopCircleIcon}/>
                Закончить
            </span>
        ),
        key: "END",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px", color: "#EA5455"}}>
                <Icon component={trashCan}/>
                Удалить
            </span>
        ),
        key: "DELETE",
    },
    {
        label: (
            <span style={{display: "flex", gap: "10px"}}>
                <Icon component={plusIcon}/>
                Дублировать
            </span>
        ),
        key: "DUP",
    },
];

export default function CashbackTable() {

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const [addCashbackOpened, setAddCashbackOpened] = useState<boolean>(false);
    const [editCashbackOpened, setEditCashbackOpened] = useState<boolean>(false);
    const [addExpensesModal, setAddExpensesModal] = useState<any>({
        isOpen: false,
        id: null
    });
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });
    const [cashbackToEdit, setCashbackToEdit] = useState<Cashback>();

    const {filterMenuItems} = useCashbackTableFilters();

    const {handleCreate} = useAnalyticsCashbackCreate();
    const {
        handleUpdate,
        isSuccess: isSuccessChangeStatus,
        isError: isErrorChangeStatus
    } = useAnalyticsCashbackUpdate();

    const {
        handleDelete,
        errorMessage,
        isLoading: isLoadingDelete,
        isSuccess: isSuccessDelete,
        isError: isErrorDelete
    } = useCashBackDelete();

    const {
        data,
        isSuccess,
        setId
    } = usePublishToTestGroup();

    const onPublishToTestGroup = (id: any) => {
        publishToTestGroup(id)
            .then((response) => {
                message.success('Вы успешно опубликовали в тестовую группу')

            })
            .catch((e) => {
                message.error('Произошла ошибка при попытке опубликовать в тестовую группу')
            })
            .finally(() => {

            })
    }

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно опубликовали в тестовую группу')
        }
        if (isSuccessDelete) {
            message.success('Вы успешно удалили акцию')
        }
        if (isErrorDelete) {
            message.error(errorMessage)
        }
        if (isSuccessChangeStatus){
            message.success('Вы успешно изменили статус')
        }
        if (isErrorChangeStatus){
            message.error('Произошла ошибка при попытке изменить статус')
        }
    }, [isSuccess, isErrorDelete, isSuccessDelete, isSuccessChangeStatus, isErrorChangeStatus])


    const onDuplicateClick = (record: any) => {
        record.keyword = record?.keyword?.title;
        record.productId = record?.product?.id;
        record.items = record?.cashbackItems?.map((cashbackItem: any) => ({
            size: cashbackItem?.size,
            count: cashbackItem?.count,
        }));
        record.cashbackType = "percentage";
        record.cashbackValue = record?.cashbackPercentage;
        record.publishDate = dayjs(record?.publishDate).add(1,'day').format('YYYY-MM-DD HH:mm');

        handleCreate(record);
    };

    //просто запустить передавать publish_tg = true, во всех остальных передавать false
    const changeCashbackStatus = (record: any, status: CashbackStatus, tgStatus?: any) => {
        const body = {cashbackActionStatusId: status, publish_tg: tgStatus ? true : false};
        handleUpdate(record.id, body);
    };

    const getActions = (record: any) => {
        const actions = {
            items: menuItems,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT":
                        setCashbackToEdit(record);
                        setEditCashbackOpened(true);
                        break;
                    case "PUBLISH":
                        onPublishToTestGroup(record.id)
                        break;
                    case "DUP":
                        onDuplicateClick(record);
                        break;
                    case "ADD_EXPENSES":
                        setAddExpensesModal({
                            isOpen: true,
                            id: record.id,
                            cashBackTitle: record?.name,
                            isReadOnly: true
                        })
                        break;
                    case "START":
                        changeCashbackStatus(record, CashbackStatus.IN_PROGRESS, true);
                        break;
                    case "READY_WITHOUT_PUBLISH":
                        changeCashbackStatus(record, CashbackStatus.IN_PROGRESS);
                        break;
                    case "STOP":
                        changeCashbackStatus(record, CashbackStatus.STOPPED);
                        break;
                    case "CANCEL":
                        changeCashbackStatus(record, CashbackStatus.CANCELED);
                        break;
                    case "READY_TO_START":
                        changeCashbackStatus(record, CashbackStatus.READY_TO_START);
                        break;
                    case "DELETE":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить акцию?',
                            onPress: () => handleDelete(record?.id)
                        })
                        break;
                    case "END":
                        changeCashbackStatus(record, CashbackStatus.ENDED);
                        break;
                }
            },
            className: "dropdown-border",
        };
        return actions;
    };

    const cashbackColumns = [
        {
            title: "Фото",
            dataIndex: "photo",
            key: "photo",
            width: "5%",
            render: (text: any, record: any) => {

                const handleClick = () => {
                    navigate(`/cashbacks/${record.id}`);
                };

                return (
                    <div
                        style={{textAlign: "center"}}
                    >
                        <img
                            onClick={handleClick}
                            src={text}
                            style={{width: "90px", height: '120px'}}
                        />{" "}
                    </div>
                );
            },
        },
        {
            title: "Имя акции",
            dataIndex: "name",
            key: "name",
            width: "7%",
        },
        {
            title: "Номенк.",
            dataIndex: "nmId",
            key: "nmId",
            width: "6%",
            render: (text: string, record: Cashback) => (
                <CopyToClipboard text={`${text}`}
                                 onCopy={() => message.success('Текст скопирован')}>
                    <span>
                      {text}
                    </span>
                </CopyToClipboard>

            ),
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "5%",
            render: (_: string, record: Cashback) => (
                <span>
          {record.cashbackItems.map((t) => (
              <span>
              {t.size}
                  <br/>
            </span>
          ))}
        </span>
            ),
        },
        {
            title: "Кол-во шт",
            dataIndex: "quantity",
            key: "quantity",
            width: "5%",
            render: (_: string, record: Cashback) => (
                <span>
          {record.cashbackItems.map((t) => (
              <span>
              {t.count}
                  <br/>
            </span>
          ))}
        </span>
            ),
        },
        {
            title: "Ключ",
            dataIndex: "keyword.title",
            key: "keyword.title",
            width: "6%",
            render: (_: string, record: Cashback) => (
                <span>{record.keyword.title}</span>
            ),
        },
        {
            title: "Кол-во в акции, шт",
            dataIndex: "promoQuantity",
            key: "promoQuantity",
            width: "7%",
            render: (_: string, record: Cashback) => (
                <span>
                    {record.maxItemsGiven
                        ?
                        record?.maxItemsGiven
                        :
                        record.cashbackItems
                            .map((t) => t.count)
                            .reduce((partialSum, a) => partialSum + a, 0)
                    }
                </span>
            ),
        },
        {
            title: "Кол-во отраб, шт",
            dataIndex: "doneQuantity",
            key: "doneQuantity",
            width: "7%",
            render: (_: string, record: Cashback) => (
                <span>
                     {record?.maxItemsGiven
                         ?
                         (record.cashbackItems
                                 .map((t) => t.count)
                                 .reduce((partialSum, a) => partialSum + a, 0)
                             -
                             record.cashbackItems
                                 .map((t) => t.remains)
                                 .reduce((partialSum, a) => partialSum + a, 0)
                         )
                         :
                         record.cashbackItems
                             .map((t) => t.givenAway)
                             .reduce((partialSum, a) => partialSum + a, 0)
                     }
                 </span>
            ),
        },
        {
            title: "Дата запуска",
            dataIndex: "publishDate",
            key: "publishDate",
            width: "7%",
            render: (_: string, record: Cashback) => (
                <span>{dayjs(record.publishDate).format("DD.MM.YYYY HH:mm")}</span>
            ),
        },
        // {
        //     title: "Текст для публ в стор. группах",
        //     dataIndex: "publishText",
        //     key: "publishText",
        //     width: "10%",
        //     render: (text: string, record: Cashback) => (
        //         <CopyToClipboard text={`${text}`}
        //                          onCopy={() => message.success('Текст скопирован')}>
        //             <span>{text?.length >= 50 ? `${text.slice(1, 50)}...` : text}</span>
        //         </CopyToClipboard>
        //     ),
        // },
        // {
        //     title: "Условия акции",
        //     dataIndex: "actionText",
        //     key: "actionText",
        //     width: "10%",
        //     render: (_: string, record: Cashback) => {
        //
        //         const data = record?.actionText?.length >= 50 ? `${record.actionText.slice(1, 50)}...` : record.actionText
        //
        //         return (
        //             <CopyToClipboard text={`${record?.actionText}`}
        //                              onCopy={() => message.success('Текст скопирован')}>
        //                 <span>
        //                     <span>{data}</span>
        //
        //                 </span>
        //             </CopyToClipboard>
        //         )
        //     }
        // },
        {
            title: "Сумма кб, руб",
            dataIndex: "cashbackSum",
            key: "cashbackSum",
            width: "6%",
        },
        {
            title: "% кб",
            dataIndex: "cashbackPercentage",
            key: "cashbackPercentage",
            width: "6%",
        },
        {
            title: "Статус кешбека",
            dataIndex: "cashbackActionStatus.title",
            key: "cashbackActionStatus.title",
            width: "9%",
            render: (_: string, record: Cashback) => (
                <span>{record.cashbackActionStatus.title}</span>
            ),
        },
        {
            title: "Затрат, руб",
            dataIndex: "costs",
            key: "costs",
            width: "6%",
            render: (_: string, record: Cashback) => <span>{record.expences}</span>,
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: "4%",
            render: (text: any, record: any) => (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getActions(record)}
                        overlayClassName={"dropdown-border"}
                    >
                        <MoreOutlined style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const navigate = useNavigate();

    const {
        analyticsCashbacks,
        currentPage,
        setSearchText,
        setCurrentPage,
        sortType,
        sortParam,
        setSortParam,
        setSortType,
        getCashbackStatuses,
        currentPageSize,
        setCurrentPageSize,
        isLoading
    } = useAnalyticsCashbackList();

    return (
        <>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input className="table-header-input" placeholder={"Поиск"}
                           onChange={(e) => setSearchText(e.target.value)}/>
                </Col>
                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon}/>}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                            {value: "500", label: "Показать по 500 шт."},
                        ]}
                    />
                </Col>
            </Row>
            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Row
                        justify={"space-between"}
                        align={"middle"}
                        style={{padding: "5px 20px 5px 30px"}}
                    >
                        <Col style={{
                            display: 'flex',
                            gap: 20,
                            alignItems: 'center'
                        }}>
                            <div style={{position: "relative"}}>
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setFilterMenuOpened(!filterMenuOpened);
                                    }}
                                >
                                    <Icon component={filterIcon}/>
                                    <span
                                        style={{
                                            color: "#82868B",
                                            marginLeft: "6px",
                                        }}
                                    >
                                        Фильтры
                                    </span>
                                </div>

                                <Menu
                                    className={
                                        filterMenuOpened
                                            ? "filter-menu filter-menu-opened"
                                            : "filter-menu filter-menu-closed"
                                    }
                                    style={{
                                        color: "red !important",
                                        width: "250px",
                                        left: "-40%",
                                    }}
                                    items={filterMenuItems}
                                    mode={"inline"}
                                    onClick={(e) => {
                                        const key = e.key;
                                        const test = key.split('-')
                                        setSortParam(test[0]);
                                        setSortType(test[1]);
                                    }}
                                />
                            </div>
                            <Dropdown
                                trigger={["click"]}
                                placement={"bottomRight"}
                                menu={{items: getCashbackStatuses()}}
                                overlayClassName={"dropdown-border"}
                            >
                                <div className="analytics-header-dropdown-v2">
                                    <Icon component={filterIcon}/>
                                    <span
                                        style={{
                                            color: "#82868B",
                                            marginRight: 12,
                                        }}
                                    >
                                        Статусы
                                    </span>
                                </div>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{width: "171px", fontSize: "12px"}}
                                ghost
                                onClick={() => navigate("expenses")}
                            >
                                Работа c затратами
                            </Button>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{marginLeft: "10px", width: "171px", fontSize: "12px"}}
                                ghost
                                onClick={() => navigate("participants")}
                            >
                                Работа с участниками
                            </Button>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{
                                    marginLeft: "10px",
                                    width: "171px",
                                    fontSize: "12px",
                                }}
                                ghost
                                onClick={() => navigate("users")}
                            >
                                Пользователи
                            </Button>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{
                                    marginLeft: "10px",
                                    width: "171px",
                                    fontSize: "12px",
                                }}
                                onClick={() => setAddCashbackOpened(true)}
                            >
                                Добавить акцию
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className={"cashback-table"}
                        columns={cashbackColumns}
                        dataSource={analyticsCashbacks?.data ?? []}
                        rowClassName={"cashback-table-row"}
                        pagination={false}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: (e) => {
                        //       navigate(`/analytics/cashbacks/${record.id}`);
                        //     },
                        //   };
                        // }}
                    />

                    <Pagination
                        defaultCurrent={1}
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        current={currentPage}
                        showSizeChanger={false}
                        total={analyticsCashbacks?.total}
                        pageSize={10}
                        style={{textAlign: "center", padding: "16px 0"}}
                    />
                </Col>

                <Modal
                    open={addExpensesModal.isOpen}
                    closable={false}
                    footer={null}
                    width={500}
                >
                    <AddExpensesModal
                        id={addExpensesModal.id}
                        cashBackTitle={addExpensesModal?.cashBackTitle}
                        isReadOnly={addExpensesModal?.isReadOnly}
                        onClose={() => {
                            setAddExpensesModal({
                                isOpen: false,
                                id: null
                            })
                        }}
                    />
                </Modal>
                <Modal
                    open={addCashbackOpened}
                    footer={null}
                    closable={false}
                    // width={982}
                    width={1320}
                >
                    <AddCashbackModalContent
                        onClose={() => setAddCashbackOpened(false)}
                    />
                </Modal>
                <Modal
                    open={isModalVisible.isOpen}
                    closable={false}
                    footer={null}
                    width={500}
                >
                    <ConfirmModal
                        onClose={() => setIsModalVisible({
                            isOpen: false,
                            onPress: () => {}
                        })}
                        isLoading={isLoadingDelete}
                        onConfirm={() => isModalVisible.onPress()}
                        text={isModalVisible.text}
                    />
                </Modal>
                {cashbackToEdit && (
                    <Modal
                        open={editCashbackOpened}
                        footer={null}
                        closable={false}
                        // width={982}
                        width={1320}
                    >
                        <EditCashbackModalContent
                            cashback={cashbackToEdit}
                            onClose={() => setEditCashbackOpened(false)}
                        />
                    </Modal>
                )}
            </Row>
        </>
    );
}
