import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useProductArrivalTableFilters() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По наименованию",
            key: "name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "name:desc",
                },
            ],
        },
        {
            label: "По описанию",
            key: "description",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "description:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "description:desc",
                },
            ],
        },
        {
            label: "По кол-ву",
            key: "count",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "count:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "count:desc",
                },
            ],
        },
        {
            label: "По стране",
            key: "country",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "country:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "country:desc",
                },
            ],
        },
        {
            label: "По бокскоду",
            key: "box_code",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "box_code:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "box_code:desc",
                },
            ],
        },
        {
            label: "По юр.лицу",
            key: "importer_name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "importer_name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "importer_name:desc",
                },
            ],
        },
        {
            label: "По штрихкоду",
            key: "barcode",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "barcode:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "barcode:desc",
                },
            ],
        },
        {
            label: "По производительности",
            key: "production_name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "production_name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "production_name:desc",
                },
            ],
        },
        {
            label: "По размеру ГТД",
            key: "dec_size",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "dec_size:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "dec_size:desc",
                },
            ],
        },
        {
            label: "По артикулу ГТД",
            key: "article_ccd",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "article_ccd:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "article_ccd:desc",
                },
            ],
        },
        {
            label: "По артикулу ВБ",
            key: "article_mkp",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "article_mkp:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "article_mkp:desc",
                },
            ],
        },
        {
            label: "По артикулу Пост.",
            key: "article_pvd",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "article_pvd:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "article_pvd:desc",
                },
            ],
        },
        {
            label: "По артикулу Кит.",
            key: "article_cns",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "article_cns:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "article_cns:desc",
                },
            ],
        },
        {
            label: "По дате создания",
            key: "created_at",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "created_at:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "created_at:desc",
                },
            ],
        },
        {
            label: "По дате обновления",
            key: "updated_at",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "updated_at:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А(def)",
                    key: "updated_at:desc",
                },
            ],
        },
    ];

    return {filterMenuItems};
}
