import {createEvent, createStore} from "effector";
import { persist } from 'effector-storage/local'

//seller
export const $selectedSeller = createStore('')
persist({
  store: $selectedSeller,
  key: "selectedSeller",
  keyPrefix: "v1",
});
export const onChangeSelectedSeller = createEvent()
$selectedSeller.on(onChangeSelectedSeller, (_, data) => data)

//type
export const $type = createStore('weeks')
persist({
  store: $type,
  key: "type",
  keyPrefix: "v1",
});
export const onChangeSelectedType = createEvent()
$type.on(onChangeSelectedType, (_, data) => data)