import {api} from "../index";
import dayjs from "dayjs";

export async function getLaboratoryResultData(
  searchValue?: string,
  page?: string,
  pageSize?: string,
  selectedSeasonType?: any,
) {
  let params = new URLSearchParams();

  if (searchValue) {
    params.append("search", searchValue);
  }
  if (pageSize) {
    params.append("limit", pageSize);
  }
  if (page) {
    params.append("page", page);
  }
  if (selectedSeasonType.key) {
    params.append("seasonType", selectedSeasonType.key);
  }

  const response = await api.get<any>(`tvt/results`, {
    params,
  });

  return response.data.data;
}

export async function getLaboratoryStatusesData() {
  let params = new URLSearchParams();

  const response = await api.get<any>(`tvt/statuses`, {
    params,
  });

  return response.data.data;
}

export async function getLaboratoryCurrentData(
  page?: string,
  pageSize?: string,
  selectedGender?: any,
  selectedSeasonType?: any,
  selectedStatus?: any,
  periodStartDate?: string,
  periodEndDate?: string,
  saleDate?: string,
  selectedProducts?: any
) {
  let params = new URLSearchParams();

  if (pageSize) {
    params.append("limit", pageSize);
  }
  if (page) {
    params.append("page", page);
  }
  if (selectedGender && selectedGender.length > 0) {
    params.append("gender", selectedGender?.map((item: any) => item?.key)?.join(','));
  }
  if (selectedSeasonType.key) {
    params.append("seasonType", selectedSeasonType.key);
  }
  if (selectedStatus && selectedStatus.length > 0) {
    params.append("status", selectedStatus?.map((item: any) => item?.key)?.join(','));
  }
  if (periodStartDate && periodEndDate) {
    params.append("dateTVTStart", dayjs(periodStartDate).format('DD.MM.YYYY'));
    params.append("dateTVTEnd", dayjs(periodEndDate).format('DD.MM.YYYY'));
  }
  if (saleDate) {
    params.append("saleData", dayjs(saleDate).format('DD.MM.YYYY'));
  }
  if (selectedProducts && selectedProducts.length > 0) {
    params.append("categoriesIds", selectedProducts?.map((item: any) => item?.id)?.join(","));
  }

  const response = await api.get<any>(`/tvt/current`, {
    params,
  });

  return response.data.data;
}

export async function getLaboratoryProducts(
  search?: string,
  notEmptyMode?: string,
) {

  let params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (notEmptyMode) {
    params.append("not_empty", notEmptyMode);
  }

  const response = await api.get<any>(`tvt/categories`, {params});
  return response.data.data;
}

export async function getLaboratoryAppointments(
  search?: string,
  notEmptyMode?: string,
) {

  let params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (notEmptyMode) {
    params.append("not_empty", notEmptyMode);
  }

  const response = await api.get<any>(`tvt/appointments`, {params});
  return response.data.data;
}