import { api } from "index";

export async function getAnalyticUserData(
    page?: string,
    pageSize?: string,
    searchValue?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (searchValue) {
        params.append("search", searchValue);
    }

    const response = await api.get<any>(`/analytics-users`, {
        params,
    });

    return response.data.data;
}

