import { Button, Modal } from 'antd'
import React, { FC } from 'react'

interface IConfirm {
	isOpen: boolean
	setIsOpen: any
	confirm: any
	title: string
}

export const ConfirmationModal: FC<IConfirm> = ({
	isOpen,
	setIsOpen,
	confirm,
	title,
}) => {
	return (
		<Modal
			title={<p style={{ padding: '20px 0 0 20px' }}>Подтвердите действие</p>}
			closable={false}
			visible={isOpen}
			footer={
				<div style={{ padding: '0px 20px 20px 20px' }}>
					<Button type='primary' onClick={() => setIsOpen(false)}>
						Нет
					</Button>
					<Button
						type='primary'
						onClick={() => {
							confirm()
							setIsOpen(false)
						}}
					>
						Да
					</Button>
				</div>
			}
		>
			<p style={{ padding: '0 20px 0 20px' }}>{title}</p>
		</Modal>
	)
}
