import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "../../api";

export const useSaveFile = () => {

    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: saveFile,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
        ...options
    } = useMutation(backend.declaration.saveAcceptanceFile, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`ACCEPTANCE_DATA`])]);
        },
    });

    const handleSave = useCallback(
        async (values: any) => {
            try {
                let body = { ...values };
                await saveFile(body);
                queryClient.invalidateQueries(['ACCEPTANCE_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [saveFile]
    );

    return {
        isSuccessCreate,
        handleSave,
        createLoading,
        isErrorCreate,
        errorMessage,
        ...options,
    };
};
