import React, {useState} from 'react';
import {ReactComponent as editIcon} from "../../../assets/images/edit-icon.svg";
import Icon from "@ant-design/icons";
import {Avatar, Col, Row} from "antd";
import avatarImage from "../../../assets/images/analytic-avatar.png";

const AnalyticsTableNote = ({
                                onOpen,
                                count,
                                text,
                                isShowHover,
                                hoverData
                            }: any) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        if (isShowHover) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (isShowHover) {
            setIsHovered(false);
        }
    };

    const sliceText = (text: any) =>{
        if (!text) return 'Нет данных'
        if (text?.length > 10){
            return text.slice(0,10) + '...'
        } else {
            return text
        }
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={"analytics-col"}
            style={{
                width: "13%",
                backgroundColor: "#FFFFFF",
            }}
        >
            <div className={"analytics-col-title"}>
                <span>Заметки</span>
            </div>

            <div className={"analytics-col-text"}>
                <span>
                    {sliceText(text)} {""}
                    <Icon
                        component={editIcon}
                        style={{cursor: "pointer"}}
                        onClick={onOpen}
                    />
                    {`(` + count + `)`}
                </span>
            </div>
            {isHovered && (
                <div className="hover-info-frame-wrap" style={{}}>
                    {!hoverData?.noteText ? (
                        <div>
                            Нет данных
                        </div>
                    ) : (
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            alignItems: 'flex-start',
                        }}>
                            <div style={{
                                fontWeight: 800,
                                whiteSpace: 'pre-line',
                            }}>
                                {hoverData?.noteTitle}
                            </div>
                            <div style={{
                                paddingLeft: 5,
                                whiteSpace: 'pre-line',
                            }}>
                                {hoverData?.noteText}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AnalyticsTableNote;