import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Dropdown, message, Modal, Row} from "antd";
import dayjs from "dayjs";
import keywordGraph from "../../../../assets/images/keyword-graph.png";
import greenArrowToTop from "../../../../assets/images/ArrowToTop.svg";
import arrowToBottom from "../../../../assets/images/ArrowToBottom.svg";
import {useChangeStatusOfIssuingPositions} from "../../hooks/issuingPositions/useChangeStatusOfIssuingPositions";
import KeywordModalContent from "../../modal/issuingPositions/KeywordModalContent";
import AddToAdvertisementModal from "../../modal/issuingPositions/AddToAdvertisementModal";

const IssuingPositionsItem = ({item, handleShowMore}: any) => {

    const {
        isSuccessChange,
        handleCreate,
        isErrorChange,
        changeLoading,
        errorMessage,
    } = useChangeStatusOfIssuingPositions()

    const [keywordModalOpened, setKeywordModalOpened] = useState<any>({
        isOpen: false,
        id: null
    });

    const [addToAdvertisementModal, setAddToAdvertisementModal] = useState<any>({
        isOpen: false,
        id: null
    });

    const renderArrow = (min: any, max: any) => {
        if (min === 0) return (
            <img
                src={greenArrowToTop}
                alt=""
                style={{width: 16, height: 16, marginBottom: 2}}
            />
        )
        if (max === 0) return (
            <img
                src={arrowToBottom}
                alt=""
                style={{width: 16, height: 16, marginBottom: 2}}
            />
        )
        if ((Number(min) - Number(max)) > 0) {
            return (
                <img
                    src={greenArrowToTop}
                    alt=""
                    style={{width: 16, height: 16, marginBottom: 2}}
                />
            )
        } else {
            return (
                <img
                    src={arrowToBottom}
                    alt=""
                    style={{width: 16, height: 16, marginBottom: 2}}
                />
            )
        }
    }

    useEffect(() => {
        if (isErrorChange) {
            message.error(errorMessage)
        }
        if (isSuccessChange) {
            message.success('')
        }
    }, [isSuccessChange, isErrorChange])

    return (
        <div className="issuing-positions-item">
            <div className="issuing-positions-item-info">
                <img src={item?.photo} className="issuing-positions-item-info-img" alt={'img'}/>
                <div className="issuing-positions-item-info-data">
                    <div className="issuing-positions-item-info-data-title">
                        {item?.productName}
                    </div>
                    <a
                        className="issuing-positions-item-info-data-elem"
                        href={`https://wildberries.ru/catalog/${item?.nmId}/detail.aspx?targetUrl=SP`}
                        target='_blank'
                        style={{
                            color: '#fff',
                            backgroundColor: '#4C78EE'
                        }}
                    >
                        {item?.nmId}
                    </a>
                    <div className="issuing-positions-item-info-data-elem">
                        Автореклама
                    </div>
                    <Checkbox
                        disabled={changeLoading}
                        checked={item?.inAds}
                        onChange={() =>
                            item?.inAds
                                ? handleCreate({inAds: false, id: item?.productId})
                                : setAddToAdvertisementModal({isOpen: true, id: item?.productId})
                        }
                    >
                        Добавить в рекламу
                    </Checkbox>
                    {/*<div className="issuing-positions-item-info-data-elem">*/}
                    {/*    Ставка: {item?.price} ₽*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="issuing-positions-item-table">
                {
                    item?.keywords?.length === 0
                        ? <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                padding: '30px 0'
                            }}
                        >
                            Нет данных
                        </div>
                        :
                        <table className="issuing-positions-table">
                            <thead>
                            <th style={{width: '40%'}}></th>
                            <th style={{width: '20%'}}>{dayjs(item?.minDate).format('DD.MM.YYYY')}</th>
                            <th style={{width: '20%'}}>{dayjs(item?.maxDate).format('DD.MM.YYYY')}</th>
                            <th style={{width: '20%'}}></th>
                            </thead>
                            <tbody style={{width: '100%'}}>
                            {
                                !item?.isShowMore
                                    ?
                                    item?.keywords?.slice(0, 10)?.map((item: any) =>
                                        <tr style={{width: '100%', textAlign: 'center'}}>
                                            <td style={{width: '50%', textAlign: 'center'}}>
                                                {item?.keywordTitle}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                {item?.minDatePosition == 0 ? '-' : item?.minDatePosition}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                {item?.maxDatePosition == 0 ? '-' : item?.maxDatePosition}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 5
                                                }}>
                                                    {Math.abs(Number(item?.minDatePosition) - Number(item?.maxDatePosition))}
                                                    {
                                                        renderArrow(item?.minDatePosition, item?.maxDatePosition)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    item?.keywords?.map((item: any) =>
                                        <tr style={{width: '100%', textAlign: 'center'}}>
                                            <td style={{width: '50%', textAlign: 'center'}}>
                                                {item?.keywordTitle}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                {item?.minDatePosition == 0 ? '-' : item?.minDatePosition}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                {item?.maxDatePosition == 0 ? '-' : item?.maxDatePosition}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 5
                                                }}>
                                                    {Math.abs(Number(item?.minDatePosition) - Number(item?.maxDatePosition))}
                                                    {
                                                        renderArrow(item?.minDatePosition, item?.maxDatePosition)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>
                }
            </div>

            <div className="issuing-positions-item-table-buttons">
                {
                    item?.keywords?.length > 11 &&
                    <div
                        className="issuing-positions-item-table-buttons-show-more"
                        onClick={() => handleShowMore(item?.productId)}
                    >
                        {item?.isShowMore
                            ? 'Свернуть список ключевых слов'
                            : 'Развернуть список ключевых слов'
                        }
                    </div>
                }

                <div
                    style={{
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                        borderRadius: 10,
                        backgroundColor: "#E6E7E8",
                        cursor: "pointer",
                    }}
                    onClick={() => setKeywordModalOpened({
                        isOpen: true,
                        id: item?.productId
                    })}
                >
                    <div className={"analytics-col-title"} style={{marginBottom: 5}}>
                        <span>График по ключевым словам</span>
                    </div>

                    <div>
                        <span>
                            <img src={keywordGraph} alt={""}></img>
                        </span>
                    </div>
                </div>

                {/*<Button*/}
                {/*    className={"button"}*/}
                {/*    type={"primary"}*/}
                {/*    style={{width: "100%"}}*/}
                {/*    htmlType={"submit"}*/}
                {/*>*/}
                {/*    Скачек с 120 места*/}
                {/*</Button>*/}
            </div>

            <Modal
                open={keywordModalOpened.isOpen}
                closable={false}
                footer={null}
                width={1169}
            >
                <KeywordModalContent
                    onClose={() => setKeywordModalOpened({id: null, isOpen: false})}
                    id={keywordModalOpened.id}
                />
            </Modal>

            <Modal
                open={addToAdvertisementModal.isOpen}
                closable={false}
                footer={null}
                width={380}
            >
                <AddToAdvertisementModal
                    onClose={() => setAddToAdvertisementModal({id: null, isOpen: false})}
                    id={addToAdvertisementModal.id}
                />
            </Modal>
        </div>
    );
};

export default IssuingPositionsItem;