import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useCashbackExpensesTableFilter() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По сумме",
            key: "sum_payed",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "sum_payed:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "sum_payed:desc",
                },
            ],
        },
        {
            label: "По номеру",
            key: "id",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "id:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "id:desc",
                },
            ],
        },
        {
            label: "По кешбэку",
            key: "cashback",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "cashback:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "cashback:desc",
                },
            ],
        },
        {
            label: "По аналитику",
            key: "analytic",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "analytic:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "analytic:desc",
                },
            ],
        },
        {
            label: "По документу об оплате",
            key: "payment_url",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "payment_url:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "payment_url:desc",
                },
            ],
        },
        {
            label: "По оплатившему лице",
            key: "payed_whom",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "payed_whom:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "payed_whom:desc",
                },
            ],
        },
    ];

    return {filterMenuItems};
}
