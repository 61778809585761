import React, {useEffect, useState} from "react";
import {
    AutoComplete,
    Button, Checkbox,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu, MenuProps, message, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as uploadButtonIcon} from "assets/images/upload-button-icon.svg";
import {ReactComponent as columnsIcon} from "assets/images/columns-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";
import {useFilteredEmissionFullInfoColumns} from "../../hooks/emission/useFilteredEmissionFullInfoColumns";
import useEmissionsDataById from "../../hooks/emission/useEmissionsDataById";
import {useDeleteEmissionFullInfo} from "../../hooks/emission/useDeleteEmissionFullInfo";
import selectIcon from "../../../../assets/images/select-icon.svg";
import {ReactComponent as rangePickerArrow} from "../../../../assets/images/rangepicker-arrow.svg";
import {downloadEmissionFile, downloadFile} from "../../help/downloadFile";
import useEmissionMainTableFilterBuId from "../../hooks/emission/useEmissionMainTableFilterBuId";

const {RangePicker} = DatePicker;

export default function EmissionsFullInfo() {

    const navigate = useNavigate()
    const route = useParams()

    const {
        emissionsById,
        currentPage,
        setCurrentPage,
        id,
        currentPageSize,
        setCurrentPageSize,
        setId,
        search,
        setSearch,
        isLoading,
        searchAcricle,
        setSearchAcricle,
        onChangeDateCreate,
        onChangeDateUpdate,
        onChangeDateCustomed,
        getBoxCodeFilterItems,
        getSizeFilterItems,
        selectedSizes,
        selectedBoxCode,
        dateCreateStart,
        dateCreateEnd,
        dateUpdateStart,
        dateUpdateEnd,
        dateCustomedStart,
        dateCustomedEnd,
        chineArticle,
        setOrderBy
    } = useEmissionsDataById()

    const {
        handleDelete,
        errorMessage,
        isSuccess: isSuccessDeleteEmisson,
        isError: iserrorDeleteEmission
    } = useDeleteEmissionFullInfo()

    useEffect(() => {
        setId(route.id)
    }, [])

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const {filterMenuItems} = useEmissionMainTableFilterBuId();

    const {
        getMenu,
        filteredColumns,
    } = useFilteredEmissionFullInfoColumns()

    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (visible: any) => {
        setVisible(visible);
    };

    useEffect(() => {
        if (isSuccessDeleteEmisson) {
            message.success('Вы успешно удалилил эмисиию')
        }
    }, [])

    useEffect(() => {
        if (iserrorDeleteEmission) {
            message.success(errorMessage)
        }
    }, [])


    const handleSearch = (value: any) => {
        setSearchAcricle(value);
    };

    const handleSelect = (value: any) => {
        setSearchAcricle(value);
    };

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Col span={24}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    <Icon component={headerPrevBtn}/>
                    Назад
                </div>
            </Col>
            <Col span={24} style={{
                marginTop: 20,
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    {emissionsById?.title}
                </div>
            </Col>

            <Row gutter={6} align={"middle"} style={{marginBottom: 10}}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск по названию"}
                        // type={"number"}
                    />
                </Col>
                <Col
                    style={{flexGrow: "1"}}
                >
                    <AutoComplete
                        style={{
                            width: '100%'
                        }}
                        className="custom-autocomplete"
                        dropdownClassName="custom-autocomplete-dropdown"
                        placeholder="Поиск по китайскому артикулу"
                        dataSource={chineArticle?.filter((item: any) => item?.toLowerCase()?.includes(searchAcricle?.toLowerCase()))?.slice(0, 10)}
                        value={searchAcricle}
                        onChange={handleSearch}
                        onSelect={handleSelect}
                    />

                </Col>
                <Col style={{width: "88px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSizeFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Размер</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "98px"}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getBoxCodeFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Box_code</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon} alt={""}/>}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>
            <Row style={{
                marginTop: 10,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Col span={7}>
                    <RangePicker
                        style={{width: '350px'}}
                        onChange={onChangeDateCreate}
                        showTime={{format: 'HH-mm-ss'}}
                        placeholder={["Дата создания(от)", "Дата создания(до)"]}
                        separator={
                            <Icon component={rangePickerArrow}/>
                        }
                        format={"DD.MM.YYYY HH:mm:ss"}
                    />
                </Col>
                <Col span={7}>
                    <RangePicker
                        style={{width: '350px'}}
                        onChange={onChangeDateUpdate}
                        showTime={{format: 'HH-mm-ss'}}
                        placeholder={["Дата обновления(от)", "Дата обновления(до)"]}
                        separator={
                            <Icon component={rangePickerArrow}/>
                        }
                        format={"DD.MM.YYYY HH:mm:ss"}
                    />
                </Col>
                <Col span={7}>
                    <RangePicker
                        style={{width: '350px'}}
                        onChange={onChangeDateCustomed}
                        showTime={{format: 'HH-mm-ss'}}
                        placeholder={["Дата загрузки(от)", "Дата загрузки(до)"]}
                        separator={
                            <Icon component={rangePickerArrow}/>
                        }
                        format={"DD.MM.YYYY HH:mm:ss"}
                    />
                </Col>
            </Row>
            <Col
                span={24}
                className="kiz-actions-wrapper"
            >
                <div className="kiz-actions-wrapper-left">
                    <div style={{position: "relative"}}>
                        <div
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setFilterMenuOpened(!filterMenuOpened);
                            }}
                        >
                            <Icon component={filterIcon}/>
                            <span
                                style={{
                                    color: "#82868B",
                                    marginLeft: "6px",
                                }}
                            >
                                Фильтры
                            </span>
                        </div>

                        <Menu
                            className={
                                filterMenuOpened
                                    ? "filter-menu filter-menu-opened"
                                    : "filter-menu filter-menu-closed"
                            }
                            style={{
                                color: "red !important",
                                width: "250px",
                                left: "-40%",
                            }}
                            items={filterMenuItems}
                            mode={"inline"}
                            onClick={(e) => {
                                setOrderBy(e.key)
                            }}
                        />
                    </div>
                    <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        overlay={getMenu()}
                        overlayStyle={{width: "218px"}}
                        overlayClassName="dropdown-border"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                    >
                        <div style={{cursor: "pointer"}}>
                            <Icon component={columnsIcon}/>
                            <span style={{marginLeft: 5}}>Столбцы</span>
                        </div>
                    </Dropdown>
                </div>
                <div>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "171px"}}
                        onClick={() => {
                            const id = route.id
                            downloadEmissionFile(
                                `${process.env.REACT_APP_API_URL_KIZ_FULL_INFO}emissions/${route.id}/cises/xlsx`,
                                true,
                                selectedSizes,
                                selectedBoxCode,
                                search,
                                searchAcricle,
                                dateCreateStart,
                                dateCreateEnd,
                                dateUpdateStart,
                                dateUpdateEnd,
                                dateCustomedStart,
                                dateCustomedEnd,
                                id
                            )
                        }}
                    >
                        Выгрузить
                    </Button>
                </div>
            </Col>

            <Row className={"container"}>

                <Col span={24}>
                    <Table
                        loading={isLoading}
                        className="product-arrival-table"
                        columns={filteredColumns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={emissionsById?.data || []}
                        scroll={{x: true}}
                        pagination={{
                            onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            pageSize: Number(currentPageSize),
                            total: Number(emissionsById?.total),
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
