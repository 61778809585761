import React, {useState} from 'react';
import priceChangeGraph from "../../../assets/images/price-change-graph.png";

const AnalyticsTableChart = ({
                                 onOpen,
                                 img,
                                 name,
                                 isShowHover = false,
                                 hoverData = {}
                             }: any) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        if (isShowHover) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (isShowHover) {
            setIsHovered(false);
        }
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onOpen}
            className={"analytics-col"}
            style={{
                width: "15%",
                backgroundColor: "#E6E7E8",
                cursor: "pointer",
            }}
        >
            <div className={"analytics-col-title"}>
                <span>{name}</span>
            </div>

            <div>
                <span>
                    <img src={img} alt={""}></img>
                </span>
            </div>
            {isHovered && hoverData && (
                <div className="hover-info-frame-wrap">
                    {Object.entries(hoverData).length === 0 ? (
                        <div>
                            Нет данных
                        </div>
                    ) : (
                        Object.keys(hoverData).map((key) => (
                            <div key={key}>
                                <span style={{fontWeight: 800}}>{key}</span>: {hoverData[key]}
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default AnalyticsTableChart;