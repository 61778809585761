import {api} from "../index";


export async function getChatTypesData() {
    const response = await api.get<any>(`/investor-chats/types`);
    return response.data.data;
}

export async function getChatByChatType(
    chatType: any,
    page: any,
    limit: any,
) {

    let params = new URLSearchParams();

    if (page) {
        params.append("page", page);
    }
    if (limit) {
        params.append("limit", limit);
    }
    if (chatType) {
        const response = await api.get<any>(`/investor-chats/chats/types/${chatType}`, {params});
        return response.data.data;
    }
}

export async function getMessagesByChat(
    selectedChat: any,
    page: any,
    limit: any,
) {

    let params = new URLSearchParams();

    if (page) {
        params.append("page", page);
    }
    if (limit) {
        params.append("limit", limit);
    }

    if (selectedChat){
        const response = await api.get<any>(`/investor-chats/chats/${selectedChat}/messages`, {params});
        return response.data.data;
    }

}

export async function postMessageToChat(json: any) {

    const response = await api.post<any>(`/investor-chats/message`, json);
    return response.data.data;
}

