import { useQuery } from "react-query";
import {backend} from "../../apiExtraInfo";
import {useState} from "react";

export default function useEmmissionsDataFilterChineArticle() {

    const [id, setId] = useState<any>('')
    const [fieldParam, setFieldParam] = useState<any>('article_china')


    const { data: chineArticle, ...options } = useQuery(
        ["EMISSIONS_DATA_FILTER_SIZE_CHINE_ARTICLE", id, fieldParam],
        () => backend.emissions.getEmissionsDataFilterSize(
            id,
            fieldParam.toString()
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        chineArticle,
        setId,
        ...options,
    };
}
