import {Col, Row} from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import ShipmentTable from "../../features/kiz/pages/shipment/ShipmentTable";

export default function ShipmentTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col className={"container"} span={24}>
                <ShipmentTable/>
            </Col>
        </Row>
    );
}
