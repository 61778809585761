import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as SelectIconComponent } from "assets/images/select-icon.svg";
import { ReactComponent as uploadButtonIcon } from "assets/images/upload-button-icon.svg";
import AddOrderModal from "./modal/AddOrderModal";

const data = [
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },

];

export default function OrdersFromChinaTable() {

    const [makeOrderModal, setMakeOrderModal] = useState<boolean>(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [keywordIds , setKeywordIds] = useState<any>([]);
    const [selectedType, setSelectedType] = useState<number>(1);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    // useEffect(() => {
    //     setKeywordIds(selectedRowKeys)
    // }, [selectedRowKeys])


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "9%",
        },
        {
            title: "Артикул китайский",
            dataIndex: "art",
            key: "art",
            width: "9%",
        },
        {
            title: "Товар",
            dataIndex: "product",
            key: "product",
            width: "9%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "9%",
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            key: "count",
            width: "9%",
        },
        {
            title: "Цвет",
            dataIndex: "color",
            key: "color",
            width: "9%",
        },
        {
            title: "Статус заказа",
            dataIndex: "status",
            key: "status",
            width: "9%",
        },
        {
            title: "Дата оформ заявки",
            dataIndex: "date",
            key: "date",
            width: "9%",
        },
        {
            title: "Дата прихода",
            dataIndex: "dateCode",
            key: "dateCode",
            width: "9%",
        },
        {
            title: "Инвестор",
            dataIndex: "investor",
            key: "investor",
            width: "9%",
        },
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     render: (text: any, record: any) => (
        //         <div
        //             style={{
        //                 textAlign: "center",
        //                 display: "flex",
        //                 gap: "7px",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 cursor: "pointer",
        //             }}
        //             onClick={() => {
        //                 setEditProductOpened(true);
        //                 setProductToEdit(record);
        //             }}
        //         >
        //             <Icon component={editIcon} style={{fontSize: "12px"}}/>
        //         </div>
        //     ),
        // },
    ];


    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        // value={searchText}
                        // onChange={(e) => setSearchText(e.target.value)}
                        className="table-header-input"
                        placeholder={"Поиск"}
                        // type={"number"}
                    />
                </Col>

                <Col style={{width: "250px"}} >
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        defaultValue={"10"}
                        // onChange={(e) => {
                        //     setCurrentPage(1);
                        //     setCurrentPageSize(e);
                        // }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>

                <Col style={{width: "250px"}}>
                    <div
                        className="table-header-add-product"
                    >
                        Сформировать заказ на выкуп
                    </div>
                </Col>

                <Col>
                    <Button
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => setMakeOrderModal(true)}
                    >
                        Добавить заказ
                        <Icon
                            component={uploadButtonIcon}
                            style={{fontSize: "18px", display: "block"}}
                        />
                    </Button>
                </Col>
            </Row>
            <Row style={{marginTop: 10}}>
                <Col style={{width: '33.3%'}}>
                    <Button
                        className={
                            selectedType === 1
                                ? "table-tab-button-active-without-circle"
                                : "table-tab-button-without-circle"
                        }
                        style={{borderTopLeftRadius: '10px'}}
                        onClick={() => setSelectedType(1)}
                    >
                        Активные
                    </Button>
                </Col>
                <Col style={{width: '33.3%'}}>
                    <Button
                        className={
                            selectedType === 2
                                ? "table-tab-button-active-without-circle"
                                : "table-tab-button-without-circle"
                        }
                        onClick={() => setSelectedType(2)}
                    >
                        Отклоненные
                    </Button>
                </Col>
                <Col style={{width: '33.3%'}}>
                    <Button
                        className={
                            selectedType === 3
                                ? "table-tab-button-active-without-circle"
                                : "table-tab-button-without-circle"
                        }
                        style={{borderTopRightRadius: '10px'}}
                        onClick={() => setSelectedType(3)}
                    >
                        Завершенные
                    </Button>
                </Col>
            </Row>

            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        // loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={data}
                        scroll={{x: true}}
                        rowSelection={rowSelection}
                        pagination={{
                            // onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            // pageSize: Number(currentPageSize),
                            // total: Number(storageData?.total),
                            // showSizeChanger: false,
                            // current: currentPage,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => {
                                    // setProductToInfo(record);
                                    // setProductInfoOpened(true);
                                },
                            };
                        }}
                    />
                </Col>
            </Row>

            <Modal
                open={makeOrderModal}
                closable={false}
                footer={null}
                width={600}
            >
                <AddOrderModal
                    onClose={() => setMakeOrderModal(false)}
                />
            </Modal>
        </div>
    );
}
