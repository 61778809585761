import {Avatar, Col, Row} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as blueIcon} from "assets/images/article-blue.svg";
import {ReactComponent as dotForMessages} from "assets/images/dotForMessages.svg";
import avatarImage from "assets/images/analytic-avatar.png";
import line from "assets/images/article-line.png";
import dayjs from "dayjs";
import {ReactComponent as editIcon} from "../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../assets/images/trash-bucket.svg";
import React from "react";
import {ReactComponent as orangeIcon} from "../../../assets/images/article-orange.svg";


export default function Message({ data, isLastElement, isOwnMessage}: any) {

    return (
        <div className="message-wrap">
            <div className={`message-left ${isLastElement && "last-message"}`}>
                <Icon
                    component={
                        isOwnMessage ? dotForMessages : orangeIcon
                    }
                    style={{ fontSize: "20px" }}
                />
            </div>
            <div className="message-center">
                <div className="message-center-text">
                    {data?.text}
                </div>
                <div className="message-center-user">
                    <div className="message-center-user-avatar">
                        <Avatar src={avatarImage} size={32}/>
                    </div>
                    <div className="message-center-user-data">
                        <div className="message-center-user-data-name">
                            {`${data?.user?.firstName} ${data?.user?.lastName}`}
                        </div>
                        <div className="message-center-user-data-name-position">
                            {data?.user?.email}
                        </div>
                    </div>
                </div>
            </div>
            <div className="message-right">
                {dayjs(data?.updatedAt).format("HH:mm YYYY-MM-DD")}
            </div>
        </div>

    );
}
