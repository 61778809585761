import { Button, Col, Form, Input, Row } from "antd";
import ModalHeader from "features/shared/ModalHeader";
import { useEffect, useState } from "react";
import { useAnalyticsCashbackUsersUpdate } from "../hooks/useAnalyticsCashbackUsersUpdate";

const { TextArea } = Input;

export default function EditCashbackUserModalContent({
  onClose,
  onCashbackUserUpdated,
  cashbackUser,
}: any) {

  const { handleUpdate, isError, errorMessage, isLoading } =
    useAnalyticsCashbackUsersUpdate(cashbackUser.id);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      firstName: cashbackUser.firstName,
      lastName: cashbackUser.lastName,
      middleName: cashbackUser.middleName,
      phoneNumber: cashbackUser.phoneNumber,
      tgNickname: cashbackUser.tgNickname,
      bannedText: cashbackUser.bannedText,
    });
  }, [cashbackUser]);

  const onUpdate = (values: any, onClose: any) => {
    //sending only updated values
    let updatedValues = { ...values };

    if (updatedValues.firstName === cashbackUser.firstName) {
      delete updatedValues.firstName;
    }
    if (updatedValues.lastName === cashbackUser.lastName) {
      delete updatedValues.lastName;
    }
    if (updatedValues.middleName === cashbackUser.middleName) {
      delete updatedValues.middleName;
    }
    if (updatedValues.phoneNumber === cashbackUser.phoneNumber) {
      delete updatedValues.phoneNumber;
    }
    if (updatedValues.tgNickname === cashbackUser.tgNickname) {
      delete updatedValues.tgNickname;
    }
    if (updatedValues.bannedText === cashbackUser.bannedText) {
      delete updatedValues.bannedText;
    }

    handleUpdate(updatedValues, onClose);
  };

  return (
    <div className={"modal-wrapper"} style={{ padding: "30px" }}>
      <ModalHeader title={"Редактировать пользователя"} onClose={onClose} />
      <Form
        form={form}
        layout={"vertical"}
        onFinish={(values) => onUpdate(values, onClose)}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item name={"firstName"} label={"Имя пользователя"}>
              <Input />
            </Form.Item>
            <Form.Item name={"lastName"} label={"Фамилия пользователя"}>
              <Input />
            </Form.Item>
            <Form.Item name={"middleName"} label={"Отчество пользователя"}>
              <Input />
            </Form.Item>
            <Form.Item
              name={"phoneNumber"}
              label={"Номер телефона пользователя"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"tgNickname"}
              label={"Ник пользователя в telegram"}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"bannedText"} label={"Заметки"}>
              <TextArea style={{ height: "auto !important" }} rows={15} />
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginTop: "10px" }}
        >
          <Col style={{ display: "flex", gap: "15px" }}>
            <Button
              type={"primary"}
              className={"button"}
              htmlType={"submit"}
              loading={isLoading}
              style={{ fontSize: "12px", width: "222px" }}
            >
              Сохранить изменения
            </Button>
            <Button
              type={"primary"}
              ghost
              className={"button"}
              style={{ fontSize: "12px", width: "222px" }}
              onClick={onClose}
            >
              Отмена
            </Button>
          </Col>
          <Col>
            {!cashbackUser.isBanned ? (
              <Button
                type={"primary"}
                className={"button"}
                style={{
                  fontSize: "12px",
                  backgroundColor: "#EA5455",
                  padding: "8px 30px",
                }}
                onClick={() => {
                  onUpdate({ isBanned: true }, onClose);
                }}
              >
                Добавить в бан
              </Button>
            ) : (
              <Button
                type={"primary"}
                className={"button"}
                style={{
                  fontSize: "12px",
                  backgroundColor: "#28C76F",
                  padding: "8px 30px",
                }}
                onClick={() => onUpdate({ isBanned: false }, onClose)}
              >
                Удалить из бана
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}
