import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useDeclarationUpdate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading: updateLoading,
        isError,
        isSuccess,
    } = useMutation(backend.expenses.updateExpensesAdById, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`EXPENSES_AD_DATA`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, id: string, onClose: any, currentPage: any) => {
            try {
                let body = { ...values };

                await update({ id: id, ...body });
                queryClient.invalidateQueries(['EXPENSES_AD_DATA', currentPage, 10]);

                if (onClose) {
                    onClose();
                }
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при редактировании");
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: updateLoading,
        isError,
        errorMessage,
        isSuccess
    };
};
