import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "../../../../api";
import {useCallback, useEffect, useState} from "react";
import useAdvertisingMirrorDataCount from "./useAdvertisingMirrorDataCount";
import {getAllCountPage} from "../../utils/heplers";

export default function useAdvertisingMirrorData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sellerId, setSellerId] = useState<any>();
    const [offSet, setOffSet] = useState<number>(0);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [allCountPage, setAllCountPage] = useState<any>(null)

    const {
        allCount,
        adverts,
        selectedSeller,
        setSelectedSeller,
    } = useAdvertisingMirrorDataCount();

    const onChangePage = (page: number) => {
        setCurrentPage(page)
        setOffSet(Number(page - 1) * Number(currentPageSize))
        // console.log(Number(page - 1) * Number(currentPageSize))
    }

    useEffect(() => {
        setAllCountPage(getAllCountPage({adverts, selectedType, selectedStatus}))
    }, [selectedType, selectedStatus])

    useEffect(() => {
        setAllCountPage(allCount)
    }, [allCount])

    const {data: advertising, ...options} = useQuery(
        ["ADVERTISING_MIRROR_DATA", sellerId, offSet, currentPageSize, selectedStatus, selectedType],
        () =>
            backend.advertisingMirror.getAdvertisingMirrorData(
                sellerId,
                offSet.toString(),
                currentPageSize,
                selectedStatus.toString(),
                selectedType.toString(),
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        advertising,
        allCountPage,
        allCount,
        setOffSet,
        currentPage,
        setCurrentPage,
        onChangePage,
        currentPageSize,
        setCurrentPageSize,
        selectedStatus,
        setSelectedStatus,
        selectedType,
        setSelectedType,
        sellerId,
        setSellerId,
        selectedSeller,
        setSelectedSeller,
        ...options,
    };
}
