import {Row, Col} from "antd/es/grid";
import {Avatar, Badge, Dropdown, MenuProps, theme} from "antd";
import {BellOutlined, LogoutOutlined} from "@ant-design/icons";
import logo from "assets/images/logo.svg";
import logoWhite from "assets/images/logo-white.svg";
import burger from "assets/images/headerBurger.svg";
import burgerWhite from "assets/images/burgerWhite.png";
import avatarImage from "assets/images/avatar.png";
import arrowToLeft from "assets/images/arrowToLeft.svg";
import {useAuthStore} from "features/auth/hooks/useAuthStore";
import {removeToken} from "utils/tokenStorage";
import useCurrentUserData from "../users/hooks/useCurrentUserData";
import {useLocation} from 'react-router-dom';

export default function Header({isShowBurger, setIsShowBurger, isFinance}: any) {

    const {
        token: {colorBgContainer, colorPrimary},
    } = theme.useToken();

    const {state} = useAuthStore();
    const isInvestor = state?.user?.role?.name === 'Инвестор';

    const {actions} = useAuthStore();


    const {
        user,
        isLoading: isLoadingCurrentUser
    } = useCurrentUserData();

    const onLogout = () => {
        actions.logout();
        removeToken();
    };

    const items: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}} onClick={onLogout}>
                    <LogoutOutlined/>
                    <span>Выйти</span>
                </span>
            ),
            key: "1",
        },
    ];

    return (
        <>
            <Row
                justify={"space-between"}
                className="header-mob"
                style={{
                    width: '100%',
                    // backgroundColor: colorBgContainer
                    backgroundColor: isInvestor || isFinance ? '#5C4E73' : '#fff',
                }}
            >
                {isShowBurger
                    ?
                    <Col style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}>
                        <div className="header-burger" onClick={() => setIsShowBurger(!isShowBurger)}>
                            {
                                isInvestor || isFinance
                                    ? <img src={burgerWhite} alt="" style={{width: 20}}/>
                                    : <img src={burger} alt="" style={{width: 20}}/>
                            }
                            {/*<img src={logoWhite} alt="" style={{width: 20}}/>*/}
                        </div>
                        <div className="header-actions-container">
                            {" "}
                            <div style={{margin: "0 14px 0 27px"}}>
                                <div style={{color: isInvestor || isFinance ? "#F8F8F8" : 'rgb(75, 75, 75)'}}>
                                    {/*<div style={{color: "#4B4B4B"}}>*/}
                                    {`${user?.firstName ? user?.firstName : '---'} ${user?.lastName ? user?.lastName : '---'} `}
                                </div>
                                <div style={{color: isInvestor || isFinance ? "#82868B" : 'rgb(130, 134, 139)', fontSize: "12px"}}>
                                    {/*<div style={{color: "#82868B", fontSize: "12px"}}>*/}
                                    {`${user?.role?.name ? user?.role?.name : '---'}`}
                                </div>
                            </div>
                            <Dropdown
                                placement="bottomRight"
                                trigger={["click"]}
                                menu={{items: items}}
                            >
                                <Badge color={"#28C76F"} dot offset={[-5, 33]}>
                                    <Avatar
                                        src={user?.photo ? `${process.env.REACT_APP_API_URL}storage/${user?.photo}` : avatarImage}
                                        size={38}
                                        style={{cursor: "pointer"}}
                                    />
                                </Badge>
                            </Dropdown>
                        </div>
                    </Col>
                    :
                    <Col>
                        <div className="company-title-container-mob" style={{backgroundColor: isInvestor || isFinance ? '#5C4E73' : '#fff'}}>
                            {" "}
                            <img src={logo}></img>
                            {/*<div*/}
                            {/*    className="company-title"*/}
                            {/*    style={{color: colorPrimary}}*/}
                            {/*>*/}
                            {/*    MA Decigi*/}
                            {/*</div>*/}
                        </div>
                        <div className="header-mob-close" onClick={() => setIsShowBurger(!isShowBurger)}>
                            <img src={arrowToLeft} alt=""/>
                            <h2 style={{color: isInvestor || isFinance ? '#fff' : '#5C4E73'}}>
                                Свернуть
                            </h2>
                        </div>
                    </Col>
                }
            </Row>


            <Row
                justify={"space-between"}
                style={{backgroundColor: isFinance ? '#5C4E73' : isInvestor ? '#fff' : colorBgContainer}}
                className="header"
            >
                <Col span={6}>
                    <div className="company-title-container">
                        {" "}
                        {
                            isFinance
                                ?
                                <img src={logoWhite}></img>
                                :
                                <img src={logo}></img>
                        }
                        {/*<div*/}
                        {/*    className="company-title"*/}
                        {/*    style={{color: isFinance ? "#D9D9D9" : colorPrimary}}*/}
                        {/*>*/}
                        {/*    MA Decigi*/}
                        {/*</div>*/}
                    </div>
                    <div className="header-burger" onClick={() => setIsShowBurger(!isShowBurger)}>
                        <img src={burger} alt=""/>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="header-actions-container">
                        {" "}
                        <Badge count={4} size={"small"} className="header-not">
                            <BellOutlined size={20}/>
                        </Badge>
                        <div style={{margin: "0 14px 0 27px"}}>
                            <div style={{color: isFinance ? "#F8F8F8" : "#4B4B4B"}}>
                                {`${user?.firstName ? user?.firstName : '---'} ${user?.lastName ? user?.lastName : '---'} `}
                            </div>
                            <div style={{color: isFinance ? "#82868B" : "#82868B", fontSize: "12px"}}>
                                {`${user?.role?.name ? user?.role?.name : '---'}`}
                            </div>
                        </div>
                        <Dropdown
                            placement="bottomRight"
                            trigger={["click"]}
                            menu={{items: items}}
                        >
                            <Badge color={"#28C76F"} dot offset={[-5, 33]}>
                                <Avatar
                                    src={user?.photo ? `${process.env.REACT_APP_API_URL}storage/${user?.photo}` : avatarImage}
                                    size={38}
                                    style={{cursor: "pointer"}}
                                />
                            </Badge>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </>

    );
}
