import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const usePostMessageToChat = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: addNewMessageToChat,
        ...options
    } = useMutation(backend.chat.postMessageToChat, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`CHAT_MESSAGES_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any) => {
            try {
                let body = { ...values };
                await addNewMessageToChat(body);
                queryClient.invalidateQueries(['CHAT_MESSAGES_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [addNewMessageToChat]
    );

    return {
        handleCreate,
        errorMessage,
        ...options,
    };
};
