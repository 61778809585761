import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function useExpensesById() {
    const [id, setId] = useState<string>('');

    const { data: expensesById, ...options } = useQuery(
        ["EXPENSES_DATA_BY_ID", id],
        () =>
            backend.operatingExpenses.getExpensesDataById(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        expensesById,
        setId,
        ...options,
    };
}
