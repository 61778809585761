import ModalHeader from "features/shared/ModalHeader";
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useRef,
    useState
} from "react";
import {AutoComplete, Button, Checkbox, Col, Dropdown, Input, MenuProps, message, Spin} from "antd";
import bucket from '../../../assets/images/bucket.svg'
import useProductsDataById from "../hooks/useProductDataById";
import useAnalyticsUserList from "../../analyticsUser/hooks/useAnalyticsUserList";
import {LoadingOutlined} from "@ant-design/icons";
import {useProductPostAnalytics} from "../hooks/useProductPostAnalytics";
import {useProductDeleteAnalytics} from "../hooks/useProductDeleteAnalytics";
import { Select } from "antd";

const { Option } = Select;

export default function EditProductAnalytics({onClose, productId, productModal}: any) {

    const [selectedAnalyticUser, setSelectedAnalyticUser] = useState<Array<string>>([]);

    const {
        isLoading,
        product,
        setId,
    } = useProductsDataById()

    console.log(product)

    const {
        analyticUser,
        searchValue,
        setSearchValue,
    } = useAnalyticsUserList();

    const {
        isLoading: deleteLoading,
        handleDelete: handleDelete,
        isError: isErrorDelete,
        errorMessage: deleteMessage,
        isSuccess: successDelete,
    } = useProductDeleteAnalytics()

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
        setId: setIdForPost
    } = useProductPostAnalytics();

    useEffect(() =>{
        setId(productId)
        setIdForPost(productId)
        setSelectedAnalyticUser([])
    },[productId])

    useEffect(() => {
        if (isErrorDelete){
            message.error('Произошла ошибка при попытке удалить аналитика')
        }
    }, [isErrorDelete])

    useEffect(() => {
        if (isErrorCreate){
            message.error('Произошла ошибка при попытке добавить аналитиков')
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isSuccessCreate){
            setSelectedAnalyticUser([])
            setSearchValue('')
        }
    }, [isSuccessCreate])

    const getAnalyticsUsersFilterItems = () => {
        if (!analyticUser?.data) {
            return [];
        }
        const items: MenuProps["items"] = [

        ];
        analyticUser?.data?.forEach((item: any) => {
            items.push({
                label: (
                    <div
                        style={{ display: "flex", gap: "10px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            style={{width: '100%'}}
                            value={item.user.firstName}
                            checked={selectedAnalyticUser.includes(item.id.toString())}
                            onChange={(e) => {
                                let newSelectedAnalyticsUser = [...selectedAnalyticUser];
                                if (e.target.checked) {
                                    newSelectedAnalyticsUser.push(item.id.toString());
                                } else {
                                    newSelectedAnalyticsUser = newSelectedAnalyticsUser.filter(
                                        (el) => el !== item.id.toString()
                                    );
                                }
                                setSelectedAnalyticUser(newSelectedAnalyticsUser);
                            }}
                        >
                            {item.user.firstName} {item.user.lastName}
                        </Checkbox>
                    </div>
                ),
                key: `analyticUser-${item.id}`,
            });
        });
        return items;
    };

    const handleSearch = (value: any) => {
        setSearchValue(value);
        console.log("Selected value:", value);
    };
    const handleSelect = (value: any) => {
        setSearchValue(value);
        console.log("Selected value:", value);
        // Добавьте здесь свой код для обработки выбранного значения
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Редактировать закрепленного аналитика"}
                onClose={onClose}
            />

            <div className={"send-article-wrapper"}>
                <div className="edit-product-wrap">

                    {isLoading
                        ?
                        <div
                            style={{
                                padding: "30px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        product?.analyticsBounded?.length <= 0
                            ? <div className="edit-product-item-analytic-empty">
                                На данный момент продукт не имеет аналитиков
                            </div>
                            :
                            product?.analyticsBounded?.map((item: any) =>
                                <div className="edit-product-item-analytic">
                                    {item?.lastName + " " + item?.firstName}
                                    <img
                                        style={{
                                            pointerEvents: deleteLoading ? 'none' : 'auto',
                                            opacity: deleteLoading ? 0.5 : 1
                                        }}
                                        onClick={() => handleDelete(item?.id, productId)}
                                        src={bucket}
                                        alt=""
                                        className='edit-product-item-analytic-bucket'
                                    />
                                </div>
                            )
                    }
                </div>
                <div className="products-edit">
                    Добавить нового аналитика
                </div>

                <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Введите ключевое слово"
                    value={searchValue}
                    onSearch={(e: any) => handleSearch(e)}
                    onSelect={handleSelect}
                    filterOption={false}
                >
                    {/* Здесь добавьте нужные варианты выбора */}
                    {analyticUser?.data?.map((item: any) => (
                        <Option key={item.id} value={item.id.toString()}>
                            {`${item.user.lastName} ${item.user.firstName}`}
                        </Option>
                    ))}
                </Select>

                {/*<Dropdown*/}
                {/*    trigger={["click"]}*/}
                {/*    placement={"bottomRight"}*/}
                {/*    menu={{items: getAnalyticsUsersFilterItems()}}*/}
                {/*    overlayClassName="dropdown-border"*/}
                {/*>*/}
                {/*    <div*/}
                {/*        className="products-header-dropdown"*/}
                {/*        style={{*/}
                {/*            borderWidth: 1,*/}
                {/*            borderColor: '#000'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <div>Выберите имя из списка</div>*/}
                {/*        <Icon*/}
                {/*            component={SelectIconComponent}*/}
                {/*            style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Dropdown>*/}

                <div className="products-edit-buttons-wrap">
                    <Button
                        loading={createLoading}
                        disabled={!searchValue}
                        className="products-edit-button-save"
                        type={"primary"}
                        style={{width: "222px", fontSize: "12px"}}
                        onClick={() => handleCreate({
                            productId: productId,
                            analyticIds: [searchValue]
                        })}
                    >
                        Сохранить
                    </Button>
                    <Button
                        className="products-edit-button-exit"
                        type={"primary"}
                        style={{width: "222px", fontSize: "12px"}}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
}
