import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import useArticleList from "./useArticleList";
import useSizesList from "./useSizesList";
import useSellerList from "./useSellerList";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import Icon from "@ant-design/icons";

export default function useTopologyData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [container, setContainer] = useState<any>('location');
    const [containerId, setContainerId] = useState<any>('');
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchText, 1000);

    const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);
    const [selectedArticle, setSelectedArticle] = useState<Array<string>>([]);
    const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);

    const {
        articles,
        search: searchArticle,
        setSearch: setSearchArticle,
    } = useArticleList()

    const {
        sizes,
        search: searchSizes,
        setSearch: setSearchSizes,
    } = useSizesList()

    const {
        sellers,
        search: searchSeller,
        setSearch: setSearchSeller,
    } = useSellerList()


    const getArticleFilterItems = () => {
        if (!articles) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        articles?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedArticle.some((productItem: any) => productItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticle.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedArticle];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedArticle(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `article-${item}`,
                });
            }
        });

        selectedArticle?.forEach((item: any) => {
            const isDuplicate = selectedArticle.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticle.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedArticle];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedArticle(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `article-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchArticle}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchArticle(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "article-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getSellerFilterItems = () => {
        if (!sellers) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sellers?.data?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.extRef}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id)}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id !== item?.id);
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item?.companyName}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item?.extRef}`,
                });
            }
        });

        selectedSeller?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.extRef}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id)}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id !== item?.id);
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item?.companyName}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item?.extRef}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSeller}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSeller(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "seller-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getSizesFilterItems = () => {
        if (!sizes) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sizes?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((sizesItem: any) => sizesItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedSizes.some((sizesItem: any) => sizesItem === item)}
                                onChange={(e) => {
                                    let newSelectedSizes = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedSizes.push(item);
                                    } else {
                                        newSelectedSizes = newSelectedSizes.filter((el: any) => el!== item);
                                    }
                                    setSelectedSizes(newSelectedSizes);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item}`,
                });
            }
        });

        selectedSizes?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((sizesItem: any) => sizesItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedSizes.some((sizesItem: any) => sizesItem === item)}
                                onChange={(e) => {
                                    let newSelectedSizes = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedSizes.push(item);
                                    } else {
                                        newSelectedSizes = newSelectedSizes.filter((el: any) => el !== item);
                                    }
                                    setSelectedSizes(newSelectedSizes);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSizes}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSizes(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "sizes-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };


    const {data: topologyData, ...options} = useQuery(
        [
            "MARKING_DATA",
            currentPage,
            currentPageSize,
            debouncedSearch,
            container,
            containerId,
            selectedSeller,
            selectedArticle,
            selectedSizes,
        ],
        () =>
            backend.storage.getTopologyData(
                currentPage.toString(),
                currentPageSize,
                debouncedSearch,
                container,
                containerId,
                selectedSeller,
                selectedArticle,
                selectedSizes,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        topologyData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        container,
        setContainer,
        containerId,
        setContainerId,
        getArticleFilterItems,
        getSizesFilterItems,
        getSellerFilterItems,
        ...options,
    };
}
