import {useMutation, useQuery, useQueryClient} from "react-query";
import {backend} from "api";
import {useCallback, useState} from "react";

export default function useProductDataByGTD() {

    const [id, setId] = useState<any>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");

    const { data: productsData, ...options } = useQuery(
        [
            "PRODUCT_DATA_BY_GTD",
            id,
            currentPage,
            currentPageSize
        ],
        () =>
            backend.storage.getProductByGTD(
                id.toString(),
                currentPage.toString(),
                currentPageSize
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        productsData,
        setId,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
