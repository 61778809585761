import { useState } from "react";
import {Checkbox, Col, Row, Table} from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as centimeterIcon } from "assets/images/centimeter-icon.svg";
import { ReactComponent as arrowUp } from "assets/images/blue-arrow-up.svg";
import { ReactComponent as arrowDown } from "assets/images/blue-arrow-down.svg";
import bannerImage from "assets/images/banner-image-big.png";


export default function ShipmentsFormationTableRow({
                                                       item,
                                                       onChangeStatus,
                                                       isLoadingCheckBox
}: any) {

    const [showSize, setShowSize] = useState<boolean>(false);

    const {
        photo,
        article,
        article_china,
        seller,
        seller_article,
        sizes,
        name,
        discount,
        retail_price,
        price,
    } = item;


    const ownStocksSum = sizes?.reduce((accumulator: number, currentItem: any) => {
        return accumulator + currentItem?.own_stocks;
    }, 0);

    const wbStocksSum = sizes?.reduce((accumulator: number, currentItem: any) => {
        return accumulator + currentItem?.wb_stocks;
    }, 0);

    const ordersSpeedSum = sizes?.reduce((accumulator: number, currentItem: any) => {
        return accumulator + currentItem?.orders_speed;
    }, 0);

    const salesSpeedSum = sizes?.reduce((accumulator: number, currentItem: any) => {
        return accumulator + currentItem?.sales_speed;
    }, 0);

    const needStocksSum = sizes?.reduce((accumulator: number, currentItem: any) => {
        return accumulator + currentItem?.need_stocks;
    }, 0);

    // console.log(totalDaysRest)

    const columns = [
        // {
        //     title: "Разм букв",
        //     dataIndex: "sizeLetter",
        //     key: "sizeLetter",
        //     width: "6%",
        // },
        {
            title: "Разм числ",
            dataIndex: "size",
            key: "size",
            width: "6%",
        },
        {
            title: "На собств. складе",
            dataIndex: "own_stocks",
            key: "own_stocks",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "На складе WB",
            dataIndex: "wb_stocks",
            key: "wb_stocks",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "Скорость заказов",
            dataIndex: "orders_speed",
            key: "orders_speed",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "Скорость продаж",
            dataIndex: "sales_speed",
            key: "sales_speed",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "Хватит на",
            dataIndex: "days_rest",
            key: "days_rest",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "Необход по складам",
            dataIndex: "need_stocks",
            key: "need_stocks",
            width: "10%",
            render: (text: any, record: any) => (
                text?.toFixed(2)
            ),
        },
        {
            title: "К поставке",
            dataIndex: "need_to_ship",
            key: "need_to_ship",
            width: "10%",
            render: (text: any, record: any) => (
                <span
                    style={{
                        fontWeight: 600,

                        color: "#4C78EE",
                    }}
                >
                {text?.toFixed(2)}
            </span>
            ),
        },
        {
            title: "В работе",
            dataIndex: "in_work",
            key: "in_work",
            width: "10%",
            render: (text: any, record: any) =>{
                return(
                    <Checkbox
                        disabled={isLoadingCheckBox}
                        onChange={(e) => onChangeStatus(!text, record?.id)}
                        checked={text}
                    />
                )
            }
        },
    ];

    return (
        <Row>
            <Col style={{ width: "20%" }}>
                <div className={"shipments-formation-row-header"}>Товар</div>
                <div
                    className={"shipments-table-product-col"}
                    style={{ paddingBottom: 0 }}
                >
                    <img
                        style={{ width: "45px", height: "64px" }}
                        src={photo}
                        alt={""}
                    ></img>
                    <div>
                        <div className={"shipments-table-highlighted"}>
                            {name}
                        </div>
                        <div className={"shipments-table-text"}>
                            Артикул WB: {article}
                        </div>
                        <div className={"shipments-table-text"}>
                            Артикул пост: {seller_article}
                        </div>
                        <div className={"shipments-table-text"}>
                            Артикул китай: {article_china}
                        </div>
                        {/*<div className={"shipments-table-text"}>*/}
                        {/*    Штрихкод: ---*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Col>
            <Col style={{ width: "80%" }}>
                <Row gutter={1}>
                    <Col style={{ width: "14%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Цена, руб
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {price}
                            </div>
                            <div className={"shipments-table-text"}>
                                Рознич. цена: {retail_price}
                            </div>
                            <div className={"shipments-table-text"}>
                                Скидка: {discount}
                            </div>
                        </div>
                    </Col>
                    <Col style={{ width: "13%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Остаток на собс., складе
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {ownStocksSum?.toFixed(2)}
                            </div>
                            {/*<div className={"shipments-table-text"}>*/}
                            {/*    ---*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col style={{ width: "13%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Остаток на складе WB
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {wbStocksSum?.toFixed(2)}
                            </div>
                            {/*<div className={"shipments-table-text"}>*/}
                            {/*    ---*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col style={{ width: "15%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Скорость заказа за 5 дней
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {salesSpeedSum?.toFixed(2)}
                            </div>
                            {/*<div className={"shipments-table-text"}>*/}
                            {/*    ---*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col style={{ width: "12%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Скор продаж за 5 дней
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {ordersSpeedSum?.toFixed(2)}
                            </div>
                            {/*<div className={"shipments-table-text"}>*/}
                            {/*    ---*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col style={{ width: "13%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Необход на 25 дн по заказу
                        </div>
                        <div className={"shipments-table-col"}>
                            <div className={"shipments-table-highlighted"}>
                                {needStocksSum?.toFixed(2)}
                            </div>
                        </div>
                    </Col>
                    {/*<Col style={{ width: "11%" }}>*/}
                    {/*    <div className={"shipments-formation-row-header"}>*/}
                    {/*        Динамика продаж*/}
                    {/*    </div>*/}
                    {/*    <div className={"shipments-table-col"}>*/}
                    {/*        <div className={"shipments-table-highlighted"}>*/}
                    {/*            ---*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col style={{ width: "20%" }}>
                        <div className={"shipments-formation-row-header"}>
                            Юр.лицо
                        </div>
                        <div className={"shipments-table-col"}>
                            {seller}
                        </div>
                    </Col>
                    {/*<Col style={{ width: "7%" }}>*/}
                    {/*    {" "}*/}
                    {/*    <div className={"shipments-formation-row-header"}>*/}
                    {/*        Уведом.*/}
                    {/*    </div>*/}
                    {/*    <div className={"shipments-table-col"}>---</div>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <Col span={24} className={"shipments-size-row"}>
                        <Row justify={"space-between"}>
                            <Col>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    <Icon
                                        component={centimeterIcon}
                                        style={{ fontSize: "22px" }}
                                    />
                                    Размерный ряд
                                </div>
                            </Col>
                            <Col>
                                <div
                                    onClick={() => setShowSize(!showSize)}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "4px",
                                        cursor: "pointer",
                                    }}
                                >
                                    Свернуть
                                    <Icon
                                        component={
                                            showSize ? arrowUp : arrowDown
                                        }
                                        style={{ fontSize: "17px" }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <div
                        className={"collapse"}
                        style={{
                            height: showSize ? "100%" : 0,
                            width: "100%",
                        }}
                    >
                        <Table
                            className="product-arrival-table"
                            columns={columns}
                            dataSource={sizes}
                            pagination={false}
                        />
                    </div>
                </Row>
            </Col>
        </Row>
    );
}
