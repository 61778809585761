import React from 'react';
import emptyMessages from '../../../assets/images/emptyMessages.png'

const EmptyMessages = ({selectedChat}: any) => {

    return (
        <div className="message-empty">
            <img src={emptyMessages} alt=""/>
            <h2 className="message-empty-text">
                Сообщений пока нет
            </h2>
            {
                !selectedChat &&
                <h2 className="message-empty-text">
                    Пожалуйста выберите чат
                </h2>
            }
        </div>
    );
};

export default EmptyMessages;