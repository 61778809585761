import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSellerList() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch] = useDebounce(search, 1000);

    const {data: sellers, ...options} = useQuery(
        [
            "SELLER_STORAGE_DATA",
            currentPage,
            currentPageSize,
            debouncedSearch
        ],
        () =>
            backend.storage.getSellerList(
                currentPage.toString(),
                currentPageSize,
                debouncedSearch
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        sellers,
        currentPage,
        currentPageSize,
        setCurrentPage,
        setCurrentPageSize,
        search,
        setSearch,
        ...options,
    };
}

