import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSizesByGTDAndArticle() {

    const [idGTD, setIdGTD] = useState(null)
    const [article, setArticle] = useState(null)

    const {data: sizesDataBuGTDAndArticle, ...options} = useQuery(
        [
            "SIZES_DATA_BY_GTD_ID_AND_ARTICLE",
            idGTD,
            article
        ],
        () =>
            backend.storage.getSizesByGTDIdAndArticle(
                idGTD,
                article
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        sizesDataBuGTDAndArticle,
        idGTD,
        article,
        setArticle,
        setIdGTD,
        ...options,
    };
}
