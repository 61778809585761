import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useDeclarationAdd} from "../../hooks/declaratiion/useDeclarationAdd";
import {useSaveFile} from "../../hooks/acceptance/useSaveFile";


export default function SendToCheck({onClose, typeToSend}: any) {

    const [form] = Form.useForm();

    const {
        isSuccessCreate,
        handleSave,
        createLoading,
        isErrorCreate,
        errorMessage,

    } = useSaveFile()

    const onSend = (value: any) =>{

        if (typeToSend === 'save'){
            handleSave(value)
        } else{
            onClose()
        }
    }

    useEffect(() =>{
        if (isErrorCreate){
            message.error(errorMessage)
        }
    },[isErrorCreate])

    useEffect(() =>{
        if (isSuccessCreate){
            message.success('операция выполнена успешно')
            onClose()
            form.resetFields()
        }
    },[isSuccessCreate])


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={typeToSend === 'save' ? 'Сохранение файла отгрузки' : 'Отправка на ТСД'} onClose={onClose}/>
            <>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onSend}
                >
                    <Form.Item
                        label={
                            <div>
                                <span>Название файла</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="filename"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название файла",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "100%"}}
                        htmlType={"submit"}
                        loading={createLoading}
                    >
                        Сохранить
                    </Button>
                </Form>

            </>

        </div>
    );
}
