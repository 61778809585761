import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {useDeclarationAdd} from "../../hooks/declaratiion/useDeclarationAdd";
import trashBucket from "../../../../assets/images/trash-bucket.svg";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useUploadEmission} from "../../hooks/emission/useUploadEmission";
import useExtRef from "../../hooks/useExtRef";

const {Option} = Select;

export default function AddEmissionModal({onClose}: any) {

    const {
        sellers,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
    } = useExtRef();

    const {
        handleCreate,
        uploadData,
        errorMessage,
        isLoading,
        isSuccess,
        isError
    } = useUploadEmission()

    const [selectedSeller, setSelectedSeller] = useState<any>();

    const [form] = Form.useForm();
    const fileInputRef = useRef<any>(null);
    const [file, setFile] = useState<any>('')

    const onFinish = (values: any) => {

        const formData = new FormData();
        formData.append('file', file);

        if (!file){
            message.error('Выберите, пожалуйста, файл')
        } else{
            message.warning('Файл начал загрузку. Не закрывайте страницу')
            handleCreate({
                values: formData,
                selectedSeller
            })
        }

    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    useEffect(() =>{
        if (errorMessage){
            message.error(errorMessage)
        }
        if (isSuccess){
            message.success('Вы успешно загрузили эмиссию')
            form.resetFields()
            setFile('')
            setSelectedSeller('')
        }
    },[isError, isSuccess])


    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Загрузка эмисии"} onClose={() =>{
                form.resetFields()
                setFile('')
                setSelectedSeller('')
                onClose()
            }}/>
            <>
                <div  style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20, width: '100%'}}>
                    {file?.name
                        ?
                        <>
                            <div style={{
                                fontSize: 16,
                                color: '#4B4B4B'
                            }}>
                                {file?.name}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    fontSize: 14,
                                    color: '#EA5455',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setFile('')}
                            >
                                <img src={trashBucket} alt="" style={{marginTop: -2}}/>
                                Удалить
                            </div>
                        </>
                        :
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            Выберите файл
                        </div>
                    }
                </div>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={
                            <div>
                                <span>Выберите юр.лицо, на которое будет загружен файл</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="sellerId"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            value={selectedSeller}
                            placeholder="Юр. лицо"
                            filterOption={false}
                            suffixIcon={<Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />}
                            onSearch={(value: any) => setSearch(value)}
                            onChange={(e: any, option: any) => {
                                setSelectedSeller(e)
                            }}
                        >
                            {sellers?.data?.map((option: any) => {
                                return (
                                    <Option key={option?.wbArticle} value={option?.id}>
                                        {option.companyName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Col style={{display: 'flex', gap: 10}}>
                        <Button
                            className={"button"}
                            type="dashed"
                            style={{width: "100%"}}
                            onClick={handleFileSelect}
                        >
                            Добавить файл
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                        <Button
                            loading={isLoading}
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                        >
                            Загрузить
                        </Button>
                    </Col>

                </Form>
            </>
        </div>
    );
}
