import React from 'react';
import {PDFViewer, Document, Page, Text, View, Image, StyleSheet, Font} from '@react-pdf/renderer';
import bwipjs from "bwip-js";

// Register Font
Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 12,
        padding: 40,
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    cell: {
        width: '100%',
        maxWidth: '100%',
        padding: 5,
        border: '1px dotted black',
    },
    headerCell: {
        textAlign: 'center',
    },
    image: {
        padding: 10,
        width: '200px',
        height: '200px',
    },
});


export const MyPdfComponent = (data: any) => {

    const generetImg = (text: any) => {

        text = text.replace(/\\x1d/g, String.fromCharCode(29));

        text = String.fromCharCode(29) + text;

        let canvas = document.createElement("canvas");
        bwipjs.toCanvas(canvas, {
            bcid: "datamatrix", // Barcode type
            text: text, // Text to encode
            scale: 3, // 3x scaling factor
            height: 10, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center" // Always good to set this
        });
        return canvas?.toDataURL("image/png")
    }

    return (
        <Document>
            {data?.data?.map((record: any, index: any) => (
                <Page style={styles.page} key={index}>

                    <View style={styles.table}>
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Страна
                                </Text>
                                <Text style={styles.cell}>
                                    {record?.country || '---'}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Артикул: {record?.articleChina || '---'}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.table}>
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Размер
                                </Text>
                                <Text style={styles.cell}>
                                    {record?.size?.ruSize || '---'}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Код упаковки
                                </Text>
                                <Text style={styles.cell}>
                                    {record?.boxCode || '---'}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Испортер
                                </Text>
                                <Text style={styles.cell}>
                                    {record?.importerName || '---'}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Производитель
                                </Text>
                                <Text style={styles.cell}>
                                    {record?.productionName || '---'}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell} wrap break>
                                    {record?.description || '---'}
                                </Text>
                            </View>
                        </View>
                        <View style={[styles.column, styles.cell]}>
                            <View
                                style={[styles.row, {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }]}>
                                {record?.kizCodeLong ?
                                    <Image style={styles?.image}
                                           src={generetImg(record.kizCodeLong)}/>
                                    : '---'
                                }
                            </View>
                        </View>
                    </View>
                    <View style={[styles.row, {textAlign: 'center'}]}>
                        <Text style={styles.cell}>
                            {record?.kizCodeShort || '---'}
                        </Text>
                    </View>
                </Page>
            ))}

        </Document>
    )
};