import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Input,
    InputNumber, InputRef,
    Row,
    Select, Space,
    Switch,
} from "antd";
import {
 getAnalyticsProductSizesByProductId,
} from "api/analytics";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useRef, useState} from "react";
import {useDebounce} from "use-debounce";
import {useAnalyticsCashbackCreate} from "../hooks/useAnalyticsCashbackCreate";
import {useAnalyticsCashbackUpdate} from "../hooks/useAnalyticsCashbackUpdate";
import dayjs from "dayjs";
import {getKeywordsByProductArticleV2, getProductsDataById} from "api/product";
import ReactQuill from "react-quill";
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useArticleWbData from "../../financial-results/hooks/useArticleWbData";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";

dayjs.extend(utc);
dayjs.extend(timezone);

// Установка часового пояса "ru"
dayjs.tz.setDefault('Europe/Moscow');

// Использование локали "ru"
dayjs.locale('ru');

const {TextArea} = Input;
const {Option} = Select;

interface EditCashbackModalProps {
    cashback: Cashback;
    onClose: any;
}

export default function EditCashbackModalContent({
                                                     cashback,
                                                     onClose,
                                                 }: EditCashbackModalProps) {

    const {
        articleWb,
        setSellerId,
        search,
        setSearch,
    } = useArticleWbData()

    const [selectedArticle, setSelectedArticle] = useState<any>(null)
    const [selectedProduct, setSelectedProduct] = useState<any>(null)
    const [debouncedArticleSelectedProduct] = useDebounce(selectedProduct, 1000);

    const [publishDate, setPublishDate] = useState<any>('');

    const [cashbackSwitchChecked, setCashbackSwitchChecked] = useState<boolean>(false);
    const [chooseSizeSwich, setChooseSizeSwich] = useState<boolean>(false);

    const [form] = Form.useForm<{}>();

    const inputRef = useRef<InputRef>(null);
    const [newKeyword, setNewKeyword] = useState("");

    const articleValue = Form.useWatch("article", form);

    const [debouncedArticleValue] = useDebounce(articleValue, 1000);

    const [product, setProduct] = useState<Product>();
    const [isExistProductSizes, setIsExistProductSizes] = useState<Boolean>(true);
    const [productSizes, setProductSizes] = useState<any[]>();
    const [productSizesFilter, setProductSizesFilter] = useState<any[]>();

    const [productSizesSelected, setSelectedProductSizes] = useState();

    const [keywordsToSelect, setKeywordsToSelect] = useState<any[]>();

    const {handleCreate, isLoading: isCreateLoading} =
        useAnalyticsCashbackCreate();
    const {handleUpdate, isLoading: isUpdateLoading} =
        useAnalyticsCashbackUpdate();

    const onNewKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewKeyword(event.target.value);
    };

    const addItem = (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        e.preventDefault();
        setKeywordsToSelect([
            ...(keywordsToSelect as any[]),
            {label: newKeyword, value: newKeyword},
        ]);

        setNewKeyword("");

        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        // setSelectedProductSizes
        form.setFieldValue("name", cashback.name);
        form.setFieldValue("article", cashback.product.wbArticle);
        form.setFieldValue("cashbackTypeBoolean", chooseSizeSwich);
        form.setFieldValue("daysAction", cashback.daysAction);
        form.setFieldValue("cashbackType", "percentage");
        form.setFieldValue("cashbackValue", cashback.cashbackSum || cashback.cashbackPercentage);
        setCashbackSwitchChecked(cashback.cashbackSum ? true : false)
        form.setFieldValue("publishText", cashback.publishText);
        form.setFieldValue("actionText", cashback.actionText);
        form.setFieldValue("publishDate", dayjs(cashback.publishDate));
        form.setFieldValue("productId", cashback?.product?.id || -1);
        form.setFieldValue("wasPositionAt", 0);
        form.setFieldValue("wasPageAt", 0);
        form.setFieldValue("keyword", cashback.keyword.title);
        form.setFieldValue("sizes", cashback?.cashbackItems?.map(item => item?.size));

        form.setFieldValue("condition1", cashback.condition1);
        form.setFieldValue("condition2", cashback.condition2);
        form.setFieldValue("condition3", cashback.condition3);
        form.setFieldValue("condition4", cashback.condition4);
        form.setFieldValue("condition5", cashback.condition5);

        setPublishDate(dayjs(cashback.publishDate))

        if (cashback.maxItemsGiven) {
            setChooseSizeSwich(false)
            form.setFieldValue("maxItemsGiven", cashback.maxItemsGiven);

            let sizes: any = [];
            cashback.cashbackItems.forEach((item, index) => {
                sizes.push(item.size);
                form.setFieldValue(`${item.size}-${index}`, item.count);
            });
            setSelectedProductSizes(sizes);
        } else {
            setChooseSizeSwich(true)
            let sizes: any = [];
            cashback.cashbackItems.forEach((item, index) => {
                sizes.push(item.size);
                form.setFieldValue(`${item.size}-${index}`, item.count);
            });
            setSelectedProductSizes(sizes);
        }

        getProductsDataById(String(cashback?.product?.id)).then((data) => {
            console.log(
                "🚀 ~ file: AddCashbackModalContent.tsx:33 ~ getAnalyticsProductByArticle ~ data:",
                data
            );

            setProduct(data);
        }).catch(() => console.log('error'));

        getAnalyticsProductSizesByProductId(String(cashback?.product?.id)).then((data: any) => {
            var filter = data.map((t: any) => ({
                label: t,
                value: t
                // amount: t.amount
            }));

            setProductSizes(filter);
            setProductSizesFilter(filter);
            setIsExistProductSizes(true)
        }).catch((e) => {
            console.log(e?.response?.data?.message)
            if (e?.response?.data?.message === 'Product not found'){
                setIsExistProductSizes(false)
            }
        });

    }, [cashback]);

    useEffect(() => {
        if (!debouncedArticleValue) {
            return;
        }

        if (selectedProduct){
            getProductsDataById(debouncedArticleSelectedProduct).then((data) => {
                console.log(
                    "🚀 ~ file: AddCashbackModalContent.tsx:33 ~ getAnalyticsProductByArticle ~ data:",
                    data
                );

                setProduct(data);
            }).catch(() => console.log('error'));
            getAnalyticsProductSizesByProductId(debouncedArticleSelectedProduct).then((data: any) => {
                var filter = data.map((t: any) => ({
                    label: t,
                    value: t
                    // amount: t.amount
                }));

                setProductSizes(filter);
                setProductSizesFilter(filter);
                setIsExistProductSizes(true)
            }).catch((e) => {
                console.log(e?.response?.data?.message)
                if (e?.response?.data?.message === 'Product not found'){
                    setIsExistProductSizes(false)
                }
            });
        }

        getKeywordsByProductArticleV2(debouncedArticleValue).then((data) => {
            let keywords = data.map((elem: any) => ({
                label: elem.title,
                value: elem.title,
            }));
            setKeywordsToSelect(keywords);
        });

        // getAnalyticsProductSizesByArticle(debouncedArticleValue).then((data) => {
        //     var filter = (data as any).map((t: any) => ({
        //         label: t.size,
        //         value: t.size,
        //         amount: t.amount
        //     }));
        //
        //     const all = {
        //         label: 'Все товары',
        //         value: 'all',
        //         amount: filter.reduce((min: any, obj: any) => {
        //             const amount = obj.amount;
        //             return amount < min ? amount : min;
        //         }, Infinity)
        //     }
        //
        //     setProductSizes([all, ...filter]);
        //     setProductSizesFilter([all, ...filter]);
        //     setIsExistProductSizes(true)
        // }).catch((e) => {
        //     console.log(e?.response?.data?.message)
        //     if (e?.response?.data?.message === 'Product not found'){
        //         setIsExistProductSizes(false)
        //     }
        // });



    }, [debouncedArticleValue, debouncedArticleSelectedProduct]);

    useEffect(() => {
        if (articleValue) {
            getKeywordsByProductArticleV2(articleValue).then((data) => {
                let keywords = data.map((elem: any) => ({
                    label: elem.title,
                    value: elem.title,
                }));
                setKeywordsToSelect(keywords);
            });
        }

    }, [])

    const onCreate = (values: any, onClose: any) => {
        let updatedValues = {...values};
        // console.log(
        //     "🚀 ~ file: AddCashbackModalContent.tsx:69 ~ onCreate ~ updatedValues:",
        //     updatedValues
        // );

        var body: any = {};

        body["name"] = updatedValues.name;
        body["daysAction"] = updatedValues.daysAction;

        body["cashbackType"] = cashbackSwitchChecked
            ? "fixed"
            : "percentage";
        body["cashbackValue"] = updatedValues.cashbackValue;

        body["publishText"] = updatedValues.publishText;
        body["actionText"] = updatedValues.actionText;

        body["publishDate"] = dayjs(publishDate).format('YYYY-MM-DD HH:mm');

        body["productId"] = cashback?.product?.id || 0;

        body["wasPositionAt"] = 0;
        body["wasPageAt"] = 0;

        body["keyword"] = values.keyword;

        body["condition1"] = updatedValues.condition1;
        body["condition2"] = updatedValues.condition2;
        body["condition3"] = updatedValues.condition3;
        body["condition4"] = updatedValues.condition4;
        body["condition5"] = updatedValues.condition5;

        // console.log(
        //     "🚀 ~ file: AddCashbackModalContent.tsx:104 ~ body[]=updatedValues.keys.map ~ updatedValues:",
        //     Object.keys(updatedValues)
        // );

        console.log('updatedValues')
        console.log(updatedValues)

        if (chooseSizeSwich) {
            body["items"] = (productSizesSelected ?? []).map((item, index) => {
                console.log('item')
                console.log(item)
                return {
                    size: item,
                    count: updatedValues[`${item}-${index}`],
                };
            });
        } else {
            body["maxItemsGiven"] = values.maxItemsGiven
            body["items"] = [];
        }

        console.log("create", body);

        handleCreate(body, onClose);
    };

    const onUpdate = (values: any, onClose: any) => {
        let updatedValues = {...values};

        var body: any = {};

        body["name"] = updatedValues.name;
        body["daysAction"] = updatedValues.daysAction;

        body["cashbackType"] = cashbackSwitchChecked
            ? "fixed"
            : "percentage";
        body["cashbackValue"] = updatedValues.cashbackValue;

        body["publishText"] = updatedValues.publishText;
        body["actionText"] = updatedValues.actionText;

        body["publishDate"] = dayjs(publishDate).format('YYYY-MM-DD HH:mm');

        body["productId"] = product?.id ?? -1;

        body["wasPositionAt"] = 0;
        body["wasPageAt"] = 0;

        body["keyword"] = values.keyword;

        body["condition1"] = updatedValues.condition1;
        body["condition2"] = updatedValues.condition2;
        body["condition3"] = updatedValues.condition3;
        body["condition4"] = updatedValues.condition4;
        body["condition5"] = updatedValues.condition5;

        if (chooseSizeSwich) {
            body["items"] = (productSizesSelected ?? []).map((item, index) => {
                return {
                    size: item,
                    count: updatedValues[`${item}-${index}`],
                };
            });
        } else {
            body["maxItemsGiven"] = values.maxItemsGiven
            body["items"] = [];
        }

        console.log("update", body);
        console.log(cashbackSwitchChecked);

        handleUpdate(cashback.id, body, onClose);
    };

    function removeHtmlCss(inputString: any) {
        var cleanString = inputString.replace(/<.*?>/g, '');
        return cleanString;
    }

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактировать акцию"} onClose={onClose}/>
            <Form
                onFinish={(values) => onUpdate(values, onClose)}
                form={form}
                layout={"vertical"}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name={"name"} label={"Введите имя акции"}>
                            <Input style={{fontWeight: 600}}/>
                        </Form.Item>
                        <Form.Item
                            name={"article"}
                            label={"Введите номенклатуру товара, передаваемого в акцию"}
                        >
                            {/*<Input/>*/}
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                value={selectedArticle}
                                placeholder="Артикул"
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                onSearch={(value: any) => setSearch(value)}
                                onChange={(e: any, option: any) => {
                                    setSelectedArticle(e)
                                    setSelectedProduct(option?.key)
                                }}
                            >
                                {articleWb?.slice(0,10)?.map((option: any) => {
                                    return (
                                        <Option key={option?.id} value={option?.nmId}>
                                            {option.nmId}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    name="sizes"*/}
                        {/*    rules={[{ required: true }]}*/}
                        {/*    label={"Выберите размеры, которые будут участвовать в акции"}*/}
                        {/*>*/}
                        {/*    <Select*/}
                        {/*        onChange={(values) => {*/}
                        {/*            setSelectedProductSizes(values);*/}
                        {/*        }}*/}
                        {/*        mode="multiple"*/}
                        {/*        allowClear*/}
                        {/*        options={productSizesFilter}*/}
                        {/*        value={productSizesSelected}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}

                        <div
                            className={"modal-switch-wrapper"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                            }}
                        >
                            <div className={"modal-form-label"}>Выбор размеров:</div>
                            <div
                                className={`modal-switch-label ${
                                    !chooseSizeSwich ? "active" : ""
                                }`}
                            >
                                Все
                            </div>
                            <Form.Item
                                name={"cashbackTypeBoolean"}
                                className={"no-mb"}
                            >
                                <Switch
                                    checked={chooseSizeSwich}
                                    onChange={(e) => setChooseSizeSwich(e)}
                                />
                            </Form.Item>
                            <div
                                className={`modal-switch-label ${
                                    chooseSizeSwich ? "active" : ""
                                }`}
                            >
                                По размерно
                            </div>
                        </div>

                        {chooseSizeSwich
                            ?
                            <>
                                <Form.Item
                                    name="sizes"
                                    rules={[{required: true}]}
                                    label={"Выберите размеры, которые будут участвовать в акции"}
                                >
                                    <Select
                                        onChange={(values) => {
                                            setSelectedProductSizes(values);
                                        }}
                                        mode="multiple"
                                        allowClear
                                        options={productSizesFilter}
                                        value={productSizesSelected}
                                    />
                                </Form.Item>

                                {(productSizesSelected ?? []).map((item: any, index: number) => {
                                    return (
                                        <Form.Item
                                            rules={[
                                                // ({getFieldValue}) => ({
                                                //     validator(_, value) {
                                                //         const maxValue = productSizesFilter?.find(item1 => item1.value == item)?.amount;
                                                //         if (value <= maxValue) {
                                                //             return Promise.resolve();
                                                //         }
                                                //         return Promise.reject('Введено некорректное количество');
                                                //     },
                                                // }),
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!isExistProductSizes) {
                                                            return Promise.reject('Продукт не существует');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            name={`${item}-${index}`}
                                            label={`Введите кол-во товара ${index + 1} ${item} размера`}
                                        >
                                            <InputNumber style={{width: "100%"}}/>
                                        </Form.Item>
                                    );
                                }) ?? <></>}
                            </>
                            :
                            <Form.Item
                                rules={[
                                    // ({getFieldValue}) => ({
                                    //     validator(_, value) {
                                    //         const maxValue = productSizesFilter?.find(item1 => item1.value == 'all')?.amount;
                                    //         if (value <= maxValue) {
                                    //             return Promise.resolve();
                                    //         }
                                    //         return Promise.reject('Введено некорректное количество');
                                    //     },
                                    // }),
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!isExistProductSizes) {
                                                return Promise.reject('Продукт не существует');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                    {required: true, message: 'Поле обазательно для воода'}
                                ]}
                                name={'maxItemsGiven'}
                                label={`Введите кол-во всех товаров размеров`}
                            >
                                <InputNumber style={{width: "100%"}} type={'number'}/>
                            </Form.Item>

                        }

                        {/*<Form.Item*/}
                        {/*    label={"Введите ключ, по которому будут искать товар"}*/}
                        {/*    name={"keyword"}*/}
                        {/*>*/}
                        {/*    <Select allowClear options={keywordsToSelect}/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item
                            name={"keyword"}
                            label={"Введите ключ, по которому будут искать товар"}
                        >
                            <Select
                                allowClear
                                options={keywordsToSelect}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: "8px 0"}}/>
                                        <Space style={{padding: "0 8px 4px"}}>
                                            <Input
                                                placeholder="Введите новое ключевое слово"
                                                ref={inputRef}
                                                value={newKeyword}
                                                onChange={onNewKeywordChange}
                                            />
                                            <Button type="text" block onClick={addItem}>
                                                Добавить
                                            </Button>
                                        </Space>
                                    </>
                                )}
                            />
                        </Form.Item>
                        <div
                            className={"modal-switch-wrapper"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                            }}
                        >
                            <div className={"modal-form-label"}>Возвращаем:</div>
                            <div
                                className={`modal-switch-label ${
                                    !cashbackSwitchChecked ? "active" : ""
                                }`}
                            >
                                Процент
                            </div>
                            <Form.Item name={"cashbackTypeBoolean"} className={"no-mb"}>
                                <Switch
                                    checked={cashbackSwitchChecked}
                                    onChange={(e) => setCashbackSwitchChecked(e)}
                                />
                            </Form.Item>
                            <div
                                className={`modal-switch-label ${
                                    cashbackSwitchChecked ? "active" : ""
                                }`}
                            >
                                Фиксированную сумму
                            </div>
                        </div>
                        <Form.Item
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const maxValue = cashbackSwitchChecked ? 9999999 : 100;
                                        if (value <= maxValue) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Введено некорректное количество');
                                    },
                                }),
                            ]}
                            name={"cashbackValue"}
                            label={"Введите значение возврата"}
                        >
                            <InputNumber style={{width: "100%"}} type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: 'Поле обазательно для воода'}]}
                            name={"publishDate"}
                            label={"Введите/выберите дату, когда запустить акцию"}
                        >
                            <DatePicker
                                showTime
                                placeholder={""}
                                format="YYYY-MM-DD HH:mm"
                                style={{width: "100%"}}
                                onChange={(e) => setPublishDate(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            rules={
                                [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 999,
                                        message: 'Минимальное количество дней: 1, максимальное количество дней: 999'
                                    },
                                    {required: true, message: 'Поле обазательно для воода'}
                                ]
                            }
                            name={"daysAction"}
                            label={"Введите кол-во дней, за которые выполнится акция"}
                        >
                            <InputNumber style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    validator: (rule, value, callback) => {
                                        const cleanValue = removeHtmlCss(value); // Удаление HTML и CSS из текста
                                        if (cleanValue.length > 574) {
                                            callback('Максимальная длина 574 символов');
                                        } else if (!cleanValue.trim()) {
                                            callback('Поле обязательно для ввода');
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ]}
                            name={"publishText"}
                            label={"Введите текст рекламы акции кешбека для публикации"}
                        >
                            <ReactQuill />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    validator: (rule, value, callback) => {
                                        const cleanValue = removeHtmlCss(value); // Удаление HTML и CSS из текста
                                        if (cleanValue.length > 574) {
                                            callback('Максимальная длина 574 символов');
                                        } else if (!cleanValue.trim()) {
                                            callback('Поле обязательно для ввода');
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ]}
                            name={"actionText"}
                            label={"Введите текст условия акции кешбека"}
                        >
                            {/*<TextArea style={{height: "auto !important"}} rows={3}/>*/}
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item name={"condition1"} label={"Введите условие №1"}>
                            <ReactQuill/>
                        </Form.Item>
                        <Form.Item name={"condition2"} label={"Введите условие №2"}>
                            <ReactQuill/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"condition3"} label={"Введите условие №3"}>
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item name={"condition4"} label={"Введите условие №4"}>
                            <ReactQuill/>
                        </Form.Item>

                        <Form.Item name={"condition5"} label={"Введите условие №5"}>
                            <ReactQuill/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    justify={"space-between"}
                    align={"middle"}
                    style={{marginTop: "10px"}}
                >
                    <Col style={{display: "flex", gap: "15px"}}>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{fontSize: "12px", width: "222px"}}
                            htmlType={"submit"}
                            loading={isUpdateLoading}
                        >
                            Сохранить изменения
                        </Button>
                        <Button
                            type={"primary"}
                            ghost
                            className={"button"}
                            style={{fontSize: "12px", width: "222px"}}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{
                                fontSize: "12px",
                                backgroundColor: "#FF9F43",
                                padding: "8px 30px",
                            }}
                            onClick={() => onCreate(form.getFieldsValue(true), onClose)}
                            loading={isCreateLoading}
                        >
                            Создать новую акцию на основе этой
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
