import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSellerData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentSize, setCurrentSize] = useState<number>(10);

    const [search, setSearch] = useState<any>('')
    const [debouncedSearch] = useDebounce(search, 1000);

    const { data: sellers, ...options } = useQuery(
        [
            "SELLER_LIST_FULL_INFO",
            currentPage,
            currentSize,
            debouncedSearch,

        ],
        () => backend.seller.getSeller(
            currentPage.toString(),
            currentSize.toString(),
            debouncedSearch,
        ),
        {
            keepPreviousData: true,
        }
    );

    return {
        sellers,
        currentPage,
        setCurrentPage,
        currentSize,
        setCurrentSize,
        search,
        setSearch,
        ...options,
    };
}
