import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import useGuidListData from "../hooks/expenses/useGuideList";
import {ReactComponent as rangePickerArrow} from "../../../assets/images/rangepicker-arrow.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useExpensesUpdate} from "../hooks/expenses/useExpensesUpdate";
import useExpensesById from "../hooks/expenses/useExpensesById";
import dayjs from "dayjs";
import useSellerList from "../../sellers/hooks/useSellerList";
import {ReactComponent as SelectIconComponent} from "../../../assets/images/select-icon.svg";

const {RangePicker} = DatePicker;
const {Option} = Select;

export default function EditPayment({onClose, id}: any) {

    const [form] = Form.useForm();

    const [selectedSeller, setSelectedSeller] = useState<any>();
    const {
        sellers,
        setSearch: setSearchSeller
    } = useSellerList();

    const {
        guidListData,
        search,
        setSearch,
    } = useGuidListData()

    const {
        expensesById,
        setId,
        isLoading: isLoadingGet
    } = useExpensesById()


    const {
        handleUpdate,
        isError,
        errorMessage,
        isLoading,
        isSuccess,
    } = useExpensesUpdate();

    const onSend = (value: any) => {
        var body: any = {}

        if (value.operationTypeId) {
            body['operationTypeId'] = value.operationTypeId
        }
        if (value.description) {
            body['description'] = value.description
        }
        if (selectedSeller) {
            body['sellerId'] = selectedSeller
        }
        if (value.cost) {
            body['cost'] = value.cost
        }
        if (value.date) {
            body['startDate'] = value.date[0]
            body['endDate'] = value.date[1]
        }

        handleUpdate(body, id)
    };

    useEffect(() => {
        form.setFieldsValue({
            operationTypeId: expensesById?.operationType?.id,
            seller: expensesById?.seller?.id,
            description: expensesById?.description,
            cost: expensesById?.cost,
            date: [dayjs(expensesById?.startDate), dayjs(expensesById?.endDate)],
        })
        setSelectedSeller(expensesById?.sellerId)
    }, [id, expensesById])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно изменили тип оплаты')
            onClose()
        }
    }, [isSuccess])

    useEffect(() => {
        setId(id)
    }, [id])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Редактирование оплаты"} onClose={onClose}/>
            <Row
                className={"container"}
            >
                <Col span={24}>
                    {isLoadingGet
                        ?
                        <div
                            style={{
                                padding: "120px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        <Form layout={"vertical"} onFinish={onSend} form={form}>
                            <Form.Item
                                label={"Выберите тип оплаты"}
                                style={{marginBottom: "-10px"}}
                                name='operationTypeId'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    value={search}
                                    placeholder="Поиск"
                                    filterOption={false}
                                    onSearch={(value: any) => setSearch(value)}
                                >
                                    {guidListData?.map((option: any) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Выберите продавца"}
                                name='seller'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    value={selectedSeller}
                                    placeholder="Продавец"
                                    filterOption={false}
                                    suffixIcon={<Icon
                                        component={SelectIconComponent}
                                        style={{marginTop: "2px", fontSize: "10px"}}
                                    />}
                                    // value={search}
                                    onSearch={(value: any) => setSearchSeller(value)}
                                    onChange={(e: any, option: any) => {
                                        setSelectedSeller(e)
                                    }}
                                >
                                    {sellers?.map((option: any) => {
                                        return (
                                            <Option key={option?.wb_article} value={option?.id}>
                                                {option.company_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Укажите сумму оплаты в рублях"}
                                name='cost'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Input type='number'/>
                            </Form.Item>
                            <Form.Item
                                label={"Введите описание данной оплаты"}
                                name='description'
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={"Выберите промежуток"}
                                name='date'
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <RangePicker
                                    style={{
                                        width: '100%'
                                    }}
                                    placeholder={["Начало", "Конец"]}
                                    separator={<Icon component={rangePickerArrow}/>}
                                    format={"DD.MM.YYYY"}
                                    // onChange={(dates: any) => handleRangeChangeTable(dates)}
                                />
                            </Form.Item>

                            <Col style={{
                                display: 'flex',
                                gap: 10
                            }}>
                                <Button
                                    loading={isLoading}
                                    type={"primary"}
                                    className={"button"}
                                    htmlType={"submit"}
                                    style={{
                                        fontSize: "12px",
                                        width: "50%",
                                    }}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    onClick={onClose}
                                    className={"button"}
                                    type={"primary"}
                                    ghost
                                    style={{
                                        fontSize: "12px",
                                        width: "50%",
                                    }}
                                >
                                    Отмена
                                </Button>
                            </Col>
                        </Form>
                    }
                </Col>
            </Row>
        </div>
    );
}
