import React from 'react';
import {Checkbox} from "antd";

const SearchCheckBoxBlock = ({blocks, handleChangeSearchItems, blockToSet} : any) => {
    return (
        <div>
            {blocks.map((block: any) => (
                <div
                    key={block.value}
                    onClick={() => handleChangeSearchItems(block.id, blockToSet)}
                    style={{
                        cursor: 'pointer',
                        marginBottom: "10px",
                        border: block.selected ? '1px solid #4c78ee' : '0px solid #000',
                        borderRadius: block.selected ? 5 : 0,
                        padding: '0 5px'
                    }}
                >
                    {block.selected ? (
                        <Checkbox checked>{block.value}</Checkbox>
                    ) : (
                        block.value
                    )}
                </div>
            ))}
        </div>
    );
};

export default SearchCheckBoxBlock;