import {Col, Row} from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import TSDTablet from "../../features/kiz/pages/tsd/TSDTablet";
import GTDTablet from "../../features/storage/pages/gtd/GTDTablet";

export default function GTDTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col className={"container"} span={24}>
                <GTDTablet/>
            </Col>
        </Row>
    );
}
