import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import {ReactComponent as editIcon} from "../../../../assets/images/edit-icon.svg";
import {ReactComponent as trashBucket} from "../../../../assets/images/trash-bucket.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";
import EditKizFilesModal from "../../modal/gtd/EditKizFilesModal";
import AddKizFilesModal from "../../modal/gtd/AddKizFilesModal";
import useProductDataByGTD from "../../hooks/gtd/useProductDataByGTD";

const data = [
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
    {
        name: 'Имя задачи',
        status: 'Статус задачи',
        fio: 'Ответственное лицо',
    },
];

export default function GTDFullInfo() {

    const {
        productsData,
        setId,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        isLoading
    } = useProductDataByGTD()

    // tasks, products
    const [headerNav, setHeaderNav] = useState('tasks')
    const [tabNav, setTabNav] = useState('status1')

    const [editKizFilesModal, setEditKizFilesModal] = useState<any>({
        isOpen: false,
        gtdId: null
    })
    const [addKizFilesModal, setAddKizFilesModal] = useState<any>({
        isOpen: false,
        gtdId: null
    })

    const navigate = useNavigate()
    const route = useParams()

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px", width: 180}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT_PAYMENT",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px", color: 'red', width: 180}}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    В архив
                </span>
            ),
            key: "DELETE_PAYMENT",
        }
    ];

    const getProductsActions = () => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_PAYMENT":
                        // setEditPaymentModal(true)
                        break;
                    case "DELETE_PAYMENT":
                        // setEditProductKeyWordsModal({
                        //     ...editProductAnalyticsModal,
                        //     productId: productId,
                        //     isOpen: true
                        // })
                        break;
                }
            },
        };
    };

    const columns = [
        {
            title: "ГТД",
            dataIndex: "1",
            key: "1",
            width: "9%",
            render: () =>{
                return(
                    <div>
                        {productsData?.title || '---'}
                    </div>
                )
            }
        },
        {
            title: "Название",
            dataIndex: "title",
            key: "title",
            width: "9%",
        },
        {
            title: "Артикул",
            dataIndex: "dec_article",
            key: "dec_article",
            width: "9%",
        },
        {
            title: "Размер",
            dataIndex: "dec_size",
            key: "dec_size",
            width: "9%",
        },
        {
            title: "Штрихкод",
            dataIndex: "barcode",
            key: "barcode",
            width: "9%",
        },
        {
            title: "Производитель",
            dataIndex: "production_name",
            key: "production_name",
            width: "9%",
        },
        {
            title: "Ссылка на изображение",
            dataIndex: "image_src",
            key: "image_src",
            width: "9%",
        },
        {
            title: "Короткий КИЗ",
            dataIndex: "cis_ref",
            key: "cis_ref",
            width: "9%",
        },
        // {
        //     title: "Файл с перечнем КИЗ",
        //     dataIndex: "9",
        //     key: "9",
        //     width: "9%",
        // },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
            width: "19%",
        },
    ];

    const renderTasks = () => {
        return (
            <Col span={24} style={{width: '100%', padding: 20,}}>
                <div className={"storage-tabs"} style={{marginBottom: 20}}>
                    <div
                        className={`${
                            tabNav === 'status1' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status1')}
                    >
                        Активные
                    </div>
                    <div
                        className={`${
                            tabNav === 'status2' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status2')}
                    >
                        Архивные
                    </div>
                    <div
                        className={`${
                            tabNav === 'status3' ? "tab-active" : ""
                        }`}
                        onClick={() => setTabNav('status3')}
                    >
                        Отсканировано этим устройством
                    </div>
                </div>

                <div className="tsd-wrap">
                    {
                        data.map(item =>
                            <div className="tsd-item">
                                <div className="tsd-item-info">
                                    <div>
                                        {item.name}
                                    </div>
                                    <div>
                                        {item.status}
                                    </div>
                                    <div>
                                        {item.fio}
                                    </div>
                                </div>
                                <div className="tsd-item-dots">
                                    <Dropdown
                                        trigger={["click"]}
                                        placement={"bottomRight"}
                                        menu={getProductsActions()}
                                    >
                                        <MoreOutlined
                                            style={{cursor: "pointer", fontSize: "20px"}}/>
                                    </Dropdown>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Pagination
                    defaultCurrent={1}
                    current={1}
                    // onChange={(page, pageSize): any => setPage(page)}
                    total={3}
                    pageSize={6}
                    style={{textAlign: "center", padding: "16px 0"}}
                />
            </Col>
        )
    }

    const renderProducts = () => {
        return (
            <Col span={24} style={{width: '100%'}}>
                <div style={{display: 'flex', padding: 10, justifyContent: 'flex-end', alignItems: 'center', gap: 10}}>
                    <Button
                        disabled={productsData?.aux_data?.ccd_state !== "ccd_being_formed"}
                        className={"button"}
                        type={"primary"}
                        style={{width: "222px"}}
                        onClick={() => setEditKizFilesModal({
                            isOpen: true,
                            gtdId: route.id
                        })}
                    >
                        Редактировать КИЗы
                    </Button>
                    <Button
                        disabled={productsData?.aux_data?.ccd_state !== "ccd_being_formed"}
                        className={"button"}
                        type={"primary"}
                        style={{width: "222px"}}
                        onClick={() => setAddKizFilesModal({
                            isOpen: true,
                            gtdId: route.id
                        })}
                    >
                        Добавить КИЗ файлы
                    </Button>
                </div>
                <Table
                    loading={isLoading}
                    className="product-arrival-table"
                    columns={columns}
                    dataSource={productsData?.data || []}
                    pagination={{
                        onChange: (page, pageSize) => setCurrentPage(page),
                        position: ["bottomCenter"],
                        pageSize: Number(currentPageSize),
                        total: Number(productsData?.total),
                        showSizeChanger: false,
                        current: currentPage,
                    }}
                />
            </Col>
        )
    }

    useEffect(() => {
        setId(route.id)
    }, [route.id])

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Col span={24} style={{
                marginBottom: 10
            }}>
                {" "}
                <div
                    className={"back-button-header"}
                    onClick={() => navigate(-1)}
                >
                    <Icon component={headerPrevBtn}/>
                    Назад
                </div>
            </Col>

            <Col span={24}
                 style={{
                     marginTop: 20,
                     marginBottom: 20,
                     display: 'flex',
                     alignItems: 'center'
                 }}
            >
                {" "}
                <div
                    className={"back-button-header"}
                >
                    {productsData?.title}
                </div>
                <div className={"storage-tabs"} style={{marginLeft: 20}}>
                    <div
                        className={`${
                            headerNav === 'tasks' ? "tab-active" : ""
                        }`}
                        onClick={() => setHeaderNav('tasks')}
                    >
                        Задачи
                    </div>
                    <div
                        className={`${
                            headerNav === 'products' ? "tab-active" : ""
                        }`}
                        onClick={() => setHeaderNav('products')}
                    >
                        Товары
                    </div>
                </div>
            </Col>

            <Row className={"container"}>

                {
                    headerNav === 'tasks' && renderTasks()
                }
                {
                    headerNav === 'products' && renderProducts()
                }

            </Row>

            <Modal
                open={editKizFilesModal.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <EditKizFilesModal
                    gtdId={editKizFilesModal?.gtdId}
                    onClose={() => setEditKizFilesModal({
                        isOpen: false,
                        gtdId: null
                    })}
                />
            </Modal>
            <Modal
                open={addKizFilesModal?.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <AddKizFilesModal
                    gtdId={addKizFilesModal?.gtdId}
                    onClose={() => setAddKizFilesModal({
                        isOpen: false,
                        gtdId: null
                    })}
                />
            </Modal>
        </div>
    );
}
