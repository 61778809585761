import React, {useState} from "react";
import {Col, Row} from "antd";
import {useNavigate, Link, useLocation, Outlet} from "react-router-dom";

export default function DocumentMirrorPage() {

    const location = useLocation();
    const navigate = useNavigate()

    return (
        <Row>
            <Col style={{padding: "10px 20px"}} span={24}>
                <div className={"storage-tabs"}>
                    <div
                        className={`${
                            location.pathname.includes('/statements') ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/document-mirror/statements')}
                    >
                        Финансовые отчеты
                    </div>
                    <div
                        className={`${
                            location.pathname.includes('/documents') ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/document-mirror/documents')}
                    >
                        Документы
                    </div>
                </div>
                <Row>
                    <Col span={24}>
                        <Outlet/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
