import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useDeclarationAdd} from "../../hooks/declaratiion/useDeclarationAdd";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import useExtRef from "../../hooks/useExtRef";

const {Option} = Select;

export default function AddDeclarationModal({onClose}: any) {

    const [form] = Form.useForm();

    const [selectedSeller, setSelectedSeller] = useState<any>('');
    const {
        sellers,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
    } = useExtRef();

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useDeclarationAdd()

    const onFinish = (values: any) => {
        handleCreate(values)
    };

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно добавили новую декларацию')
            form.resetFields()
            setSelectedSeller('')
            onClose()
        }
    }, [isSuccessCreate])

    useEffect(() => {
        if (isErrorCreate) {
            message.error(errorMessage)
        }
    }, [isErrorCreate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Декларации"} onClose={() =>{
                form.resetFields()
                setSelectedSeller('')
                onClose()
            }}/>
            <>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={
                            <div>
                                <span>Название</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <div>
                                <span>Gtin</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="gtin"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите gtin",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <div>
                                <span>Выберите юр.лицо, на которое будет загружен файл</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="owner_ref"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            value={selectedSeller}
                            placeholder="Юр. лицо"
                            filterOption={false}
                            suffixIcon={<Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />}
                            onSearch={(value: any) => setSearch(value)}
                            onChange={(e: any, option: any) => {
                                setSelectedSeller(e)
                            }}
                        >
                            {sellers?.data?.map((option: any) => {
                                return (
                                    <Option key={option?.wbArticle} value={option?.id}>
                                        {option.companyName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{width: "100%"}}
                        htmlType={"submit"}
                        loading={createLoading}
                    >
                        Добавить
                    </Button>
                </Form>
                {isErrorCreate && (
                    <div
                        className={"ant-form-item-explain-error"}
                        style={{color: "#ff4d4f"}}
                    >
                        {errorMessage}
                    </div>
                )}
            </>

        </div>
    );
}
