import {Button, Col, Form, Input, Row} from "antd";
import React from "react";

interface AddKeywordDropdownProps {
    open: boolean;
    keywordsWb: any
    setKeywordsWb: any;
    onClose: any;
}

export default function AddKeywordDropdown({
                                               open,
                                               setKeywordsWb,
                                               onClose,
                                               keywordsWb
                                           }: AddKeywordDropdownProps) {

    const [form] = Form.useForm<{}>();

    const onAddNewOne = async (values: any) => {
        console.log(values)
        await setKeywordsWb([...keywordsWb, {id: Math.floor(Math.random() * 1000000), value: values.keyWord, selected: false}])
        await onClose()
        await form.resetFields()
    }

    return (
        <div
            className={`keyword-add-dropdown ${
                !open && "keyword-add-dropdown-closed"
            }`}
            style={{top: "30px"}}
        >
            <Form
                layout={"vertical"}
                onFinish={(values: any) => onAddNewOne(values)}
                form={form}
            >
                <Form.Item
                    label={"Введите ключевое слово"}
                    name="keyWord"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите ключевое слово",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Row style={{marginTop: "10px"}} gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            style={{
                                color: "#4C78EE",
                                width: "100%",
                                border: "1px solid #4C78EE",
                            }}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                        >
                            Добавить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
