import React, {useEffect, useState} from 'react';
import arrowToRight from '../../../assets/financeResultIcons/arrowToRight.svg'
import arrowToLeft from '../../../assets/financeResultIcons/arrowToLeft.svg'
import {Spin} from "antd";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const CustomRangePicker = ({
                               loading,
                               rangeData,
                               type,
                               handleRangeChangeTable,
                               isCalendarMode,
                               setIsCalendarMode,
                               dateStart,
                               dateEnd
                           }: any) => {


    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setIsCalendarMode(false)
        }
    };

    const handleNextClick = () => {
        if (currentIndex < rangeData.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setIsCalendarMode(false)
        }
    };

    const currentItem = rangeData[currentIndex];

    useEffect(() => {
        setCurrentIndex(0)
        // setCurrentIndex(rangeData?.length - 1)
    }, [type])

    useEffect(() => {
        setIsLoading(true)
        if (isCalendarMode) {
            if (!loading) {
                setTimeout(() => {
                    let findIndex = rangeData?.findIndex((item: any) =>
                        dayjs(item?.startDate).format('DD.MM.YYYY') + '-' + dayjs(item?.endDate).format('DD.MM.YYYY') === dateStart + "-" + dateEnd
                    );
                    // const isMinusIndex = findIndex === -1 ?  rangeData?.length - 1 : findIndex
                    const isMinusIndex = findIndex === -1 ?  0 : findIndex
                    setCurrentIndex(isMinusIndex)
                    setIsLoading(false)
                }, 500)
            }
        } else {
            if (!loading) {
                console.log("findIndex: " + 'pagi')
                setTimeout(() => {
                    handleRangeChangeTable([
                        dayjs(currentItem?.startDate),
                        dayjs(currentItem?.endDate)
                    ])
                }, 500)
                setIsLoading(false)
            }
        }
    }, [currentItem?.title, dateStart, loading, rangeData])

    if (loading || isLoading) {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    paddingTop: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: "center",
                }}
            >
                <Spin
                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                />
            </div>
        )
    }

    if (rangeData?.length === 0 || !rangeData) {
        return (
            <div
                style={{
                    height: 40,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: "center",
                    color: '#fff'
                }}
            >
                Для данного этапа нет данных для выбора месяца/квартала/года
            </div>
        )
    }

    return (
        <div className="fin-result-range-wrap">

            <img
                src={arrowToLeft}
                alt=""
                onClick={handleNextClick}
                style={{
                    pointerEvents: currentIndex === rangeData.length - 1 || loading ? 'none' : 'auto',
                    opacity: currentIndex === rangeData.length - 1 || loading ? 0.5 : 1,
                    cursor: currentIndex === rangeData.length - 1 || loading ? 'auto' : 'pointer',
                }}
            />

            <div
                className="fin-result-range-text"
                style={{
                    opacity: loading ? 0.5 : 1,
                }}
            >
                {currentItem?.title}
            </div>

            <img
                src={arrowToRight}
                alt=""
                onClick={handlePrevClick}
                style={{
                    pointerEvents: currentIndex === 0 || loading ? 'none' : 'auto',
                    opacity: currentIndex === 0 || loading ? 0.5 : 1,
                    cursor: currentIndex === 0 || loading ? 'auto' : 'pointer',
                }}
            />
        </div>
    );
};

export default CustomRangePicker;