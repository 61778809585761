import {Col, Row} from "antd";
import React from "react";
import ExpensesTable from "../../features/operatingExpenses/ExpensesTable";
import GuideTable from "../../features/operatingExpenses/GuideTable";

export default function GuideTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col span={24}>
                <GuideTable/>
            </Col>
        </Row>
    );
}
