import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";

export default function usePlusMinusPhraseData(id: string) {

    const { data: data, ...options } = useQuery(
        ["PLUS_MINUS_PHRASE_DATA", id],
        () =>
            backend.keyWords.getPlusMinusPhraseData(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        data,
        ...options,
    };
}
