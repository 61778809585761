import { useQuery } from "react-query";
import { backend } from "api";

export default function useCurrentUserData() {
    const { data: user, ...options } = useQuery(
        ["USER_CURRENT_DATA"],
        () => backend.user.getUserCurrentData(),
        {
            keepPreviousData: true,
        }
    );

    return {
        user,
        ...options,
    };
}
