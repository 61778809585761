import React from 'react';
import {useDrop} from 'react-dnd';
import Phrase from "./PhraseProps";

const ItemTypes = {
    PHRASE: 'phrase',
};

interface BlockProps {
    name: string;
    blocks: any;
    blockName: any;
    onDrop: (phrase: string, targetBlock: string) => void;
}

const Block: React.FC<BlockProps> = ({name, onDrop, blocks, blockName}) => {

    const [, drop] = useDrop({
        accept: ItemTypes.PHRASE,
        drop: (item: any) => onDrop(item?.item?.id, blockName),
    });

    return (
        <div className="plus-minus-wrap" ref={drop}>
            <div className="plus-minus-wrap-title">{name}</div>
            <div className="plus-minus-wrap-phrases">
                {blocks?.length === 0
                    ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        Нет данных
                    </div>
                    :
                    blocks?.map((item: any) => (
                        <Phrase key={item} item={item}/>
                    ))
                }
            </div>
        </div>
    );
};

export default Block;
