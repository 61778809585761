import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";
import {getLaboratoryProducts} from "../../../api/laboratory";

export default function useLaboratoryProductList() {

  const [search, setSearch] = useState<any>('')
  const [notEmptyMode, setNotEmptyMode] = useState<any>(true)
  const [debouncedSearch] = useDebounce(search, 1000);

  const { data: products, ...options } = useQuery(
    [
      "LABORATORY_PRODUCTS_LIST",
      debouncedSearch,
      notEmptyMode
    ],

    () => backend.laboratory.getLaboratoryProducts(
      debouncedSearch,
      notEmptyMode
    ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    products,
    search,
    setSearch,
    setNotEmptyMode,
    ...options,
  };
}
