import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import {backend} from "api";

export const useUpdateGTD = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading: updateLoading,
        isError,
        isSuccess,
        ...options
    } = useMutation(backend.storage.updateGTD, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`GTD_DATA`])]);
        },
    });

    const handleUpdate = useCallback(
        async (values: any, id: string, formatData?: any) => {
            try {
                await update({ id: id,  values,  formatData});
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при редактировании ГТД");
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: updateLoading,
        isError,
        errorMessage,
        isSuccess,
        ...options
    };
};
