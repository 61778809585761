import {useQuery} from "react-query";
import {backend} from "api";

export default function useCashbackStatuses() {

    const {data: cashBackStatuses, ...options} = useQuery(
        ["CASHBACK_RESULT_LIST"],
        () =>
            backend.analytics.getCashbackStatuses(),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        cashBackStatuses,
        ...options,
    };
}
