import React from "react";
import BiderTable from "../../features/advertising-mirror/pages/bider/BiderTable";

export default function BiderTab() {
    return (
        <div style={{marginTop: "20px"}}>
            <BiderTable/>
        </div>
    );
}
