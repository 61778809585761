import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "api";
import {useCallback, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useExpensesData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedText] = useDebounce(searchText, 1000);
    const [sortType, setSortType] = useState<string>("desc");
    const [sortParam, setSortParam] = useState<string>("id");

    const { data: expensesData, ...options } = useQuery(
        [
            "EXPENSES_DATA",
            currentPage,
            currentPageSize,
            debouncedText,
            sortType,
            sortParam
        ],
        () =>
            backend.operatingExpenses.getExpensesData(
                currentPage.toString(),
                currentPageSize,
                debouncedText,
                sortType,
                sortParam
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        expensesData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        sortType,
        sortParam,
        setSortType,
        setSortParam,
        ...options,
    };
}
