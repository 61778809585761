import React, {useState} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

interface ChartData {
    xCoords: string;
    yCoords: number;
}

interface ChartProps {
    data: any;
}

const KeyWordsChart: React.FC<ChartProps> = ({data}) => {
    const colors = ['#28C76F', '#FDB813', '#FF4961', '#166FFF', '#FF9F1C', '#9C36B5', '#3B4CCA', '#FF5722', '#F44336', '#9C27B0', '#4CAF50', '#2196F3', '#FFC107', '#E91E63', '#00BCD4'];

    const dateMap: any = {};

    for (const category in data) {
        data[category].forEach((item: any) => {
            const { xCoords, yCoords } = item;
            if (!dateMap[xCoords]) {
                dateMap[xCoords] = {};
            }
            dateMap[xCoords][category] = yCoords;
        });
    }

    const formattedData = Object.keys(dateMap).map(date => {
        const dataPoint = dateMap[date];
        return {
            name: date,
            ...dataPoint,
        };
    });

    function compareDates(a: any, b: any) {
        var dateA: any = new Date(a.name);
        var dateB: any = new Date(b.name);
        return dateA - dateB;
    }

    // Сортировка данных по полю "name" от меньшей к большей дате
    let sort = formattedData.sort(compareDates);
    console.log(sort)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <LineChart width={900} height={400} data={formattedData}>
                <CartesianGrid strokeDasharray="3 3"/>
                {/*<XAxis dataKey="xCoords"/>*/}
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip content={<CustomTooltip data={data}/>}/>
                {
                    Object?.keys(data)?.map((item: any, index: any) =>
                            <Line
                                type="monotone"
                                dataKey={item}
                                strokeWidth={4}
                                stroke={colors[index % colors.length]}
                            />
                        )
                }
            </LineChart>
            <div style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row',
                gap: 20,
                flexWrap: 'wrap'
            }}>
                {
                    Object?.keys(data)?.map((key: any, index) => (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 10,
                        }}>
                            <div
                                style={{
                                    backgroundColor: colors[index % colors.length],
                                    width: 10,
                                    height: 10,
                                    borderRadius: 50
                                }}>
                            </div>
                            {key}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

const CustomTooltip = ({active, payload, label, data}: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                minWidth: 100,
                minHeight: 50,
                padding: '0 10px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #d5d5d5'
            }}>
                <div style={{
                    marginBottom: 10
                }}>
                    {label}
                </div>
                {
                    payload?.map((item: any) =>
                    <div style={{color: item?.color, marginBottom: 10}}>
                        {item?.dataKey}: {item?.value}
                    </div>
                    )
                }
            </div>
        );
    }

    return null;
};

export default KeyWordsChart;