import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const useUpdateFileName = () => {
    const queryClient = useQueryClient();

    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: handleUpdateFileName,
        ...options
    } = useMutation(backend.financialResult.updateFileName, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`CHART_FIN_RES_DATA`])]);
        },
    });

    const onUpdate = useCallback(
        async (values: any) => {
            try {
                await handleUpdateFileName(values);

            } catch (e: any) {
                const errorData = await e.response.data || e?.response?.data?.detail;
                if (errorData) {
                    setErrorMessage(errorData);
                }
            }
        },
        [handleUpdateFileName]
    );

    return {
        onUpdate,
        errorMessage,
        ...options
    };
};
