import {Button, Col, Form, Input, Row} from "antd";
import {useAuthStore} from "features/auth/hooks/useAuthStore";
import ModalHeader from "features/shared/ModalHeader";
import {useCashbackOperationUpdate} from "../hooks/useCashbackOperationUpdate";
import {useCashbackOperationSixUpdate} from "../hooks/useCashbackOperationSixUpdate";

const {TextArea} = Input;

interface ConditionModificationModalProps {
    operationId: number;
    onClose: any;
    action: any;
    text: any;
}

export default function ConditionModificationModalContent({
                                                              operationId,
                                                              onClose,
                                                              action,
                                                              text,
                                                          }: ConditionModificationModalProps) {
    const [form] = Form.useForm<{}>();

    const {state} = useAuthStore();
    const {handleUpdate} = useCashbackOperationUpdate();

    const handleSubmit = (values: any) => {
        const body = {
            action: action,
            userMessageText: values.userMessageText,
            analyticId: state.user.id,
        };
        handleUpdate(operationId, body, onClose);
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px", }}>
            <ModalHeader title={text} onClose={onClose}/>
            <Form
                onFinish={(values) => handleSubmit(values)}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name={"userMessageText"}
                    label={"Введите сообщение для пользователя"}
                    style={{width: "100%"}}
                >
                    <TextArea
                        style={{
                            height: "auto !important",
                            width: "100% !important",
                        }}
                        rows={7}
                    />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                        >
                            Добавить
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{width: "100%"}}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
