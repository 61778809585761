import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    Dropdown,
    Input,
    Menu, MenuProps, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";

import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, Link, useLocation, Outlet, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../../assets/images/header-prev-btn.svg";

const dataMainInfo = [
    {
        title: 'Имя задачи',
        type: 'Приемка',
        person: 'Кладовщик',
        responsible: 'И.О.Роман',
        dateCreate: '01.01.2023',
        dateCome: '01.01.2023',
        dateEnd: '01.01.2023',
    },
];

const dataDesc = [
    {
        desc: 'Текст',
    },
];

const dataComment = [
    {
        comment: 'Текст',
    },
];

export default function TSDExtraInfo() {


    const navigate = useNavigate()
    const route = useParams()

    const columnsMainInfo = [
        {
            title: "Название эмисиии",
            dataIndex: "title",
            key: "title",
            width: "15%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Тип задачи",
            dataIndex: "type",
            key: "type",
            width: "15%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Исполнитель",
            dataIndex: "person",
            key: "person",
            width: "15%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Ответственный",
            dataIndex: "responsible",
            key: "responsible",
            width: "15%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Дата создания задачи",
            dataIndex: "dateCreate",
            key: "dateCreate",
            width: "12%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Дата принятия задачи",
            dataIndex: "dateCome",
            key: "dateCome",
            width: "12%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: "Дата завер-ния задачи",
            dataIndex: "dateEnd",
            key: "dateEnd",
            width: "12%",
            render: (text: any) => {
                return (
                    <div style={{
                        padding: 15,
                    }}>
                        {text}
                    </div>
                )
            }
        },
    ];

    const columnsDesc = [
        {
            title: "Название эмисиии",
            dataIndex: "desc",
            key: "desc",
            width: "100%",
        },
    ];

    const columnsComment = [
        {
            title: "Название эмисиии",
            dataIndex: "comment",
            key: "comment",
            width: "100%",
        },
    ];


    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col span={24} style={{
                    marginBottom: 10
                }}>
                    {" "}
                    <div
                        className={"back-button-header"}
                        onClick={() => navigate(-1)}
                    >
                        <Icon component={headerPrevBtn}/>
                        Назад
                    </div>
                </Col>

                <Col span={24} style={{
                    marginTop: 20,
                    marginBottom: 10
                }}>
                    {" "}
                    <div
                        className={"back-button-header"}
                        onClick={() => navigate(-1)}
                    >
                        {route.id}
                    </div>
                </Col>

                <Row className={"container"} style={{width: '100%'}}>
                    <Col style={{width: '100%'}}>
                        <Table
                            className="product-arrival-table"
                            columns={columnsMainInfo}
                            dataSource={dataMainInfo}
                            scroll={{x: true}}
                            pagination={false}
                        />
                    </Col>
                    <Col style={{width: '100%'}}>
                        <Table
                            className="product-arrival-table"
                            columns={columnsDesc}
                            dataSource={dataDesc}
                            scroll={{x: true}}
                            pagination={false}
                        />
                    </Col>
                    <Col style={{width: '100%'}}>
                        <Table
                            className="product-arrival-table"
                            columns={columnsComment}
                            dataSource={dataComment}
                            scroll={{x: true}}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Row>

        </div>

    );
}
