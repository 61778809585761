import ModalHeader from "features/shared/ModalHeader";
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useRef,
    useState
} from "react";
import {AutoComplete, Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, message, Spin} from "antd";
import bucket from '../../../assets/images/bucket.svg'
import useProductsDataById from "../hooks/useProductDataById";
import {LoadingOutlined} from "@ant-design/icons";
import {useProductPostKeyWord} from "../hooks/useProductPostKeyWord";
import useKeyWordsList from "../../keyWords/hooks/useKeyWordsList";
import {useProductDeleteKeyWord} from "../hooks/useProductDeleteKeyWord";
import useKeyWordsGroupList from "../hooks/useKeyWordsGroupList";
import {useProductPostKeyWordGroup} from "../hooks/useProductPostKeyWordGroup";
import TextArea from "antd/es/input/TextArea";
import {useProductAutoPostKeyWord} from "../hooks/useProductAutoPostKeyWord";

export default function EditProductKeyWords({onClose, productId, productModal}: any) {

    const [selectedKeyWordsGroup, setSelectedKeyWordsGroup] = useState<any>([]);
    const [editState, setEditState] = useState<any>('one');
    const [autoKeyWords, setAutoKeyWords] = useState<string>('');

    const {
        isLoading,
        product,
        setId,
    } = useProductsDataById()

    const {
        keyWords,
        searchValue,
        setSearchValue,
    } = useKeyWordsList()

    const {
        keyWordsGroup,
        searchValue: searchValueGroup,
        setSearchValue: setSearchValueGroup,
    } = useKeyWordsGroupList()

    const {
        isLoading: deleteLoading,
        handleDelete: handleDelete,
        isError: isErrorDelete,
        errorMessage: deleteMessage,
        isSuccess: successDelete,
        setId: setIdForDelete
    } = useProductDeleteKeyWord();

    const {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
        setId: setIdForPost
    } = useProductPostKeyWord();

    const {
        isSuccessCreate: isSuccessCreateAuto,
        handleCreate: handleCreateAuto,
        setId: setIdAuto,
        createLoading: createLoadingAuto,
        isErrorCreate: isErrorCreateAuto,
        errorMessage: errorMessageAuto,
    } = useProductAutoPostKeyWord()

    const {
        isSuccessCreate: isSuccessCreateGroup,
        handleCreate: createGroup,
        createLoading: creatLoadingGroup,
        isErrorCreate: isErrorCreateGroup,
        errorMessage: errorMessageGroup,
        setId: setIdForPostGroup
    } = useProductPostKeyWordGroup();

    useEffect(() => {
        setId(productId)
        setIdForDelete(productId)
        setIdForPost(productId)
        setIdForPostGroup(productId)
        setIdAuto(productId)
    }, [productId])

    useEffect(() => {
        if (isErrorDelete) {
            message.error('Произошла ошибка при попытке удалить аналитика')
        }
    }, [isErrorDelete])

    useEffect(() => {
        if (isErrorCreate) {
            message.error('Произошла ошибка при попытке добавить ключевое слово. Пожалуйста выберите из предлогающих')
        }
        if (isErrorCreateGroup) {
            message.error('Произошла ошибка при попытке добавить группу ключевых слово. Слова из группы уже присутствуют в продукте')
        }
        if (isErrorCreateAuto) {
            message.error(errorMessageAuto)
        }
    }, [isErrorCreate, isErrorCreateGroup, isErrorCreateAuto])


    useEffect(() => {
        if (isSuccessCreate) {
            setSearchValue('')
        }
        if (isSuccessCreateGroup) {
            setSearchValueGroup('')
        }
        if (isSuccessCreateAuto) {
            setAutoKeyWords('')
        }
    }, [isSuccessCreate, isSuccessCreateGroup, isSuccessCreateAuto])


    useEffect(() =>{
        setEditState('one')
    },[productId])

    const handleSearch = (value: any) => {
        setSearchValue(value);
    };

    const handleSelect = (value: any) => {
        setSearchValue(value);
    };

    const handleSearchGroup = (value: any) => {
        setSearchValueGroup(value);
        setSelectedKeyWordsGroup(keyWordsGroup?.data?.find((item: any) => item.title === value)?.id)
    };

    const handleSelectGroup = (value: any) => {
        setSearchValueGroup(value);
        setSelectedKeyWordsGroup(keyWordsGroup?.data?.find((item: any) => item.title === value)?.id)
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px", marginBottom: 200}}>
            <ModalHeader
                title={"Редактирование ключевых слов"}
                onClose={onClose}
            />

            <div className={"send-article-wrapper"}>
                <div className="edit-product-wrap">

                    {isLoading
                        ?
                        <div
                            style={{
                                padding: "30px",
                                textAlign: "center",
                            }}
                        >
                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                            />
                        </div>
                        :
                        product.keywordsProducts?.length <= 0
                            ? <div className="edit-product-item-analytic-empty">
                                На данный момент продукт не ключевых слов
                            </div>
                            :
                            product.keywordsProducts?.map((item: any) =>
                                <div className="edit-product-item-analytic">
                                    {item?.title}
                                    <img
                                        style={{
                                            pointerEvents: deleteLoading ? 'none' : 'auto',
                                            opacity: deleteLoading ? 0.5 : 1
                                        }}
                                        onClick={() => handleDelete(item?.id, productId)}
                                        src={bucket}
                                        alt=""
                                        className='edit-product-item-analytic-bucket'
                                    />
                                </div>
                            )
                    }
                </div>
                <div className="products-edit-wrap">
                    <div
                        className={`${editState === 'one' ? 'products-edit-active' : 'products-edit'}`}
                        onClick={() => setEditState('one')}
                    >
                        Добавить ключевое слово
                    </div>
                    <div
                        className={`${editState === 'group' ? 'products-edit-active' : 'products-edit'}`}
                        onClick={() => setEditState('group')}
                    >
                        Добавить группу ключевых слов
                    </div>
                    <div
                        className={`${editState === 'auto' ? 'products-edit-active' : 'products-edit'}`}
                        onClick={() => setEditState('auto')}
                    >
                        Добавить ключевые слова вручную
                    </div>
                </div>

                {
                    editState === 'one' &&
                    <AutoComplete
                        style={{
                            width: '100%'
                        }}
                        placeholder="Введите ключевое слово"
                        dataSource={keyWords?.data?.map((item: any) => item.title)}
                        value={searchValue}
                        onChange={handleSearch}
                        onSelect={handleSelect}
                    />
                }

                {
                    editState === 'group' &&
                    <AutoComplete
                        style={{
                            width: '100%'
                        }}
                        placeholder="Введите группу ключевых слов"
                        dataSource={keyWordsGroup?.data?.map((item: any) => item.title)}
                        value={searchValueGroup}
                        onChange={handleSearchGroup}
                        onSelect={handleSelectGroup}
                    />
                }

                {
                    editState === 'auto' &&
                    <TextArea
                        style={{height: 350}}
                        rows={25}
                        value={autoKeyWords}
                        onChange={(e) => setAutoKeyWords(e.target.value)}
                    />
                }

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, marginTop: 20}}>
                    {
                        editState === 'one' &&
                            <Button
                                loading={createLoading}
                                disabled={!searchValue}
                                className="products-edit-button-save"
                                type={"primary"}
                                style={{width: "222px", fontSize: "12px"}}
                                onClick={() => handleCreate({
                                    productId: productId,
                                    keyword: searchValue
                                    // keywordId: keyWords?.data?.find((item: any) => item.title === searchValue)?.id
                                })}
                            >
                                Сохранить
                            </Button>
                    }
                    {
                        editState === 'group' &&
                        <Button
                            loading={creatLoadingGroup}
                            disabled={!searchValueGroup}
                            className="products-edit-button-save"
                            type={"primary"}
                            style={{width: "222px", fontSize: "12px"}}
                            onClick={() => createGroup({
                                productId: productId,
                                groupId: selectedKeyWordsGroup
                                // keywordId: keyWords?.data?.find((item: any) => item.title === searchValue)?.id
                            })}
                        >
                            Сохранить
                        </Button>
                    }
                    {
                        editState === 'auto' &&
                        <Button
                            loading={createLoadingAuto}
                            disabled={!autoKeyWords}
                            className="products-edit-button-save"
                            type={"primary"}
                            style={{width: "222px", fontSize: "12px"}}
                            onClick={() => handleCreateAuto({
                                productId,
                                keywords: autoKeyWords.split('\n')
                            })}
                        >
                            Сохранить
                        </Button>
                    }

                    <Button
                        className="products-edit-button-exit"
                        type={"primary"}
                        style={{width: "222px", fontSize: "12px"}}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
}
