import React from 'react';
import ProductByPhraseTable from "../../features/advertising-mirror/pages/productByPhrase/ProductByPhraseTable";

const ProductByPhraseTab = () => {
    return (
        <div style={{marginTop: "20px"}}>
            <ProductByPhraseTable/>
        </div>
    );
};

export default ProductByPhraseTab;