import React from 'react';
import ListOfSavedInvoicesTable from "../../features/storage/pages/listOfSavedInvoices/ListOfSavedInvoicesTable";

const ListOfSavedInvoicesTab = ({tab, setTab}: any) => {
    return (
        <div style={{ marginTop: "20px" }}>
            <ListOfSavedInvoicesTable tab={tab} setTab={setTab}/>
        </div>
    );
};

export default ListOfSavedInvoicesTab;