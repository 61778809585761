import React, { useEffect, useState } from 'react'
import {
	Button,
	Col,
	DatePicker,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import SettingItem from './components/SettingsTokenItem'
import dayjs from 'dayjs'
import useSettingsData from './hooks/settings/useSettingsData'
import Icon, {
	DeleteOutlined,
	EditOutlined,
	KeyOutlined,
	LoadingOutlined,
	MoreOutlined,
	UserAddOutlined,
} from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'
import { EditModal } from './modals/EditModal'
import { DeleteModal } from './modals/DeleteModal'
import { AddUserModal } from './modals/AddUser'
import { AddTokenModal } from './modals/AddToken'
import { PickSeller } from './components/PickSeller'

const { RangePicker } = DatePicker
const { Option } = Select

const SettingsMirrorApiAccess = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false)
	const [selectedToken, setSelectedToken] = useState<any>(null)

	const {
		settingsData,
		selectedSellerId,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		deleteToken,
	} = useSettingsData()
	const { setDataType: setDataNamesType, settingsData: scopesNamesData } =
		useSettingsData()

	const [data, setData] = useState(settingsData?.tokens)
	useEffect(() => {
		setDataType('tokens')
		setDataNamesType('token-scopes-name')
		if (settingsData) {
			setData(settingsData?.tokens)
		}
	}, [settingsData])

	useEffect(() => {
		if (settingsData && scopesNamesData) {
			const processedData = settingsData.tokens.map((token: any) => {
				const scopesList = token.scopes.map((scope: string) => {
					const scopeName = scopesNamesData[scope]?.name || scope
					return scopeName
				})
				return {
					...token,
					scopesList: scopesList.join(', '),
				}
			})
			setData(processedData)
		}
	}, [settingsData, scopesNamesData])

	const showEditModal = (user: any) => {
		setSelectedToken(user)
		setIsEditModalVisible(true)
	}

	const showDeleteModal = (token: any) => {
		setSelectedToken(token)
		setIsDeleteModalVisible(true)
	}

	const handleCancel = () => {
		setIsEditModalVisible(false)
		setIsDeleteModalVisible(false)
		setIsAddUserModalVisible(false)
		setSelectedToken(null)
	}

	const handleDelete = () => {
		if (selectedToken) {
			try {
				deleteToken(selectedToken.id)
				setData((prevData: any) =>
					prevData.filter((user: any) => user.id !== selectedToken.id)
				)
			} catch (error) {
				console.error('Ошибка при удалении пользователя.')
			} finally {
				setIsDeleteModalVisible(false)
				setSelectedToken(null)
			}
		}
	}

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Токен',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as const,

			width: 250,
		},
		{
			title: 'Доступы',
			dataIndex: 'scopesList',
			key: 'scopesList',
			width: 200,
			align: 'center' as const,
			render: (scopesList: string) => (
				<div
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						padding: '0.5rem 0',
					}}
				>
					{scopesList || 'Нет доступов'}
				</div>
			),
		},
		{
			title: 'Дата Создания',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 150,
			align: 'center' as const,

			render: (createdAt: any) => dayjs(createdAt).format('DD.MM.YYYY'),
		},
		{
			title: 'Последний раз использован',
			dataIndex: 'lastUsage',
			key: 'lastUsage',
			width: 150,
			align: 'center' as const,

			render: (lastUsage: any) => dayjs(lastUsage).format('DD.MM.YYYY'),
		},
		{
			title: 'Заканчивается',
			dataIndex: 'expiresAt',
			key: 'expiresAt',
			width: 150,
			align: 'center' as const,

			render: (expiresAt: any) => dayjs(expiresAt).format('DD.MM.YYYY'),
		},
		{
			title: 'Действие',
			dataIndex: 'action',
			align: 'center' as const,

			render: (_: any, record: any) => (
				<>
					<Button onClick={() => showDeleteModal(record)}>
						<DeleteOutlined />
					</Button>
				</>
			),
			width: 100,
		},
	]

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify='end'>
				<Col style={{ width: '250px' }}>
					<PickSeller
						selectedSeller={selectedSeller}
						setSelectedSeller={setSelectedSeller}
					/>
				</Col>
				<Col>
					<Button
						onClick={() => setIsAddUserModalVisible(true)}
						className='table-header-button'
						type='primary'
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: '10px',
						}}
					>
						Добавить токен
						<KeyOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
					</Button>
				</Col>
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<>
						<Col span={24}>
							<Table
								columns={columns}
								dataSource={data}
								pagination={false}
								rowKey={(record: any) => record.id}
							/>
						</Col>
					</>
				)}
			</Row>
			<EditModal
				isVisible={isEditModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsEditModalVisible}
				selectedUser={selectedToken}
			/>
			<DeleteModal
				handleDelete={handleDelete}
				isVisible={isDeleteModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsDeleteModalVisible}
				selected={selectedToken}
				text={`Вы уверены, что хотите удалить токен ${selectedToken?.name}?`}
			/>
			<AddTokenModal
				isVisible={isAddUserModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsAddUserModalVisible}
			/>
		</div>
	)
}

export default SettingsMirrorApiAccess
