import {
    Button, Checkbox,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Table, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";

export default function AddTopology({onClose}: any) {

    const [form] = Form.useForm();


    const onFinish = (values: any) => {
        // handleCreate(values)
    };
    //
    // useEffect(() => {
    //     if (isErrorCreate) {
    //         message.error(errorMessage)
    //     }
    // }, [isErrorCreate])
    //
    // useEffect(() => {
    //     if (isSuccessCreate) {
    //         message.success('Вы успешно добавили тип оплаты')
    //         onClose()
    //         form.resetFields()
    //     }
    // }, [isSuccessCreate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавить"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Row
                className={"container"}
            >
                <Col span={24} >
                    <Form layout={"vertical"} onFinish={onFinish} form={form}>
                        <Form.Item
                            label={"Введите номер стеллажа"}
                            name='numberOfShelf'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите номер секции"}
                            name='numberOfSection'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите номер ячейки"}
                            name='shelf'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите описание товара в ячейке"}
                            name='desc'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите артикул китайский товара в ячейке"}
                            name='articleKit'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите артикул поставщика товара в ячейке"}
                            name='articlePost'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите  артикул WB товара в ячейке"}
                            name='articleWB'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>
                        <Form.Item
                            label={"Введите размеры товара в ячейке (вводите через запятую)"}
                            name='size'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'}/>
                        </Form.Item>

                        <Form.Item
                            label={"Выберите Юр.лицо"}
                            name='face'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                // mode={"multiple"}
                                allowClear
                                style={{width: "100%"}}
                                options={[
                                    {value: "1", label: "Юрий"},
                                    {value: "2", label: "Алексей"},
                                    {value: "3", label: "Диана"},
                                ]}
                            />
                        </Form.Item>

                        <Col style={{
                            display: 'flex',
                            gap: 10
                        }}>
                            <Button
                                // loading={createLoading}
                                type={"primary"}
                                className={"button"}
                                htmlType={"submit"}
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Добавить
                            </Button>
                            <Button
                                onClick={() =>{
                                    form.resetFields()
                                    onClose()
                                }}
                                className={"button"}
                                type={"primary"}
                                ghost
                                style={{
                                    fontSize: "12px",
                                    width: "50%",
                                }}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
