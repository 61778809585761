import { Button, Modal, Input, Select, Form, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import useSettingsData from '../hooks/settings/useSettingsData'

export const AddUserModal = ({
	isVisible,
	handleCancel,
	setIsVisible,
}: any) => {
	const { settingsData, setDataType, addUser, usersData } = useSettingsData()
	const [tab, setTab] = useState<'external' | 'internal'>('external')
	const [rawPhone, setRawPhone] = useState('')
	const [role, setRole] = useState<string>('Аналитик')
	const [roles, setRoles] = useState<any[]>([])

	const phoneRegex = /^(\+374\d{8}|\+7\d{10}|\+375\d{9})$/

	useEffect(() => {
		setDataType('wb-user-roles')
		if (settingsData) {
			setRoles(settingsData)
		}
	}, [settingsData])

	const formatPhoneInput = (value: string) => {
		const onlyNumbers = value.replace(/\D/g, '')

		if (onlyNumbers.startsWith('374')) {
			return `+${onlyNumbers.slice(0, 3)} ${onlyNumbers.slice(
				3,
				5
			)} ${onlyNumbers.slice(5, 8)} ${onlyNumbers.slice(8, 11)}`
		} else if (onlyNumbers.startsWith('7') && onlyNumbers.length > 1) {
			return `+7 ${onlyNumbers.slice(1, 4)} ${onlyNumbers.slice(
				4,
				7
			)}-${onlyNumbers.slice(7, 9)}-${onlyNumbers.slice(9, 11)}`
		} else if (onlyNumbers.startsWith('375')) {
			return `+${onlyNumbers.slice(0, 3)} ${onlyNumbers.slice(
				3,
				5
			)} ${onlyNumbers.slice(5, 8)}-${onlyNumbers.slice(
				8,
				10
			)}-${onlyNumbers.slice(10, 12)}`
		}

		return `+${onlyNumbers}`
	}

	const formatAndValidatePhone = (phone: string) => {
		let rawPhone = phone.replace(/\D/g, '')

		if (rawPhone.startsWith('8')) {
			rawPhone = '7' + rawPhone.slice(1)
		}

		const phoneRegex = /^(7\d{10}|374\d{8}|375\d{9})$/
		const isValid = phoneRegex.test(rawPhone)

		return {
			rawPhone,
			isValid,
		}
	}
	const isPhoneValid = phoneRegex.test(rawPhone)

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const rawInput = e.target.value.replace(/\D/g, '')
		setRawPhone(`+${rawInput}`)
		console.log('phone:', rawPhone)
	}

	const handleSave = async () => {
		const phoneToSave = rawPhone
		const roleToSave = role

		if (isPhoneValid && roleToSave) {
			try {
				await addUser({ phone: phoneToSave, position: roleToSave })
				setIsVisible(false)
				setRawPhone('')
				setRole('Аналитик')
				message.success('Пользователь успешно добавлен')
			} catch (error) {
				message.error('Не удалось добавить пользователя')
			}
		} else {
			message.error('Введите корректный номер телефона')
		}
		setTab('external')
	}

	const handleTabSwitch = (newTab: 'external' | 'internal') => {
		setTab(newTab)
		setRawPhone('')
		setRole('Аналитик')
	}

	const handleUserSelect = (userId: string) => {
		const selectedUser = usersData.find((user: any) => user.id === userId)
		if (selectedUser) {
			setRawPhone(`+${formatAndValidatePhone(selectedUser.phone).rawPhone}`)
			setRole(selectedUser.role.name)
			console.log(rawPhone, role)
		}
	}

	const handleCancelAll = () => {
		setTab('external')
		setRawPhone('')
		setRole('Аналитик')
		handleCancel()
	}

	console.log(roles)

	const options = useMemo(
		() =>
			roles.map((r: any) => ({
				label: r.role,
				value: r.role,
			})),
		[roles]
	)

	return (
		<Modal
			visible={isVisible}
			onCancel={handleCancelAll}
			bodyStyle={{ padding: 20 }}
			title={
				<div
					className='storage-tabs'
					style={{ padding: '10px 20px 0 20px', display: 'flex', gap: 10 }}
				>
					<div
						onClick={() => handleTabSwitch('external')}
						className={tab === 'external' ? 'tab-active' : ''}
					>
						Внешний
					</div>
					<div
						onClick={() => handleTabSwitch('internal')}
						className={tab === 'internal' ? 'tab-active' : ''}
					>
						Внутренний
					</div>
				</div>
			}
			footer={[
				<div style={{ padding: 20 }} key='footer'>
					<Button key='back' onClick={handleCancelAll}>
						Отмена
					</Button>
					<Button
						key='submit'
						type='primary'
						onClick={handleSave}
						disabled={!isPhoneValid}
					>
						Сохранить
					</Button>
				</div>,
			]}
		>
			<Form layout='vertical'>
				{tab === 'external' ? (
					<>
						<Form.Item
							label='Номер телефона'
							required
							help={
								rawPhone === ''
									? 'Поле обязательно для заполнения'
									: !isPhoneValid && 'Введите корректный номер телефона'
							}
							validateStatus={!isPhoneValid ? 'error' : undefined}
						>
							<Input
								placeholder='Введите номер телефона'
								value={formatPhoneInput(rawPhone)}
								onChange={handlePhoneChange}
							/>
						</Form.Item>

						<Form.Item label='Роль (обязательно)'>
							<Select
								placeholder='Выберите роль'
								value={role}
								onChange={value => setRole(value)}
								options={options}
							/>
						</Form.Item>
					</>
				) : (
					<Form.Item label='Выберите пользователя'>
						<Select
							placeholder='Выберите пользователя из списка'
							onChange={handleUserSelect}
						>
							{usersData
								.filter((user: any) => user.phone && user.role.name)
								.map((user: any) => (
									<Select.Option key={user.id} value={user.id}>
										{user.firstName} {user.lastName} ({user.role.name}){' '}
										{user.phone.replace(/^\+/, '')}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				)}
			</Form>
		</Modal>
	)
}
