import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useKeyWordAdd = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: createDeclaration,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
    } = useMutation(backend.keyWords.createKeyWord, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`KEY_WORDS_LIST`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any) => {
            try {
                let body = { ...values };
                await createDeclaration(body);
                queryClient.invalidateQueries(['KEY_WORDS_LIST', 1, 1]);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [createDeclaration]
    );

    return {
        isSuccessCreate,
        handleCreate,
        createLoading,
        isErrorCreate,
        errorMessage,
    };
};
