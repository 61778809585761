import { Col, Row } from "antd";
import React from "react";
import DocumentMirrorDocumentsTable from "../../features/document-mirror/DocumentMirrorDocumentsTable";

export default function DocumentMirrorDocuments() {
    return (
        <Row style={{ padding: "10px 0" }}>
            <Col className={"container"} span={24}>
                <DocumentMirrorDocumentsTable />
            </Col>
        </Row>
    );
}
