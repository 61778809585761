import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";

export default function useAnalyticsUserList() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchValue, setSearchValue] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchValue, 1000);

    const { data: analyticUser, ...options } = useQuery(
        [
            "ANALYTIC_LIST",
            currentPage,
            currentPageSize,
            debouncedSearch
        ],
        () => backend.analyticUser.getAnalyticUserData(
            currentPage.toString(),
            currentPageSize,
            debouncedSearch
        ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        analyticUser,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchValue,
        setSearchValue,
        ...options,
    };
}
