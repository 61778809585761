import { useQuery } from "react-query";
import { backend } from "api";

export default function useRoleList() {
    const { data: roles, ...options } = useQuery(
        ["ROLE_LIST"],
        () => backend.role.getRoleList(),
        {
            keepPreviousData: true,
        }
    );

    return {
        roles,
        ...options,
    };
}
