import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useUserCreate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: create,
        isLoading: createLoading,
        isError,
        ...options
    } = useMutation(backend.user.createUser, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`USER_LIST`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any, handleCloseModal: any) => {
            try {
                let body = { ...values };
                body.roleId = parseInt(values.roleId);
                await create(body);
                handleCloseModal();
            } catch (e: any) {
                const errorData = await e.response.data;

                if (errorData?.message){
                    setErrorMessage(errorData?.message)
                }

                if (errorData) {
                    if (
                        errorData.detail[0]?.loc?.length > 1 &&
                        errorData.detail[0]?.loc[1] === "email"
                    ) {
                        setErrorMessage("Пожалуйста, введите корректный Email");
                        return;
                    }
                    if (
                        errorData.detail === "This email address already exists"
                    ) {
                        setErrorMessage(
                            "Пользователь с данным email уже существует"
                        );
                        return;
                    }
                    if (errorData.detail === "This login already exists") {
                        setErrorMessage(
                            "Пользователь с данным логином уже существует"
                        );
                        return;
                    }
                }

                setErrorMessage("Произошла ошибка при создании пользователя");
            }
        },
        [create]
    );

    return {
        handleCreate,
        isLoading: createLoading,
        isError,
        errorMessage,
        ...options
    };
};
