import React, {useEffect, useState} from "react";
import {
  AutoComplete,
  Button, Checkbox,
  Col, DatePicker,
  Dropdown,
  Input,
  Menu, MenuProps, message, Modal,
  Pagination,
  Radio,
  Row,
  Select, Table,
} from "antd";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as headerPrevBtn} from "../../../assets/images/header-prev-btn.svg";
import {ReactComponent as rangePickerArrow} from "../../../assets/images/rangepicker-arrow.svg";

const {RangePicker} = DatePicker;

export default function AdsSelectionFullInfo() {

  const navigate = useNavigate()

  const data = [
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
    {
      name1: 'test',
      name2: 'test',
      name3: 'test',
    },
  ]
  const columns = [
    {
      title: "Sku",
      dataIndex: "name3",
      key: "name3",
      width: "30%",
      render: (text: any, record: any) => {
        return (
          <div>
            {text}
          </div>
        )
      }
    },
    {
      title: "Дата последней рекламной затраты",
      dataIndex: "name3",
      key: "name3",
      width: "30%",
      render: (text: any, record: any) => {
        return (
          <div>
            {text}
          </div>
        )
      }
    },
    {
      title: "Всего затрат",
      dataIndex: "name3",
      key: "name3",
      width: "30%",
      render: (text: any, record: any) => {
        return (
          <div>
            {text}
          </div>
        )
      }
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (text: any, record: any) => {
        return (
          <Button
            type={'primary'}
            style={{
              margin: 10,
            }}
          >
            Вызгрузить
          </Button>
        )
      }
    },
  ];


  return (
    <div style={{backgroundColor: '#f8f8f8'}}>
      <Col span={24}>
        {" "}
        <div
          className={"back-button-header"}
          onClick={() => navigate(-1)}
        >
          <Icon component={headerPrevBtn}/>
          Назад
        </div>
      </Col>
      <Row style={{
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Col span={7}>
          <RangePicker
            style={{width: '350px'}}
            // onChange={onChangeDateCreate}
            showTime={{format: 'HH-mm-ss'}}
            placeholder={["Дата от", "Дата до"]}
            separator={
              <Icon component={rangePickerArrow}/>
            }
            format={"DD.MM.YYYY HH:mm:ss"}
          />
        </Col>
      </Row>

      <Row className={"container"}>

        <Col span={24}>
          <Table
            // loading={isLoading}
            className="product-arrival-table"
            columns={columns}
            // dataSource={storageData?.data?.map((item: any, index: any) => ({
            //     ...item,
            //     key: index,
            // }))}
            dataSource={data}
            scroll={{x: true}}
            pagination={{
              // onChange: (page, pageSize) => setCurrentPage(page),
              position: ["bottomCenter"],
              // pageSize: Number(currentPageSize),
              // total: Number(emissionsById?.total),
              showSizeChanger: false,
              // current: currentPage,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
