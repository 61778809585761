import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useState} from "react";

export default function useDeclarationDataBuIdOld() {
    const [id, setId] = useState<string>('');

    const { data: declaration, ...options } = useQuery(
        ["DECLARATION_DATA_BY_ID", id],
        () =>
            backend.declaration.getDeclarationsDataByIdOld(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        declaration,
        setId,
        ...options,
    };
}
