import {
    Button,
    Col,
    Form,
    Input,
    message,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect} from "react";
import {useAdLeftMoney} from "../hooks/useAdLeftMoney";

export default function AdLeftMoneyModal({
                                           productId,
                                           onClose,
                                       }: any) {


    const [form] = Form.useForm<{}>();

    const {
        handleCreate,
        isSuccess,
        isError,
        errorMessage,
        isLoading
    } = useAdLeftMoney()

    const onFinish = (value: any) => {

        console.log({...value, productId: productId})
        handleCreate({...value, productId: productId})
    }

    useEffect(() => {
        if (isSuccess) {
            message.success('Вы успешно добавили новую себестоимость')
            form.resetFields()
            onClose()
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
    }, [isError])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Добавление средств в рекламный бюджет"} onClose={() =>{
                form.resetFields()
                onClose()
            }}/>
            <Form
                onFinish={(values) => onFinish(values)}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    rules={[{required: true}]}
                    name={"sum"}
                    label={"Введите сумму, которую необходимо добавить"}
                >
                    <Input type={"number"}/>
                </Form.Item>

                <Col style={{display: "flex", gap: "15px"}}>
                    <Button
                        type={"primary"}
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        htmlType={"submit"}
                        loading={isLoading}
                    >
                        Добавить
                    </Button>
                    <Button
                        type={"primary"}
                        ghost
                        className={"button"}
                        style={{fontSize: "12px", width: "50%"}}
                        onClick={() =>{
                            form.resetFields()
                            onClose()
                        }}
                    >
                        Отмена
                    </Button>
                </Col>
            </Form>
        </div>
    );
}
