import {Col, Row} from "antd";
import React from "react";
import OrdersFromChinaTable from "../../features/ordersFromChina/OrdersFromChinaTable";

export default function OrdersFromChinaTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col span={24}>
                <OrdersFromChinaTable/>
            </Col>
        </Row>
    );
}
