import React, { useState } from "react";
import { Col, Row } from "antd";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export default function LaboratoryTVTPage() {

  const location = useLocation();
  const navigate = useNavigate()

    return (
      <Row>
        <Col style={{padding: "10px 20px"}} span={24}>
          {
            !location.pathname.includes('full-info') &&
              <div className={"storage-tabs"}>
                  <div
                      className={`${
                        location.pathname === '/laboratory-tvt' ? "tab-active" : ""
                      }`}
                      onClick={() => navigate('/laboratory-tvt')}
                  >
                      Текущие
                  </div>
                  <div
                      className={`${
                        location.pathname === '/laboratory-tvt/results' ? "tab-active" : ""
                      }`}
                      onClick={() => navigate('/laboratory-tvt/results')}
                  >
                      Результаты
                  </div>

              </div>
          }
          <Row>
            <Col span={24}>
              <Outlet/>
            </Col>
          </Row>
        </Col>
      </Row>
    );
}
