import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useState} from "react";

export default function useCoordinatorUserDataById() {
    const [id, setId] = useState<string>('');

    const { data: coordinatorUser, ...options } = useQuery(
        ["COORDINATOR_USER_DATA_BY_ID", id],
        () =>
            backend.coordinatorUser.getCoordinatorUserDataById(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        coordinatorUser,
        setId,
        ...options,
    };
}
