import {Button, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as filterSortIcon} from "assets/images/filter-sort-icon.svg";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import React from "react";

export default function useDeclarationTableFilter() {
    const filterMenuItems: MenuProps["items"] = [
        {
            label: "По названию",
            key: "name",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "name:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "name:desc",
                },
            ],
        },
        {
            label: "По имени продукта",
            key: "date",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "date:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "date:desc",
                },
            ],
        },
        {
            label: "По артикулу",
            key: "sum",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от А до Я",
                    key: "sum:asc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сортировка от Я до А",
                    key: "sum:desc",
                },
            ],
        },
        {
            label: "По отсканированию",
            key: "has_added",
            children: [
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сперва отсканированные",
                    key: "has_added:desc",
                },
                {
                    icon: <Icon component={filterSortIcon}/>,
                    label: "Сперва не отсканированные",
                    key: "has_added:asc",
                },
            ],
        },
    ];

    return {filterMenuItems};
}
