import { backend } from "api";
import { useQuery } from "react-query";

export function useWhoAmi() {
    return useQuery(["CHECK_AUTH"], () => backend.auth.checkAuth(), {
        enabled: false,
        retry: false,
        keepPreviousData: true,
    });
}
