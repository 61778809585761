import { Col, Row } from "antd";
import AnalyticsTable from "features/analytics/pages/AnalyticsTable";
import FinancialResultsTable from "../../features/financial-results/FinancialResultsTable";
import React from "react";
import {InnovationProposalsBlock} from "../../features/innovationProposals/innovationProposalsBlock";

export default function InnovationProposalsPage() {
    return (
        <Row style={{ padding: "10px 20px" }} className='finance-page'>
            <Col className={"container finance-page"} span={24}>
                <InnovationProposalsBlock/>
            </Col>
        </Row>
    );
}
