import {useQuery} from "react-query";
import {backend} from "api";

export default function useInfoProductByArticleAndSize(article: any, size: any) {

    const {data: productDataByArticleAndSize, ...options} = useQuery(
        [
            "PRODUCT_DATA_BY_ARTICLE_AND_SIZE_FOR_GTD",
            size,
            article,
        ],
        () =>
            backend.storage.getInfoProductByArticleAndSize(
                size,
                article,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        productDataByArticleAndSize,
        ...options,
    };
}
