import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Input,
    Select,
    Button,
    Table,
    Dropdown,
    Checkbox,
    DatePicker,
    Modal, Pagination, message, Spin,
} from "antd";
import { ReactComponent as SelectIconComponent } from "../../../../assets/images/select-icon.svg";
import useIssuingPositionsData from "../../hooks/issuingPositions/useIssuingPositionsData";
import Icon, { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import IssuingPositionsItem from "../../components/issuingPositions/issuingPositionsItem";
import { ReactComponent as rangePickerArrow } from "../../../../assets/images/rangepicker-arrow.svg";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function IssuingPositionsTable() {

    const {
        data,
        currentPage,
        setCurrentPage,
        currentPageSize,
        dateStart,
        dateEnd,
        setCurrentPageSize,
        selectedSeller,
        handleRangeChangeTable,
        getProductsFilterItems,
        sellers,
        setSearchSellers,
        setSelectedSeller,
        type,
        setType,
        searchArticle,
        setSearchArticle,
        isWithElementsInTable,
        setIsWithElementsInTable,
        setIsPlusMinusPhraseMode,
        isLoading
    } = useIssuingPositionsData()

    const [dataToShow, setDataToShow] = useState<any>([])

    const handleShowMore = (id: any) => {
        let map = dataToShow?.map((item: any) => item?.productId === id ? ({
            ...item,
            isShowMore: !item?.isShowMore
        }) : item);
        setDataToShow(map)
    }

    const mapData = (dataToMap: any) => {
        return dataToMap?.map((item: any) => ({ ...item, isShowMore: false }))
    }

    const renderData = () => {
        if (isLoading) {
            return (
                <div style={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    padding: 200
                }}>
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                    />
                </div>
            )
        }
        if (data?.data?.length === 0 || !data?.data || dataToShow?.length === 0) {
            return (
                <div style={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    padding: 200
                }}>
                    Нет данных
                </div>
            )
        }
        if (data?.data?.length !== 0 && dataToShow?.length !== 0) {
            return (
                <div>
                    <div className="issuing-positions-wrap">
                        {
                            dataToShow?.map((item: any) =>
                                <IssuingPositionsItem
                                    item={item}
                                    handleShowMore={handleShowMore}
                                />
                            )
                        }
                    </div>
                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={Number(currentPageSize)}
                        total={data?.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </div>

            )
        }
    }

    useEffect(() => {
        if (data?.data?.length !== 0) {
            setDataToShow(mapData(data?.data))
        }
    }, [data?.data])

    useEffect(() =>{
        setIsPlusMinusPhraseMode(false)
    },[])

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{ flexGrow: "1" }}>
                    <Input
                        value={searchArticle}
                        onChange={(text: any) => setSearchArticle(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск по артикулу"
                        size="large"
                    />
                </Col>

                <Col style={{ width: "250px" }}>
                    <Select
                        className="table-header-select"
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={selectedSeller}
                        placeholder="Продавец"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{ marginTop: "2px", fontSize: "10px" }}
                        />}
                        onSearch={(value: any) => setSearchSellers(value)}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.map((option: any) => {
                            return (
                                <Option key={option?.wb_article} value={option?.id}>
                                    {option.company_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>

                <Col style={{ width: "200px" }}>
                    <Select
                        filterOption={false}
                        className="table-header-select"
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{ marginTop: "2px", fontSize: "10px" }}
                        />}
                        placeholder={'Тип'}
                        style={{
                            width: '100%'
                        }}
                        value={type}
                        onChange={(e: any) => setType(e)}
                        options={[
                            {
                                value: "all",
                                label: "Все",
                            },
                            {
                                value: "ads",
                                label: "Рекламируются",
                            },
                        ]}
                    />
                </Col>

                <Col style={{ width: "200px" }}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{ items: getProductsFilterItems() }}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Предмет</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{ marginTop: "2px", fontSize: "10px" }}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{ width: "200px" }}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent} />}
                        defaultValue={"10"}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(Number(e));
                        }}
                        options={[
                            { value: "10", label: "Показать по 10 шт." },
                            { value: "50", label: "Показать по 50 шт." },
                            { value: "100", label: "Показать по 100 шт." },
                        ]}
                    />
                </Col>
                <Col style={{ width: "200px" }}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        suffixIcon={<Icon component={SelectIconComponent} />}
                        defaultValue={isWithElementsInTable}
                        onChange={(e) => {
                            setIsWithElementsInTable(e);
                        }}
                        options={[
                            { value: true, label: "Показать с данными в таблице" },
                            { value: false, label: "Показать все" },
                        ]}
                    />
                </Col>

                <Col style={{ width: "300px" }}>
                    <RangePicker
                        onChange={handleRangeChangeTable}
                        value={[dateStart, dateEnd]}
                        style={{
                            backgroundColor: '#fff',
                            height: 38,
                            borderRadius: 0,
                            borderColor: '#fff'
                        }}
                        showTime={{ format: 'HH:mm' }}
                        format="DD.MM.YYYY HH:mm"
                        placeholder={["Начало", "Конец"]}
                        separator={<Icon component={rangePickerArrow} />}
                    />
                    {/*<DatePickerCustomed*/}
                    {/*    pickerOpen={pickerOpen}*/}
                    {/*    setPickerOpen={setPickerOpen}*/}
                    {/*    dateStart={dateStart}*/}
                    {/*    dateEnd={dateEnd}*/}
                    {/*    handleRangeChangeTable={handleRangeChangeTable}*/}
                    {/*/>*/}
                </Col>
            </Row>

            {renderData()}

        </div>
    );
}
