import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Input,
    MenuProps,
    message,
    Pagination,
    Radio,
    Row,
    Select, Spin
} from "antd";
import selectIcon, {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import DocumentItem from "./components/DocumentItem";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import useDocumentsData from "./hooks/documents/useDocumentsData";
import {ReactComponent as rangePickerArrow} from "../../assets/images/rangepicker-arrow.svg";
import useDocumentsCategoriesData from "./hooks/documents/useDocumentsCategoriesData";
import {downloadFinRecordFile, downloadFinRecordFileWithoutNot} from "../../api/documentMirror";

const {RangePicker} = DatePicker;
const {Option} = Select;

const DocumentMirrorDocumentsTable = () => {

    const [isCheckedAll, setIsCheckedAll] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [data, setData] = useState<any[]>([])

    const {
        documentsData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        onChangeData,
        selectedSeller,
        setSelectedSeller,
        sellers,
        search,
        setSearch,
        selectedCategories,
        setSelectedCategories,
        isLoading
    } = useDocumentsData()

    const {
        documentsCategoriesData,
    } = useDocumentsCategoriesData()

    const modifyData = (data: any) => {
        return data?.map((item: any) => ({...item, isChecked: false}))
    }

    const onCheck = (id: any) => {
        let map = data?.map((item: any) => item?.id === id ? ({...item, isChecked: !item?.isChecked}) : item);
        setData(map)
    }

    const handleSelectAll = () => {
        let some = data?.some((item: any) => item?.isChecked === false)

        if (some) {
            setData(data?.map((item: any) => ({...item, isChecked: true})))
            setIsCheckedAll(true)

        } else {
            setData(data?.map((item: any) => ({...item, isChecked: false})))
            setIsCheckedAll(false)
        }
    }

    useEffect(() => {
        setData(modifyData(documentsData?.data))
    }, [documentsData])

    useEffect(() => {
        if (data?.every((item: any) => item?.isChecked === false)) {
            setIsCheckedAll(false)
            setIndeterminate(false)
        } else if (data?.every((item: any) => item?.isChecked === true)) {
            setIsCheckedAll(true)
            setIndeterminate(false)
        } else {
            setIsCheckedAll(false)
            setIndeterminate(true)
        }
    }, [data])

    const onDownloadCheckedDocs = async () => {
        await message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

        const listOfIds = await data?.filter((item: any) => item?.isChecked)?.map((item: any) => item?.id)
        await listOfIds.forEach((item: any) =>
            downloadFinRecordFileWithoutNot(`${process.env.REACT_APP_API_URL}documents/file/${item}`, item)
        )
    }

    return (
        <div style={{backgroundColor: '#f8f8f8'}}>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        value={search}
                        onChange={(text: any) => setSearch(text.target.value)}
                        className="table-header-input"
                        placeholder="Поиск"
                        size="large"
                    />
                </Col>
                <Col>
                    <RangePicker
                        onChange={onChangeData}
                        placeholder={["Начало", "Конец"]}
                        separator={
                            <Icon component={rangePickerArrow}/>
                        }
                        format={"DD.MM.YYYY"}
                        style={{
                            backgroundColor: '#fff',
                            height: 38,
                            borderRadius: 0,
                            borderColor: '#fff'
                        }}
                    />
                </Col>
                <Col>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        style={{
                            width: 250
                        }}
                        value={selectedCategories}
                        placeholder={'Выберите тип документа'}
                        filterOption={false}
                        suffixIcon={<img src={selectIcon} alt={""}/>}
                        onChange={(e: any, option: any) => {
                            setSelectedCategories(e)
                        }}
                    >
                        <Option key={''} value={'all'}>
                            Все
                        </Option>
                        {documentsCategoriesData?.map((option: any) => {
                            return (
                                <Option key={option?.id} value={option?.id}>
                                    {option.title}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
                <Col style={{width: "300px", paddingTop: 0, paddingBottom: 0}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        style={{
                            width: '100%',
                        }}
                        defaultValue={sellers?.data?.[0]?.extRef}
                        value={selectedSeller}
                        placeholder="Продавец"
                        filterOption={false}
                        suffixIcon={<Icon
                            component={SelectIconComponent}
                            style={{marginTop: "2px", fontSize: "10px"}}
                        />}
                        onChange={(e: any, option: any) => {
                            setSelectedSeller(e)
                        }}
                    >
                        {sellers?.data?.map((option: any) => {
                            return (
                                <Option key={option?.wbArticle} value={option?.extRef}>
                                    {option.companyName}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
                <Col>
                    <Button
                        disabled={data?.every((item: any) => item?.isChecked === false)}
                        onClick={onDownloadCheckedDocs}
                        className="table-header-button"
                        type={"primary"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Скачать выбранные документы
                    </Button>
                </Col>
            </Row>
            <Row className={"document-help-text"}>
                Обращаем ваше внимание, документы указанные в отчетах о продаж формируются до 3-х рабочих дней.
            </Row>

            <Row className={"container"} style={{marginTop: "10px", padding: '20px 10px'}}>
                {isLoading
                    ?
                    <div
                        style={{
                            margin: '0 auto',
                            padding: "150px",
                            textAlign: "center",
                        }}
                    >
                        <Spin
                            indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                        />
                    </div>
                    :
                    documentsData?.data?.length === 0 || data?.length === 0
                        ? <div style={{margin: '0 auto', padding: 150}}>Нет данных</div>
                        :
                        <>
                            <Col span={24}>
                                <div className="document-page-title-wrap">
                                    <div className="document-page-title-elem" style={{width: 200, minWidth: 200}}>
                                        <Checkbox
                                            indeterminate={indeterminate}
                                            style={{marginRight: 10}}
                                            onChange={handleSelectAll}
                                            checked={isCheckedAll}
                                        />
                                        Тип документа
                                    </div>
                                    <div className="document-page-title-elem" style={{width: 350, minWidth: 350}}>
                                        Наименование
                                    </div>
                                    <div className="document-page-title-elem" style={{width: 150, minWidth: 150}}>
                                        Номер документа
                                    </div>
                                    <div className="document-page-title-elem" style={{width: 150, minWidth: 150}}>
                                        Дата создания
                                    </div>
                                    <div className="document-page-title-elem" style={{width: '100%'}}/>
                                </div>
                                <div className="document-page-items">
                                    {
                                        data?.map((item: any) =>
                                            <DocumentItem item={item} onCheck={onCheck}/>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col span={24}>
                                <Pagination
                                    onChange={(page, pageSize): any => setCurrentPage(page)}
                                    defaultCurrent={1}
                                    current={currentPage}
                                    pageSize={parseInt(currentPageSize)}
                                    total={parseInt(documentsData?.total || 0)}
                                    style={{
                                        textAlign: "center",
                                        padding: "16px 0",
                                    }}
                                    showSizeChanger={false}
                                />
                            </Col>
                        </>
                }
            </Row>
        </div>
    );
};

export default DocumentMirrorDocumentsTable;