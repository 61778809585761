import React from 'react';
import {Col, Row} from "antd";
import AdsSelectionTablet from "../../features/adsSelection/pages/AdsSelectionTablet";

const AdsSelectionPage = () => {
  return (
    <Row style={{ padding: "10px 20px" }}>
      <Col className={"container"} span={24}>
        <AdsSelectionTablet />
      </Col>
    </Row>
  );
};

export default AdsSelectionPage;