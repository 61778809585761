import React from 'react';
import circleIcon from "../../../assets/images/gtd-circle-icon.svg";
import plusIcon from "../../../assets/images/bluePlus.svg";

const AddTsdFileButton = ({handleFileSelect, fileInputRef, handleFileChange, file, title = 'Файл ГТД'}: any) => {
    return (
        <div
            className="add-tsd"
            onClick={handleFileSelect}
        >
            {
                file?.name
                    ?
                    <div className="add-tsd-file-name">
                        {file?.name}
                        <div style={{display: 'flex', gap: 5}}>
                            <img src={circleIcon} alt=""/>
                            Заменить
                        </div>
                    </div>
                    :
                    <div style={{display: "flex", alignItems: 'center', gap: 9}}>
                        <img src={plusIcon} alt=""/>
                        {title}
                    </div>
            }
            <input
                type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
        </div>
    );
};

export default AddTsdFileButton;