import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const useChangeStatusOfIssuingPositions = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: onChange,
        isSuccess: isSuccessChange,
        isLoading: changeLoading,
        isError: isErrorChange,
    } = useMutation(backend.advertisingMirror.changeStatusOfProduct, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`ISSUING_POSITIONS_LIST`])]);
        },
    });

    const handleCreate = useCallback(
        async (data: any) => {
            try {
                await onChange(data);
                queryClient.invalidateQueries(['ISSUING_POSITIONS_LIST']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [onChange]
    );

    return {
        isSuccessChange,
        handleCreate,
        isErrorChange,
        changeLoading,
        errorMessage,
    };
};
