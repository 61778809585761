import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useState} from "react";

export default function useDeclarationCustomedFilesById() {
    const [id, setId] = useState<string>('');

    const { data: files, ...options } = useQuery(
        ["DECLARATION_CUSTOMED_FILES_DATA_BY_ID", id],
        () =>
            backend.declaration.getDeclarationsCustomedFilesDataById(
                id.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        files,
        setId,
        ...options,
    };
}
