import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../../../api";
import {useCallback, useState} from "react";

export default function useAdvertisingMirrorDataCount() {

    const [selectedSeller, setSelectedSeller] = useState<any>(null);


    const { data: data, ...options } = useQuery(
        [
            "ADVERTISING_MIRROR_DATA_COUNT",
            selectedSeller
        ],
        () =>
            backend.advertisingMirror.getAdvertisingMirrorDataCount(
                selectedSeller
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        allCount: data?.all,
        adverts: data?.adverts,
        selectedSeller,
        setSelectedSeller,
        ...options,
    };
}
