export const getTypeText = (type: string): string => {
    let typeText = "";
    if (type.toString() === "4") {
        typeText = "в каталоге";
    } else if (type.toString() === "5") {
        typeText = "в карточке товара";
    } else if (type.toString() === "6") {
        typeText = "в поиске";
    } else if (type.toString() === "7") {
        typeText = "В рекомендациях на главной странице";
    }
    return typeText;
};
export const getStatusText = (status: string): string => {
    let typeText = "";
    if (status.toString() === "7") {
        typeText = "Завершенный";
    } else if (status.toString() === "9") {
        typeText = "Активный";
    } else if (status.toString() === "11") {
        typeText = "Приостановленный";
    }
    return typeText;
};

export const getBackground = (status: string) => {
    if (status.toString() === "7") {
        return "#fbe0e0";
    }
    if (status.toString() === "9") {
        return "#d8f5e5";
    }
    if (status.toString() === "11") {
        return "#d8e7f5";
    }
    return "#E9EAE5";
};

export const getAllCountPage = ({adverts, selectedType, selectedStatus}: any) => {
    return adverts?.find((item: any) => item.type === selectedType && item.status === selectedStatus)?.count;
};
