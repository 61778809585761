import React, {useState} from 'react';
import {Button, Col, Modal, Row, Table} from "antd";
import burgerIcon from '../../../../assets/images/burgerIcon.svg'
import {useNavigate} from "react-router-dom";
import AddDeclarationModal from "../../modal/declaration/AddDeclarationModal";
import SendToTSDModal from "../../modal/listOfAcceptanceFiles/SendToTSDModal";

const StorekeepersTablet = ({tab, setTab}: any) => {

    const navigate = useNavigate();

    const data = [
        {
            id: '1',
            name: 'test',
            status: 'Устройство 1',
        },
        {
            id: '2',
            name: 'test',
            status: 'Устройство 1',
        },
        {
            id: '3',
            name: 'test',
            status: 'Устройство 1',
        },
        {
            id: '4',
            name: 'test',
            status: 'Устройство 1',
        },
    ]

    return (
        <div>
            <Row
                className={"container"}
                style={{marginTop: "20px", minHeight: "800px"}}
            >
                <Col span={24}>
                    <div className="saved-invoices-wrap">
                        {
                            data.map((item: any) =>
                                <div className="saved-invoices-item">
                                    <div
                                        onClick={() => navigate('/storage/storekeepers/full-info/test')}
                                        className="saved-invoices-item-left"
                                    >
                                        <img src={burgerIcon} alt=""/>
                                        <div className="saved-invoices-item-title">
                                            {item.name}
                                        </div>
                                        <div className="saved-invoices-item-title">
                                            {item.status}
                                        </div>
                                    </div>
                                    <div className="saved-invoices-item-right">
                                        <Button
                                            className={"button"}
                                            type={"primary"}
                                            style={{width: "222px"}}
                                        >
                                            Кол-во задач: 120
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default StorekeepersTablet;