import {api} from "../index";

export async function getShipmentsData(
  page?: string,
  pageSize?: string,
  searchText?: string,
  sizes?: string[],
  products?: string[],
  selectedSeller?: string[],
  selectedWarehouse?: string[],
  selectedPeriod?: string,
  withDetails?: string
) {
  let params = new URLSearchParams();
  if (pageSize) {
    params.append("limit", pageSize);
  }
  if (page) {
    params.append("page", page);
  }
  if (selectedPeriod) {
    params.append("period", selectedPeriod);
  }
  if (sizes && sizes.length > 0) {
    params.append("sizes", sizes?.map((item: any) => item?.title).join(","));
  }
  if (products && products.length > 0) {
    params.append("categories", products?.map((item: any) => item?.id)?.join(","));
  }
  if (selectedSeller && selectedSeller.length > 0) {
    params.append("sellers", selectedSeller?.map((item: any) => item?.id)?.join(","));
  }
  if (selectedWarehouse && selectedWarehouse.length > 0) {
    params.append("warehouses", selectedWarehouse?.map((item: any) => item?.id)?.join(","));
  }

  if (searchText) {
    params.append("search", searchText);
  }

  if (withDetails !== 'all') {
    params.append("withDetails", String(withDetails));
  }

  const response = await api.get<ApiResponseList<any>>(`/otgruzki`, {
    params,
  });
  return response?.data?.data;
}

export async function getWarehouses(
  search?: string,
) {
  let params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }

  const response = await api.get<any>(`/otgruzki/warehouses`, {params});
  return response.data.data;
}

export async function updateNeeds(data: any) {

  const {json, id} = data

  const response = await api.patch<any>(`/otgruzki/need/${id}`, json);
  return response.data;
}
