import {api, apiKiz} from "index";

export async function getKeyWordsData(
    searchValue?: string,
    page?: string,
    pageSize?: string,
) {
    let params = new URLSearchParams();

    if (searchValue) {
        params.append("search", searchValue);
    }
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    const response = await api.get<any>(`/keywords`, {
        params,
    });

    return response.data.data;
}

export async function getKeyWordsGroupData(
    searchValue?: string,
    page?: string,
    pageSize?: string,
) {
    let params = new URLSearchParams();

    if (searchValue) {
        params.append("search", searchValue);
    }
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    const response = await api.get<any>(`/keywords/groups`, {
        params,
    });

    return response.data.data;
}

export async function getKeyWordDataById(
    id: string,
) {
    const response = await api.get(`/keywords/${id}`);
    return response.data.data;
}

export async function getKeyWordGroupDataById(
    id: string,
) {
    if (id) {
        const response = await api.get(`keywords/list?group_id=${id}`);
        return response.data.data;
    }
}

export async function getBiderDataById(
    id: string,
) {
    const response = await api.get(`/bider/${id}`);
    return response.data.data;
}

export async function getPlusMinusPhraseData(
    id: string,
) {
    if (id) {
        const response = await api.get(`/keywordsV2/product/${id}/phrases`);
        return response.data.data;
    }
}

export async function createKeyWord(json: Declaration) {
    const response = await api.post<any>(`/keywords`, json);
    return response.data;
}

export async function createKeyWordGroup(json: any) {
    const response = await api.post<any>(`/keywords/group`, json);
    return response.data;
}

export async function updateKeyWord(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<Seller>(`/keywords/${params.id}`, body);
    return response.data;
}

export async function updateKeyWordGroup(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(`/keywords/group/${params.id}`, body);
    return response.data;
}

export async function updateBiderById(params: any) {
    const {id, ...body} = params;
    const response = await api.put<any>(`/bider/${params.id}`, body);
    return response.data;
}

export async function deleteKeyWord(id: string) {
    const response = await api.delete(`/keywords/${id}`);
    return response.data;
}

export async function deleteKeyWordGroup(id: string) {
    const response = await api.delete(`/keywords/group/${id}`);
    return response.data;
}