import ModalHeader from "features/shared/ModalHeader";
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useRef,
    useState
} from "react";
import {Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, message, Row, Spin, Tooltip} from "antd";
import useProductCoefficientsDataById from "../hooks/useProductCoefficientsDataById";
import {useProductCoefficientsUpdateById} from "../hooks/useProductCoefficientsUpdateById";
import {LoadingOutlined} from "@ant-design/icons";

export default function EditCoefficientsByMonth({onClose, productId, productModal}: any) {

    const [form] = Form.useForm();

    const {
        coef,
        setId,
        isLoading: isLoadingCoef,
        isError: isErrorGet
    } = useProductCoefficientsDataById()

    const {
        isError,
        handleUpdate,
        errorMessage,
        isLoading,
        isSuccess,
    } = useProductCoefficientsUpdateById()

    const onFinishUpdate = (values: any) => {
        console.log(values)
        handleUpdate(values, productId)
    };

    useEffect(() => {
        setId(productId)
    }, [productId])

    useEffect(() => {
        setId(productId)
    }, [])

    useEffect(() => {
        if (isSuccess) {
            message.success('Коэффициенты успешно обновлены')
        }
    }, [isSuccess])

    useEffect(() => {
        if (isErrorGet){
            form.setFieldsValue({
                coef1: 0,
                coef2: 0,
                coef3: 0,
                coef4: 0,
                coef5: 0,
                coef6: 0,
                coef7: 0,
                coef8: 0,
                coef9: 0,
                coef10: 0,
                coef11: 0,
                coef12: 0,
            })
        } else {
            form.setFieldsValue({
                coef1: coef?.coef1 || 0,
                coef2: coef?.coef2 || 0,
                coef3: coef?.coef3 || 0,
                coef4: coef?.coef4 || 0,
                coef5: coef?.coef5 || 0,
                coef6: coef?.coef6 || 0,
                coef7: coef?.coef7 || 0,
                coef8: coef?.coef8 || 0,
                coef9: coef?.coef9 || 0,
                coef10: coef?.coef10 || 0,
                coef11: coef?.coef11 || 0,
                coef12: coef?.coef12 || 0,
            })
        }

    }, [productId, coef])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Редактировать коэффициенты по месяцам"}
                onClose={onClose}
            />

            {
                isLoadingCoef
                    ?
                    <div
                        style={{
                            padding: "150px",
                            textAlign: "center",
                        }}
                    >
                        <Spin
                            indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                        />
                    </div>
                    :
                    <div className={"send-article-wrapper"}>
                        <Form
                            form={form}
                            layout={"vertical"}
                            onFinish={onFinishUpdate}
                        >
                            <Form.Item
                                label={<div><span>Январь</span></div>}
                                name="coef1"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Февраль</span></div>}
                                name="coef2"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Март</span></div>}
                                name="coef3"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Апрель</span></div>}
                                name="coef4"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Май</span></div>}
                                name="coef5"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Июнь</span></div>}
                                name="coef6"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Июль</span></div>}
                                name="coef7"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Август</span></div>}
                                name="coef8"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Сентябрь</span></div>}
                                name="coef9"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Октябрь</span></div>}
                                name="coef10"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Ноябрь</span></div>}
                                name="coef11"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div><span>Декабрь</span></div>}
                                name="coef12"
                                rules={[{required: true, message: "Поле не должно быть пустым",},]}
                            >
                                <Input
                                    type={'number'}
                                />
                            </Form.Item>
                            {isError && (
                                <div
                                    className={"ant-form-item-explain-error"}
                                    style={{color: "#ff4d4f"}}
                                >
                                    {errorMessage}
                                </div>
                            )}
                            <div className="products-edit-buttons-wrap">
                                <Button
                                    loading={isLoading}
                                    // disabled={selectedAnalyticUser.length <= 0}
                                    className="products-edit-button-save"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    htmlType={"submit"}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    className="products-edit-button-exit"
                                    type={"primary"}
                                    style={{width: "222px", fontSize: "12px"}}
                                    onClick={onClose}
                                >
                                    Отмена
                                </Button>
                            </div>
                        </Form>

                    </div>

            }
        </div>
    );
}
