import {Button, Col, Row, Table, DatePicker, Radio, Form, message} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {useChangeStatusOfIssuingPositions} from "../../hooks/issuingPositions/useChangeStatusOfIssuingPositions";

export default function AddToAdvertisementModal({
                                                    onClose,
                                                    id,
                                                }: any) {

    const [form] = Form.useForm();

    const {
        isSuccessChange,
        handleCreate,
        isErrorChange,
        changeLoading,
        errorMessage,
    } = useChangeStatusOfIssuingPositions()

    const onFinish = (values: any) => {

        const data = {
            id,
            inAds: true,
            datetime: values?.date
        }

        handleCreate(data)
    }

    useEffect(() =>{
        if(isErrorChange){
            message.error(errorMessage)
        }
        if(isSuccessChange){
            message.success('')
            onClose()
        }
    },[isSuccessChange,isErrorChange])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={`Добавление в рекламу`}
                onClose={onClose}
            />

            <Form
                layout={"vertical"}
                form={form}
                onFinish={(values) => onFinish(values)}
            >
                <Form.Item
                    name="date"
                    label="Выберите начало старта рекламы"
                    rules={[{required: true}]}
                >
                    <DatePicker
                        showTime
                        placeholder={""}
                        format={"DD.MM.YYYY HH:mm"}
                        style={{width: "100%"}}
                    />
                </Form.Item>

                <div className={"keyword-select-buttons-container"} style={{marginTop: 20}}>
                    <Button
                        loading={changeLoading}
                        className={"button"}
                        style={{fontSize: "12px", width: '50%'}}
                        type={"primary"}
                        htmlType={"submit"}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        className={"button"}
                        style={{fontSize: "12px", width: '50%'}}
                        type={"primary"}
                        ghost
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </div>
            </Form>


        </div>
    );
}
