import { Col, Pagination, Row, Spin } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as filterIcon } from "assets/images/filter-icon.svg";
import CashbackInfoTableRow from "./CashbackInfoTableRow";
import useCashbackActions from "../hooks/useCashbackActionInfo";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

export default function CashbackInfoTable() {

    const { id } = useParams();
    const {
        cashbackActions,
        isLoading,
        currentPage,
        setCurrentPage,
        currentPageSize
    } = useCashbackActions(id);

    const renderCashbackInfoTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                    />
                </div>
            );
        }

        if (cashbackActions?.data && cashbackActions.data.length > 0) {
            return (
                <>
                    {cashbackActions.data.map(
                        (cashbackAction: CashbackContract, index: number) => (
                            <div style={{ marginTop: index > 0 ? 10 : 0 }}>
                                <CashbackInfoTableRow data={cashbackAction} />
                            </div>
                        )
                    )}
                    <Pagination
                        defaultCurrent={1}
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        current={currentPage}
                        showSizeChanger={false}
                        total={cashbackActions.total}
                        pageSize={Number(currentPageSize)}
                        style={{ textAlign: "center", padding: "16px 0" }}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    return (
        <div className={"container"} style={{ marginTop: "20px" }}>
            <Row justify={"end"} style={{ padding: "9px 40px" }}>
                <Col>
                    <div style={{ cursor: "pointer" }}>
                        <Icon component={filterIcon} />
                        <span
                            style={{
                                color: "#82868B",
                                marginLeft: "6px",
                            }}
                        >
                            Фильтры
                        </span>
                    </div>
                </Col>
            </Row>
            {renderCashbackInfoTable()}
        </div>
    );
}
