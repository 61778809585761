import { Button, Col, Form, Input, Row, Select, Table } from "antd";
import trashCan from '../../../../assets/images/trashCan.svg'
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import React from "react";
import Icon from "@ant-design/icons";
import useArticleBySearch from "../../hooks/acceptance/useArticleBySearch";

const data = [
    {
        article: "1234567890",
        size: "10",
        planCount: "10",
        factCount: '10'
    },
    {
        article: "1234567890",
        size: "10",
        planCount: "10",
        factCount: '10'
    },
    {
        article: "1234567890",
        size: "10",
        planCount: "10",
        factCount: '10'
    },
    {
        article: "1234567890",
        size: "10",
        planCount: "10",
        factCount: '10'
    },

];

const columns = [
    {
        title: "Артикул",
        dataIndex: "article",
        key: "article",
        width: "25%",
    },
    {
        title: "Размер",
        dataIndex: "size",
        key: "size",
        width: "25%",
    },
    {
        title: "Планируемое кол-во",
        dataIndex: "planCount",
        key: "planCount",
        width: "25%",
    },
    {
        title: "Фактическое кол-во",
        dataIndex: "factCount",
        key: "factCount",
        width: "25%",
    },
];


export default function ShipmentTable() {

    return (
        <div>
            <Row gutter={10} style={{
                backgroundColor: '#f8f8f8',
                paddingBottom: 20
            }}>
                <Col>
                    <Button
                        className={"button"}
                        type={"primary"}
                        style={{ width: "222px" }}
                    >
                        Создать файл отгрузки
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={true}
                        className={"button"}
                        type={"primary"}
                        ghost
                        style={{ width: "222px" }}
                    >
                        Сохранить файл отгрузки
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={true}
                        className={"button"}
                        type={"primary"}
                        ghost
                        style={{ width: "222px" }}
                    >
                        Отправить на ТСД
                    </Button>
                </Col>
            </Row>
            <Row
                className={"container"}
                style={{ marginTop: "20px", minHeight: "500" }}
            >
                <Col span={12} style={{ padding: "14px 40px" }}>
                    <Form layout={"vertical"} className={"form"}>
                        <Form.Item
                            label={"Выберите или напишите артикул(вб,китайский), штрихкод товара"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Укажите размер товара"}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1234567890",
                                        label: "1234567890",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label={"Укажите кол-во товара"}>
                            <Input />
                        </Form.Item>
                        <Button
                            type={"primary"}
                            className={"button"}
                            style={{
                                fontSize: "12px",
                                width: "171px",
                            }}
                        >
                            Добавить в таблицу
                        </Button>
                    </Form>
                </Col>
                <Col span={12}>
                    <Table
                        className={"additional-deliveries-table"}
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
            </Row>

        </div>
    );
}
