import { useQuery, useMutation, useQueryClient } from 'react-query'
import { backend } from 'api'
import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import useSellerList from 'features/sellers/hooks/useSellerList'

export default function useSettingsData() {
	const queryClient = useQueryClient()
	const [dataType, setDataType] = useState<
		| 'supplier'
		| 'users'
		| 'tokens'
		| 'wb-user-roles'
		| 'user-personal-data'
		| 'token-scopes'
		| 'token-scopes-name'
		| 'am-i-owner'
		| 'supplier-info'
		| 'get-pvz'
	>('supplier')
	const [settingsData, setSettingsData] = useState<any>(null)
	const [usersData, setUsersData] = useState<any>(null)
	const [token, setToken] = useState<any>(null)

	const {
		sellers,
		search: searchSellers,
		setSearch: setSearchSellers,
	} = useSellerList()

	const getSavedSeller = (sellers: any[]) => {
		const savedSeller = localStorage.getItem('selectedSeller')
		if (!!savedSeller) {
			return JSON.parse(savedSeller)
		} else {
			const defaultSeller = {
				id: sellers[0]?.id,
				company_name: sellers[0]?.company_name,
			}
			localStorage.setItem('selectedSeller', JSON.stringify(defaultSeller))
			return defaultSeller
		}
	}

	const [selectedSeller, setSelectedSeller] = useState<string>(
		getSavedSeller(sellers).company_name
	)
	const [selectedSellerId, setSelectedSellerId] = useState<string>(
		getSavedSeller(sellers).id
	)

	const handleSetSelectedSeller = (id: string, company_name: string) => {
		setSelectedSellerId(id)
		setSelectedSeller(company_name)
		localStorage.setItem('selectedSeller', JSON.stringify({ id, company_name }))
	}

	const getCurrentSellerId = () => {
		const savedSeller = localStorage.getItem('selectedSeller')
		return savedSeller ? JSON.parse(savedSeller).id : sellers[0]?.id
	}

	useEffect(() => {
		if (sellers && sellers.length > 0) {
			const savedSeller = getSavedSeller(sellers)
			if (!savedSeller) {
				const defaultSeller =
					sellers.find((seller: any) => seller.id === savedSeller.id) ||
					sellers[0]
				handleSetSelectedSeller(defaultSeller.id, defaultSeller.company_name)
			}
		}
	}, [sellers, settingsData])

	const {
		data: sData,
		error,
		isLoading,
		refetch,
		...options
	} = useQuery(
		['SETTING_MIRROR_SUPPLIER_DATA', selectedSeller, dataType],
		() =>
			backend.settingsMirror.getSettingsData(dataType, getCurrentSellerId()),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: !!getCurrentSellerId(),

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				const newSettingsData = data?.result ? data.result : data
				setSettingsData(newSettingsData)
			},
		}
	)

	const {
		data: usersList,
		error: userError,
		isLoading: userIsLoading,
		refetch: userRefetch,
	} = useQuery(
		['USERS_LIST', selectedSeller, dataType],
		() => backend.user.getUserListByRole(),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: !!getCurrentSellerId(),

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				setUsersData(data?.result ? data.result : data)
			},
		}
	)

	const fetchUserByPhone = async (phone: string) => {
		try {
			const data = await backend.settingsMirror.getUserByPhone(
				getCurrentSellerId(),
				phone
			)
			return data
		} catch (error) {
			console.error('Failed to fetch user by phone:', error)
		}
	}

	const addUserMutation = useMutation(
		({ phone, position }: { phone: string; position: string }) =>
			backend.settingsMirror.addUser(getCurrentSellerId(), phone, position),
		{
			onSuccess: () => {
				message.success('Пользователь успешно добален.')
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
			},
			onError: () => {
				message.error('Не удалось добавить пользователя.')
			},
		}
	)

	const deleteUserMutation = useMutation(
		(user_id: string) =>
			backend.settingsMirror.deleteUser(getCurrentSellerId(), user_id),
		{
			onSuccess: () => {
				message.success('Пользователь успешно удален.')
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
			},
			onError: () => {
				message.error('Не удалось удалить пользователя.')
			},
		}
	)

	const generateTokenMutation = useMutation(
		({ tokenName, param }: { tokenName: string; param: string[] }) =>
			backend.settingsMirror.generateToken(getCurrentSellerId(), tokenName, {
				param,
			}),
		{
			onSuccess: data => {
				queryClient.invalidateQueries(['token-scopes'])
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
				queryClient.invalidateQueries(['token-scopes-name'])
				message.success('Токен успешно создан.')
				console.log(data)
				if (data) {
					setToken(data.token.accessToken)
				}
			},
			onError: () => {
				message.error('Не удалось создать токен.')
			},
		}
	)

	const deleteTokenMutation = useMutation(
		(delete_token_id: string) =>
			backend.settingsMirror.deleteToken(getCurrentSellerId(), delete_token_id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
				message.success('Токен успешно удален.')
			},
			onError: () => {
				message.error('Не удалось удалить токен.')
			},
		}
	)

	const editUserMutation = useMutation(
		({
			user_id,
			balance_disabled,
			discount_price_disabled,
			feedbacks_questions_disabled,
			finance_disabled,
			supply_disabled,
			wb_point_disabled,
			worker_new_position,
		}: {
			user_id: string
			balance_disabled: boolean
			discount_price_disabled: boolean
			feedbacks_questions_disabled: boolean
			finance_disabled: boolean
			supply_disabled: boolean
			wb_point_disabled: boolean
			worker_new_position: string
		}) =>
			backend.settingsMirror.editUser(
				getCurrentSellerId(),
				user_id,
				balance_disabled,
				discount_price_disabled,
				feedbacks_questions_disabled,
				finance_disabled,
				supply_disabled,
				wb_point_disabled,
				worker_new_position
			),
		{
			onSuccess: () => {
				message.success('Данные пользователя успешно обновлены.')
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
			},
			onError: () => {
				message.error('Не удалось обновить данные пользователя.')
			},
		}
	)

	const editSupplierMutation = useMutation(
		(data: any) =>
			backend.settingsMirror.editSupplierInfo(getCurrentSellerId(), data),
		{
			onSuccess: () => {
				message.success('Информация о компании успешно обновлена.')
				queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
			},
			onError: () => {
				message.error('Не удалось обновить информацию о компании.')
			},
		}
	)
	const setPvzMutation = useMutation(
		(data: any) => backend.settingsMirror.setPvz(getCurrentSellerId()),
		{
			onSuccess: data => {
				console.log(data)
				if (data.isSuccess) {
					message.success('Информация о складах успешно обновлена.')
					queryClient.invalidateQueries(['SETTING_MIRROR_SUPPLIER_DATA'])
				}else{
					message.error('Не удалось обновить информацию о складах.')
				}
			},
			onError: () => {
				message.error('Не удалось обновить информацию о складах.')
			},
		}
	)

	return {
		settingsData,
		selectedSeller,
		selectedSellerId,
		setSelectedSeller: handleSetSelectedSeller,
		dataType,
		setDataType,
		sellers,
		setSearchSellers,
		searchSellers,
		isLoading,
		error,
		fetchUserByPhone,
		addUser: addUserMutation.mutate,
		deleteUser: deleteUserMutation.mutate,
		generateToken: generateTokenMutation.mutate,
		deleteToken: deleteTokenMutation.mutate,
		editUser: editUserMutation.mutate,
		editSupplier: editSupplierMutation.mutate,
		token,
		usersData,
		setPvz: setPvzMutation.mutate,
		...options,
	}
}
