import ModalHeader from "features/shared/ModalHeader";
import Article from "../components/Article";
import {Button, Col, Form, Input, message, Modal, Row} from "antd";
import useAnalyticsNoteData from "../hooks/analiticsNote/useAnalyticsNoteData";
import React, {useEffect, useRef, useState} from "react";
import {useAnalyticsNoteAdd} from "../hooks/analiticsNote/useAnalyticsNoteAdd";
import {useSelector} from "react-redux";
import {useAnalyticsNoteDelete} from "../hooks/analiticsNote/useAnalyticsNoteDelete";
import ConfirmModal from "../../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import {useAnalyticsNoteUpdate} from "../hooks/analiticsNote/useAnalyticsNoteUpdate";

const {TextArea} = Input;

export default function ArticleModalContent({onClose, productId, articleModal, finishUpdate}: any) {

    const [form] = Form.useForm();
    const user = useSelector((state: any) => state.auth.user)

    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        isLoadingDelete: false,
        onPress: () => {
        }
    });

    const [updateStateMode, setUpdateStateMode] = useState<any>({
        isUpdate: false,
        id: '',
        text: '',
        title: ''
    });

    const {
        setId,
        notes,
    } = useAnalyticsNoteData()

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useAnalyticsNoteAdd()

    const {
        handleUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorEdit,
        isSuccess: isSuccessEdit
    } = useAnalyticsNoteUpdate()

    const {
        handleDelete,
        isLoading: isLoadingDelete,
        errorMessage: errorMessageDelete,
        isError: isErrorDelete,
        isSuccess: isSuccessDelete
    } = useAnalyticsNoteDelete()

    const onDeleteNote = (id: any) => {
        setIsModalVisible({
            isOpen: true,
            text: 'удалить заметку?',
            onPress: () => handleDelete(id)
        })
    }

    const onFinishUpdate = (values: any) => {
        handleUpdate(updateStateMode?.id, {
            noteTitle: values.noteTitle,
            noteText: values.noteText,
            productId: articleModal?.productId,
            noteCreatorId: user?.id,
            noteResponderId: articleModal?.analyticsUserId === 0 ? null : articleModal?.analyticsUserId
        })
    };

    const onFinish = (values: any) => {
        handleCreate({
            noteTitle: values.noteTitle,
            noteText: values.noteText,
            productId: articleModal?.productId,
            noteCreatorId: user?.id,
            noteResponderId: articleModal?.analyticsUserId === 0 ? null : articleModal?.analyticsUserId
        })
    };

    const renderAdd = () => {
        return (
            <>
                <div className={"send-article-title"}>Оставить заметку</div>
                <Form name={"sendArticleForm"} layout={"vertical"} onFinish={onFinish} form={form}>
                    <Form.Item
                        label={"Введите заголовок"}
                        name="noteTitle"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите заголовок",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"Введите текст заметки"}
                        name="noteText"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите текст заметки",
                            },
                        ]}
                    >
                        <TextArea
                            style={{height: "auto !important"}}
                            rows={5}
                        />
                    </Form.Item>
                    <Row justify={"end"}>
                        <Col>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{width: "100%"}}
                                htmlType={"submit"}
                                loading={createLoading}
                            >
                                Отправить
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderEdit = () => {
        return (
            <>
                <div className={"send-article-title"}>Редактировать заметку: {updateStateMode?.text}</div>
                <Form name={"sendArticleForm"} layout={"vertical"} onFinish={onFinishUpdate} form={form}>
                    <Form.Item
                        label={"Введите заголовок"}
                        name="noteTitle"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите заголовок",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"Введите текст заметки"}
                        name="noteText"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите текст заметки",
                            },
                        ]}
                    >
                        <TextArea
                            style={{height: "auto !important"}}
                            rows={5}
                        />
                    </Form.Item>
                    <Row justify={"end"}>
                        <Col style={{marginRight: 10,}}>
                            <Button
                                type={"primary"}
                                className={"button"}
                                style={{width: "100%"}}
                                htmlType={"submit"}
                                loading={isLoadingUpdate}
                            >
                                Сохранить
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className={"button"}
                                style={{width: '100%'}}
                                type={"primary"}
                                ghost
                                htmlType={"submit"}
                                onClick={() => setUpdateStateMode({
                                    isUpdate: false,
                                    id: '',
                                    text: '',
                                    title: ''
                                })}
                            >
                                Отмена
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    useEffect(() =>{
        if (updateStateMode?.id){
            form.setFieldsValue({
                noteTitle: updateStateMode?.title,
                noteText: updateStateMode?.text
            })
        }else {
            form.setFieldsValue({
                noteTitle: '',
                noteText: ''
            })
        }
    },[updateStateMode])

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно добавили новую заметку')
            form.resetFields()
        }
    }, [isSuccessCreate])

    useEffect(() => {
        if (isErrorCreate) {
            message.error('Ошибка отправки заметки, повторите позже')
        }
    }, [isErrorCreate])

    useEffect(() => {
        if (isErrorDelete) {
            message.error('У вас недостаточно прав для редактирования')
        }
        if (isSuccessDelete){
            message.success('Вы успешно удалили заметку')
            setUpdateStateMode({
                isUpdate: false,
                id: '',
                text: '',
                title: ''
            })
        }
    }, [isErrorDelete, isSuccessDelete])

    useEffect(() => {
        if (isErrorEdit) {
            message.error('У вас недостаточно прав для редактирования')
        }
        if (isSuccessEdit){
            setUpdateStateMode({
                isUpdate: false,
                id: '',
                text: '',
                title: ''
            })
        }
    }, [isErrorEdit, isSuccessEdit])

    useEffect(() => {
        setId(productId)
    }, [productId])

    useEffect(() =>{
        if (articleModal?.isUpdate){
            setUpdateStateMode({
                isUpdate: false,
                id: '',
                text: '',
                title: ''
            })
            finishUpdate()
        }

    },[articleModal?.isUpdate])

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={"Заметки"}
                subtitle={`Артикул ${articleModal?.articule}`}
                onClose={onClose}
            />
            {
                notes?.data?.length === 0
                    ? <div className={"articles-container_nothing-to-see"}>Нет данных</div>
                    :
                    <div className="articles-container">

                        {notes?.data?.map((item: any, index: number) =>
                            <div
                                style={{
                                    marginTop: index === 0 ? "0" : "42px", overflow: "hidden"
                                }}
                            >
                                <Article
                                    color={item?.noteCreator?.id === user?.id ? "blue" : 'orange'}
                                    data={item}
                                    onDeleteNote={onDeleteNote}
                                    setUpdateStateMode={setUpdateStateMode}
                                    updateStateMode={updateStateMode}
                                />
                            </div>
                        )}
                    </div>

            }
            <div className={"send-article-wrapper"}>
                {updateStateMode?.id
                    ? renderEdit()
                    : renderAdd()
                }
            </div>

            <Modal
                open={isModalVisible.isOpen}
                closable={false}
                footer={null}
                width={500}
            >
                <ConfirmModal
                    onClose={() => setIsModalVisible({
                        isOpen: false,
                        isLoading: false,
                        onPress: () => {
                        }
                    })}
                    isLoading={isLoadingDelete}
                    onConfirm={() => isModalVisible.onPress()}
                    text={isModalVisible.text}
                />
            </Modal>
        </div>
    );
}
