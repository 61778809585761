import React, {useState, useEffect} from 'react';
import {Upload, message, Button, UploadProps, Modal} from 'antd';
import type {UploadFile} from 'antd/es/upload/interface';
import SuccessUploadModal from "../modal/SuccessUploadModal";

interface Props {
    actionUrl: string;
    title: string,
    successText?: string
}

const FileUploader = ({actionUrl, title, successText}: Props) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [backendData, setBackendData] = useState<any>({
        totalCount: 0,
        failedCount: 0,
        createdCount: 0,
        firstAddedId: 0,
        lastAddedId: 0
    });

    const [answerModal, setAnswerModal] = useState<any>(false);

    const handleChange: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        newFileList = newFileList.slice(-2);

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;

                // Extract backend message
                const backendResponse = file.response;
                if (backendResponse.isSuccess) {
                    setBackendData({...backendResponse, ...backendResponse?.data});
                    setAnswerModal(true)
                }
            }
            return file;
        });

        setFileList(newFileList);

        setFileList(newFileList);

        // Remove the uploaded file from the fileList state after a successful or failed upload
        const successFile = info.fileList.find(file => file.status === 'done');
        if (successFile) {
            setFileList(prevList => prevList.filter(file => file.uid !== successFile.uid));
            message.success(`${successFile.name} загружен успешно. ${successText}`);
        } else {
            const errorFile = info.fileList.find(file => file.status === 'error');
            if (errorFile) {
                console.log(errorFile.response)
                const errorMsg = `${errorFile.name} upload failed. Status: ${errorFile.response.message}`;
                message.error(errorMsg);
                setFileList((prevList) => prevList.filter((file) => file.uid !== errorFile.uid));
            }
        }
    };

    const props = {
        action: actionUrl,
        onChange: handleChange,
        multiple: false,
        maxCount: 1,
    };

    return (
        <>
            <Upload {...props} fileList={fileList}>
                <Button
                    className="button"
                    type="primary"
                    style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line', minWidth: '117px'}}
                >
                    {title}
                </Button>
            </Upload>
            <Modal
                open={answerModal}
                closable={false}
                footer={null}
                width={600}
            >
                <SuccessUploadModal
                    backendData={backendData}
                    setBackendData={setBackendData}
                    onClose={() => setAnswerModal(false)}
                />
            </Modal>
        </>
    );
};

export default FileUploader;