import { useState } from "react";
import { Col, Row } from "antd";
import ExpensesTab from "./ExpensesTab";
import GuideTab from "./GuideTab";

export default function OperatingExpenses() {

    //addSk, listOfSavedInvoices
    const [tab, setTab] = useState<any>({
        route: "expenses",
        id: null
    });

    return (
        <Row>
            <Col style={{ padding: "10px 20px" }} span={24}>
                <div className={"storage-tabs"}>
                    <div
                        className={`${
                            tab.route === "expenses" ? "tab-active" : ""
                        }`}
                        onClick={() => setTab({...tab, route: "expenses"})}
                    >
                        Расходы
                    </div>
                    <div
                        className={`${
                            tab.route  === "guide" ? "tab-active" : ""
                        }`}
                        onClick={() => setTab({...tab, route: "guide"})}
                    >
                        Справочник
                    </div>
                </div>
                <Row>
                    <Col span={24}>
                        {tab.route === "expenses" && (
                            <ExpensesTab/>
                        )}
                        {tab.route === "guide" && (
                            <GuideTab/>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
