import {useMutation, useQueryClient} from "react-query";
import {backend} from "api";
import {useCallback, useState} from "react";

export const useAddCostPrice = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: setNewCostPrice,
        ...options
    } = useMutation(backend.analytics.setNewCostPriceToProduct, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries([`ANALYTICS_DATA`])]);
            await Promise.all([queryClient.invalidateQueries([`COST_PRICE_DATA`])]);
        },
    });

    const handleCreate = useCallback(
        async (values: any) => {
            try {
                let body = {...values};
                await setNewCostPrice(body);
                queryClient.invalidateQueries(['ANALYTICS_DATA']);
                queryClient.invalidateQueries(['COST_PRICE_DATA']);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [setNewCostPrice]
    );

    return {
        handleCreate,
        errorMessage,
        ...options
    };
};
