import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getToken } from "utils/tokenStorage";

export interface AuthState {
  isLoggedIn: boolean;
  user: any | null;
}

const initialState: AuthState = {
  isLoggedIn: !!getToken(),
  user: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (_, action: PayloadAction<AuthState["user"]>) => ({
      isLoggedIn: true,
      user: action.payload,
    }),
    logout: () => ({
      isLoggedIn: false,
      user: null,
    }),
  },
});

export default slice;
