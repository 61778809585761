import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu, Modal,
    Pagination,
    Radio,
    Row,
    Select, Table,
} from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as SelectIconComponent } from "assets/images/select-icon.svg";
import { ReactComponent as uploadButtonIcon } from "assets/images/upload-button-icon.svg";

const data = [
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },
    {
        id: '12345',
        art: '12345',
        product: 'Куртка',
        size: '50',
        count: '100',
        color: 'Цвет',
        status: 'Готов к заказу',
        date: '31.01.2023',
        dateCode: '31.01.2023',
        investor: 'Имя'
    },

];

export default function DeliveriesFromChinaTable() {

    const [selectedType, setSelectedType] = useState<number>(1);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "9%",
        },
        {
            title: "Артикул китайский",
            dataIndex: "art",
            key: "art",
            width: "9%",
        },
        {
            title: "Товар",
            dataIndex: "product",
            key: "product",
            width: "9%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "9%",
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            key: "count",
            width: "9%",
        },
        {
            title: "Цвет",
            dataIndex: "color",
            key: "color",
            width: "9%",
        },
        {
            title: "Статус заказа",
            dataIndex: "status",
            key: "status",
            width: "9%",
        },
        {
            title: "Дата оформ заявки",
            dataIndex: "date",
            key: "date",
            width: "9%",
        },
        {
            title: "Дата прихода",
            dataIndex: "dateCode",
            key: "dateCode",
            width: "9%",
        },
        {
            title: "Инвестор",
            dataIndex: "investor",
            key: "investor",
            width: "9%",
        },
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     render: (text: any, record: any) => (
        //         <div
        //             style={{
        //                 textAlign: "center",
        //                 display: "flex",
        //                 gap: "7px",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 cursor: "pointer",
        //             }}
        //             onClick={() => {
        //                 setEditProductOpened(true);
        //                 setProductToEdit(record);
        //             }}
        //         >
        //             <Icon component={editIcon} style={{fontSize: "12px"}}/>
        //         </div>
        //     ),
        // },
    ];

    return (
        <div>

            <Row style={{paddingTop: 0, backgroundColor: '#f8f8f8'}}>
                <Col style={{width: '50%'}}>
                    <Button
                        className={
                            selectedType === 1
                                ? "table-tab-button-active-without-circle"
                                : "table-tab-button-without-circle"
                        }
                        style={{borderTopLeftRadius: '10px'}}
                        onClick={() => setSelectedType(1)}
                    >
                        Актуальные
                    </Button>
                </Col>
                <Col style={{width: '50%'}}>
                    <Button
                        className={
                            selectedType === 2
                                ? "table-tab-button-active-without-circle"
                                : "table-tab-button-without-circle"
                        }
                        style={{borderTopRightRadius: '10px'}}
                        onClick={() => setSelectedType(2)}
                    >
                        Архив
                    </Button>
                </Col>
            </Row>
            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Table
                        // loading={isLoading}
                        className="product-arrival-table"
                        columns={columns}
                        // dataSource={storageData?.data?.map((item: any, index: any) => ({
                        //     ...item,
                        //     key: index,
                        // }))}
                        dataSource={data}
                        scroll={{x: true}}
                        pagination={{
                            // onChange: (page, pageSize) => setCurrentPage(page),
                            position: ["bottomCenter"],
                            // pageSize: Number(currentPageSize),
                            // total: Number(storageData?.total),
                            // showSizeChanger: false,
                            // current: currentPage,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => {
                                    // setProductToInfo(record);
                                    // setProductInfoOpened(true);
                                },
                            };
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
