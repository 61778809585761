import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import useGTDData from "../gtd/useGTDData";
import useArticleCcdForProductsArrival from "./useArticleCcdForProductsArrival";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import Icon from "@ant-design/icons";
import useSellerList from "../topology/useSellerList";
import useArticleMkpForProductsArrival from "./useArticleMkpForProductsArrival";
import useArticlePvdForProductsArrival from "./useArticlePvdForProductsArrival";
import useArticleCnsForProductsArrival from "./useArticleCnsForProductsArrival";
import useSizesForProductsArrival from "./useSizesForProductsArrival";


export default function useProductArrivalData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [orderBy, setOrderBy] = useState<string>("");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchText, 1000);
    const [periodStartDate, setPeriodStartDate] = useState<any>('');
    const [periodEndDate, setPeriodEndDate] = useState<any>('');
    const [selectedGtd, setSelectedGtd] = useState<any>(null);

    const [selectedArticleCcs, setSelectedArticleCcs] = useState<Array<string>>([]);
    const [selectedArticleMkp, setSelectedArticleMkp] = useState<Array<string>>([]);
    const [selectedArticlePvd, setSelectedArticlePvd] = useState<Array<string>>([]);
    const [selectedArticleCns, setSelectedArticleCns] = useState<Array<string>>([]);
    const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);
    const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);

    const handleRangeChangeTable = (dates: any, dateStrings: any) => {
        setPeriodStartDate(dateStrings[0])
        setPeriodEndDate(dateStrings[1])
    }

    const {
        gtdData,
        searchText: searchGtd,
        setSearchText: setSearchGtd,
        setSelectedStatus,
    } = useGTDData()

    const {
        articleCcd,
        searchText: searchForArticleCcs,
        setSearchText: setSearchForArticleCcs,
    } = useArticleCcdForProductsArrival()

    const {
        articlePvd,
        searchText: searchForArticlePvd,
        setSearchText: setSearchForArticlePvd,
    } = useArticlePvdForProductsArrival()

    const {
        articleMkp,
        searchText: searchForArticleMkp,
        setSearchText: setSearchForArticleMkp,
    } = useArticleMkpForProductsArrival()

    const {
        articleCns,
        searchText: searchForArticleCns,
        setSearchText: setSearchForArticleCns,
    } = useArticleCnsForProductsArrival()

    const {
        sizes,
        searchText: searchForSizes,
        setSearchText: setSearchForSizes,
    } = useSizesForProductsArrival()

    const {
        sellers,
        search: searchSeller,
        setSearch: setSearchSeller,
    } = useSellerList()

    const getSizesFilterItems = () => {
        if (!sizes) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sizes?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((articleItem: any) => articleItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedSizes.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedSizes(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item}`,
                });
            }
        });

        selectedSizes?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedSizes.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedSizes(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchForSizes}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchForSizes(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "sizes-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getArticleCnsFilterItems = () => {
        if (!articleCns) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        articleCns?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedArticleCns.some((articleItem: any) => articleItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleCns.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedArticleCns];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedArticleCns(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleCns-${item}`,
                });
            }
        });

        selectedArticleCns?.forEach((item: any) => {
            const isDuplicate = selectedArticleCns.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleCns.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedArticleCns];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedArticleCns(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleCns-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchForArticleCns}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchForArticleCns(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "articleCns-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getArticlePvdFilterItems = () => {
        if (!articlePvd) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        articlePvd?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedArticlePvd.some((articleItem: any) => articleItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticlePvd.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedArticlePvd];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedArticlePvd(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articlePdv-${item}`,
                });
            }
        });

        selectedArticlePvd?.forEach((item: any) => {
            const isDuplicate = selectedArticlePvd.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticlePvd.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedArticlePvd];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedArticlePvd(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articlePdv-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchForArticlePvd}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchForArticlePvd(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "articlePdv-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getArticleMkpFilterItems = () => {
        if (!articleMkp) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        articleMkp?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedArticleMkp.some((articleItem: any) => articleItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleMkp.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedArticleMkp];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedArticleMkp(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleMkp-${item}`,
                });
            }
        });

        selectedArticleMkp?.forEach((item: any) => {
            const isDuplicate = selectedArticleMkp.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleMkp.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedArticleMkp];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedArticleMkp(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleMkp-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchForArticleMkp}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchForArticleMkp(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "articleMkp-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getArticleCcdFilterItems = () => {
        if (!articleCcd) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        articleCcd?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedArticleCcs.some((articleItem: any) => articleItem === item);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleCcs.some((articleItems: any) => articleItems === item)}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedArticleCcs];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el!== item);
                                    }
                                    setSelectedArticleCcs(newSelectedProducts);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleCcd-${item}`,
                });
            }
        });

        selectedArticleCcs?.forEach((item: any) => {
            const isDuplicate = selectedArticleCcs.some((articleItem: any) => articleItem === item);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item}
                                checked={selectedArticleCcs.some((articleItem: any) => articleItem === item)}
                                onChange={(e) => {
                                    let newSelectedArticle = [...selectedArticleCcs];
                                    if (e.target.checked) {
                                        newSelectedArticle.push(item);
                                    } else {
                                        newSelectedArticle = newSelectedArticle.filter((el: any) => el !== item);
                                    }
                                    setSelectedArticleCcs(newSelectedArticle);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </div>
                    ),
                    key: `articleCcd-${item}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchForArticleCcs}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchForArticleCcs(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "article-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getSellerFilterItems = () => {
        if (!sellers) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sellers?.data?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.extRef}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id)}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id !== item?.id);
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item?.companyName}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item?.extRef}`,
                });
            }
        });

        selectedSeller?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id);

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.extRef}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id === item?.id)}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id !== item?.id);
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item?.companyName}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item?.extRef}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSeller}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSeller(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "seller-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    useEffect(() =>{
        setSelectedStatus('')
    },[])

    const {data: productArrivalData, ...options} = useQuery(
        [
            "PRODUCTS_ARRIVAL_DATA",
            currentPage,
            currentPageSize,
            selectedGtd,
            periodStartDate,
            periodEndDate,
            orderBy,
            debouncedSearch,
            selectedArticleCcs,
            selectedArticleMkp,
            selectedArticlePvd,
            selectedArticleCns,
            selectedSizes,
            selectedSeller,
        ],
        () =>
            backend.storage.getProductArrivalData(
                currentPage.toString(),
                currentPageSize,
                selectedGtd,
                periodStartDate,
                periodEndDate,
                orderBy,
                debouncedSearch,
                selectedArticleCcs,
                selectedArticleMkp,
                selectedArticlePvd,
                selectedArticleCns,
                selectedSizes,
                selectedSeller
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        productArrivalData,
        periodStartDate,
        periodEndDate,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        setPeriodStartDate,
        setPeriodEndDate,
        gtdData,
        searchGtd,
        setSearchGtd,
        selectedGtd,
        setSelectedGtd,
        setOrderBy,
        handleRangeChangeTable,
        getArticleCcdFilterItems,
        getSellerFilterItems,
        getArticleMkpFilterItems,
        getArticlePvdFilterItems,
        getArticleCnsFilterItems,
        getSizesFilterItems,
        ...options,
    };
}
