import React, {useEffect, useRef, useState} from "react";
import {Col, Row, DatePicker, Button, Modal, Pagination, Spin, Dropdown, Select} from "antd";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import useFinancialResultList from "./hooks/useFinancialResultList";
import {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import {addSpacesToNumber} from "./help/addSpacesToNumber";
import useChartData from "./hooks/useChartData";
import {useAuthStore} from "../auth/hooks/useAuthStore";
import AddFileModal from "./modals/AddFileModal";
import InfoChart from "./comonents/InfoChart";
import TableCardROI from "./comonents/TableCardROI";
import TableCardSimple from "./comonents/TableCardSimple";
import TableCardOne from "./comonents/TableCardOne";
import InfoChartDesc from "./comonents/InfoChartDesc";
import DatePickerCustomed from "./comonents/DatePickerCustomed";
import useDataForRange from "./hooks/useDataForRange";
import CustomRangePicker from "./comonents/CustomRangePicker";
import useStockData from "./hooks/useStockData";

const {RangePicker} = DatePicker;
const {Option} = Select;

export default function FinancialResultsTable() {

    const {state} = useAuthStore();

    const isAdmin = state?.user?.role?.name === 'Администратор'

    const [isMob, setIsMob] = useState(false);
    const [loadModal, setLoadModal] = useState<any>(false);
    const [pickerOpen, setPickerOpen] = useState(false)

    const {
        financialResults,
        type,
        setSelectedSeller,
        selectedSeller,
        handleRangeChangeTable,
        sellers,
        onChangeType,
        articleWbLink,
        setArticleWbLink,
        dateStart,
        dateEnd,
        getLastSunday,
        isCalendarMode,
        setIsCalendarMode,
        setSearchSeller,
        isLoading
    } = useFinancialResultList();

    const {
        stockData,
        setSellerId: seSellerIdForStock,
        isLoading: isLoadingStock
    } = useStockData()

    const {
        rangeData,
        isLoading: isLoadingRangeData,
        isError: isErrorRangeData,
        setSellerId: setSellerIdForRange
    } = useDataForRange()

    const {
        chartData,
        setSellerId,
        isLoading: isLoadingChart
    } = useChartData()

    useEffect(() => {
        setSellerId(selectedSeller)
        setSellerIdForRange(selectedSeller)
        seSellerIdForStock(selectedSeller)
    }, [selectedSeller])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1350) {
                setIsMob(true)
            } else {
                setIsMob(false)
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Проверка размера страницы при первой загрузке компонента

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const renderFinancialResultsMod = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }
        if (financialResults?.roi) {
            return (
                <Row style={{width: '100%', marginTop: 5,}}>
                    <Col span={24}>

                        <TableCardROI
                            value={financialResults?.roi?.value ? addSpacesToNumber(financialResults?.roi?.value) : '0'}
                        />

                        <div className="fina-result-second-card-wrap">
                            <TableCardSimple
                                title={'Финансовый результат'}
                                sub={'(руб)'}
                                value={financialResults?.financialResult?.value ? addSpacesToNumber(financialResults?.financialResult?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.financialResult?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.financialResult?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.financialResult?.dif ? addSpacesToNumber(financialResults?.financialResult?.dif) : '0'}
                                    </span>
                                }
                            />
                            <TableCardSimple
                                title={'Маржинальная прибыль'}
                                percent={true}
                                value={financialResults?.marginProfitPercent?.value ? addSpacesToNumber(financialResults?.marginProfitPercent?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.marginProfitPercent?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.marginProfitPercent?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.marginProfitPercent?.dif ? addSpacesToNumber(financialResults?.marginProfitPercent?.dif) + '%' : '0'}
                                    </span>
                                }
                            />
                        </div>
                        <div className="fina-result-second-card-wrap">
                            <TableCardSimple
                                title={'Выручка от продаж'}
                                sub={'(руб)'}
                                value={financialResults?.salesRevenue?.value ? addSpacesToNumber(financialResults?.salesRevenue?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.salesRevenue?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.salesRevenue?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.salesRevenue?.dif ? addSpacesToNumber(financialResults?.salesRevenue?.dif) : '0'}
                                    </span>
                                }
                            />

                            <TableCardSimple
                                title={'Кол-во проданного товара'}
                                sub={'(шт)'}
                                value={financialResults?.count?.value ? addSpacesToNumber(financialResults?.count?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.count?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.count?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.count?.dif ? addSpacesToNumber(financialResults?.count?.dif) : '0'}
                                    </span>
                                }
                            />
                        </div>

                        <TableCardOne
                            title={'Себестоимость проданных товаров'}
                            sub={'(руб)'}
                            value={financialResults?.totalSelfCosts?.value ? addSpacesToNumber(financialResults?.totalSelfCosts?.value) : '0'}
                            diff={
                                <span style={{
                                    color: String(financialResults?.totalSelfCosts?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                }}>
                                    {String(financialResults?.totalSelfCosts?.dif)?.includes('-') ? '' : "+"}
                                    {financialResults?.totalSelfCosts?.dif ? addSpacesToNumber(financialResults?.totalSelfCosts?.dif) : '0'}
                                </span>
                            }
                        />
                        <TableCardOne
                            title={'Комиссия/Удержания Wb'}
                            sub={'(руб)'}
                            value={(financialResults?.wbDeductions?.value) ? addSpacesToNumber(financialResults?.wbDeductions?.value) : '0'}
                            diff={
                                <span style={{
                                    color: String(financialResults?.wbDeductions?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                }}>
                                    {String(financialResults?.wbDeductions?.dif)?.includes('-') ? '' : "+"}
                                    {(financialResults?.wbDeductions?.dif) ? addSpacesToNumber(financialResults?.wbDeductions?.dif) : '0'}
                                </span>
                            }
                        />
                        <TableCardOne
                            title={'Операционные расходы'}
                            sub={'(руб)'}
                            value={financialResults?.oper?.value ? addSpacesToNumber(financialResults?.oper?.value) : '0'}

                            diff={
                                <span style={{
                                    color: String(financialResults?.oper?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                }}>
                                    {String(financialResults?.oper?.dif)?.includes('-') ? '' : "+"}
                                    {financialResults?.oper?.dif ? addSpacesToNumber(financialResults?.oper?.dif) : '0'}
                                </span>
                            }
                        />
                        <TableCardOne
                            title={'Реклама на ВБ'}
                            sub={'(руб)'}
                            value={financialResults?.ads?.value ? addSpacesToNumber(financialResults?.ads?.value) : '0'}
                            diff={
                                <span style={{
                                    color: String(financialResults?.ads?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                }}>
                                    {String(financialResults?.ads?.dif)?.includes('-') ? '' : "+"}
                                    {financialResults?.ads?.dif ? addSpacesToNumber(financialResults?.ads?.dif) : '0'}
                                </span>
                            }
                        />
                        <TableCardOne
                            title={'Расходы на продвижение'}
                            sub={'(руб)'}
                            value={financialResults?.adverts?.value ? addSpacesToNumber(financialResults?.adverts?.value) : '0'}

                            diff={
                                <span style={{
                                    color: String(financialResults?.adverts?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                }}>
                                    {String(financialResults?.adverts?.dif)?.includes('-') ? '' : "+"}
                                    {financialResults?.adverts?.dif ? addSpacesToNumber(financialResults?.adverts?.dif) : '0'}
                                </span>
                            }
                        />
                    </Col>
                </Row>
            );
        }
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: "150px",
                    textAlign: "center",
                    color: '#fff'
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderFinancialResultsDesk = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }
        if (financialResults?.roi) {
            return (
                <Row style={{width: '100%', marginTop: 5,}}>
                    <Col span={24}>
                        <TableCardROI
                            fsTitle={32}
                            fsValue={50}
                            value={financialResults?.roi?.value ? addSpacesToNumber(financialResults?.roi?.value) : '0'}
                        />
                        <div className="fina-result-second-card-wrap-desc">
                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Финансовый результат'}
                                sub={'(руб)'}
                                value={financialResults?.financialResult?.value ? addSpacesToNumber(financialResults?.financialResult?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.financialResult?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.financialResult?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.financialResult?.dif ? addSpacesToNumber(financialResults?.financialResult?.dif) : '0'}
                                    </span>
                                }
                            />

                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Маржинальная прибыль'}
                                sub={' (руб)'}
                                value={financialResults?.marginProfit?.value ? addSpacesToNumber(financialResults?.marginProfit?.value) : '0'}

                                diff={
                                    <span style={{
                                        color: String(financialResults?.marginProfit?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.marginProfit?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.marginProfit?.dif ? addSpacesToNumber(financialResults?.marginProfit?.dif) : '0'}
                                    </span>
                                }
                            />
                            <div style={{width: '150%'}}>
                                <TableCardOne
                                    fsTitle={14}
                                    fsValue={22}
                                    fsDiff={18}
                                    title={'Себестоимость проданных товаров'}
                                    sub={' (руб)'}
                                    value={financialResults?.totalSelfCosts?.value ? addSpacesToNumber(financialResults?.totalSelfCosts?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.totalSelfCosts?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.totalSelfCosts?.dif)?.includes('-') ? '' : "+"}
                                            {financialResults?.totalSelfCosts?.dif ? addSpacesToNumber(financialResults?.totalSelfCosts?.dif) : '0'}
                                        </span>
                                    }
                                />
                                <TableCardOne
                                    fsTitle={14}
                                    fsValue={22}
                                    fsDiff={18}
                                    title={'Комиссия/Удержания Wb'}
                                    sub={'(руб)'}
                                    value={(financialResults?.wbDeductions?.value) ? addSpacesToNumber(financialResults?.wbDeductions?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.wbDeductions?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.wbDeductions?.dif)?.includes('-') ? '' : "+"}
                                            {(financialResults?.wbDeductions?.dif) ? addSpacesToNumber(financialResults?.wbDeductions?.dif) : '0'}
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                        <div className="fina-result-second-card-wrap-desc">
                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Финансовый результат'}
                                percent={true}
                                value={financialResults?.financialResultPercent?.value ? addSpacesToNumber(financialResults?.financialResultPercent?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.financialResultPercent?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.financialResultPercent?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.financialResultPercent?.dif ? addSpacesToNumber(financialResults?.financialResultPercent?.dif) : '0'}
                                    </span>
                                }
                            />

                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Маржинальная прибыль'}
                                percent={true}
                                value={financialResults?.marginProfitPercent?.value ? addSpacesToNumber(financialResults?.marginProfitPercent?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.marginProfitPercent?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.marginProfitPercent?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.marginProfitPercent?.dif ? addSpacesToNumber(financialResults?.marginProfitPercent?.dif) : '0'}
                                    </span>
                                }
                            />
                            <div style={{width: '150%'}}>
                                <TableCardOne
                                    fsTitle={14}
                                    fsValue={22}
                                    fsDiff={18}
                                    title={'Логистика/Хранение'}
                                    sub={' (руб)'}
                                    value={financialResults?.logisticsAndStorage?.value ? addSpacesToNumber(financialResults?.logisticsAndStorage?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.logisticsAndStorage?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.logisticsAndStorage?.dif)?.includes('-') ? '' : "+"}
                                            {financialResults?.logisticsAndStorage?.dif ? addSpacesToNumber(financialResults?.logisticsAndStorage?.dif) : '0'}
                                        </span>
                                    }
                                />
                                <TableCardOne
                                    fsTitle={14}
                                    fsValue={22}
                                    fsDiff={18}
                                    title={'Операционные расходы'}
                                    sub={'(руб)'}
                                    value={financialResults?.oper?.value ? addSpacesToNumber(financialResults?.oper?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.oper?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.oper?.dif)?.includes('-') ? '' : "+"}
                                            {financialResults?.oper?.dif ? addSpacesToNumber(financialResults?.oper?.dif) : '0'}
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                        <div className="fina-result-second-card-wrap-desc">
                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Выручка от продаж'}
                                sub={' (руб)'}
                                value={financialResults?.salesRevenue?.value ? addSpacesToNumber(financialResults?.salesRevenue?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.salesRevenue?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.salesRevenue?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.salesRevenue?.dif ? addSpacesToNumber(financialResults?.salesRevenue?.dif) : '0'}
                                    </span>
                                }
                            />
                            <TableCardSimple
                                fsTitle={20}
                                fsValue={36}
                                fsDiff={28}
                                fsSub={14}
                                title={'Количество проданного товара'}
                                sub={' (шт)'}
                                value={financialResults?.count?.value ? addSpacesToNumber(financialResults?.count?.value) : '0'}
                                diff={
                                    <span style={{
                                        color: String(financialResults?.count?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                    }}>
                                        {String(financialResults?.count?.dif)?.includes('-') ? '' : "+"}
                                        {financialResults?.count?.dif ? addSpacesToNumber(financialResults?.count?.dif) : '0'}
                                    </span>
                                }
                            />
                            <div style={{width: '150%'}}>
                                <TableCardOne
                                    title={'Реклама на ВБ'}
                                    sub={'(руб)'}
                                    value={financialResults?.ads?.value ? addSpacesToNumber(financialResults?.ads?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.ads?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.ads?.dif)?.includes('-') ? '' : "+"}
                                            {financialResults?.ads?.dif ? addSpacesToNumber(financialResults?.ads?.dif) : '0'}
                                        </span>
                                    }
                                />
                                <TableCardOne
                                    fsTitle={14}
                                    fsValue={22}
                                    fsDiff={18}
                                    title={'Расходы на продвижение'}
                                    sub={' (руб)'}
                                    value={financialResults?.adverts?.value ? addSpacesToNumber(financialResults?.adverts?.value) : '0'}
                                    diff={
                                        <span style={{
                                            color: String(financialResults?.adverts?.dif)?.includes('-') ? '#EA5455' : '#9BB913'
                                        }}>
                                            {String(financialResults?.adverts?.dif)?.includes('-') ? '' : "+"}
                                            {financialResults?.adverts?.dif ? addSpacesToNumber(financialResults?.adverts?.dif) : '0'}
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: "150px",
                    textAlign: "center",
                    color: '#fff'
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderStockData = () => {
        if (isLoadingStock) {
            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }
        if (stockData?.own_stocks) {
            return (
                <Row style={{width: '100%'}}>
                    <Col span={24}>
                        <div className="fina-result-second-card-wrap">
                            <TableCardSimple
                                title={'Остатки товар на собственном складе'}
                                sub={'(шт)'}
                                value={stockData?.own_stocks?.total || 0}
                                diff={null}
                            />
                            <TableCardSimple
                                title={'Остаток ВБ'}
                                sub={'(шт)'}
                                value={stockData?.wb_stocks?.total || 0}
                                diff={null}
                            />
                        </div>
                    </Col>
                </Row>
            );
        }
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: "150px",
                    textAlign: "center",
                    color: '#fff'
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderChart = () => {
        if (isLoadingChart) {
            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }
        if (chartData?.data?.length !== 0) {
            return (
                <>
                    <InfoChart
                        data={chartData?.data}
                        isReadyToStart={chartData?.is_sale}
                        selectedSeller={selectedSeller}
                        onClickStart={() => window.open(`https://www.wildberries.ru/seller/${articleWbLink}`, '_blank')}
                    />
                    <InfoChartDesc
                        data={chartData?.data}
                        isReadyToStart={chartData?.is_sale}
                        selectedSeller={selectedSeller}
                        onClickStart={() => window.open(`https://www.wildberries.ru/seller/${articleWbLink}`, '_blank')}
                    />
                </>
            )
        }
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: "150px",
                    textAlign: "center",
                    color: '#fff'
                }}
            >
                Нет данных
            </div>
        );

    }

    return (
        <div>
            {isMob
                ?
                <Row className="fin-result-wrapper-mob">
                    <Row style={{display: 'flex', gap: 5, flexDirection: 'row'}}>
                        <Col span={24}>
                            <Select
                                className="custom-select" // Используем свой собственный CSS-класс
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                defaultValue={sellers?.[0]?.id}
                                value={selectedSeller}
                                placeholder="Продавец"
                                filterOption={false}
                                suffixIcon={<Icon
                                    component={SelectIconComponent}
                                    style={{marginTop: "2px", fontSize: "10px"}}
                                />}
                                // value={search}
                                onSearch={(value: any) => setSearchSeller(value)}
                                onChange={(e: any, option: any) => {
                                    setSelectedSeller(e)
                                    setSellerId(e)
                                    setArticleWbLink(option?.key)
                                }}
                            >
                                {sellers?.map((option: any) => {
                                    return (
                                        <Option key={option?.wb_article} value={option?.id}>
                                            {option.company_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col span={24}>
                            <Button
                                onClick={() => window.open(`https://www.wildberries.ru/seller/${articleWbLink}`, '_blank')}
                                className="button"
                                type="primary"
                                style={{
                                    width: "100%",
                                    color: '#fff',
                                    backgroundImage: "linear-gradient(to right, #c111a7, #57117a)",
                                }}
                            >
                                Перейти на WB магазин
                            </Button>
                        </Col>
                        {isAdmin &&
                        <Col xs={24}>
                            <Button
                                onClick={() => setLoadModal(true)}
                                className="button"
                                type="primary"
                                style={{
                                    width: "100%",
                                    fontSize: 14,
                                    height: 32.5,
                                    backgroundColor: '#9BB913',
                                    color: '#fff'
                                }}
                            >
                                Добавить файлы
                            </Button>
                        </Col>
                        }
                    </Row>
                    <Row style={{marginTop: 5}}>
                        {/*<Col span={12}>*/}
                        {/*    <Dropdown*/}
                        {/*        trigger={["click"]}*/}
                        {/*        placement={"bottomRight"}*/}
                        {/*        menu={{items: getArticleWbFilterItems()}}*/}
                        {/*        overlayClassName="dropdown-border"*/}
                        {/*    >*/}
                        {/*        <div className="analytics-header-dropdown-v3"*/}
                        {/*             style={{*/}
                        {/*                 display: 'flex',*/}
                        {/*                 alignItems: 'center',*/}
                        {/*                 width: '99.5%',*/}
                        {/*                 height: 30,*/}
                        {/*                 backgroundColor: '#d9d9d9',*/}
                        {/*             }}*/}
                        {/*        >*/}
                        {/*            <div>Товар</div>*/}
                        {/*            <Icon*/}
                        {/*                component={SelectIconComponent}*/}
                        {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Dropdown>*/}
                        {/*</Col>*/}
                        <Col span={24}>
                            <DatePickerCustomed
                                rangeType={type}
                                pickerOpen={pickerOpen}
                                setPickerOpen={setPickerOpen}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                handleRangeChangeTable={handleRangeChangeTable}
                                width={'100%'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <div className="financial-results-time-choose-mob">
                            <div
                                className={`${type === 'weeks' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                onClick={() => {
                                    onChangeType('weeks')
                                    handleRangeChangeTable(getLastSunday(), true)
                                }}
                            >
                                Неделя
                            </div>
                            <div
                                className={`${type === 'months' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                onClick={() => onChangeType('months')}
                            >
                                Месяц
                            </div>
                            <div
                                className={`${type === 'quarters' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                onClick={() => onChangeType('quarters')}
                            >
                                Квартал
                            </div>
                            <div
                                className={`${type === 'years' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                onClick={() => onChangeType('years')}
                            >
                                Год
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <CustomRangePicker
                            type={type}
                            loading={isLoadingRangeData || isLoading}
                            rangeData={
                                rangeData?.length === 0 || !rangeData
                                    ? []
                                    : rangeData[type]
                            }
                            handleRangeChangeTable={(e: any) => {
                                handleRangeChangeTable(e)
                                setIsCalendarMode(true)
                            }}
                            isCalendarMode={isCalendarMode}
                            setIsCalendarMode={setIsCalendarMode}
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                        />
                    </Row>
                    <Row>
                        {isMob
                            ? renderFinancialResultsMod()
                            : renderFinancialResultsDesk()
                        }
                    </Row>
                </Row>
                :
                <Row className="fin-result-wrapper-mob">
                    <Row gutter={5}>
                        <Col xs={isAdmin ? 12 : 18}>
                            <Select
                                className="custom-select"
                                style={{width: '100%'}}
                                showSearch
                                defaultValue={sellers?.[0]?.id}
                                value={selectedSeller}
                                placeholder="Продавец"
                                filterOption={false}
                                suffixIcon={<Icon component={SelectIconComponent}
                                                  style={{marginTop: "2px", fontSize: "10px"}}/>}
                                onSearch={(value: any) => setSearchSeller(value)}
                                onChange={(e: any, option: any) => {
                                    setSelectedSeller(e);
                                    setSellerId(e)
                                    setArticleWbLink(option?.key);
                                }}
                            >
                                {sellers?.map((option: any) => (
                                    <Option key={option?.wb_article} value={option?.id}>
                                        {option.company_name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={6}>
                            <Button
                                onClick={() => window.open(`https://www.wildberries.ru/seller/${articleWbLink}`, '_blank')}
                                className="button"
                                type="primary"
                                style={{
                                    width: "100%",
                                    color: '#fff',
                                    fontSize: 14,
                                    height: 32.5,
                                    backgroundImage: "linear-gradient(to right, #c111a7, #57117a)",
                                }}
                            >
                                Перейти на WB магазин
                            </Button>
                        </Col>
                        {isAdmin &&
                        <Col xs={6}>
                            <Button
                                onClick={() => setLoadModal(true)}
                                className="button"
                                type="primary"
                                style={{
                                    width: "100%",
                                    fontSize: 14,
                                    height: 32.5,
                                    backgroundColor: '#9BB913',
                                    color: '#fff'
                                }}
                            >
                                Добавить файлы
                            </Button>
                        </Col>
                        }
                    </Row>
                    <Row style={{marginTop: 5, display: 'flex', alignItems: 'center',}}>
                        {/*<Col span={4}>*/}
                        {/*    <Dropdown*/}
                        {/*        trigger={["click"]}*/}
                        {/*        placement={"bottomRight"}*/}
                        {/*        menu={{items: getArticleWbFilterItems()}}*/}
                        {/*        overlayClassName={"dropdown-border"}*/}
                        {/*    >*/}
                        {/*        <div className="analytics-header-dropdown-v3"*/}
                        {/*             style={{*/}
                        {/*                 display: 'flex',*/}
                        {/*                 alignItems: 'center',*/}
                        {/*                 width: '99%',*/}
                        {/*                 height: 40,*/}
                        {/*                 backgroundColor: '#D9D9D9'*/}
                        {/*             }}*/}
                        {/*        >*/}
                        {/*            <div>Товар</div>*/}
                        {/*            <Icon*/}
                        {/*                component={SelectIconComponent}*/}
                        {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Dropdown>*/}
                        {/*</Col>*/}
                        <Col span={9}>
                            <div style={{paddingRight: 4}}>
                                <DatePickerCustomed
                                    rangeType={type}
                                    pickerOpen={pickerOpen}
                                    setPickerOpen={setPickerOpen}
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    handleRangeChangeTable={handleRangeChangeTable}
                                    height={40}
                                />
                            </div>
                        </Col>
                        <Col span={15}>
                            <div className="financial-results-time-choose-mob" style={{marginBottom: 5}}>
                                <div
                                    className={`${type === 'weeks' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                    onClick={() => {
                                        onChangeType('weeks')
                                        handleRangeChangeTable(getLastSunday(), true)
                                    }}
                                >
                                    Неделя
                                </div>
                                <div
                                    className={`${type === 'months' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                    onClick={() => onChangeType('months')}
                                >
                                    Месяц
                                </div>
                                <div
                                    className={`${type === 'quarters' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                    onClick={() => onChangeType('quarters')}
                                >
                                    Квартал
                                </div>
                                <div
                                    className={`${type === 'years' ? 'financial-results-time-choose-item-active-mob' : 'financial-results-time-choose-item-mob'}`}
                                    onClick={() => onChangeType('years')}
                                >
                                    Год
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CustomRangePicker
                            type={type}
                            loading={isLoadingRangeData || isLoading}
                            rangeData={
                                rangeData?.length === 0 || !rangeData
                                    ? []
                                    : rangeData[type]
                            }
                            handleRangeChangeTable={(e: any) => {
                                handleRangeChangeTable(e)
                                setIsCalendarMode(true)
                            }}
                            isCalendarMode={isCalendarMode}
                            setIsCalendarMode={setIsCalendarMode}
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                        />
                    </Row>
                    <Row>
                        {isMob
                            ? renderFinancialResultsMod()
                            : renderFinancialResultsDesk()
                        }
                    </Row>
                </Row>
            }

            {renderStockData()}
            {renderChart()}

            <Modal
                open={loadModal}
                closable={false}
                footer={null}
                width={'100%'}
                style={{
                    maxWidth: 600,
                    minWidth: 360,
                    padding: '0 10px'
                }}
            >
                <AddFileModal
                    selectedSeller={selectedSeller}
                    onClose={() => setLoadModal(false)}
                />
            </Modal>
        </div>
    );
}
