import { useQuery } from "react-query";
import { backend } from "api";
import {useState} from "react";
import {useDebounce} from "use-debounce";
import {getLaboratoryAppointments, getLaboratoryProducts} from "../../../api/laboratory";

export default function useLaboratoryAppointmentsList() {

  const { data: appointments, ...options } = useQuery(
    [
      "LABORATORY_APPOINTMENT_LIST",
    ],

    () => backend.laboratory.getLaboratoryAppointments(
    ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    appointments,
    ...options,
  };
}
