import { Col, Row } from "antd";
import React, {useState} from "react";
import ProductTable from "../../features/product/ProductTable";

export default function ProductsPage() {

    return (
        <Row style={{ padding: "10px 20px" }}>
            <Col className={"container"} span={24}>
                <ProductTable />
            </Col>
        </Row>
    );
}