import {useQuery} from "react-query";
import {backend} from "api";
import dayjs, {Dayjs} from "dayjs";
import {useEffect, useState} from "react";
import {Checkbox, Input, MenuProps} from "antd";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import useSellerList from "../../../sellers/hooks/useSellerList";
import useProductList from "../../../product/hooks/useProductList";
import {useDebounce} from "use-debounce";

export default function useIssuingPositionsData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [selectedSeller, setSelectedSeller] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
    const [isWithElementsInTable, setIsWithElementsInTable] = useState<boolean>(false);
    const [isPlusMinusPhraseMode, setIsPlusMinusPhraseMode] = useState<boolean>(false);
    const [type, setType] = useState<string>('all');
    const [searchArticle, setSearchArticle] = useState<string>('');
    const [debouncedSearchArticle] = useDebounce(searchArticle, 1000);

    const getLastEndOfWeek = (today: any = dayjs()) => {
        return today.endOf('week')
    };

    const getPreLastEndOfWeek = (today: any = dayjs()) => {
        return today.startOf('week')
    };

    const prevSunday = getPreLastEndOfWeek()
    const lastSunday = getLastEndOfWeek()

    const [dateStart, setDateStart] = useState<any>(prevSunday);
    const [dateEnd, setDateEnd] = useState<any>(lastSunday);

    const handleRangeChangeTable = (dateStrings: any) => {
        setDateStart(dateStrings[0]);
        setDateEnd(dateStrings[1]);
    }

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useSellerList();

    const {
        products,
        search: searchProduct,
        setSearch: setSearchProduct,
    } = useProductList();

    const getProductsFilterItems = () => {
        if (!products) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        products?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedProducts];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedProducts(newSelectedProducts);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `product-${item.id}`,
                });
            }
        });

        selectedProducts?.forEach((item: any) => {
            const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedProducts];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedProducts(newSelectedProducts);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `product-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchProduct}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchProduct(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "product-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    useEffect(() => {
        if (sellers?.[0]?.id) {
            setSelectedSeller(sellers?.[0]?.id)
        }
    }, [sellers])

    const {data: data, ...options} = useQuery(
        [
            "ISSUING_POSITIONS_LIST",
            dateStart,
            dateEnd,
            currentPage,
            currentPageSize,
            selectedSeller,
            selectedProducts,
            type,
            debouncedSearchArticle,
            isWithElementsInTable,
            isPlusMinusPhraseMode
        ],
        () =>
            backend.advertisingMirror.getIssuingPositionsData(
                dateStart,
                dateEnd,
                currentPage.toString(),
                currentPageSize,
                selectedSeller,
                selectedProducts,
                type.toString(),
                debouncedSearchArticle,
                isWithElementsInTable,
                isPlusMinusPhraseMode
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        currentPage,
        setCurrentPage,
        currentPageSize,
        dateStart,
        dateEnd,
        setDateStart,
        setDateEnd,
        lastSunday,
        prevSunday,
        setCurrentPageSize,
        selectedSeller,
        sellers,
        setSelectedSeller,
        searchSellers,
        setSearchSellers,
        type,
        setType,
        searchArticle,
        setSearchArticle,
        isWithElementsInTable,
        setIsWithElementsInTable,
        getLastSunday: getLastEndOfWeek,
        handleRangeChangeTable,
        getProductsFilterItems,
        setIsPlusMinusPhraseMode,
        ...options,
    };
}
