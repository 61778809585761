import {useMutation, useQueryClient} from "react-query";
import {useCallback, useState} from "react";
import {backend} from "api";

export const useCoordinatorPostAnalytics = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [id, setId] = useState<string>('');

    const {
        mutateAsync: createProductUserAnalytic,
        isSuccess: isSuccessCreate,
        isLoading: createLoading,
        isError: isErrorCreate,
    } = useMutation(backend.coordinatorUser.createCoordinatorUserAnalytics, {
        async onSuccess() {
            await Promise.all([queryClient.invalidateQueries(["COORDINATOR_USER_DATA_BY_ID", id])]);
            await Promise.all([queryClient.invalidateQueries(["COORDINATOR_USER_LIST"])]);

        },
    });

    const handleCreate = useCallback(
        async (values: any) => {
            try {
                await createProductUserAnalytic(values);
                await queryClient.invalidateQueries(["COORDINATOR_USER_DATA_BY_ID", values?.coordinatorId]);
                await queryClient.invalidateQueries(["COORDINATOR_USER_LIST"]);
            } catch (e: any) {
                setErrorMessage(e?.response?.data?.message);
            }
        },
        [createProductUserAnalytic]
    );

    return {
        isSuccessCreate,
        handleCreate,
        setId,
        createLoading,
        isErrorCreate,
        errorMessage,
    };
};
