import React, {useState} from "react";
import {Col, message, Modal, Row} from "antd";
import Icon from "@ant-design/icons";
import AnalyticsTableCol from "../components/AnalyticsTableCol";
import priceChangeGraph from "assets/images/price-change-graph.png";
import keywordGraph from "assets/images/keyword-graph.png";
import cashbackGraph from "assets/images/cashback-graph.png";
import ransomGraph from "assets/images/ransom-graph.png";
import advertisingExpensesGraph from "assets/images/advertising-expenses-graph.png";
import KeywordModalContent from "../modal/KeywordModalContent";
import PriceChangeModalContent from "../modal/PriceChangeModalContent";
import ArticleModalContent from "../modal/ArticleModalContent";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import {renderTableText} from "../helpers/renderTableText";
import {AnalyticTableColumnFilter} from "../hooks/useAnalyticsTableColumnFilters";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CashBackModalContent from "../../cashbacks/modal/CashBackModalContent";
import CostPriceModal from "../modal/CostPriceModal";
import OrderModalContent from "../modal/OrderModalContent";
import AdLeftMoneyModal from "../modal/AdLeftMoneyModal";
import ChangeCurrentCurrencyModal from "../modal/ChangeCurrentCurrencyModal";
import {useAuthStore} from "../../auth/hooks/useAuthStore";
import RecommendedPriceModal from "../modal/RecommendedPriceModal";
import AnalyticsTableChart from "../components/AnalyticsTableChart";
import AnalyticsTableNote from "../components/AnalyticsTableNote";

interface AnalyticTableRowProps {
    analytic: Analytics;
    filters: AnalyticTableColumnFilter;
}

export default function AnalyticsTableRow({
                                              analytic,
                                              filters,
                                          }: AnalyticTableRowProps) {

    const {state} = useAuthStore();

    const isAdminOrCoordinator = state?.user?.role?.name === 'Администратор' || state?.user?.role?.name === 'Координатор'

    const [keywordModalOpened, setKeywordModalOpened] = useState<any>({
        isOpen: false,
        id: null
    });

    const [orderModal, setOrderModal] = useState<any>({
        isOpen: false,
        id: null
    });

    const [priceChangeModalOpened, setPriceChangeModalOpened] = useState<any>({
        isOpen: false,
        id: null
    });

    const [cashBackModalOpened, setCashBackModalOpened] = useState<any>({
        isOpen: false,
        id: null
    });

    const [articleModal, setArticleModal] = useState<any>({
        productId: '',
        analyticsUserId: '',
        articule: '',
        isUpdate: false,
        isOpen: false
    });

    const [costPriceModal, setCostPriceModal] = useState<any>({
        productId: '',
        isOpen: false
    });

    const [currentCurrencyModal, setCurrentCurrencyModal] = useState<any>({
        articule: '',
        fullPrice: '',
        spp: '',
        isOpen: false
    });

    const [recommendedPriceModal, setRecommendedPriceModal] = useState<any>({
        id: null,
        isOpen: false
    });

    const [adLeftMoneyModal, setAdLeftMoneyModal] = useState<any>({
        productId: '',
        isOpen: false
    });


    const priceWithDiscountWb = (Number(analytic?.currentFullPrice) - (Number(analytic?.currentFullPrice) * Number(analytic?.discount) / 100))?.toFixed(2)
    const priceWithDiscountSpp = (Number(priceWithDiscountWb) - (Number(priceWithDiscountWb) * analytic?.spp / 100)).toFixed(2)

    return (
        <Row className={"analytics-row-container"} gutter={1}>
            <Col
                className={"gray"}
                style={{
                    padding: "9px",
                    textAlign: "center",
                    width: "108px",
                    borderRadius: "8px 0 0 8px",
                }}
            >
                <div className={"analytics-col-title__wrap"}>
                    <CopyToClipboard text={`${analytic?.wbArticule}`}
                                     onCopy={() => message.success('Текст скопирован')}>
                        <div
                            className={"analytics-col-title"}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {analytic?.wbArticule}
                        </div>
                    </CopyToClipboard>
                    <a href={analytic?.linkOnSite} target="_blank">
                        <img
                            src={analytic.photo}
                            style={{width: "100%", margin: "9px 0", minHeight: 120}}
                        />
                    </a>
                    {analytic?.tsName !== "-" &&
                    <div className={"analytics-col-title"}>{analytic?.tsName}</div>
                    }
                </div>
            </Col>
            <Col className={"analytics-right-wrapper"}>
                <div className="analytics-row">
                    {filters.productNameVisible && (
                        <AnalyticsTableCol
                            title={"Предмет"}
                            text={analytic.subjectName}
                            width={"6%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.articleVisible && (
                        <AnalyticsTableCol
                            title={"Артикул поставщика"}
                            text={analytic?.seller?.article}
                            width={"8%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.selfCostVisible && (
                        <AnalyticsTableCol
                            icon={
                                <Icon
                                    component={editIcon}
                                    style={{cursor: "pointer"}}
                                    onClick={() =>
                                        setCostPriceModal({
                                            ...costPriceModal,
                                            productId: analytic?.productId,
                                            articule: analytic?.articule,
                                            sellerId: analytic?.seller?.userId,
                                            productSizeId: analytic?.productSizeId === 0 ? null : analytic?.productSizeId,
                                            isOpen: true
                                        })
                                    }
                                />
                            }
                            title={"Себестоимость, руб"}
                            text={analytic.selfCost}
                            width={"10%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.wbStorageRemainingVisible && (
                        <AnalyticsTableCol
                            isShowHover={true}
                            hoverData={analytic?.wbStocksBySizes}
                            title={"Остаток, на складе WB, шт"}
                            text={analytic.wbTotalStorageCount}
                            width={"12%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.storageRemainingWisible && (
                        <AnalyticsTableCol
                            isShowHover={true}
                            hoverData={analytic?.ownStocksBySizes}
                            title={"Остаток, на складе, шт"}
                            text={analytic.ownTotalStorageCount}
                            width={"12%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.productOnWayVisible && (
                        <AnalyticsTableCol
                            title={"Товар в пути"}
                            text={analytic.productsOnWay}
                            width={"7%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.orderedInChinaVisible && (
                        <AnalyticsTableCol
                            title={"Заказано в Китае"}
                            text={analytic.orderedInChina}
                            width={"7%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.avgOrdersVisible && (
                        <AnalyticsTableCol
                            title={"Среднее заказов в день за период"}
                            text={analytic.avgOrdersPerDay}
                            width={"12%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.avgSalesVisible && (
                        <AnalyticsTableCol
                            title={"Среднее кол-во продаж в день  (отчет продаж)"}
                            text={analytic.avgSalesPerDay}
                            width={"12%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.avgSalesPerDayFinRepVisible && (
                        <AnalyticsTableCol
                            title={"Среднее кол-во продаж в день (фин.отчет)"}
                            text={analytic.avgSalesPerDayFinRep}
                            width={"12%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.salesPercentVisible && (
                        <AnalyticsTableCol
                            title={"% Выкупа"}
                            text={analytic.salesPercent}
                            width={"7%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}

                    {filters.commissionVisible && (
                        <AnalyticsTableCol
                            title={"Комиссия, %"}
                            text={analytic.commissionPercent}
                            width={"8%"}
                            backgroundColor={"#E6E7E8"}
                        />
                    )}
                </div>

                <div className={"analytics-row gray"}>
                    {filters.logisticVisible && (
                        <AnalyticsTableCol
                            title={"Логистика, руб"}
                            text={analytic.deliveryRub}
                            width={"6%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.storageVisible && (
                        <AnalyticsTableCol
                            title={"Хранение, руб"}
                            text={analytic.storage}
                            width={"6%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.adExpensesVisible && (
                        <AnalyticsTableCol
                            title={"Затраты на рекламу, руб"}
                            text={analytic.adExpenses}
                            width={"9%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.adLeftMoneyVisible && (
                        <AnalyticsTableCol
                            icon={
                                <Icon
                                    component={editIcon}
                                    style={{cursor: "pointer"}}
                                    onClick={() =>
                                        setAdLeftMoneyModal({
                                            ...adLeftMoneyModal,
                                            productId: analytic?.productId,
                                            isOpen: true
                                        })
                                    }
                                />
                            }
                            title={"Остаток рекл бюджета, руб"}
                            text={analytic.adLeftMoney}
                            width={"8%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.profitPerMonthVisible && (
                        <AnalyticsTableCol
                            title={"Выручка за 30 дней, руб"}
                            text={analytic.profitPerMonth}
                            width={"9%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.salePerMonthVisible && (
                        <AnalyticsTableCol
                            title={"Продажа за 30 суток, руб"}
                            text={analytic.salesPerMonth}
                            width={"8%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.currentPriceVisible && (
                        isAdminOrCoordinator
                            ?
                            <AnalyticsTableCol
                                icon={
                                    <Icon
                                        component={editIcon}
                                        style={{cursor: "pointer"}}
                                        onClick={() =>
                                            setCurrentCurrencyModal({
                                                id: analytic?.productId,
                                                recommendedPrice: analytic?.recommendedPrice,
                                                autoChangeLt: analytic?.autoChangeLt,
                                                articule: analytic?.articule,
                                                fullPrice: analytic?.currentFullPrice,
                                                spp: analytic?.spp,
                                                isOpen: true
                                            })
                                        }
                                    />
                                }
                                title={"Текущая цена, руб"}
                                text={priceWithDiscountSpp}
                                width={"7%"}
                                backgroundColor={
                                    analytic?.recommendedPrice && Math.abs(analytic?.currentFullPrice - analytic?.recommendedPrice) > analytic?.autoChangeLt
                                        ? "rgba(245,102,102,0.4)"
                                        : "#FFFFFF"
                                }
                                isShowHover={true}
                                hoverData={{
                                    "Полная цена": analytic?.currentFullPrice,
                                    "Цена со скидкой ВБ": priceWithDiscountWb,
                                    "Цена со скидкой СПП": priceWithDiscountSpp,
                                }}
                            />
                            :
                            <AnalyticsTableCol
                                title={"Текущая цена, руб"}
                                text={priceWithDiscountSpp}
                                width={"10%"}
                                backgroundColor={"#FFFFFF"}
                                isShowHover={true}
                                hoverData={{
                                    "Полная цена": analytic?.currentFullPrice,
                                    "Цена со скидкой ВБ": priceWithDiscountWb,
                                    "Цена со скидкой СПП": priceWithDiscountSpp,
                                }}
                            />)
                    }


                    {filters.recommendedPriceVisible && (
                        <AnalyticsTableCol
                            icon={
                                <Icon
                                    component={editIcon}
                                    style={{cursor: "pointer"}}
                                    onClick={() =>
                                        setRecommendedPriceModal({
                                            id: analytic?.productId,
                                            isOpen: true
                                        })
                                    }
                                />
                            }
                            title={"Рекомендованная цена, руб"}
                            text={analytic.recommendedPrice}
                            isShowHover={true}
                            hoverData={{
                                "Является ли автоматической": analytic?.isAutoChangedPrice ? 'Да' : 'Нет' || '-',
                                "Рекомендованная цена": analytic?.recommendedPrice || '-',
                                "Предел изменения до": analytic?.autoChangeLt || '-',
                                "Предел изменения после": analytic?.autoChangeGt || '-',
                            }}
                            width={"9%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.expensePerBuyoutVisible && (
                        <AnalyticsTableCol
                            title={"Расход на выкуп, руб"}
                            text={analytic.expensePerBuyout}
                            width={"9%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.expensePerCashbackVisible && (
                        <AnalyticsTableCol
                            title={"Расход на кешбек, руб"}
                            text={analytic.expensePerCashback}
                            width={"7%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.needToShipVisible && (
                        <AnalyticsTableCol
                            title={"Запас товара на дней"}
                            text={analytic.needToShip}
                            width={"7%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}

                    {filters.notesVibislbe && (
                        <AnalyticsTableNote
                            onOpen={() =>
                                setArticleModal({
                                    ...articleModal,
                                    analyticsUserId: analytic?.analyticsUserId,
                                    articule: analytic?.articule,
                                    productId: analytic?.productId,
                                    isUpdate: true,
                                    isOpen: true
                                })
                            }
                            text={analytic?.lastNote?.noteText}
                            count={analytic?.countNotes}
                            isShowHover={true}
                            hoverData={analytic?.lastNote}
                        />
                    )}

                    {filters.companyVisible && (
                        <AnalyticsTableCol
                            title={"Инвестор"}
                            text={analytic.seller.companyName}
                            width={"7%"}
                            backgroundColor={"#FFFFFF"}
                        />
                    )}
                </div>
                <div className={"analytics-row"}>
                    <AnalyticsTableChart
                        onOpen={() => setPriceChangeModalOpened({
                            isOpen: true,
                            id: analytic.productId
                        })}
                        img={priceChangeGraph}
                        name={"График по изменению цены"}
                        isShowHover={true}
                        hoverData={analytic?.last4Prices}
                    />
                    <AnalyticsTableChart
                        onOpen={() => setKeywordModalOpened({
                            isOpen: true,
                            id: analytic.productId
                        })}
                        img={keywordGraph}
                        name={"График по ключевым словам"}
                    />

                    <AnalyticsTableChart
                        onOpen={() => setCashBackModalOpened({
                            isOpen: true,
                            id: analytic.productId
                        })}
                        img={cashbackGraph}
                        name={"График по кешбекам"}
                        isShowHover={true}
                        hoverData={analytic?.last4Cashbacks}
                    />

                    <AnalyticsTableChart
                        onOpen={() => setOrderModal({
                            isOpen: true,
                            id: analytic.productId
                        })}
                        img={ransomGraph}
                        name={"График заказов"}
                        isShowHover={true}
                        hoverData={analytic?.last4Orders}
                    />

                    <AnalyticsTableChart
                        onClick={() => {
                        }}
                        img={advertisingExpensesGraph}
                        name={"График по реклам расходам"}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: "1",
                        }}
                    >
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                    backgroundColor: "#E6E7E8",
                                    height: "fit-content",
                                    color: "#4B4B4B",
                                }}
                            >
                                ABC-Анализ
                            </Col>
                        </Row>
                        <Row style={{flexGrow: "1"}}>
                            <Col
                                className={"analytics-col-abc"}
                                span={8}
                                style={{borderLeft: "0px"}}
                            >
                                <div className={"analytics-col-title"}>Продажа</div>
                                <div>{renderTableText(analytic.abcSale)}</div>
                            </Col>
                            <Col className={"analytics-col-abc"} span={7}>
                                <div className={"analytics-col-title"}>Выручка</div>
                                <div>{renderTableText(analytic.abcProfit)}</div>
                            </Col>
                            <Col
                                className={"analytics-col-abc"}
                                span={9}
                                style={{borderRight: "0px"}}
                            >
                                <div className={"analytics-col-title"}>Суммарный</div>
                                <div>{renderTableText(analytic.abcSummary)}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Modal
                open={keywordModalOpened.isOpen}
                closable={false}
                footer={null}
                width={1169}
            >
                <KeywordModalContent
                    onClose={() => setKeywordModalOpened({id: null, isOpen: false})}
                    id={keywordModalOpened.id}
                />
            </Modal>
            <Modal
                open={orderModal.isOpen}
                closable={false}
                footer={null}
                width={1169}
            >
                <OrderModalContent
                    onClose={() => setOrderModal({id: null, isOpen: false})}
                    id={orderModal.id}
                />
            </Modal>
            <Modal
                open={priceChangeModalOpened.isOpen}
                closable={false}
                footer={null}
                width={1169}
            >
                <PriceChangeModalContent
                    onClose={() => setPriceChangeModalOpened({id: null, isOpen: false})}
                    id={priceChangeModalOpened.id}
                />
            </Modal>
            <Modal
                open={cashBackModalOpened.isOpen}
                closable={false}
                footer={null}
                width={1169}
            >
                <CashBackModalContent
                    onClose={() => setCashBackModalOpened({id: null, isOpen: false})}
                    id={cashBackModalOpened.id}
                />
            </Modal>
            <Modal
                open={articleModal.isOpen}
                closable={false}
                footer={null}
                width={770}
            >
                <ArticleModalContent
                    productId={articleModal.productId}
                    articleModal={articleModal}
                    onClose={() => setArticleModal({...articleModal, isOpen: false})}
                    finishUpdate={() => setArticleModal({...articleModal, isUpdate: false})}
                />
            </Modal>

            <Modal
                open={costPriceModal.isOpen}
                closable={false}
                footer={null}
                width={519}
            >
                <CostPriceModal
                    articule={costPriceModal.articule}
                    sellerId={costPriceModal.sellerId}
                    productSizeId={costPriceModal.productSizeId}
                    productId={costPriceModal.productId}
                    onClose={() => setCostPriceModal({...costPriceModal, isOpen: false})}
                />
            </Modal>

            <Modal
                open={adLeftMoneyModal.isOpen}
                closable={false}
                footer={null}
                width={519}
            >
                <AdLeftMoneyModal
                    productId={adLeftMoneyModal.productId}
                    onClose={() => setAdLeftMoneyModal({...adLeftMoneyModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={currentCurrencyModal.isOpen}
                closable={false}
                footer={null}
                width={700}
            >
                <ChangeCurrentCurrencyModal
                    id={currentCurrencyModal?.id}
                    articule={currentCurrencyModal.articule}
                    fullPrice={currentCurrencyModal?.fullPrice}
                    spp={currentCurrencyModal?.spp}
                    recommendedPrice={currentCurrencyModal?.recommendedPrice}
                    autoChangeLt={currentCurrencyModal?.autoChangeLt}
                    onClose={() => setCurrentCurrencyModal({...currentCurrencyModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={recommendedPriceModal.isOpen}
                closable={false}
                footer={null}
                width={700}
            >
                <RecommendedPriceModal
                    id={recommendedPriceModal?.id}
                    onClose={() => setRecommendedPriceModal({id: null, isOpen: false})}
                />
            </Modal>
        </Row>
    );
}
