// export function addSpacesToNumber(number: any) {
//     // Округляем число до двух знаков после запятой
//     const roundedNumber = Math.round(number * 100) / 100;
//
//     // Преобразуем число в строку с двумя знаками после запятой
//     let str = roundedNumber.toFixed(2);
//
//     // Разделяем целую и десятичную части
//     const parts = str.split('.');
//     let integerPart = parts[0];
//     const decimalPart = parts[1];
//
//     // Разделяем целую часть на группы по три символа
//     let groups = [];
//     while (integerPart.length > 3) {
//         groups.unshift(integerPart.substr(-3));
//         integerPart = integerPart.substr(0, integerPart.length - 3);
//     }
//     groups.unshift(integerPart);
//
//     // Объединяем группы с пробелами
//     let formattedNumber = groups.join(' ');
//
//     // Если есть десятичная часть, добавляем ее с точкой
//     if (decimalPart) {
//         formattedNumber += '.' + decimalPart;
//     }
//
//     return formattedNumber;
// }

export function addSpacesToNumber(number: any) {
    // Преобразуем число в строку
    let str = Math.round(number).toString();

    // Разделяем строку на группы по три символа
    let groups = [];
    while (str.length > 3) {
        groups.unshift(str.substr(-3));
        str = str.substr(0, str.length - 3);
    }
    groups.unshift(str);

    // Объединяем группы с пробелами
    return groups.join(' ');
}