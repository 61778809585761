import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useArticleCnsForProductsArrival() {

    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearch] = useDebounce(searchText, 1000);

    const {data: articleCns, ...options} = useQuery(
        [
            "ARTICLE_CNS_FOR_PRODUCT_ARRIVAL_DATA",
            debouncedSearch
        ],
        () =>
            backend.storage.getArticleCnsForProductsArrival(
                debouncedSearch
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        articleCns,
        searchText,
        setSearchText,
        ...options,
    };
}
