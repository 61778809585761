import AdditionalDeliveriesTable from "features/storage/pages/additionalDeliveries/AdditionalDeliveriesTable";
import React from "react";
import AdvertisingMirrorTable from "../../features/advertising-mirror/pages/advertisingMirror/AdvertisingMirrorTable";

export default function AdvertisingMirrorTab() {
    return (
        <div style={{marginTop: "20px"}}>
            <AdvertisingMirrorTable/>
        </div>
    );
}
