import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import DownloadButton from "../components/DownloadButton";
import FileUploader from "../components/FileUploader";
import {MyPdfComponent} from "../components/MyPdfComponent";
import {getFreeKizezInfoForPDF} from "../api/declaration";
import {saveAs} from 'file-saver';
import {pdf} from '@react-pdf/renderer';
import useDeclarationDataOld from "../hooks/oldDeclaration/useDeclarationDataOld";
import {useDeclarationAddOld} from "../hooks/oldDeclaration/useDeclarationAddOld";
import useDeclarationDataBuIdOld from "../hooks/oldDeclaration/useDeclarationById";
import {useDeclarationDeleteOld} from "../hooks/oldDeclaration/useDeclarationDeleteOld";
import {useDeclarationUpdateOld} from "../hooks/oldDeclaration/useDeclarationUpdateOld";

export default function Declarations({onClose, declarationState, setDeclarationState}: any) {

    const [form] = Form.useForm();

    const {
        declaration,
        isError,
        isLoading,
        currentPage,
        currentPageSize,
        setCurrentPage,
        setCurrentPageSize,
    } = useDeclarationDataOld();

    const {
        declaration: declarationById,
        setId,
        isLoading: isLoadingDeclarationById,
    } = useDeclarationDataBuIdOld();

    const {
        handleCreate,
        isErrorCreate,
        errorMessage,
        createLoading,
        isSuccessCreate,
    } = useDeclarationAddOld()

    const {
        isError: isErrorDeclarationUpdate,
        handleUpdate,
        errorMessage: declarationUpdateErrorMessage,
        isLoading: isLoadingUpdateDeclaration,
        isSuccess: declarationSuccessUpdate,
    } = useDeclarationUpdateOld();

    const {
        handleDelete: handleDeclarationDelete,
        isError: isErrorDeleteDeclaration,
        errorMessage: deleteDeclarationMessage,
        isSuccess: successDeleteDeclaration
    } = useDeclarationDeleteOld();


    const [size, setSize] = useState<string>("");
    const [article, setArticle] = useState<string>("");

    useEffect(() => {
        if (isSuccessCreate) {
            message.success('Вы успешно добавили новую декларацию')
            form.resetFields()
        }
    }, [isSuccessCreate])

    useEffect(() => {
        if (declarationSuccessUpdate) {
            message.success('Вы успешно обновили декларацию')
        }
    }, [declarationSuccessUpdate])

    useEffect(() => {
        if (isErrorDeleteDeclaration) {
            message.error(deleteDeclarationMessage)
        }
    }, [isErrorDeleteDeclaration])

    useEffect(() => {
        if (successDeleteDeclaration) {
            message.success('Вы успешно удалили декларацию')
        }
    }, [successDeleteDeclaration])

    useEffect(() => {
        form.setFieldsValue({
            title: declarationById?.title,
            gtin: declarationById?.gtin
        })
    }, [declarationById])

    const onFinish = (values: any) => {
        handleCreate(values)
    };

    const onFinishUpdate = (values: any) => {
        handleUpdate(values, declarationState.idForEdit)
    };

    const onDeleteDeclaration = (id: any) => {
        handleDeclarationDelete(id)
    }

    // const {
    //     data,
    //     id,
    //     size: sizeTest,
    //     article: articleTest,
    //     type,
    //     setId: setIdTest,
    //     setSize: setSizeTest,
    //     setArticle: setArticleTest,
    //     setType,
    // } = useFreeKizesInfoForPDF()


    const getTest = (id: any) => {

        const size = ''
        const article = ''
        const type = ''
        // console.log(id)
        getFreeKizezInfoForPDF(id, size, article, type)
            .then((response) => {
                downloadPdf(response)
            })
            .catch((e) => {

            })
            .finally(() => {

            })
    }

    const downloadPdf = async (pdfData: any) => {
        const pdfName = 'example.pdf'; // PDF file name

        // Create an instance of the PDF document
        const pdfDocument = (
            <MyPdfComponent data={pdfData}/>
        );
        // Render the PDF document to a blob
        const pdfBlob = await pdf(pdfDocument).toBlob();

        // Save the blob as a file
        saveAs(pdfBlob, pdfName);
    };


    const renderDeclarationTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (declaration?.data) {
            return (
                <>
                    <Row
                        justify={"space-between"}
                        style={{padding: "10px 40px 5px 30px"}}
                    >
                    </Row>
                    <div className="table-container">
                        <table className={"kiz-table"}>
                            <thead>
                            <th style={{}}>Название</th>
                            <th style={{}}>gtin</th>
                            <th style={{}}>Редактировать</th>
                            <th style={{}}>Выгрузить свободные КИЗы</th>
                            <th style={{}}>Выгрузить растаможенные КИЗы</th>
                            <th style={{}}>Добавить растаможенные КИЗы</th>
                            <th style={{}}>Удаление</th>
                            </thead>
                            <tbody>
                            {declaration?.data?.map((item: any) => (
                                <tr>
                                    <td style={{
                                        maxWidth: 50,
                                    }}>
                                        {item.title}
                                    </td>
                                    <td style={{}}>
                                        {item.gtin}
                                    </td>
                                    <td style={{}}>
                                        <Button
                                            className={"button"}
                                            type={"primary"}
                                            style={{width: "100%", fontSize: "12px"}}
                                            htmlType={"submit"}
                                            onClick={() => {
                                                setDeclarationState({state: 'declarationUpdate', idForEdit: item.id})
                                                setId(item.id)
                                            }}
                                        >
                                            Ред.
                                        </Button>
                                    </td>
                                    <td style={{}}>
                                        <DownloadButton
                                            isWithQuery={false}
                                            fileUrl={`${process.env.REACT_APP_API_URL_KIZ}declarations/${item.id}/freeKizes`}
                                            size={size}
                                            article={article}
                                        />
                                    </td>
                                    <td style={{}}>
                                        {/*<Button*/}
                                        {/*    className="button"*/}
                                        {/*    type="primary"*/}
                                        {/*    style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}*/}
                                        {/*    htmlType="submit"*/}
                                        {/*    onClick={() => getTest(item.id)}*/}
                                        {/*>*/}
                                        {/*    Выгрузить*/}
                                        {/*</Button>*/}
                                        <DownloadButton
                                            isWithQuery={true}
                                            fileUrl={`${process.env.REACT_APP_API_URL_KIZ}declarations/${item.id}/freeKizes?type=all`}
                                            size={size}
                                            article={article}
                                        />
                                    </td>
                                    <td style={{}}>
                                        <div style={{marginTop: 5}}/>
                                        <FileUploader
                                            actionUrl={`${process.env.REACT_APP_API_URL_KIZ}import/customed/${item.id}`}
                                            title={'Загрузить'}
                                        />
                                    </td>
                                    <td style={{}}>
                                        <Button
                                            className="button"
                                            type="primary"
                                            style={{width: "100%", fontSize: "12px", whiteSpace: 'pre-line'}}
                                            htmlType="submit"
                                            onClick={() => onDeleteDeclaration(item.id)}
                                        >
                                            Удалить
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={parseInt(currentPageSize)}
                        total={declaration.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    const renderAddDeclaration = () => {
        return (
            <>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={
                            <div>
                                <span>Название</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите название",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <div>
                                <span>Gtin</span>
                                <Tooltip
                                    // title={getTooltipText()}
                                    placement={"right"}
                                >

                                </Tooltip>
                            </div>
                        }
                        name="gtin"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуйста, введите gtin",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                        <Col span={12}>
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "100%"}}
                                htmlType={"submit"}
                                loading={createLoading}
                            >
                                Добавить
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {isErrorCreate && (
                    <div
                        className={"ant-form-item-explain-error"}
                        style={{color: "#ff4d4f"}}
                    >
                        {errorMessage}
                    </div>
                )}
            </>
        );

    };

    const renderDeclarationById = () => {
        if (isLoadingDeclarationById) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (declarationById) {
            return (
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onFinishUpdate}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span>Название</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите название",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Gtin</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >

                                    </Tooltip>
                                </div>
                            }
                            name="gtin"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите gtin",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Row gutter={16} style={{justifyContent: 'flex-end'}}>
                            <Col span={12}>
                                <Button
                                    className={"button"}
                                    type={"primary"}
                                    style={{width: "100%"}}
                                    htmlType={"submit"}
                                    loading={isLoadingUpdateDeclaration}
                                >
                                    Сохранить
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {isErrorDeclarationUpdate && (
                        <div
                            className={"ant-form-item-explain-error"}
                            style={{color: "#ff4d4f"}}
                        >
                            {errorMessage}
                        </div>
                    )}

                </>
            );
        }
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Декларации"} onClose={onClose}/>
            {declarationState.state === "declarationList" &&
            <>
                <div style={{textAlign: 'center', marginBottom: 10,}}>
                    Параметры для выгрузки свободных кизов
                </div>
                <Col style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                }}>
                    <Input
                        value={size}
                        onChange={(text: any) => setSize(text.target.value)}
                        placeholder="Размер"
                        size="large"
                    />
                    <Input
                        value={article}
                        onChange={(text: any) => setArticle(text.target.value)}
                        placeholder="Артикул"
                        size="large"
                    />
                </Col>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => {
                                    setDeclarationState({idForEdit: null, state: 'declarationAdd'})
                                    setId('')
                                }}
                            >
                                Добавить декларацию
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderDeclarationTable()}
                    </Col>
                </Row>
            </>
            }
            {declarationState.state === "declarationAdd" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setDeclarationState({...declarationState, state: 'declarationList'})}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderAddDeclaration()}
                    </Col>
                </Row>
            </>
            }
            {declarationState.state === "declarationUpdate" &&
            <>
                <Row justify={"space-between"}
                     style={{display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'center'}}>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                    }}>
                        <Col>
                            {" "}
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "171px", fontSize: "12px"}}
                                onClick={() => setDeclarationState({...declarationState, state: 'declarationList'})}
                            >
                                Назад
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className={"container"} style={{marginTop: "10px"}}>
                    <Col span={24} className={"analytic-rows-container"}>
                        {renderDeclarationById()}
                    </Col>
                </Row>
            </>
            }
        </div>
    );
}
