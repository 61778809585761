import {Col, Row} from "antd";
import KizTablet from "features/kiz/pages/KizTablet";
import React from "react";
import TopologyTable from "../../features/storage/pages/topology/TopologyTable";
import TasksTable from "../../features/kiz/pages/tasks/TasksTable";

export default function TasksTab() {
    return (
        <Row style={{padding: "10px 0"}}>
            <Col className={"container"} span={24}>
                <TasksTable/>
            </Col>
        </Row>
    );
}
