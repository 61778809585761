// Requests
import {api} from "../index";
import {getToken} from "../utils/tokenStorage";
import {message} from "antd";
import dayjs from "dayjs";
import axios from "axios";

export async function getFinanceReportsData(
    page?: string,
    pageSize?: string,
    ext_ref?: string,
    dateSortStart?: string,
    dateSortEnd?: string,
    search?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    if (ext_ref) {
        params.append("ext_ref", ext_ref);
    }

    if (search) {
        params.append("report_id", search);
    }

    if (dateSortStart && dateSortEnd) {
        params.append("start_date", dateSortStart);
        params.append("end_date", dateSortEnd);
    }

    if (ext_ref) {
        const response = await api.get<any>(`/frs`, {
            params,
        });
        return response.data.data;
    }

}

export async function getDocumentsData(
    page?: string,
    pageSize?: string,
    ext_ref?: string,
    dateSortStart?: string,
    dateSortEnd?: string,
    selectedCategories?: string,
    debouncedSearch?: string,
) {

    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    if (ext_ref) {
        params.append("ext_ref", ext_ref);
    }

    if (selectedCategories !== 'all' && selectedCategories) {
        params.append("category_id", selectedCategories);
    }

    if (debouncedSearch) {
        params.append("search", debouncedSearch);
    }

    if (dateSortStart && dateSortEnd) {
        params.append("start_date", dateSortStart);
        params.append("end_date", dateSortEnd);
    }

    if (ext_ref) {
        const response = await api.get<any>(`/documents`, {
            params,
        });

        return response.data.data;
    }
}

export async function getDocumentsCategoriesData() {

    const response = await api.get<any>(`/documents/categories`);

    return response.data.data;
}

export const downloadFinRecordFile = (
    url?: any,
    id?: any
) => {
    const token = getToken();

    message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')

    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `document-${id}.zip`);
        link.setAttribute('target', '_blank');
        // link.type = 'application/xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error downloading file:', error);
        message.error('Ошибка загрузки. Обратитесь в поддержку')
    });
};

export const downloadFinRecordFileWithoutNot = (
    url?: any,
    id?: any
) => {

    console.log('here')
    const token = getToken();

    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `document-${id}.xlsx`);
        link.setAttribute('target', '_blank');
        link.type = 'application/xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error downloading file:', error);
        message.error('Ошибка загрузки. Обратитесь в поддержку')
    });
};