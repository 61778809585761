import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useSizesListForGTDProductByArticle(article: any) {

    const [limit, setLimit] = useState<string>("10");
    const [searchSize, setSearchSize] = useState<string>("");
    const [debouncedSpp] = useDebounce(searchSize, 1000);

    const {data: sizesList, ...options} = useQuery(
        [
            "SIZES_DATA_FOR_GTD_PRODUCT_BY_ARTICLE",
            limit,
            debouncedSpp,
            article,
        ],
        () =>
            backend.storage.getSizesListForGTDProductByArticle(
                limit,
                debouncedSpp,
                article,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        sizesList,
        searchSize,
        setSearchSize,
        ...options,
    };
}
