import {
    Button,
    Col,
    DatePicker, Dropdown,
    Form,
    Input, MenuProps, message, Modal, Pagination,
    Row,
    Select, Spin,
    Switch, Tooltip,
} from "antd";
import ModalHeader from "features/shared/ModalHeader";
import React, {useEffect, useState} from "react";
import useDeclarationDataBuId from "../../hooks/declaratiion/useDeclarationById";
import {useDeclarationUpdate} from "../../hooks/declaratiion/useDeclarationUpdate";
import {LoadingOutlined} from "@ant-design/icons";


export default function EditDeclarationModal({onClose, id}: any) {

    const [form] = Form.useForm();

    const {
        declaration: declarationById,
        setId,
        isLoading: isLoadingDeclarationById,
    } = useDeclarationDataBuId();

    const {
        isError: isErrorDeclarationUpdate,
        handleUpdate,
        errorMessage: declarationUpdateErrorMessage,
        isLoading: isLoadingUpdateDeclaration,
        isSuccess: declarationSuccessUpdate,
    } = useDeclarationUpdate();


    const onFinishUpdate = (values: any) => {
        handleUpdate(values, id)
    };

    useEffect(() => {
        if (declarationSuccessUpdate) {
            message.success('Вы успешно обновили декларацию')
            onClose()
        }
    }, [declarationSuccessUpdate])

    useEffect(() => {
        if (isErrorDeclarationUpdate) {
            message.error(declarationUpdateErrorMessage)
            onClose()
        }
    }, [isErrorDeclarationUpdate])


    useEffect(() => {
        form.setFieldsValue({
            title: declarationById?.title,
            gtin: declarationById?.gtin
        })
    }, [declarationById])

    useEffect(() => {
        setId(id)
    }, [id])


    const renderDeclarationById = () => {
        if (isLoadingDeclarationById) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (declarationById) {
            return (
                <>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onFinishUpdate}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span>Название</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >
                                    </Tooltip>
                                </div>
                            }
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите название",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={
                                <div>
                                    <span>Gtin</span>
                                    <Tooltip
                                        // title={getTooltipText()}
                                        placement={"right"}
                                    >

                                    </Tooltip>
                                </div>
                            }
                            name="gtin"
                            rules={[
                                {
                                    required: true,
                                    message: "Пожалуйста, введите gtin",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                            loading={isLoadingUpdateDeclaration}
                        >
                            Сохранить
                        </Button>
                    </Form>
                </>
            );
        }
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader title={"Декларации"} onClose={onClose}/>
            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24} className={"analytic-rows-container"}>
                    {renderDeclarationById()}
                </Col>
            </Row>
        </div>
    );
}
