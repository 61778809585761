import { getToken, removeToken } from "utils/tokenStorage";
import { useCallback } from "react";
import { useAuthStore } from "features/auth/hooks/useAuthStore";
import { useWhoAmi } from "features/auth/hooks/useWhoAmI";

export function useAuthCheck() {
    const { refetch } = useWhoAmi();
    const authStore = useAuthStore();

    const checkAuth = useCallback(async () => {
        if (!getToken()) return;

        try {
            const { data: user } = await refetch();
            authStore.actions.login(user);
            return true;
        } catch (err) {
            authStore.actions.logout();
            removeToken();
            return false;
        }
    }, [authStore.actions, refetch]);

    return {
        checkAuth,
    };
}
