import {useQuery} from "react-query";
import {backend} from "api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useDebounce} from "use-debounce";

export default function useAnalyticsOrderChartData(id: any) {

    const end = dayjs().format('DD.MM.YYYY');
    const start = dayjs().subtract(3, 'month').format('DD.MM.YYYY');

    const [period_start_date, setPeriod_start_date] = useState<any>(start);
    const [period_end_date, setPeriod_end_date] = useState<any>(end);


    const {data: data, ...options} = useQuery(
        [
            "ANALYTICS_PRICE_DATA",
            id,
            period_start_date,
            period_end_date
        ],
        () =>
            backend.analytics.getAnalyticsOrderChartData(
                id.toString(),
                period_start_date,
                period_end_date
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        data,
        setPeriod_start_date,
        setPeriod_end_date,
        ...options,
    };
}
