import {Routes, Route, Navigate} from "react-router-dom";
import LoginPage from "pages/auth/login";

export default function PublicRoutes() {
    return (
        <Routes>
            <Route path="/" element={<LoginPage/>}></Route>
            <Route path="*" element={<Navigate to={"/"}/>}/>
        </Routes>
    );
}
