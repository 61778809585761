import {Button, Col, Form, Input, message, Row, Select} from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";
import { ReactComponent as selectIcon } from "assets/images/select-icon.svg";
import { useUserCreate } from "features/users/hooks/useUserCreate";
import useRoleList from "features/roles/hooks/useRoleList";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";

interface RoleOption {
    label: string;
    value: string;
}

export default function AddUserModalContent({ onClose }: any) {

    const [form] = Form.useForm();

    const {
        handleCreate,
        isError,
        errorMessage,
        isLoading,
        isSuccess
    } = useUserCreate();

    const [rolesOptions, setRolesOptions] = useState<RoleOption[]>([]);
    const { roles } = useRoleList();

    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    useEffect(() => {
        let newRolesOptions: RoleOption[] = [];
        roles?.forEach((role) => {
            newRolesOptions.push({
                value: role.id.toString(),
                label: role.name,
            });
        });
        setRolesOptions(newRolesOptions);
    }, [roles]);

    const onAdd = (values: any, onClose: any) => {
        //sending only updated values

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (values.phone.length < 18) {
            setIsPhoneError(true);
            return
        }

        if (!emailRegex.test(values.email)) {
            setIsEmailError(true);
            return
        }

        handleCreate(values, onClose);
    };

    useEffect(() =>{
        if (isSuccess){
            message.success('Вы успешно добавили новую декларацию')
            form.resetFields()
        }
        if (isError){
            message.error(errorMessage)
        }
    },[isSuccess, isError])

    return (
        <div className={"modal-wrapper"} style={{ padding: "30px" }}>
            <ModalHeader title={"Добавление пользователя"} onClose={() =>{
                form.resetFields()
                onClose()
            }} />
            <Form
                form={form}
                layout={"vertical"}
                onFinish={(values) => onAdd(values, onClose)}
            >
                <Form.Item
                    label={"Выберите роль пользователя"}
                    name="roleId"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, выберите роль пользователя",
                        },
                    ]}
                >
                    <Select
                        options={rolesOptions}
                        suffixIcon={<Icon component={selectIcon} />}
                    />
                </Form.Item>

                <Form.Item
                    label={"Введите имя"}
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите имя",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Введите фамилию"}
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите фамилию",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Номер телефона"}
                    name="phone"
                    rules={[{ required: true, message: 'Пожалуйста введите номер' }]}
                    validateStatus={isPhoneError ? 'error' : ''}
                    help={isPhoneError ? 'Пожалуйста введите корректный номер' : null}
                >
                    <InputMask
                        mask="+7 (999) 999-99-99"
                        maskChar=""
                        onFocus={() => setIsPhoneError(false)}
                    >
                        {(inputProps: any) => (
                            <Input
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item
                    label={"Ник telegram"}
                    name="tg"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите ник telegram",
                        },
                    ]}
                >
                    <InputMask
                        mask="@*********************************"
                        maskChar=""
                    >
                        {(inputProps: any) => (
                            <Input
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[{ required: true, message: 'Пожалуйста введите почту' }]}
                    validateStatus={isEmailError ? 'error' : ''}
                    help={isEmailError ? 'Пожалуйста введите корректную почту' : null}
                >
                    <Input
                        onFocus={() => setIsEmailError(false)}
                    />
                </Form.Item>

                <Form.Item
                    label={"Введите логин"}
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите логин",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Введите пароль"}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите пароль",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{ width: "100%" }}
                            htmlType={"submit"}
                            loading={isLoading}
                        >
                            Добавить
                        </Button>
                    </Col>
                    <Col span={12}>
                        {" "}
                        <Button
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{ width: "100%" }}
                            onClick={() =>{
                                form.resetFields()
                                onClose()
                            }}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
