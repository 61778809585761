import { useMutation, useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { backend } from "api";

export const useAnalyticsCashbackUpdate = () => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        mutateAsync: update,
        isLoading,
        isError,
        ...options
    } = useMutation(backend.analytics.updateAnalyticsCashback, {
        async onSuccess() {
            await Promise.all([
                queryClient.invalidateQueries([`CASHBACK_RESULT_LIST`]),
            ]);
        },
    });

    const handleUpdate = useCallback(
        async (id: any, values: any, handleCloseModal?: any) => {
            try {
                let body = { ...values, id };
                await update(body);
                if (handleCloseModal) {
                    handleCloseModal();
                }
            } catch (e: any) {
                setErrorMessage("Произошла ошибка при редактировании кешбэка");
            }
        },
        [update]
    );

    return {
        handleUpdate,
        isLoading: isLoading,
        isError,
        errorMessage,
        ...options,
    };
};
