import {useMutation, useQuery, useQueryClient} from "react-query";
import { backend } from "../../api";
import {useCallback, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

export default function useDeclarationList() {

    const [declarationList, setDeclarationList] = useState([])
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("100");

    const { data: declaration, ...options } = useQuery(
        [
            "DECLARATION_LIST",
            currentPage,
            currentPageSize,
        ],
        () =>
            backend.declaration.getDeclarationsList(
                currentPage.toString(),
                currentPageSize,
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() =>{
        setDeclarationList(declaration?.data?.map((item: any) => ({title: item.title, id: item.id})))
    },[declaration])

    return {
        declarationList,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        ...options,
    };
}
