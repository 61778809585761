import React, {useState} from "react";
import {Button, Checkbox, Col, Dropdown, Input, MenuProps, message, Modal, Pagination, Row, Select, Spin} from "antd";
import Icon, {LoadingOutlined, MoreOutlined, DownloadOutlined} from "@ant-design/icons";
import selectIcon, {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import useProductData from "./hooks/useProducData";
import {ReactComponent as editIcon} from "../../assets/images/big-edit-icon.svg";
import EditProductAnalytics from "./modal/EditProductAnalytics";
import {CopyToClipboard} from "react-copy-to-clipboard";
import EditProductKeyWords from "./modal/EditProductKeyWords";
import {Link, useLocation} from 'react-router-dom';
import EditKeyWords from "./modal/EditKeyWords";
import bigEditIcon from '../../assets/images/big-edit-icon.svg'
import EditCoefficientsByMonth from "./modal/EditCoefficientsByMonth";
import DownloadButton, {generateFilename} from "./components/DownloadButton";
import EditKeyWordsGroup from "./modal/EditKeyWordsGroup";
import {useAuthStore} from "../auth/hooks/useAuthStore";
import EditProductGroup from "./modal/EditProductGroup";
import axios from "axios";
import {getToken} from "../../utils/tokenStorage";
import EditListOfPhrase from "./modal/EditListOfPhrase";
import EditProductPlusMinusPhrase from "./modal/EditProductPlusMinusPhrase";
import EditProductBider from "../advertising-mirror/modal/bider/EditProductBider";
import AddFileModal from "./modal/AddFileModal";

export default function ProductTable() {

    const token = getToken();
    const location = useLocation();
    const {state} = useAuthStore();
    const isShowCoordTab = state?.user?.role?.name !== 'Аналитик'

    const {
			products,
			isLoading,
			currentPage,
			currentPageSize,
			searchText,
			setCurrentPage,
			setCurrentPageSize,
			setSearchText,
			getSellersFilterItems,
			getAnalyticsUsersFilterItems,
			setAllAvailable,
			setAllUnavailable,
			setAvailable,
            setLoadingCheckboxId,
			loadingCheckboxId,
		} = useProductData()

    const [confirmUnavailableVisible, setConfirmUnavailableVisible] = useState(false)
    const [confirmAvailableVisible, setConfirmAvailableVisible] = useState(false)

    const handleSetAllUnavailable = () => {
        setConfirmUnavailableVisible(true)
    }
    const handleSetAllAvailable = () => {
        setConfirmAvailableVisible(true)
    }

    const confirmSetAllUnavailable = () => {
        setConfirmUnavailableVisible(false)
        setAllUnavailable()
    }
    const confirmSetAllAvailable = () => {
        setConfirmAvailableVisible(false)
        setAllAvailable()
    }
    const [editProductAnalyticsModal, setEditProductAnalyticsModal] = useState<any>({
        productId: '',
        isOpen: false
    });
    const [editProductKeyWordsModal, setEditProductKeyWordsModal] = useState<any>({
        productId: '',
        isOpen: false
    });
    const [editProductBiderModal, setEditProductBiderModal] = useState<any>({
        productId: '',
        isOpen: false
    });

    const [editProductPlusMinusPhraseModal, setEditProductPlusMinusPhraseModal] = useState<any>({
        productId: '',
        isOpen: false
    });
    const [coefficientsByMonthModal, setCoefficientsByMonthModal] = useState<any>({
        productId: '',
        isOpen: false
    });

    const [editKeyWordsModal, setEditKeyWordsModal] = useState<any>(false);
    const [editKeyWordsGroupModal, setEditKeyWordsGroupModal] = useState<any>(false);
    const [editProductGroupModal, setEditProductGroupModal] = useState<any>(false);
    const [editListOfPhraseModal, setEditListOfPhraseModal] = useState<any>(false);
    const [addFileModal, setAddFileModal] = useState<any>(false)


    const productsItemsForEdit: MenuProps["items"] = [
        isShowCoordTab ?
            {
                label: (
                    <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать аналитиков
                </span>
                ),
                key: "EDIT_ANALYTICS_LIST",
            } : null,
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать ключевые слова
                </span>
            ),
            key: "EDIT_KEYWORDS_LIST",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать плюс/минус фраз
                </span>
            ),
            key: "EDIT_PLUS_MINUS_PHRASE",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать бидера
                </span>
            ),
            key: "EDIT_BIDER",
        },

    ];

    const itemsForActions: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <span style={{ color: '#8faaf4' }}>
                        {React.createElement(DownloadOutlined)}
                    </span>
                    Выгрузить допоставки
                </span>
            ),
            key: "DOWNLOAD",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Дополнить справочник ключевых слов (плюс/минус фразы)
                </span>
            ),
            key: "EDIT_KEY_WORDS",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Дополнить групп ключевых слов
                </span>
            ),
            key: "EDIT_KEY_WORD_GROUP",
        },
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Дополнить группу товаров
                </span>
            ),
            key: "EDIT_PRODUCT_GROUP",
        },
        // {
        //     label: (
        //         <span style={{display: "flex", gap: "10px"}}>
        //             <Icon component={editIcon} style={{cursor: "pointer"}}/>
        //             Дополнить справочник фраз
        //         </span>
        //     ),
        //     key: "EDIT_GROUP_PHRASE",
        // },

    ];

    const getProductsActions = (productId: any) => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_ANALYTICS_LIST":
                        setEditProductAnalyticsModal({
                            ...editProductAnalyticsModal,
                            productId: productId,
                            isOpen: true
                        })
                        break;
                    case "EDIT_KEYWORDS_LIST":
                        setEditProductKeyWordsModal({
                            ...editProductAnalyticsModal,
                            productId: productId,
                            isOpen: true
                        })
                        break;
                    case "EDIT_PLUS_MINUS_PHRASE":
                        setEditProductPlusMinusPhraseModal({
                            productId: productId,
                            isOpen: true
                        })
                        break;
                    case "EDIT_BIDER":
                        setEditProductBiderModal({
                            ...editProductAnalyticsModal,
                            productId: productId,
                            isOpen: true
                        })
                        break;
                }
            },
        };
    };

    const getActions = () => {
        return {
            items: itemsForActions,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "DOWNLOAD":
                        message.warning('Скачивание началось. Пожалуйста не закрывайте страницу')
                        axios({
                            method: 'get',
                            url: `${process.env.REACT_APP_API_URL}products/download`,
                            responseType: 'blob',
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(response => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', generateFilename());
                            link.setAttribute('target', '_blank');
                            link.type = 'application/xlsx';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }).catch(error => {
                            console.error('Error downloading file:', error);
                        });
                        break;
                    case "EDIT_KEY_WORDS":
                        setEditKeyWordsModal(true)
                        break;
                    case "EDIT_KEY_WORD_GROUP":
                        setEditKeyWordsGroupModal(true)
                        break;
                    case "EDIT_PRODUCT_GROUP":
                        setEditProductGroupModal(true)
                        break;
                    case "EDIT_GROUP_PHRASE":
                        setEditListOfPhraseModal(true)
                        break;
                }
            },
        };
    };

    const sliceKeyWords = (keyWords: any) => {

        if (keyWords?.length !== 0) {
            const stringKeyWords = keyWords?.map((mapItem: any) => mapItem?.title)?.join(", ")

            if (stringKeyWords?.length >= 50) {
                return stringKeyWords?.slice(0, 50) + '...'
            } else {
                return stringKeyWords
            }
        }
        return ''
    }

    const sliceSizes = (sizes: any) => {

        if (sizes?.length !== 0) {
            const stringKeyWords = sizes?.map((mapItem: any) => <div>{`${mapItem?.size} - ${mapItem?.wbBarcode}`}</div>)
            return stringKeyWords
        }
        return ''
    }

    return (
        <div style={{
            backgroundColor: '#f8f8f8',
        }}>
            <Row gutter={6} align={"middle"} style={{
                backgroundColor: '#f8f8f8',
            }}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        className="table-header-input"
                        placeholder={"Поиск"}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        // type={"number"}
                    />
                </Col>
                <Col style={{width: "160px", paddingTop: 0, paddingBottom: 0}}>
                    <Button
                        type="primary"
                        onClick={handleSetAllAvailable}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Отслеживать все
                    </Button>
                </Col>
                <Col style={{width: "180px", paddingTop: 0, paddingBottom: 0}}>
                    <Button
                        type="primary"
                        onClick={handleSetAllUnavailable}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Не отслеживать все
                    </Button>
                </Col>
                					<Col>
						<Button
							href='https://it-korobka.ru/api/storage/products_availavility/actual_sample.xlsx'
							type={'primary'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
                    <DownloadOutlined />
							Скачать шаблон
						</Button>
					</Col>

					<Col>
                    
						<Button
							onClick={() => setAddFileModal(true)}
							type={'primary'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Добавить файл продуктов
						</Button>
					</Col>

                <Col style={{width: "200px", paddingTop: 0, paddingBottom: 0}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        placeholder={"Баркод"}
                        suffixIcon={<Icon component={SelectIconComponent}/>}
                        allowClear
                        options={[
                            {label: "2038310943012", value: "1"},
                            {label: "2038310943013", value: "2"},
                            {label: "2038310943014", value: "3"},
                            {label: "2038310943015", value: "4"},
                            {label: "2038310943016", value: "5"},
                        ]}
                    />
                </Col>

                <Col style={{width: "110px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Юр. лицо</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>

                <Col style={{width: "110px"}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getAnalyticsUsersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Аналитик</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select"
                        style={{
                            borderTopRightRadius: 8
                        }}
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon} alt={""}/>}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>
            <Row className="container" style={{marginTop: "10px", width: '100%'}}>
                <Col span={24} style={{
                    padding: '10px 10px'
                }}>
                    <div className="product-extra-nav">
                        <div className="product-extra-nav-items">
                            <div
                                className={`product-extra-nav-item ${location?.pathname.includes('products/products') && 'product-extra-nav-item-active'}`}>
                                <Link to={'/products/products'}>
                                    Товары
                                </Link>
                            </div>
                            {isShowCoordTab &&
                            <div
                                className={`product-extra-nav-item ${location?.pathname.includes('products/analytics') && 'product-extra-nav-item-active'}`}>
                                <Link to={'/products/analytics'}>
                                    Координаторы и аналитики
                                </Link>
                            </div>
                            }
                        </div>
                        <div className='product-extra-nav-buttons'>
                            <Dropdown
                                trigger={["click"]}
                                placement={"bottomRight"}
                                menu={getActions()}
                            >
                                <MoreOutlined
                                    style={{cursor: "pointer", fontSize: "20px"}}/>
                            </Dropdown>

                            {/*<DownloadButton*/}
                            {/*    fileUrl={`${process.env.REACT_APP_API_URL}products/download`}*/}
                            {/*/>*/}
                            {/*<Button*/}
                            {/*    className={"button"}*/}
                            {/*    type={"primary"}*/}
                            {/*    style={{width: "300px", fontSize: "12px"}}*/}
                            {/*    onClick={() => setEditKeyWordsModal(true)}*/}
                            {/*>*/}
                            {/*    Дополнить справочник ключевых слов*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    className={"button"}*/}
                            {/*    type={"primary"}*/}
                            {/*    style={{width: "250px", fontSize: "12px"}}*/}
                            {/*    onClick={() => setEditKeyWordsGroupModal(true)}*/}
                            {/*>*/}
                            {/*    Дополнить групп ключевых слов*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    className={"button"}*/}
                            {/*    type={"primary"}*/}
                            {/*    style={{width: "250px", fontSize: "12px"}}*/}
                            {/*    onClick={() => setEditProductGroupModal(true)}*/}
                            {/*>*/}
                            {/*    Дополнить группу товаров*/}
                            {/*</Button>*/}
                        </div>

                    </div>

                </Col>

                <Col span={24} style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}>
                    {
                        isLoading
                            ?
                            <div
                                style={{
                                    padding: "150px",
                                    textAlign: "center",
                                }}
                            >
                                <Spin
                                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                />
                            </div>
                            : !products || products?.data?.length === 0
                            ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: "150px",
                                        textAlign: "center",
                                        color: '#030000'
                                    }}
                                >
                                    Нет данных
                                </div>
                            )
                            : (
                                <>
                                    <div className="table-container">
                                        <table className={"kiz-table"}>
                                            <thead>
                                            <th
                                                style={{
                                                    borderTopLeftRadius: 8,
                                                }}
                                            >
                                                Фото
                                            </th>
                                            <th style={{}}>Предмет</th>
                                            <th style={{}}>Артикул</th>
                                            {/*<th style={{}}>Артикул поставщика</th>*/}
                                            <th style={{}}>Ключевые слова</th>
                                            <th style={{}}>Размер</th>
                                            <th style={{}}>Юридическое лицо</th>
                                            <th>
                                                Закрепленный аналитик
                                            </th>
                                            <th>
                                                Отслеживаем
                                            </th>
                                            <th>
                                                Коэффициенты по месяцам
                                            </th>
                                            <th style={{borderTopRightRadius: 8}}>
                                            </th>
                                            </thead>
                                            <tbody>
                                            {products?.data?.map((item) => (
                                                <tr>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        <a href={item?.linkOnSite} target="_blank">
                                                            <img
                                                                src={item?.photoUrl}
                                                                alt=""
                                                                style={{
                                                                    width: 110,
                                                                    height: 150,
                                                                }}
                                                            />
                                                        </a>

                                                    </td>

                                                    <td style={{}}>
                                                        {item?.name}
                                                    </td>
                                                    <td style={{}}>
                                                        <CopyToClipboard text={`${item?.wbArticle}`}
                                                                         onCopy={() => message.success('Текст скопирован')}>

                                                            <div style={{cursor: 'pointer'}}>
                                                                {item?.wbArticle}
                                                            </div>
                                                        </CopyToClipboard>
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        {sliceKeyWords(item?.keywordsProducts)}
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 90,
                                                    }}>
                                                        {
                                                            sliceSizes(item?.sizes)
                                                        }
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 100,
                                                    }}>
                                                        {item?.seller?.name}
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        {item?.analyticsBounded?.map((mapItem: any) => mapItem?.firstName)?.join(", ") || '---'}
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
           <Checkbox
                        checked={item.isAvailable}
                            onChange={() => {
                                    setLoadingCheckboxId(item.id);
                                    setAvailable({ 
                                        id: item.id, 
                                        availability: !item.isAvailable 
                                    });
                                }}
                    >
                        {loadingCheckboxId === item.id && <Spin size="small" />}
                    </Checkbox>
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        <img
                                                            src={bigEditIcon}
                                                            alt=""
                                                            style={{width: 15, cursor: 'pointer'}}
                                                            onClick={() => setCoefficientsByMonthModal({
                                                                productId: item?.id,
                                                                isOpen: true
                                                            })}
                                                        />
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        <Dropdown
                                                            trigger={["click"]}
                                                            placement={"bottomRight"}
                                                            menu={getProductsActions(item?.id)}
                                                        >
                                                            <MoreOutlined
                                                                style={{cursor: "pointer", fontSize: "20px"}}/>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    `<Pagination
                                    onChange={(page, pageSize): any => setCurrentPage(page)}
                                    defaultCurrent={1}
                                    current={currentPage}
                                    pageSize={parseInt(currentPageSize)}
                                    total={products.total}
                                    style={{
                                        textAlign: "center",
                                        padding: "16px 0",
                                    }}
                                    showSizeChanger={false}
                                />`
                                </>
                            )
                    }
                </Col>
            </Row>
            <Modal
                open={coefficientsByMonthModal.isOpen}
                closable={false}
                footer={null}
                width={519}
            >
                <EditCoefficientsByMonth
                    productId={coefficientsByMonthModal.productId}
                    productModal={coefficientsByMonthModal}
                    onClose={() => setCoefficientsByMonthModal({...coefficientsByMonthModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={editProductAnalyticsModal.isOpen}
                closable={false}
                footer={null}
                width={519}
            >
                <EditProductAnalytics
                    productId={editProductAnalyticsModal.productId}
                    productModal={editProductAnalyticsModal}
                    onClose={() => setEditProductAnalyticsModal({...editProductAnalyticsModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={editProductPlusMinusPhraseModal.isOpen}
                closable={false}
                footer={null}
                width={800}
            >
                <EditProductPlusMinusPhrase
                    productId={editProductPlusMinusPhraseModal.productId}
                    productModal={editProductPlusMinusPhraseModal}
                    onClose={() => setEditProductPlusMinusPhraseModal({...editProductPlusMinusPhraseModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={editProductKeyWordsModal.isOpen}
                closable={false}
                footer={null}
                width={700}
            >
                <EditProductKeyWords
                    productId={editProductKeyWordsModal.productId}
                    productModal={editProductKeyWordsModal}
                    onClose={() => setEditProductKeyWordsModal({...editProductKeyWordsModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={editProductBiderModal.isOpen}
                closable={false}
                footer={null}
                width={480}
            >
                <EditProductBider
                    productId={editProductBiderModal.productId}
                    productModal={editProductBiderModal}
                    onClose={() => setEditProductBiderModal({...editProductBiderModal, isOpen: false})}
                />
            </Modal>
            <Modal
                open={editKeyWordsModal}
                closable={false}
                footer={null}
                width={700}
            >
                <EditKeyWords
                    onClose={() => setEditKeyWordsModal(false)}
                />
            </Modal>
            <Modal
                open={editKeyWordsGroupModal}
                closable={false}
                footer={null}
                width={700}
            >
                <EditKeyWordsGroup
                    onClose={() => setEditKeyWordsGroupModal(false)}
                />
            </Modal>
            <Modal
                open={editProductGroupModal}
                closable={false}
                footer={null}
                width={700}
            >
                <EditProductGroup
                    onClose={() => setEditProductGroupModal(false)}
                />
            </Modal>
            <Modal
                open={editListOfPhraseModal}
                closable={false}
                footer={null}
                width={700}
            >
                <EditListOfPhrase
                    onClose={() => setEditListOfPhraseModal(false)}
                />
            </Modal>
            <Modal
            title={<p style={{padding:'20px 0 0 20px'}}>Подтвердите действие</p>}
            closable={false}
            visible={confirmUnavailableVisible}
            footer={<div style={{padding: '0px 20px 20px 20px'}}>
                <Button type='primary' onClick={() => setConfirmUnavailableVisible(false)}>Нет</Button>
                <Button type='primary' onClick={confirmSetAllUnavailable}>Да</Button>
            </div>}
        >
            <p style={{padding:'0 20px 0 20px'}}>Вы уверены, что хотите отключить отслеживание для всех товаров выбранного продавца?</p>
        </Modal>
            <Modal
            title={<p style={{padding:'20px 0 0 20px'}}>Подтвердите действие</p>}
            closable={false}
            visible={confirmAvailableVisible}
            footer={<div style={{padding: '0px 20px 20px 20px'}}>
                <Button type='primary' onClick={() => setConfirmAvailableVisible(false)}>Нет</Button>
                <Button type='primary' onClick={confirmSetAllAvailable}>Да</Button>
            </div>}
        >
            <p style={{padding:'0 20px 0 20px'}}>Вы уверены, что хотите включить отслеживание для всех товаров выбранного продавца?</p>
        </Modal>

        <Modal open={addFileModal} closable={false} footer={null} width={600}>
            <AddFileModal onClose={() => setAddFileModal(false)} />
        </Modal>
        </div>
    );
}


